export type Except<ObjectType, KeysType extends keyof ObjectType> = Pick<
  ObjectType,
  Exclude<keyof ObjectType, KeysType>
>;
export type Merge<FirstType, SecondType> = Except<FirstType, Extract<keyof FirstType, keyof SecondType>> & SecondType;
export type MergeAsPartial<FirstType, SecondType> = Partial<Merge<FirstType, SecondType>>;

export function isNullOrUndefined(val: any): boolean {
  return val === null || val === undefined;
}

export function notNullOrUndefined(val: any): boolean {
  return !isNullOrUndefined(val);
}

export function notNullOrUndefinedOrEmptyString(val: any): boolean {
  return !isNullOrUndefined(val) && val !== '';
}
