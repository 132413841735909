<div *ngIf="items.length" class="snackbar-group" [style.width.rem]="snackbarSize === 'small' ? '36' : '50'">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true" [hideToggle]="true" class="snackbar-group_panel" #expansionPanel>
      <mat-expansion-panel-header class="snackbar-group_panel_header" [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight">
        <mat-panel-title class="snackbar-group_panel_title">
          <celum-icon class="snackbar-group_title-icon" [configuration]="config.titleIcon"></celum-icon>
          <span class="snackbar-group_panel_title_description">
            {{ config.title | translate: config.titleArgs }}
          </span>
          <celum-icon
              class="snackbar-group_panel_title_toggle"
              [class.snackbar-group_panel_title_toggle--closed]="!expansionPanel.expanded"
              [configuration]="arrowIcon"></celum-icon>
        </mat-panel-title>
        <mat-panel-description class="snackbar-group_panel_description">
          <celum-icon [configuration]="cancelIcon" (click)="closeAll.emit()"></celum-icon>
        </mat-panel-description>

        <mat-progress-bar
            *ngIf="isProgressSnackbar()"
            class="snackbar-group_panel_progress-bar"
            [class.snackbar-group_panel_progress-bar--progress]="config.progress < 100"
            [class.snackbar-group_panel_progress-bar--success]="config.progress >= 100"
            [mode]="'determinate'"
            [value]="config.progress">
        </mat-progress-bar>
      </mat-expansion-panel-header>
      <div class="flex-column-scrollbar celum-scrollbar">
        <ng-container *ngFor="let item of items; trackBy: trackByFn">
          <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
