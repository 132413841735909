<robot
    [title]="'ROBOTS.MANDATORY_ASSIGNMENT.TITLE'"
    [whenIcon]="robotTaskListIcon"
    [thenIcon]="automatorIcon"
    [readonly]="isReadonly"
    [messageBoxes]="messageBoxes$ | async">
  <span
      when
      class="robot-dialog_content_text"
      [innerHTML]="'ROBOTS.MANDATORY_ASSIGNMENT.ROW1' | translate: { taskListName: sourceEntity.name.toUpperCase() }">
  </span>
  <span
      then
      class="robot-dialog_content_text"
      [innerHTML]="'ROBOTS.MANDATORY_ASSIGNMENT.ROW2' | translate: { taskListName: sourceEntity.name.toUpperCase() }">
  </span>
</robot>
