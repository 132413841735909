import { Injectable } from '@angular/core';

import { LocalStorageService } from '@celum/work/app/core';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';

export type WorkroomGroupExpandState = Record<number, boolean>;

export enum WORKROOOM_GROUP_EVENT {
  EXPAND_STATE_CHANGED = 'EXPAND_STATE_CHANGED'
}

@Injectable({ providedIn: 'root' })
export class WorkroomGroupService {
  public EXPANDED_GROUPS_KEY = 'expanded_groups_ids';

  constructor(
    private readonly localStorageService: LocalStorageService,
    private eventBus: ApplicationEventBus
  ) {}

  public getGroupExpandState(groupId: number): boolean {
    const existingData: WorkroomGroupExpandState = this.getWorkroomGroupExpandState();

    return existingData[groupId];
  }

  public saveGroupExpandedState(groupId: number, expanded: boolean): void {
    const existingData: WorkroomGroupExpandState = this.getWorkroomGroupExpandState();

    existingData[groupId] = expanded;

    this.localStorageService.setItem(this.EXPANDED_GROUPS_KEY, JSON.stringify(existingData));
    this.eventBus.publishEvent({ type: WORKROOOM_GROUP_EVENT.EXPAND_STATE_CHANGED, data: existingData });
  }

  public getWorkroomGroupExpandState(): WorkroomGroupExpandState {
    return JSON.parse(this.localStorageService.getItem(this.EXPANDED_GROUPS_KEY)) ?? {};
  }
}
