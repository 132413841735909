import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { memoize } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { Permission } from '@celum/work/app/core/api/permission';
import { selectContributorByUserIdAndWorkroomId } from '@celum/work/app/core/model/entities/contributor';
import { Person } from '@celum/work/app/core/model/entities/person';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';
import { selectWorkroomById, Workroom } from '@celum/work/app/core/model/entities/workroom';
import {
  DeleteWorkroomDialog,
  DeleteWorkroomDialogConfiguration
} from '@celum/work/app/pages/dashboard/components/workroom-group-list/components/delete-workroom-dialog/delete-workroom-dialog.component';
import {
  ReopenWorkroomDialog,
  ReopenWorkroomDialogConfiguration
} from '@celum/work/app/pages/dashboard/components/workroom-group-list/components/reopen-workroom-dialog/reopen-workroom-dialog.component';
import { SharedModule } from '@celum/work/app/shared';
import { DueDateModule } from '@celum/work/app/shared/components/due-date/due-date.module';
import {
  FinishWorkroomDialog,
  FinishWorkroomDialogConfiguration
} from '@celum/work/app/shared/components/finish-workroom-dialog/finish-workroom-dialog.component';
import { WorkroomContextMenuPermissions } from '@celum/work/app/shared/components/workroom-context-menu/workroom-context-menu.component';
import { WorkroomContextMenuModule } from '@celum/work/app/shared/components/workroom-context-menu/workroom-context-menu.module';
import { WorkroomIconModule } from '@celum/work/app/shared/components/workroom-icon/workroom-icon.module';
import { AvatarDecoratorFn, AvatarUtil, DateUtil } from '@celum/work/app/shared/util';

@Component({
  selector: 'workroom-table-row',
  templateUrl: './workroom-table-row.component.html',
  styleUrls: ['./workroom-table-row.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, SharedModule, WorkroomContextMenuModule, DueDateModule, WorkroomIconModule]
})
export class WorkroomTableRowComponent {
  @Input() public workroom: Workroom;
  @Input() public teamspace: Teamspace;

  public readonly fileIcon = IconConfiguration.large('file-m');
  public readonly taskIcon = IconConfiguration.small('icon-create-single-task');
  public getContributorsMemoized = memoize(this.getContributors);

  constructor(
    private store: Store<any>,
    private dialogOpener: CelumDialogOpener,
    private dateUtil: DateUtil,
    private translateService: TranslateService,
    private avatarUtil: AvatarUtil
  ) {}

  public getRoleBadgeDecorator(workroomId: number): AvatarDecoratorFn {
    return avatar =>
      this.store
        .select(selectContributorByUserIdAndWorkroomId(avatar.person.id, workroomId))
        .pipe(map(contributor => this.avatarUtil.handleRoleBadge(avatar, contributor.roles)));
  }

  public onWorkroomContextMenuAction(permission: WorkroomContextMenuPermissions, workroom: Workroom): void {
    if (permission === Permission.WORKROOM_DELETE) {
      this.dialogOpener.showDialog(
        'delete-workroom',
        DeleteWorkroomDialog,
        new DeleteWorkroomDialogConfiguration(workroom),
        {
          restoreFocus: false
        }
      );
    }
    if (permission === Permission.WORKROOM_FINISH) {
      this.dialogOpener.showDialog(
        'finish-workroom',
        FinishWorkroomDialog,
        new FinishWorkroomDialogConfiguration({ ...workroom }, false),
        { restoreFocus: false }
      );
    }
    if (permission === Permission.WORKROOM_REOPEN) {
      this.dialogOpener.showDialog(
        'reopen-workroom',
        ReopenWorkroomDialog,
        new ReopenWorkroomDialogConfiguration(workroom, false, true),
        {
          restoreFocus: false
        }
      );
    }
  }

  public getContributors(workroomId: number): Observable<Person[]> {
    return this.store.select(selectWorkroomById(workroomId)).pipe(
      filter(workroom => !!workroom),
      switchMap(workroom => workroom.persons(this.store))
    );
  }

  public getLastActivityToNow(date: number): string {
    return this.translateService.instant('TEAMSPACE_MANAGEMENT.WORKROOMS.LAST_ACTIVITY', {
      timePeriod: this.dateUtil.fromNow(date)
    });
  }
}
