import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { DataUtil, EntityType } from '@celum/core';
import { Person, PersonType } from '@celum/work/app/core/model/entities/person';

import { Entity } from '../../entity';

export interface ContentItem extends Entity<string, ContentItemType> {
  id: string;
  name: string;
  parentId: string;
  libraryId: string;
  assetType: string;
  createdById: string;
  changedById: string;
  createdOn: number;
  changedOn: number;
  relationId: string;
  creator: (store: Store<any>) => Observable<Person>;
  modifier: (store: Store<any>) => Observable<Person>;
  importId: number;
  exportId: number;
  parentCollectionId?: number;
  entityType: ContentItemType;
  status: ContentItemStatus;
  contentHubConnections: ContentHubConnection[];
}

export enum ContentItemTypes {
  FILE = 'FILE',
  FOLDER = 'FOLDER'
}

export enum ContentItemStatus {
  NOT_DELETED = 'NOT_DELETED',
  SOFT_DELETED = 'SOFT_DELETED',
  HARD_DELETED = 'HARD_DELETED'
}

export enum ContentItemTypeKeys {
  FILE_UPDATE = 'FileUpdate',
  FOLDER_UPDATE = 'FolderUpdate',
  FOLDER_CREATE = 'FolderCreate'
}

export enum ContentItemOrigin {
  SASS = 'SASS',
  WORKROOMS = 'WORKROOMS'
}

export interface ContentHubConnection {
  id?: number;
  fileId: string;
  externalId: number;
  connectionType: ContentHubConnectionTypes;
  active: boolean;
}

export enum ContentHubConnectionTypes {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT'
}

export class ContentItemType implements EntityType {
  public static readonly TYPE_KEY: string = 'ContentItem';

  private static _instance: ContentItemType;

  public id = ContentItemType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): ContentItemType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(PersonType.TYPE_KEY)) {
        relationDefinitions.createdBy = PersonType.instance().getSchema({});
        relationDefinitions.changedBy = PersonType.instance().getSchema({});
      }
    }

    return new schema.Entity(ContentItemType.TYPE_KEY, relationDefinitions);
  }
}

export interface ConflictingContentItem {
  fileCount?: number;
  folderCount?: number;
  contentItemTaskCount?: ContentItemTaskCount[];
  contentItemRobotCount?: ContentItemRobotCount[];
}

export interface ContentItemTaskCount {
  contentItemName: string;
  taskCount: number;
}

export interface ContentItemRobotCount {
  contentItemName: string;
  robotCount: number;
}

export type ConflictItem = ContentItemTaskCount | ContentItemRobotCount;

export function isTaskConflictItem(item: ContentItemTaskCount | ContentItemRobotCount): item is ContentItemTaskCount {
  return (item as ContentItemTaskCount)?.taskCount !== undefined;
}

export function isRobotConflictItem(item: ContentItemTaskCount | ContentItemRobotCount): item is ContentItemRobotCount {
  return (item as ContentItemRobotCount)?.robotCount !== undefined;
}
