<div
    [class]="triggerClass"
    [style.background-color]="computedBackgroundColor"
    [matMenuTriggerFor]="menuRef"
    (menuOpened)="menuOpened()"
    (menuClosed)="menuClosed()"
    [matTooltip]="triggerTooltipTranslationKey | translate">
  <ng-content select="[popupTrigger]"></ng-content>
</div>

<mat-menu #menuRef="matMenu" [class]="contentClass">
  <ng-content select="[popupContent]"></ng-content>
</mat-menu>
