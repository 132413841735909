<ng-content select="[top-info-note]"></ng-content>

<search-and-select
    [elementIdentifier]="userIdentifier"
    [elements]="sortedContributors"
    [selectedElements]="selectedContributors"
    [hasMoreKey]="'SEARCH_AND_SELECT_CONTRIBUTOR.HAS_MORE'"
    [noResultsKey]="'SEARCH_AND_SELECT_CONTRIBUTOR.NO_RESULTS'"
    [placeholder]="'SEARCH_AND_SELECT_CONTRIBUTOR.SEARCH'"
    [isSearchEnabled]="isSearchEnabled"
    (searchValueChanged)="searchValueChanged.emit($event)"
    (elementSelected)="elementSelected.emit($any($event))"
    (elementDeselected)="elementDeselected.emit($any($event))"
    data-operation-id="assign-people">
  <ng-content select="[extra-option]" ngProjectAs="[extra-option]"></ng-content>
  <ng-content select="[fallback-action]" ngProjectAs="[fallback-action]"></ng-content>
  <ng-template let-person="element">
    <search-and-select-person
        [person]="person"
        [withEmail]="true"
        [avatarDecorator]="avatarDecorator">
    </search-and-select-person>
  </ng-template>
</search-and-select>
