import { createAction, props } from '@ngrx/store';

import { TaskList } from './task-list.model';

export const TaskListsUpsertOne = createAction('[TaskLists] Upsert One', props<{ taskList: TaskList }>());

export const TaskListsUpsertMany = createAction('[TaskLists] Upsert Many', props<{ taskLists: TaskList[] }>());

export const TaskListsDeleteOne = createAction('[TaskLists] Delete One', props<{ id: number }>());

export const TaskListsDeleteMany = createAction('[TaskLists] Delete Many', props<{ ids: number[] }>());

export const TaskListsHandleOwnerId = createAction(
  '[TaskLists] Handle Owner Id',
  props<{ taskListId: number; ownerId: number; operation: 'add' | 'remove' }>()
);
