import { AfterContentInit, ContentChild, Directive, ElementRef, EventEmitter, HostBinding, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { filter } from 'rxjs/operators';

import { CelumInlineFormFieldButtons } from './celum-inline-form-field-buttons/celum-inline-form-field-buttons';

@Directive({ selector: '[celum-inline-form-field]' })
export class CelumInlineFormFieldDirective implements OnInit, AfterContentInit, OnDestroy {

  @Output() public readonly onSaved = new EventEmitter<string>();
  @Output() public readonly onCancel = new EventEmitter<string>();

  @HostBinding('class.celum-inline-input') public hostClass = true;
  @HostBinding('class.celum-inline-input--required') public isRequiredClass = false;

  @ContentChild(MatInput, { static: false }) public input: MatInput;
  @ContentChild(MatInput, {
    read: ElementRef,
    static: true
  }) public inputElement: ElementRef;

  @ContentChild(CelumInlineFormFieldButtons, { static: true }) public inlineFormFieldButtons: CelumInlineFormFieldButtons;

  private initialValue: string;

  @HostListener('document:click')
  public deactivateEditMode(): void {
    this.emitNewValue();
    this.deactivateInput();
  }

  @HostListener('click', ['$event'])
  public activateEditMode(event?: MouseEvent): void {
    this.activateInput();
    event?.stopPropagation(); // stop bubbling to above document:click handler
  }

  @HostListener('keydown.enter', ['$event'])
  public onEnter(event: Event): void {
    event.preventDefault();
    this.emitNewValue();
    this.deactivateInput();
  }

  public ngOnInit(): void {
    this.listenForCancelEvent();
    this.listenForSaveEvent();
  }

  public ngAfterContentInit(): void {
    // By default such inputs are readonly
    this.input.readonly = true;
    this.isRequiredClass = this.input.required;
  }

  public ngOnDestroy(): void {
    this.inlineFormFieldButtons?.cancel.unsubscribe();
    this.inlineFormFieldButtons?.save.unsubscribe();
  }

  public resetInputValue(value?: string): void {
    const resetToValue = value ?? this.initialValue;

    // If formControl was defined - reset form control otherwise write value directly to value
    // the reason that FormControl's property valueChanges isn't able to detect changes if input value was directly changed.
    this.input.ngControl ? this.input.ngControl.reset(resetToValue) : this.input.value = resetToValue;
  }

  private listenForSaveEvent(): void {
    this.inlineFormFieldButtons?.save.pipe(filter(() => !this.input.readonly)).subscribe(() => {
      this.emitNewValue();
      this.deactivateInput();
    });
  }

  private listenForCancelEvent(): void {
    // if the input is not in edit mode we should't reset it
    this.inlineFormFieldButtons?.cancel.pipe(filter(() => !this.input.readonly)).subscribe(() => {
      this.resetInputValue();
      this.deactivateInput();
      this.onCancel.emit();
    });
  }

  private activateInput(): void {
    if (this.initialValue !== undefined) {
      return;
    }

    if (!this.input.disabled) {
      this.input.readonly = false;
      this.input.focus();
      this.input.focused = true;
      this.inlineFormFieldButtons?.changeActiveState(true);
    }

    // save the value state before it was changed
    this.initialValue = this.input.value;
  }

  private deactivateInput(): void {
    this.input.readonly = true;
    this.input.focused = false;
    this.inlineFormFieldButtons?.changeActiveState(false);
    this.initialValue = undefined;
  }

  private emitNewValue(): void {
    if (this.initialValue !== undefined && this.input.value !== this.initialValue) {
      this.onSaved.emit(this.input.value);
    } else {
      this.onCancel.emit();
    }

    this.initialValue = undefined;
  }

}
