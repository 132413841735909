import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';

import { CelumQuillComponent } from './celum-quill.component';
import { QuillInitializeService } from './quillInitialize.service';
import { SharedModule } from '../../shared.module';

@NgModule({
  imports: [SharedModule, QuillModule.forRoot()],
  declarations: [CelumQuillComponent],
  exports: [CelumQuillComponent],
  providers: [QuillInitializeService]
})
export class CelumQuillModule {}
