<div
    class="search-and-select_entry"
    [class.search-and-select_entry--selected]="selected"
    [attr.data-element-id]="dataElementId"
    [attr.transition-name]="transitionName"
    [attr.data-cy]="'search-and-select-entry-' + elementId">
  <div
    class="search-and-select_template"
    (click)="selectionChange.emit(!selected)"
    [ngClass]="showCheckbox ? 'search-and-select_template-with-checkbox' : 'search-and-select_template-no-checkbox'">
    <ng-content></ng-content>
  </div>

  <div class="search-and-select_check" *ngIf="showCheckbox">
    <mat-checkbox [checked]="selected" (change)="selectionChange.emit($event.checked)"></mat-checkbox>
  </div>
</div>
