<mat-form-field
    celum-form-field
    [size]="celumFormFieldSize"
    class="search-field"
    [reserveHintSpace]="reserveHintSpace">
  <input
      #searchInput
      [autoFocus]="autoFocused"
      [placeholder]="placeholder | translate"
      [formControl]="searchFormCtrl"
      [matAutocomplete]="auto"
      data-cy="search-field-input"
      matInput />
  <celum-icon matSuffix *ngIf="!searchFormCtrl.value" [configuration]="searchIcon"></celum-icon>
  <button *ngIf="searchFormCtrl.value"
      class="search-field_clear"
      matSuffix
      mat-button celum-square-button
      [matTooltip]="'SEARCH.CLEAR_SEARCH' | translate"
      (click)="clearInput()"
      data-cy="search-field-clear">
    <celum-icon [configuration]="clearIcon"></celum-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngIf="showHint && searchFormCtrl.value?.length > 0 && searchFormCtrl.value?.length < minChars"
                class="body"
                data-cy="search-field-min-length-hint"
                disabled>
      {{ 'SEARCH_FIELD.MIN_LENGTH' | translate: {minLength: minChars} }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
