import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, from, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { Roles } from '@celum/work/app/core/model';
import { selectCurrentWorkroomRootFolder } from '@celum/work/app/core/model/entities/folder/folder.selector';
import {
  AttachFilesToTaskOnImport,
  AttachFilesToTaskOnUpload,
  SubsequentActionTypeKey
} from '@celum/work/app/core/model/subsequent-action.model';
import { PermissionUtil } from '@celum/work/app/shared/util';
import { isNullOrUndefined, notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import {
  SelectAssetsDialog,
  SelectAssetsDialogConfiguration,
  SelectAssetsDialogResult
} from '../../../../content-hub/components/select-assets-dialog/select-assets-dialog.component';
import { AssetDTO, ContentHubAssetTicketDTO } from '../../../../content-hub/model/content-hub.model';
import { ImportProgressTaskParams } from '../../../../content-hub/model/import.model';
import { File as FileEntity } from '../../../../core/model/entities/file/file.model';
import { Task } from '../../../../core/model/entities/task';
import { ImportFileFromContentHubOperation } from '../../../../pages/workroom/pages/files/services/content-hub-operations/import/import-file-from-ch-operation';
import { selectCurrentWorkroom } from '../../../../pages/workroom/store/workroom-wrapper.selectors';
import { ProgressTask, ProgressTaskType } from '../../../../progress-task/store/progress-task.model';
import {
  SelectFolderDialogComponent,
  SelectFolderDialogConfiguration,
  SelectFolderDialogResult
} from '../../../../shared/components/select-folder-dialog/select-folder-dialog.component';
import { UploadTicket } from '../../model/upload-ticket.dto';
import { UploadParams, UploadProgressTaskParams } from '../../model/upload.model';

interface UploadToComputerParams {
  files: File[];
  folderId?: string;
  fileId?: string;
  taskId?: number;
  targetEntityForQuillImages?: FileEntity | Task;
}

@Injectable({
  providedIn: 'root'
})
export class UploadProgressTaskService {
  constructor(
    private store: Store<any>,
    @Inject(DOCUMENT) private document: Document,
    private permissionUtil: PermissionUtil,
    private celumDialogOpener: CelumDialogOpener
  ) {}

  public getUploadTasksForDraggedFiles(
    files: File[],
    folderId?: string,
    taskId?: number
  ): Observable<ProgressTask<UploadProgressTaskParams, UploadTicket>[]> {
    return (isNullOrUndefined(folderId) ? this.openSelectFolderDialogOrGetDefault() : of(folderId)).pipe(
      mergeMap((id: string | null) =>
        isNullOrUndefined(id) ? EMPTY : this.getUploadTasksForFilesFromComputer({ files, taskId, folderId: id })
      )
    );
  }

  public getUploadTasksForChImport(
    taskId: number
  ): Observable<ProgressTask<ImportProgressTaskParams, ContentHubAssetTicketDTO>[] | null> {
    return this.openSelectAssetsDialog().pipe(
      mergeMap((files: FileEntity[] | undefined) =>
        isNullOrUndefined(files)
          ? EMPTY
          : this.openSelectFolderDialogOrGetDefault().pipe(
              mergeMap((folderId: string | undefined) =>
                isNullOrUndefined(folderId) ? EMPTY : this.getUploadTasksForFilesFromCH(files, folderId, taskId)
              )
            )
      )
    );
  }

  public getUploadNewVersionTask(
    contentItemId: string
  ): Observable<ProgressTask<UploadProgressTaskParams, UploadTicket>[]> {
    return this.getFiles(true).pipe(
      switchMap(files => this.getUploadTasksForFilesFromComputer({ files, fileId: contentItemId }))
    );
  }

  public getUploadTasksForFolder(
    folderId?: string,
    taskId?: number
  ): Observable<ProgressTask<UploadProgressTaskParams, UploadTicket>[] | null> {
    return this.getFiles(false).pipe(
      mergeMap(files =>
        (isNullOrUndefined(folderId) ? this.openSelectFolderDialogOrGetDefault() : of(folderId)).pipe(
          mergeMap((id: string | null) =>
            isNullOrUndefined(id) ? EMPTY : this.getUploadTasksForFilesFromComputer({ files, folderId: id, taskId })
          )
        )
      )
    );
  }

  public getUploadTasksForFilesFromComputer({
    folderId,
    fileId,
    taskId,
    files,
    targetEntityForQuillImages
  }: UploadToComputerParams): Observable<ProgressTask<UploadProgressTaskParams, UploadTicket>[]> {
    return forkJoin(
      files.map(file => this.getUploadParams(file, folderId, fileId, taskId, targetEntityForQuillImages))
    ).pipe(
      map(uploadParamList =>
        uploadParamList.map(uploadParam =>
          ProgressTask.queued<UploadProgressTaskParams, UploadTicket>(
            new UploadProgressTaskParams({ ...uploadParam }),
            ProgressTaskType.UPLOAD
          )
        )
      )
    );
  }

  public getFiles(single?: boolean, supportedFileTypes?: string[]): Observable<File[]> {
    return new Observable(subj => {
      const fileInputElement: HTMLInputElement = this.createFileInputElement();

      if (!single) {
        fileInputElement.setAttribute('multiple', '');
      }

      if (supportedFileTypes) {
        fileInputElement.setAttribute('accept', supportedFileTypes.join(','));
      }

      fileInputElement.onchange = file => {
        const files = this.onFileChanged(file, fileInputElement);
        subj.next(files);
        subj.complete();
      };

      if (window.navigator.userAgent.toLocaleLowerCase().includes('selenium')) {
        return;
      }

      fileInputElement.click();
    });
  }

  private getUploadTasksForFilesFromCH(
    files: FileEntity[],
    folderId: string,
    taskId: number
  ): Observable<ProgressTask<ImportProgressTaskParams, ContentHubAssetTicketDTO>[]> {
    return forkJoin(
      files.map(({ activeVersion: activeVersionGetter, parentCollectionId, id, name }) =>
        activeVersionGetter(this.store).pipe(
          map(activeVersion => new AssetDTO(id.toString(), name, activeVersion.originalFileSize, parentCollectionId))
        )
      )
    ).pipe(
      withLatestFrom(this.store.select(selectCurrentWorkroom)),
      map(([assets, workroom]) =>
        assets
          .map(asset => {
            const action: AttachFilesToTaskOnImport = {
              _type: SubsequentActionTypeKey.ATTACH_FILES_TO_TASK_ON_IMPORT,
              taskId
            };

            return ProgressTask.queued(
              new ImportProgressTaskParams({ parentId: folderId, asset, workroom, action }),
              ProgressTaskType.IMPORT_FROM_CH
            );
          })
          .reduce((acc, curr) => [...acc, curr], [])
      )
    );
  }

  private onFileChanged(event: any, fileInputElement: HTMLInputElement): File[] {
    this.document.body.removeChild(fileInputElement);

    if (!event.target.files) {
      return;
    }

    return Object.values(event.target.files);
  }

  private getUploadParams(
    file: File,
    folderId: string,
    contentItemId?: string,
    taskId?: number,
    targetEntityForCommentBasedFiles?: FileEntity | Task
  ): Observable<UploadParams> {
    return this.store.select(selectCurrentWorkroom).pipe(
      take(1),
      map(workroom => {
        const params: UploadParams = {
          folderId,
          contentItemId,
          file,
          workroom,
          isNewVersion: contentItemId !== undefined,
          targetEntityForCommentBasedFiles
        };

        // for files in the comments we don't pass SubsequentAction
        if (notNullOrUndefined(taskId) && isNullOrUndefined(targetEntityForCommentBasedFiles)) {
          params.action = {
            _type: SubsequentActionTypeKey.ATTACH_FILES_TO_TASK_ON_UPLOAD,
            taskId
          } as AttachFilesToTaskOnUpload;
        }

        return params;
      })
    );
  }

  private createFileInputElement(): HTMLInputElement {
    const fileInputElement = this.document.createElement('input');

    fileInputElement.setAttribute('type', 'file');
    fileInputElement.style.display = 'none';
    this.document.body.append(fileInputElement);

    return fileInputElement;
  }

  private openSelectFolderDialog(): Observable<string | null> {
    return from(
      this.celumDialogOpener.showDialog(
        'select-folder',
        SelectFolderDialogComponent,
        new SelectFolderDialogConfiguration(
          [],
          'LINK_FILE_TO_TASK_DIALOG.SELECT_FOLDER_FOR_UPLOAD_DIALOG.TITLE',
          'LINK_FILE_TO_TASK_DIALOG.SELECT_FOLDER_FOR_UPLOAD_DIALOG.CONFIRM'
        )
      )
    ).pipe(map((result: SelectFolderDialogResult) => result?.folder.id));
  }

  private openSelectFolderDialogOrGetDefault(): Observable<string | null> {
    return this.permissionUtil.hasRoleForCurrentWorkroom(Roles.VISITOR).pipe(
      take(1),
      switchMap(isVisitor => {
        return isVisitor
          ? this.store.select(selectCurrentWorkroomRootFolder).pipe(map(({ id }) => id))
          : this.openSelectFolderDialog();
      })
    );
  }

  private openSelectAssetsDialog(): Observable<FileEntity[] | undefined> {
    const config = new SelectAssetsDialogConfiguration({
      title: 'CONTENT_HUB.IMPORT.FILE.DIALOG.HEADLINE',
      submitButtonText: 'CONTENT_HUB.IMPORT.FILE.DIALOG.BUTTON',
      submitIcon: IconConfiguration.medium('import'),
      isMultiSelection: true,
      canImportEntireCollection: false
    });

    return from(
      this.celumDialogOpener.showDialog(ImportFileFromContentHubOperation.DIALOG_KEY, SelectAssetsDialog, config)
      // TODO: should be refactored, in previous implementation this was converted to File besides in the dialog only ContentItem returned
    ).pipe(map((result: SelectAssetsDialogResult) => result?.selectedContentItems as FileEntity[]));
  }
}
