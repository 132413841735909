import { ContentHubAutomatorTypes, RobotActionType } from '@celum/work/app/core/model/entities/workroom/robot.model';

import { ContentHub63XStrategy } from './content-hub-6-3-x-strategy';
import { ContentHubStrategy } from './content-hub-strategy';
import { ContentHubSortField } from './content-hub.model';

export class ContentHub68XStrategy extends ContentHubStrategy {
  public defaultStrategy(): ContentHubStrategy {
    return new ContentHub63XStrategy();
  }

  public buildNumber(): string {
    return '6.8.0';
  }

  public supportsRelayCalls(): boolean {
    return true;
  }

  public getSupportedContentHubAction(): { [key: string]: RobotActionType[] } {
    return {
      [ContentHubAutomatorTypes.WORKROOM_FILES]: [RobotActionType.CONTENT_HUB_EXPORT_AS_NEW_ASSET],
      [ContentHubAutomatorTypes.CONTENT_HUB_ASSETS]: [RobotActionType.CONTENT_HUB_EXPORT_TO_IMPORTED_ASSET]
    };
  }

  public getSupportedContentHubSortFields(): ContentHubSortField[] {
    return [
      ContentHubSortField.NAME,
      ContentHubSortField.ID,
      ContentHubSortField.ORIGINAL_FILE_NAME,
      ContentHubSortField.MEDIA_TYPE,
      ContentHubSortField.CREATION_DATE,
      ContentHubSortField.LAST_MODIFIED_DATE,
      ContentHubSortField.FILE_EXTENSION,
      ContentHubSortField.ORIGINAL_FILE_SIZE
    ];
  }
}
