import { Injectable } from '@angular/core';

import {
  Subtask,
  SubtaskCreateParams,
  SubtaskType,
  SubtaskUpdateParams
} from '@celum/work/app/core/model/entities/subtask/subtask.model';
import { SubtaskStatus } from '@celum/work/app/core/model/entities/task/task.model';
import { MAX_SORT } from '@celum/work/app/shared/util';

@Injectable()
export class SubtaskUtilService {
  public static subtaskToCreateParams(subtask: Subtask): SubtaskCreateParams {
    return { taskId: subtask.parentId, subtaskName: subtask.name };
  }

  public static subtaskToUpdateParams(oldSubtask: Subtask, newSubtask: Subtask): SubtaskUpdateParams {
    return {
      taskId: newSubtask.parentId,
      subtaskId: newSubtask.id,
      name: oldSubtask.name !== newSubtask.name ? newSubtask.name : undefined,
      status: oldSubtask.status !== newSubtask.status ? newSubtask.status : undefined,
      sort: oldSubtask.sort !== newSubtask.sort ? newSubtask.sort : undefined
    };
  }

  public createTmpSubtask(params: SubtaskCreateParams): Subtask {
    return {
      id: -Date.now(), // temporary negative number as ID
      name: params.subtaskName,
      sort: MAX_SORT,
      parentId: params.taskId,
      status: SubtaskStatus.OPEN,
      entityType: SubtaskType.instance()
    } as unknown as Subtask;
  }
}
