import { createAction, props } from '@ngrx/store';

import { File } from '@celum/work/app/core/model/entities/file/file.model';
import {
  Task,
  TaskCreationContext,
  TaskCreationStrategy,
  TaskPropertiesToUpdate
} from '@celum/work/app/core/model/entities/task';
import { TaskFormAutofillData } from '@celum/work/app/core/model/subsequent-action.model';

export const TaskGetTask = createAction('[Task] Get Task', props<{ taskId: number }>());

export const TaskGetTaskError = createAction('[Task] Get Task Error');

export const TaskUpdateTask = createAction(
  '[Task] Update Task',
  props<{ task: Task; propertiesToUpdate: TaskPropertiesToUpdate }>()
);

export const TaskMoveTasks = createAction(
  '[Task] Move Tasks',
  props<{ tasks: Task[]; sourceTaskListId: number; targetTaskListId: number; sort: number; showSnackbar?: boolean }>()
);

export const TaskCreateTask = createAction('[Task] Create Task', props<{ task: Task }>());

export const TaskCopyTaskSuccess = createAction('[Task] Copy Task Success', props<{ task: Task }>());

export const TaskCreateTaskSucceeded = createAction(
  '[Task] Create Task Succeeded',
  props<{ task: Task; tempId: number }>()
);

export const TaskCreateTaskFailed = createAction('[Task] Create Task Failed', props<{ task: Task }>());

export const TaskDeleteTask = createAction('[Task] Delete Task', props<{ task: Task }>());

export const TaskDeleteTaskSucceeded = createAction('[Task] Delete Task Succeeded', props<{ task: Task }>());

export const TaskDeleteTaskFailed = createAction('[Task] Delete Task Failed', props<{ task: Task }>());

export const TaskDeleteTasks = createAction('[Task] Delete Tasks', props<{ tasks: Task[] }>());

export const TaskDeleteTasksSucceeded = createAction('[Task] Delete Tasks Succeeded', props<{ tasks: Task[] }>());

export const TaskDeleteTasksFailed = createAction('[Task] Delete Tasks Failed', props<{ tasks: Task[] }>());

export const TaskAssignPerson = createAction('[Task] Assign Person To Task', props<{ task: Task; personId: number }>());

export const TaskAssignPersonSuccess = createAction(
  '[Task] Assigned Person To Task',
  props<{ task: Task; personId: number }>()
);

export const TaskMoveWithBulkAssignment = createAction(
  '[Task] Move with Bulk Assignment',
  props<{
    tasks: Task[];
    sourceTaskListId: number;
    targetTaskListId: number;
    sort: number;
    personIds: number[];
    showSnackbar?: boolean;
  }>()
);

export const TaskUnassignPerson = createAction(
  '[Task] Unassign Person From Task',
  props<{ task: Task; personId: number }>()
);

export const TaskUnassignPersonSuccess = createAction(
  '[Task] Unassigned Person From Task',
  props<{ task: Task; personId: number }>()
);

export const TasksMovedToOtherList = createAction(
  '[Task] Tasks moved between lists',
  props<{ loggedInPersonId: number; tasks: Task[]; sourceTaskListId: number; targetTaskListId: number }>()
);

export const TaskDeleteTaskContentItems = createAction(
  '[Task] Delete content item(s) from a task',
  props<{ task: Task; contentItems: File[] }>()
);

export const TaskDeleteTaskContentItemsSucceeded = createAction(
  '[Task] Delete content item(s) from a task Succeeded',
  props<{ taskId: number; contentItems: File[] }>()
);

export const TaskDeleteTaskContentItemsFailed = createAction(
  '[Task] Delete content item(s) from a task Failed',
  props<{ task: Task; contentItems: File[] }>()
);

export const TaskCreateTaskFromFiles = createAction(
  '[Task] Create task(s) based on file(s)',
  props<CreateTaskFromFilesParams>()
);

export const TaskFillFormAndCreateTaskFromFiles = createAction(
  '[Task] Fill form and create task(s) based on file(s)',
  props<CreateTaskFromFilesParams>()
);

export const TaskCreateTaskFromFilesSucceeded = createAction(
  '[Task] Creating task(s) based on file(s) succeeded',
  props<{ tasks: Task[]; taskListId: number; taskCreationContext: TaskCreationContext }>()
);

export const TaskCreateTaskFromFilesFailed = createAction(
  '[Task] Creating task(s) based on file(s) failed',
  props<{ taskListId: number; files: File[] }>()
);

export const TaskCreateTaskFromPortal = createAction(
  '[Task] Create Task from Portal',
  props<CreateTaskFromFilesParams>()
);

export const TaskFillFormAndCreateTaskFromPortal = createAction(
  '[Task] Fill form and create task from Portal',
  props<CreateTaskFromFilesParams>()
);

export const TaskCreateTaskFromPortalSucceeded = createAction(
  '[Task] Creating task from Portal succeeded',
  props<{ tasks: Task[]; taskListId: number }>()
);

export const TaskCreateTaskFromPortalFailed = createAction(
  '[Task] Creating task from Portal failed',
  props<{ portalId: string }>()
);

export const TaskAttachmentsAdded = createAction(
  '[Task] Attachment Added',
  props<{ taskId: number; fileIds: string[] }>()
);

export interface CreateTaskFromFilesParams {
  taskName: string;
  taskListId: number;
  taskCreationStrategy: TaskCreationStrategy;
  files: File[];
  taskCreationContext: TaskCreationContext;
  taskForm?: TaskFormAutofillData;
  portalId?: string;
}
