<content-item-list-banner
    *ngIf="(usedStoragePercentage >= 90 || used3DPercentage >= 90)"
    data-cy="content-item-list-storage-warning"
    [usedStoragePercentage]="usedStoragePercentage"
    [used3DPercentage]="used3DPercentage"
    [isOwner]="isOwner$ | async">
</content-item-list-banner>

<celum-simple-list [customItemClass]="getCardWrapperClasses()"
                   clear-selection-on-document-click
                   [selectionHandler]="listSelectionHandler"
                   [itemTemplate]="itemTemplate"
                   [items]="contentItems"
                   [hasMoreBottom]="loading || hasMoreBottom"
                   [trackByFn]="trackByFn"
                   (requestNextPage)="onFetchNextBatch()">

  <ng-template let-item #itemTemplate>
    <file-card *ngIf="item.entityType.id === 'File'; else folderView"
               [contentItem]="item"
               [canCreateTasksFromFiles]="canCreateTasksFromFiles$ | async"
               [withAlreadyLinkedCheck]="(currentViewContext$ | async) === uiViewContext.LINK_FILE_DIALOG"
               [isInSelection]="item.id | isSelected: selectedContentItems"
               [disableContextMenu]="disableContextMenu"
               [showToolbar]="showCardsToolbar"
               [task]="task"
               [selectionContainsFoldersWithRobots]='selectionContainsFoldersWithRobotsApplied$ | async'
               [isModerator]="isModerator$ | async"
               (click)="handleFileClicked(item, $event)">
    </file-card>
    <ng-template #folderView>
      <folder-card [contentItem]="item"
                   [isInSelection]="item.id | isSelected: selectedContentItems"
                   [selectionContainsFoldersWithRobots]='selectionContainsFoldersWithRobotsApplied$ | async'
                   [isModerator]="isModerator$ | async"
                   [showToolbar]="showCardsToolbar"
                   (click)="handleFolderClicked(item, $event)">
      </folder-card>
    </ng-template>
  </ng-template>
</celum-simple-list>

<div *ngIf="contentItems?.length < 1 && !hasMoreBottom && !loading"
     class="content-item-list_empty-page"
     data-cy="content-item-list-empty-page">
  <empty-page [config]="emptyPageConfig" class="content-item-list_empty-page"></empty-page>
</div>
