import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { CelumDialog, CelumDialogConfiguration, ColorConstants, IconConfiguration } from '@celum/common-components';
import { DataUtil } from '@celum/core';
import { selectCurrentWorkroomId } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { PermissionUtil } from '@celum/work/app/shared/util';

import {
  ConflictItem,
  isRobotConflictItem,
  isTaskConflictItem
} from './../../../../../../core/model/entities/content-item/content-item.model';
import { ContentItemService } from '../../../../../../core/api/content-item/content-item.service';
import { Roles } from '../../../../../../core/model';
import {
  ConflictingContentItem,
  ContentItem
} from '../../../../../../core/model/entities/content-item/content-item.model';
import * as ContentItemActions from '../../../../../../files/store/content-item-list/content-item-list.actions';

@Component({
  selector: 'delete-items-dialog',
  templateUrl: './delete-items-dialog.component.html',
  styleUrls: ['./delete-items-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteItemsDialog implements OnInit, CelumDialog<DeleteItemsDialogConfiguration> {
  public color: string;
  public items: string[];
  public conflictingContentItem: ConflictingContentItem;
  public loading = true;

  public warningIconConfig = IconConfiguration.xLarge('information', '', 64);
  public attentionIconConfig = IconConfiguration.small('attention');
  public fileIcon = IconConfiguration.small('icon-16-file');
  public folderIcon = IconConfiguration.small('file-explorer');
  public infoIcon = IconConfiguration.small('info-icon');
  public cancelIcon = IconConfiguration.medium('cancel-s').withColor('white');
  public confirmIcon = IconConfiguration.medium('check-m').withColor('white');

  constructor(
    private dialogRef: MatDialogRef<DeleteItemsDialog>,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private contentItemService: ContentItemService,
    private store: Store<any>,
    private actions$: Actions,
    private permissionUtil: PermissionUtil
  ) {}

  public get conflictItems(): ConflictItem[] {
    return [
      ...(this.conflictingContentItem?.contentItemTaskCount ?? []),
      ...(this.conflictingContentItem?.contentItemRobotCount ?? [])
    ];
  }

  public get isDeleteEnabled$(): Observable<boolean> {
    const conflictedFolders = this.conflictItems.filter(item => isRobotConflictItem(item));
    if (DataUtil.isEmpty(conflictedFolders)) {
      return of(true);
    }
    return this.permissionUtil.hasRoleForCurrentWorkroom(Roles.MODERATOR);
  }

  public ngOnInit(): void {
    this.store
      .select(selectCurrentWorkroomId)
      .pipe(
        take(1),
        switchMap(wrId => this.contentItemService.getConflictsForDelete(this.items, wrId))
      )
      .subscribe(conflictingContentItem => {
        this.conflictingContentItem = conflictingContentItem;
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public configure(configuration: DeleteItemsDialogConfiguration): void {
    this.color = configuration.color;
    this.items = configuration.items.map(item => item.id);
  }

  public deleteItems(): void {
    this.loading = true;
    this.store.dispatch(ContentItemActions.ContentItemSelected({ contentItems: [] }));
    this.store.dispatch(ContentItemActions.ContentItemDeleteContentItems({ contentItemIds: this.items }));
    this.actions$.pipe(ofType(ContentItemActions.ContentItemContentItemsDeleted)).subscribe(() => {
      this.loading = false;
      this.dialogRef.close({ confirmed: true });
    });
  }

  public getHeadline(): string {
    const { fileCount: files, folderCount: folders } = this.conflictingContentItem;

    if (folders && files) {
      return this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.HEADLINE_ITEMS');
    }

    if (folders) {
      return folders > 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.HEADLINE_FOLDERS')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.HEADLINE_FOLDER');
    }

    if (files) {
      return files > 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.HEADLINE_FILES')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.HEADLINE_FILE');
    }

    return this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.HEADLINE_CONTENT');
  }

  public getQuestionText(): string {
    const { fileCount: files, folderCount: folders } = this.conflictingContentItem;

    if (folders === 0 && files > 0) {
      return files === 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.QUESTION_FILE')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.QUESTION_FILES', { n: files });
    }

    if (folders > 0 && files === 0) {
      return folders === 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.QUESTION_FOLDER')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.QUESTION_FOLDERS', { n: folders });
    }

    if (files === 1) {
      return folders === 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.QUESTION_FILE_AND_FOLDER')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.QUESTION_FILE_AND_FOLDERS', { n: folders });
    }

    return folders === 1
      ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.QUESTION_FILES_AND_FOLDER', { n: files })
      : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.QUESTION_FILES_AND_FOLDERS', {
          n: files,
          m: folders
        });
  }

  public getConflictHeader(conflictItems: ConflictItem[]): string {
    const files = conflictItems.filter(item => isTaskConflictItem(item)).length;
    const folders = conflictItems.filter(item => isRobotConflictItem(item)).length;

    if (folders === 0 && files > 0) {
      return files === 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_HEADER_FILE')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_HEADER_FILES', { n: files });
    }

    if (files === 0 && folders > 0) {
      return folders === 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_HEADER_FOLDER')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_HEADER_FOLDERS', {
            n: folders
          });
    }

    return this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_HEADER_ITEMS', {
      n: files + folders
    });
  }

  public getConflictLabel(conflictItem: ConflictItem): string {
    if (isTaskConflictItem(conflictItem)) {
      return conflictItem.taskCount === 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_LABEL_TASK')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_LABEL_TASKS', {
            n: conflictItem.taskCount
          });
    }

    return conflictItem.robotCount === 1
      ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_LABEL_ROBOT')
      : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_LABEL_ROBOTS', {
          n: conflictItem.robotCount
        });
  }

  public getConflictTooltip(conflictItems: ConflictItem[]): string {
    const files = conflictItems.filter(item => isTaskConflictItem(item)).length;
    const folders = conflictItems.filter(item => isRobotConflictItem(item)).length;

    if (folders === 0 && files > 0) {
      return files === 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_TOOLTIP_FILE')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_TOOLTIP_FILES');
    }

    if (files === 0 && folders > 0) {
      return folders === 1
        ? this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_TOOLTIP_FOLDER')
        : this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_TOOLTIP_FOLDERS');
    }

    return this.translateService.instant('CONTENT_ITEMS.DIALOG.DELETE.INFO_CONFLICT_TOOLTIP_ITEMS');
  }

  public getContentItemIcon(conflictItem: ConflictItem): IconConfiguration {
    return isTaskConflictItem(conflictItem) ? this.fileIcon : this.folderIcon;
  }

  public trackByFn(index, item: ConflictItem): boolean {
    return item.contentItemName || index;
  }
}

export class DeleteItemsDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.WARNING;

  constructor(public items: ContentItem[]) {
    super('main');
  }
}
