import { createAction, props } from '@ngrx/store';

import { Activity } from './activity.model';

/**
 * By default, all properties are updated!
 */
export const ActivityUpsertOne = createAction(
  '[Activity] Upsert One',
  props<{ activity: Activity; propertiesToUpdate?: string[] }>()
);

/**
 * By default, all properties are updated!
 */
export const ActivityUpsertMany = createAction(
  '[Activity] Upsert Many',
  props<{ activities: Activity[]; propertiesToUpdate?: string[] }>()
);

export const ActivityDeleteOne = createAction('[Activity] Delete One', props<{ activity: Activity }>());

export const ActivitySelectOne = createAction('[Activity] Select One', props<{ activity: Activity }>());

export const ActivityRemoveSelection = createAction('[Activity] Remove selection');
