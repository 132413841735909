import { Pipe, PipeTransform } from '@angular/core';

import { InvitationStatus } from '../../../../../core/model/entities/member';

@Pipe({
  name: 'multilinePersonTemplateUserStatus'
})
export class MultilinePersonTemplateUserStatusPipe implements PipeTransform {
  public transform(invitationStatus: InvitationStatus): string {
    if (invitationStatus) {
      if (invitationStatus === InvitationStatus.APPROVED || invitationStatus === InvitationStatus.ACCEPTED) {
        return 'PEOPLE.INVITE.APPROVED_USER';
      }
      if (invitationStatus === InvitationStatus.PENDING_APPROVAL) {
        return 'PEOPLE.INVITE.PENDING_USER';
      } else {
        return 'PEOPLE.INVITE.NEW_USER';
      }
    } else {
      return 'PEOPLE.INVITE.NEW_USER';
    }
  }
}
