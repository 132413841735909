import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RobotNotificationSnackbarGroupComponent } from './robot-notification-snackbar-group.component';
import { SharedModule } from '../../shared';
import { SnackbarGroupModule } from '../../shared/components/snackbar-group/snackbar-group.module';

@NgModule({
  imports: [CommonModule, SharedModule, SnackbarGroupModule],
  declarations: [RobotNotificationSnackbarGroupComponent],
  exports: [RobotNotificationSnackbarGroupComponent]
})
export class RobotNotificationSnackbarGroupModule {}
