<task-lists-configuration-board
    [template]="template"
    [repositoryId]="repositoryId"
    [permissionCheckStrategy]="permissionCheckStrategy"
    [taskListBodyTemplate]="taskListBodyTemplate">
</task-lists-configuration-board>

<ng-template #taskListBodyTemplate let-index="index" let-taskList="taskList">
  <mat-accordion [multi]="true" class="task-list-accordion">
    <mat-expansion-panel class="task-list-accordion_panel" data-content="general" [expanded]="true" [hideToggle]="true" [disabled]="true">
      <div class="task-list-accordion_panel_section">
        <div class="task-list-accordion_panel_title">{{ 'WORKROOM_WIZARD.DIALOG.DUE_DATE.TASK_LIST' | translate }}</div>
        <due-date
            (dueDateChange)="handleDueDateUpdated($event, taskList)"
            [class.due-date]="!!taskList.dueDate"
            [attr.data-due-date]="taskList.dueDate"
            [backgroundColor]="dateBgColor"
            [dueDateParentComponent]="'WORKROOM'"
            [dueDate]="taskList.dueDate"
            [hasPermission]="permissionCheckStrategy.canUpdate(taskList, true) | async">
        </due-date>
      </div>

      <ng-container *ngIf="(workroomWizardPeopleService.people$ | async).length > 0">
        <div class="task-list-accordion_panel_section">
          <div class="task-list-accordion_panel_title">{{ 'TASK_LIST_OWNERS.HEADLINE' | translate }}</div>
          <task-list-owners
              [teamspaceId]="teamspaceId"
              [filteredPeople]="nonInactivePeople$ | async"
              [workroomContributors]="allContributors$ | async"
              [owners]="getOwners(taskList) | async"
              [canRemoveTaskListOwners]="true"
              [canAddTaskListOwners]="true"
              [taskList]="taskList"
              [maxDisplayedOwners]="3"
              [darkOverflowBackground]="true"
              [inviteAndAssignFn]="inviteAndAddOwners.bind(this, taskList)"
              [canInviteAndAssign]="(currentWorkroomId$ | async) === null || (isModerator$ | async)"
              [canInviteAndAssignOrganisationMembersOnly]="targetEntity === 'TEMPLATE'"
              [showMessageFieldOnInvitationDialog]="targetEntity !== 'TEMPLATE'"
              [avatarDecorator]="avatarDecorator$"
              (addOwner)="addOwner($event, taskList)"
              (removeOwner)="removeOwner($event, taskList)"
              (searchPeople)="searchPeople.next($event)">
          </task-list-owners>
        </div>
      </ng-container>

    </mat-expansion-panel>

    <mat-expansion-panel class="task-list-accordion_panel robot-panel-scrollable" data-content="automator" [expanded]="true" [hideToggle]="true" [disabled]="true">
      <mat-expansion-panel-header class="task-list-accordion_header" collapsedHeight="2.4rem" expandedHeight="2.4rem">
        <mat-panel-title *ngIf="index === 0">{{ 'WORKROOM_WIZARD.DIALOG.STEP_SECTIONS.TASKBOARD.TASKLIST_ROBOT_TITLE' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>

      <div>
        <robot-menu
            [source]="taskList"
            [workroomConfig]="template.workroomConfiguration"
            [templatePeople]="templatePeople$ | async"
            [teamspaceId]="teamspaceId"
            [repositoryId]="repositoryId"
            [robotContext]="robotContextType.TASK_LIST"
            (createRobot)="openCreateRobotDialog($event)">
        </robot-menu>
        <robot-chip
            *ngFor="let robot of template.workroomConfiguration | map : getRobots : taskList; let i = index; trackBy: trackByFn"
            [attr.data-cy]="'robot-chip-' + i + '-'+ robot.type"
            [style.display]="(robotVisible(robot.type) | async) ? '' : 'none'"
            [robot]="robot"
            [workroomConfig]="template.workroomConfiguration"
            [templatePeople]="templatePeople$ | async"
            [teamspaceId]="teamspaceId"
            [workroomId]="currentWorkroomId$ | async"
            [repositoryId]="repositoryId"
            [sourceEntity]="taskList"
            [editable]="robot | map : editable"
            (editRobot)="openEditRobotDialog($event)"
            (deleteRobot)="deleteRobotFromTemplate(robot)">
        </robot-chip>
      </div>

    </mat-expansion-panel>
  </mat-accordion>

</ng-template>


