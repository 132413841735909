import { createSelector } from '@ngrx/store';

import { contributorAdapter } from './contributor.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = contributorAdapter.getSelectors();

export const selectContributors = createSelector(selectEntitiesState, (state: EntitiesState) => state.contributors);

export const selectAllContributors = createSelector(selectContributors, selectAll);
export const selectContributorEntities = createSelector(selectContributors, selectEntities);

export const selectContributorsByIds = (ids: string[]) =>
  createSelector(selectContributorEntities, contributors =>
    ids.map(id => contributors[id]).filter(contributor => !!contributor)
  );

export const selectContributorByUserIdAndWorkroomId = (userId: number, workroomId: number) =>
  createSelector(selectAllContributors, contributors =>
    contributors.find(contributor => contributor.personId === userId && contributor.workroomId === workroomId)
  );
