import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { selectImportIntentById } from '@celum/work/app/content-hub/store/import-intent.selector';
import { TenantGuard } from '@celum/work/app/core/auth/tenant.guard';
import { Folder } from '@celum/work/app/core/model/entities/folder/folder.model';
import { selectRouterQueryParam } from '@celum/work/app/core/router/router.selectors';

import { WatchCollectionsAutomatorComponent } from './watch-collections-automator.component';
import {
  Automator,
  AutomatorType,
  ContentHubAssetAddedTrigger,
  isAutomator,
  RobotActionType,
  RobotContext,
  Robots
} from '../../../../core/model/entities/workroom/robot.model';
import { RobotChipConfig } from '../../../services/robots-factory';
import { BaseRobotConfigService, RobotNotAppliedConfig } from '../base-robot/base-robot-config.service';

@Injectable()
export class WatchCollectionsAutomatorConfigService extends BaseRobotConfigService {
  constructor(
    public translate: TranslateService,
    private store: Store<any>
  ) {
    super(translate);
  }

  public robotBelongsToFolder(robot: Robots, folder: Folder) {
    return ((robot as Automator).trigger as ContentHubAssetAddedTrigger).collectionFolderPairs.some(
      collectionFolderPair => collectionFolderPair.folderId === folder.id
    );
  }

  public serviceMatchesRobot(robot: Robots): boolean {
    return robot.type === AutomatorType.CONTENT_HUB && robot.sourceContext === RobotContext.CONTENT_ITEM;
  }

  public getRobotChipConfig(robot: Automator, sourceEntity?: any): Observable<Partial<RobotChipConfig>> {
    const rows = this.getContentHubRows(robot as Automator);
    const contentHubFolderIcon = IconConfiguration.large('icon-content-hub-folder').withIconSize(16);
    return of({
      icon: contentHubFolderIcon,
      row1: rows[0],
      row2: rows[1],
      editEvent: {
        robot,
        type: WatchCollectionsAutomatorComponent
      }
    });
  }

  public isRobotNotApplied(params: RobotNotAppliedConfig): Observable<boolean> {
    const { robot, isRobotBeingCreated } = params;

    if (isAutomator(robot) && robot.action.type === RobotActionType.CONTENT_HUB_IMPORT_ASSET && !isRobotBeingCreated) {
      return this.store.select(selectRouterQueryParam(TenantGuard.IMPORT_INTENT_PARAM)).pipe(
        take(1),
        switchMap(importIntentId => this.store.select(selectImportIntentById(importIntentId))),
        map(importIntent => {
          if (importIntent && importIntent.collectionCounter > 0) {
            return false;
          }

          const collectionPairs = (robot.trigger as ContentHubAssetAddedTrigger).collectionFolderPairs;
          return collectionPairs.length === 0;
        })
      );
    }

    return of(false);
  }
}
