import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, withLatestFrom } from 'rxjs/operators';

import {
  AutomatorType,
  RobotActionType,
  TaskAddAssigneesAction
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import { getTaskListAutomatorsOfType } from '@celum/work/app/robots/services/robots-util';
import { ReactiveService } from '@celum/work/app/shared/util/reactive-service';
import { WorkroomWizardEvent } from '@celum/work/app/workroom-wizard/model/workroom-wizard-event';

import { WorkroomWizardPeopleService } from './workroom-wizard-people.service';
import { ApplicationEvent, ApplicationEventBus } from '../../shared/util/application-event-bus.service';
import { WorkroomWizardData } from '../components/workroom-wizard.component';

@Injectable()
export class TaskListEventListenerService extends ReactiveService {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: WorkroomWizardData,
    private eventBus: ApplicationEventBus,
    private workroomWizardPeopleService: WorkroomWizardPeopleService
  ) {
    super();
    this.removeOwnersOnPeopleRemoved();
    this.updateTaskAssignmentAutomatorOnTLOChanges();
  }

  private removeOwnersOnPeopleRemoved() {
    this.eventBus
      .observeEvents(this.unsubscribe$, WorkroomWizardEvent.PEOPLE_REMOVED)
      .pipe(withLatestFrom(this.workroomWizardPeopleService.people$))
      .subscribe(([_, people]) => {
        const personIds = people.map(({ id }) => id);
        this.data.template.taskLists.forEach(taskList => {
          taskList.ownerIds = taskList.ownerIds.filter(ownerId => personIds.includes(ownerId));
        });
      });
  }

  private updateTaskAssignmentAutomatorOnTLOChanges() {
    this.eventBus
      .observeEvents<ApplicationEvent>(
        this.unsubscribe$,
        WorkroomWizardEvent.TLO_ADDED,
        WorkroomWizardEvent.TLO_REMOVED
      )
      .pipe(
        filter(({ data }) =>
          getTaskListAutomatorsOfType(
            this.data.template.workroomConfiguration,
            data.taskListId,
            AutomatorType.TASK_ASSIGNMENT
          ).some(({ action }) => action.type === RobotActionType.TASK_ADD_ASSIGNEES)
        )
      )
      .subscribe(({ data, type }) => {
        const automator = getTaskListAutomatorsOfType(
          this.data.template.workroomConfiguration,
          data.taskListId,
          AutomatorType.TASK_ASSIGNMENT
        )
          .filter(({ action }) => action.type === RobotActionType.TASK_ADD_ASSIGNEES)
          .pop();

        const taskAddAssigneesAction = automator.action as TaskAddAssigneesAction;
        if (!taskAddAssigneesAction.allTaskListOwners) {
          return;
        }

        if (type === WorkroomWizardEvent.TLO_ADDED) {
          taskAddAssigneesAction.personIds.push(+data.personId);
        } else {
          taskAddAssigneesAction.personIds = taskAddAssigneesAction.personIds.filter(
            personId => personId !== +data.personId
          );
        }
      });
  }
}
