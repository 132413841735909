import { createAction, props } from '@ngrx/store';

import { PaginationResult } from '@celum/core';
import { IncludeExcludeFilter } from '@celum/work/app/collaboration-properties/components/activity/activity.constants';

import { CollaborationPropertySearchType } from '../../core/api/collaboration-item/collaboration-property.service';

export enum CollaborationItemsContext {
  FILE_DETAIL,
  TASK_DETAIL,
  TASK_DETAIL_CONTENT
}

export const CollaborationPropertiesLoad = createAction(
  '[CollaborationProperties] Load collaboration properties',
  props<{
    parentId?: string;
    objectIdsInContext: IncludeExcludeFilter;
    offset: number;
    collaborationItemsCtx: CollaborationItemsContext;
    fileVersionIds?: string[];
  }>()
);

export const CollaborationPropertiesLoaded = createAction(
  '[CollaborationProperties] Collaboration properties loaded',
  props<{
    parentId: string;
    paginationResult: PaginationResult;
    collaborationPropertyIds: number[];
    resetListState: boolean;
  }>()
);

export const CollaborationPropertiesLoadFailed = createAction(
  '[CollaborationProperties] Loading failed',
  props<{ parentId: string }>()
);

export const CollaborationPropertiesFetchNextBatch = createAction(
  '[CollaborationProperties] Fetch next batch',
  props<{
    parentId: string;
    objectIdsInContext: IncludeExcludeFilter;
    collaborationItemsCtx: CollaborationItemsContext;
  }>()
);

export const CollaborationPropertiesChangeBatchSize = createAction(
  '[CollaborationProperties] Change batch size',
  props<{
    batchSize: number;
    parentId: string;
    objectIdsInContext: IncludeExcludeFilter;
    collaborationItemsCtx: CollaborationItemsContext;
  }>()
);

export const SetCollaborationSearchType = createAction(
  '[CollaborationProperties] Set collaboration search type',
  props<{ searchType: CollaborationPropertySearchType }>()
);

export const RefreshCurrentTask = createAction('[TaskDetail] Refresh current task');

export const RefreshCurrentTaskSubtasks = createAction('[TaskDetail] Refresh current task subtasks');
export const RefreshCurrentTaskActivities = createAction('[TaskDetail] Refresh current task activities');
export const RefreshCurrentFileActivities = createAction('[CollaborationProperties] Refresh current file activities');
export const RefreshCurrentFileTasks = createAction('[FileDetail] Refresh current file tasks');
export const RefreshCurrentTaskForm = createAction('[TaskDetail] Refresh current task form');
export const RefreshTaskForm = createAction(
  '[TaskDetail] Refresh task form',
  props<{ taskId: number; formId: number }>()
);
