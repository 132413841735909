<celum-dialog (onCloseAll)="closeDialog()" class="robot-dialog" [ngClass]="component?.instance?.colorSchemeClass" data-cy="robot-dialog">
  <celum-dialog-header>
    <span>{{ title | translate }}</span>
  </celum-dialog-header>
  <celum-dialog-content class="robot-dialog_content">
    <section>
      <section #anchor></section>
    </section>
  </celum-dialog-content>
  <celum-dialog-footer
      [confirmBtnText]="confirmationText"
      cancelBtnText="COMMON.CANCEL"
      [valid]="valid"
      [color]="color"
      (onConfirm)="saveDialog()"
      (onCancel)="closeDialog()">
  </celum-dialog-footer>
</celum-dialog>


