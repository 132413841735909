import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApplicationInsightsService } from '@celum/work/app/core/application-insights.service';
import { environment } from '@celum/work/env/environment';

import { CommunicationException } from '../communication/communication-exception';
import { NotificationService } from '../notifications/notification.service';

/**
 * Error handler for executing correct actions on error.
 */
@Injectable()
export class FailureHandler {
  constructor(
    private injector: Injector,
    private appInsights: ApplicationInsightsService
  ) {}

  // TODO ton IMO this failure handler is useless
  //  - errors coming from outside (http error responses) are handler by error interceptor
  //  - errors thrown within app are handled by global ErrorHandler (if not caught by anything else)
  //  - therefore both ways are covered so I think this service doesn't serve any purpose, and calling it in catchError operator
  //    in effects is useless because of the error interceptor
  public handleError(error: Error | CommunicationException) {
    // HttpErrorResponse is handler by ErrorInterceptor
    if (error instanceof HttpErrorResponse) {
      return;
    }

    const translateService = this.injector.get(TranslateService);
    let displayMessage = '';

    if (error instanceof CommunicationException) {
      const msg = translateService.instant(error.messageKey);
      displayMessage = msg === error.messageKey ? translateService.instant('WORK_ERROR.COMMUNICATION') : msg;
      console.error(msg, error.stack);
    } else {
      // Client Error
      displayMessage = translateService.instant('WORK_ERROR.CLIENT');

      const msg = error.message ? error.message : error.toString();
      const stackTrace = error.stack;
      console.error(msg, stackTrace);
    }

    if (
      this.isError(error, 'celum_cif_core') || // annotation app; no consequences
      this.isError(error, 'spreadToCanvasCoordinateOffset') || // annotation app; no consequences
      this.isError(error, 'userpilot') || // userpilot; no consequences
      this.isError(error, 'AbortError: The user aborted a request') || // annotation app; no consequences
      this.isError(error, 'Error: Worker was destroyed') // annotation app; no consequences
    ) {
      return;
    }

    this.appInsights.logError(error);

    if (environment.production) {
      return;
    }

    const notifier = this.injector.get(NotificationService);
    notifier.error(displayMessage);
  }

  public hasErrorKey(err: Error, errKey: string) {
    return err instanceof HttpErrorResponse && err?.error?.errorKey === errKey;
  }

  private isError(error: Error, token: string): boolean {
    return (
      (error.message?.includes && error.message?.includes(token)) ||
      (error.stack?.includes && error.stack?.includes(token))
    );
  }
}
