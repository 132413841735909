import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';
import { ContentHubPaging, ContentHubSort } from '@celum/work/app/content-hub/model/content-hub.model';
import { File } from '@celum/work/app/core/model/entities/file/file.model';
import { ListResult } from '@celum/work/app/core/model/list-result';

import { AbstractContentHubService } from './abstract-content-hub.service';

@Injectable({ providedIn: 'root' })
export class AssetService extends AbstractContentHubService {
  constructor(private http: HttpClient) {
    super();
  }

  public getAssets(repositoryId: string, requestParams: GetAssetsRequestParams): Observable<ListResult<File>> {
    const body: any = {
      ...GetAssetsRequestDTO.from(requestParams)
    };

    return this.http
      .post<
        ListResult<any>
      >(`${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content-hub/assets?repositoryId=${repositoryId}`, body)
      .pipe(
        map(res => ({
          ...res,
          results: this.processFilesResult(res.results)
        }))
      );
  }
}

type SearchType = 'STARTS_WITH' | 'ENDS_WITH' | 'EXACT' | 'ANY' | 'WITHOUT';

export interface GetAssetsRequestParams {
  parentId?: string;
  searchTerm?: string;
  nodeTypeId?: number;
  recursive: boolean;
  pageParams: ContentHubPaging;
  sort?: ContentHubSort;
}

class GetAssetsRequestDTO implements ContentHubPaging {
  public readonly nodeId: string;
  public readonly searchTerm: string;
  public readonly searchType: SearchType = 'ANY';
  public readonly recursive: boolean;
  public readonly nodeTypeId: number;
  public readonly sortBy: string;
  public readonly sort: 'ASC' | 'DESC';
  public readonly page: number;
  public readonly pageSize: number;

  constructor(params: GetAssetsRequestParams) {
    this.nodeId = params.parentId;
    this.searchTerm = params.searchTerm;
    this.nodeTypeId = params.nodeTypeId;
    this.recursive = params.recursive;
    this.sortBy = params.sort?.field;
    this.sort = params.sort?.direction;
    this.page = params.pageParams.page;
    this.pageSize = params.pageParams.pageSize;
  }

  public static from(params: GetAssetsRequestParams): Partial<GetAssetsRequestDTO> {
    const requestDTO = new GetAssetsRequestDTO(params);
    // Have to filter out also searchTerm='' because CH won't give any results for it for some reason
    return pickBy(requestDTO, v => v !== undefined && v !== '');
  }
}
