<celum-dialog *ngIf="quotaTypes | contactDialogTranslationsPipe: accountAccess.ownerName; let headlineAndText">
  <celum-dialog-header>
    <span>{{ headlineAndText.headline }}</span>
  </celum-dialog-header>

  <celum-dialog-content class="contact-form-dialog">
    <div class="contact-form-dialog_account-owner_avatar">
      <celum-avatar [configuration]="accountOwnerAvatarConfig"></celum-avatar>
    </div>
    <div class="contact-form-dialog_account-owner_details">
      <div class="contact-form-dialog_account-owner_details_name">{{ accountAccess.ownerName | uppercase }}</div>
      <div class="contact-form-dialog_account-owner_details_email">{{ accountAccess.ownerEmail}}</div>
      <span class="contact-form-dialog_info">{{ headlineAndText.text }}</span>
    </div>
  </celum-dialog-content>

  <celum-dialog-footer confirmBtnText="TEMPLATE_CHOOSER.LICENSE_LIMIT.CONTACT.FORM.CONFIRM"
                       [color]="color"
                       (onConfirm)="confirm()"
                       (onCancel)="cancel()">
  </celum-dialog-footer>
</celum-dialog>
