import { Injectable } from '@angular/core';

const STATE_APP_PREFIX = 'WR-STATE-';
const APP_PREFIX = 'WR-';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(STATE_APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(STATE_APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map(key =>
            key
              .split('-')
              .map((token, index) => (index === 0 ? token : token.charAt(0).toUpperCase() + token.slice(1)))
              .join('')
          );

        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  public setItem(key: string, value: any, state = false) {
    localStorage.setItem(`${state ? STATE_APP_PREFIX : APP_PREFIX}${key}`, JSON.stringify(value));
  }

  public getItem<T>(key: string, state = false): T {
    return JSON.parse(localStorage.getItem(`${state ? STATE_APP_PREFIX : APP_PREFIX}${key}`));
  }

  public setWithExpiry<T>(key: string, value: T, ttl: number): void {
    const now = new Date();

    const item: StoredEntityWithExpiry<T> = {
      value,
      expiry: now.getTime() + ttl
    };

    this.setItem(key, item);
  }

  public getWithExpiry<T>(key: string): T {
    const comment = this.getItem<StoredEntityWithExpiry<T>>(key);

    if (!comment) {
      return null;
    }

    if (typeof comment === 'string') {
      return comment;
    }

    const now = new Date();
    if (now.getTime() > comment.expiry) {
      this.removeItem(key);
      return null;
    }

    return comment.value;
  }

  public removeItem(key: string, state = false) {
    localStorage.removeItem(`${state ? STATE_APP_PREFIX : APP_PREFIX}${key}`);
  }

  public clear(): void {
    Object.keys(localStorage)
      .filter(key => key.includes(APP_PREFIX))
      .map(key => key.replace(APP_PREFIX, ''))
      .forEach(key => this.removeItem(key));
  }

  /** Tests that localStorage exists, can be written to, and read from. */
  public testLocalStorage() {
    const testValue = 'testValue';
    const testKey = 'testKey';
    const errorMessage = 'localStorage did not return expected value';

    this.setItem(testKey, testValue);
    const retrievedValue = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }
}

export interface StoredEntityWithExpiry<T> {
  value: T;
  expiry: number;
}
