import { createAction, props } from '@ngrx/store';

import { PaginationResult } from '@celum/core';

import { Folder } from '../../../core/model/entities/folder/folder.model';

export const FilesTreeLoadSubfolders = createAction(
  '[FilesTree] Load subfolders',
  props<{ offset: number; parentId: string; limit: number; treeId: string }>()
);

export const FilesTreeSubfoldersLoaded = createAction(
  '[FilesTree] Subfolders Loaded',
  props<{
    parentId: string;
    offset: number;
    limit: number;
    folders: Folder[];
    paginationResult: PaginationResult;
    treeId: string;
  }>()
);

export const FilesTreeFetchNextBatch = createAction(
  '[FilesTree] Fetch Next Batch',
  props<{ parentId: string; offset: number; treeId: string }>()
);

export const FilesTreeDeeplinkSearch = createAction('[FilesTree] Load Deeplinks', props<{ objectId: string }>());
