import { NgModule } from '@angular/core';

import { SharedModule } from '@celum/work/app/shared';

import { WorkroomIconComponent } from './workroom-icon.component';

@NgModule({
  declarations: [WorkroomIconComponent],
  exports: [WorkroomIconComponent],
  imports: [SharedModule]
})
export class WorkroomIconModule {}
