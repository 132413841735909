import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractWebsocketWatcher } from './watcher/abstract-websocket-watcher';

@Injectable()
export abstract class AbstractWebsocketListener {
  protected constructor(public websocketWatcher: AbstractWebsocketWatcher) {}

  protected watchUserTopic<T>(destination: string): Observable<T> {
    return this.websocketWatcher.watchUserTopic(`${destination}`);
  }

  protected watchTopic<T>(destination: string): Observable<T> {
    return this.websocketWatcher.watchTopic(`${destination}`);
  }
}
