import { Dictionary } from '@ngrx/entity';
import { map, Observable } from 'rxjs';

import { Person } from './person.model';
import { Roles } from '../../roles.model';
import { Contributor } from '../contributor';
import { Task, TaskList } from '../task';
import { Workroom } from '../workroom';

export interface PersonWithRoles {
  person: Person;
  roles: Roles[];
}

export function mapToPersonWithRoles([person, contributor$]: [
  Person,
  Observable<Contributor>
]): Observable<PersonWithRoles> {
  return contributor$.pipe(map(contributor => ({ person, roles: contributor?.roles ?? [] })));
}

export function filterBySearchString(queryString: string, persons: Person[]): Person[] {
  const trimmedString = queryString.trim().toLowerCase();
  return persons.filter(
    ({ email, firstName, lastName }) =>
      email.toLowerCase().indexOf(trimmedString) !== -1 ||
      `${firstName?.toLowerCase()} ${lastName?.toLowerCase()}`.indexOf(trimmedString) !== -1
  );
}

export function filterByVisitorMentionRestriction(
  currWorkroom: Workroom,
  contributors: Dictionary<Contributor>,
  persons: Person[],
  currentTask: Task,
  currentTaskList: TaskList
): Person[] {
  return persons.filter(person => {
    const isModerator = contributors[`${currWorkroom.id}_${person.id}`]?.roles.includes(Roles.MODERATOR);
    const isTaskAssignee = currentTask?.assigneeIds.includes(person.id);
    const isTaskCreator = currentTask?.createdBy === person.id;
    const isTaskListOwner = currentTaskList?.ownerIds.includes(person.id);

    return isModerator || isTaskAssignee || isTaskCreator || isTaskListOwner;
  });
}

export function isNewUser(person: Person): boolean {
  return !person?.firstName && !person?.lastName;
}
