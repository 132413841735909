<div class="workroom-template" *ngIf="template" [class.disabled]="!isAllowedToCreate" (click)="createWorkroomFromTemplate($event)">
  <div class="workroom-template_header template-header">
    <div class="template-header_name">{{ template.name | translate }}</div>
    <div class="workroom-template_header-actions">
      <button *ngIf="isAllowedToUpdate"
          mat-button celum-square-button
          class="workroom-template_header-actions_item"
          [matTooltip]="'TEMPLATE_CHOOSER.ACTIONS_MENU.EDIT' | translate"
          (click)="editTemplate($event)">
        <celum-icon [configuration]="menuActionsIcon.EDIT" [attr.data-cy]="'template-chooser-edit-template-' + template.id"></celum-icon>
      </button>
      <button
          mat-button celum-square-button
          class="workroom-template_header-actions_item"
          [matTooltip]="'TEMPLATE_CHOOSER.ACTIONS_MENU.COPY' | translate"
          (click)="copyTemplate($event)">
        <celum-icon [configuration]="menuActionsIcon.COPY" [attr.data-cy]="'template-chooser-copy-template-' + template.id"></celum-icon>
      </button>
      <button *ngIf="isAllowedToUpdate && canCreateTemplate$ | async"
          mat-button celum-square-button
          class="workroom-template_header-actions_item"
          [matTooltip]="'TEMPLATE_CHOOSER.ACTIONS_MENU.MOVE' | translate"
          (click)="moveTemplate($event)">
        <celum-icon [configuration]="menuActionsIcon.MOVE" [attr.data-cy]="'template-chooser-move-template-' + template.id"></celum-icon>
      </button>
      <button *ngIf="isAllowedToDelete"
          mat-button celum-square-button
          class="workroom-template_header-actions_item"
          [matTooltip]="'TEMPLATE_CHOOSER.ACTIONS_MENU.DELETE' | translate"
          (click)="deleteTemplate($event)">
        <celum-icon [configuration]="menuActionsIcon.DELETE" [attr.data-cy]="'template-chooser-delete-template-' + template.id"></celum-icon>
      </button>
    </div>
  </div>
  <div class="workroom-template_body">
    <div class="workroom-template_task-columns template-task-columns">
      <div *ngFor="let taskList of template?.taskLists; trackBy: trackByFn"
           [ngStyle]="{ 'background-color': getRGBHex(taskList.color) }"
           class="template-task-columns_column">
        <text-truncator [text]="taskList.name"></text-truncator>
      </div>
    </div>
    <div class="workroom-template_description">
      <p *ngIf="template.categoryId === PredefinedCategoryValues.CELUM_TEMPLATE_CATEGORY_ID; else formattedDescription" class="workroom-template_description">{{ template.description | translate }}</p>
      <ng-template #formattedDescription>
        <celum-quill-editor type="other" [jsonString]="template.description" [readonly]="true" (click)="createWorkroomFromTemplate($event)" placeholder=""></celum-quill-editor>
      </ng-template>
    </div>
  </div>
</div>
