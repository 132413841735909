<celum-dialog class="delete-items-dialog"
              (onCloseAll)="closeDialog()">
  <celum-dialog-content>
    <loading-mask *ngIf="loading; else content"></loading-mask>
    <ng-template #content>
      <div class="delete-items-dialog_delete">
        <celum-icon [configuration]="warningIconConfig" [clickable]="false"></celum-icon>
      </div>

      <div class="delete-items-dialog_content">
        <div class="delete-items-dialog_headline">{{ getHeadline() }}</div>
        <div class="delete-items-dialog_name">{{ getQuestionText() }}</div>
      </div>

      <div *ngIf="conflictItems.length > 0">
        <div class="delete-items-dialog_conflict">
          <div class="delete-items-dialog_conflict_header">
            <span>{{ getConflictHeader(conflictItems) }}</span>
            <celum-icon [configuration]="infoIcon" [matTooltip]="getConflictTooltip(conflictItems)"></celum-icon>
          </div>
          <div class="delete-items-dialog_conflict_line-separator"></div>
          <div class="delete-items-dialog_scroll flex-column-scrollbar celum-scrollbar">
            <div class="delete-items-dialog_wrapper" *ngFor="let item of conflictItems; trackBy: trackByFn">
              <celum-icon [configuration]="getContentItemIcon(item)" [clickable]="false"></celum-icon>
              <text-truncator class="delete-items-dialog_conflict_value" [text]="item.contentItemName"></text-truncator>
              <div class="delete-items-dialog_conflict_label">{{ getConflictLabel(item) }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </celum-dialog-content>
  <celum-dialog-footer [color]="color">
    <button class="delete-items-dialog_footer_button delete-items-dialog_footer_button--dark"
            data-test-dialog-footer-cancel-button
            [disableRipple]="true"
            mat-button
            (click)="closeDialog()">
      <celum-icon [clickable]="true" [configuration]="cancelIcon"></celum-icon>
      {{ 'COMMON.CANCEL' | translate | uppercase}}
    </button>
    <button class="delete-items-dialog_footer_button"
            data-test-dialog-footer-confirm-button
            [clickDisabled]="(isDeleteEnabled$ | async) === false || loading"
            [disableRipple]="true"
            [matTooltip]="'FOLDER.DIALOG.DELETE.TOOLTIP' | translate"
            [matTooltipDisabled]="isDeleteEnabled$ | async"
            mat-button
            (configurableClick)="deleteItems()">
      <celum-icon [clickable]="isDeleteEnabled$ | async" [configuration]="confirmIcon" [class.loading]="loading"></celum-icon>
      {{ 'CONTENT_ITEMS.DIALOG.DELETE.CONFIRM' | translate | uppercase}}
    </button>
  </celum-dialog-footer>
</celum-dialog>
