import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { DataUtil, EntityType } from '@celum/core';
import {
  CollaborationProperty,
  CollaborationPropertyType
} from '@celum/work/app/core/model/entities/collaboration-property/collaboration-property.model';
import { File } from '@celum/work/app/core/model/entities/file/file.model';
import { Task, TaskList } from '@celum/work/app/core/model/entities/task';
import { Workroom } from '@celum/work/app/core/model/entities/workroom';

import { PersonType } from '../person/person.model';

export enum ItemContext {
  WORKROOM = 'WORKROOM',
  TASK = 'TASK',
  CONTENT_ITEM = 'CONTENT_ITEM',
  TASK_LIST = 'TASK_LIST'
}

export interface Activity extends CollaborationProperty {
  key: string;
  data: any;
  entityType: ActivityType;
  affected: number[] | null;
  object: (store: Store<any>) => Observable<Workroom | TaskList | Task | File>;
}

export class ActivityType implements EntityType {
  public static readonly TYPE_KEY: string = 'Activity';

  private static _instance: ActivityType;

  public id = ActivityType.TYPE_KEY;
  public inheritsFrom = new Set<CollaborationPropertyType>();

  public static instance(): ActivityType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(PersonType.TYPE_KEY)) {
        relationDefinitions.createdBy = PersonType.instance().getSchema({});
        relationDefinitions.changedBy = PersonType.instance().getSchema({});
      }
    }

    return new schema.Entity(ActivityType.TYPE_KEY, relationDefinitions);
  }
}

export interface ActivityState extends EntityState<Activity> {
  selectedId: number;
}
