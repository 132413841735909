import { createSelector } from '@ngrx/store';

import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';
import { taskAdapter } from '../task/task.reducer';

const { selectEntities, selectAll } = taskAdapter.getSelectors();

export const selectSubtasks = createSelector(selectEntitiesState, (state: EntitiesState) => state.tasks);

export const selectAllSubtasks = createSelector(selectSubtasks, selectAll);
export const selectSubtaskEntities = createSelector(selectSubtasks, selectEntities);

export const selectSubtaskById = (id: number) => createSelector(selectSubtaskEntities, subtasks => subtasks[id]);
