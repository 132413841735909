import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ProgressTaskActions } from '@celum/work/app/progress-task/store/progress-task.actions';
import {
  ProgressTask,
  ProgressTaskState,
  ProgressTaskStatus
} from '@celum/work/app/progress-task/store/progress-task.model';

export const progressTaskAdapter: EntityAdapter<ProgressTask<any, any>> = createEntityAdapter<ProgressTask<any, any>>();
export const initialState: ProgressTaskState = progressTaskAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(ProgressTaskActions.Upsert, (state, action) => progressTaskAdapter.upsertOne(action.progressTask, state)),
  on(ProgressTaskActions.UpsertMany, (state, action) => progressTaskAdapter.upsertMany(action.progressTasks, state)),
  on(ProgressTaskActions.UpdateOne, (state, action) => progressTaskAdapter.updateOne(action, state)),
  on(ProgressTaskActions.Cancel, (state, action) => progressTaskAdapter.removeOne(action.progressTask.id, state)),
  on(ProgressTaskActions.CloseSnackbarGroup, (state, { progressTaskType }) => {
    const progressTasksByType = Object.values(state.entities)
      .filter(({ type }) => type === progressTaskType)
      .map(({ id }) => id);
    return progressTaskAdapter.removeMany(progressTasksByType, state);
  }),
  on(ProgressTaskActions.Failed, (state, { progressTaskId, errorKey }) => {
    const update: Update<ProgressTask<any, any>> = {
      id: progressTaskId,
      changes: {
        errorKey,
        status: ProgressTaskStatus.FAILED
      }
    };
    return progressTaskAdapter.updateOne(update, state);
  }),
  on(ProgressTaskActions.Finished, (state, { progressTaskId, payload }) => {
    const update: Update<ProgressTask<any, any>> = {
      id: progressTaskId,
      changes: {
        payload,
        status: ProgressTaskStatus.FINISHED
      }
    };
    return progressTaskAdapter.updateOne(update, state);
  })
);

export function progressTaskStateReducer(state: ProgressTaskState | undefined, action: Action) {
  return reducer(state, action);
}
