import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ContentHubAutomatorService } from '@celum/work/app/robots/services/content-hub-automator.service';
import { RobotsFactory } from '@celum/work/app/robots/services/robots-factory';

import { TaskList } from '../../../../core/model/entities/task';
import { RobotContext, Rule, RuleType } from '../../../../core/model/entities/workroom/robot.model';
import { RobotDialogConfiguration } from '../../robot-dialog/robot-dialog-config';
import { BaseRobot } from '../base-robot/base-robot.component';

@Component({
  selector: 'create-restriction-rule',
  templateUrl: './create-restriction-rule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateRestrictionRuleComponent extends BaseRobot<TaskList> {
  public readonly TARGETING_ROBOT_TYPE = RuleType.TASK_CREATION_RESTRICTION;

  constructor(
    protected robotsFactory: RobotsFactory,
    protected automatorService: ContentHubAutomatorService,
    translateService: TranslateService
  ) {
    super(robotsFactory, translateService, automatorService);
  }

  public configure(configuration: RobotDialogConfiguration<TaskList>): void {
    super.configure(configuration);
  }

  public getResult(): Rule {
    return {
      type: RuleType.TASK_CREATION_RESTRICTION,
      sourceContext: RobotContext.TASK_LIST,
      sourceId: this.sourceEntity.id
    };
  }
}
