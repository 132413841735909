<div class="navigation-buttons_button navigation-buttons_previous-button"
     [class.navigation-buttons_button--hidden]="!hasPrevious || !enabled"
     (click)="previous.emit()">
  <celum-icon [configuration]="prevIcon"></celum-icon>
</div>
<div class="navigation-buttons_button navigation-buttons_next-button"
     [class.navigation-buttons_button--hidden]="!hasNext || !enabled"
     (click)="next.emit()">
  <celum-icon [configuration]="nextIcon"></celum-icon>
</div>
