import { createSelector } from '@ngrx/store';

import { Permission } from '@celum/work/app/core/api/permission';
import { selectTeamspaceEntities } from '@celum/work/app/core/model/entities/teamspace';
import { TemplateCategory } from '@celum/work/app/core/model/entities/template-category/template-category.model';
import { selectRouterQueryParam } from '@celum/work/app/core/router/router.selectors';

import { templateCategoryAdapter } from './template-category.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectAll, selectEntities } = templateCategoryAdapter.getSelectors();

export const TEMPLATE_CATEGORY_QUERY_PARAM = 'templateCategory';

export const selectTemplateCategories = createSelector(
  selectEntitiesState,
  (state: EntitiesState) => state.templateCategories
);

export const selectAllTemplateCategories = createSelector(selectTemplateCategories, selectAll);
export const selectAllTemplateCategoryEntities = createSelector(selectTemplateCategories, selectEntities);

export const selectActiveCategoryId = createSelector(
  selectRouterQueryParam(TEMPLATE_CATEGORY_QUERY_PARAM),
  param => param || 'all'
);

export const selectActiveCategory = createSelector(
  selectRouterQueryParam(TEMPLATE_CATEGORY_QUERY_PARAM),
  selectAllTemplateCategoryEntities,
  (param, categories) => categories[param]
);

export const selectCategoryById = (id: number) =>
  createSelector(selectAllTemplateCategoryEntities, category => category[id]);

export const selectCategoriesForPermission = createSelector(
  selectAllTemplateCategories,
  selectTeamspaceEntities,
  (categories: TemplateCategory[], teamspaces, props) =>
    categories?.filter(category => hasPermission(teamspaces, category.teamspaceId, props.permission))
);

export const selectCelumTemplateCategory = createSelector(selectAllTemplateCategories, categories =>
  categories.find(c => !c.editable)
);

export const selectHasPermissionInAnyTeamspace = (permission: Permission) =>
  createSelector(selectAllTemplateCategories, selectTeamspaceEntities, (categories, teamspaces) =>
    categories?.some(category => hasPermission(teamspaces, category.teamspaceId, permission))
  );

export const selectHasPermissionForTeamspace = createSelector(
  selectAllTemplateCategoryEntities,
  selectTeamspaceEntities,
  (categories, teamspaces, props) =>
    hasPermission(teamspaces, categories[props.categoryId]?.teamspaceId, props.permission)
);

export const selectIsCelumTemplatesActiveCategory = createSelector(
  selectCelumTemplateCategory,
  selectActiveCategoryId,
  (celumCategory, activeCategoryId) => celumCategory?.id === activeCategoryId
);

export const selectTeamspaceByCategoryId = (categoryId: number) =>
  createSelector(selectAllTemplateCategoryEntities, selectTeamspaceEntities, (categories, teamspaces) => {
    if (teamspaces && categories && categories[categoryId]) {
      return teamspaces[categories[categoryId].teamspaceId];
    }
    return null;
  });

function hasPermission(teamspaces: any, teampsaceId: number, permission: Permission): boolean {
  return teamspaces && teamspaces[teampsaceId]?.permissions?.permissions?.includes(permission.toString());
}
