import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ReactiveService } from '@celum/work/app/shared/util/reactive-service';
import { WorkroomWizardEvent } from '@celum/work/app/workroom-wizard/model/workroom-wizard-event';

import { AutomatorType } from '../../core/model/entities/workroom/robot.model';
import { ApplicationEventBus } from '../../shared/util/application-event-bus.service';
import { WorkroomWizardData } from '../components/workroom-wizard.component';

@Injectable()
export class RobotEventListenerService extends ReactiveService {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: WorkroomWizardData,
    private eventBus: ApplicationEventBus
  ) {
    super();
    this.removeContentHubRobotsOnRepositoryRemovalOrChange();
  }

  private removeContentHubRobotsOnRepositoryRemovalOrChange() {
    this.eventBus
      .observeEvents(this.unsubscribe$, WorkroomWizardEvent.REPOSITORY_REMOVED, WorkroomWizardEvent.REPOSITORY_CHANGED)
      .subscribe(() => {
        const { workroomConfiguration } = this.data.template;
        this.data.template.workroomConfiguration.automators = workroomConfiguration.automators.filter(
          automator => automator.type !== AutomatorType.CONTENT_HUB
        );
      });
  }
}
