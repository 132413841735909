import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DragulaService } from 'ng2-dragula';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Permission } from '@celum/work/app/core/api/permission';
import { Color, Roles } from '@celum/work/app/core/model';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import { TasksOverviewUpdateVisibleColumns } from '@celum/work/app/pages/workroom/pages/tasks/store/tasks-overview.actions';
import {
  selectTaskCountForList,
  selectVisibleColumnCount
} from '@celum/work/app/pages/workroom/pages/tasks/store/tasks-overview.selectors';

import { ColorService, PermissionUtil, WorkroomConstants } from '../../../shared/util';

export interface DroppedTask {
  taskId: number;
  prevTaskListId: number;
  nextTaskListId: number;
  index: number;
}

export interface DroppedTaskList {
  taskListId: number;
  index: number;
}

export enum LockReason {
  TRANSITION = 'transition',
  MANDATORY_ASSIGNMENT = 'mandatoryAssignment'
}

@Component({
  selector: 'task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('dropAnimation', [
      transition(
        '* => dropped',
        animate(
          250,
          keyframes([
            style({
              transform: 'scale(1)',
              offset: 0
            }),
            style({
              transform: 'scale(0.98)',
              offset: 0.3
            }),
            style({
              transform: 'scale(1)',
              offset: 1
            })
          ])
        )
      )
    ])
  ]
})
export class TaskListComponent extends ReactiveComponent implements OnInit, OnChanges, AfterViewInit {
  public static TASK_LIST_UNMOVABLE_CLASS = 'task-list_unmovable';

  @Input() public taskList: TaskList;
  @Input() public deletable = true;
  @Input() public dragNDropListBag: string;
  @Input() public dragNDropTaskBag: string;
  @Input() public hasPermissionToMoveTaskList: boolean;
  @Input() public taskListUpdatePermission: Permission;
  @Input() public taskListDeletePermission: Permission;
  @Input() public collapsible = true;
  @Input() public collapsed: boolean;
  @Input() public dynamicColumnSize: boolean;

  @Output() public readonly nameChanged = new EventEmitter<string>();
  @Output() public readonly droppedList = new EventEmitter<DroppedTaskList>();
  @Output() public readonly droppedTask = new EventEmitter<DroppedTask>();
  @Output() public readonly onColorChanged: EventEmitter<Color> = new EventEmitter<Color>();
  @Output() public readonly onDeleteTaskList: EventEmitter<TaskList> = new EventEmitter<TaskList>();

  @ViewChild('nameInputField') public inputField: ElementRef;
  @HostBinding('@dropAnimation') public dropAnimationState = '';
  @HostBinding('class.task-list') public readonly hostCls: boolean = true;

  public expandIcon = IconConfiguration.small(`asc-xs`).withIconSize(11);
  public readonly lockIcon = IconConfiguration.xLarge('lock-icon', '', 40);

  public visibleColumns = 1;
  public ownersToDisplay = 2;
  public lockTaskList = false;
  public transitionSourceId: number;
  public transitionTaskLists: TaskList[];
  public lockReason: LockReason;
  public readonly lockReasonType: typeof LockReason = LockReason;

  public taskCount$: Observable<number>;
  public isVisitor$: Observable<boolean>;

  private visibleColumnsClass = 'task-list--show-1';
  private isDragged = false;

  constructor(
    protected store: Store<any>,
    private renderer: Renderer2,
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private dragulaService: DragulaService,
    private permissionUtil: PermissionUtil
  ) {
    super();
  }

  public static countUnmovableElements(children: HTMLCollection): number {
    let idx = 0;
    for (let i = 0; i < children.length; i++) {
      const el = children[i];
      if (el.classList?.contains(TaskListComponent.TASK_LIST_UNMOVABLE_CLASS)) {
        idx++;
      }
    }
    return idx;
  }

  @HostBinding('attr.data-task-list-name')
  public get attrDataListName(): string {
    return this.taskList?.name || 'new';
  }

  @HostBinding('attr.data-cy')
  public get attrDataCy(): string {
    return `task-list-${this.taskList?.id}`;
  }

  public ngOnInit(): void {
    this.taskCount$ = this.store.select(selectTaskCountForList(this.taskList.id));
    this.isVisitor$ = this.permissionUtil.hasRoleForCurrentWorkroom(Roles.VISITOR);
    this.initDragAndDrop();
    this.initVisibleColumns();
  }

  public ngOnChanges({ taskList }: SimpleChanges): void {
    if (taskList?.currentValue) {
      this.expandIcon = IconConfiguration.small(`asc-xs`)
        .withIconSize(11)
        .withColor(ColorService.getColorAsRgbString(this.taskList.color));
    }
  }

  public ngAfterViewInit(): void {
    this.updateComponentClasses();
  }

  public onNameChanged(name: string): void {
    // dragging and triggering current event lead to unpredictable cloning behavior for task list
    // that's why name changing is allowed only when list isn't being dragged
    if (!this.isDragged) {
      this.nameChanged.emit(name);
    }
  }

  public deleteTaskList(): void {
    this.onDeleteTaskList.emit(this.taskList);
  }

  public changeVisibleColumns(visibleColumnsCount: number): void {
    this.store.dispatch(
      TasksOverviewUpdateVisibleColumns({
        taskListId: this.taskList.id,
        visibleColumnsCount
      })
    );
  }

  public updateTransitionLock(
    transitionTaskLists: TaskList[] = [],
    taskListsWithMandatoryAssignment: TaskList[] = [],
    source: number,
    isVisitor = false
  ): void {
    const visitorLockForMandatoryAssignmentRobot =
      isVisitor && taskListsWithMandatoryAssignment.includes(this.taskList);
    const taskListIsNotDefinedInTransitions = !transitionTaskLists.includes(this.taskList);

    if (visitorLockForMandatoryAssignmentRobot || taskListIsNotDefinedInTransitions) {
      this.lockTaskList = true;
      this.transitionSourceId = source;
      this.lockReason = visitorLockForMandatoryAssignmentRobot
        ? LockReason.MANDATORY_ASSIGNMENT
        : LockReason.TRANSITION;
      this.transitionTaskLists = transitionTaskLists.filter(taskList =>
        taskListsWithMandatoryAssignment.every(robotTaskList => robotTaskList.id !== taskList.id)
      );

      this.changeDetectorRef.markForCheck();
    }
  }

  public finishTransitionLock(): void {
    this.lockTaskList = false;
    this.changeDetectorRef.markForCheck();
  }

  public trackByFn(index, item: TaskList): string {
    return item.id || index;
  }

  private initDragAndDrop(): void {
    const listEl = this.el.nativeElement;

    const dragAnimationState$ = this.dragulaService.drag().pipe(map(({ el }) => el === listEl && 'dragged'));
    const dropAnimationState$ = this.dragulaService.drop().pipe(map(({ el }) => el === listEl && 'dropped'));

    // drop animation stream for drag and drop of task list itself
    merge(dragAnimationState$, dropAnimationState$)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(state => !!state)
      )
      .subscribe((state: 'dragged' | 'dropped') => {
        this.isDragged = state === 'dragged';
        this.dropAnimationState = state;
        this.changeDetectorRef.markForCheck();
      });

    this.dragulaService
      .drop()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ name, el, target }) => {
        const idx = Array.from(target.children).indexOf(el);

        if (el === listEl && name === this.dragNDropListBag) {
          this.droppedList.emit({
            taskListId: this.taskList.id,
            index: idx
          });
        } else if (name === this.dragNDropTaskBag) {
          this.handleTaskDrop(listEl, el, idx - TaskListComponent.countUnmovableElements(target.children));
        }
      });

    this.dragulaService
      .cancel()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ el }) => {
        if (el === listEl && this.isDragged) {
          this.isDragged = false;
        }
      });
  }

  private handleTaskDrop(listEl: HTMLElement, el: any, idx): void {
    // check whether task was dropped to this list
    if (listEl.contains(el)) {
      const taskId = Number(el.dataset.itemId); // data-item-id
      const prevTaskListId = Number(el.querySelector('task-card').dataset.taskListId);
      if (taskId) {
        this.droppedTask.emit({
          taskId,
          prevTaskListId,
          nextTaskListId: this.taskList.id,
          index: idx
        });
      } else {
        console.error('TaskList: Cannot move task, found no task id!');
      }
    }
  }

  private setVisibleColumns(visibleColumns: number): void {
    if (this.collapsible) {
      let conditionalVisibleColumns = visibleColumns;
      if (this.collapsed && this.visibleColumns !== 0) {
        this.changeVisibleColumns(0);
        conditionalVisibleColumns = 0;
      }
      this.visibleColumns = conditionalVisibleColumns;
      this.updateComponentClasses();
      this.updateOwnersToDisplay(visibleColumns);
    }
  }

  private updateComponentClasses(): void {
    if (this.hasPermissionToMoveTaskList) {
      this.renderer.addClass(this.el.nativeElement, 'task-list--draggable');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'task-list--draggable');
    }

    this.renderer.removeClass(this.el.nativeElement, this.visibleColumnsClass);
    if (this.visibleColumns > 0) {
      this.visibleColumnsClass = `task-list--show-${this.visibleColumns}`;
    } else {
      this.visibleColumnsClass = 'task-list--collapsed';
    }

    this.changeDetectorRef.detectChanges();
    const dndContainer = this.el.nativeElement.querySelector('.simple-list_dnd-container');

    if (dndContainer) {
      this.renderer.setAttribute(
        dndContainer,
        WorkroomConstants.VISIBLE_COLUMNS_ATTRIBUTE,
        String(this.visibleColumns)
      );
    }

    this.renderer.addClass(this.el.nativeElement, this.visibleColumnsClass);
  }

  private updateOwnersToDisplay(visibleColumns: number): void {
    if (visibleColumns === 0) {
      this.ownersToDisplay = 0;
    } else {
      this.ownersToDisplay = visibleColumns + 1;
    }
  }

  private initVisibleColumns(): void {
    this.store
      .select(selectVisibleColumnCount(this.taskList.id))
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(visibleColumnCount => this.setVisibleColumns(visibleColumnCount));
  }
}
