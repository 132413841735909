import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, Renderer2 } from '@angular/core';

import { PreviewUtil } from '@celum/work/app/shared/util';

/*
 * The purpose of this directive to replace image src to some custom placeholder
 * in case if image was not loaded or broke.
 * If also placeholder wasn't loaded the directive will do nothing
 */
@Directive({
  selector: '[img[withImagePlaceholder]]'
})
export class WithImagePlaceholderDirective {
  private placeholderPath = this.getNoPreviewAssetPath();

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @Input() public set withImagePlaceholder(placeholderPath: string) {
    this.placeholderPath = placeholderPath || this.getNoPreviewAssetPath();
  }

  @HostListener('error')
  public onError() {
    this.setPlaceholder();
  }

  public setPlaceholder() {
    if (this.el.nativeElement.src !== this.getFullPlaceholderPath()) {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.placeholderPath);
    }
  }

  private getFullPlaceholderPath() {
    return this.isPlaceholderPathAbsolute()
      ? this.placeholderPath
      : `${this.document.location.origin}/${this.placeholderPath}`;
  }

  private isPlaceholderPathAbsolute(): boolean {
    return this.placeholderPath.trim().startsWith('http');
  }

  private getNoPreviewAssetPath(): string {
    return PreviewUtil.getNoPreviewAssetPath();
  }
}
