<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="task-list-locked" *ngIf="lockTaskList">
  <div class="task-list-locked_content">
    <celum-icon [configuration]="lockIcon"></celum-icon>
    <div *ngIf="visibleColumns > 0">
      <p *ngIf="transitionTaskLists.length === 0"
         data-cy="task-list-transitions-error-permission">{{'TRANSITIONS.ERROR.TRANSITION.PERMISSION' | translate | uppercase }}</p>
      <ng-container  [ngSwitch]="lockReason">
        <ng-container *ngSwitchCase="lockReasonType.TRANSITION">
          <p *ngIf="transitionTaskLists.length > 0">{{'TRANSITIONS.ERROR.TRANSITION.HEADER' | translate | uppercase }}</p>
          <div
            *ngIf="transitionTaskLists.length > 1 || (transitionTaskLists.length == 1 && transitionTaskLists[0].id !== transitionSourceId)">
            <p>{{'TRANSITIONS.ERROR.TRANSITION.SUBHEADER' | translate }}</p>
            <div class="task-list-locked_transition-list ">
              <div *ngFor="let transition of transitionTaskLists; trackBy: trackByFn">
                <div class="task-list-locked_transition-item" [style.background-color]="transition.color | color"
                     *ngIf="transition.id !== transitionSourceId">{{transition.name | uppercase}}</div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="lockReasonType.MANDATORY_ASSIGNMENT">
          <p *ngIf="transitionTaskLists.length > 0">{{'TRANSITIONS.ERROR.MANDATORY_ASSIGNMENT.HEADER' | translate | uppercase }}</p>
          <ng-container *ngIf="transitionTaskLists.length > 1 || (transitionTaskLists.length == 1 && transitionTaskLists[0].id !== transitionSourceId)">
            <p >{{'TRANSITIONS.ERROR.MANDATORY_ASSIGNMENT.SUBHEADER' | translate }}</p>
            <div class="task-list-locked_transition-list ">
              <div *ngFor="let transition of transitionTaskLists; trackBy: trackByFn">
                <div class="task-list-locked_transition-item" [style.background-color]="transition.color | color"
                      *ngIf="transition.id !== transitionSourceId">{{transition.name | uppercase}}</div>
              </div>
            </div>

            <p class="task-list-locked_footer">{{'TRANSITIONS.ERROR.MANDATORY_ASSIGNMENT.FOOTER' | translate }}</p>
          </ng-container>
        </ng-container>
      </ng-container >
    </div>
  </div>
</div>

<task-list-header [taskList]="taskList"
                  [listColor]="taskList.color | color"
                  [dynamicColumnSize]="dynamicColumnSize"
                  [updatePermission]="taskListUpdatePermission"
                  [deletePermission]="taskListDeletePermission"
                  [hasPermissionToMoveTaskList]="hasPermissionToMoveTaskList"
                  [deletable]="deletable"
                  [autoFocused]="taskList?.isPlaceholder"
                  (onNameChanged)="onNameChanged($event)"
                  (onDeleteTaskList)="deleteTaskList()"
                  (onColorChanged)="onColorChanged.emit($event)">
  <ng-content taskcount select="[taskcount]"></ng-content>
  <ng-content menuitem="add-new-task" select="[menuitem=add-new-task]"></ng-content>
</task-list-header>

<ng-content select="[subheader]"></ng-content>
<ng-content select="[collapsible]" *ngIf="visibleColumns > 0"></ng-content>
<ng-content></ng-content>

<div *ngIf="visibleColumns === 0"
      class="task-list_name-vertical"
      [ngStyle]="{ 'color': taskList.color | color }"
      (click)="changeVisibleColumns(1)"
      data-cy="task-list-name-vertical">
  <span class="task-list_name-vertical_name">
    {{taskList.name}}
  </span>&nbsp;
  <span *ngIf="(isVisitor$ | async) === false" data-cy="task-list-vertical-task-count">
    ({{ taskCount$ | async }})
  </span>
  <button mat-icon-button celum-square-button class="task-list_vertical-expand-button">
    <celum-icon [configuration]="expandIcon"></celum-icon>
  </button>
</div>



