import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { ImportedFolderData } from '../watch-collections-automator.component';
import { selectedFoldersChanged } from '../watch-collections-automator.util';

export interface SelectFoldersDialogState {
  allFolders: ImportedFolderData[];
  initiallySelectedFolders: ImportedFolderData[];
  selectedFolders: ImportedFolderData[];
  searchValue: string;
}

@Injectable()
export class SelectFoldersDialogStore extends ComponentStore<SelectFoldersDialogState> {
  public readonly allFolders$: Observable<ImportedFolderData[]> = this.select(state => state.allFolders);
  public readonly selectedFolders$: Observable<ImportedFolderData[]> = this.select(state => state.selectedFolders);
  public readonly initiallySelectedFolders$: Observable<ImportedFolderData[]> = this.select(
    state => state.initiallySelectedFolders
  );
  public readonly isValid$: Observable<boolean> = this.select(
    ({ selectedFolders, initiallySelectedFolders }) =>
      selectedFolders.length !== 0 && selectedFoldersChanged(initiallySelectedFolders, selectedFolders)
  );
  public readonly filteredFolders$: Observable<ImportedFolderData[]> = this.select(({ allFolders, searchValue }) =>
    allFolders.filter(el => el.folderName.toLowerCase().includes(searchValue.toLowerCase()))
  );

  public readonly selectFolder = this.updater((state, collection: ImportedFolderData) => ({
    ...state,
    selectedFolders: [...state.selectedFolders, collection]
  }));

  public readonly deselectFolder = this.updater((state, collection: ImportedFolderData) => ({
    ...state,
    selectedFolders: state.selectedFolders.filter(el => el.folderId !== collection.folderId)
  }));

  public searchValueChanged = this.updater((state, searchValue: string) => ({ ...state, searchValue }));
}
