<celum-dialog class="copy-template" (onCloseAll)="closeDialog()">
    <celum-dialog-header class="copy-template_header">
      {{ 'TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.HEADLINE' | translate }}
    </celum-dialog-header>
    <celum-dialog-content>
      <form [formGroup]="copyActionForm" class="copy-template_form">
        <mat-form-field celum-form-field class="copy-template_name" [reserveHintSpace]="true">
          <mat-label>{{ 'TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.NAME' | translate }}</mat-label>
          <input matInput
                 required
                 formControlName="name"
                 [maxLength]="validation.maxNameLength"
                 [placeholder]="'TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.NAME' | translate">
          <mat-error *ngIf="copyActionForm.get('name').hasError('required')">{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
          <mat-error *ngIf="copyActionForm.get('name').hasError('maxlength')">
            {{ 'VALIDATION.MAX_LENGTH' | translate: { maxLength: validation.maxNameLength } }}
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="categories$ | async as categories">
          <ng-container *ngIf="categories.length > 0; else categoryName">
            <mat-form-field celum-form-field class="copy-template_category" [reserveHintSpace]="true">
              <mat-label>{{ 'TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.CATEGORY' | translate }}</mat-label>
              <mat-select data-cy="copy-template-category-select"
                          panelClass="celum-dropdown-panel" formControlName="categoryId">
                <mat-option data-cy="copy-template-category-option-personal"
                            [attr.data-cy]="'copy-template-category-option-personal'"
                            [value]="personalCategoryKey">{{ 'TEMPLATE_CHOOSER.PERSONAL_CATEGORY' | translate }}</mat-option>
                <mat-option [attr.data-cy]="'copy-template-category-option-' + category.id"
                            [value]="category.id" *ngFor="let category of categories, trackBy: trackByFn">
                  {{ category | templateCategoryName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="copyActionForm.get('categoryId').hasError('required')">{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </ng-container>

          <ng-template #categoryName>
            <div class="copy-template_label">{{ 'TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.CATEGORY' | translate }}</div>
            <div>{{ 'TEMPLATE_CHOOSER.PERSONAL_CATEGORY' | translate }}</div>
          </ng-template>
        </ng-container>

      </form>
    </celum-dialog-content>
    <celum-dialog-footer [valid]="copyActionForm.valid"
                         [loading]="loading$ | async"
                         [confirmBtnText]="'TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.CONFIRM'"
                         [cancelBtnText]="'TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.CANCEL'"
                         (onConfirm)="copyTemplate()"
                         (onCancel)="closeDialog()"></celum-dialog-footer>
</celum-dialog>
