import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';

import { ColorConstants } from '../../base/color-constants';
import { ResizeSlider } from '../resize-slider';

@Component({
             selector: 'resize-button',
             templateUrl: './resize-button.html',
             styleUrls: ['./resize-button.less'],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class CelumResizeButton implements ResizeSlider, OnChanges {

  @Input() public active: boolean;
  @Input() public orientation: 'horizontal' | 'vertical' = 'horizontal';

  @HostBinding('style.backgroundColor') public backgroundColor: string;
  @HostBinding('style.cursor') public cursor: string;

  constructor(public elementRef: ElementRef) {
  }

  public ngOnChanges(): void {
    this.backgroundColor = this.active ? ColorConstants.BLUE_GRAY_100 : null;
    this.cursor = this.orientation === 'horizontal' ? 'col-resize' : 's-resize';
  }

  public getHtmlElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
