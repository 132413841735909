import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { ProgressTask, ProgressTaskType } from '@celum/work/app/progress-task/store/progress-task.model';

import { ErrorKey } from '../../core/error/error-key';

export class ProgressTaskActions {
  public static Upsert = createAction('[Progress Task] Upsert', props<{ progressTask: ProgressTask<any, any> }>());

  public static UpsertMany = createAction(
    '[Progress Task] Upsert Many',
    props<{ progressTasks: ProgressTask<any, any>[] }>()
  );

  public static UpdateOne = createAction('[Progress Task] Update One', props<Update<ProgressTask<any, any>>>());

  public static Finished = createAction('[Progress Task] Finished', props<{ progressTaskId: string; payload: any }>());

  public static Failed = createAction(
    '[Progress Task] Failed',
    props<{ progressTaskId: string; errorKey?: ErrorKey }>()
  );

  public static Cancel = createAction('[Progress Task] Cancel', props<{ progressTask: ProgressTask<any, any> }>());

  public static CloseSnackbarGroup = createAction(
    '[Progress Task] Close Snackbar Group',
    props<{ progressTaskType: ProgressTaskType }>()
  );

  public static CreateFromWebSocketMessage = createAction(
    '[Progress Task] Create From WebSocket Message',
    props<{ payload: any; progressTaskType: ProgressTaskType }>()
  );
}
