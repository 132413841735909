import { FormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { CustomFieldDropdownOption } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

export class ChipNameCustomErrorStateMatcher implements ErrorStateMatcher {
  constructor(private chipsControl: FormControl<null | CustomFieldDropdownOption[]>) {}

  public isErrorState(chipNameControl: FormControl | null) {
    return (
      chipNameControl.touched &&
      notNullOrUndefined(Validators.required(chipNameControl)) &&
      notNullOrUndefined(Validators.required(this.chipsControl))
    );
  }
}
