import { createAction, props } from '@ngrx/store';

import { FileVersion } from '@celum/work/app/core/model/entities/file-version/file-version.model';

export const FileVersionUpsertOne = createAction(
  '[FileVersion] Upsert One',
  props<{ fileVersion: FileVersion; propertiesToUpdate?: string[] }>()
);

export const FileVersionsUpsertMany = createAction(
  '[FileVersion] Upsert Many',
  props<{ fileVersions: FileVersion[]; propertiesToUpdate?: string[] }>()
);

export const FileVersionDeleteOne = createAction('[FileVersion] Delete One', props<{ fileVersion: FileVersion }>());

export const FileVersionsDeleteMany = createAction('[FileVersion] Delete Many', props<{ ids: number[] }>());
