import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IconConfiguration } from '@celum/common-components';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';

@Component({
  selector: 'workrooms-tab',
  templateUrl: './workrooms-tab.component.html',
  styleUrls: ['./workrooms-tab.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WorkroomsTabComponent {
  @HostBinding('class.workrooms-tab') public hostCls = true;

  @Input() public teamspace: Teamspace;

  public readonly companyLogoIconConfiguration = IconConfiguration.large('company-logo-fallback').withIconSize(30);

  constructor(private translateService: TranslateService) {}

  public get statsHtml(): string {
    const fixedStats = { usedCount: this.teamspace.workroomsUsed, totalCount: this.teamspace.workroomsLimit };

    if (this.teamspace.workroomsUsed > 0 || this.teamspace.workroomsFinished > 0) {
      return (
        this.translateService.instant('TEAMSPACE_MANAGEMENT.WORKROOMS.STATS', fixedStats) +
        this.translateService.instant('TEAMSPACE_MANAGEMENT.WORKROOMS.OPTIONAL_STAT', {
          finishedCount: this.teamspace.workroomsFinished
        })
      );
    }

    return this.translateService.instant('TEAMSPACE_MANAGEMENT.WORKROOMS.STATS', fixedStats);
  }
}
