import { Store } from '@ngrx/store';

import { EntityTranslator } from '@celum/work/app/core/model/entity';

import { WorkroomGroupItem, WorkroomGroupItemType } from './workroom-group-item.model';
import { selectWorkroomById } from '../workroom.selectors';

export class WorkroomGroupItemTranslator implements EntityTranslator<WorkroomGroupItem> {
  public translateToEntity(json: any): WorkroomGroupItem {
    const { id, groupId, workroomId, pendingTransaction, sort } = json;
    return {
      id,
      groupId,
      sort,
      workroomId,
      workroom: (store: Store<any>) => store.select(selectWorkroomById(workroomId)),
      pendingTransaction,
      entityType: WorkroomGroupItemType.instance()
    };
  }
}
