import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, merge, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, mergeMap, startWith, switchMap, withLatestFrom } from 'rxjs/operators';

import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import {
  selectTaskListEntities,
  TaskListsDeleteOne,
  TaskListsHandleOwnerId,
  TaskListsUpsertOne
} from '@celum/work/app/core/model/entities/task';
import { selectLoggedInPersonId } from '@celum/work/app/core/ui-state/ui-state.selectors';

import {
  TaskListAddOwner,
  TaskListAddOwnerSuccess,
  TaskListCreateTaskList,
  TaskListCreateTaskListFailed,
  TaskListCreateTaskListSucceeded,
  TaskListDeleteTaskList,
  TaskListDeleteTaskListFailed,
  TaskListDeleteTaskListSucceeded,
  TaskListLoadTaskLists,
  TaskListLoadTaskListsFailed,
  TaskListLoadTaskListsSuccess,
  TaskListRemoveOwner,
  TaskListRemoveOwnerSuccess,
  TaskListUpdateTaskList
} from './task-list.actions';
import { TaskListService } from './task-list.service';

@Injectable()
export class TaskListEffects {
  public createTaskList = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskListCreateTaskList),
      mergeMap(action =>
        this.taskListService.createTaskList(action.taskList).pipe(
          switchMap(taskList => {
            return merge(
              of(TaskListsDeleteOne({ id: action.taskList.id })),
              of(
                TaskListCreateTaskListSucceeded({
                  taskList: taskList,
                  tempId: action.taskList.id
                })
              )
            );
          }),
          catchError(err => {
            this.failureHandler.handleError(err);
            // reset creation...
            return merge(
              of(TaskListsDeleteOne({ id: action.taskList.id })),
              of(TaskListCreateTaskListFailed({ taskList: action.taskList }))
            );
          }),
          startWith(
            TaskListsUpsertOne({
              taskList: action.taskList
            })
          )
        )
      )
    )
  );

  public updateTaskList = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskListUpdateTaskList),
      withLatestFrom(this.store.select(selectTaskListEntities)),
      mergeMap(([action, taskListEntities]) => {
        const oldTaskList = taskListEntities[action.taskList.id];

        return this.taskListService.updateTaskList(action.taskList, action.propertiesToUpdate).pipe(
          switchMap(() => EMPTY),
          catchError(err => {
            this.failureHandler.handleError(err);
            // reset task list...
            return of(TaskListsUpsertOne({ taskList: oldTaskList }));
          }),
          startWith(
            TaskListsUpsertOne({
              taskList: action.taskList
            })
          )
        );
      })
    )
  );

  public addOwner = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskListAddOwner),
      concatMap(action => {
        let stream$ = this.taskListService.addOwnerToTaskList(action.taskList.id, action.personId).pipe(
          switchMap(() =>
            of(
              TaskListAddOwnerSuccess({
                taskList: action.taskList,
                personId: action.personId
              })
            )
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(
              TaskListsHandleOwnerId({ taskListId: action.taskList.id, ownerId: action.personId, operation: 'remove' })
            );
          })
        );
        if (action.taskList.ownerIds.indexOf(action.personId) < 0) {
          stream$ = stream$.pipe(
            startWith(
              TaskListsHandleOwnerId({ taskListId: action.taskList.id, ownerId: action.personId, operation: 'add' })
            )
          );
        }
        return stream$;
      })
    )
  );

  public removeOwner = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskListRemoveOwner),
      mergeMap(action => {
        const updatedTaskList = {
          ...action.taskList,
          ownerIds: [...action.taskList.ownerIds]
        };
        const index = updatedTaskList.ownerIds.findIndex(id => action.personId === id);

        let stream$ = this.taskListService.removeOwnerFromTaskList(action.taskList.id, action.personId).pipe(
          switchMap(() =>
            of(
              TaskListRemoveOwnerSuccess({
                taskList: updatedTaskList,
                personId: action.personId
              })
            )
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(TaskListsUpsertOne({ taskList: action.taskList }));
          })
        );

        if (index >= 0) {
          updatedTaskList.ownerIds.splice(index, 1);
          stream$ = stream$.pipe(startWith(TaskListsUpsertOne({ taskList: updatedTaskList })));
        }
        return stream$;
      })
    )
  );

  public deleteTaskList = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskListDeleteTaskList),
      withLatestFrom(this.store.select(selectLoggedInPersonId)),
      mergeMap(([action]) => {
        const taskListId = action.taskList.id;
        return this.taskListService.deleteTaskList(taskListId).pipe(
          switchMap(() => of(TaskListDeleteTaskListSucceeded({ taskList: action.taskList, tasks: action.tasks }))),
          catchError(err => {
            this.failureHandler.handleError(err);
            // reset status change...
            return of(
              TaskListDeleteTaskListFailed({ taskList: action.taskList }),
              TaskListsUpsertOne({ taskList: action.taskList })
            );
          }),
          startWith(TaskListsDeleteOne({ id: taskListId }))
        );
      })
    )
  );

  public loadTaskLists = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskListLoadTaskLists),
      exhaustMap(action =>
        this.taskListService.loadTaskLists(action.workroomId).pipe(
          switchMap(() => of(TaskListLoadTaskListsSuccess({ workroomId: action.workroomId }))),
          catchError(error => {
            this.failureHandler.handleError(error);
            return of(TaskListLoadTaskListsFailed({ workroomId: action.workroomId }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private taskListService: TaskListService,
    private failureHandler: FailureHandler
  ) {}
}
