<robot [title]="'ROBOTS.TASK_MANAGEMENT.TASK_RESTRICTION.TITLE'" [whenIcon]="robotTaskListIcon" [thenIcon]="automatorIcon" [readonly]="isReadonly">
  <span
      when
      class="robot-dialog_content_text"
      [innerHTML]="'ROBOTS.TASK_MANAGEMENT.TASK_RESTRICTION.ROW1' | translate: { taskListName: sourceEntity.name.toUpperCase() }">
  </span>
  <span
      then
      class="robot-dialog_content_text"
      [innerHTML]="'ROBOTS.TASK_MANAGEMENT.TASK_RESTRICTION.ROW2' | translate">
    </span>
</robot>
