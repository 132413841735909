import { Injectable } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';
import { TaskList } from '@celum/work/app/core/model/entities/task/task-list.model';
import { WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import { RuleType } from '@celum/work/app/core/model/entities/workroom/robot.model';
import { hasTaskListRule } from '@celum/work/app/robots/services/robots-util';
import { SelectFolderDialogSubmitSubMenuConfiguration } from '@celum/work/app/shared/components/select-folder-dialog/select-folder-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ImportIntentSelectFolderService {
  public readonly infoIconSmall = IconConfiguration.small('info-icon');

  public getSubMenuItemsForCollectionImport(isModerator: boolean): SelectFolderDialogSubmitSubMenuConfiguration[] {
    return isModerator
      ? [
          new SelectFolderDialogSubmitSubMenuConfiguration(
            'ADD_TO_FOLDER.DIALOG.CONFIRM_SUBMENU.ADD_AND_WATCH_COLLECTION',
            'AddAndWatch',
            'ADD_TO_FOLDER.DIALOG.CONFIRM_SUBMENU.ADD_AND_WATCH_COLLECTION_INFO',
            this.infoIconSmall
          ),
          new SelectFolderDialogSubmitSubMenuConfiguration('ADD_TO_FOLDER.DIALOG.CONFIRM_SUBMENU.ADD_COLLECTION', 'Add')
        ]
      : [];
  }

  public getSubMenuItemsForAssetImport(
    workroomConfiguration: WorkroomConfiguration,
    taskLists: TaskList[]
  ): SelectFolderDialogSubmitSubMenuConfiguration[] {
    const canCreateTasks = taskLists.some(taskList => this.isTaskCreationEnabled(workroomConfiguration, taskList.id));
    return canCreateTasks
      ? [
          new SelectFolderDialogSubmitSubMenuConfiguration(
            'ADD_TO_FOLDER.DIALOG.CONFIRM_SUBMENU.ADD_AND_CREATE_TASKS',
            'AddAndCreateTasks'
          ),
          new SelectFolderDialogSubmitSubMenuConfiguration('ADD_TO_FOLDER.DIALOG.CONFIRM', 'Add')
        ]
      : [];
  }

  private isTaskCreationEnabled(workroomConfiguration: WorkroomConfiguration, taskListId: number): boolean {
    return !hasTaskListRule(workroomConfiguration, taskListId, RuleType.TASK_CREATION_RESTRICTION);
  }
}
