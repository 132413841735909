import { Injectable } from '@angular/core';

import { CelumDialogOpener } from '@celum/internal-components';

import { Template } from '../../core/model/entities/template/template.model';
import { isAutomator } from '../../core/model/entities/workroom/robot.model';
import {
  RobotDialogConfiguration,
  RobotDialogConfigurationArgs
} from '../../robots/components/robot-dialog/robot-dialog-config';
import { RobotDialogComponent } from '../../robots/components/robot-dialog/robot-dialog.component';
import { RobotEditEvent } from '../../robots/services/robots-factory';

@Injectable()
export class OpenRobotDialogService {
  constructor(private celumDialogOpener: CelumDialogOpener) {}

  public async openCreateRobotDialog(config: RobotDialogConfigurationArgs<any>, template: Template): Promise<void> {
    const res = await this.celumDialogOpener.showDialog(
      'robot-dialog',
      RobotDialogComponent,
      new RobotDialogConfiguration(config),
      { autoFocus: false, restoreFocus: false }
    );

    if (!res) {
      return;
    }

    // ? not sure if mutating reference of the automator is a good idea, maybe store has to be provided
    if (isAutomator(res)) {
      template.workroomConfiguration.automators.push(res);
    } else {
      template.workroomConfiguration.rules.push(res);
    }
  }

  public async openEditRobotDialog(config: any, event: RobotEditEvent, template: Template): Promise<void> {
    const res = await this.celumDialogOpener.showDialog(
      'edit-robot-dialog',
      RobotDialogComponent,
      new RobotDialogConfiguration(config),
      { autoFocus: false, restoreFocus: false }
    );

    if (!res) {
      return;
    }

    // ? not sure if mutating reference of the automator is a good idea, maybe store has to be provided
    if (isAutomator(event.robot)) {
      const idx = template.workroomConfiguration.automators.indexOf(event.robot);
      template.workroomConfiguration.automators[idx] = res;
    } else {
      const idx = template.workroomConfiguration.rules.indexOf(event.robot);
      template.workroomConfiguration.rules[idx] = res;
    }
  }
}
