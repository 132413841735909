import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { AVATAR_SIZE, AvatarLabel } from '@celum/common-components';
import { Color } from '@celum/work/app/core/model/color.model';
import { selectContributorByUserIdAndWorkroomId } from '@celum/work/app/core/model/entities/contributor';
import { InvitationStatus, Member, MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { selectMemberByUserIdAndTeamspaceId } from '@celum/work/app/core/model/entities/member/member.selectors';
import { isNewUser } from '@celum/work/app/core/model/entities/person';
import { Person } from '@celum/work/app/core/model/entities/person/person.model';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';

import { ColorService } from './color.service';
import { Roles } from '../../core/model';
import { selectTenantTeamspace } from '../../core/ui-state/ui-state.selectors';
import {
  WorkroomAvatarConfigBuilder,
  WorkroomAvatarConfiguration
} from '../components/workroom-avatar/workroom-avatar-configuration';

@Injectable({
  providedIn: 'root'
})
export class AvatarUtil {
  public readonly NEW_USER_ICON_PATH = '/icons/invited-user-avatar.svg';

  constructor(
    private translateService: TranslateService,
    private store: Store<any>
  ) {}

  public getAvatarConfigWithImage(args: {
    person: Person;
    size: number | AVATAR_SIZE;
    teamspaceId?: number;
    showWithName?: boolean;
    interactive?: boolean;
  }): Observable<WorkroomAvatarConfiguration> {
    return this.getAvatarConfigWithOrWithoutMembership(args).pipe(
      map(configuration => {
        if (isNewUser(args.person)) {
          configuration.withImage(this.NEW_USER_ICON_PATH);
        } else if (args.person.avatarUrl) {
          configuration.withImage(args.person.avatarUrl);
        } else {
          configuration.withImage('');
        }

        if (args.showWithName) {
          configuration.withDisplayName(this.getUserDisplayName(args.person));
        }
        return configuration.build();
      })
    );
  }

  public getAvatarConfigWithImageForCurrentTeamspace(args: {
    person: Person;
    size: AVATAR_SIZE;
    cssClass?: string;
    showWithName?: boolean;
    interactive?: boolean;
  }): Observable<WorkroomAvatarConfiguration> {
    return this.store.select(selectTenantTeamspace).pipe(
      mergeMap(teamspace =>
        this.getAvatarConfigWithImage({ ...args, teamspaceId: teamspace?.id }).pipe(
          map(avatarCfg => {
            avatarCfg.interactive = args.interactive;
            avatarCfg.cssClass = args.cssClass || '';
            avatarCfg.title = this.adaptTitleBasedOnStatus(
              avatarCfg.title,
              avatarCfg.member?.invitationStatus ?? args.person.invitationStatus,
              avatarCfg.member?.status
            );

            if (avatarCfg.member?.status === MembershipStatus.INACTIVE) {
              avatarCfg.cssClass += ' celum-avatar-inactive';
            }

            return avatarCfg;
          })
        )
      )
    );
  }

  public getAvatarConfigForEmail(
    email: string,
    randomColor: Color,
    size: number | AVATAR_SIZE
  ): WorkroomAvatarConfiguration {
    return new WorkroomAvatarConfigBuilder()
      .withTitle(email)
      .withSize(size)
      .withBackgroundColor(ColorService.getColorAsRgbString(randomColor))
      .build();
  }

  public getUserDisplayName(person: Person): string {
    if (!person) {
      return null;
    }
    return person.firstName || person.lastName ? `${person.firstName} ${person.lastName}`.trim() : person.email;
  }

  public adaptTitleBasedOnStatus(
    title: string,
    invitationStatus: InvitationStatus,
    membershipStatus: MembershipStatus
  ): string {
    if (membershipStatus === MembershipStatus.INACTIVE) {
      return `${this.translateService.instant('PEOPLE.DEACTIVATED.TOOLTIP')}: ${title}`;
    }

    switch (invitationStatus) {
      case InvitationStatus.PENDING_APPROVAL:
        return `${this.translateService.instant('PEOPLE.DEACTIVATED.PENDING_APPROVAL')}: ${title}`;
      case InvitationStatus.INVITED:
        return `${this.translateService.instant('AVATAR.STATUS.INVITED')}: ${title}`;
      default:
        return title;
    }
  }

  public handleRoleBadge(avatar: WorkroomAvatarConfiguration, roles: Roles[]): WorkroomAvatarConfiguration {
    if (roles) {
      avatar.roles = roles;
      if (roles.includes(Roles.MODERATOR)) {
        avatar.addTopLeftLabel(new AvatarLabel('moderator-m', 'PEOPLE.MODERATOR', '#794be8'));
        avatar.cssClass += ' moderator';
      } else if (roles.includes(Roles.VISITOR)) {
        avatar.addTopLeftLabel(new AvatarLabel('visitor-badge', 'PEOPLE.VISITOR', '#01A9CA'));
        avatar.cssClass += ' visitor';
      }
    }

    return avatar;
  }

  public getAvatarDecorator(workroomId$: Observable<number>): AvatarDecoratorFn {
    return avatar =>
      workroomId$.pipe(
        switchMap(workroomId =>
          this.store.select(selectContributorByUserIdAndWorkroomId(avatar.person.id, workroomId))
        ),
        map(contributor => this.handleRoleBadge(avatar, contributor?.roles))
      );
  }

  public getAvatarDecoratorForCurrentWorkroom(): AvatarDecoratorFn {
    const currentWorkroomId$ = this.store.select(selectCurrentWorkroom).pipe(map(({ id }) => id));
    return this.getAvatarDecorator(currentWorkroomId$);
  }

  private getBackgroundColor(person: Person) {
    if (person?.color) {
      return ColorService.getColorAsRgbString(person.color);
    }

    return WorkroomAvatarConfiguration.INACTIVE_BG_COLOR;
  }

  private getMembership(args: { person: Person; teamspaceId?: number }): Observable<Member | null> {
    return this.store.select(selectCurrentWorkroom).pipe(
      switchMap(workroom => {
        if (workroom || args.teamspaceId) {
          return this.store.select(
            selectMemberByUserIdAndTeamspaceId(args.person.id, args.teamspaceId ?? workroom?.teamspaceId)
          );
        } else {
          return of(null);
        }
      })
    );
  }

  private getAvatarConfigWithOrWithoutMembership(args: {
    person: Person;
    size: number | AVATAR_SIZE;
    teamspaceId?: number;
    showWithName?: boolean;
    interactive?: boolean;
  }): Observable<WorkroomAvatarConfigBuilder> {
    return this.getMembership(args).pipe(
      map(membership => {
        const configuration = this.getAvatarConfigForPersonBuilder(args.person, args.size, args.interactive);
        if (membership) {
          configuration.withMember(membership);
        }
        return configuration;
      })
    );
  }

  private getAvatarConfigForPersonBuilder(
    person: Person,
    size: number | AVATAR_SIZE,
    interactive: boolean
  ): WorkroomAvatarConfigBuilder {
    return new WorkroomAvatarConfigBuilder()
      .withPerson(person)
      .withInteractive(interactive)
      .withDisplayName(this.getUserDisplayName(person), false)
      .withTitle(this.getUserDisplayName(person))
      .withSize(size)
      .withBackgroundColor(this.getBackgroundColor(person)) as WorkroomAvatarConfigBuilder;
  }
}

export type AvatarDecoratorFn = (avatar: WorkroomAvatarConfiguration) => Observable<WorkroomAvatarConfiguration>;
