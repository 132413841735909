import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable, take, takeUntil } from 'rxjs';

import { EmptyPage, EmptyPageConfig, SortDirection } from '@celum/common-components';
import { ReactiveService } from '@celum/core';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { CustomField } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { selectCustomFieldEntities } from '@celum/work/app/core/model/entities/custom-field/custom-field.selectors';
import { VirtualScrollTableService } from '@celum/work/app/shared/components/virtual-scroll-table/virtual-scroll-table.base';
import { WindowResizeService } from '@celum/work/app/shared/util';

import { CustomFieldsStore } from '../../fields-overview-tab.store';

@Injectable()
export class CustomFieldsVirtualScrollTableService
  extends ReactiveService
  implements VirtualScrollTableService<CustomField>
{
  public readonly items$: Observable<CustomField[]>;
  public readonly emptyPageConfig: EmptyPageConfig;
  public readonly isEmpty$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;
  public readonly stickyRowConfig: string[];
  public readonly selectedItems$: BehaviorSubject<Record<number, CustomField>>;
  public readonly selectionEnabled: boolean;

  public lastEmitCount: number;
  public itemSize: number;

  constructor(
    public customFieldsStore: CustomFieldsStore,
    private store: Store<any>
  ) {
    super();

    this.items$ = combineLatest([
      this.store.select(selectCustomFieldEntities),
      this.customFieldsStore.customFieldIds$
    ]).pipe(map(([customFields, customFieldIds]) => customFieldIds.map(id => customFields[id])));

    this.emptyPageConfig = EmptyPage.noActionConfig(
      'no-forms',
      'no-fields',
      'TEAMSPACE_MANAGEMENT.FIELDS.EMPTY',
      'normal',
      232
    );
    this.emptyPageConfig.iconConfiguration.iconHeight = 188;

    this.isEmpty$ = combineLatest([this.customFieldsStore.loading$, this.customFieldsStore.hasCustomFields$]).pipe(
      takeUntil(this.unsubscribe$),
      map(([loading, hasCustomFields]) => !loading && !hasCustomFields)
    );

    this.loading$ = this.customFieldsStore.loading$;

    this.stickyRowConfig = this.generateStickyRowConfig();

    this.itemSize = 56;

    this.selectedItems$ = new BehaviorSubject({});

    this.selectionEnabled = true;
  }

  public loadItems(): void {
    this.customFieldsStore.changeBatchSize(this.getBatchSize());
    this.customFieldsStore.loadCustomFields$({
      paging: Paging.of(0, this.getBatchSize()),
      sorting: Sorting.of('createdOn', SortDirection.DESC),
      offset: 0
    });
  }

  public onRequestNextPage(viewport: CdkVirtualScrollViewport): void {
    this.customFieldsStore.paginationResult$.pipe(take(1)).subscribe(paginationResult => {
      if (viewport && (!paginationResult || paginationResult.hasBottom)) {
        const end = viewport.getRenderedRange().end;
        const total = viewport.getDataLength();

        if (end >= total && end !== this.lastEmitCount) {
          // if scrolled while loading, this would emit multiple times - so remember last end count of items and check if it changed
          this.lastEmitCount = end;
          this.loadNextBatch();
        }
      }
    });
  }

  public loadNextBatch(): void {
    this.customFieldsStore.loadNextBatch$();
  }

  public getBatchSize(): number {
    return WindowResizeService.calculateBatchSize({
      elementSize: this.itemSize * window.innerWidth,
      valueModifier: { height: -115 }
    });
  }

  public selectionChanged(selected: boolean, item: CustomField): void {
    const items = this.selectedItems$.getValue();

    if (selected) {
      items[item.id] = item;
    } else {
      delete items[item.id];
    }

    this.selectedItems$.next(items);
    this.customFieldsStore.setSelectedCustomFields(items);
  }

  public clearSelection(): void {
    this.selectedItems$.next({});
    this.customFieldsStore.setSelectedCustomFields({});
  }

  private generateStickyRowConfig(): string[] {
    return [
      'TEAMSPACE_MANAGEMENT.FIELDS.TABLE.HEADERS.NAME',
      'TEAMSPACE_MANAGEMENT.FIELDS.TABLE.HEADERS.USED_IN_FORMS',
      'TEAMSPACE_MANAGEMENT.FIELDS.TABLE.HEADERS.TYPE'
    ];
  }
}
