<celum-dialog (onCloseAll)="closeDialog()">
  <celum-dialog-header>
    <span> {{ 'WORKROOM_TASK.SUBTASK.CONVERT_TO_TASK.DIALOG_HEADLINE' | translate }} </span>
  </celum-dialog-header>

  <celum-dialog-content>
    <p class="convert-to-task_field-label">
      {{ 'WORKROOM_TASK.SUBTASK.CONVERT_TO_TASK.DIALOG_TEXT' | translate }}
    </p>

    <mat-form-field class="convert-to-task_form-field" celum-form-field *ngIf="taskLists.length > 1; else singleList">
      <mat-select
        [placeholder]="'FILES.CREATE_TASK.DIALOG.PLACEHOLDER' | translate"
        [formControl]="taskListFormControl">
        <mat-option *ngFor="let taskList of taskLists; trackBy: trackByFn" [value]="taskList.id">
          {{ taskList.name.toUpperCase() }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-template #singleList>
      <div class="create-task_single-list">{{ taskLists[0]?.name.toUpperCase() }}</div>
    </ng-template>
  </celum-dialog-content>

  <celum-dialog-footer
    confirmBtnText="WORKROOM_TASK.SUBTASK.CONVERT_TO_TASK.CONFIRM_BUTTON"
    cancelBtnText="WORKROOM_TASK.SUBTASK.CONVERT_TO_TASK.CANCEL_BUTTON"
    [valid]="taskListFormControl.valid"
    [loading]="isLoading"
    (onConfirm)="convertToTask()"
    (onCancel)="closeDialog()">
  </celum-dialog-footer>
</celum-dialog>
