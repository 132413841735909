import { createAction, props } from '@ngrx/store';

import { Subtask } from './subtask.model';

export const SubtaskUpsertOne = createAction(
  '[Subtask] Upsert One',
  props<{ task: Subtask; propertiesToUpdate?: string[] }>()
);
export const SubtaskUpsertMany = createAction(
  '[Subtask] Upsert Many',
  props<{ tasks: Subtask[]; propertiesToUpdate?: string[] }>()
);

export const SubtaskDeleteOne = createAction('[Subtask] Delete One', props<{ task: Subtask }>());
