import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { CustomTranslateLoader } from '@celum/ng2base';
import { ImportIntentEffects } from '@celum/work/app/content-hub/store/import-intent.effects';
import { CommentEffects } from '@celum/work/app/core/api/comment/comment.effects';
import { ContentItemEffects } from '@celum/work/app/core/api/content-item/content-item.effects';
import { FileEffects } from '@celum/work/app/core/api/file/file.effects';
import { FolderEffects } from '@celum/work/app/core/api/folder/folder.effects';
import { ItemLinkEffects as CoreTaskLinkEffects } from '@celum/work/app/core/api/item-link/item-link.effects';
import { PersonEffects } from '@celum/work/app/core/api/person';
import { SubtaskEffects } from '@celum/work/app/core/api/subtask/subtask.effects';
import { TaskEffects } from '@celum/work/app/core/api/task/task.effects';
import { TaskListEffects } from '@celum/work/app/core/api/task-list/task-list.effects';
import { TeamspaceEffects } from '@celum/work/app/core/api/teamspace/teamspace.effects';
import { TeamspaceMemberEffects } from '@celum/work/app/core/api/teamspace-member';
import { WorkroomEffects } from '@celum/work/app/core/api/workroom/workroom.effects';
import { WorkroomContributorEffects } from '@celum/work/app/core/api/workroom-contributor/workroom-contributor.effects';
import { TemplateChooserEffects } from '@celum/work/app/core/api/workroom-creator/template-chooser.effects';
import { WorkroomGroupEffects } from '@celum/work/app/core/api/workroom-group/workroom-group.effects';
import { ErrorModule } from '@celum/work/app/core/error/error.module';
import { RouterEffects } from '@celum/work/app/core/router/router.effects';
import { TranslationUtilService } from '@celum/work/app/core/translations/translation-util.service';
import { UserPilotEffects } from '@celum/work/app/core/user-pilot/user-pilot.effects';
import { WebsocketModule } from '@celum/work/app/core/websocket/websocket.module';
import { CustomFormStateEffects } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/custom-form/store/custom-form-state.effects';
import { SubtaskStateEffects } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/subtasks/store/subtask-state.effects';
import { ItemLinkEffects } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/task-link/store/item-link.effects';
import { environment } from '@celum/work/env/environment';

import { MarkerEffects } from './api/marker/marker.effects';
import { metaReducers, reducers } from './core.state';
import { LocalStorageService } from './local-storage/local-storage.service';
import { EntitiesModule } from './model/entities.module';
import { NotificationService } from './notifications/notification.service';
import { CustomSerializer } from './router/custom-serializer';
import { UiStateModule } from './ui-state/ui-state.module';
import { UserPilot } from './user-pilot/user-pilot.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,

    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false
      }
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'Workrooms',
          connectInZone: true
        }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translationFactory,
        deps: [HttpClient]
      }
    }),
    EntitiesModule,
    EffectsModule.forRoot([
      PersonEffects,
      TeamspaceMemberEffects,
      WorkroomEffects,
      WorkroomContributorEffects,
      TaskEffects,
      SubtaskEffects,
      MarkerEffects,
      SubtaskStateEffects,
      TaskListEffects,
      CommentEffects,
      CoreTaskLinkEffects,
      ContentItemEffects,
      TeamspaceEffects,
      FolderEffects,
      FileEffects,
      WorkroomGroupEffects,
      TemplateChooserEffects,
      RouterEffects,
      UserPilotEffects,
      ItemLinkEffects,
      CustomFormStateEffects,
      ImportIntentEffects
    ]),
    UiStateModule,
    ErrorModule,
    WebsocketModule
  ],
  declarations: [],
  providers: [NotificationService, LocalStorageService, UserPilot, TranslationUtilService],
  exports: [TranslateModule, EntitiesModule]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}

export function translationFactory(http: HttpClient): CustomTranslateLoader {
  return new CustomTranslateLoader(http, ['', 'common', 'shared', 'sharing', 'annotations'], false, '/assets');
}
