import { RobotActionType } from '@celum/work/app/core/model/entities/workroom/robot.model';

import { ContentHubStrategy } from './content-hub-strategy';
import { ContentHubSortField } from './content-hub.model';

export class ContentHub63XStrategy extends ContentHubStrategy {
  public defaultStrategy(): ContentHubStrategy {
    return undefined;
  }

  public buildNumber(): string {
    return '6.3.0';
  }

  public supportsRelayCalls(): boolean {
    return false;
  }

  public getSupportedContentHubAction(): { [key: string]: RobotActionType[] } {
    return {};
  }

  public supportsImportCollection(): boolean {
    return false;
  }

  public getSupportedContentHubSortFields(): ContentHubSortField[] {
    return [];
  }

  public supportsStringContentItemIds(): boolean {
    return false;
  }
}
