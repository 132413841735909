import { createSelector } from '@ngrx/store';

import { TargetContext } from '@celum/work/app/core/model/entities/item-link/item-link.model';

import { itemLinkAdapter } from './item-link.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = itemLinkAdapter.getSelectors();

export const selectItemLinks = createSelector(selectEntitiesState, (state: EntitiesState) => state.itemLinks);
export const selectAllItemLinks = createSelector(selectItemLinks, selectAll);
export const selectItemLinksEntities = createSelector(selectItemLinks, selectEntities);
export const selectItemLinkById = (id: number) => createSelector(selectItemLinksEntities, itemLink => itemLink[id]);
export const selectItemLinkBySourceId = (sourceId: string) =>
  createSelector(selectAllItemLinks, itemLinks => itemLinks.filter(itemLink => itemLink.sourceId === sourceId));

export const selectPortalByFileId = (fileId: string) =>
  createSelector(selectItemLinkBySourceId(fileId), selectAllItemLinks, (_, allItemLinks) => {
    const linkToPortal = allItemLinks.find(
      itemLink => itemLink.sourceId === fileId && itemLink.targetContext === TargetContext.PORTAL
    );

    return linkToPortal ? linkToPortal : null;
  });
