import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { CelumDialog, CelumDialogConfiguration, ColorConstants } from '@celum/common-components';
import { CelumValidators, ReactiveComponent } from '@celum/ng2base';

import { ContentItem } from '../../../../../../core/model/entities/content-item/content-item.model';
import { FileUpdate } from '../../../../../../core/model/entities/file/file.actions';
import { File, FileType } from '../../../../../../core/model/entities/file/file.model';
import { FolderUpdate } from '../../../../../../core/model/entities/folder/folder.actions';
import { Folder } from '../../../../../../core/model/entities/folder/folder.model';
import { INVALID_CHARS } from '../../common';

@Component({
  selector: 'content-item-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentItemRenameDialog
  extends ReactiveComponent
  implements CelumDialog<ContentItemRenameDialogConfiguration>, OnInit
{
  public headerText: SafeHtml;
  public color: string;
  public contentItem: Folder | File;
  public contentItemName: string;
  public renameContentItemActionForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<ContentItemRenameDialog>,
    private store: Store<any>,
    private translateService: TranslateService,
    private fb: UntypedFormBuilder
  ) {
    super();
  }

  public ngOnInit(): void {
    this.createDialogContent();
    this.initFormGroup();
  }

  public configure(config: ContentItemRenameDialogConfiguration): void {
    this.color = config.color;
    this.contentItem = config.contentItem;
  }

  public selectionRangeFn(val: string): number {
    const lastIdx = val.lastIndexOf('.');

    if (lastIdx > -1) {
      return lastIdx;
    } else {
      return val.length;
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public renameContentItem(): void {
    const newName = this.renameContentItemActionForm.get('name').value;
    if (this.isFile(this.contentItem)) {
      this.store.next(
        FileUpdate({
          file: this.contentItem,
          propertiesToUpdate: { name: newName } as File
        })
      );
    } else {
      this.store.next(
        FolderUpdate({
          folder: this.contentItem,
          propertiesToUpdate: { name: newName } as Folder
        })
      );
    }
    this.dialogRef.close();
  }

  private createDialogContent(): void {
    this.headerText = this.translateService.instant(
      this.isFile(this.contentItem) ? 'FILES.DIALOG.RENAME' : 'FOLDER.DIALOG.RENAME'
    );
  }

  private initFormGroup(): void {
    this.contentItemName = this.contentItem.name;
    this.renameContentItemActionForm = this.fb.group({
      name: new UntypedFormControl(this.contentItemName, [
        CelumValidators.required,
        CelumValidators.validCharacters(INVALID_CHARS),
        CelumValidators.maxLength(250)
      ])
    });
  }

  private isFile(contentItem: ContentItem): contentItem is File {
    return contentItem.entityType.id === FileType.TYPE_KEY;
  }
}

export class ContentItemRenameDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(public contentItem: Folder | File) {
    super('main');
  }
}
