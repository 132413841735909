import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AbstractWebsocketListener } from '@celum/work/app/core/websocket/abstract-websocket-listener';

import { AbstractWebsocketWatcher } from '../websocket/watcher/abstract-websocket-watcher';

@Injectable({ providedIn: 'root' })
export class NotificationWebsocketListener<T> extends AbstractWebsocketListener {
  public clientNotificationsSubj: Subject<T> = new Subject<T>();

  constructor(public websocketWatcher: AbstractWebsocketWatcher) {
    super(websocketWatcher);
    this.startListening();
  }

  public startListening(): void {
    this.watchUserTopic<T>(`client-notification`).subscribe(clientNotification =>
      this.clientNotificationsSubj.next(clientNotification)
    );
  }
}
