import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Compiler,
  Component,
  ComponentFactory,
  ComponentRef,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';

import { TaskList } from '../../../core/model/entities/task';
import { Template } from '../../../core/model/entities/template/template.model';
import { PermissionCheckStrategy } from '../../../pages/workroom-creator/services/permission-check-strategy';

@Component({
  selector: 'workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WorkflowComponent implements AfterViewInit, OnChanges, OnDestroy {
  @HostBinding('class.workflow') public hostCls = true;

  @Input() public permissionCheckStrategy: PermissionCheckStrategy;
  @Input() public template: Template;
  @Input() public taskLists: TaskList[];
  @Input() public processViewHeight: string;

  @Output() public readonly processViewHeightChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('jointjsOutlet', {
    read: ViewContainerRef,
    static: true
  })
  public jointjsOutlet: ViewContainerRef;

  @ViewChild('processViewResizer', {
    read: ViewContainerRef,
    static: true
  })
  public processViewResizer: ViewContainerRef;

  // any. DO NOT import TransitionsJointjsComponent ANYWHERE. if you do it will cause including of Jquery, lodash and backbone in vendor.js
  // https://jira.celum.company/browse/WR2-1547
  public jointjsOutletCmpRef: ComponentRef<any>;
  public compFactory: ComponentFactory<any>;

  public processViewClosed: boolean;
  public resizing: boolean;

  constructor(
    private compiler: Compiler,
    private injector: Injector
  ) {}

  public ngAfterViewInit(): void {
    this.processViewResizer.element.nativeElement.style.height = this.processViewHeight;
    this.loadJointjsModule();
  }

  public ngOnChanges({ taskLists }: SimpleChanges): void {
    if (!taskLists?.isFirstChange() && taskLists?.currentValue) {
      this.renderJointjsComponent();
    }
  }

  public ngOnDestroy(): void {
    const processViewHeight: string = this.processViewResizer.element.nativeElement.style.height;
    this.processViewHeightChange.emit(processViewHeight);
  }

  public onTransitionsChange(): void {
    this.renderJointjsComponent();
  }

  public loadJointjsModule(): void {
    import('@celum/work/app/workroom-wizard/jointjs/jointjs.module')
      .then(m => m.JointjsModule)
      .then(elementModuleOrFactory => this.compiler.compileModuleAsync(elementModuleOrFactory))
      .then(moduleFactory => {
        const entryComponent = (moduleFactory.moduleType as any).entry;
        const moduleRef = moduleFactory.create(this.injector);
        this.compFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(entryComponent);
        this.renderJointjsComponent();
      });
  }

  public renderJointjsComponent(): void {
    this.jointjsOutlet.clear();
    this.jointjsOutletCmpRef = this.jointjsOutlet.createComponent(this.compFactory);
    this.jointjsOutletCmpRef.instance.init(
      this.getTaskLists(this.template.taskLists),
      this.template.workroomConfiguration.transitions
    );
  }

  public onSliderActive(active: boolean): void {
    this.resizing = active;
  }

  // TODO @anybody remove this with https://celum.atlassian.net/browse/WR2-2023
  public getTaskList(taskList: TaskList): TaskList {
    return {
      ...taskList,
      id: Number(taskList.id)
    } as any;
  }

  public getTaskLists(taskLists: TaskList[]): TaskList[] {
    return taskLists.map(this.getTaskList);
  }
}
