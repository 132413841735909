import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Permission, PermissionService, RoleName, RolesWithPermissions } from '@celum/work/app/core/api/permission';
import { Contributor } from '@celum/work/app/core/model/entities/contributor/contributor.model';
import { Person } from '@celum/work/app/core/model/entities/person/person.model';
import { Workroom } from '@celum/work/app/core/model/entities/workroom/workroom.model';
import { Roles } from '@celum/work/app/core/model/roles.model';
import { selectTenantTeamspace } from '@celum/work/app/core/ui-state/ui-state.selectors';
import {
  selectCurrentWorkroom,
  selectHasPermissionForCurrentWorkroom,
  selectLoggedInPersonHasRoleForCurrentWorkroom
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';

import { selectContributorByUserIdAndWorkroomId } from '../../core/model/entities/contributor/contributor.selectors';

@Injectable({ providedIn: 'root' })
export class PermissionUtil {
  constructor(
    private store: Store<any>,
    private permissionService: PermissionService
  ) {}

  public rolesToPermissions(roles: Roles[]) {
    const permissions: string[] = [];
    roles.forEach(role => {
      const found = this.permissionService.rolePermissionMapping.roles.find(it => it.name === role);
      if (found?.permissions) {
        permissions.push(...found.permissions);
      }
    });
    return permissions;
  }

  public hasTeamspacePermission(permission: string): Observable<boolean> {
    return this.store.select(selectTenantTeamspace).pipe(
      filter(teamspace => !!teamspace),
      map(({ permissions }) => permissions?.permissions.includes(permission))
    );
  }

  public hasTeamspaceRole(role: RoleName): Observable<boolean> {
    return this.store.select(selectTenantTeamspace).pipe(
      filter(teamspace => !!teamspace),
      map(({ permissions }) => permissions?.roles.map(({ name }) => name).includes(role))
    );
  }

  public hasWorkroomPermission(permission: Permission): Observable<boolean> {
    return this.store.select(selectHasPermissionForCurrentWorkroom(permission));
  }

  public hasRoleForCurrentWorkroom(role: Roles): Observable<boolean> {
    return this.store.select(selectLoggedInPersonHasRoleForCurrentWorkroom(role));
  }

  /**
   * Checks if person has a specific role in a specified workroom
   * @param person person to be evaluated
   * @param workroom workroom to be evaluated
   * @param role role to check for
   * @returns true if has the role, false if not
   */
  public hasWorkroomRole(role: Roles, person: Person, workroom: Workroom): Observable<boolean> {
    return this.getWorkroomRoles(person, workroom).pipe(map(roles => roles.includes(role)));
  }

  public hasAnyWorkroomRole(roles: Roles[], person: Person, workroom: Workroom): Observable<boolean> {
    return this.getWorkroomRoles(person, workroom).pipe(map(wrRoles => roles.some(role => wrRoles.includes(role))));
  }

  public getWorkroomRoles(person: Person, workroom: Workroom): Observable<Roles[]> {
    return this.store
      .select(selectContributorByUserIdAndWorkroomId(person.id, workroom?.id))
      .pipe(map((entity: Contributor) => entity?.roles || []));
  }

  public checkDynamicPermission(isTLO: boolean, checkPermission: string): Observable<any> {
    return this.store
      .select(selectCurrentWorkroom)
      .pipe(map(workroom => isTLO && this.isPermissionAllowed(checkPermission, workroom?.permissions)));
  }

  private isPermissionAllowed(requiredPermission: string, rolesWithPermissions: RolesWithPermissions) {
    return rolesWithPermissions?.permissions
      .concat(this.rolesToPermissions([Roles.TASK_LIST_OWNER]))
      .includes(requiredPermission);
  }
}
