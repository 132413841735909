import { Entity } from '../../core/model';
import { ContentItemType } from '../../core/model/entities/content-item/content-item.model';
import { File } from '../../core/model/entities/file/file.model';
import { Folder } from '../../core/model/entities/folder/folder.model';
import { ListResult } from '../../core/model/list-result';
import { ProgressTaskFilePayload } from '../../progress-task/store/progress-task.model';

export interface ContentHubSort {
  field: string;
  direction: 'ASC' | 'DESC';
}

export enum ContentHubSortField {
  ID = 'id',
  NAME = 'name',
  ORIGINAL_FILE_NAME = 'originalFileName',
  MEDIA_TYPE = 'mediaType',
  CREATION_DATE = 'creationDate',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  FILE_EXTENSION = 'fileExtension',
  ORIGINAL_FILE_SIZE = 'originalFileSize',
  CREATED_BY = 'createdBy',
  LAST_MODIFIED_BY = 'lastModifiedBy'
}

export interface ContentHubPaging {
  page: number;
  pageSize: number;
}

export class AssetDTO {
  constructor(
    public id: string,
    public name: string,
    public fileSize: number,
    public parentCollectionId?: number
  ) {}
}

export interface ContentHubAssetTicketDTO extends ProgressTaskFilePayload {
  assetId: string;
  assetName: string;
  status: ContentHubAssetTicketStatus;
  assetSize: number;
  file: Entity<number, ContentItemType>;
}

export enum ContentHubAssetTicketStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  ABORTED = 'ABORTED',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED'
}

export interface Collection {
  id: string;
  title: { [id: string]: string };
  name: string;
  nodeType: NodeType;
}

export interface NodeType {
  id: number;
  name: string;
}

export interface AggregationResponse {
  collections: Collection[];
  files: ListResult<File>;
  folders: ListResult<Folder>;
}
