import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { DataUtil, EntityType } from '@celum/core';
import { RolesWithPermissions } from '@celum/work/app/core/api/permission';
import { MemberType } from '@celum/work/app/core/model/entities/member';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import { InvitedPerson } from '@celum/work/app/core/model/entities/template/template.model';

import { Automator, Rule } from './robot.model';
import { Color } from '../../color.model';
import { Entity } from '../../entity';
import { Contributor, ContributorType } from '../contributor/contributor.model';
import { Folder, FolderType } from '../folder/folder.model';
import { Person } from '../person/person.model';

export enum WorkroomStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE'
}

export interface Workroom extends Entity<number, WorkroomType> {
  libraryId: string;
  renditionLibraryId: string;
  name: string;
  description: string;
  creationDate: number;
  dueDate: number;
  icon: string;
  status: WorkroomStatus;
  color: Color;
  headerUrl: string;
  teamspaceId: number;
  slibResourceToken: string;
  driveSubscribed: boolean;
  contentHubRepositoryId: string;
  contributorIds: string[];
  configuration: WorkroomConfiguration;
  /**
   * Returns the root {@link Folder} of this workroom.
   * @param store the store
   */
  rootFolder: (store: Store<any>) => Observable<Folder>;
  /**
   * Returns the comment attachments {@link Folder} of this workroom.
   * @param store the store
   */
  commentAttachmentsFolder: (store: Store<any>) => Observable<Folder>;
  /**
   * Returns the trash bin {@link Folder} of this workroom.
   * @param store the store
   */
  trashBinFolder: (store: Store<any>) => Observable<Folder>;
  /**
   * Returns all {@link Person Persons} which contribute to this workroom.
   * @param store the store
   */
  persons: (store: Store<any>) => Observable<Person[]>;
  /**
   * Returns all {@link Contributor} entities this workrooms is reference by.
   * @param store the store
   */
  contributors: (store: Store<any>) => Observable<Contributor[]>;
  /**
   * Returns the {@link Contributor} entity this workroom and the given personId is reference by.
   * @param store       the store
   * @param personId    id of the person
   */
  contributor: (store: Store<any>, personId: number) => Observable<Contributor>;
  permissions?: RolesWithPermissions;
  modifiedDate?: number;
  lastActivity?: number;
  numberOfFiles?: number;
  numberOfTasks?: number;
  contentItemCount?: number;
}

export class WorkroomType implements EntityType {
  public static readonly TYPE_KEY: string = 'Workroom';

  private static _instance: WorkroomType;

  public id = WorkroomType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): WorkroomType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(ContributorType.TYPE_KEY)) {
        relationDefinitions.contributors = [
          ContributorType.instance().getSchema({ relationsFor: [MemberType.TYPE_KEY] })
        ];
      }

      if (relations.relationsFor.includes(FolderType.TYPE_KEY)) {
        relationDefinitions.rootFolder = FolderType.instance().getSchema({});
        relationDefinitions.commentAttachmentsFolder = FolderType.instance().getSchema({});
        relationDefinitions.trashBinFolder = FolderType.instance().getSchema({});
      }
    }

    return new schema.Entity(WorkroomType.TYPE_KEY, relationDefinitions);
  }
}

export interface WorkroomState extends EntityState<Workroom> {}

export interface WorkroomTemplateInputParams {
  teamspaceId: number;
  repositoryId: string;
  template: WorkroomTemplateDTO;
  people: InvitedPerson[];
}

export interface WorkroomTransitionRule {
  from: number;
  to: number;
  reverse: boolean;
}

export interface WorkroomTemplateDTO {
  version?: number;
  id?: number;
  name: string;
  description?: string;
  dueDate?: string;
  color: Color;
  colorVariant: number;
  categoryId?: number;
  taskLists?: TaskList[];
  workroomConfiguration?: WorkroomConfiguration;
}

export interface WorkroomValidationValues {
  maxNameLength: number;
  maxDescriptionLength: number;
  hintOffsetPercentage: number;
  contentItemCount: number;
}

export enum FeatureType {
  WORKFLOW = 'WORKFLOW'
}

export interface Feature {
  name: FeatureType;
}

export interface WorkroomConfiguration {
  version: number;
  features: Feature[];
  transitions?: WorkroomTransitionRule[];
  rules: Rule[];
  automators: Automator[];
}

export const WORKROOM_VALIDATION_VALUES: WorkroomValidationValues = {
  maxNameLength: 50,
  maxDescriptionLength: 1000,
  hintOffsetPercentage: 0.8,
  contentItemCount: 1000
};
