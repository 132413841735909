<div class="search-and-select_top" *ngIf="isSearchEnabled">
  <span class="search-and-select_search-label" *ngIf="searchFieldLabelKey">
    {{ searchFieldLabelKey | translate }}
  </span>
  <search-field
      [autoFocused]="true"
      placeholder="{{ placeholder | translate }}"
      [showHint]="minChars > 0"
      [minChars]="minChars"
      [reserveHintSpace]="true"
      (valueChange)="onSearchValueChanged($event)"
      data-component-id="search-select">
  </search-field>
</div>
<div class="search-and-select_content flex-column-scrollbar celum-scrollbar">
  <div *ngIf="isLoading; else content" class="search-and-select_loading-mask-container">
    <loading-mask [background]="'white'" class="search-and-select_loading-mask"></loading-mask>
  </div>

  <ng-template #content>
    <ng-content select="[extra-option]" *ngIf="hasNoResults() === false"></ng-content>
  
    <search-and-select-entry
        *ngFor="let element of elements; trackBy: trackByFn"
        [dataElementId]="elementIdentifier(element)"
        [elementId]="element.id"
        [transitionName]="$any(element).name"
        [showCheckbox]="showCheckbox"
        [selected]="isChecked(element)"
        (selectionChange)="toggleValue($event, element)">
      <ng-container *ngTemplateOutlet="elementTemplate; context: { element: element }"></ng-container>
    </search-and-select-entry>
  
    <div class="search-and-select_has-more" *ngIf="hasMore">
      <ng-container *ngIf="hasMoreAmount && hasMoreKeySingular && hasMoreKeyPlural; else simpleHasMore">
        {{ (hasMoreAmount === 1 ? hasMoreKeySingular : hasMoreKeyPlural) | translate: { hasMoreAmount } }}
      </ng-container>
      <ng-template #simpleHasMore>
        {{ hasMoreKey | translate }}
      </ng-template>
    </div>
    <div class="search-and-select_has-more" *ngIf="hasNoResults()">
      {{ noResultsKey | translate: {searchValue: currentSearchValue} }}
      <ng-content select="[fallback-action]"></ng-content>
    </div>
  </ng-template>
</div>
