import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject, combineLatest, filter, map, mergeMap, Observable, take } from 'rxjs';

import { AVATAR_SIZE, ColorConstants, IconConfiguration } from '@celum/common-components';
import { InvitationStatus } from '@celum/work/app/core/model/entities/member';
import { Person } from '@celum/work/app/core/model/entities/person';
import { Roles } from '@celum/work/app/core/model/roles.model';
import { WorkroomAvatarConfiguration } from '@celum/work/app/shared/components/workroom-avatar/workroom-avatar-configuration';
import { AvatarUtil } from '@celum/work/app/shared/util/avatar-util';

import { RoleOption } from './role-option.model';

@Component({
  selector: 'multiline-template-person',
  templateUrl: './multiline-person-template.component.html',
  styleUrls: ['./multiline-person-template.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultilinePersonTemplate implements OnChanges, OnInit {
  @Input() public person: Person;
  @Input() public invitationStatus: InvitationStatus;
  @Input() public initialRole: Roles = Roles.CONTRIBUTOR;
  @Input() public isDeactivated: boolean;
  @Input() public additionalInfo: string;
  @Input() public deletable = false;
  @Input() public withStatus = true;
  @Input() public withRoles = true;
  @Input() public showDriveWarnings = false;

  @Output() public readonly deleted: EventEmitter<Person> = new EventEmitter<Person>();
  @Output() public readonly roleChanged: EventEmitter<Roles> = new EventEmitter<Roles>();

  public displayName$: Observable<string>;
  public avatar$: Observable<WorkroomAvatarConfiguration>;
  public roleOptions$: Observable<RoleOption[]>;
  public initialRoleOption$: Observable<RoleOption>;

  public readonly closeIcon = IconConfiguration.medium('cancel-m').withColor(ColorConstants.BLUE_GRAY_700);
  public readonly driveWarningIcon = IconConfiguration.small('wr2-warning').withColor(ColorConstants.WARNING);
  public readonly newUserIcon = IconConfiguration.large('icon-new-user')
    .withColor(ColorConstants.BLUE_GRAY_700)
    .withIconSize(30);
  public emailUserIcon = IconConfiguration.small('invite-mail').withIconSize(16);

  private personSubject$: BehaviorSubject<Person | null> = new BehaviorSubject(null);
  private selectedRoleSubject$: BehaviorSubject<Roles> = new BehaviorSubject(this.initialRole);

  constructor(private avatarUtil: AvatarUtil) {}

  public ngOnInit(): void {
    this.displayName$ = this.getDisplayName$();
    this.avatar$ = this.getAvatar$();
    this.roleOptions$ = this.getRoleOptions$();
    this.initialRoleOption$ = this.getInitialRole$();

    this.selectedRoleSubject$.next(this.initialRole);
  }

  public ngOnChanges({ person }: SimpleChanges): void {
    if (person?.currentValue) {
      this.personSubject$.next(person.currentValue);
    }
  }

  public onItemDeleted(person: Person) {
    this.deleted.emit(person);
  }

  public onRoleChanged(event: MatSelectChange) {
    this.roleChanged.emit(event.value.role);
    this.selectedRoleSubject$.next(event.value.role);
  }

  public trackByFn(_: number, item: RoleOption) {
    return item.role;
  }

  public compareRoleOptions(roleOption1: RoleOption, roleOption2: RoleOption): boolean {
    return roleOption1 && roleOption2 ? roleOption1.role === roleOption2.role : roleOption1 === roleOption2;
  }

  private getInitialRole$(): Observable<RoleOption> {
    return this.roleOptions$.pipe(
      take(1),
      filter(Boolean),
      map(roleOptions => roleOptions.find(roleOption => roleOption.role === this.initialRole))
    );
  }

  private getRoleOptions$(): Observable<RoleOption[]> {
    return this.personSubject$.pipe(
      map(() => {
        const moderatorDisabled = [InvitationStatus.INVITED, InvitationStatus.PENDING_APPROVAL].includes(
          this.invitationStatus
        );
        const moderatorDisabledTooltipKey = moderatorDisabled ? 'PEOPLE.INVITE.ROLES.DISABLED_MODERATOR' : '';

        return [
          {
            role: Roles.CONTRIBUTOR,
            text: 'PEOPLE.INVITE.ROLES.CONTRIBUTOR',
            disabled: false
          },
          {
            role: Roles.MODERATOR,
            text: 'PEOPLE.INVITE.ROLES.MODERATOR',
            disabled: moderatorDisabled,
            disabledTooltipKey: moderatorDisabledTooltipKey
          },
          {
            role: Roles.VISITOR,
            text: 'PEOPLE.INVITE.ROLES.VISITOR',
            disabled: false
          }
        ];
      })
    );
  }

  private getDisplayName$(): Observable<string> {
    return this.personSubject$.pipe(map(person => this.avatarUtil.getUserDisplayName(person)));
  }

  private getAvatar$(): Observable<WorkroomAvatarConfiguration> {
    return combineLatest([this.personSubject$, this.selectedRoleSubject$]).pipe(
      mergeMap(([person, selectedRole]) =>
        this.avatarUtil.getAvatarConfigWithImage({ person, size: AVATAR_SIZE.m }).pipe(
          map(config => {
            return this.avatarUtil.handleRoleBadge(config, [selectedRole]);
          })
        )
      )
    );
  }
}
