import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { CelumPropertiesProvider, PaginationResult } from '@celum/core';
import { WorkroomService } from '@celum/work/app/core/api/workroom';
import { MetaInfo } from '@celum/work/app/core/model';
import { workroomUpdatableProperties } from '@celum/work/app/core/model/entities/workroom';
import {
  WorkroomGroup,
  WorkroomGroupCreate,
  WorkroomGroupType
} from '@celum/work/app/core/model/entities/workroom/group/workroom-group.model';
import {
  WorkroomGroupItem,
  WorkroomGroupItemType
} from '@celum/work/app/core/model/entities/workroom/group-item/workroom-group-item.model';
import { RequireAtLeastOne } from '@celum/work/app/core/model/requires-at-least-one';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

import { ResultConsumerService } from '../../communication/result-consumer.service';
import { WorkroomType } from '../../model/entities/workroom/workroom.model';
import { Paging } from '../../model/paging.model';
import { Sorting } from '../../model/sorting.model';

@Injectable({ providedIn: 'root' })
export class WorkroomGroupService {
  public groupItemMetaInfo = MetaInfo.of(
    [WorkroomGroupItemType.TYPE_KEY, ...this.workroomService.METAINFO_WITH_CONTRIBUTORS_AND_SYSTEM_FOLDERS.entities],
    { results: [WorkroomGroupItemType.instance().getSchema({ relationsFor: [WorkroomType.TYPE_KEY] })] },
    [WorkroomGroupItemType.TYPE_KEY],
    'results'
  );

  private readonly GROUP_ITEMS_BATCH_SIZE = 10;

  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService,
    private workroomService: WorkroomService
  ) {
    this.groupItemMetaInfo.partialUpdates[WorkroomType.TYPE_KEY] = [...workroomUpdatableProperties, 'lastActivity'];
  }

  public deleteWorkroomGroup(groupId: number): Observable<void> {
    return this.httpClient.delete<void>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/workroom-groups/${groupId}`,
      STRONGLY_CONSISTENT_OPTION
    );
  }

  public moveWorkroom(workroomGroupItemId: number, targetGroupId: number, sort: number): Observable<WorkroomGroupItem> {
    const body = {
      workroomGroupItemId,
      targetGroupId,
      sort
    };
    return this.httpClient
      .post<WorkroomGroupItem>(`${CelumPropertiesProvider.properties.httpBaseAddress}/workroom-group-items/move`, body)
      .pipe(
        map(workroomGroupItem => {
          const metaInfo = MetaInfo.of([WorkroomGroupItemType.TYPE_KEY], WorkroomGroupItemType.instance().getSchema());
          const res = this.resultConsumerService.translateAndAddToStore(workroomGroupItem, metaInfo);
          return res.entities[WorkroomGroupItemType.TYPE_KEY][0] as WorkroomGroupItem;
        })
      );
  }

  public patchWorkroomGroup(
    groupId: number,
    propertiesToUpdate: RequireAtLeastOne<WorkroomGroup, 'sort' | 'name'>
  ): Observable<WorkroomGroupItemListResult> {
    return this.httpClient
      .patch<WorkroomGroupItemListResult>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/workroom-groups/${groupId}`,
        { ...propertiesToUpdate }
      )
      .pipe(map(workroomGroup => this.processWorkroomGroupResult(workroomGroup)));
  }

  public createWorkroomGroup(group: WorkroomGroupCreate): Observable<WorkroomGroupItemListResult> {
    return this.httpClient
      .post<WorkroomGroupItemListResult>(`${CelumPropertiesProvider.properties.httpBaseAddress}/workroom-groups`, group)
      .pipe(map(workroomGroup => this.processWorkroomGroupResult(workroomGroup)));
  }

  public loadGroups(): Observable<WorkroomGroupItemListResult[]> {
    const body = {
      paging: Paging.of(0, this.GROUP_ITEMS_BATCH_SIZE),
      sorting: Sorting.of('name', SortDirection.ASC)
    };
    return this.httpClient
      .post(CelumPropertiesProvider.properties.httpBaseAddress + '/workroom-groups/search', body)
      .pipe(
        map((res: any[]) => {
          const groupItemsResults: WorkroomGroupItemListResult[] = res.reduce((acc, group) => {
            const result = this.resultConsumerService.translateAndAddToStore(
              group.workroomGroupItems,
              this.groupItemMetaInfo
            );
            acc.push({
              groupId: group.id,
              groupItems: result.entities[WorkroomGroupItemType.TYPE_KEY] as WorkroomGroupItem[],
              paginationResult: result.paginationResult
            });
            return acc;
          }, []);

          const groupMetaInfo = MetaInfo.of(
            [WorkroomGroupType.TYPE_KEY],
            [WorkroomGroupType.instance().getSchema({ relationsFor: [WorkroomGroupItemType.TYPE_KEY] })]
          );
          this.resultConsumerService.translateAndAddToStore(res, groupMetaInfo);
          return groupItemsResults;
        })
      );
  }

  public loadMoreGroupItems(
    groupId: number,
    offset: number,
    batchSize?: number
  ): Observable<WorkroomGroupItemListResult> {
    const body = {
      groupId,
      paging: Paging.of(offset, batchSize || this.GROUP_ITEMS_BATCH_SIZE),
      sorting: Sorting.of('sort', SortDirection.DESC)
    };
    return this.httpClient
      .post(CelumPropertiesProvider.properties.httpBaseAddress + '/workroom-group-items/search', body)
      .pipe(
        map((res: any) => {
          const result = this.resultConsumerService.translateAndAddToStore(res, this.groupItemMetaInfo);
          return {
            groupId,
            groupItems: result.entities[WorkroomGroupItemType.TYPE_KEY] as WorkroomGroupItem[],
            paginationResult: result.paginationResult
          };
        })
      );
  }

  private processWorkroomGroupResult(res: any): WorkroomGroupItemListResult {
    const groupMetaInfo = MetaInfo.of(
      [WorkroomGroupType.TYPE_KEY],
      WorkroomGroupType.instance().getSchema({ relationsFor: [WorkroomGroupItemType.TYPE_KEY] })
    );

    this.resultConsumerService.translateAndAddToStore(res, groupMetaInfo);

    const result = this.resultConsumerService.translateAndAddToStore(res.workroomGroupItems, this.groupItemMetaInfo);
    return {
      groupId: res.id,
      groupItems: result.entities[WorkroomGroupItemType.TYPE_KEY] as WorkroomGroupItem[],
      paginationResult: result.paginationResult
    };
  }
}

export interface WorkroomGroupItemListResult {
  groupId: number;
  groupItems: WorkroomGroupItem[];
  paginationResult: PaginationResult;
}
