export const MIN_SORT = -2147483648;
export const MAX_SORT = 2147483647;

export function calculateNewSortValue(
  newIndex: number,
  entryId: number,
  list: { id: number; sort: number }[],
  ascending = true
): number {
  const sortedList = list
    .slice()
    .sort((a, b) => (ascending ? sortAscending(a.sort, b.sort) : sortDescending(a.sort, b.sort)));
  const index = sortedList.findIndex(entry => entry.id === entryId);

  let newSort;

  if (index >= 0) {
    const entry = sortedList[index];
    newSort = entry.sort;

    if (index === newIndex) {
      return newSort;
    }

    if (newIndex === 0) {
      // first position
      newSort = ascending ? MIN_SORT : MAX_SORT;
    } else if (newIndex === sortedList.length - 1) {
      // last position
      newSort = ascending ? MAX_SORT : MIN_SORT;
    } else if (index < newIndex) {
      // "higher"
      newSort = (sortedList[newIndex].sort + sortedList[newIndex + 1].sort) / 2;
    } else if (index > newIndex) {
      // "lower"
      newSort = (sortedList[newIndex].sort + sortedList[newIndex - 1].sort) / 2;
    }
  } else {
    // item moved to other list
    if (newIndex === 0) {
      // first position
      newSort = ascending ? MIN_SORT : MAX_SORT;
    } else if (newIndex === sortedList.length) {
      // last position
      newSort = ascending ? MAX_SORT : MIN_SORT;
    } else {
      // between some items
      newSort = (sortedList[newIndex - 1].sort + sortedList[newIndex].sort) / 2;
    }
  }

  return newSort;
}

export function sortAscending(a: number, b: number): number {
  return a - b;
}

export function sortDescending(a: number, b: number): number {
  return b - a;
}

// Special sorting for asset ids, post-migration
export function sortAssetIds(a: any, b: any) {
  if (!isNaN(a) && !isNaN(b)) {
    return Number(b) - Number(a);
  } else if (isNaN(a) && isNaN(b)) {
    return a.localeCompare(b);
    // If only one is a number, we can assume it's pre-migration, therefore comes first
  } else {
    return !isNaN(a) ? 1 : -1;
  }
}
