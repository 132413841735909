<celum-icon class="robot-menu-icon" data-cy="add-robot" [configuration]="robotAddIcon" [matMenuTriggerFor]="menuRef" [matTooltip]="'ROBOTS.ADD_ROBOT' | translate"></celum-icon>
<mat-menu #menuRef="matMenu" class="robot-menu">
  <ng-container *ngFor="let menuItem of robotMenuItems; trackBy: trackByMenuItemFn">
    <div *ngIf="robotVisible(menuItem.robotType) | async">
      <ng-container *ngIf="(subMenuItemsVisible(menuItem) | async) === false; then simpleMenu; else menuWithSubMenu"></ng-container>
        <ng-template #simpleMenu>
          <div
              [matTooltip]="getMenuToolTip(menuItem) | async">
            <button
                mat-menu-item
                [attr.data-cy]="'robot-option-' + menuItem.robotType"
                class="robot-menu_menu-item-button celum-menu-item"
                (configurableClick)="onMenuItemClicked(menuItem)"
                [clickDisabled]="isRobotDisabled(menuItem) | async">
              <celum-icon class="robot-menu_menu-item-icon" [configuration]="menuItem.icon"></celum-icon>
              <span class="robot-menu_menu-item-text">{{ menuItem.text }}</span>
            </button>
          </div>
        </ng-template>
        <ng-template #menuWithSubMenu>
          <div
              [matTooltip]="getParentMenuToolTip(menuItem) | async"
              [matTooltipDisabled]="(hasAllSubRobotsDisabled(menuItem) | async) === false">
            <button
                mat-menu-item
                [attr.data-cy]="'robot-option-' + menuItem.robotType"
                class="robot-menu_menu-item-button celum-menu-item"
                [class.robot-menu_menu-item--disabled]="(hasAllSubRobotsDisabled(menuItem) | async)"
                [disabled]="(hasAllSubRobotsDisabled(menuItem) | async)"
                [matMenuTriggerFor]="robotSubmenu">
              <celum-icon class="robot-menu_menu-item-icon" [configuration]="menuItem.icon"></celum-icon>
              <span class="robot-menu_menu-item-text">{{ menuItem.text }}</span>
            </button>
          </div>
          <mat-menu #robotSubmenu="matMenu" xPosition="after">
            <ng-container *ngFor="let subMenuItem of menuItem.subMenuItems; trackBy: trackBySubMenuItemFn">
              <celum-menu-item
                  class="robot-menu_menu-item"
                  [attr.data-cy]="'robot-option-' + subMenuItem.robotSubType"
                  [matTooltip]="getMenuToolTip(menuItem, subMenuItem.robotSubType) | async"
                  (configurableClick)="onMenuItemClicked(menuItem, subMenuItem.robotSubType, subMenuItem)"
                  [clickDisabled]="isRobotDisabled(menuItem, subMenuItem.robotSubType) | async">
                <span class="robot-menu_sub-menu-item-text">{{ subMenuItem.text }}</span>
              </celum-menu-item>
            </ng-container>
          </mat-menu>
        </ng-template>
    </div>
  </ng-container>
</mat-menu>
