import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { View, ViewTypes } from '@celum/annotations';
import { FileUtilService } from '@celum/work/app/shared/util/file-util.service';

import { AuthInterceptor } from '../auth/auth-interceptor.service';
import { FileFormat, FileVersion } from '../model/entities/file-version/file-version.model';

const REDIRECT_QUERY_PARAM = 'redirect';

@Injectable({ providedIn: 'root' })
export class PrintService {
  public static readonly appropriateFormats: FileFormat[] = [FileFormat.DOCUMENT, FileFormat.TEXT, FileFormat.IMAGE];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpClient: HttpClient,
    private fileUtilService: FileUtilService
  ) {}

  public printFile(fileVersion$: Observable<FileVersion>): void {
    this.fileUtilService
      .getFileView(fileVersion$)
      .pipe(
        switchMap(view => {
          const headers = { [AuthInterceptor.SKIP_AUTHORIZATION_KEY]: 'true' };

          /* Preview urls of images, include a redirect flag with true value. Since the redirect destroys CORS context 
          and the redirected call to BLOB has origin = null, resulting in CORS headers not being included. 
          To overcome, the BLOB is being get by two calls, first to get the url of it and the next to get the BLOB. 
          However, the same approach does not work for documents, in short, it causes http failure during parsing 
          the response. 
          */
          if (view.viewType === ViewTypes.ImageView) {
            return this.getImagePreview(view, headers);
          }

          return this.httpClient.get(view.previewUrl, {
            responseType: 'blob',
            headers
          });
        }),
        take(1)
      )
      .subscribe(blob => this.printBlob(blob));
  }

  public printBlob(blob: Blob): void {
    const blobUrl = URL.createObjectURL(blob);
    const iframe = this.document.createElement('iframe');

    iframe.style.display = 'none';
    iframe.src = blobUrl;
    // FF/Safari shrinks the content of iframe -> we need to prevent that
    iframe.width = '20000px';
    iframe.height = '20000px';
    iframe.onload = () => {
      setTimeout(() => {
        iframe.contentWindow.print();
        URL.revokeObjectURL(blobUrl);
      });
    };

    this.document.body.appendChild(iframe);
  }

  private getImagePreview(view: View, headers: { [x: string]: string }): Observable<Blob> {
    const previewUrl = new URL(view.previewUrl);
    previewUrl.searchParams.delete(REDIRECT_QUERY_PARAM);

    return this.httpClient.get<{ baseUrl: string; sasToken: string }>(previewUrl.href).pipe(
      switchMap(res => {
        return this.httpClient.get(res.baseUrl.concat(res.sasToken), {
          responseType: 'blob',
          headers
        });
      })
    );
  }
}
