import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import {
  CelumFormFieldSizes,
  CelumIconModule,
  CelumInputsModule,
  ColorConstants,
  IconConfiguration
} from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';

import { AutoFocusDirective } from '../../directives/auto-focus.directive';

@Component({
  selector: 'search-field',
  styleUrls: ['./search-field.component.less'],
  templateUrl: './search-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    CelumIconModule,
    MatAutocompleteModule,
    MatTooltipModule,
    AutoFocusDirective,
    CelumInputsModule,
    MatButtonModule
  ]
})
export class SearchField extends ReactiveComponent implements OnInit, OnChanges {
  @Input() public placeholder = '';
  @Input() public minChars = 1;
  @Input() public debounceTime = 300;
  @Input() public value = '';
  @Input() public showHint = false;
  @Input() public autoFocused = false;
  @Input() public reserveHintSpace = false;
  @Input() public celumFormFieldSize: CelumFormFieldSizes = 'medium';

  @Output() public readonly valueChange = new EventEmitter<string>();
  @Output() public searchInputFocus = new EventEmitter<boolean>();

  @ViewChild('searchInput') public searchInput: ElementRef;

  public searchIcon = IconConfiguration.medium('search-m', '').withColor(ColorConstants.BLUE_GRAY_600).withIconSize(20);
  public clearIcon = IconConfiguration.medium('cancel-m', '').withColor(ColorConstants.BLUE_GRAY_600).withIconSize(20);

  public searchFormCtrl: UntypedFormControl = new UntypedFormControl('');

  public ngOnInit(): void {
    this.searchFormCtrl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.debounceTime),
        filter(val => val !== undefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(val => this.valueChange.emit(val));
  }

  public ngOnChanges({ value }: SimpleChanges): void {
    if (value && value.currentValue !== this.searchFormCtrl.value) {
      this.searchFormCtrl.setValue(value?.currentValue);
    }
  }

  public clearInput() {
    this.searchFormCtrl.setValue('');
  }
}
