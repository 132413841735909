import { immerable } from 'immer';

export interface Stack<T> {
  size(): number;
  push(t: T): void;
  pop(): T;
  peek(): T;
}

export class StackImpl<T> implements Stack<T> {
  private readonly items: T[];

  constructor(items: T[] = []) {
    this.items = items;
    this[immerable] = true;
  }

  public size(): number {
    return this.items.length;
  }

  public peek(): T | undefined {
    return this.items[this.size() - 1];
  }

  public pop(): T | undefined {
    return this.items.pop();
  }

  public push(t: T): void {
    this.items.push(t);
  }
}
