<celum-simple-list [clearSelectionOnEmptySpaceClick]="false"
                   [itemTemplate]="itemTemplate"
                   [items]="collections"
                   [hasMoreBottom]="false"
                   [selectionHandler]="listSelectionHandler">

  <ng-template let-item #itemTemplate>
    <collection-item [collection]="item" [selected]="item.id === selectedCollection.id"></collection-item>
  </ng-template>
</celum-simple-list>
