import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CelumResizeButton } from './resize-button/resize-button';
import { ResizeDirective } from './resize-directive';

@NgModule({
            imports: [CommonModule],
            declarations: [ResizeDirective, CelumResizeButton],
            exports: [ResizeDirective, CelumResizeButton]
          })
export class CelumResizeModule {
}
