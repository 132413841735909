import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { DataUtil, EntityType } from '@celum/core';
import { Entity } from '@celum/work/app/core/model';
import { CustomField, CustomFieldType } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { Person, PersonType } from '@celum/work/app/core/model/entities/person/person.model';

export interface CustomForm extends Entity<number, CustomFormType> {
  name: string;
  teamspaceId: number;
  fieldsSortDictionary: { [customFieldId: string]: number };
  fieldIds: number[];
  createdById: number;
  createdOn: number;
  modifiedDate: number;
  taskCounter: number;
  createdBy: (store: Store<any>) => Observable<Person>;
  customFields: (store: Store<any>) => Observable<CustomField[]>;
}

export class CustomFormType implements EntityType {
  public static readonly TYPE_KEY: string = 'Form';

  private static _instance: CustomFormType;

  public id = CustomFormType.TYPE_KEY;
  public inheritsFrom: Set<EntityType>;

  public static instance(): CustomFormType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(PersonType.TYPE_KEY)) {
        relationDefinitions.createdBy = PersonType.instance().getSchema({});
      }

      if (relations.relationsFor.includes(CustomFieldType.TYPE_KEY)) {
        relationDefinitions.fields = [{ customField: CustomFieldType.instance().getSchema() }];
      }
    }

    return new schema.Entity(CustomFormType.TYPE_KEY, relationDefinitions);
  }
}

export interface CustomFormsState extends EntityState<CustomForm> {}
