import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { AppErrorHandler } from '@celum/work/app/core/error/app-error-handler.service';
import { ErrorEffects } from '@celum/work/app/core/error/error.effects';
import { ErrorInterceptor } from '@celum/work/app/core/error/error.interceptor';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';

@NgModule({
  imports: [EffectsModule.forFeature([ErrorEffects])],
  providers: [
    FailureHandler,
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ]
})
export class ErrorModule {}
