import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { FailureHandler } from './failure-handler.service';
import { CommunicationException } from '../communication/communication-exception';

/**
 * Application-wide error handler that adds a UI notification to the error
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  public handleError(error: Error | CommunicationException) {
    const handler = this.injector.get(FailureHandler);

    handler.handleError(error);
  }
}
