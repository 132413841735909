import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';

import { CelumDialog } from '@celum/common-components';

import { NotificationService } from '../../../../../core';
import { Permission } from '../../../../../core/api/permission';
import { TemplateChooserService } from '../../../../../core/api/workroom-creator';
import { Template } from '../../../../../core/model/entities/template/template.model';
import { TemplateCategory } from '../../../../../core/model/entities/template-category/template-category.model';
import { selectCategoriesForPermission } from '../../../../../core/model/entities/template-category/template-category.selectors';
import {
  CopyTemplateCallback,
  TemplateMaintenanceDialogConfiguration
} from '../template-maintenance-dialog-config.abstract';

@Component({
  selector: 'move-template-dialog',
  templateUrl: './move-template-dialog.component.html',
  styleUrls: ['./move-template-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoveTemplateDialogComponent implements OnInit, CelumDialog<MoveTemplateDialogConfiguration> {
  public color: string;
  public callback: CopyTemplateCallback;
  public template: Template;
  public category$: Observable<TemplateCategory>;
  public loading$ = new Subject<boolean>();

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<MoveTemplateDialogComponent>,
    private templateService: TemplateChooserService,
    private notificationService: NotificationService
  ) {}

  public configure({ color, template, callback }: MoveTemplateDialogConfiguration) {
    this.color = color;
    this.template = template;
    this.callback = callback;
  }

  public ngOnInit() {
    const { categoryId } = this.template;

    this.category$ = this.store
      .select(selectCategoriesForPermission, { permission: Permission.TEMPLATE_UPDATE })
      .pipe(map(categories => categories.filter(c => c.id !== categoryId).pop()));
  }

  public trackByFn(index: number, category: TemplateCategory): number | string {
    return category ? category.id : index;
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public moveTemplate() {
    this.loading$.next(true);

    this.withCategoryId(categoryId => {
      const resolvedCategoryId = this.template.categoryId === null ? categoryId : null;

      return this.templateService
        .move(this.template.id, resolvedCategoryId)
        .pipe(
          finalize(() => this.handleSuccessResponse()),
          catchError(() => this.handleError())
        )
        .subscribe();
    });
  }

  private handleError(): Observable<never> {
    this.notificationService.error('WORKROOM_WIZARD.ERROR');
    return EMPTY;
  }

  private handleSuccessResponse(): void {
    this.withCategoryId(categoryId => this.callback(categoryId));
    this.closeDialog();
  }

  private withCategoryId(callback: (categoryId: number) => void): void {
    this.category$.pipe(take(1)).subscribe(category => callback(category?.id));
  }
}

export class MoveTemplateDialogConfiguration extends TemplateMaintenanceDialogConfiguration {}
