import { PaginationInformation } from '@celum/core';

export interface ListResult<T> {
  results: T[];
  paginationInformation: PaginationInformation;
}

export const defaultPaginationInformation: PaginationInformation = {
  scopeId: '',
  totalElementCount: 0,
  elementsFollow: true,
  previousElementCount: 0
};

export const defaultListResult: ListResult<any> = {
  results: [],
  paginationInformation: { ...defaultPaginationInformation }
};
