import { EntityTranslator } from '@celum/work/app/core/model';

import { Template, TemplateType } from './template.model';

export class TemplateTranslator implements EntityTranslator<Template> {
  public translateToEntity(json: any): Template {
    return {
      ...json,
      taskLists: json.taskLists,
      contentHubRepositoryId: json.contentHubRepositoryId,
      workroomConfiguration: { ...(json.workroomConfiguration || {}) },
      entityType: TemplateType.instance()
    };
  }
}
