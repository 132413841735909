import { createAction, props } from '@ngrx/store';

import { ContentItem } from './content-item.model';

export const ContentItemUpsertOne = createAction(
  '[ContentItem] Upsert One',
  props<{ contentItem: ContentItem; propertiesToUpdate?: string[] }>()
);

export const ContentItemUpsertMany = createAction(
  '[ContentItem] Upsert Many',
  props<{ contentItems: ContentItem[]; propertiesToUpdate?: string[] }>()
);
