import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';

import { DataUtil } from '@celum/core';
import { EntityUtil } from '@celum/work/app/core/model';
import { VersionSwitcherResetState } from '@celum/work/app/files/detail/version-switcher/store/version-switcher.actions';

import {
  CommentAddMarker,
  CommentChangeReplyCount,
  CommentClearMarkers,
  CommentDeleteOne,
  CommentEditMarker,
  CommentRemoveMarker,
  CommentRemoveSelection,
  CommentSelectOne,
  CommentUpsertMany,
  CommentUpsertOne,
  ResetSelection
} from './comment.actions';
import { Comment, commentBasicProperties, CommentState } from './comment.model';
import { mergeEntities, mergeEntity } from '../entities-state-util';

export const commentAdapter: EntityAdapter<Comment> = createEntityAdapter<Comment>();

const initialState: CommentState = commentAdapter.getInitialState({
  selectedId: null,
  markers: []
});

const reducer = createReducer(
  initialState,
  on(CommentUpsertOne, (state: CommentState, { comment, propertiesToUpdate }) => {
    const comments = EntityUtil.changedEntities(commentBasicProperties, [comment], state.entities);

    if (!DataUtil.isEmpty(comments)) {
      return commentAdapter.upsertOne(mergeEntity(comments[0], state, propertiesToUpdate), state);
    } else {
      return state;
    }
  }),

  on(CommentUpsertMany, (state: CommentState, { comments, propertiesToUpdate }) => {
    const newComments = EntityUtil.changedEntities(commentBasicProperties, comments, state.entities);
    return commentAdapter.upsertMany(mergeEntities(newComments, state, propertiesToUpdate), state);
  }),

  on(CommentSelectOne, (state: CommentState, { commentId }) => ({
    ...state,
    selectedId: commentId
  })),

  on(ResetSelection, (state: CommentState) => ({
    ...state,
    selectedId: null
  })),

  on(CommentDeleteOne, (state: CommentState, { comment }) => {
    return commentAdapter.removeOne(comment.id, state);
  }),
  on(CommentAddMarker, (state: CommentState, { marker }) =>
    produce(state, draft => {
      draft.markers.push(marker);
    })
  ),
  on(CommentEditMarker, (state: CommentState, { marker }) =>
    produce(state, draft => {
      draft.markers = draft.markers.filter(({ id }) => id !== marker.id);
      draft.markers.push(marker);
    })
  ),
  on(CommentRemoveMarker, (state: CommentState, { marker }) =>
    produce(state, draft => {
      draft.markers = draft.markers.filter(({ id }) => id !== marker.id);
    })
  ),
  on(CommentClearMarkers, (state: CommentState) =>
    produce(state, draft => {
      draft.markers = [];
    })
  ),

  on(VersionSwitcherResetState, (state: CommentState) => ({
    ...state,
    selectedId: null
  })),
  on(CommentRemoveSelection, (state: CommentState) => ({
    ...state,
    selectedId: null
  })),
  on(CommentChangeReplyCount, (state: CommentState, { id, delta }) =>
    produce(state, draft => {
      draft.entities[id] = {
        ...draft.entities[id],
        replyCount: draft.entities[id].replyCount + delta
      };
    })
  )
);

export function commentReducer(state: CommentState = initialState, action: Action): CommentState {
  return reducer(state, action);
}
