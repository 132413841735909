import { createSelector } from '@ngrx/store';

import { customFieldAdapter } from '@celum/work/app/core/model/entities/custom-field/custom-field.reducer';

import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities } = customFieldAdapter.getSelectors();

export const selectCustomFields = createSelector(selectEntitiesState, (state: EntitiesState) => state.customFields);

export const selectCustomFieldEntities = createSelector(selectCustomFields, selectEntities);

export const selectCustomFieldsByIds = (ids: number[]) =>
  createSelector(selectCustomFieldEntities, customFieldsDictionary => ids.map(id => customFieldsDictionary[id]));
