import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { CelumDialog, CelumDialogConfiguration, IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialog extends ReactiveComponent implements CelumDialog<ConfirmationDialogConfiguration> {
  public color: string;
  public dialogClass: string;

  public confirmationHeader: SafeHtml;
  public confirmationQuestion: SafeHtml;
  public confirmationButtonMsgKey = 'COMMON.OK';

  public warnIcon: IconConfiguration;

  public configure(configuration: ConfirmationDialogConfiguration): void {
    this.color = configuration.color;
    this.dialogClass = configuration.dialogClass;
    this.warnIcon = configuration.icon || IconConfiguration.xLarge('attention-l', '', 80).withColor(this.color);

    this.confirmationHeader = configuration.header;
    this.confirmationQuestion = configuration.question;
    this.confirmationButtonMsgKey = configuration.confirmationButtonMsgKey || this.confirmationButtonMsgKey;
  }
}

export class ConfirmationDialogConfiguration extends CelumDialogConfiguration {
  constructor(
    public header: string | SafeHtml,
    public question: string | SafeHtml,
    color: string,
    public confirmationButtonMsgKey?: string,
    public icon?: IconConfiguration,
    public dialogClass?: string
  ) {
    super('confirmation-dialog');
    this.color = color;
  }
}
