import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Host,
  HostBinding,
  OnInit,
  SkipSelf,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CelumButtonModule, EmptyPageConfig } from '@celum/common-components';
import { SharedModule } from '@celum/work/app/shared';

import { VirtualScrollTableItem, VirtualScrollTableService } from './virtual-scroll-table.base';

@Component({
  selector: 'virtual-scroll-table',
  templateUrl: './virtual-scroll-table.component.html',
  styleUrls: ['./virtual-scroll-table.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, SharedModule, CelumButtonModule]
})
export class VirtualScrollTableComponent<T extends VirtualScrollTableItem> implements OnInit {
  @HostBinding('class.virtual-scroll-table') public hostCls = true;

  @ContentChild('itemTemplate', { static: true }) public itemTemplateRef: any;

  public readonly emptyPageConfig: EmptyPageConfig;
  public readonly items$: Observable<T[]>;
  public readonly isEmpty$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;
  public readonly stickyRowConfig: string[];
  public readonly itemSize: number;
  public readonly selectionEnabled: boolean;
  public readonly selectedItems$: BehaviorSubject<Record<number, T>>;

  @ViewChild(CdkVirtualScrollViewport) public viewport: CdkVirtualScrollViewport;

  constructor(@SkipSelf() @Host() private virtualScrollTableService: VirtualScrollTableService<T>) {
    this.items$ = this.virtualScrollTableService.items$;
    this.isEmpty$ = this.virtualScrollTableService.isEmpty$;
    this.loading$ = this.virtualScrollTableService.loading$;
    this.emptyPageConfig = this.virtualScrollTableService.emptyPageConfig;
    this.stickyRowConfig = this.virtualScrollTableService.stickyRowConfig;
    this.itemSize = this.virtualScrollTableService.itemSize;
    this.selectionEnabled = this.virtualScrollTableService.selectionEnabled;
    this.selectedItems$ = this.virtualScrollTableService.selectedItems$;
  }

  public ngOnInit(): void {
    this.virtualScrollTableService.loadItems();
  }

  public onRequestNextPage(): void {
    this.virtualScrollTableService.onRequestNextPage(this.viewport);
  }

  public selectionChanged(selected: boolean, item: T): void {
    this.virtualScrollTableService.selectionChanged(selected, item);
  }

  public trackByFn(_: number, item: T): number {
    return item.id;
  }
}
