import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { DataUtil } from '@celum/core';

import { ImportIntentUpsertOne } from './import-intent.actions';
import { EntityUtil } from '../../core/model';
import { mergeEntity } from '../../core/model/entities/entities-state-util';
import { ImportIntent, ImportIntentState } from '../model/import-intent.model';

export const importIntentAdapter: EntityAdapter<ImportIntent> = createEntityAdapter<ImportIntent>();
export const initialState: ImportIntentState = importIntentAdapter.getInitialState();

type ImportIntentProp = keyof ImportIntent;
export const importIntentProperties: ImportIntentProp[] = [
  'repositoryId',
  'assetCounter',
  'collectionCounter',
  'collectionId'
];

const reducer = createReducer(
  initialState,
  on(ImportIntentUpsertOne, (state: ImportIntentState, { importIntent, propertiesToUpdate }) => {
    const importIntents = EntityUtil.changedEntities(importIntentProperties, [importIntent], state.entities);

    if (!DataUtil.isEmpty(importIntents)) {
      return importIntentAdapter.upsertOne(mergeEntity(importIntents[0], state, propertiesToUpdate), state);
    }
    return state;
  })
);

export function importIntentReducer(state: ImportIntentState | undefined, action: Action) {
  return reducer(state, action);
}
