import { Pipe, PipeTransform } from '@angular/core';

import { ImportedFolderData } from '../watch-collections-automator.component';

@Pipe({
  name: 'selected'
})
export class SelectedPipe implements PipeTransform {
  public transform(collection: ImportedFolderData, selectedCollections: ImportedFolderData[]): boolean {
    return !!selectedCollections.find(el => el.folderId === collection.folderId);
  }
}
