import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  CelumDialog,
  CelumDialogConfiguration,
  ColorConstants,
  EmptyPage,
  EmptyPageConfig,
  IconConfiguration
} from '@celum/common-components';

import { SelectFoldersDialogStore } from './select-folders-dialog.store';
import { ImportedFolderData } from '../watch-collections-automator.component';
import { trackCollectionFn } from '../watch-collections-automator.util';

@Component({
  selector: 'select-folders-dialog',
  templateUrl: './select-folders-dialog.component.html',
  styleUrls: ['./select-folders-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [SelectFoldersDialogStore]
})
export class SelectFoldersDialogComponent implements CelumDialog<SelectFoldersDialogConfiguration> {
  public static readonly DIALOG_ID = 'select-folders';

  public folderIcon = IconConfiguration.medium('folder').withIconSize(30);
  public infoIcon = IconConfiguration.small('info-icon');

  public color: string;
  public trackByFn = trackCollectionFn;

  public noFilteredResultsConfig = EmptyPage.noActionConfig(
    'not-found-content-hub-folders',
    'not-found-content-hub-folders',
    'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.SELECT_FOLDERS_DIALOG.NO_RESULTS',
    'small',
    120
  );
  public noImportedFoldersConfig = EmptyPage.noActionConfig(
    'content-hub-no-folders',
    'content-hub-no-folders',
    'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.SELECT_FOLDERS_DIALOG.NO_FOLDERS',
    'small',
    120
  );

  constructor(
    public readonly store: SelectFoldersDialogStore,
    private readonly dialogRef: MatDialogRef<SelectFoldersDialogComponent>
  ) {}

  public configure({ color, selectedFolders, allFolders }: SelectFoldersDialogConfiguration): void {
    this.color = color;

    const mustPreselectSingleElementList = allFolders.length === 1 && selectedFolders.length === 0;
    const computedSelectedFolders = mustPreselectSingleElementList ? allFolders : selectedFolders;

    this.store.setState({
      allFolders: allFolders,
      selectedFolders: computedSelectedFolders,
      initiallySelectedFolders: selectedFolders,
      searchValue: ''
    });
  }

  public folderSelectionChanged(checked: boolean, folder: ImportedFolderData): void {
    checked ? this.store.selectFolder(folder) : this.store.deselectFolder(folder);
  }

  public selectFolders(selectedFolders: ImportedFolderData[]): void {
    this.dialogRef.close(selectedFolders);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onSearchValueChanged(searchValue: string): void {
    this.store.searchValueChanged(searchValue);
  }

  public getEmptyPageConfiguration$(): Observable<EmptyPageConfig> {
    return combineLatest([this.store.allFolders$, this.store.filteredFolders$]).pipe(
      take(1),
      map(([allFolders, filteredFolders]) => {
        if (allFolders.length === 0) {
          return this.noImportedFoldersConfig;
        }
        if (filteredFolders.length === 0) {
          return this.noFilteredResultsConfig;
        }
        return null;
      })
    );
  }

  public getFolderTranslationPluralOrSingular(): Observable<string> {
    return this.store.filteredFolders$.pipe(
      take(1),
      map(
        value =>
          'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.SELECT_FOLDERS_DIALOG.FOLDERS.' +
          (value.length > 1 ? 'PLURAL' : 'SINGULAR')
      )
    );
  }
}

export class SelectFoldersDialogConfiguration extends CelumDialogConfiguration {
  constructor(
    public allFolders: ImportedFolderData[],
    public selectedFolders: ImportedFolderData[],
    public color: string = ColorConstants.PRIMARY
  ) {
    super('main');
  }
}
