import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { PaginationResult } from '@celum/core';
import { ItemLink, ItemLinkRelationType } from '@celum/work/app/core/model/entities/item-link/item-link.model';
import { Task } from '@celum/work/app/core/model/entities/task';

export const ItemLinkLoadItemLinksByType = createAction(
  '[ItemLink] Load Item Links',
  props<{
    sourceId: number;
    itemLinkRelationType: ItemLinkRelationType;
    offset: number;
  }>()
);

export const ItemLinkFetchNextBatchItemLinks = createAction(
  '[ItemLink] Fetch Next Batch Item Links',
  props<{ itemLinkRelationType: ItemLinkRelationType; sourceTaskId: number }>()
);

export const ItemLinkLoadItemLinksByTypeSucceeded = createAction(
  '[ItemLink] Load Item Links Succeeded',
  props<{
    itemLinks: ItemLink[];
    itemLinkRelationType: ItemLinkRelationType;
    paginationResult: PaginationResult;
    resetState: boolean;
  }>()
);
export const ItemLinkLoadItemLinksByTypeFailed = createAction(
  '[ItemLink] Load Item Links Failed',
  props<{ error: HttpErrorResponse; itemLinkRelationType: ItemLinkRelationType }>()
);

export const ItemLinkCreateItemLink = createAction(
  '[ItemLink] Create Item Link',
  props<{ sourceTaskId: number; itemLinkRelationType: ItemLinkRelationType; targetTask: Task; tmpItemLink: ItemLink }>()
);

export const ItemLinkCreateItemLinkSucceeded = createAction(
  '[ItemLink] Create Item Link Succeeded',
  props<{ itemLink: ItemLink; itemLinkRelationType: ItemLinkRelationType }>()
);

export const ItemLinkCreateItemLinkFailed = createAction(
  '[ItemLink] Create Item Link Failed',
  props<{ itemLinkRelationType: ItemLinkRelationType; createdItemLink: ItemLink }>()
);

export const ItemLinkDeleteItemLink = createAction(
  '[ItemLink] Delete Item Link',
  props<{ itemLink: ItemLink; itemLinkRelationType: ItemLinkRelationType }>()
);

export const ItemLinkDeleteItemLinkSucceeded = createAction(
  '[ItemLink] Delete Item Link Succeeded',
  props<{ itemLink: ItemLink; itemLinkRelationType: ItemLinkRelationType }>()
);

export const ItemLinkDeleteItemLinkFailed = createAction(
  '[ItemLink] Delete Item Link Failed',
  props<{ itemLink: ItemLink; itemLinkRelationType: ItemLinkRelationType }>()
);
