import { EntityTranslator, Roles } from '@celum/work/app/core/model';

import { Contributor, ContributorType } from './contributor.model';

export class ContributorTranslator implements EntityTranslator<Contributor> {
  public translateToEntity(json: any): Contributor {
    return {
      id: `${json.workroomId}_${json.personId}`,
      workroomId: json.workroomId,
      personId: json.personId,
      roles: (json.roles || []).map(role => Roles[role]),
      creationDate: json.creationDate,
      entityType: ContributorType.instance()
    };
  }
}
