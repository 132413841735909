<celum-standard-dialog [confirmBtnText]="'MOVE_TASK_DIALOG.CONFIRM'"
                       [headerText]="headline"
                       [valid]="assignmentChanged$ | async"
                       [disableOnConfirmClosing]="true"
                       [color]="color"
                       (onConfirm)="confirm()">
  <p class="move-tasks-dialog_description">{{ description | translate }}</p>
  <p class="move-tasks-dialog_caption">{{ 'WORKROOM_TASK.ASSIGNEES' | translate }}</p>
  <task-assignee class="move-tasks-dialog_task-assignee"
                 [assignees]="assignees"
                 (unassignPerson)="unassignPerson($event)"
                 (assignPerson)="assignPerson($event)">
  </task-assignee>
</celum-standard-dialog>
