import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';

import { CelumEmptyPageModule, CelumIconModule, CelumLoadingMaskModule } from '@celum/common-components';
import { CustomField, CustomFieldTypes } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { selectCustomFieldEntities } from '@celum/work/app/core/model/entities/custom-field/custom-field.selectors';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';

import { CustomFieldsVirtualScrollTableComponent } from './components/custom-fields-virtual-scroll-table/custom-fields-virtual-scroll-table.component';
import { FieldCreateButtonComponent } from './components/field-create-button/field-create-button.component';
import { CustomFieldsStore } from './fields-overview-tab.store';

@Component({
  selector: 'fields-overview-tab',
  standalone: true,
  imports: [
    CommonModule,
    CelumEmptyPageModule,
    CelumIconModule,
    MatTooltipModule,
    FieldCreateButtonComponent,
    ScrollingModule,
    CelumLoadingMaskModule,
    CustomFieldsVirtualScrollTableComponent
  ],
  templateUrl: './fields-overview-tab.component.html',
  styleUrls: ['./fields-overview-tab.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomFieldsStore]
})
export class FieldsOverviewTabComponent implements OnInit {
  @HostBinding('class') public componentCls = 'fields-overview-tab';

  @Input() public teamspace: Teamspace;

  public readonly selectedCustomFields$: Observable<Record<number, CustomField>> =
    this.customFieldsStore.selectedCustomFields$;
  public readonly selectionLimitExceeded$: Observable<boolean> = this.customFieldsStore.selectionLimitExceeded$;
  public readonly loading$: Observable<boolean> = this.customFieldsStore.loading$;
  public customFields$: Observable<CustomField[]>;

  constructor(
    public customFieldsStore: CustomFieldsStore,
    private store: Store<any>
  ) {}

  public ngOnInit(): void {
    this.customFields$ = combineLatest([
      this.store.select(selectCustomFieldEntities),
      this.customFieldsStore.customFieldIds$
    ]).pipe(map(([customFields, customFieldIds]) => customFieldIds.map(id => customFields[id])));
  }

  public openCreateCustomFieldDialog(type: CustomFieldTypes): void {
    this.customFieldsStore.openCreateCustomFieldDialog$(type);
  }

  public openCreateCustomFormDialog(customFieldsRecord: Record<number, CustomField>): void {
    this.customFieldsStore.openCreateCustomFormDialog$(customFieldsRecord);
  }

  public displayCreationLimitSnackbar(): void {
    this.customFieldsStore.displayFieldLimitationSnackbar$();
  }
}
