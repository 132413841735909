export class Paging {
  constructor(
    public offset: number,
    public limit: number
  ) {}

  public static of(offset: number, limit: number) {
    return new Paging(offset, limit);
  }
}

export const PAGE_LIMIT_MAXIMUM = 200;
