import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { RobotActionType } from '@celum/work/app/core/model/entities/workroom/robot.model';
import { isNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { DueDateAutomatorComponent } from './due-date-automator.component';
import {
  Automator,
  AutomatorType,
  DueDateAction,
  isAutomator,
  Robots
} from '../../../../core/model/entities/workroom/robot.model';
import { DateUtil } from '../../../../shared/util';
import { RobotChipConfig } from '../../../services/robots-factory';
import { BaseRobotConfigService, RobotNotAppliedConfig } from '../base-robot/base-robot-config.service';

@Injectable()
export class DueDateAutomatorConfigService extends BaseRobotConfigService {
  constructor(
    public translate: TranslateService,
    public dateUtil: DateUtil
  ) {
    super(translate, dateUtil);
  }

  public serviceMatchesRobot(robot: Robots): boolean {
    return robot.type === AutomatorType.TASK_DUE_DATE;
  }

  public getRobotChipConfig(robot: Automator): Observable<Partial<RobotChipConfig>> {
    const robotAction: DueDateAction = robot.action as DueDateAction;
    const dueDate = this.dateUtil.getFormattedFullDate(robotAction.dueDate);
    return of({
      row1: this.translate.instant(`ROBOTS.CHIP.FIRST_ROW.${robot.action.type}`, { ...robot.action, dueDate }),
      row2: this.translate.instant(`ROBOTS.CHIP.SECOND_ROW.${robot.trigger.type}`),
      editEvent: {
        robot,
        type: DueDateAutomatorComponent
      }
    });
  }

  public isRobotNotApplied(params: RobotNotAppliedConfig): Observable<boolean> {
    const { robot, sourceEntity } = params;

    if (isAutomator(robot) && robot.action.type === RobotActionType.TASK_SET_TASKLIST_DUE_DATE) {
      return of(isNullOrUndefined(sourceEntity?.dueDate));
    }

    return of(false);
  }
}
