import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { DataUtil } from '@celum/core';
import {
  WorkroomDeleteWorkroom,
  WorkroomDeleteWorkroomFailed,
  WorkroomFinishWorkroom,
  WorkroomFinishWorkroomFailed,
  WorkroomReopenWorkroom,
  WorkroomReopenWorkroomFailed
} from '@celum/work/app/core/api/workroom/workroom.actions';
import { EntityUtil } from '@celum/work/app/core/model';

import {
  TeamspaceAddAllPermissions,
  TeamspacesDeleteOne,
  TeamspacesLoaded,
  TeamspacesUpdateOne,
  TeamspacesUpsertMany,
  TeamspacesUpsertOne,
  UpdateTeamspaceStorageDetails
} from './teamspace.actions';
import { Teamspace, TeamspaceState } from './teamspace.model';

export const teamspaceAdapter: EntityAdapter<Teamspace> = createEntityAdapter<Teamspace>();

export const initialState: TeamspaceState = {
  ...teamspaceAdapter.getInitialState(),
  loaded: false
};

const properties = ['name', 'active', 'workroomsUsed', 'workroomsLimit'];

const reducer = createReducer(
  initialState,
  on(TeamspacesUpsertOne, (state: TeamspaceState, { teamspace }) => {
    const teamspaces = EntityUtil.changedEntities(properties, [teamspace], state.entities);

    if (!DataUtil.isEmpty(teamspaces)) {
      return teamspaceAdapter.upsertOne(teamspaces[0], state);
    } else {
      return state;
    }
  }),

  on(TeamspacesUpsertMany, (state: TeamspaceState, { teamspaces }) => {
    const updatedTeamspaces = EntityUtil.changedEntities(properties, teamspaces, state.entities);
    return teamspaceAdapter.upsertMany(updatedTeamspaces, state);
  }),

  on(TeamspacesDeleteOne, (state: TeamspaceState, { id }) => {
    return teamspaceAdapter.removeOne(id, state);
  }),

  on(TeamspaceAddAllPermissions, (state: TeamspaceState, { teamspaceIds, permissions }) => {
    const updatedTeamspaces = teamspaceIds
      .filter(teamspaceId => state.entities[teamspaceId])
      .map(teamspaceId => ({
        ...state.entities[teamspaceId],
        permissions: permissions[teamspaceId]
      }));
    return teamspaceAdapter.upsertMany(updatedTeamspaces, state);
  }),

  on(TeamspacesLoaded, (state: TeamspaceState) => ({
    ...state,
    loaded: true
  })),

  on(TeamspacesUpdateOne, (state: TeamspaceState, { teamspaceUpdate }) => {
    const teamspaceUpdated: Update<Teamspace> = teamspaceUpdate;
    return teamspaceAdapter.updateOne(teamspaceUpdated, state);
  }),

  on(UpdateTeamspaceStorageDetails, (state, { teamspaceId, storageDetails }) => {
    const teamspace = state.entities[teamspaceId];
    if (teamspace) {
      const updated: Update<Teamspace> = {
        id: teamspaceId,
        changes: storageDetails
      };
      return teamspaceAdapter.updateOne(updated, state);
    }
    return state;
  }),

  on(WorkroomFinishWorkroom, WorkroomReopenWorkroomFailed, (state: TeamspaceState, { workroom }) => {
    const { id, workroomsFinished, workroomsUsed } = state.entities[workroom.teamspaceId];
    const teamspaceUpdated: Update<Teamspace> = {
      id,
      changes: {
        workroomsFinished: workroomsFinished + 1,
        workroomsUsed: workroomsUsed - 1
      }
    };
    return teamspaceAdapter.updateOne(teamspaceUpdated, state);
  }),

  on(WorkroomReopenWorkroom, WorkroomFinishWorkroomFailed, (state: TeamspaceState, { workroom }) => {
    const { id, workroomsFinished, workroomsUsed } = state.entities[workroom.teamspaceId];
    const teamspaceUpdated: Update<Teamspace> = {
      id,
      changes: {
        workroomsFinished: workroomsFinished - 1,
        workroomsUsed: workroomsUsed + 1
      }
    };
    return teamspaceAdapter.updateOne(teamspaceUpdated, state);
  }),

  on(WorkroomDeleteWorkroom, (state: TeamspaceState, { workroom }) => {
    const { id, workroomsFinished } = state.entities[workroom.teamspaceId];
    const teamspaceUpdated: Update<Teamspace> = {
      id,
      changes: { workroomsFinished: workroomsFinished - 1 }
    };
    return teamspaceAdapter.updateOne(teamspaceUpdated, state);
  }),

  on(WorkroomDeleteWorkroomFailed, (state: TeamspaceState, { workroom }) => {
    const { id, workroomsFinished } = state.entities[workroom.teamspaceId];
    const teamspaceUpdated: Update<Teamspace> = {
      id,
      changes: { workroomsFinished: workroomsFinished + 1 }
    };
    return teamspaceAdapter.updateOne(teamspaceUpdated, state);
  })
);

export function teamspaceReducer(state: TeamspaceState = initialState, action: Action): TeamspaceState {
  return reducer(state, action);
}
