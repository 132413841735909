<span class="fields-label">{{ 'TEAMSPACE_MANAGEMENT.FIELDS.NAV_BUTTON.LABEL' | translate }}</span>

<celum-icon
  class="fields-overview_icon"
  [matTooltip]="'TEAMSPACE_MANAGEMENT.FIELDS.NAV_BUTTON.TOOLTIP' | translate"
  [configuration]="fieldCreateIcon"
  [matMenuTriggerFor]="menuRef"
  data-component-id="field-create-button"
  data-cy="field-create-button">
</celum-icon>

<mat-menu class="custom-field-create-button_menu" #menuRef xPosition="after">
  <ng-template matMenuContent>
    <celum-menu-item 
      *ngFor="let type of customFieldType | keyvalue" 
      (click)="fieldTypeSelected.emit(type.value)" 
      [attr.data-cy]="'create-custom-field-option-' + type.value">
      <span> {{ 'TEAMSPACE_MANAGEMENT.FIELDS.FIELD_TYPES.' + type.value | translate }} </span>
    </celum-menu-item>
  </ng-template>
</mat-menu>
