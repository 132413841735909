import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import {
  ColorConstants,
  IconConfiguration,
  ProgressSnackbar,
  RemoveSnackbar,
  ShowSnackbar,
  SimpleSnackbar,
  SnackbarButtonConfig,
  SnackbarConfiguration
} from '@celum/common-components';
import { ExecutableAction, UUIDGenerator } from '@celum/core';
import { FileUtilService } from '@celum/work/app/shared/util/file-util.service';

import { File } from '../../model/entities/file/file.model';
import { selectWorkroomByLibraryId } from '../../model/entities/workroom';
import { ReplyWebsocketListener } from '../../websocket/reply-websocket-listener.service';

export type ZipTaskStatus = 'NEW' | 'SENT' | 'FINISHED' | 'FAILED';

@Injectable({ providedIn: 'root' })
export class ZipService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<any>,
    private replyListener: ReplyWebsocketListener
  ) {}

  public requestZipDownload(libraryId: string, files: File[]): void {
    const snackbarId = UUIDGenerator.generateId();
    const snackbarConfig: SnackbarConfiguration =
      SnackbarConfiguration.progress('FILES.ZIP.PROGRESS').withDismissible(true);
    this.store.dispatch(new ShowSnackbar(snackbarId, ProgressSnackbar, snackbarConfig));

    combineLatest(files.map(file => file.activeVersion(this.store)))
      .pipe(take(1))
      .subscribe(fileVersions => {
        const zipArgs = fileVersions.map((fileVersion, idx) => ({
          fileId: files[idx].relationId,
          version: fileVersion.versionNumber
        }));
        this.doRequestZipDownload(zipArgs, libraryId, snackbarId, () => this.requestZipDownload(libraryId, files));
      });
  }

  private doRequestZipDownload(
    files: { fileId: string; version: number }[],
    libraryId: string,
    snackbarId: string,
    retryAction: () => void
  ): void {
    this.httpClient
      .post<{ id: string }>(`${(window as any).Celum.properties.librariesHttpBaseAddress}/zip/requests`, { files })
      .pipe(
        switchMap(({ id }) =>
          this.replyListener.startListening<{ status: ZipTaskStatus; downloadUrl: string }>(id).pipe(
            take(1),
            switchMap(({ status, downloadUrl }) => {
              if (status === 'FAILED') {
                return throwError(`Zip task with id: ${id} has failed`);
              }
              return of(downloadUrl);
            })
          )
        ),
        switchMap(downloadUrl =>
          this.store.select(selectWorkroomByLibraryId(libraryId)).pipe(
            take(1),
            map(({ slibResourceToken }) => `${downloadUrl}&token=${slibResourceToken}`)
          )
        ),
        catchError(err => {
          this.showErrorSnackbar(snackbarId, retryAction);
          console.error(err);
          return EMPTY;
        })
      )
      .subscribe(downloadUrl => {
        const successSnackbar = new ShowSnackbar(
          UUIDGenerator.generateId(),
          SimpleSnackbar,
          SnackbarConfiguration.success('FILES.ZIP.SUCCESS').withIcon(IconConfiguration.small('download-m'))
        );
        this.showSnackbar(snackbarId, successSnackbar);
        FileUtilService.downloadUrl(downloadUrl);
      });
  }

  private showErrorSnackbar(snackbarId: string, retryAction: () => void): void {
    const retryButton: SnackbarButtonConfig = {
      action: new ExecutableAction(() => {
        this.store.dispatch(new RemoveSnackbar(snackbarId));
        retryAction();
      }, 'RETRY'),
      text: 'COMMON.RETRY',
      icon: IconConfiguration.medium('retry-m').withColor('#fff'),
      buttonColor: ColorConstants.ERROR,
      identifier: 'retry'
    };
    const errorSnackbar = new ShowSnackbar(
      UUIDGenerator.generateId(),
      SimpleSnackbar,
      SnackbarConfiguration.error('FILES.ZIP.FAILED')
        .withIcon(IconConfiguration.small('download-m'))
        .withActionButtons([retryButton])
    );

    this.showSnackbar(snackbarId, errorSnackbar);
  }

  private showSnackbar(snackbarId: string, action: ShowSnackbar<any>): void {
    this.store.dispatch(new RemoveSnackbar(snackbarId));
    this.store.dispatch(action);
  }
}
