import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UiStateEffects } from './ui-state.effects';
import { uiStateReducer } from './ui-state.reducer';
import { FEATURE_NAME } from './ui-state.selectors';

@NgModule({
  imports: [StoreModule.forFeature(FEATURE_NAME, uiStateReducer), EffectsModule.forFeature([UiStateEffects])]
})
export class UiStateModule {}
