import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { PredefinedCategoryValues } from '../../../core/model/entities/template-category/template-category.model';
import { selectCategoryById } from '../../../core/model/entities/template-category/template-category.selectors';

@Pipe({
  name: 'isCategoryEditable',
  pure: true
})
export class IsCategoryEditablePipe implements PipeTransform {
  constructor(private store: Store<any>) {}

  public transform(categoryId: any | null): Observable<boolean> {
    if (
      PredefinedCategoryValues.ALL === categoryId ||
      PredefinedCategoryValues.PERSONAL === categoryId ||
      categoryId === null
    ) {
      return of(true);
    }
    return this.store.select(selectCategoryById(categoryId)).pipe(
      filter(category => !!category),
      map(category => (category ? category.editable : true)),
      take(1)
    );
  }
}
