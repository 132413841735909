import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { File } from '../../core/model/entities/file/file.model';
import { FileTranslator } from '../../core/model/entities/file/file.translator';

@Injectable({ providedIn: 'root' })
export abstract class AbstractContentHubService {
  protected readonly fileTranslator: FileTranslator = new FileTranslator();

  protected processFilesResult(files: any[]): File[] {
    return files.map(file => ({
      ...this.fileTranslator.translateToEntity(file),
      activeVersion: () =>
        of({
          ...file.activeVersion,
          renditions: () => of(file.activeVersion.renditions)
        })
    }));
  }
}
