import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { EntityType } from '@celum/core';
import { ContentItem, ContentItemType } from '@celum/work/app/core/model/entities/content-item/content-item.model';

export enum FolderSubType {
  ORDINARY = 'ORDINARY',
  ROOT = 'ROOT',
  TRASH_BIN = 'TRASH_BIN',
  COMMENT_ATTACHMENTS = 'COMMENT_ATTACHMENTS'
}

export interface Folder extends ContentItem {
  hasSubfolders: boolean;
  hasChildren?: boolean;
  validationLevel: string;
  entityType: FolderType;
  folderSubType: FolderSubType;
}

export class FolderType implements EntityType {
  public static readonly TYPE_KEY = 'Folder';

  private static _instance: FolderType;

  public id = FolderType.TYPE_KEY;
  public inheritsFrom = new Set<ContentItemType>();

  public static instance(): FolderType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};

    return new schema.Entity(FolderType.TYPE_KEY, relationDefinitions);
  }
}

export interface FolderState extends EntityState<Folder> {}

export type FolderPropertiesToUpdate = Partial<Folder>;
