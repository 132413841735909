import { BackendProviderService } from '@celum/work/app/core/auth/backend-provider.service';
import { Workroom } from '@celum/work/app/core/model/entities/workroom';

export class DownloadUrlUtil {
  public static resolveDownloadUrl(downloadUrl: string, workroom: Workroom, slibResourceToken?: string): string {
    const computedDownloadUrl = this.tryGetUrlObject(downloadUrl);
    const isSlibUrl = BackendProviderService.slib()
      .urls.map(url => url.host)
      .includes(computedDownloadUrl?.host);

    if (!isSlibUrl) {
      return downloadUrl;
    }

    const token = slibResourceToken || workroom?.slibResourceToken;
    if (token) {
      return `${downloadUrl}${downloadUrl?.includes('?') ? '&' : '?'}token=${token}`;
    }

    return `${downloadUrl}`;
  }

  public static tryGetUrlObject(downloadUrl: string): URL | null {
    try {
      return new URL(downloadUrl);
    } catch (error) {
      return null;
    }
  }
}
