import { createAction, props } from '@ngrx/store';

import { Person } from './person.model';

export const PersonsUpsertOne = createAction('[Person] Upsert One', props<{ person: Person }>());

export const PersonsUpsertMany = createAction('[Person] Upsert Many', props<{ persons: Person[] }>());

export const PersonsDeleteOne = createAction('[Person] Delete One', props<{ id: number }>());

export const FetchPersonAvatars = createAction('[Person] Fetch avatars', props<{ persons: Person[] }>());

export const FetchPersonAvatarsSuccess = createAction('[Person] Fetch avatars success', props<{ persons: Person[] }>());
