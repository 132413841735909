import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { EntityTranslator } from '@celum/work/app/core/model';
import { ContentItem, ContentItemType } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { selectFileById } from '@celum/work/app/core/model/entities/file/file.selectors';
import { selectPersonEntities } from '@celum/work/app/core/model/entities/person';

export class ContentItemTranslator implements EntityTranslator<ContentItem> {
  public translateToEntity(json: any): ContentItem {
    const id = json.id;

    return {
      id,
      name: json.name,
      parentId: json.parentId,
      libraryId: json.libraryId,
      assetType: json.assetType,
      createdById: json.createdById,
      changedById: json.changedById,
      createdOn: json.createdOn,
      changedOn: json.changedOn,
      relationId: json.relationId,
      status: json.status,
      creator: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectFileById(id))]).pipe(
          filter(([_, file]) => !!file),
          map(([persons, file]) => Object.values(persons).find(person => person.oid === file.createdById))
        ),
      modifier: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectFileById(id))]).pipe(
          filter(([_, file]) => !!file),
          map(([persons, file]) => persons[file.changedById])
        ),
      importId: json.importId,
      exportId: json.exportId,
      entityType: ContentItemType.instance(),
      contentHubConnections: json.contentHubConnections
    };
  }
}
