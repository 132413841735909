import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { Color } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';
import { Roles } from '@celum/work/app/core/model';
import { Person } from '@celum/work/app/core/model/entities/person';
import { selectCurrentWorkroomContributorsWithSearch } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { AvatarDecoratorFn, AvatarUtil, ColorService, PermissionUtil } from '@celum/work/app/shared/util';
import { InviteAndAssignService } from '@celum/work/app/shared/util/invite-and-assign.service';

@Component({
  selector: 'task-assignee',
  templateUrl: './task-assignees.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [InviteAndAssignService]
})
export class TaskAssignees extends ReactiveComponent implements OnInit {
  @Input() public assignees: Person[];
  @Input() public hasAddPermission = true;
  @Input() public hasRemovePermission = true;

  @Output() public readonly assignPerson: EventEmitter<Person> = new EventEmitter<Person>();
  @Output() public readonly unassignPerson: EventEmitter<Person> = new EventEmitter<Person>();

  public selectPersonIcon = IconConfiguration.small('task-assignee')
    .withIconSize(30)
    .withColor(ColorConstants.BLUE_GRAY_500)
    .withHoverColor(ColorConstants.BLUE_GRAY_700);
  public avatarDecorator$: AvatarDecoratorFn;
  public allPeople$: Observable<Person[]>;
  public isModerator$: Observable<boolean>;
  private searchQuery: string;
  private _iconColor: string;

  constructor(
    private store: Store,
    private colorService: ColorService,
    private permissionUtil: PermissionUtil,
    private avatarUtil: AvatarUtil,
    @Self() private inviteAndAssignService: InviteAndAssignService
  ) {
    super();
  }

  get iconColor(): string {
    return this._iconColor;
  }

  @Input() set iconColor(value: string) {
    this._iconColor = value;

    const color: Color = ColorService.rbgToColor(this.iconColor);
    this.selectPersonIcon.withColor(this.iconColor).withHoverColor(this.colorService.getHoverColor(color));
  }

  public searchInCurrentWorkroom(searchQuery: string): void {
    this.searchQuery = searchQuery;
    this.allPeople$ = this.store.select(selectCurrentWorkroomContributorsWithSearch(searchQuery));
  }

  public ngOnInit() {
    this.avatarDecorator$ = this.avatarUtil.getAvatarDecoratorForCurrentWorkroom();
    this.isModerator$ = this.permissionUtil.hasRoleForCurrentWorkroom(Roles.MODERATOR);
  }

  public onPersonUnassign(person: Person) {
    this.unassignPerson.emit(person);
  }

  public inviteAndAssign(): void {
    this.inviteAndAssignService.inviteAndAssign(this.searchQuery, this.assignPerson);
  }
}
