<div class="color-menu-item_text">{{'TASK_BOARD.ACTIONS.CHANGE_COLOR' | translate}}</div>
<div class="color-menu-item_wrap">
  <div *ngFor="let color of colors; let i = index; trackBy: trackByFn"
      class="color-menu-item_color-dot"
      [class.color-menu-item_color-dot--unselected]="!selectedColor(color)"
      [style.borderColor]="convertColorDefinitionToColorString(color)"
      [attr.data-cy]="'task-list-color-' + i"
      (click)="selectColor(color)">
    <div class="color-menu-item_core" [style.background]="convertColorDefinitionToColorString(color)"></div>
  </div>
</div>
