import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil } from 'rxjs/operators';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';
import { ReactiveService } from '@celum/core';
import { ClientNotification } from '@celum/work/app/core/client-notification/model/client-notification.model';
import { NotificationWebsocketListener } from '@celum/work/app/core/client-notification/notification-websocket-listener.service';

@Injectable({
  providedIn: 'root'
})
export class FailureClientNotificationsListener extends ReactiveService {
  public static readonly SNACKBAR_ID = 'failure-client-notifications';

  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    private clientNotificationWebsocketListener: NotificationWebsocketListener<ClientNotification>
  ) {
    super();
    this.startListening();
  }

  public startListening(): void {
    this.clientNotificationWebsocketListener.clientNotificationsSubj
      .pipe(
        filter(notification => notification.typeKey === 'failure'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(notification => {
        const config = SnackbarConfiguration.error(
          this.translateService.instant(`CLIENT_NOTIFICATION.ERROR.${notification.key}`, notification.data)
        );
        this.store.dispatch(new ShowSnackbar(FailureClientNotificationsListener.SNACKBAR_ID, SimpleSnackbar, config));
      });
  }
}
