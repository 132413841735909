<div class="workrooms-virtual-scroll-table_row">
  <div class="workrooms-virtual-scroll-table_row_name-column">
    <workroom-icon class="workrooms-virtual-scroll-table_row_name-column_icon" [workroom]="workroom" [iconSize]="20"></workroom-icon>
    <text-truncator class="workrooms-virtual-scroll-table_row_name-column_text" [text]="workroom.name"></text-truncator>
  </div>
  <div class="workrooms-virtual-scroll-table_row_counter-column">
    <celum-icon class="workrooms-virtual-scroll-table_row_counter-column_icon" [configuration]="fileIcon" [clickable]="false"></celum-icon>
    <text-truncator class="workrooms-virtual-scroll-table_row_counter-column_text" [text]="workroom.numberOfFiles.toString()"></text-truncator>
  </div>
  <div class="workrooms-virtual-scroll-table_row_counter-column">
    <celum-icon class="workrooms-virtual-scroll-table_row_counter-column_icon" [configuration]="taskIcon" [clickable]="false"></celum-icon>
    <text-truncator class="workrooms-virtual-scroll-table_row_counter-column_text" [text]="workroom.numberOfTasks.toString()"></text-truncator>
  </div>
  <div class="workrooms-virtual-scroll-table_row_people-column">
    <interactive-avatars
        [maxNumberOfAvatars]="5"
        [darkOverflowBackground]="true"
        [hasAddPermissions]="false"
        [hasRemovePermissions]="false"
        [showUnableToAddListOwner]="false"
        [selectedPeople]="getContributorsMemoized(workroom.id) | async"
        [avatarDecorator]="getRoleBadgeDecorator(workroom.id)">
    </interactive-avatars>
  </div>
  <div class="workrooms-virtual-scroll-table_row_date-column">
    <due-date
        *ngIf="workroom.dueDate"
        [dueDate]="workroom.dueDate"
        [displayLabelOnly]="true">
    </due-date>
  </div>
  <div class="workrooms-virtual-scroll-table_row_last-activity-column">
    {{ getLastActivityToNow(workroom.lastActivity) }}
  </div>
  <div class="workrooms-virtual-scroll-table_row_menu-column">
    <workroom-context-menu
        [workroom]="workroom"
        [teamspace]="teamspace"
        (executeActionForPermission)="onWorkroomContextMenuAction($event.permission, workroom)">
    </workroom-context-menu>
  </div>
</div>
