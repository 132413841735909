import { Injectable } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { DataUtil } from '@celum/core';
import { Color } from '@celum/work/app/core/model/color.model';
import { ConfigurationKey } from '@celum/work/app/core/model/configuration.model';
import { Workroom } from '@celum/work/app/core/model/entities/workroom/workroom.model';

import { ConfigurationService } from './configuration.service';

@Injectable()
export class ColorService {
  private static colorsWithHover: CelumColor[] = [];
  private static celumColors: CelumColor[] = [];
  private static DARK_GRAY_COLOR = new Color(120, 144, 156);
  private static LIGHT_GRAY_COLOR = new Color(144, 164, 174);

  constructor(
    private sanitizer: DomSanitizer,
    private configurationService: ConfigurationService
  ) {}

  public static getTaskListColor(previousColor: Color): Color {
    if (previousColor && JSON.stringify(previousColor) === JSON.stringify(this.DARK_GRAY_COLOR)) {
      return this.LIGHT_GRAY_COLOR;
    }
    return this.DARK_GRAY_COLOR;
  }

  public static getColorAsRgbString(color: Color, opacity?: number): string {
    // eslint-disable-next-line eqeqeq
    if (opacity != null) {
      return `rgba(${color.red},${color.green},${color.blue},${opacity})`;
    } else {
      return `rgb(${color.red},${color.green},${color.blue})`;
    }
  }

  public static rbgToColor(rgbColor: string): Color {
    const regEx = new RegExp('\\D+', 'i');
    const [, r, g, b] = rgbColor.split(regEx);
    return {
      red: Number(r),
      green: Number(g),
      blue: Number(b)
    };
  }

  public static hexToRGB(hex: string) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b})`;
  }

  public static rgbToRGBA(rgb: string, alpha: number): string {
    return `${rgb.slice(0, rgb.length - 1)},${alpha})`;
  }

  public loadColorConfiguration() {
    this.configurationService.loadConfiguration(ConfigurationKey.CELUM_COLORS).subscribe(result => {
      ColorService.colorsWithHover = result.celumColors as CelumColor[];
    });

    this.configurationService.loadConfiguration(ConfigurationKey.CELUM_WORKROOM_COLORS).subscribe(result => {
      ColorService.celumColors = result.celumColors as CelumColor[];
    });
  }

  public getPredefinedColors() {
    return ColorService.colorsWithHover.map(colorWithHover => colorWithHover.color);
  }

  public getHeaderImageStyle(image?: any): SafeStyle {
    if (!DataUtil.isEmpty(image)) {
      return { backgroundImage: `url('${image}')` };
    }
    return null;
  }

  public getGradientHeaderStyle(color: Color): SafeStyle {
    return {
      background: this.getGradientString(color)
    };
  }

  public getGradientForFinishedWorkroom() {
    return {
      background: this.getGradientString(ColorService.DARK_GRAY_COLOR)
    };
  }

  public getGradientForStyle(color: Color): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(this.getGradientString(color));
  }

  public getMainColor(workroom: Workroom): string {
    return workroom ? `rgb(${workroom.color.red},${workroom.color.green},${workroom.color.blue})` : '';
  }

  public getHoverColor(color: Color): string {
    const index = ColorService.colorsWithHover.findIndex(elem => JSON.stringify(elem.color) === JSON.stringify(color));
    return index >= 0 ? ColorService.getColorAsRgbString(ColorService.colorsWithHover[index].hoverColor) : '';
  }

  public getRandomColor(): Color {
    const index = Math.floor(Math.random() * ColorService.colorsWithHover.length);
    return ColorService.colorsWithHover[index].color;
  }

  public getRandomColorForWorkroom(): Color {
    const possibleColors = ColorService.celumColors.map(value => value.color);
    const index = Math.floor(Math.random() * possibleColors.length);
    return possibleColors[index];
  }

  private getGradientString(color: Color): string {
    if (color) {
      return `linear-gradient(to right, ${ColorService.getColorAsRgbString(
        color,
        1
      )}, ${ColorService.getColorAsRgbString(color, 0.7)} )`;
    } else {
      return '';
    }
  }
}

export class CelumColor {
  constructor(
    public color: Color,
    public hoverColor: Color
  ) {}
}
