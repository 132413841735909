import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { schema } from 'normalizr';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { CelumPropertiesProvider, PaginationResult } from '@celum/core';
import { EntityTranslator } from '@celum/work/app/core/communication/entity-translator';
import { CommunicationServerResponse } from '@celum/work/app/core/communication/response.model';
import { ResultCachingService } from '@celum/work/app/core/communication/result-caching.service';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo, Paging, Sorting } from '@celum/work/app/core/model';
import {
  ConflictingContentItem,
  ContentItem,
  ContentItemStatus,
  ContentItemTypes
} from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { File, FileType, LinkedAsset } from '@celum/work/app/core/model/entities/file/file.model';
import { fileUpdatableProperties } from '@celum/work/app/core/model/entities/file/file.reducer';
import { Folder, FolderSubType, FolderType } from '@celum/work/app/core/model/entities/folder/folder.model';
import { folderUpdatableProperties } from '@celum/work/app/core/model/entities/folder/folder.reducer';
import { FileFilter } from '@celum/work/app/files/store/content-item-list/content-item-list.model';
import { FilterUtils } from '@celum/work/app/shared/components/filter-sort/filter/filter.utils';
import { SearchQuery } from '@celum/work/app/shared/components/global-search';
import { ContentItemResult } from '@celum/work/app/shared/components/global-search/model/content-item-result.model';
import { STRONGLY_CONSISTENT_HEADER, STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

export interface ContentItemSearchArgs {
  libraryId: string;
  status?: ContentItemStatus;
  parentIds?: string[];
  objectId?: string[];
  contentTypes?: ContentItemTypes[];
  paging?: Paging;
  sorting?: Sorting;
  filter?: FileFilter;
}

export interface LibrarySpecificIdsDTO {
  libraryId: string;
  ids: string[];
}

@Injectable({ providedIn: 'root' })
export class ContentItemService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService,
    private cachingService: ResultCachingService
  ) {}

  public getContentById(contentId: string): Observable<File> {
    const fileSchema = FileType.instance().getSchema();
    const metaInfo = MetaInfo.of(FileType.TYPE_KEY_NESTED, fileSchema);
    metaInfo.partialUpdates = { [FileType.TYPE_KEY]: fileUpdatableProperties };

    return this.httpClient.get<File>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/${contentId}`
    );
  }

  public loadContentItems(
    searchArgs: ContentItemSearchArgs
  ): Observable<{ contentItems: ContentItem[]; paginationResult: PaginationResult }> {
    const resultsSchema = {
      results: [
        new schema.Union(
          {
            File: FileType.instance().getSchema(),
            Folder: FolderType.instance().getSchema()
          },
          EntityTranslator.TYPE_KEY
        )
      ]
    };

    const metaInfo = MetaInfo.of(
      [...FileType.TYPE_KEY_NESTED, FolderType.TYPE_KEY],
      resultsSchema,
      [FileType.TYPE_KEY, FolderType.TYPE_KEY],
      'results'
    );

    return this.httpClient
      .post(
        `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/search`,
        this.searchArgsToDTO(searchArgs)
      )
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            contentItems: entitiesResult.combinedEntities as ContentItem[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public loadContentItemsWithoutStore(
    searchArgs: ContentItemSearchArgs
  ): Observable<CommunicationServerResponse<ContentItem>> {
    const searchArgsDTO = this.searchArgsToDTO(searchArgs);
    const cachedResult = this.cachingService.getCachedResult('loadContentItems', searchArgsDTO, 50);
    if (cachedResult) {
      return of(cachedResult);
    }
    return this.httpClient
      .post<
        CommunicationServerResponse<ContentItem>
      >(`${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/search`, searchArgsDTO)
      .pipe(tap(result => this.cachingService.createCacheEntry('loadContentItems', searchArgsDTO, result)));
  }

  public loadSystemFolders(
    libraryId: string,
    paging: Paging,
    sorting?: { field: string; direction: number }
  ): Observable<{ folders: Folder[]; paginationResult: PaginationResult; rootId: string }> {
    const resultsSchema = [
      {
        folder: FolderType.instance().getSchema(),
        content: {
          results: [FolderType.instance().getSchema()]
        }
      }
    ];

    const metaInfo = MetaInfo.of([FolderType.TYPE_KEY], resultsSchema);

    const body = {
      libraryId,
      status: ContentItemStatus.NOT_DELETED,
      contentItemType: {
        include: [ContentItemTypes.FOLDER],
        exclude: [ContentItemTypes.FILE]
      },
      folderType: FolderSubType.ROOT,
      sorting: sorting || Sorting.of('name', SortDirection.ASC),
      paging
    };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/extended-search`, body)
      .pipe(
        map((res: any) => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            folders: entitiesResult.entities[FolderType.TYPE_KEY] as Folder[],
            paginationResult: res[0]?.content
              ? entitiesResult.paginationResult
              : {
                  totalElementCount: 0,
                  elementsFollow: false,
                  hasBottom: false,
                  hasTop: false
                },
            rootId: res[0]?.folder?.id
          };
        })
      );
  }

  public deeplinkSearch(libraryId: string, objectId: string, sorting?: { field: string; direction: number }) {
    const resultsSchema = [
      {
        folder: FolderType.instance().getSchema(),
        content: {
          results: [FolderType.instance().getSchema()]
        }
      }
    ];

    const metaInfo = MetaInfo.of([FolderType.TYPE_KEY], resultsSchema);

    const body = {
      libraryId,
      status: ContentItemStatus.NOT_DELETED,
      contentItemType: {
        include: [ContentItemTypes.FOLDER],
        exclude: [ContentItemTypes.FILE]
      },
      objectId: {
        include: [objectId]
      },
      deeplinkAccess: true,
      folderType: FolderSubType.ROOT,
      sorting: sorting || Sorting.of('name', SortDirection.ASC)
    };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/extended-search`, body)
      .pipe(
        map((res: any) => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            folders: entitiesResult.entities[FolderType.TYPE_KEY] as Folder[],
            paginationResult: res[0]?.content
              ? entitiesResult.paginationResult
              : {
                  totalElementCount: 0,
                  elementsFollow: false,
                  hasBottom: false,
                  hasTop: false
                }
          };
        })
      );
  }

  public loadSubfolders(
    libraryId: string,
    parentNodeId: string,
    paging: Paging
  ): Observable<{ folders: Folder[]; paginationResult: PaginationResult }> {
    const resultsSchema = {
      results: [
        new schema.Union(
          {
            Folder: FolderType.instance().getSchema()
          },
          EntityTranslator.TYPE_KEY
        )
      ]
    };

    const metaInfo = MetaInfo.of([FolderType.TYPE_KEY], resultsSchema, [FolderType.TYPE_KEY], 'results');

    const body = {
      libraryId,
      parentId: {
        include: [parentNodeId]
      },
      contentItemType: {
        include: [ContentItemTypes.FOLDER],
        exclude: [ContentItemTypes.FILE]
      },
      sorting: Sorting.of('name', SortDirection.ASC),
      paging
    };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/search`, body)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            folders: entitiesResult.entities[FolderType.TYPE_KEY] as Folder[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public moveContentItems(
    libraryId: string,
    targetId: string,
    contentItemIds: string[]
  ): Observable<{ contentItems: ContentItem[] }> {
    const resultsSchema = [
      new schema.Union(
        {
          File: FileType.instance().getSchema(),
          Folder: FolderType.instance().getSchema()
        },
        EntityTranslator.TYPE_KEY
      )
    ];

    const metaInfo = MetaInfo.of([...FileType.TYPE_KEY_NESTED, FolderType.TYPE_KEY], resultsSchema);
    metaInfo.partialUpdates = {
      [FileType.TYPE_KEY]: fileUpdatableProperties,
      [FolderType.TYPE_KEY]: folderUpdatableProperties
    };

    const body: LibrarySpecificIdsDTO = {
      libraryId,
      ids: contentItemIds
    };

    return this.httpClient
      .patch(
        `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/${targetId}/move`,
        body,
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            contentItems: [
              ...entitiesResult.entities[FolderType.TYPE_KEY],
              ...entitiesResult.entities[FileType.TYPE_KEY]
            ] as ContentItem[]
          };
        })
      );
  }

  public shareContentItems(libraryId: string, fileCount: number, folderIds: string[]): Observable<File[]> {
    const metaInfo = MetaInfo.of([...FileType.TYPE_KEY_NESTED], [FileType.instance().getSchema()]);

    return this.httpClient
      .post<File[]>(`${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/recursive-search`, {
        libraryId,
        folderIds,
        fileCount
      })
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return entitiesResult.entities[FileType.TYPE_KEY] as File[];
        })
      );
  }

  public deleteContentItems(libraryId: string, contentItemIds: string[], permanently = false): Observable<void> {
    const httpOptions = {
      headers: STRONGLY_CONSISTENT_HEADER,
      body: {
        libraryId,
        ids: contentItemIds
      }
    };
    const path = permanently ? 'content/delete-hard' : 'content';
    return this.httpClient.delete<void>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/${path}`,
      httpOptions
    );
  }

  public emptyBin(libraryId: string): Observable<void> {
    const httpOptions = {
      headers: STRONGLY_CONSISTENT_HEADER,
      body: libraryId
    };

    return this.httpClient.delete<void>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/empty-trash-bin`,
      httpOptions
    );
  }

  public restoreContentItems(libraryId: string, contentItemIds: string[]): Observable<string[]> {
    const body: LibrarySpecificIdsDTO = {
      libraryId,
      ids: contentItemIds
    };
    return this.httpClient.post<string[]>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/restore`,
      body,
      STRONGLY_CONSISTENT_OPTION
    );
  }

  public restoreAll(libraryId: string): Observable<string[]> {
    return this.httpClient.post<string[]>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/restore-all`,
      libraryId,
      STRONGLY_CONSISTENT_OPTION
    );
  }

  public getConflictsForDelete(itemIds: string[], workroomId: number): Observable<ConflictingContentItem> {
    return this.httpClient.post<ConflictingContentItem>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/content/${workroomId}/delete-conflicts`,
      itemIds
    );
  }

  public searchForItems({
    queryString,
    workroomId,
    limit
  }: SearchQuery): Observable<CommunicationServerResponse<ContentItemResult>> {
    const body = {
      searchTerm: queryString,
      paging: Paging.of(0, limit),
      currentWorkroomId: workroomId,
      sorting: Sorting.of('modifiedOn', SortDirection.DESC)
    };

    return this.httpClient.post<CommunicationServerResponse<ContentItemResult>>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/content/global-search`,
      body
    );
  }

  public getAssetsLinkedToFile(fileId: string): Observable<LinkedAsset[]> {
    return this.httpClient.get<LinkedAsset[]>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/${fileId}/asset-details`
    );
  }

  private searchArgsToDTO(searchArgs: ContentItemSearchArgs): any {
    const body: any = {
      ...searchArgs,
      contentTypes: {},
      parentId: {}
    };

    if (searchArgs.parentIds) {
      body.parentId = {
        include: searchArgs.parentIds
      };
    }

    if (searchArgs.contentTypes) {
      body.contentTypes = {
        include: searchArgs.contentTypes
      };
    }

    if (searchArgs.objectId) {
      body.objectId = {
        include: searchArgs.objectId
      };
    }

    if (searchArgs.filter) {
      body.filter = FilterUtils.toFileFilterDTO(searchArgs.filter, searchArgs.contentTypes);
    }

    return body;
  }
}
