import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { takeUntil } from 'rxjs';

import { ListSelectionHandler } from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';

import { Collection } from '../../model/content-hub.model';

@Component({
  selector: 'collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CollectionListComponent extends ReactiveComponent implements OnInit {
  @HostBinding('class.collection-list') public hostCls = true;

  @Input() public collections: Collection[];
  @Input() public selectedCollection: Collection;

  @Output() public readonly collectionSelected: EventEmitter<Collection> = new EventEmitter<Collection>();

  public listSelectionHandler: ListSelectionHandler<Collection> = new ListSelectionHandler<Collection>();

  public ngOnInit() {
    this.listSelectionHandler.selectionChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((collections: Collection[]) => this.collectionSelected.emit(collections[0]));
  }
}
