import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { CelumPropertiesProvider, DataUtil, PaginationResult } from '@celum/core';
import { AvatarService } from '@celum/work/app/core/avatar/avatar.service';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import {
  InvitationStatus,
  Member,
  MembershipStatus,
  MemberType
} from '@celum/work/app/core/model/entities/member/member.model';
import { Person, PersonType } from '@celum/work/app/core/model/entities/person/person.model';
import { selectTeamspaceById } from '@celum/work/app/core/model/entities/teamspace';
import { Teamspace, TeamspaceType } from '@celum/work/app/core/model/entities/teamspace/teamspace.model';
import { MetaInfo } from '@celum/work/app/core/model/meta-info.model';

import { PersonService } from '../person';

@Injectable({ providedIn: 'root' })
export class TeamspaceMemberService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService,
    private store: Store<any>,
    private avatarService: AvatarService,
    private personService: PersonService
  ) {}

  public getMyMembership(): Observable<Member> {
    const schema = MemberType.instance().getSchema({ relationsFor: [TeamspaceType.TYPE_KEY] });
    const metaInfo = MetaInfo.of([MemberType.TYPE_KEY, TeamspaceType.TYPE_KEY, PersonType.TYPE_KEY], schema);

    return this.httpClient.get(CelumPropertiesProvider.properties.httpBaseAddress + '/member/current').pipe(
      map(res => {
        const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
        const members = entitiesResult.entities[MemberType.TYPE_KEY];
        return DataUtil.isEmpty(members) ? null : (members[0] as Member);
      })
    );
  }

  public searchPeopleFromSacc(
    teamspaceId: number,
    searchValue: string,
    validInvitationStatuses: InvitationStatus[]
  ): Observable<{ persons: Person[]; paginationResult: PaginationResult }> {
    const schema = {
      entities: [PersonType.instance().getSchema()]
    };

    const metaInfo = MetaInfo.of([PersonType.TYPE_KEY], schema, [PersonType.TYPE_KEY], 'entities');
    return this.store.select(selectTeamspaceById(teamspaceId)).pipe(
      take(1),
      switchMap(teamspace =>
        this.httpClient
          .post<any>(this.getAccountUsersUrl(teamspace), {
            nameOrEmailContains: searchValue,
            statusIn: [MembershipStatus.ACTIVE]
          })
          .pipe(
            map(response => {
              const filteredResponse = response.entities.filter(person =>
                validInvitationStatuses.includes(person.invitationStatus)
              );
              filteredResponse.forEach(entity => {
                entity.typeKey = 'Person';
                const profilePictureDownloadLink = entity.profilePictureDownloadLink;
                const accountAccessToken = this.avatarService.getAccountAccessTokenByAccountId(teamspace.externalId);
                entity.avatarUrl = AvatarService.buildAvatarUrl(profilePictureDownloadLink, accountAccessToken);
              });
              const { entities, paginationResult } = this.resultConsumerService.translateAndAddToStore(
                { entities: filteredResponse },
                metaInfo
              );

              const persons = (entities[PersonType.TYPE_KEY] as Person[]) || [];

              this.translateAndAddMembersToStore(filteredResponse, teamspace);

              return {
                persons,
                paginationResult
              };
            })
          )
      )
    );
  }

  public searchPeopleForAssignedToFilter(
    searchTerm: string
  ): Observable<{ persons: Person[]; members: Member[]; paginationResult: PaginationResult }> {
    const resultsSchema = {
      results: [MemberType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY] })]
    };

    const metaInfo = MetaInfo.of(
      [MemberType.TYPE_KEY, PersonType.TYPE_KEY],
      resultsSchema,
      [MemberType.TYPE_KEY],
      'results'
    );

    const body = {
      searchTerm,
      paging: {
        offset: 0,
        limit: 200
      },
      sorting: {
        field: 'person.firstName',
        direction: 'asc'
      }
    };

    return this.httpClient
      .post<any>(CelumPropertiesProvider.properties.httpBaseAddress + '/member/advanced-search', body)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            persons: entitiesResult.entities[PersonType.TYPE_KEY] as Person[],
            members: entitiesResult.entities[MemberType.TYPE_KEY] as Member[],
            paginationResult: entitiesResult.paginationResult
          };
        }),
        switchMap(res => {
          return res.persons.length !== 0
            ? this.personService.populatePersonAvatar(res.persons).pipe(map(persons => ({ ...res, persons })))
            : of(res);
        })
      );
  }

  private getAccountUsersUrl(teamspace: Teamspace): string {
    return `${(window as any).Celum.properties.saccHttpBaseAddress}/api/accounts/${
      teamspace.externalId
    }/members/search?size=1000`;
  }

  private translateAndAddMembersToStore(entities: any, teamspace: Teamspace): void {
    const schema = {
      entities: [MemberType.instance().getSchema()]
    };
    const metaInfo = MetaInfo.of([MemberType.TYPE_KEY], schema, [MemberType.TYPE_KEY], 'entities');

    entities.forEach(entity => {
      entity.typeKey = 'Member';
      entity.personId = entity.id;
      entity.externalId = entity.oid ?? entity.id;
      entity.id = `${teamspace.id}_${entity.personId}`;
      entity.teamspaceId = teamspace.id;
    });
    this.resultConsumerService.translateAndAddToStore({ entities }, metaInfo);
  }
}
