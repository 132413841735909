import { CelumDialogConfiguration, ColorConstants } from '@celum/common-components';

import { Template } from '../../../../core/model/entities/template/template.model';

export type CopyTemplateCallback = (categoryId: number) => void;

export abstract class TemplateMaintenanceDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(
    public template: Template,
    public callback: CopyTemplateCallback
  ) {
    super('main');
  }
}
