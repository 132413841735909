import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

/**
 * Basic context menu item providing the default styling of a context menu element.
 */
@Component({
             selector: 'celum-menu-item',
             template: '<button mat-menu-item [disabled]="disabled"><ng-content></ng-content></button>',
             styleUrls: ['./celum-menu-item.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             exportAs: 'celumMenuItem'
           })
export class CelumMenuItem {

  @HostBinding('class') public cssClass = 'celum-menu-item';

  @Input() public disabled = false;
}
