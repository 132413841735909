import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { ReactiveService } from '@celum/core';
import { FileSoftDeletedNotification } from '@celum/work/app/core/client-notification/model/client-notification.model';
import { NotificationWebsocketListener } from '@celum/work/app/core/client-notification/notification-websocket-listener.service';
import { ContentItemContentItemsDeleted } from '@celum/work/app/files/store/content-item-list/content-item-list.actions';

@Injectable({
  providedIn: 'root'
})
export class FileSoftDeletedNotificationListener extends ReactiveService {
  constructor(
    private store: Store<any>,
    private clientNotificationWebsocketListener: NotificationWebsocketListener<FileSoftDeletedNotification>
  ) {
    super();
    this.startListening();
  }

  public startListening(): void {
    this.clientNotificationWebsocketListener.clientNotificationsSubj
      .pipe(
        filter(notification => notification.typeKey === 'file_soft_deleted'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(notification => {
        this.store.dispatch(ContentItemContentItemsDeleted({ contentItemIds: [notification.fileId] }));
      });
  }
}
