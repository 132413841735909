import { Injectable } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';

import { ReactiveService } from '@celum/core';

import { InvitationFailureNotification } from '../../../core/client-notification/model/invitation-failure-notification';
import { NotificationWebsocketListener } from '../../../core/client-notification/notification-websocket-listener.service';
import { InviteOperation } from '../pages/people/services/invite-operation';

@Injectable({
  providedIn: 'root'
})
export class InvitationClientNotificationListener extends ReactiveService {
  constructor(
    private clientNotificationWebsocketListener: NotificationWebsocketListener<InvitationFailureNotification>,
    private inviteOperation: InviteOperation
  ) {
    super();
    this.startListening();
  }

  public startListening(): void {
    this.clientNotificationWebsocketListener.clientNotificationsSubj
      .pipe(
        filter(notification => notification.typeKey === 'invitation-failure'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(notification => {
        this.inviteOperation.handleFailedInvitations(notification.errorMessageKeys);
      });
  }
}
