import { trigger } from '@angular/animations';
import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { IconConfiguration } from '@celum/common-components';
import { ToggleDrawerButtonTooltipConfig } from '@celum/work/app/shared/components/toggle-drawer-button/toggle-drawer-button.component';

import { FeatureType } from '../../../core/model/entities/workroom';

export type StepConfiguration = {
  icon: IconConfiguration;
  label: string;
  featureType?: FeatureType;
};

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('noop', [])],
  providers: [
    {
      provide: CdkStepper,
      useExisting: StepperComponent
    }
  ]
})
export class StepperComponent extends CdkStepper {
  @HostBinding('class.stepper') public hostCls = true;
  @Input() public stepsConfiguration: StepConfiguration[];
  @Input() public collapseButtonTooltipConfig: ToggleDrawerButtonTooltipConfig;

  constructor(
    @Optional() dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    elementRef?: ElementRef<HTMLElement>
  ) {
    super(dir, changeDetectorRef, elementRef);
  }

  public isDisabled(idx: number): boolean {
    return this.selectedIndex !== idx && !this.selected.completed && !this.selected.optional;
  }

  public selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }

  public trackByFn(index: number): number {
    return index;
  }

  public sanitizeHtml(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}
