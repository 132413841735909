import { createSelector } from '@ngrx/store';

import { selectCurrentFileId, selectFileEntities } from '../../../../../../../core/model/entities/file/file.selectors';
import {
  selectContentItemsState,
  selectFilesIds
} from '../../../../../../../files/store/content-item-list/content-item-list.selectors';

export const selectFileForDetail = createSelector(
  selectCurrentFileId,
  selectFileEntities,
  (fileId, fileDictionary) => fileDictionary[fileId]
);

export const selectFileDetailIdxInTheList = createSelector(
  selectFilesIds,
  selectCurrentFileId,
  (fileIds: string[], fileId) => fileIds.findIndex(fId => fId === fileId?.toString())
);

export const selectShouldRequestNextBatch = createSelector(
  selectFilesIds,
  selectFileDetailIdxInTheList,
  selectContentItemsState,
  (fileIds, fileDetailIdxInTheList, fileListState, { threshold }) =>
    fileListState?.paginationResult?.hasBottom && fileIds - fileDetailIdxInTheList <= threshold
);

export const selectIsFileDetailFirstInTheList = createSelector(
  selectFileDetailIdxInTheList,
  fileDetailIdxInTheList => fileDetailIdxInTheList <= 0
);

export const selectIsFileDetailLastInTheList = createSelector(
  selectFileDetailIdxInTheList,
  selectFilesIds,
  (fileDetailIdxInTheList, fileIds: string[]) => fileDetailIdxInTheList === fileIds.length - 1
);

export const selectPreviousFileId = createSelector(
  selectFilesIds,
  selectCurrentFileId,
  (fileIds: string[], fileId: string) => {
    const fileIdx = fileIds.findIndex(fId => fId === fileId.toString());

    if (fileIdx < 1) {
      return fileIds[fileIdx];
    }

    return fileIds[fileIdx - 1];
  }
);

export const selectNextFileId = createSelector(selectFilesIds, selectCurrentFileId, (fileIds: string[], fileId) => {
  const fileIdx = fileIds.findIndex(fId => fId === fileId.toString());

  if (fileIdx === fileIds.length - 1) {
    return fileIds[fileIdx];
  }

  return fileIds[fileIdx + 1];
});
