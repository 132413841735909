import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectTaskEntities } from '@celum/work/app/core/model/entities/task';
import { selectTaskListEntities } from '@celum/work/app/core/model/entities/task/task-list.selectors';
import { selectLoggedInPersonId } from '@celum/work/app/core/ui-state/ui-state.selectors';

import { TasksOverviewState } from './tasks-overview.model';

export const FEATURE_NAME = 'tasksOverview';

export const selectTasksOverviewStateState = createFeatureSelector<TasksOverviewState>(FEATURE_NAME);

export const selectTasksOverviewState = createSelector(
  selectTasksOverviewStateState,
  (state: TasksOverviewState) => state
);

export const selectIfTaskPreviewShown = createSelector(selectTasksOverviewState, state => state.showPreview);

export const selectIsLoading = createSelector(selectTasksOverviewState, state => state.loading);

export const selectListCount = createSelector(selectTasksOverviewState, state =>
  state.entityIds ? state.entityIds.length : 0
);

export const selectTaskListIds = createSelector(selectTasksOverviewState, state => state.entityIds);

export const selectLists = createSelector(selectTasksOverviewState, state => state?.lists);

export const selectTaskListState = (listId: number) => createSelector(selectLists, lists => lists[listId]);

export const selectIsListLoading = (listId: number) =>
  createSelector(selectLists, lists => !lists[listId] || lists[listId]?.loading);

export const selectTaskListStates = createSelector(selectTaskListIds, selectLists, (listIds, taskListState) =>
  listIds ? listIds.map(id => taskListState[id]).filter(state => !!state) : []
);

export const selectTaskCountForList = (listId: number) =>
  createSelector(selectLists, lists => (lists && lists[listId]?.totalCount) || 0);

export const selectHasBottomMoreForList = (listId: number) =>
  createSelector(selectLists, lists => !lists[listId]?.paginationResult || lists[listId]?.paginationResult?.hasBottom);

export const selectTasksForList = (listId: number) =>
  createSelector(selectLists, selectTaskEntities, (lists, taskEntities) =>
    lists[listId] ? lists[listId].entityIds.map(id => taskEntities[id]).filter(task => !!task) : []
  );

export const selectAssignedTaskCountForList = (listId: number) =>
  createSelector(
    selectTasksForList(listId),
    selectLoggedInPersonId,
    (tasks, userId) => tasks.filter(task => task.assigneeIds?.includes(userId)).length
  );

export const selectTaskLists = createSelector(selectTaskListIds, selectTaskListEntities, (listIds, taskListEntities) =>
  listIds ? listIds.map(id => taskListEntities[id]).filter(list => !!list) : null
);

export const selectVisibleColumnCount = (listId: number) =>
  createSelector(selectLists, lists => (lists && lists[listId]?.visibleColumnsCount) ?? 1);
