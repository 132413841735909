import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { Marker } from '@celum/annotations';
import { DataUtil, EntityType } from '@celum/core';
import { File, FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { Task } from '@celum/work/app/core/model/entities/task';
import { RequireAtLeastOne } from '@celum/work/app/core/model/requires-at-least-one';

import {
  CollaborationProperty,
  CollaborationPropertyType
} from '../collaboration-property/collaboration-property.model';
import { PersonType } from '../person/person.model';

export interface Comment extends CollaborationProperty {
  content: string;
  htmlContents?: string[];
  markers: Marker[];
  entityType: CommentType;
  parentId: number;
  replyCount: number;
  attachmentIds: string[];
  attachments: (store: Store<any>) => Observable<File[]>;
  object: (store: Store<any>) => Observable<Task | File>;
}

export class CommentType implements EntityType {
  public static readonly TYPE_KEY: string = 'Comment';

  private static _instance: CommentType;

  public id = CommentType.TYPE_KEY;
  public inheritsFrom = new Set<CollaborationPropertyType>();

  public static instance(): CommentType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(PersonType.TYPE_KEY)) {
        relationDefinitions.createdBy = PersonType.instance().getSchema({});
        relationDefinitions.changedBy = PersonType.instance().getSchema({});
      }

      if (relations.relationsFor.includes(FileType.TYPE_KEY)) {
        relationDefinitions.attachments = [FileType.instance().getSchema()];
      }
    }

    return new schema.Entity(CommentType.TYPE_KEY, relationDefinitions);
  }
}

export interface CommentState extends EntityState<Comment> {
  selectedId: number;
  markers: Marker[];
}

export type CommentPropertiesToUpdate = RequireAtLeastOne<Comment, 'content'>;
type CommentItemProp = keyof Comment;
export const commentBasicProperties: CommentItemProp[] = [
  'content',
  'changedBy',
  'changedOn',
  'replyCount',
  'versionNumber'
];
