import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RobotService, RobotServiceArgs } from '@celum/work/app/robots/services/robot.service';
import { hasTaskListRule } from '@celum/work/app/robots/services/robots-util';

import { Automator, RobotActionType, RobotTriggerType, RuleType } from '../../core/model/entities/workroom/robot.model';

export interface AutomatorServiceArgs extends RobotServiceArgs {
  initialRobotState?: Automator;
  currentRobotState?: Automator;
}

export abstract class AutomatorService extends RobotService {
  public abstract getAllTriggers(args?: AutomatorServiceArgs): Observable<RobotTriggerType[]>;

  public abstract getAllActions(args?: AutomatorServiceArgs): Observable<RobotActionType[]>;

  public getPossibleTriggers(args?: AutomatorServiceArgs): Observable<RobotTriggerType[]> {
    return this.getAllTriggers(args).pipe(
      map(allTriggers => {
        let result = allTriggers;

        const taskCreationRestricted = hasTaskListRule(
          args.workroomConfig,
          args.sourceId,
          RuleType.TASK_CREATION_RESTRICTION
        );

        if (taskCreationRestricted) {
          result = result.filter(trigger => trigger !== RobotTriggerType.TASK_CREATED);
        }

        return result;
      })
    );
  }

  public getPossibleActions(args?: AutomatorServiceArgs): Observable<RobotActionType[]> {
    return this.getAllActions(args).pipe(
      map(allTriggers => {
        let result = allTriggers;

        const mandatoryAssignment = hasTaskListRule(
          args.workroomConfig,
          args.sourceId,
          RuleType.TASK_MANDATORY_ASSIGNMENT
        );
        if (mandatoryAssignment) {
          result = result.filter(action => action !== RobotActionType.TASK_REMOVE_ASSIGNEES);
        }

        return result;
      })
    );
  }

  public definedRobotsLimitReached(args: RobotServiceArgs): Observable<boolean> {
    const possibleActions = this.getPossibleActions(args);
    const possibleTriggers = this.getPossibleTriggers(args);

    return combineLatest([possibleActions, possibleTriggers]).pipe(
      map(([actions, triggers]) => actions.length < 1 || triggers.length < 1)
    );
  }

  public getDisabledTooltipKey(args: RobotServiceArgs): Observable<string | null> {
    return this.definedRobotsLimitReached(args).pipe(
      map(limitReached => (limitReached ? 'ROBOTS.ALREADY_USED' : null))
    );
  }

  public abstract getAutomatorPreselection(
    args: AutomatorServiceArgs
  ): Observable<{ triggerType: RobotTriggerType; actionType: RobotActionType }>;
}
