import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateInFutureValidator } from './date-in-future';
import { DateValidator } from './date-validator';
import { EmailValidator } from './email-validator';
import { MaxLengthValidator } from './max-length-validator';
import { MinLengthValidator } from './min-length-validator';
import { NotEmptyValidator } from './not-empty-validator';
import { NotInValidator } from './not-in-validator';
import { PureNumberValidator } from './pure-number-validator';
import { RangeNumberValidator } from './range-number-validator';
import { RequiredValidator } from './required-validator';
import { ValidCharactersValidator } from './valid-characters-validator';

@NgModule({
            imports: [
              CommonModule
            ],
            declarations: [
              DateInFutureValidator, DateValidator, EmailValidator, MaxLengthValidator, MinLengthValidator, NotEmptyValidator, NotInValidator,
              PureNumberValidator, RequiredValidator, ValidCharactersValidator, RangeNumberValidator
            ],
            exports: [
              DateInFutureValidator, DateValidator, EmailValidator, MaxLengthValidator, MinLengthValidator, NotEmptyValidator, NotInValidator,
              PureNumberValidator, RequiredValidator, ValidCharactersValidator, RangeNumberValidator
            ]
          })
export class CelumValidatorsModule {
}
