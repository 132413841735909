import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { Permission, RoleName } from '@celum/work/app/core/api/permission';
import { TeamspaceMemberService } from '@celum/work/app/core/api/teamspace-member';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';
import { selectTenantTeamspace } from '@celum/work/app/core/ui-state/ui-state.selectors';
import { StepConfiguration } from '@celum/work/app/shared/components/stepper/stepper.component';
import { ToggleDrawerButtonTooltipConfig } from '@celum/work/app/shared/components/toggle-drawer-button/toggle-drawer-button.component';
import { PermissionUtil } from '@celum/work/app/shared/util';

import { WorkroomsManagementService } from '../workrooms-tab/workrooms-management.service';

@Component({
  selector: 'teamspace-management',
  templateUrl: './teamspace-management.component.html',
  styleUrls: ['./teamspace-management.component.less'],
  providers: [WorkroomsManagementService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TeamspaceManagementComponent implements OnInit {
  public teamspace$: Observable<Teamspace>;
  public hasWorkroomsTabPermission$: Observable<boolean>;
  public hasWorkroomsFinishPermission$: Observable<boolean>;
  public hasFormsEnabled: boolean;

  public readonly collapseButtonTooltipConfig: ToggleDrawerButtonTooltipConfig = {
    open: 'TEAMSPACE_MANAGEMENT.COLLAPSE_BUTTON.OPEN',
    close: 'TEAMSPACE_MANAGEMENT.COLLAPSE_BUTTON.CLOSE'
  };

  public stepsConfiguration$: Observable<StepConfiguration[]>;

  constructor(
    private store: Store<any>,
    private memberService: TeamspaceMemberService,
    private permissionUtil: PermissionUtil,
    @Inject(DOCUMENT) public document: Document
  ) {
    this.hasFormsEnabled = (this.document.defaultView.window as any).Celum.properties.features.forms;
    this.reloadTeamspace();
  }

  public ngOnInit(): void {
    this.teamspace$ = this.store.select(selectTenantTeamspace);
    this.hasWorkroomsTabPermission$ = this.permissionUtil.hasTeamspaceRole(RoleName.WORKROOM_MAINTAINER);
    this.hasWorkroomsFinishPermission$ = this.permissionUtil.hasTeamspacePermission(Permission.WORKROOM_FINISH);
    this.setStepsConfiguration();
  }

  private reloadTeamspace(): void {
    this.memberService.getMyMembership().subscribe();
  }

  private setStepsConfiguration(): void {
    this.stepsConfiguration$ = this.hasWorkroomsTabPermission$.pipe(
      map(isWorkroomMaintainer => {
        const steps = [];

        if (isWorkroomMaintainer) {
          steps.push({
            icon: IconConfiguration.small('workroom-icon').withColor('white'),
            label: 'TEAMSPACE_MANAGEMENT.WORKROOMS.HEADLINE'
          });
        }

        if (this.hasFormsEnabled) {
          steps.push({
            icon: IconConfiguration.small('forms-overview').withColor('white').withIconSize(32),
            label: 'TEAMSPACE_MANAGEMENT.FORMS.HEADLINE'
          });
        }

        return steps;
      })
    );
  }
}
