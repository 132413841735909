import { ValidationErrors } from '@angular/forms';

export enum CustomFieldCardValidationErrorsEnum {
  NAME_REQUIRED = 'NAME_REQUIRED',
  NAME_NOT_UNIQUE = 'NAME_NOT_UNIQUE',
  NAME_IS_EMPTY = 'NAME_IS_EMPTY',
  CHIP_NAME_REQUIRED = 'CHIP_NAME_REQUIRED',
  CHIPS_NOT_DEFINED = 'CHIPS_NOT_DEFINED'
}

export type CustomFieldCardValidationErrors = ValidationErrors & {
  [key in CustomFieldCardValidationErrorsEnum]?: boolean;
};
