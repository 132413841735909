import { createAction, props } from '@ngrx/store';

import { TaskFormDTO } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/custom-form/model/task-form.model';

export const CustomFormStateSetFormPanelExpandedState = createAction(
  '[Custom Form] Toggle Form Panel Expanded State',
  props<{ expanded: boolean }>()
);

export const AddFormToTask = createAction(
  '[Custom Form] Add Form To Task',
  props<{
    taskId: number;
    formId: number;
  }>()
);

export const AddFormToTaskSucceeded = createAction(
  '[Custom Form] Add Form To Task Succeeded',
  props<{
    form: TaskFormDTO;
  }>()
);

export const AddFormToTaskFailed = createAction('[Custom Form] Add Form To Task Failed');

export const RemoveFormFromTask = createAction(
  '[Custom Form] Remove Form From Task',
  props<{
    taskId: number;
    formId: number;
  }>()
);

export const RemoveFormFromTaskCanceled = createAction('[Custom Form] Remove Form From Task Canceled');

export const RemoveFormFromTaskSucceeded = createAction(
  '[Custom Form] Remove Form From Task Succeeded',
  props<{
    taskId: number;
  }>()
);

export const RemoveFormFromTaskFailed = createAction('[Custom Form] Remove Form From Task Failed');

export const OpenRemoveFormConfirmationDialog = createAction(
  '[Custom Form] Open Remove Form Confirmation Dialog',
  props<{
    formId: number;
    taskId: number;
  }>()
);

export const LoadCustomFormToTask = createAction(
  '[Custom Form] Load Custom Form To Task',
  props<{ taskId: number; formId: number }>()
);

export const LoadCustomFormSucceeded = createAction(
  '[Custom Form] Load Custom Form To Task Succeeded',
  props<{ form: TaskFormDTO }>()
);

export const LoadCustomFormFailed = createAction('[Custom Form] Load Custom Form To Task Failed');

export const ResetCustomFormState = createAction('[Custom Form] Reset Form State');

export const DeleteCustomFormSucceeded = createAction(
  '[Custom Form] Delete Custom Form Succeeded',
  props<{ formId: number }>()
);

export const UpdateCustomFormFieldValue = createAction(
  '[Custom Form] Update Custom Form Field Value',
  props<{ taskId: number; formId: number; fieldId: number; value: any }>()
);

export const UpdateCustomFormFieldValueSucceeded = createAction(
  '[Custom Form] Update Custom Form Field Value Succeeded'
);

export const HandleUpdateCustomFormFieldValue = createAction(
  '[Custom Form] Handle Update Custom Form Field Value',
  props<{ fieldId: number; value: any }>()
);
