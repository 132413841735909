import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider, PaginationResult } from '@celum/core';
import {
  ActivityKey,
  CollaborationPropertyFilter
} from '@celum/work/app/collaboration-properties/components/activity/activity.constants';
import { EntityTranslator } from '@celum/work/app/core/communication/entity-translator';
import {
  ResultConsumerService,
  TranslatedDataToStore
} from '@celum/work/app/core/communication/result-consumer.service';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { Activity, ActivityType } from '@celum/work/app/core/model/entities/activity/activity.model';
import { CollaborationProperty } from '@celum/work/app/core/model/entities/collaboration-property/collaboration-property.model';
import { CommentType } from '@celum/work/app/core/model/entities/comment/comment.model';
import { FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { PersonType } from '@celum/work/app/core/model/entities/person';
import { MetaInfo } from '@celum/work/app/core/model/meta-info.model';

export enum CollaborationPropertySearchType {
  COMMENT = 'COMMENT',
  ACTIVITY = 'ACTIVITY',
  ALL = 'ALL'
}

export interface ActivityCountDTO {
  activities: string[];
  onlyLoggedIn?: boolean;
}

@Injectable({ providedIn: 'root' })
export class CollaborationPropertyService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public loadCollaborationProperties(
    filter: CollaborationPropertyFilter,
    paging: Paging,
    sorting: Sorting,
    collaborationPropertyType: CollaborationPropertySearchType = CollaborationPropertySearchType.ALL
  ): Observable<LoadCollaborationPropertiesResult> {
    const resultsSchema = {
      results: [
        new schema.Union(
          {
            Comment: CommentType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY] }),
            Activity: ActivityType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY] })
          },
          EntityTranslator.TYPE_KEY
        )
      ]
    };

    const metaInfo = MetaInfo.of(
      [PersonType.TYPE_KEY, CommentType.TYPE_KEY, ActivityType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED],
      resultsSchema,
      [CommentType.TYPE_KEY, ActivityType.TYPE_KEY],
      'results'
    );
    const body: any = {
      paging: paging,
      sorting: sorting,
      collaborationPropertyType,
      onlyAffected: false,
      ...filter
    };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/collaboration/search`, body)
      .pipe(
        map((res: any) => {
          const entitiesResult = this.resultConsumerService.translate(res, metaInfo);
          return {
            collaborationProperties: entitiesResult.combinedEntities as CollaborationProperty[],
            paginationResult: entitiesResult.paginationResult,
            dataToStore: entitiesResult.dataToStore
          };
        })
      );
  }

  public countActivitiesByKey(countDto: ActivityCountDTO): Observable<{ [key in ActivityKey]?: number }> {
    return this.httpClient.post(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/collaboration/activities/count`,
      countDto
    );
  }

  public getPollingActivities(body: any): Observable<Activity[]> {
    body.collaborationPropertyType = CollaborationPropertySearchType.ACTIVITY;
    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/collaboration/search`, body)
      .pipe(map(({ results }: { results: Activity[] }) => results));
  }
}
export interface LoadCollaborationPropertiesResult {
  collaborationProperties: CollaborationProperty[];
  paginationResult: PaginationResult;
  dataToStore: TranslatedDataToStore[];
}
