import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';

import {
  ItemLinkCreateItemLinkFailed,
  ItemLinkCreateItemLinkSucceeded,
  ItemLinkDeleteItemLink,
  ItemLinkDeleteItemLinkFailed,
  ItemLinkLoadItemLinksByType,
  ItemLinkLoadItemLinksByTypeFailed,
  ItemLinkLoadItemLinksByTypeSucceeded
} from '@celum/work/app/core/api/item-link/item-link.actions';
import { ItemLinkRelationType } from '@celum/work/app/core/model/entities/item-link/item-link.model';
import {
  initialPaginatedListState,
  initialSimpleListState,
  PaginatedListState,
  SimpleListState
} from '@celum/work/app/core/model/list.model';

import { ItemLinkChangeDisplayedItemLinksCount, ItemLinkSetItemLinksPanelExpandedState } from './item-link.actions';

export const ITEM_LINK_BATCH_SIZE = 5;
export const ITEM_LINK_CREATION_LIMIT = 50;

interface ItemLinkStateRelationSlice extends SimpleListState<string>, PaginatedListState {
  displayedItemLinksCount: number;
  creationDisabled: boolean;
}

type ItemLinkStateMapped = {
  [key in ItemLinkRelationType]: ItemLinkStateRelationSlice;
};

export interface ItemLinkState extends ItemLinkStateMapped {
  expanded: boolean;
}

export const initialState: ItemLinkState = {
  [ItemLinkRelationType.DEPENDS_ON]: {
    ...initialSimpleListState,
    ...initialPaginatedListState,
    batchSize: ITEM_LINK_BATCH_SIZE,
    displayedItemLinksCount: 0,
    creationDisabled: false
  },
  [ItemLinkRelationType.NECESSARY_FOR]: {
    ...initialSimpleListState,
    ...initialPaginatedListState,
    batchSize: ITEM_LINK_BATCH_SIZE,
    displayedItemLinksCount: 0,
    creationDisabled: false
  },
  expanded: true
};

const reducer = createReducer(
  initialState,
  on(ItemLinkLoadItemLinksByType, (state, { offset, itemLinkRelationType }) =>
    produce(state, draft => {
      draft[itemLinkRelationType].loading = true;

      if (offset === 0) {
        draft[itemLinkRelationType].loading = true;
        draft[itemLinkRelationType].entityIds = [];
        draft[itemLinkRelationType].lastOffset = 0;
      }
    })
  ),
  on(ItemLinkLoadItemLinksByTypeSucceeded, (state, { itemLinks, paginationResult, resetState, itemLinkRelationType }) =>
    produce(state, draft => {
      const respectiveSlice = draft[itemLinkRelationType];
      const itemLinksIds = itemLinks.map(itemLink => itemLink.id);

      respectiveSlice.loading = false;

      respectiveSlice.entityIds = resetState
        ? [...itemLinksIds]
        : [...state[itemLinkRelationType].entityIds, ...itemLinksIds];
      respectiveSlice.lastOffset = respectiveSlice.entityIds.length;
      respectiveSlice.paginationResult = paginationResult;
      respectiveSlice.displayedItemLinksCount = respectiveSlice.entityIds.length;
      respectiveSlice.creationDisabled = respectiveSlice.paginationResult.totalElementCount >= ITEM_LINK_CREATION_LIMIT;
    })
  ),
  on(ItemLinkLoadItemLinksByTypeFailed, (state, { itemLinkRelationType }) =>
    produce(state, draft => {
      draft[itemLinkRelationType].loading = false;
    })
  ),
  on(ItemLinkSetItemLinksPanelExpandedState, (state, { expanded }) =>
    produce(state, draft => {
      draft.expanded = expanded;
    })
  ),
  on(ItemLinkCreateItemLinkSucceeded, (state, { itemLinkRelationType, itemLink }) =>
    produce(state, draft => {
      const respectiveSlice = draft[itemLinkRelationType];

      respectiveSlice.entityIds = [itemLink.id, ...respectiveSlice.entityIds];
      respectiveSlice.paginationResult = {
        ...respectiveSlice.paginationResult,
        totalElementCount: respectiveSlice.paginationResult.totalElementCount + 1
      };
      respectiveSlice.lastOffset = respectiveSlice.lastOffset + 1;
      respectiveSlice.creationDisabled = respectiveSlice.paginationResult.totalElementCount >= ITEM_LINK_CREATION_LIMIT;
      respectiveSlice.displayedItemLinksCount += 1;
    })
  ),
  on(ItemLinkCreateItemLinkFailed, (state, { itemLinkRelationType, createdItemLink }) =>
    produce(state, draft => {
      const respectiveSlice = draft[itemLinkRelationType];

      respectiveSlice.entityIds = respectiveSlice.entityIds.filter(id => id !== createdItemLink.id);
      respectiveSlice.paginationResult = {
        ...respectiveSlice.paginationResult,
        totalElementCount: respectiveSlice.paginationResult.totalElementCount - 1
      };
      respectiveSlice.lastOffset = respectiveSlice.lastOffset - 1;
      respectiveSlice.creationDisabled = respectiveSlice.paginationResult.totalElementCount >= ITEM_LINK_CREATION_LIMIT;
      respectiveSlice.displayedItemLinksCount -= 1;
    })
  ),
  on(ItemLinkChangeDisplayedItemLinksCount, (state, { itemLinkRelationType, count }) =>
    produce(state, draft => {
      draft[itemLinkRelationType].displayedItemLinksCount = count;
    })
  ),
  on(ItemLinkDeleteItemLink, (state, { itemLink, itemLinkRelationType }) =>
    produce(state, draft => {
      const respectiveSlice = draft[itemLinkRelationType];

      respectiveSlice.entityIds = respectiveSlice.entityIds.filter(id => id !== itemLink.id);
      respectiveSlice.lastOffset -= 1;
      respectiveSlice.paginationResult.totalElementCount -= 1;
      respectiveSlice.displayedItemLinksCount -= 1;
      respectiveSlice.creationDisabled = respectiveSlice.paginationResult.totalElementCount >= ITEM_LINK_CREATION_LIMIT;
    })
  ),
  on(ItemLinkDeleteItemLinkFailed, (state, { itemLink, itemLinkRelationType }) =>
    produce(state, draft => {
      const respectiveSlice = draft[itemLinkRelationType];

      respectiveSlice.entityIds = [...respectiveSlice.entityIds, itemLink.id];
      respectiveSlice.lastOffset += 1;
      respectiveSlice.paginationResult.totalElementCount += 1;
      respectiveSlice.displayedItemLinksCount += 1;
      respectiveSlice.creationDisabled = respectiveSlice.paginationResult.totalElementCount >= ITEM_LINK_CREATION_LIMIT;
    })
  )
);

export function itemLinkStateReducer(state: ItemLinkState = initialState, action: Action): ItemLinkState {
  return reducer(state, action);
}
