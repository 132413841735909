<snackbar-group
    [config]="titleConfig$ | async"
    [items]="progressTasks$ | async"
    [itemTemplate]="progressGroupItemTemplate"
    (closeAll)="onCloseAll()"
    (dismiss)="onDismiss()">
</snackbar-group>

<ng-template #progressGroupItemTemplate let-item="item">
  <snackbar-group-item
      [title]="item.params.getTitle()"
      [buttonStateConfigs]="getProgressTaskButtonConfigs(item)"
      [status]="item.status"
      [error]="item | progressTaskError"
      [icon]="getProgressTaskIcon(item)">
  </snackbar-group-item>
</ng-template>
