import { MatDialogConfig } from '@angular/material/dialog';
import { createAction, props } from '@ngrx/store';

import { PaginationResult } from '@celum/core';
import { ConfigurationKey } from '@celum/work/app/core/model/configuration.model';
import { Template } from '@celum/work/app/core/model/entities/template/template.model';
import { WorkroomWizardData } from '@celum/work/app/workroom-wizard/components/workroom-wizard.component';

export const WorkroomCreatorInit = createAction('[WorkroomCreator] Init');

export const WorkroomCreatorInited = createAction('[WorkroomCreator] Inited');
export const WorkroomCreatorWorkroomCreated = createAction('[WorkroomCreator] Workroom created');

export const WorkroomCreatorTemplatesLoaded = createAction(
  '[WorkroomCreator] Templates Loaded',
  props<{ templates: Template[]; paginationResult: PaginationResult }>()
);

export const WorkroomCreatorRefreshTemplates = createAction(
  '[WorkroomCreator] Refresh templates for categories',
  props<{ categoryIds: number[]; includePersonal: boolean }>()
);

export const WorkroomCreatorTemplateDelete = createAction('[WorkroomCreator] Template Delete', props<{ id: number }>());

export const WorkroomCreatorFetchNextTemplateBatch = createAction(
  '[WorkroomCreator] Fetch next template batch',
  props<{ categoryIds: number[]; includePersonal: boolean }>()
);

export const WorkroomCreatorNextTemplateBatchFetched = createAction(
  '[WorkroomCreator] Next template batch fetched',
  props<{ templates: Template[]; paginationResult: PaginationResult }>()
);

export const WorkroomCreatorFetchTemplateById = createAction(
  '[WorkroomCreator] Fetch template by id',
  props<{ templateId: number }>()
);

export const WorkroomCreatorTemplateLoaded = createAction(
  '[WorkroomCreator] Template data loaded',
  props<{ template: Template }>()
);

export const WorkroomCreatorTemplateCreated = createAction(
  '[WorkroomCreator] Template created',
  props<{ id: number }>()
);

export const WorkroomCreatorSetImportIntentRepositoryId = createAction(
  '[WorkroomCreator] Set import intent repository id',
  props<{ importIntentRepositoryId: string }>()
);

export const WorkroomCreatorSetImportIntentCollectionName = createAction(
  '[WorkroomCreator] Set import intent collection name',
  props<{ importIntentCollectionName: string }>()
);

export const WorkroomCreatorResetImportIntentInformation = createAction(
  '[WorkroomCreator] Reset import intent information'
);

export const WorkroomCreatorLoadDefaultConfig = createAction(
  '[WorkroomCreator] Load default config',
  props<{ configKey: ConfigurationKey.DEFAULT_WORKROOM | ConfigurationKey.DEFAULT_WORKROOM_TEMPLATE }>()
);

export const WorkroomCreatorLoadDefaultConfigSuccess = createAction(
  '[WorkroomCreator] Load default config success',
  props<{
    configKey: ConfigurationKey.DEFAULT_WORKROOM | ConfigurationKey.DEFAULT_WORKROOM_TEMPLATE;
    config: any;
  }>()
);

export const WorkroomCreatorOpenDialog = createAction(
  '[WorkroomCreator] Open Workroom Wizard Dialog',
  props<{ templateId: number; dialogConfig: MatDialogConfig<WorkroomWizardData> }>()
);

export const WorkroomCreatorResetState = createAction('[WorkroomCreator] Workroom Creator State reset');
