import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { EntityTranslator } from '@celum/work/app/core/model';
import { Portal } from '@celum/work/app/core/model/entities/portal/portal.model';
import { selectPortalById } from '@celum/work/app/core/model/entities/portal/portal.selectors';

import { ItemLink, ItemLinkType, TargetContext } from './item-link.model';
import { Task } from '../task/task.model';
import { selectTaskById } from '../task/task.selectors';

export class ItemLinkTranslator implements EntityTranslator<ItemLink> {
  public translateToEntity(json: any): ItemLink {
    return {
      id: `${json.sourceId}_${json.type}_${json.targetId}`,
      targetId: json.targetId,
      type: json.type,
      entityType: ItemLinkType.instance(),
      sourceId: json.sourceId,
      targetContext: json.targetContext,
      targetObject: <T extends Task | Portal>(store: Store<any>) => {
        if (json.targetContext === TargetContext.PORTAL) {
          return store.select(selectPortalById(json.targetId)) as Observable<T>;
        }
        return store.select(selectTaskById(json.targetId)) as Observable<T>;
      }
    };
  }
}
