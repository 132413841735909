import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { map as lodashMap } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { Workroom, WorkroomStatus } from '@celum/work/app/core/model/entities/workroom';
import { selectLoggedInPersonId } from '@celum/work/app/core/ui-state/ui-state.selectors';
import { ColorService } from '@celum/work/app/shared/util';

@Component({
  selector: 'workroom-icon',
  templateUrl: './workroom-icon.component.html',
  styleUrls: ['./workroom-icon.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WorkroomIconComponent {
  @Input() public workroom: Workroom;
  @Input() public iconSize: number;
  @Input() public clickable: boolean;

  public finishedIcon = IconConfiguration.medium('finish-l').withColor('#ffffff');
  public workroomIcon = IconConfiguration.large('workroom-icon-transparent');
  public lockIcon = IconConfiguration.medium('lock-icon');

  constructor(
    private colorService: ColorService,
    private store: Store<any>
  ) {}

  public get backgroundColor$(): Observable<SafeStyle> {
    return this.isLoggedInPersonContributor().pipe(
      map(isContributor => !isContributor || this.workroom.status === WorkroomStatus.INACTIVE),
      map(showGrayBackground =>
        showGrayBackground
          ? this.colorService.getGradientForFinishedWorkroom()
          : this.colorService.getGradientHeaderStyle(this.workroom.color)
      )
    );
  }

  public get workroomIconWithTooltipConfig$(): Observable<IconWithTooltipConfig> {
    if (this.workroom.status === WorkroomStatus.INACTIVE) {
      return of({
        icon: this.finishedIcon.withIconSize(this.iconSize),
        tooltip: 'WORKROOM_ICON.TOOLTIPS.ICON_FINISHED'
      });
    }
    return this.isLoggedInPersonContributor().pipe(
      map(isContributor =>
        isContributor
          ? {
              icon: this.workroomIcon.withIconSize(this.iconSize),
              tooltip: ''
            }
          : {
              icon: this.lockIcon.withIconSize(this.iconSize),
              tooltip: 'WORKROOM_ICON.TOOLTIPS.ICON_LOCKED'
            }
      )
    );
  }

  public isLoggedInPersonContributor(): Observable<boolean> {
    return this.workroom
      .persons(this.store)
      .pipe(
        switchMap(persons =>
          this.store
            .select(selectLoggedInPersonId)
            .pipe(map(loggedInPersonId => lodashMap(persons, 'id').includes(loggedInPersonId)))
        )
      );
  }
}

export interface IconWithTooltipConfig {
  icon: IconConfiguration;
  tooltip: string;
}
