import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { EntityTranslator } from '../../entity';
import { selectFileEntities } from '../file/file.selectors';
import { selectPersonEntities } from '../person';
import { Task, TaskType } from '../task/task.model';
import { selectTaskById, selectTaskPreviewByTaskId } from '../task/task.selectors';

export class TaskTranslator implements EntityTranslator<Task> {
  public translateToEntity(json: any): Task {
    const id = json.id;

    return {
      id,
      name: json.name,
      sort: json.sort,
      dueDate: json.dueDate,
      createdOn: json.createdOn,
      changedOn: json.changedOn,
      modifiedDate: json.modifiedDate,
      description: json.description,
      taskListId: json.taskListId,
      createdBy: json.createdById,
      changedBy: json.changedById,
      assigneeIds: json.assigneeIds,
      attachmentIds: json.attachmentIds,
      numberOfComments: json.numberOfComments,
      previewFileId: json.previewFileId,
      previewOrigin: json.previewOrigin,
      workroomAdditionalInfo: json.workroomAdditionalInfo,
      taskListAdditionalInfo: json.taskListAdditionalInfo,
      workroomId: json.workroomId,
      entityType: TaskType.instance(),
      priority: json.priority,
      subtaskInformation: json.subtaskInformation,
      taskLinkInformation: json.taskLinkInformation,
      parentTaskInformation: json.parentTaskInformation,
      status: json.status,
      parentId: json.parentId,
      customFormId: json.customFormId,
      creator: (store: Store<any>) =>
        combineLatest([store.select(selectTaskById(id)), store.select(selectPersonEntities)]).pipe(
          filter(([task]) => !!task),
          map(([task, persons]) => persons[task.createdBy])
        ),
      modifier: (store: Store<any>) =>
        combineLatest([store.select(selectTaskById(id)), store.select(selectPersonEntities)]).pipe(
          filter(([task]) => !!task),
          map(([task, persons]) => persons[task.changedBy])
        ),
      assignees: (store: Store<any>) =>
        combineLatest([store.select(selectTaskById(id)), store.select(selectPersonEntities)]).pipe(
          filter(([task]) => !!task),
          map(([task, persons]) => task.assigneeIds.map(assigneeId => persons[assigneeId]).filter(person => !!person))
        ),
      attachments: (store: Store<any>) =>
        combineLatest([store.select(selectTaskById(id)), store.select(selectFileEntities)]).pipe(
          filter(([task]) => !!task),
          map(([task, files]) => task.attachmentIds.map(attachmentId => files[attachmentId]).filter(file => !!file))
        ),
      previewRenditions: (store: Store<any>) =>
        store.select(selectTaskPreviewByTaskId(id)).pipe(
          filter(taskPreview => !!taskPreview),
          switchMap(taskPreview =>
            taskPreview.activeVersion(store).pipe(
              filter(activeVersion => !!activeVersion),
              switchMap(activeVersion => activeVersion.renditions(store))
            )
          )
        ),
      previewFile: (store: Store<any>) => store.select(selectTaskPreviewByTaskId(id))
    };
  }
}
