import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';

import { ContentHubImportActions } from '@celum/work/app/content-hub/store/import.actions';
import { isNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { ProgressTaskActions } from '../store/progress-task.actions';
import { ProgressTask, ProgressTaskStatus, ProgressTaskType } from '../store/progress-task.model';
import { selectProgressTasks } from '../store/progress-task.selectors';

@Injectable()
export class ProgressTaskHandlerService {
  private unhandledProgressTasks: ProgressTask<any, any>[] = [];

  constructor(
    private actions$: Actions,
    private store: Store<any>
  ) {
    this.actions$
      .pipe(
        ofType(ContentHubImportActions.HandleMissingProgressTaskMessages),
        switchMap(() => this.store.select(selectProgressTasks))
      )
      .subscribe(progressTasksFromStore => {
        this.unhandledProgressTasks
          .splice(0, this.unhandledProgressTasks.length)
          .forEach(progressTask => this.handleProgressTask(progressTask, progressTasksFromStore, false));
      });
  }

  private static getAction(
    progressTask: ProgressTask<any, any>,
    progressTaskStore: ProgressTask<any, any>,
    serverUpdated: boolean
  ): Action {
    if (progressTask.status === ProgressTaskStatus.FINISHED) {
      return ProgressTaskActions.Finished({
        progressTaskId: progressTask.id,
        payload: progressTask.payload
      });
    }
    if (progressTask.status === ProgressTaskStatus.FAILED) {
      return ProgressTaskActions.Failed({
        progressTaskId: progressTask.id,
        errorKey: progressTask.errorKey || progressTask.payload?.errorKey
      });
    }
    const isTypeExportToCH = progressTask.type === ProgressTaskType.EXPORT_TO_CH;
    if (
      (progressTask.status === ProgressTaskStatus.QUEUED && !progressTaskStore) ||
      // necessary since queued messages are filtered out on handleProgressTask method for export
      (progressTask.status === ProgressTaskStatus.IN_PROGRESS && !progressTaskStore && isTypeExportToCH)
    ) {
      return ProgressTaskActions.CreateFromWebSocketMessage({
        progressTaskType: progressTask.type,
        payload: progressTask.payload
      });
    }
    return ProgressTaskActions.UpdateOne({
      id: progressTask.id,
      changes: {
        status: progressTask.status,
        payload: progressTask.payload,
        serverUpdated
      }
    });
  }

  public handleProgressTask(
    progressTask: ProgressTask<any, any>,
    progressTasksFromStore: ProgressTask<any, any>[],
    serverUpdated: boolean
  ): void {
    // needed because SLIB BE can not filter out the queued status message
    if (progressTask.type === ProgressTaskType.EXPORT_TO_CH && progressTask.status === ProgressTaskStatus.QUEUED) {
      return;
    }

    const foundProgressTask = progressTasksFromStore
      .filter(({ payload, params }) => {
        return (
          payload?.id === progressTask?.payload?.id ||
          (params?.targetId === progressTask?.payload?.assetId && params?.file?.id === progressTask?.payload?.fileId)
        );
      })
      .pop();

    this.store.dispatch(
      ProgressTaskHandlerService.getAction(
        {
          ...progressTask,
          id: foundProgressTask?.id
        },
        foundProgressTask,
        serverUpdated
      )
    );

    if (isNullOrUndefined(foundProgressTask) && progressTask.status !== ProgressTaskStatus.QUEUED) {
      this.unhandledProgressTasks.push(progressTask);
    }
  }
}
