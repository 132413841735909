<div class="custom-forms-virtual-scroll-table_row">
  <div class="custom-forms-virtual-scroll-table_row_name-column">
    <text-truncator
        class="custom-forms-virtual-scroll-table_row_name-column_text"
        [attr.data-cy]="'custom-forms-virtual-scroll-table_row_name-column_text-' + customForm.id"
        [text]="customForm.name">
    </text-truncator>
  </div>
  <div class="custom-forms-virtual-scroll-table_row_task-count-column">
    <text-truncator
        class="custom-forms-virtual-scroll-table_row_task-count-column_text"
        [text]="customForm.taskCounter?.toString()">
    </text-truncator>
  </div>
  <div class="custom-forms-virtual-scroll-table_row_created-by-column">
    <interactive-avatars
        class="custom-forms-virtual-scroll-table_row_created-by-column_avatar"
        [darkOverflowBackground]="true"
        [hasAddPermissions]="false"
        [hasRemovePermissions]="false"
        [showUnableToAddListOwner]="false"
        [allPeople]="[(customForm.createdBy(store) | async)]"
        [selectedPeople]="[(customForm.createdBy(store) | async)]"
        [maxNumberOfAvatars]="1">
    </interactive-avatars>
  </div>
  <div class="custom-forms-virtual-scroll-table_row_creation-date-column">
    <text-truncator
        class="custom-forms-virtual-scroll-table_row_creation-date-column_text"
        [text]="customForm.createdOn | localizedDate: dateFormat.SEARCH_RESULT">
    </text-truncator>
  </div>
  <div class="custom-forms-virtual-scroll-table_row_menu-column">
    <custom-forms-context-menu
        *ngIf="hasWorkroomsFinishPermission"
        [formId]="customForm.id"
        (copyAsNewDialogOpened)="copyAsNewDialogOpened.emit()"
        (deleteDialogOpened)="deleteDialogOpened.emit()">
    </custom-forms-context-menu>
  </div>
</div>