import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Marker as AnnotationsMarker } from '@celum/annotations';
import { CelumPropertiesProvider } from '@celum/core';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo } from '@celum/work/app/core/model';
import { Marker, MarkerSubType, MarkerType } from '@celum/work/app/core/model/entities/marker/marker.model';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  constructor(
    private resultConsumerService: ResultConsumerService,
    private httpClient: HttpClient
  ) {}

  public getAllMarkersByType(
    workroomId: number,
    fileVersionId: string,
    fileId: string,
    type: MarkerSubType
  ): Observable<Marker[]> {
    const resultsSchema = [MarkerType.instance().getSchema()];
    const metaInfo = MetaInfo.of([MarkerType.TYPE_KEY], resultsSchema);
    return this.httpClient
      .post<any>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/file/${fileId}/version/${fileVersionId}/marker/search`,
        {
          workroomId,
          type
        }
      )
      .pipe(
        map(markers => {
          const wrappedMarkers = markers.reduce(
            (acc, { id, content, typeKey }) => [
              ...acc,
              ...this.toMarkerArray(content).map(marker => {
                return { ...this.getMarkerDto(marker, id), typeKey };
              })
            ],
            []
          );
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(wrappedMarkers, metaInfo);

          return entitiesResult.entities[MarkerType.TYPE_KEY] as Marker[];
        })
      );
  }

  public createStamp(
    workroomId: number,
    fileId: string,
    fileVersionId: string,
    newStamp: AnnotationsMarker
  ): Observable<MarkerWrapper> {
    return this.httpClient
      .post<any>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/file/${fileId}/version/${fileVersionId}/marker/stamp`,
        {
          workroomId,
          content: newStamp
        }
      )
      .pipe(map(({ id, content }) => this.getMarkerDto(content, id)));
  }

  public updateStamp(
    markerId: number,
    updatedStamp: AnnotationsMarker,
    fileVersionId: string,
    fileId: string
  ): Observable<MarkerWrapper> {
    return this.httpClient
      .put<any>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/file/${fileId}/version/${fileVersionId}/marker/stamp/${markerId}`,
        {
          content: updatedStamp
        }
      )
      .pipe(map(({ id, content }) => this.getMarkerDto(content, id)));
  }

  public deleteStamp(markerId: number, fileVersionId: string, fileId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/file/${fileId}/version/${fileVersionId}/marker/stamp/${markerId}`
    );
  }

  private toMarkerArray(marker: AnnotationsMarker | AnnotationsMarker[]): AnnotationsMarker[] {
    return Array.isArray(marker) ? marker : [marker];
  }

  private getMarkerDto(marker: AnnotationsMarker, serverId: number): MarkerWrapper {
    return {
      ...marker,
      serverId,
      viewId: String(marker.viewId)
    };
  }
}

export interface MarkerWrapper extends AnnotationsMarker {
  serverId: number;
}
