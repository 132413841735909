import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { EntityUtil } from '@celum/work/app/core/model/entity-util';

import {
  WorkroomGroupDelete,
  WorkroomGroupDeleteFailed,
  WorkroomGroupEditName,
  WorkroomGroupEditNameFailed,
  WorkroomGroupMove,
  WorkroomGroupMoveFailed,
  WorkroomGroupUpsertMany
} from './workroom-group.actions';
import { WorkroomGroup, WorkroomGroupState } from './workroom-group.model';

export const workroomGroupAdapter: EntityAdapter<WorkroomGroup> = createEntityAdapter<WorkroomGroup>();

export const initialState: WorkroomGroupState = workroomGroupAdapter.getInitialState();
type workroomGroupProp = keyof WorkroomGroup;
const properties: workroomGroupProp[] = ['name', 'editable', 'sort'];

const reducer = createReducer(
  initialState,
  on(WorkroomGroupUpsertMany, (state: WorkroomGroupState, { workroomGroups }) => {
    const changedWorkroomGroups = EntityUtil.changedEntities(properties, workroomGroups, state.entities);
    return workroomGroupAdapter.upsertMany(changedWorkroomGroups, state);
  }),
  on(WorkroomGroupEditName, (state: WorkroomGroupState, action) => {
    const update: Update<WorkroomGroup> = {
      id: action.workroomGroupId,
      changes: {
        name: action.currentName
      }
    };
    return workroomGroupAdapter.updateOne(update, state);
  }),
  on(WorkroomGroupEditNameFailed, (state: WorkroomGroupState, action) => {
    const update: Update<WorkroomGroup> = {
      id: action.workroomGroupId,
      changes: {
        name: action.name
      }
    };
    return workroomGroupAdapter.updateOne(update, state);
  }),
  on(WorkroomGroupDelete, (state: WorkroomGroupState, { group }) => {
    return workroomGroupAdapter.removeOne(group.id, state);
  }),
  on(WorkroomGroupDeleteFailed, (state: WorkroomGroupState, { group }) => {
    return workroomGroupAdapter.upsertOne(group, state);
  }),
  on(WorkroomGroupMove, (state: WorkroomGroupState, action) => {
    const update: Update<WorkroomGroup> = {
      id: action.workroomGroupId,
      changes: {
        sort: action.currentSort
      }
    };
    return workroomGroupAdapter.updateOne(update, state);
  }),
  on(WorkroomGroupMoveFailed, (state: WorkroomGroupState, action) => {
    const update: Update<WorkroomGroup> = {
      id: action.workroomGroupId,
      changes: {
        sort: action.sort
      }
    };
    return workroomGroupAdapter.updateOne(update, state);
  })
);

export function workroomGroupReducer(state: WorkroomGroupState = initialState, action: Action): WorkroomGroupState {
  return reducer(state, action);
}
