import { createAction, props } from '@ngrx/store';

export const SubtaskStateChangeDisplayedSubtasksCount = createAction(
  '[Subtask State] Change Displayed Subtasks Count',
  props<{ count: number }>()
);

export const SubtaskStateChangeDisplayedSubtasksCountByOne = createAction(
  '[Subtask State] Change Displayed Subtasks Count By One',
  props<{ delta: number }>()
);

export const SubtaskStateSetSubtasksPanelExpandedState = createAction(
  '[Subtask State] Toggle Subtasks Panel Expanded State',
  props<{ expanded: boolean }>()
);
