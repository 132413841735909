import { Store } from '@ngrx/store';
import Pusher, { Channel } from 'pusher-js';
import { BehaviorSubject } from 'rxjs';
import { filter, pluck, take } from 'rxjs/operators';

import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { AbstractWebsocketConnectionService } from './abstract-websocket-connection.service';
import { PusherAuthService } from './pusher-auth.service';
import { selectLoggedInPerson } from '../ui-state/ui-state.selectors';

export class WebsocketPusherConnectionService extends AbstractWebsocketConnectionService {
  public static pusher$: BehaviorSubject<Pusher> = new BehaviorSubject<Pusher>(undefined);
  public static userChannel$: BehaviorSubject<Channel> = new BehaviorSubject<Channel>(undefined);

  private static readonly USER_CHANNEL = 'private-server-to-user-';
  private static readonly CONNECTED = 'connected';

  constructor(
    private store: Store<any>,
    private pusherAuthService: PusherAuthService
  ) {
    super();
    this.configurePusher();
  }

  public init(): void {
    // for pusher no need to do anything
  }

  private configurePusher() {
    this.store
      .select(selectLoggedInPerson)
      .pipe(
        filter(person => notNullOrUndefined(person)),
        take(1),
        pluck('oid')
      )
      .subscribe(oid => {
        const pusher = this.connectToPusher();
        this.subscribeToUserChannel(pusher, oid);
        this.publishConnectionStates(pusher);
      });
  }

  private connectToPusher() {
    const pusher = new Pusher(`${(window as any).Celum.properties.websocketPusherAppKey}`, {
      cluster: 'eu',
      channelAuthorization: {
        customHandler: (params, callback) => {
          const { socketId, channelName } = params;
          this.pusherAuthService
            .authorizeChannel({
              channelName,
              socketId
            })
            .subscribe(result => {
              callback(null, result);
            });
        },
        endpoint: null, // this should not be used by Pusher since we have the customHandler
        transport: 'ajax'
      }
    });
    WebsocketPusherConnectionService.pusher$.next(pusher);
    return pusher;
  }

  private subscribeToUserChannel(pusher: Pusher, oid: string) {
    const userChannel = pusher.subscribe(WebsocketPusherConnectionService.USER_CHANNEL + oid);
    WebsocketPusherConnectionService.userChannel$.next(userChannel);
  }

  private publishConnectionStates(pusher: Pusher) {
    pusher.connection.bind(WebsocketPusherConnectionService.CONNECTED, () => this.seenFirstConnection$.next(true));
    pusher.connection.bind(`state_change`, states =>
      this.isConnected$.next(states.current === WebsocketPusherConnectionService.CONNECTED)
    );
  }
}
