import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { filter, finalize, map, withLatestFrom } from 'rxjs/operators';

import { selectLoggedInPerson, selectTenant } from '@celum/work/app/core/ui-state/ui-state.selectors';
import { VersionService } from '@celum/work/app/core/version/version.service';
import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

interface ApplicationInsightsConfig {
  endpoint: string;
  instrumentationKey: string;
  samplingRate: number;
}

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService implements OnDestroy {
  private routerSubscription: Subscription;
  private appInsights: ApplicationInsights;
  private ignoredCodes = [401, 403, 404];
  private ignoredPaths = ['/ping'];

  constructor(
    private router: Router,
    private versionService: VersionService,
    private store: Store<any>
  ) {
    // we are initiating app insights only when instrumentationKey is set
    if (this.config.instrumentationKey) {
      this.initAppInsights(this.config);

      this.store
        .select(selectLoggedInPerson)
        .pipe(
          filter(person => notNullOrUndefined(person)),
          map(person => person.oid),
          withLatestFrom(this.store.select(selectTenant)),
          finalize(() => this.clearUserId())
        )
        .subscribe(([oid, tenant]) => {
          this.setUserId(oid, tenant);
        });

      return;

      // TODO: WR2-12193 log only some of the page views, depending on business decision
      this.startLoggingPageViews();
    }
  }

  private get config(): ApplicationInsightsConfig {
    return _.pick((window as any).Celum.properties.appInsights, ['endpoint', 'instrumentationKey', 'samplingRate']);
  }

  public setUserId(userOid: string, tenant: string) {
    this.appInsights.setAuthenticatedUserContext(userOid, tenant, true);
    this.trackEvent({ name: 'PageLoad' });
  }

  public clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  public logError(error: Error) {
    this.appInsights?.trackException({ exception: error });
  }

  public trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
    this.appInsights?.trackEvent(
      {
        ...event,
        name: `Custom${event.name}`
      },
      customProperties
    );
  }

  public ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  private initAppInsights(config: ApplicationInsightsConfig): void {
    this.appInsights = new ApplicationInsights({
      config: {
        samplingPercentage: config.samplingRate,
        instrumentationKey: config.instrumentationKey,
        ...(config.endpoint ? { endpointUrl: config.endpoint } : {})
      }
    });

    this.appInsights.loadAppInsights();

    this.appInsights.addTelemetryInitializer(envelope => {
      return !(
        this.ignoredCodes.includes(envelope.baseData.responseCode) ||
        this.ignoredPaths.includes(envelope.baseData.target)
      );
    });

    this.appInsights.context.application.ver = this.versionService.getWebVersion();
  }

  private startLoggingPageViews(): void {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(
            `${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`,
            event.urlAfterRedirects
          );
        }
      });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  private logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({
      name,
      uri
    });
    this.appInsights.trackPageViewPerformance({
      name,
      uri
    });
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    const path = snapshot.routeConfig?.path ?? '';
    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }
    return path;
  }
}
