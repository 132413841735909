import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { EntityType } from '@celum/core';
import { Entity } from '@celum/work/app/core/model';

export interface CustomFieldDropdownOption {
  id: number;
  name: string;
}

export enum CustomFieldTypes {
  TEXT = 'TEXT',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN'
}

export interface CustomField extends Entity<number, CustomFieldType> {
  name: string;
  type: CustomFieldTypes;
  teamspaceId: number;
  formsUsageCounter: number;
  dropdownOptions?: CustomFieldDropdownOption[];
}

export class CustomFieldType implements EntityType {
  public static readonly TYPE_KEY: string = 'CustomField';

  private static _instance: CustomFieldType;

  public id = CustomFieldType.TYPE_KEY;
  public inheritsFrom: Set<EntityType>;

  public static instance(): CustomFieldType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};

    return new schema.Entity(CustomFieldType.TYPE_KEY, relationDefinitions, {
      processStrategy: (entity, _parent, _) => {
        return entity;
      }
    });
  }
}

export interface CustomFieldsState extends EntityState<CustomField> {}
