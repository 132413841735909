import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, from, Observable, switchMap, tap, withLatestFrom } from 'rxjs';

import {
  ColorConstants,
  ShowSnackbar,
  SimpleSnackbar,
  SnackbarConfiguration,
  SortDirection
} from '@celum/common-components';
import { PaginationResult } from '@celum/core';
import { CelumDialogOpener } from '@celum/internal-components';
import { Permission } from '@celum/work/app/core/api/permission';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { CustomForm } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import {
  initialPaginatedListState,
  initialSimpleListState,
  PaginatedListState,
  SimpleListState
} from '@celum/work/app/core/model/list.model';
import { DeleteCustomFormSucceeded } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/custom-form/store/custom-form-state.actions';
import {
  ConfirmationDialog,
  ConfirmationDialogConfiguration
} from '@celum/work/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { PermissionUtil } from '@celum/work/app/shared/util';

import {
  CreateCustomFormDialogComponent,
  CreateCustomFormDialogConfiguration,
  CreateCustomFormDialogResponse
} from './components/create-custom-form-dialog/create-custom-form-dialog.component';
import { CustomFormErrorKeyEnum } from './model/custom-form-error-response';
import { CustomFormsService } from './services/custom-forms.service';
import { CustomFieldsService } from '../fields-overview-tab/services/custom-fields.service';

export interface CustomFormsState extends SimpleListState<number>, PaginatedListState {
  displayedCustomFormsCount: number;
  hasCustomFields: boolean;
}

export const initialState: CustomFormsState = {
  ...initialSimpleListState,
  ...initialPaginatedListState,
  batchSize: 15,
  displayedCustomFormsCount: 0,
  hasCustomFields: false
};

@Injectable()
export class CustomFormsStore extends ComponentStore<CustomFormsState> {
  public readonly customFormIds$: Observable<number[]> = this.select(state => state.entityIds);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);
  public readonly hasCustomFields$: Observable<boolean> = this.select(state => state.hasCustomFields);
  public readonly hasCustomForms$: Observable<boolean> = this.select(state => state.entityIds.length > 0);
  public readonly paginationResult$: Observable<PaginationResult> = this.select(state => state.paginationResult);
  public readonly lastOffset$: Observable<number> = this.select(state => state.lastOffset);
  public readonly batchSize$: Observable<number> = this.select(state => state.batchSize);

  public readonly setLoading = this.updater((state, loading: boolean) => ({ ...state, loading }));
  public readonly resetState = this.updater(state => ({ ...state, entityIds: [], customForms: [], lastOffset: 0 }));
  public readonly setLoadedCustomFormsData = this.updater(
    (state, { customForms, paginationResult }: { customForms: CustomForm[]; paginationResult: PaginationResult }) => {
      const customFormsIds = customForms.map(form => form.id);
      const entityIds = [...state.entityIds, ...customFormsIds];

      if (customFormsIds.length === 0) {
        this.getCustomFieldCount$();
      } else {
        this.updateHasCustomFields(true);
      }

      return {
        ...state,
        entityIds,
        lastOffset: entityIds.length,
        paginationResult: {
          totalElementCount: paginationResult.totalElementCount,
          hasTop: false,
          hasBottom: paginationResult.hasBottom
        },
        displayedCustomFormsCount: entityIds.length
      };
    }
  );

  public readonly changeBatchSize = this.updater((state, batchSize: number) => {
    return {
      ...state,
      batchSize
    };
  });

  public readonly addCustomForm = this.updater((state, newCustomForm: CustomForm) => {
    const entityIds = [newCustomForm.id, ...state.entityIds];
    const totalElementCount = state.paginationResult.totalElementCount + 1;

    return {
      ...state,
      entityIds,
      paginationResult: {
        ...state.paginationResult,
        totalElementCount
      },
      lastOffset: state.lastOffset + 1,
      displayedCustomFormsCount: state.displayedCustomFormsCount + 1
    };
  });

  public readonly removeCustomForm = this.updater((state, formId: number) => {
    const entityIds = state.entityIds.filter(id => id !== formId);
    const totalElementCount = state.paginationResult.totalElementCount - 1;

    return {
      ...state,
      entityIds,
      paginationResult: {
        ...state.paginationResult,
        totalElementCount
      },
      lastOffset: state.lastOffset - 1,
      displayedCustomFormsCount: state.displayedCustomFormsCount - 1
    };
  });

  public readonly updateHasCustomFields = this.updater((state, hasCustomFields: boolean) => ({
    ...state,
    hasCustomFields
  }));

  public readonly getCustomFieldCount$ = this.effect($empty =>
    $empty.pipe(
      withLatestFrom(this.permissionUtil.hasTeamspacePermission(Permission.WORKROOM_FINISH)),
      filter(([_, hasWorkroomsFinishPermission]) => hasWorkroomsFinishPermission),
      tap(() => this.setLoading(true)),
      switchMap(() => this.customFieldsService.getCustomFieldCount()),
      tapResponse(
        (response: any) => {
          this.setLoading(false);
          this.updateHasCustomFields(response > 0);
        },
        () => this.setLoading(false)
      )
    )
  );

  public readonly loadCustomForms$ = this.effect(
    ($config: Observable<{ offset: number; paging: Paging; sorting: Sorting }>) =>
      $config.pipe(
        tap(({ offset }) => {
          this.setLoading(true);

          if (offset === 0) {
            this.resetState();
          }
        }),
        switchMap(config =>
          this.customFormsService.loadCustomForms(config.paging, config.sorting).pipe(
            // switchMap(response => this.populateAvatarsOnCustomForms(response)),
            tapResponse(
              response => {
                this.setLoading(false);
                this.setLoadedCustomFormsData(response);
              },
              () => this.setLoading(false)
            )
          )
        )
      )
  );

  public readonly loadNextBatch$ = this.effect($empty =>
    $empty.pipe(
      concatLatestFrom(() => [this.lastOffset$, this.loading$, this.paginationResult$, this.batchSize$]),
      tap(([_, lastOffset, loading, paginationResult, batchSize]) => {
        if (!loading && paginationResult?.hasBottom) {
          this.loadCustomForms$({
            offset: lastOffset,
            paging: Paging.of(lastOffset, batchSize),
            sorting: Sorting.of('createdOn', SortDirection.DESC)
          });
        }
      })
    )
  );

  public deleteCustomForm$ = this.effect((customForm$: Observable<CustomForm>) =>
    customForm$.pipe(
      withLatestFrom(this.lastOffset$, this.batchSize$),
      switchMap(([customForm, lastOffset, batchSize]) => {
        this.removeCustomForm(customForm.id);

        // Load the next batch if the table is at the scroll threshold, otherwise the virtual scrolling breaks
        if (batchSize === lastOffset) {
          this.loadNextBatch$();
        }

        return this.customFormsService.deleteCustomForm(customForm.id).pipe(
          tapResponse(
            () => {
              this.store.dispatch(DeleteCustomFormSucceeded({ formId: customForm.id }));
            },
            () => this.addCustomForm(customForm)
          )
        );
      })
    )
  );

  public readonly displayLimitationSnackbar$ = this.effect($empty =>
    $empty.pipe(
      tap(() => {
        const config = SnackbarConfiguration.info('TEAMSPACE_MANAGEMENT.FORMS.FORM_CREATION_LIMIT_SNACKBAR')
          .withAutoVanish(true)
          .withAutoVanishTime(5000);

        this.store.dispatch(new ShowSnackbar('info', SimpleSnackbar, config));
      })
    )
  );

  public readonly openCreateCustomFormDialog$ = this.effect($empty =>
    $empty.pipe(
      tap(async () => {
        const response: CreateCustomFormDialogResponse = await this.dialogOpener.showDialog(
          CreateCustomFormDialogComponent.DIALOG_ID,
          CreateCustomFormDialogComponent,
          new CreateCustomFormDialogConfiguration()
        );

        if (response?.customForm) {
          this.addCustomForm(response.customForm);
        }

        if (response?.errorKey === CustomFormErrorKeyEnum.FORM_LIMIT_EXCEEDED) {
          this.displayLimitationSnackbar$();
        }
      })
    )
  );

  public readonly openCopyAsNewCustomFormDialog$ = this.effect((formId$: Observable<number>) =>
    formId$.pipe(
      switchMap(formId => this.customFormsService.getCustomFormById(formId)),
      tap(async (customForm: CustomForm) => {
        const dialogConfig = new CreateCustomFormDialogConfiguration({
          customForm: { ...customForm, id: null, name: null }
        });
        const response: CreateCustomFormDialogResponse = await this.dialogOpener.showDialog(
          CreateCustomFormDialogComponent.DIALOG_ID,
          CreateCustomFormDialogComponent,
          dialogConfig,
          { restoreFocus: false }
        );

        if (response?.customForm) {
          this.addCustomForm(response.customForm);
        }

        if (response?.errorKey === CustomFormErrorKeyEnum.FORM_LIMIT_EXCEEDED) {
          this.displayLimitationSnackbar$();
        }
      })
    )
  );

  public readonly openDeleteCustomFormDialog$ = this.effect((customForm$: Observable<CustomForm>) =>
    customForm$.pipe(
      switchMap(customForm => {
        let questionType = '';
        switch (customForm.taskCounter) {
          case 0: {
            questionType = 'UNUSED';
            break;
          }
          case 1: {
            questionType = 'USED_SINGULAR';
            break;
          }
          default: {
            questionType = 'USED_PLURAL';
          }
        }

        return from(
          this.dialogOpener
            .showDialog(
              'deleteCustomForm',
              ConfirmationDialog,
              new ConfirmationDialogConfiguration(
                this.translateService.instant('TEAMSPACE_MANAGEMENT.FORMS.DELETE_FORM_CONFIRMATION_DIALOG.HEADER'),
                this.translateService.instant(
                  'TEAMSPACE_MANAGEMENT.FORMS.DELETE_FORM_CONFIRMATION_DIALOG.QUESTION.' + questionType,
                  { taskCounter: customForm.taskCounter }
                ),
                ColorConstants.WARNING,
                'COMMON.DELETE'
              ),
              { restoreFocus: false }
            )
            .then(confirmed => {
              if (confirmed) {
                this.deleteCustomForm$(customForm);
              }
            })
        );
      })
    )
  );

  public readonly openViewCustomFormDialog$ = this.effect((formId$: Observable<number>) =>
    formId$.pipe(
      switchMap(formId => this.customFormsService.getCustomFormById(formId)),
      tap((customForm: CustomForm) => {
        const dialogConfig = new CreateCustomFormDialogConfiguration({
          readonlyMode: true,
          customForm
        });
        this.dialogOpener.showDialog(
          CreateCustomFormDialogComponent.DIALOG_ID,
          CreateCustomFormDialogComponent,
          dialogConfig,
          { restoreFocus: false, autoFocus: false }
        );
      })
    )
  );

  constructor(
    private customFieldsService: CustomFieldsService,
    private customFormsService: CustomFormsService,
    private dialogOpener: CelumDialogOpener,
    private store: Store<any>,
    private permissionUtil: PermissionUtil,
    private translateService: TranslateService
  ) {
    super(initialState);
  }
}
