<celum-dialog (onCloseAll)="closeDialog()">
  <celum-dialog-header>
      {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CREATE_FIELD_DIALOG.HEADING' | translate }}
  </celum-dialog-header>
  <celum-dialog-content>
    <custom-field-card [formControl]="customFieldCard"></custom-field-card>
  </celum-dialog-content>
  <celum-dialog-footer
      [confirmBtnText]="'COMMON.CREATE'"
      cancelBtnText="COMMON.CANCEL"
      [loading]="loading"
      [valid]="
        (!customFieldCard.pristine && !customFieldCard.invalid) ||
        (customFieldCard.invalid && customFieldCard.hasError(customFieldCardValidationErrors.NAME_NOT_UNIQUE))
      "
      (onConfirm)="onSubmit()"
      (onCancel)="closeDialog()">
  </celum-dialog-footer>
</celum-dialog>
