import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { EntityType } from '@celum/core';
import { MarkerWrapper } from '@celum/work/app/core/api/marker/marker.service';
import { Entity } from '@celum/work/app/core/model';

export enum MarkerSubType {
  COMMENT = 'COMMENT',
  STAMP = 'STAMP'
}

export interface Marker extends Entity<string, MarkerType>, MarkerWrapper {
  duration?: number;
  startTime?: number;
}

export class MarkerType implements EntityType {
  public static readonly TYPE_KEY: string = 'comment_marker';
  public static readonly STAMP_TYPE_KEY: string = 'stamp_marker';

  private static _instance: MarkerType;

  public id = MarkerType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): MarkerType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};

    return new schema.Entity(MarkerType.TYPE_KEY, relationDefinitions);
  }
}

export interface MarkerState extends EntityState<Marker> {}

export const markerProperties = [
  'creator',
  'direction',
  'duration',
  'startTime',
  'index',
  'label',
  'objectType',
  'serverId',
  'shape',
  'viewId'
];
