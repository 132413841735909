import { createAction, props } from '@ngrx/store';

import { InvitedPerson, Template } from '../../model/entities/template/template.model';

export const CreateTemplate = createAction(
  '[TEMPLATE_CHOOSER] Create',
  props<{ template: Template; people: InvitedPerson[]; category?: string }>()
);

export const FetchTemplateCategories = createAction('[TEMPLATE CHOOSER] Fetch Template Categories');
export const FetchTemplateCategoriesSuccess = createAction('[TEMPLATE CHOOSER] Fetch Template Categories Success');
