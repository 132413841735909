import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { FolderService } from '@celum/work/app/core/api/folder/folder.service';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import {
  FolderCreate,
  FolderCreateFailed,
  FolderCreateSucceeded,
  FolderUpdate,
  FolderUpdateFailed,
  FolderUpdateSucceeded
} from '@celum/work/app/core/model/entities/folder/folder.actions';

@Injectable()
export class FolderEffects {
  public createNewFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FolderCreate),
      mergeMap(action =>
        this.folderService.createNewFolder(action.folder).pipe(
          map(folder => FolderCreateSucceeded({ folder })),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(FolderCreateFailed({ folder: action.folder }));
          })
        )
      )
    )
  );

  public updateFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FolderUpdate),
      mergeMap(action =>
        this.folderService.updateFolder(action.folder, action.propertiesToUpdate).pipe(
          map(folder =>
            FolderUpdateSucceeded({
              folder,
              updatedProperties: action.propertiesToUpdate
            })
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(FolderUpdateFailed({ folder: action.folder }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private folderService: FolderService,
    private failureHandler: FailureHandler
  ) {}
}
