import { isNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

export function getDisplayedItemsCount(totalItemsCount: number, loadedItemsCount: number, batchSize: number): number {
  if (isNullOrUndefined(totalItemsCount) || totalItemsCount === 0) {
    return 0;
  }

  if (totalItemsCount < batchSize || totalItemsCount < loadedItemsCount + batchSize) {
    return totalItemsCount;
  }

  return loadedItemsCount + batchSize;
}
