import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo } from '@celum/work/app/core/model';
import { ContentItemTypeKeys } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { Folder, FolderPropertiesToUpdate, FolderType } from '@celum/work/app/core/model/entities/folder/folder.model';
import { folderUpdatableProperties } from '@celum/work/app/core/model/entities/folder/folder.reducer';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

@Injectable({ providedIn: 'root' })
export class FolderService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public createNewFolder({ name, parentId, libraryId }: Folder): Observable<Folder> {
    const body = {
      typeKey: ContentItemTypeKeys.FOLDER_CREATE,
      libraryId,
      name,
      parentId
    };

    const metaInfo = MetaInfo.of([FolderType.TYPE_KEY], FolderType.instance().getSchema());
    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content`, body, STRONGLY_CONSISTENT_OPTION)
      .pipe(map(res => this.handleSingleResult(res, metaInfo)));
  }

  public updateFolder(folder: Folder, propertiesToUpdate: FolderPropertiesToUpdate): Observable<Folder> {
    const metaInfo = MetaInfo.of([FolderType.TYPE_KEY], FolderType.instance().getSchema());
    metaInfo.partialUpdates = {
      [FolderType.TYPE_KEY]: folderUpdatableProperties
    };

    return this.httpClient
      .patch(
        `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/${folder.id}`,
        {
          typeKey: ContentItemTypeKeys.FOLDER_UPDATE,
          ...propertiesToUpdate
        },
        { ...STRONGLY_CONSISTENT_OPTION, params: { libraryId: folder.libraryId } }
      )
      .pipe(map(res => this.handleSingleResult(res, metaInfo)));
  }

  private handleSingleResult(res, metaInfo): Folder {
    const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
    const folders = entitiesResult.entities[FolderType.TYPE_KEY];
    return isEmpty(folders) ? null : (folders[0] as Folder);
  }
}
