import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TemplateChooserService } from '@celum/work/app/core/api/workroom-creator';
import { InvitedPerson, Template } from '@celum/work/app/core/model/entities/template/template.model';

import { SaveStrategy } from './save-strategy';

@Injectable({
  providedIn: 'root'
})
export class SaveEditTemplateStrategy implements SaveStrategy {
  constructor(private templateService: TemplateChooserService) {}

  public save(
    { workroomConfiguration, ...template }: Template,
    { repositoryId, ...form }: any,
    people: InvitedPerson[]
  ): Observable<any> {
    return this.templateService
      .patch(
        {
          ...form,
          id: template.id,
          taskLists: template.taskLists,
          contentHubRepositoryId: repositoryId || null,
          workroomConfiguration
        },
        people
      )
      .pipe(catchError(() => of(true)));
  }
}
