import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { EntityType } from '@celum/core';
import { Entity } from '@celum/work/app/core/model';
import { RenditionTypes } from '@celum/work/app/core/model/entities/file/file.model';
import { FileFormat } from '@celum/work/app/core/model/entities/file-version/file-version.model';

export interface Rendition extends Entity<number, RenditionType> {
  downloadUrl: string;
  storageId: string;
  type: RenditionTypes;
  originalFormat: FileFormat;
  fileVersionId: string;
}

export class RenditionType implements EntityType {
  public static readonly TYPE_KEY: string = 'Rendition';

  private static _instance: RenditionType;

  public id = RenditionType.TYPE_KEY;
  public inheritsFrom: Set<EntityType>;

  public static instance(): RenditionType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};

    return new schema.Entity(RenditionType.TYPE_KEY, relationDefinitions, {
      processStrategy: (entity, _parent, _) => {
        return entity;
      }
    });
  }
}

export interface RenditionsState extends EntityState<Rendition> {}
