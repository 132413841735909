import { Injectable } from '@angular/core';

import { CelumPropertiesProvider } from '@celum/core';

export interface BackendService {
  urls: URL[];
  requiresB2CAuthorization: boolean;
  requiresLibrariesAuthorization: boolean;
  requiresExperienceAuthorization: boolean;
  requiresTenant: boolean;
  scopes: string[];
}

@Injectable({ providedIn: 'root' })
export class BackendProviderService {
  private static readonly SCOPES = [
    'scope/User.ReadWrite',
    'scope/Workroom.ReadWrite',
    'scope/Membership.Read',
    'scope/Storage.Read'
  ];

  private static _services;

  public static get services(): BackendService[] {
    return (this._services = this._services ?? [
      this.workrooms(),
      this.sacc(),
      this.workroomsDefaultRegion(),
      this.slib(),
      this.sharing()
    ]);
  }

  public static workrooms(): BackendService {
    return {
      urls: [new URL(CelumPropertiesProvider.properties.httpBaseAddress)],
      requiresB2CAuthorization: true,
      requiresLibrariesAuthorization: false,
      requiresExperienceAuthorization: false,
      requiresTenant: true,
      scopes: BackendProviderService.SCOPES
    };
  }

  public static workroomsDefaultRegion(): BackendService {
    return {
      urls: [new URL((window as any).Celum.properties.defaultRegionHostAddress)],
      requiresB2CAuthorization: true,
      requiresLibrariesAuthorization: false,
      requiresExperienceAuthorization: false,
      requiresTenant: false,
      scopes: BackendProviderService.SCOPES
    };
  }

  public static sacc(): BackendService {
    return {
      urls: [new URL((window as any).Celum.properties.saccHttpBaseAddress)],
      requiresB2CAuthorization: true,
      requiresLibrariesAuthorization: false,
      requiresExperienceAuthorization: false,
      requiresTenant: false,
      scopes: BackendProviderService.SCOPES
    };
  }

  public static slib(): BackendService {
    return {
      urls: [new URL(CelumPropertiesProvider.properties.librariesHttpBaseAddress)],
      requiresB2CAuthorization: false,
      requiresLibrariesAuthorization: true,
      requiresExperienceAuthorization: false,
      requiresTenant: true,
      scopes: BackendProviderService.SCOPES
    };
  }

  public static sharing(): BackendService {
    return {
      urls: [new URL((window as any).Celum.properties.sharingHttpBaseAddress)],
      requiresB2CAuthorization: false,
      requiresLibrariesAuthorization: false,
      requiresExperienceAuthorization: true,
      requiresTenant: true,
      scopes: BackendProviderService.SCOPES
    };
  }
}
