import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';

import { Teamspace } from '../../../../core/model/entities/teamspace';
import { TemplateCategory } from '../../../../core/model/entities/template-category/template-category.model';

@Component({
  selector: 'organization-information',
  templateUrl: './organization-information.component.html',
  styleUrls: ['./organization-information.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationInformationComponent {
  @Input() public category: TemplateCategory;
  @Input() public hasCreateWorkroomPermission: boolean;
  @Input() public teamspace: Teamspace;

  public get companyLogoIconConfiguration(): IconConfiguration {
    const companyFallbackIconConfig = IconConfiguration.medium('company-logo-fallback');

    let iconUrl;
    // Filter personal category
    if (this.category?.id) {
      // If category is editable, then take company logo url else celum's logo
      if (this.category?.editable) {
        iconUrl = '';
      } else {
        iconUrl = 'celum-logo-polygon';
      }
    }

    if (iconUrl) {
      return IconConfiguration.medium(iconUrl);
    }
    return companyFallbackIconConfig;
  }
}
