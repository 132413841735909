<ng-container *ngIf="(templates$ | async)?.length">
  <div *ngIf="allTemplatesCategory">
    <organization-information [category]="category$ | async"
                              [teamspace]="teamspace$ | async"
                              [hasCreateWorkroomPermission]="categoryId | hasTemplateCategoryPermission: permissions.CREATE_WORKROOM | async">
    </organization-information>

    <div *ngIf="(categoryId | hasTemplateCategoryPermission: permissions.CREATE_WORKROOM | async) === false"
         class="template-chooser-template-category_missing-permission">
      <celum-icon class="template-chooser-template-category_missing-permission_icon" [configuration]="iconInfoConfig"></celum-icon>
      <span class="template-chooser-template-category_missing-permission_label"> {{'TEMPLATE_CHOOSER.CREATE_WORKROOM.NO_PERMISSION' | translate}} </span>
    </div>

    <license-limit-reached
        *ngIf="(categoryId | hasTemplateCategoryPermission: permissions.CREATE_WORKROOM | async) && ((categoryId | licenseLimitReached | async) || (oneMoreLicenseAvailable | async))"
        [showContactButton]="(category$ | async)?.id > 1"
        [teamspace]="teamspace$ | async">
    </license-limit-reached>
  </div>

  <template-chooser-template
      *ngFor="let template of templates$ | async; trackBy: trackByFn"
      [template]="template"
      [isAllowedToDelete]="(categoryId | isCategoryEditable | async) &&
                                 (categoryId | hasTemplateCategoryPermission: permissions.DELETE | async)"
      [isAllowedToUpdate]="(categoryId | isCategoryEditable | async) &&
                                 (categoryId | hasTemplateCategoryPermission: permissions.UPDATE | async)"
      [isAllowedToCreate]="(categoryId | hasTemplateCategoryPermission: permissions.CREATE_WORKROOM | async) &&
                                 (categoryId | licenseLimitReached | async) === false"
      (onCreate)="createWorkroomFromTemplate(template)"
      (onCopy)="triggerCopyTemplateDialog($event)"
      (onMove)="triggerMoveTemplateDialog($event)"
      (onEdit)="triggerEditTemplateDialog($event)"
      (onDelete)="triggerDeleteTemplateDialog($event)">
  </template-chooser-template>
</ng-container>
