import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { isTruthy, ReactiveService } from '@celum/core';
import { RefreshTaskForm } from '@celum/work/app/collaboration-properties/store/collaboration-properties.actions';
import { TaskFormAddedNotification } from '@celum/work/app/core/client-notification/model/client-notification.model';
import { NotificationWebsocketListener } from '@celum/work/app/core/client-notification/notification-websocket-listener.service';
import { selectCurrentTask } from '@celum/work/app/core/model/entities/task/task.selectors';
import { isNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

@Injectable({
  providedIn: 'root'
})
export class TaskFormNotificationListener extends ReactiveService {
  constructor(
    private store: Store<any>,
    private clientNotificationWebsocketListener: NotificationWebsocketListener<TaskFormAddedNotification>
  ) {
    super();
    this.startListening();
  }

  public startListening(): void {
    // listening for when a task is created for each imported assets
    this.clientNotificationWebsocketListener.clientNotificationsSubj
      .pipe(
        filter(notification => notification.typeKey === 'task_form_added'),
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.store.select(selectCurrentTask).pipe(isTruthy())),
        filter(([notification, currentTask]) => {
          return currentTask.id === notification.taskId && isNullOrUndefined(currentTask.customFormId);
        })
      )
      .subscribe(([notification]) => {
        this.store.dispatch(RefreshTaskForm({ taskId: notification.taskId, formId: notification.formId }));
      });
  }
}
