<celum-dialog (onCloseAll)="cancel()" class="rename-dialog">

  <celum-dialog-header>
    {{ headerText }}
  </celum-dialog-header>

  <celum-dialog-content>
    <form [formGroup]="renameContentItemActionForm" class="rename-dialog_form">
      <mat-form-field celum-form-field class="rename-dialog_form_field" [reserveHintSpace]="true">
        <input autoSelect formControlName="name" matInput [selectionRangeFn]="selectionRangeFn">
        <mat-error *ngIf="renameContentItemActionForm.get('name').hasError('required')">{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
        <mat-error *ngIf="renameContentItemActionForm.get('name').hasError('validCharacters')">
          {{ 'VALIDATION.NO_INVALID_CHARACTERS' | translate: renameContentItemActionForm.get('name').getError('validCharacters').params}}
        </mat-error>
        <mat-error *ngIf="renameContentItemActionForm.get('name').hasError('maxLength')">
          {{ 'VALIDATION.MAX_LENGTH' | translate: renameContentItemActionForm.get('name').getError('maxLength').params}}
        </mat-error>
      </mat-form-field>
    </form>
  </celum-dialog-content>

  <celum-dialog-footer (onCancel)="cancel()"
                       (onConfirm)="renameContentItem()"
                       [color]="color"
                       [valid]="!renameContentItemActionForm.invalid"
                       cancelBtnText="CONTENT_ITEMS.DIALOG.CANCEL"
                       confirmBtnText="CONTENT_ITEMS.DIALOG.RENAME.CONFIRM">
  </celum-dialog-footer>

</celum-dialog>
