import { Injectable } from '@angular/core';

import { LocalStorageService } from '@celum/work/app/core/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  constructor(private localStorage: LocalStorageService) {}

  public getApplicationSettingsOrDefault<T>(key: string, defaultValue?: T): T {
    return this.localStorage.getItem<T>(key) ?? defaultValue;
  }

  public setApplicationSettings(key: string, value: any) {
    this.localStorage.setItem(key, value);
  }

  public getWorkroomSettingsOrDefault<T>(key: string, workroomId: any, defaultValue?: T): T {
    return this.localStorage.getItem<T>(`workroom_${workroomId}_${key}`) ?? defaultValue;
  }

  public setWorkroomSettings(key: string, workroomId: number, value: any) {
    this.localStorage.setItem(`workroom_${workroomId}_${key}`, value);
  }
}
