<div class="celum-inline-form-field-buttons" [class.celum-inline-form-field-buttons--active]="active">
  <button
      class="celum-inline-form-field-buttons_cancel"
      mat-button
      celum-square-button
      [matTooltip]="'COMMON.CANCEL' | translate"
      (click)="onCancel($event)">
    <celum-icon [configuration]="cancelIcon"></celum-icon>
  </button>

  <button
      class="celum-inline-form-field-buttons_save"
      mat-button
      celum-square-button
      [matTooltip]="'COMMON.SAVE' | translate"
      (click)="onSave($event)">
    <celum-icon [configuration]="saveIcon"></celum-icon>
  </button>

  <button
      class="celum-inline-form-field-buttons_edit"
      mat-button
      [matTooltip]="'COMMON.EDIT' | translate"
      celum-square-button>
    <celum-icon [configuration]="editIcon"></celum-icon>
  </button>
</div>
