import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { CelumInlineFormFieldDirective, IconConfiguration } from '@celum/common-components';
import { Color } from '@celum/core';
import { Permission } from '@celum/work/app/core/api/permission';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import { Roles } from '@celum/work/app/core/model/roles.model';
import { TasksOverviewUpdateVisibleColumns } from '@celum/work/app/pages/workroom/pages/tasks/store/tasks-overview.actions';
import { selectVisibleColumnCount } from '@celum/work/app/pages/workroom/pages/tasks/store/tasks-overview.selectors';
import { ColorService, PermissionUtil } from '@celum/work/app/shared/util';

@Component({
  selector: 'task-list-header',
  templateUrl: './task-list-header.component.html',
  styleUrls: ['./task-list-header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TaskListHeaderComponent implements OnInit, OnChanges, AfterViewInit {
  @HostBinding('attr.data-cy') public attrDataCy = 'task-list-header';

  @Input() public listColor: string;
  @Input() public taskList: TaskList;
  @Input() public deletePermission: Permission;
  @Input() public updatePermission: Permission;
  @Input() public deletable = true;
  @Input() public autoFocused = false;
  @Input() public dynamicColumnSize: boolean;
  @Input() public hasPermissionToMoveTaskList: boolean;

  @Output() public readonly onNameChanged = new EventEmitter();
  @Output() public readonly onColorChanged = new EventEmitter();
  @Output() public readonly onDeleteTaskList = new EventEmitter();

  @ViewChild(CelumInlineFormFieldDirective) public celumInput: CelumInlineFormFieldDirective;

  public taskListColors: Color[];
  public listNameFormCtrl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  public readonly deleteIcon = IconConfiguration.small('delete');
  public readonly menuIcon = IconConfiguration.medium('option-m').withColor('#fff');

  public visibleColumnCount$: Observable<number>;
  public canChangeTaskListColor$: Observable<boolean>;
  public isVisitor$: Observable<boolean>;

  constructor(
    private colorService: ColorService,
    private store: Store<any>,
    private permissionUtil: PermissionUtil
  ) {}

  public ngOnInit() {
    this.taskListColors = this.colorService.getPredefinedColors();
    this.visibleColumnCount$ = this.store.select(selectVisibleColumnCount(this.taskList.id));
    this.isVisitor$ = this.permissionUtil.hasRoleForCurrentWorkroom(Roles.VISITOR);
    this.canChangeTaskListColor$ = this.isVisitor$.pipe(
      map(isVisitor => !isVisitor && this.updatePermission === Permission.ALLOWED)
    );
  }

  public ngOnChanges({ taskList }: SimpleChanges): void {
    if (taskList?.currentValue?.name !== taskList?.previousValue?.name) {
      this.listNameFormCtrl.setValue(this.taskList.name);
    }
  }

  public ngAfterViewInit() {
    if (this.autoFocused) {
      this.focusOnInput();
    }
  }

  public changeName(name: string) {
    const trimmedName = name.trim();
    if (!trimmedName && !this.taskList.isPlaceholder) {
      this.celumInput.resetInputValue(this.taskList.name);
    } else {
      this.onNameChanged.emit(trimmedName);
    }
  }

  public cancelEdit() {
    if (this.taskList.isPlaceholder) {
      this.onNameChanged.emit('');
    }
  }

  public deleteTaskList() {
    this.onDeleteTaskList.emit(this.taskList);
  }

  public changeColorTaskList(color: Color) {
    this.onColorChanged.emit(color);
  }

  public changeVisibleColumns(visibleColumnsCount: number): void {
    this.store.dispatch(TasksOverviewUpdateVisibleColumns({ taskListId: this.taskList.id, visibleColumnsCount }));
  }

  private focusOnInput() {
    this.celumInput.input.readonly = false;
    requestAnimationFrame(() => {
      this.celumInput.activateEditMode();
    });
  }
}
