import { createAction, props } from '@ngrx/store';

import { Entity } from '../../../core/model';
import { ContentItemType } from '../../../core/model/entities/content-item/content-item.model';

export class UploadActions {
  public static UploadFile = createAction('[Upload] Upload file', props<{ progressTaskId: string }>());

  public static UploadFailure = createAction('[Upload] Failure', props<{ progressTaskId: string; error: any }>());

  public static UploadConfirmed = createAction(
    '[Upload] Confirmed',
    props<{ progressTaskId: string; untranslatedFile: Entity<string, ContentItemType> }>()
  );
}
