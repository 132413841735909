import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CelumButtonModule, CelumIconModule, IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'collapse-button',
  standalone: true,
  imports: [CommonModule, CelumIconModule, MatTooltipModule, MatButtonModule, CelumButtonModule],
  templateUrl: './collapse-button.component.html',
  styleUrl: './collapse-button.component.less',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapseButtonComponent {
  @Input() public expanded = false;
  @Input() public collapseTooltip: string;
  @Input() public expandTooltip: string;
  @Input() public buttonSize = 28;
  @Input() public iconSize = 9;

  @Output() public toggle = new EventEmitter<boolean>();

  public toggleIcon: IconConfiguration;

  public ngOnInit() {
    this.toggleIcon = IconConfiguration.medium('arrow-down-xs').withIconSize(this.iconSize);
  }
}
