import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject, Observable } from 'rxjs';

import { EmptyPageConfig } from '@celum/common-components';

export interface VirtualScrollTableItem {
  id: number;
}

export abstract class VirtualScrollTableService<T extends VirtualScrollTableItem> {
  public readonly items$: Observable<T[]>;
  public readonly isEmpty$: Observable<boolean>;
  public readonly emptyPageConfig: EmptyPageConfig;
  public readonly loading$: Observable<boolean>;
  public readonly stickyRowConfig: string[];
  public readonly selectionEnabled?: boolean = false;
  public readonly selectedItems$?: BehaviorSubject<Record<number, T>> = new BehaviorSubject({});

  public itemSize = 60;
  public lastEmitCount: number;

  public abstract loadNextBatch(): void;

  public abstract onRequestNextPage(viewport: CdkVirtualScrollViewport): void;

  public abstract loadItems(): void;

  public abstract selectionChanged?(selected: boolean, item: T): void;

  public abstract clearSelection?(): void;
}
