import { EntityTranslator } from '@celum/work/app/core/model';
import { CustomField, CustomFieldType } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';

export class CustomFieldTranslator implements EntityTranslator<CustomField> {
  public translateToEntity(json: any): CustomField {
    const id = json.id;
    return {
      id,
      name: json.name,
      type: json.type,
      entityType: CustomFieldType.instance(),
      dropdownOptions: json.dropdownOptions,
      formsUsageCounter: json.formsUsageCounter,
      teamspaceId: json.teamspaceId
    };
  }
}
