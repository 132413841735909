import { RobotTriggerType } from '@celum/work/app/core/model/entities/workroom/robot.model';

export enum TranslationTokenType {
  WHEN_TOKEN = 'WHEN_TOKEN',
  THEN_TOKEN = 'THEN_TOKEN'
}

export interface TokenStyle {
  order: number;
  text?: string;
  class?: string;
}

export interface TokenList {
  textTokens: TokenStyle[];
  componentTokens: Map<string, TokenStyle>;
}
export function getTaskCreatedTranslationKeyForContentHub(triggerType: RobotTriggerType): string {
  return triggerType === RobotTriggerType.TASK_CREATED ? 'TASK_FROM_FILES_CREATED' : triggerType;
}
