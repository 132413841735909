<snackbar-group
    [config]="config"
    [items]="clientNotifications$ | async"
    [itemTemplate]="progressGroupItemTemplate"
    [snackbarSize]="'large'"
    (closeAll)="onDismiss()"
    (dismiss)="onDismiss()">
</snackbar-group>

<ng-template #progressGroupItemTemplate let-item="item">
  <snackbar-group-item
      [title]="getSnackbarGroupItemTitle(item) | translate: item.data"
      [icon]="getSnackbarGroupItemIcon(item)"
      [iconBackgroundColor]="'white'">
  </snackbar-group-item>
</ng-template>
