import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { IconConfiguration, SortDirection } from '@celum/common-components';

export class SortInfo {
  constructor(
    public value: string,
    public displayName: string,
    public direction?: SortDirection
  ) {}
}

@Component({
  selector: 'sorter',
  templateUrl: './sorter.component.html',
  styleUrls: ['./sorter.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SorterComponent {
  @Input() public sortField: SortInfo;
  @Input() public sortDirection: SortDirection = SortDirection.DESC;
  @Input() public sortProperties: SortInfo[] = [];

  @Output() public readonly sortChanged = new EventEmitter<SortInfo>();

  @HostBinding('class.sorter') public hostCls = true;

  public sortOrderIcon: IconConfiguration = IconConfiguration.small('order-desc-m');

  public isDescending(): boolean {
    return SortDirection.DESC === this.sortDirection;
  }

  public toggleSortDirection(): void {
    this.sortDirection = this.isDescending() ? SortDirection.ASC : SortDirection.DESC;
    this.emitSortChanged(this.sortField);
  }

  public handleSelection(sortKey: string): void {
    const sortInfo = this.sortProperties.find(prop => prop.value === sortKey);
    this.emitSortChanged(sortInfo);
  }

  public trackByFn(index, item: SortInfo) {
    return item.displayName || index;
  }

  private emitSortChanged(sortInfo: SortInfo): void {
    this.sortChanged.emit(new SortInfo(sortInfo.value, sortInfo.displayName, this.sortDirection));
  }
}
