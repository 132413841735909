import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import {
  CelumDialog,
  CelumDialogConfiguration,
  ColorConstants,
  EmptyPage,
  EmptyPageConfig
} from '@celum/common-components';
import { Folder, FolderSubType } from '@celum/work/app/core/model/entities/folder/folder.model';
import { selectFoldersForCurrentWorkroom } from '@celum/work/app/core/model/entities/folder/folder.selector';
import { UiViewContext } from '@celum/work/app/core/ui-state/ui-state.model';
import { FolderTreePaginationInformation } from '@celum/work/app/files/components/files-tree/files-tree.component';
import * as FilesTreeActions from '@celum/work/app/files/store/files-tree/files-tree.actions';
import { selectFolderTreePaginationInformation } from '@celum/work/app/files/store/files-tree/files-tree.selector';

@Component({
  selector: 'folder-tree-selection-dialog',
  templateUrl: './folder-tree-selection-dialog.component.html',
  styleUrls: ['./folder-tree-selection-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderTreeSelectionDialogComponent implements OnInit, CelumDialog<FolderTreeSelectionDialogConfiguration> {
  public static DIALOG_ID = 'folder-tree-selection-dialog';
  public folders$: Observable<Folder[]>;
  public selectedFolders: Folder[] = [];
  public selectedFolderIds: string[];

  public paginationInformation$: Observable<FolderTreePaginationInformation>;
  public color: string;
  public emptyPageConfig: EmptyPageConfig;

  public selectionTouched = false;

  constructor(
    private dialogRef: MatDialogRef<FolderTreeSelectionDialogComponent>,
    private store: Store<any>
  ) {}

  public get isValid() {
    return this.selectedFolders.length > 0 && this.selectionTouched === true;
  }

  public ngOnInit(): void {
    this.emptyPageConfig = EmptyPage.noActionConfig(
      'no-folders',
      'no-folders',
      'ROBOTS.TASK_MANAGEMENT.TASK_CREATION.SELECT_FOLDERS_DIALOG.NO_FOLDERS_INFO',
      'small',
      120
    );
    this.folders$ = this.store.select(selectFoldersForCurrentWorkroom);
    this.paginationInformation$ = this.store.select(
      selectFolderTreePaginationInformation(UiViewContext.FOLDER_TREE_SELECTION_DIALOG)
    );
    this.handleDefaultSelection();
  }

  public configure(configuration: FolderTreeSelectionDialogConfiguration): void {
    this.color = configuration.color;
    this.updateFolderSelection(configuration.initiallySelectedFolders);
  }

  public selectFolders(): void {
    this.dialogRef.close(this.selectedFolders);
  }

  public handleDefaultSelection() {
    this.folders$
      .pipe(
        take(1),
        filter(folders =>
          folders.every(folder => folder.folderSubType !== FolderSubType.ORDINARY && folder.importId === null)
        )
      )
      .subscribe(folders => {
        this.selectionTouched = true;
        this.updateFolderSelection(folders.filter(folder => folder.folderSubType === FolderSubType.ROOT));
      });
  }

  public loadChildren(params: any): void {
    this.store.dispatch(
      FilesTreeActions.FilesTreeLoadSubfolders({
        offset: 0,
        parentId: params.parentId,
        limit: params.limit,
        treeId: UiViewContext.FOLDER_TREE_SELECTION_DIALOG
      })
    );
  }

  public loadMoreForFolder(params: any): void {
    this.store.dispatch(
      FilesTreeActions.FilesTreeFetchNextBatch({
        parentId: params.parentId,
        offset: params.offset,
        treeId: UiViewContext.FOLDER_TREE_SELECTION_DIALOG
      })
    );
  }

  public onFolderSelectedChanged(folders: Folder[]) {
    this.selectionTouched = true;
    this.updateFolderSelection(folders);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private updateFolderSelection(folders: Folder[]): void {
    this.selectedFolders = folders;
    this.selectedFolderIds = this.selectedFolders.map(({ id }) => id);
  }
}

export class FolderTreeSelectionDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;
  constructor(public initiallySelectedFolders: Folder[]) {
    super('main');
  }
}
