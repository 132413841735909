<ng-container *ngIf="(isEmpty$ | async) === false; else emptyPage">
  <div class="virtual-scroll-table_row--sticky" *ngIf="(items$ | async).length > 0">
    <div class="virtual-scroll-table_row--sticky_item" *ngFor="let item of stickyRowConfig">{{ item | translate }}</div>
  </div>

  <cdk-virtual-scroll-viewport
    [itemSize]="itemSize"
    [minBufferPx]="60"
    [maxBufferPx]="120"
    (scrolledIndexChange)="onRequestNextPage()">
    <div class="virtual-scroll-table_row" *cdkVirtualFor="let item of items$ | async; index as i; trackBy: trackByFn">
      <mat-checkbox
        #checkbox
        class="virtual-scroll-table_row_checkbox"
        *ngIf="selectionEnabled" 
        [checked]="!!(selectedItems$ | async)?.[item.id]" 
        (click)="selectionChanged(checkbox.checked, item)">
      </mat-checkbox>
      
      <div class="virtual-scroll-table_row_content">
        <ng-container [ngTemplateOutlet]="itemTemplateRef" [ngTemplateOutletContext]="{ $implicit: item, index: i }"></ng-container>
      </div>
    </div>

    <loading-mask *ngIf="(loading$ | async) && (items$ | async).length > 0" class="virtual-scroll-table_row-loader"></loading-mask>
  </cdk-virtual-scroll-viewport>
</ng-container>

<loading-mask
  *ngIf="(loading$ | async) && (items$ | async).length === 0"
  class="virtual-scroll-table_fullscreen-loader">
</loading-mask>

<ng-template #emptyPage>
  <empty-page [config]="emptyPageConfig"></empty-page>
</ng-template>
