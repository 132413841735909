import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { flattenObservableArray } from '@celum/core';
import { MembershipStatus } from '@celum/work/app/core/model/entities/member/index';
import { selectMemberByUserIdAndTeamspaceId } from '@celum/work/app/core/model/entities/member/member.selectors';

import { AssignmentAutomatorComponent } from './assignment-automator.component';
import {
  Automator,
  AutomatorType,
  isAutomator,
  RobotActionType,
  Robots,
  TaskAddAssigneesAction
} from '../../../../core/model/entities/workroom/robot.model';
import { RobotChipConfig } from '../../../services/robots-factory';
import { BaseRobotConfigService, RobotNotAppliedConfig } from '../base-robot/base-robot-config.service';

@Injectable()
export class AssignmentAutomatorConfigService extends BaseRobotConfigService {
  constructor(
    public translate: TranslateService,
    private store: Store<any>
  ) {
    super(translate);
  }

  public serviceMatchesRobot(robot: Robots): boolean {
    return robot.type === AutomatorType.TASK_ASSIGNMENT;
  }

  public getRobotChipConfig(robot: Automator, sourceEntity?: any): Observable<Partial<RobotChipConfig>> {
    return of({
      row1: this.translate.instant(`ROBOTS.CHIP.FIRST_ROW.${robot.action.type}`),
      row2: this.translate.instant(`ROBOTS.CHIP.SECOND_ROW.${robot.trigger.type}`),
      editEvent: {
        robot,
        type: AssignmentAutomatorComponent
      }
    });
  }

  public isRobotNotApplied(params: RobotNotAppliedConfig): Observable<boolean> {
    const { robot, invitedPeople, teamspaceId } = params;
    if (isAutomator(robot) && robot.action.type === RobotActionType.TASK_ADD_ASSIGNEES) {
      const personIds = (robot.action as TaskAddAssigneesAction).personIds;
      return flattenObservableArray(
        invitedPeople
          .filter(person => personIds.includes(person.id))
          .map(person => this.store.select(selectMemberByUserIdAndTeamspaceId(person.id, teamspaceId)))
      ).pipe(map(memberships => memberships.every(membership => membership?.status === MembershipStatus.INACTIVE)));
    }

    return of(false);
  }
}
