import { NgxPermissionsService } from 'ngx-permissions';

import { CelumPropertiesProvider } from '@celum/core';

export function replaceProtocol(value: string) {
  return value.replace('http:', document.location.protocol);
}

export function initializer(ngxPermissionsService: NgxPermissionsService) {
  const defaultPermissions = ['permission.allowed'];
  ngxPermissionsService.loadPermissions(defaultPermissions);

  CelumPropertiesProvider.properties.pingAddress = replaceProtocol(CelumPropertiesProvider.properties.pingAddress);
  CelumPropertiesProvider.properties.httpBaseAddress = replaceProtocol(
    CelumPropertiesProvider.properties.httpBaseAddress
  );
  (window as any).Celum.properties.defaultRegionHostAddress = replaceProtocol(
    (window as any).Celum.properties.defaultRegionHostAddress
  );
  (window as any).Celum.properties.hostAddress = replaceProtocol((window as any).Celum.properties.hostAddress);
  (window as any).Celum.properties.webSocketHostAddress = replaceProtocol(
    (window as any).Celum.properties.webSocketHostAddress
  );

  /* eslint-disable */
  return () => {};
}
