import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DueDate } from './due-date.component';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [DueDate],
  imports: [CommonModule, SharedModule, MatDatepickerModule],
  exports: [DueDate]
})
export class DueDateModule {}
