import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { EntityTranslator } from '@celum/work/app/core/model';
import { selectCustomFieldsByIds } from '@celum/work/app/core/model/entities/custom-field/custom-field.selectors';
import { CustomForm, CustomFormType } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { selectPersonEntities } from '@celum/work/app/core/model/entities/person/person.selectors';

export class CustomFormTranslator implements EntityTranslator<CustomForm> {
  public translateToEntity(json: any): CustomForm {
    const id = json.id;
    const fieldsSortDictionary = {};

    if (json.fields) {
      json.fields.forEach(fieldAndSort => {
        fieldsSortDictionary[fieldAndSort.customField.id] = fieldAndSort.sort;
      });
    }

    return {
      id,
      name: json.name,
      teamspaceId: json.teamspaceId,
      createdById: json.createdById,
      createdOn: json.createdOn,
      modifiedDate: json.modifiedDate,
      taskCounter: json.taskCounter,
      fieldsSortDictionary,
      entityType: CustomFormType.instance(),
      fieldIds: json.fieldIds,
      customFields: (store: Store<any>) => store.select(selectCustomFieldsByIds(json.fieldIds)),
      createdBy: (store: Store<any>) =>
        store.select(selectPersonEntities).pipe(map(persons => persons[json.createdById]))
    };
  }
}
