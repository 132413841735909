import { EntityType } from '@celum/core';
import { CommentTranslator } from '@celum/work/app/core/model/entities/comment/comment.translator';
import { ContentItemType } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { ContentItemTranslator } from '@celum/work/app/core/model/entities/content-item/content-item.translator';
import { ContributorTranslator } from '@celum/work/app/core/model/entities/contributor/contributor.translator';
import { CustomFieldType } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { CustomFieldTranslator } from '@celum/work/app/core/model/entities/custom-field/custom-field.translator';
import { CustomFormType } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { CustomFormTranslator } from '@celum/work/app/core/model/entities/custom-form/custom-form.translator';
import { FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { FileTranslator } from '@celum/work/app/core/model/entities/file/file.translator';
import { FileVersionType } from '@celum/work/app/core/model/entities/file-version/file-version.model';
import { FileVersionTranslator } from '@celum/work/app/core/model/entities/file-version/file-version.translator';
import { FolderType } from '@celum/work/app/core/model/entities/folder/folder.model';
import { FolderTranslator } from '@celum/work/app/core/model/entities/folder/folder.translator';
import { ItemLinkType } from '@celum/work/app/core/model/entities/item-link/item-link.model';
import { ItemLinkTranslator } from '@celum/work/app/core/model/entities/item-link/item-link.translator';
import { MemberTranslator } from '@celum/work/app/core/model/entities/member/member.translator';
import { PersonTranslator } from '@celum/work/app/core/model/entities/person/person.translator';
import { PortalType } from '@celum/work/app/core/model/entities/portal/portal.model';
import { PortalTranslator } from '@celum/work/app/core/model/entities/portal/portal.translator';
import { RenditionType } from '@celum/work/app/core/model/entities/rendition/rendition.model';
import { RenditionTranslator } from '@celum/work/app/core/model/entities/rendition/rendition.translator';
import { TaskListTranslator } from '@celum/work/app/core/model/entities/task/task-list.translator';
import { TeamspaceType } from '@celum/work/app/core/model/entities/teamspace/index';
import { TeamspaceTranslator } from '@celum/work/app/core/model/entities/teamspace/teamspace.translator';
import { TemplateType } from '@celum/work/app/core/model/entities/template/template.model';
import { WorkroomGroupType } from '@celum/work/app/core/model/entities/workroom/group/workroom-group.model';
import { WorkroomGroupTranslator } from '@celum/work/app/core/model/entities/workroom/group/workroom-group.translator';
import { WorkroomGroupItemType } from '@celum/work/app/core/model/entities/workroom/group-item/workroom-group-item.model';
import { WorkroomGroupItemTranslator } from '@celum/work/app/core/model/entities/workroom/group-item/workroom-group-item.translator';
import { WorkroomTranslator } from '@celum/work/app/core/model/entities/workroom/workroom.translator';

import { ActivityType } from '../model/entities/activity/activity.model';
import { ActivityTranslator } from '../model/entities/activity/activity.translator';
import { CommentType } from '../model/entities/comment/comment.model';
import { ContributorType } from '../model/entities/contributor/contributor.model';
import { MarkerType } from '../model/entities/marker/marker.model';
import { MarkerTranslator } from '../model/entities/marker/marker.translator';
import { MemberType } from '../model/entities/member/member.model';
import { PersonType } from '../model/entities/person/person.model';
import { TaskListType } from '../model/entities/task/task-list.model';
import { TaskType } from '../model/entities/task/task.model';
import { TaskTranslator } from '../model/entities/task/task.translator';
import { TemplateTranslator } from '../model/entities/template/template.translator';
import { TemplateCategoryType } from '../model/entities/template-category/template-category.model';
import { TemplateCategoryTranslator } from '../model/entities/template-category/template-category.translator';
import { WorkroomType } from '../model/entities/workroom/workroom.model';
import { Entity } from '../model/entity';

export class EntityTranslator {
  public static TYPE_KEY = 'typeKey';

  public translateEntities(jsonEntities: any[]): Entity<any, EntityType>[] {
    return jsonEntities
      .map(json => {
        const entity = this.translateEntity(json);

        if (entity) {
          entity.pendingTransaction = false;
        }

        return entity;
      })
      .filter(Boolean);
  }

  public translateEntity(json: any): Entity<any, EntityType> {
    // eslint-disable-next-line eqeqeq
    if (json == null) {
      console.error(`EntityTranslator: json is null!`);
      return null;
    }

    const translator = TRANSLATORS[json[EntityTranslator.TYPE_KEY]];

    if (translator) {
      return translator.translateToEntity(json);
    } else {
      console.error(
        `EntityTranslator: Found no translator for ${
          json[EntityTranslator.TYPE_KEY]
        }! Did you forget to add it to the list of TRANSLATORS in entity-translator?`
      );
      return null;
    }
  }
}

export const TRANSLATORS = {
  [FileVersionType.TYPE_KEY]: new FileVersionTranslator(),
  [PersonType.TYPE_KEY]: new PersonTranslator(),
  [WorkroomType.TYPE_KEY]: new WorkroomTranslator(),
  [TeamspaceType.TYPE_KEY]: new TeamspaceTranslator(),
  [ContributorType.TYPE_KEY]: new ContributorTranslator(),
  [MemberType.TYPE_KEY]: new MemberTranslator(),
  [TaskListType.TYPE_KEY]: new TaskListTranslator(),
  [TaskType.TYPE_KEY]: new TaskTranslator(),
  [ItemLinkType.TYPE_KEY]: new ItemLinkTranslator(),
  [FileType.TYPE_KEY]: new FileTranslator(),
  [CommentType.TYPE_KEY]: new CommentTranslator(),
  [MarkerType.TYPE_KEY]: new MarkerTranslator(),
  [MarkerType.STAMP_TYPE_KEY]: new MarkerTranslator(),
  [ActivityType.TYPE_KEY]: new ActivityTranslator(),
  [TemplateCategoryType.TYPE_KEY]: new TemplateCategoryTranslator(),
  [TemplateType.TYPE_KEY]: new TemplateTranslator(),
  [FolderType.TYPE_KEY]: new FolderTranslator(),
  [ContentItemType.TYPE_KEY]: new ContentItemTranslator(),
  [RenditionType.TYPE_KEY]: new RenditionTranslator(),
  [WorkroomGroupType.TYPE_KEY]: new WorkroomGroupTranslator(),
  [WorkroomGroupItemType.TYPE_KEY]: new WorkroomGroupItemTranslator(),
  [CustomFormType.TYPE_KEY]: new CustomFormTranslator(),
  [CustomFieldType.TYPE_KEY]: new CustomFieldTranslator(),
  [PortalType.TYPE_KEY]: new PortalTranslator()
};
