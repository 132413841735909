import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AutomatorService, AutomatorServiceArgs } from './automator.service';
import { getTaskListAutomatorsOfType } from './robots-util';
import { ContentHubBuildNumberStrategyResolverService } from '../../content-hub/services/content-hub-build-number-strategy-resolver.service';
import {
  AutomatorSubType,
  AutomatorType,
  ContentHubAutomatorTypes,
  RobotActionType,
  RobotTriggerType
} from '../../core/model/entities/workroom/robot.model';

@Injectable({ providedIn: 'root' })
export class ContentHubAutomatorService extends AutomatorService {
  constructor(private chStrategyResolver: ContentHubBuildNumberStrategyResolverService) {
    super();
  }

  public getAllTriggers(_: AutomatorServiceArgs): Observable<RobotTriggerType[]> {
    return of([
      RobotTriggerType.TASK_ATTACHMENT_ADDED,
      RobotTriggerType.TASK_CREATED,
      RobotTriggerType.TASK_MOVED,
      RobotTriggerType.WORKROOM_FINISHED
    ]);
  }

  public getAllActions(args: AutomatorServiceArgs): Observable<RobotActionType[]> {
    return this.chStrategyResolver.resolve(args.repositoryId).pipe(
      take(1),
      map(strategy => {
        const allSupportedActions = strategy.getSupportedContentHubAction();

        if (args.robotSubType === AutomatorSubType.CONTENT_HUB_ASSETS) {
          return allSupportedActions[ContentHubAutomatorTypes.CONTENT_HUB_ASSETS] ?? [];
        }
        return allSupportedActions[ContentHubAutomatorTypes.WORKROOM_FILES] ?? [];
      })
    );
  }

  public getPossibleTriggers(args: AutomatorServiceArgs): Observable<RobotTriggerType[]> {
    const possibleTriggers = super.getPossibleTriggers(args);

    const triggerIntoleranceMap = {
      [RobotTriggerType.TASK_ATTACHMENT_ADDED]: [RobotTriggerType.WORKROOM_FINISHED],
      [RobotTriggerType.TASK_CREATED]: [RobotTriggerType.WORKROOM_FINISHED],
      [RobotTriggerType.TASK_MOVED]: [RobotTriggerType.WORKROOM_FINISHED],
      [RobotTriggerType.WORKROOM_FINISHED]: [
        RobotTriggerType.TASK_ATTACHMENT_ADDED,
        RobotTriggerType.TASK_CREATED,
        RobotTriggerType.TASK_MOVED
      ]
    };

    let usedTriggers = getTaskListAutomatorsOfType(args.workroomConfig, args.sourceId, AutomatorType.CONTENT_HUB)
      .filter(automator => automator.subType === args.robotSubType)
      .map(({ trigger }) => trigger.type);

    if (usedTriggers.length > 0 && !!args.initialRobotState) {
      usedTriggers = usedTriggers.filter(trigger => trigger !== args.initialRobotState?.trigger.type);
    }

    const taskCreationRobots = getTaskListAutomatorsOfType(
      args.workroomConfig,
      args.sourceId,
      AutomatorType.TASK_CREATION
    );

    const isUnused = trigger => !usedTriggers.includes(trigger);
    const isTolerant = trigger =>
      usedTriggers.every(usedTrigger => !triggerIntoleranceMap[usedTrigger]?.includes(trigger));
    return possibleTriggers.pipe(
      map(triggers =>
        triggers.filter(possibleTrigger => {
          if (taskCreationRobots.length > 0 && possibleTrigger === RobotTriggerType.TASK_CREATED) {
            return false;
          }

          return isUnused(possibleTrigger) && isTolerant(possibleTrigger);
        })
      )
    );
  }

  public getPossibleActions(args: AutomatorServiceArgs): Observable<RobotActionType[]> {
    const { workroomConfig, sourceId, initialRobotState, robotSubType } = args;
    const possibleActions = super.getPossibleActions(args);
    const actionIntoleranceMap = {
      [RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION]: [
        RobotActionType.CONTENT_HUB_EXPORT_TO_IMPORTED_ASSET,
        RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION
      ],
      [RobotActionType.CONTENT_HUB_EXPORT_TO_IMPORTED_ASSET]: [
        RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION,
        RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION
      ],
      [RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION]: [
        RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION,
        RobotActionType.CONTENT_HUB_EXPORT_TO_IMPORTED_ASSET
      ]
    };
    const usedActions = getTaskListAutomatorsOfType(workroomConfig, sourceId, AutomatorType.CONTENT_HUB)
      .filter(automator => automator.subType === robotSubType)
      .map(({ action }) => action.type);

    if (usedActions.length === 1 && !!initialRobotState) {
      return possibleActions;
    }

    const isTolerant = (action: RobotActionType) =>
      usedActions.every(usedAction => !actionIntoleranceMap[usedAction]?.includes(action));

    return possibleActions.pipe(map(actions => actions.filter(possibleAction => isTolerant(possibleAction))));
  }

  public getAutomatorPreselection(
    args: AutomatorServiceArgs
  ): Observable<{ triggerType: RobotTriggerType; actionType: RobotActionType }> {
    return combineLatest([
      this.getAllTriggers(args),
      this.getAllActions(args),
      this.getPossibleTriggers(args),
      this.getPossibleActions(args)
    ]).pipe(
      map(([allTriggers, allActions, possibleTriggers, possibleActions]) => {
        const preferredAction = RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION;
        const preselectedAction = possibleActions.includes(preferredAction) ? preferredAction : possibleActions[0];

        if (allTriggers.length === possibleTriggers.length && allActions.length === possibleActions.length) {
          return {
            triggerType: possibleTriggers.includes(RobotTriggerType.TASK_MOVED)
              ? RobotTriggerType.TASK_MOVED
              : possibleTriggers[0],
            actionType: preselectedAction
          };
        } else {
          return {
            triggerType: possibleTriggers[0],
            actionType: preselectedAction
          };
        }
      })
    );
  }
}
