import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslationUtilService {
  constructor(private translateService: TranslateService) {}

  private static getTranslationKey(prefix: string, suffix: string | number): string {
    return `${prefix}${suffix}`;
  }

  public getTranslation(prefix: string, suffix: string): string {
    return this.translateService.instant(TranslationUtilService.getTranslationKey(prefix, suffix));
  }

  public wasTranslated(translation: string, translationKey: string): boolean {
    return !translation.includes(translationKey);
  }

  public getTranslationIfExists(translationKey: string): string | null {
    const translation = this.translateService.instant(translationKey);
    const translationExists = this.wasTranslated(translation, translationKey);
    return translationExists ? translation : null;
  }
}
