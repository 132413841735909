import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PermissionService } from '@celum/work/app/core/api/permission';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import { TeamspacesLoaded } from '@celum/work/app/core/model/entities/teamspace';

import { LoggedInPersonLoadMembership } from './teamspace-member.actions';
import { TeamspaceMemberService } from './teamspace-member.service';

@Injectable()
export class TeamspaceMemberEffects {
  public loadMyMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInPersonLoadMembership),
      switchMap(() =>
        this.teamspaceMemberService.getMyMembership().pipe(
          switchMap(membership =>
            this.permissionService
              .loadTeamspacePermissions([membership.teamspaceId])
              .pipe(map(() => TeamspacesLoaded()))
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private teamspaceMemberService: TeamspaceMemberService,
    private permissionService: PermissionService,
    private failureHandler: FailureHandler
  ) {}
}
