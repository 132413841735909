import { createAction, props } from '@ngrx/store';

import { MarkerWrapper } from './marker.service';
import { Marker, MarkerSubType } from '../../model/entities/marker/marker.model';

export const MarkerLoadMarkers = createAction(
  '[Marker] Load Markers',
  props<{ fileVersionId: string; markerType: MarkerSubType }>()
);
export const MarkerLoadAllMarkers = createAction('[Marker] Load All Markers', props<{ fileVersionId: string }>());
export const MarkerLoadMarkersSucceeded = createAction(
  '[Marker] Load Markers Succeeded',
  props<{ fileVersionId: string; markers: MarkerWrapper[] }>()
);
export const MarkerLoadMarkersFailed = createAction('[Marker] Load Markers Failed', props<{ fileVersionId: string }>());

export const MarkerCreateStamp = createAction(
  '[Marker] Create Stamp',
  props<{ fileId: string; fileVersionId: string; tmpStamp: Marker }>()
);
export const MarkerCreateStampSucceeded = createAction(
  '[Marker] Create Stamp Succeeded',
  props<{ fileVersionId: string; tmpStamp: Marker; stamp: MarkerWrapper }>()
);
export const MarkerCreateStampFailed = createAction(
  '[Marker] Create Stamp Failed',
  props<{ fileVersionId: string; tmpStamp: Marker }>()
);

export const MarkerUpdateStamp = createAction(
  '[Marker] Update Stamp',
  props<{ fileVersionId: string; stamp: Marker }>()
);
export const MarkerUpdateStampSucceeded = createAction(
  '[Marker] Update Stamp Succeeded',
  props<{ oldStamp: Marker; stamp: Marker }>()
);
export const MarkerUpdateStampFailed = createAction('[Marker] Update Stamp Failed', props<{ oldStamp: Marker }>());

export const MarkerDeleteMarker = createAction(
  '[Marker] Delete Marker',
  props<{ fileVersionId: string; marker: Marker }>()
);
export const MarkerDeleteMarkerSucceeded = createAction(
  '[Marker] Delete Marker Succeeded',
  props<{ marker: Marker }>()
);
export const MarkerDeleteMarkerFailed = createAction('[Marker] Delete Marker Failed', props<{ marker: Marker }>());
