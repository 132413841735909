<div class="message-box_content">
  <celum-icon class="message-box_icon" [configuration]="internalIcon"></celum-icon>

  <div class="message-box_text">
    <strong>{{ headline | translate }} </strong>
    <span>{{ text | translate }}</span>
  </div>

  <a class="message-box_action {{'message-box_action--' + type}}" (click)="invokeAction.emit()">{{ actionText | translate | uppercase}}</a>
</div>
