import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, of } from 'rxjs';
import { filter, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { DataContext, DataUtil, Operation } from '@celum/core';
import { CelumDialogOpener } from '@celum/internal-components';
import { Permission } from '@celum/work/app/core/api/permission';
import { WorkroomContributorService } from '@celum/work/app/core/api/workroom-contributor';
import { WorkroomContributorsInviteSuccessful } from '@celum/work/app/core/api/workroom-contributor/workroom-contributor.actions';
import { ErrorKey } from '@celum/work/app/core/error/error-key';
import { NotificationService } from '@celum/work/app/core/notifications/notification.service';
import {
  selectCurrentWorkroom,
  selectCurrentWorkroomContributingPersons,
  selectCurrentWorkroomId
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import {
  InviteUserDialog,
  InviteUserDialogConfiguration,
  InviteUserDialogExecuteFn
} from '@celum/work/app/person/invite/components/invite-user-dialog/invite-user-dialog.component';
import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

@Injectable({
  providedIn: 'root'
})
export class InviteOperation implements Operation {
  private static readonly EMPTY_ERROR_MESSAGE = 'empty.error.message';
  private static readonly USER_SHOWN_ERRORS = [
    ErrorKey.USER_INVITATION_PERIOD_LIMIT_REACHED,
    ErrorKey.USER_INVITATION_USER_LIMIT_REACHED
  ];

  constructor(
    private dialogOpener: CelumDialogOpener,
    private permissionsService: NgxPermissionsService,
    private translate: TranslateService,
    private contributorService: WorkroomContributorService,
    private notificationService: NotificationService,
    private store: Store
  ) {}

  public isVisible(): Observable<boolean> {
    return from(this.permissionsService.hasPermission(Permission.WORKROOM_INVITE));
  }

  public hasPriority(data: DataContext): Observable<boolean> {
    return of(DataUtil.isEmpty(data.getSelection()));
  }

  public execute(): void {
    this.store
      .select(selectCurrentWorkroom)
      .pipe(
        take(1),
        withLatestFrom(this.store.pipe(select(selectCurrentWorkroomContributingPersons))),
        switchMap(([workroom, contributors]) => {
          const executeFn: InviteUserDialogExecuteFn = people =>
            this.contributorService.invitePerson(
              workroom.id,
              people.map(person => ({
                personId: null,
                invitationEmail: person.getInvitationEmail(),
                roles: [person.getRole()]
              }))
            );
          const emailsToExclude = contributors.map(({ email }) => email);
          const invitationDialogConfig = InviteUserDialogConfiguration.forPeoplePage(
            workroom.teamspaceId,
            emailsToExclude,
            executeFn
          );
          return from(this.dialogOpener.showDialog('personDialog', InviteUserDialog, invitationDialogConfig));
        }),
        filter(res => !!res),
        withLatestFrom(this.store.select(selectCurrentWorkroomId))
      )
      .subscribe(([invitedContributors, wrId]) => {
        this.store.dispatch(
          WorkroomContributorsInviteSuccessful({ workroomId: wrId, contributors: invitedContributors })
        );
      });
  }

  public getKey(): string {
    return 'add-people';
  }

  public getMessageKey(): string {
    return 'PEOPLE.OPERATIONS.ADD_PERSON';
  }

  public getIcon(): string {
    return 'user-l';
  }

  public handleFailedInvitations(errorMessageKeys: string[]) {
    const failedInvitationsByErrorMessageKey = errorMessageKeys.reduce((res, currentValue) => {
      const errorMessageKey = notNullOrUndefined(currentValue) ? currentValue : InviteOperation.EMPTY_ERROR_MESSAGE;
      (res[errorMessageKey] = res[errorMessageKey] || []).push(currentValue);
      return res;
    }, {});

    for (const errorKey of Object.keys(failedInvitationsByErrorMessageKey)) {
      let errorMessage = this.translate.instant('PEOPLE.INVITE.FAILED.COMMON', {
        elements: failedInvitationsByErrorMessageKey[errorKey].length
      });

      if (InviteOperation.USER_SHOWN_ERRORS.includes(errorKey as ErrorKey)) {
        errorMessage += this.translate.instant('PEOPLE.INVITE.FAILED.' + errorKey);
      }

      this.notificationService.error(errorMessage);
    }
  }
}
