import { AssetDTO } from '@celum/work/app/content-hub/model/content-hub.model';
import { SubsequentActionType } from '@celum/work/app/core/model/subsequent-action.model';

import { Workroom } from '../../core/model/entities/workroom';
import { ProgressTaskParams } from '../../progress-task/store/progress-task.model';

export interface ImportParams {
  parentId?: string;
  fileId?: string;
  asset?: AssetDTO;
  workroom?: Workroom;
  action?: SubsequentActionType;
}

export class ImportProgressTaskParams implements ImportParams, ProgressTaskParams {
  public parentId?: string;
  public fileId?: string;
  public asset?: AssetDTO;
  public workroom: Workroom;
  public action?: SubsequentActionType;

  constructor(importParams: ImportParams) {
    Object.assign(this, importParams);
  }

  public getTitle(): string {
    return this.asset?.name;
  }
}
