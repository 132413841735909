<mat-chip-listbox
    class="custom-field-card_chip-list_container"
    [formControl]="chipsControl"
    [attr.data-cy]="'custom-field-card_chip_list'"
    [selectable]='false'>
  <mat-chip-option
      *ngFor="let chip of chipsControl.value"
      [removable]="true">
      {{ chip.name }}
    <celum-icon
        *ngIf="chipsControl.enabled"
        [configuration]="cancelIcon"
        [clickable]="true"
        matChipRemove
        (click)="removeChip(chip)"></celum-icon>
  </mat-chip-option>
</mat-chip-listbox>
