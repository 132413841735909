import { createSelector } from '@ngrx/store';

import { selectStorageLimitReachedByCurrentWorkroom } from '../../core/model/entities/teamspace/teamspace-storage.selectors';
import {
  selectCurrentWorkroom,
  selectCurrentWorkroomRepository
} from '../../pages/workroom/store/workroom-wrapper.selectors';
import { selectImportIntentRepositoryId } from '../../pages/workroom-creator/store/workroom-creator.selectors';

export const selectWorkroomConfiguredForContentHub = createSelector(
  selectCurrentWorkroomRepository,
  currentWorkroomRepository => !!currentWorkroomRepository
);

export const selectCanImport = createSelector(
  selectWorkroomConfiguredForContentHub,
  selectStorageLimitReachedByCurrentWorkroom,
  (teamspaceConfiguredForCH, limitReachedForCurrentWR) => !!teamspaceConfiguredForCH && !limitReachedForCurrentWR
);

export const selectContentHubBuildNumber = createSelector(
  selectCurrentWorkroomRepository,
  currentWorkroomRepository => currentWorkroomRepository?.buildNumber
);

export const selectContentHubRepositoryId = createSelector(
  selectCurrentWorkroom,
  selectImportIntentRepositoryId,
  (workroom, importIntentRepositoryId) => workroom?.contentHubRepositoryId || importIntentRepositoryId
);
