import { createSelector } from '@ngrx/store';

import { selectRouterQueryParam } from '@celum/work/app/core/router/router.selectors';

import { fileAdapter } from './file.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = fileAdapter.getSelectors();

export const selectFiles = createSelector(selectEntitiesState, (state: EntitiesState) => state.files);

export const selectAllFiles = createSelector(selectFiles, selectAll);
export const selectFileEntities = createSelector(selectFiles, selectEntities);

export const selectFileById = (id: string) => createSelector(selectFileEntities, fileDictionary => fileDictionary[id]);

export const selectCurrentFileId = createSelector(selectRouterQueryParam('assetId'), assetId => assetId);

export const selectFilesWithLinkedPortalAssets = createSelector(selectAllFiles, files =>
  files.filter(file => file.hasLinkedPortalAssets)
);
