import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { isTruthy } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

@Component({
  selector: 'text-truncator',
  templateUrl: './text-truncator.component.html',
  styleUrls: ['./text-truncator.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatTooltipModule]
})
export class TextTruncatorComponent
  extends ReactiveComponent
  implements OnChanges, OnInit, AfterViewInit, AfterViewChecked
{
  @Input() public text: string;
  @Input() public updatedNeededEvent: Subject<boolean>;
  @Input() public updateOnAfterViewChecked: boolean;
  @Input() public tooltipClass = 'text-truncator_tooltip';

  @ViewChild('textContainer') public textContainer: ElementRef;

  @HostBinding('class') public hostClass = 'text-truncator';

  public isOverflowing: boolean;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.text && this.isOverflowing !== undefined) {
      setTimeout(() => this.updateTextTruncated());
    }
  }

  public ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateTextTruncated();
      });

    this.updatedNeededEvent?.pipe(takeUntil(this.unsubscribe$), isTruthy()).subscribe(() => this.updateTextTruncated());
  }

  public ngAfterViewInit(): void {
    if (!this.updateOnAfterViewChecked) {
      this.updateTextTruncated();
    }
  }

  public ngAfterViewChecked(): void {
    if (this.updateOnAfterViewChecked && this.isOverflowing === undefined) {
      setTimeout(() => this.updateTextTruncated());
    }
  }

  private updateTextTruncated(): void {
    const textElement = this.textContainer.nativeElement;
    if (this.updateOnAfterViewChecked && !(textElement.scrollWidth > 0 || textElement.clientWidth > 0)) {
      return;
    }
    this.isOverflowing = textElement.scrollWidth > textElement.clientWidth;
    this.cd.detectChanges();
  }
}
