import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import VersionInfo from '../../../../package.json';

@Injectable({ providedIn: 'root' })
export class VersionService {
  public webVersion: string = VersionInfo.version;
  private appVersion: Subject<string> = new BehaviorSubject<string>('');

  public setAppVersion(appVersion: string) {
    this.appVersion.next(appVersion);
  }

  public getWebVersion(): string {
    return this.webVersion;
  }

  public getAppVersion(): Observable<string> {
    return this.appVersion;
  }
}
