import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';

import { SortDirection } from '@celum/common-components';
import { ReactiveService } from '@celum/core';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { CustomField } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { selectCustomFieldEntities } from '@celum/work/app/core/model/entities/custom-field/custom-field.selectors';

import { SearchAndSelectCustomFieldsStore } from './search-and-select-custom-fields.store';
import { SearchAndSelectGenericService } from '../search-and-select-generic/search-and-select-generic.base';
import { SelectElement } from '../search-and-select.component';

@Injectable()
export class SearchAndSelectCustomFieldsService
  extends ReactiveService
  implements SearchAndSelectGenericService<CustomField>
{
  public readonly displayedValue: string;
  public readonly noResultsKey: string;
  public readonly hasMoreKeySingular: string;
  public readonly hasMoreKeyPlural: string;
  public readonly searchFieldLabelKey: string;
  public readonly placeholder: string;
  public readonly itemsToLoad: number;

  public readonly items$: Observable<CustomField[]>;
  public readonly loading$: Observable<boolean>;
  public readonly hasMore$: Observable<boolean>;
  public readonly hasMoreAmount$: Observable<number>;

  public readonly elementSelected = new EventEmitter<SelectElement>();

  public readonly sorting: Sorting;

  constructor(
    public customFieldsStore: SearchAndSelectCustomFieldsStore,
    private store: Store<any>
  ) {
    super();

    this.items$ = combineLatest([
      this.store.select(selectCustomFieldEntities),
      this.customFieldsStore.customFieldIds$
    ]).pipe(map(([customFields, customFieldIds]) => customFieldIds.map(id => customFields[id])));

    this.loading$ = this.customFieldsStore.loading$;
    this.hasMore$ = this.customFieldsStore.hasMore$;
    this.hasMoreAmount$ = this.customFieldsStore.hasMoreAmount$;

    this.displayedValue = 'name';
    this.noResultsKey = 'SEARCH_AND_SELECT_CUSTOM_FIELD.NO_RESULTS';
    this.hasMoreKeySingular = 'SEARCH_AND_SELECT_CUSTOM_FIELD.HAS_MORE.SINGULAR';
    this.hasMoreKeyPlural = 'SEARCH_AND_SELECT_CUSTOM_FIELD.HAS_MORE.PLURAL';
    this.placeholder = 'SEARCH_AND_SELECT_CUSTOM_FIELD.SEARCH';
    this.itemsToLoad = 200;
    this.sorting = Sorting.of('name', SortDirection.ASC);
  }

  public loadEntities(name?: string): void {
    this.customFieldsStore.loadCustomFields$({
      paging: Paging.of(0, this.itemsToLoad),
      sorting: this.sorting,
      offset: 0,
      name
    });
  }
}
