import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ReactiveService implements OnDestroy {
  private unsubscribeSubj: Subject<void> = new Subject<void>();

  protected get unsubscribe$(): Observable<void> {
    return this.unsubscribeSubj.asObservable();
  }

  public ngOnDestroy(): void {
    this.unsubscribeSubj.next();
    this.unsubscribeSubj.complete();
  }
}
