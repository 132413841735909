import { Marker, MarkerType } from './marker.model';
import { EntityTranslator } from '../../entity';

export class MarkerTranslator implements EntityTranslator<Marker> {
  public translateToEntity(json: any): Marker {
    const id = json.id;

    return {
      id,
      creator: json.creator,
      direction: json.direction,
      duration: json.duration,
      startTime: json.startTime,
      index: json.index,
      label: json.label,
      objectType: json.objectType,
      serverId: json.serverId,
      shape: json.shape,
      viewId: json.viewId,
      entityType: MarkerType.instance()
    };
  }
}
