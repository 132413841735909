import { ModuleWithProviders, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { de, enUS, fr } from 'date-fns/locale';

import { DateFormats, DateUtil } from '@celum/work/app/shared/util';
import { CustomDateFnsAdapter } from '@celum/work/app/shared/util/custom-date-fns-adapter';

const isFrenchEnabled = (window as any).Celum.properties.features.frenchLanguage;

const localeMap = {
  de: de,
  en: enUS,
  fr: isFrenchEnabled ? fr : undefined
};

@NgModule({})
export class DateModule {
  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>
  ) {
    this.setLocaleBasedOnLang(this.translateService.getDefaultLang());

    this.translateService.onLangChange.subscribe(event => {
      this.setLocaleBasedOnLang(event.lang);
    });
  }

  public static forRoot(): ModuleWithProviders<DateModule> {
    return {
      ngModule: DateModule,
      providers: [
        {
          provide: DateAdapter,
          useClass: CustomDateFnsAdapter
        },
        {
          provide: MAT_DATE_FORMATS,
          deps: [DateUtil, TranslateService],
          useFactory: (dateUtil: DateUtil) => {
            return {
              parse: {
                dateInput: dateUtil.getFormatString(DateFormats.LONG)
              },
              display: {
                dateInput: dateUtil.getFormatString(DateFormats.LONG),
                monthYearLabel: dateUtil.getFormatString(DateFormats.MONTH_YEAR),
                dateA11yLabel: dateUtil.getFormatString(DateFormats.LONG),
                monthYearA11yLabel: dateUtil.getFormatString(DateFormats.MONTH_YEAR)
              }
            };
          }
        },
        {
          provide: MAT_DATE_LOCALE,
          deps: [TranslateService],
          useFactory: (translateService: TranslateService) => {
            return localeMap[translateService.currentLang] || enUS;
          }
        }
      ]
    };
  }

  private setLocaleBasedOnLang(lang: string): void {
    const locale = localeMap[lang] || enUS;
    this.dateAdapter.setLocale(locale);
  }
}
