<div class="inline-textarea_wrapper" [class.inline-textarea_wrapper--editing]="editing || !toggleEditMode">
  <div *ngIf="!editing && toggleEditMode" class="inline-textarea_text" [class.inline-textarea_text--placeholder]="!value">
    {{ value || (placeholder | translate) }}
    <celum-icon [configuration]="editIcon"></celum-icon>
  </div>
  <textarea
    #textArea
    *ngIf="editing || !toggleEditMode"
    class="inline-textarea_textarea"
    [attr.maxlength]="maxLength > 0 ? maxLength : null"
    [placeholder]="placeholder | translate"
    [ngModel]="value"
    (keydown.enter)="onSave($event)"
    (keydown.esc)="onCancel($event)">
  </textarea>
</div>
<div *ngIf="editing || !toggleEditMode" class="inline-textarea_footer">
  <button
      class="inline-textarea_button"
      mat-button
      celum-square-button
      [matTooltip]="'COMMON.CANCEL' | translate"
      (click)="onCancel($event)">
    <celum-icon data-action-id="cancel" [configuration]="cancelIcon"></celum-icon>
  </button>
  <button
      class="inline-textarea_button"
      mat-button
      celum-square-button
      [matTooltip]="'COMMON.SAVE' | translate"
      (click)="onSave($event)">
    <celum-icon data-action-id="save" [configuration]="okIcon"></celum-icon>
  </button>
</div>
