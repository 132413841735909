<fullscreen-dialog [title]="'TEAMSPACE_MANAGEMENT.HEADLINE'" [light]="true" [loading]="false">
  <stepper #stepper [stepsConfiguration]="stepsConfiguration$ | async" [collapseButtonTooltipConfig]="collapseButtonTooltipConfig">
    <cdk-step optional *ngIf="hasWorkroomsFinishPermission$ | async" >
      <workrooms-tab cdkFocusInitial *ngIf="stepper.selectedIndex === 0" [teamspace]="teamspace$ | async"></workrooms-tab>
    </cdk-step>
    <cdk-step optional *ngIf="hasFormsEnabled">
      <forms-management
          *ngIf="stepper.selectedIndex === 1 || (hasWorkroomsFinishPermission$ | async) === false"
          [teamspace]="teamspace$ | async"
          [hasWorkroomsFinishPermission]="hasWorkroomsFinishPermission$ | async">
      </forms-management>
    </cdk-step>
  </stepper>
</fullscreen-dialog>