import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CustomFormUpsertMany } from '@celum/work/app/core/model/entities/custom-form/custom-form.actions';

import { CustomForm, CustomFormsState } from './custom-form.model';

export const customFormAdapter: EntityAdapter<CustomForm> = createEntityAdapter<CustomForm>();
export const initialState: CustomFormsState = customFormAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(CustomFormUpsertMany, (state: CustomFormsState, { customForms }) =>
    customFormAdapter.upsertMany(customForms, state)
  )
);

export function customFormReducer(state: CustomFormsState = initialState, action: Action): CustomFormsState {
  return reducer(state, action);
}
