import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedModule } from '@celum/work/app/shared';

import { WorkroomContextMenuComponent } from './workroom-context-menu.component';

@NgModule({
  declarations: [WorkroomContextMenuComponent],
  exports: [WorkroomContextMenuComponent],
  imports: [SharedModule, MatMenuModule, MatTooltipModule]
})
export class WorkroomContextMenuModule {}
