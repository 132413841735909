import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { AuthService, ServiceAccessTokenProvider } from '@celum/authentication';
import { BackendProviderService, BackendService } from '@celum/work/app/core/auth/backend-provider.service';
import { TenantService } from '@celum/work/app/core/auth/tenant.service';
import { selectCurrentWorkroomIdParam, selectWorkroomById } from '@celum/work/app/core/model/entities/workroom';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';

import { selectTenant } from '../ui-state/ui-state.selectors';

type Headers = { [name: string]: string | string[] };

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public static SKIP_AUTHORIZATION_KEY = 'skipAuthorization';

  private readonly headerResolvers: ((req: HttpRequest<any>, service: BackendService) => Observable<Headers>)[] = [
    this.b2cTokenResolver.bind(this),
    this.librariesTokenResolver.bind(this),
    this.tenantHeaderResolver.bind(this),
    this.experienceTokenResolver.bind(this)
  ];

  // when you inject something, add it to provider `deps` in module definition
  constructor(
    private tenantService: TenantService,
    private store: Store<any>,
    private authService: AuthService,
    private serviceAccessTokenProviderService: ServiceAccessTokenProvider
  ) {}

  public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.cloneHttpRequest(httpRequest).pipe(
      take(1),
      switchMap(req => next.handle(req))
    );
  }

  private b2cTokenResolver(req: HttpRequest<any>, service: BackendService) {
    if (!service.requiresB2CAuthorization || req.headers.get(AuthInterceptor.SKIP_AUTHORIZATION_KEY)) {
      return of({});
    }

    return from(this.authService.getAuthResult()).pipe(map(result => ({ Authorization: `Bearer ${result.idToken}` })));
  }

  private librariesTokenResolver(req: HttpRequest<any>, service: BackendService) {
    if (!service.requiresLibrariesAuthorization || req.headers.get(AuthInterceptor.SKIP_AUTHORIZATION_KEY)) {
      return of({});
    }

    return this.store.select(selectCurrentWorkroomIdParam).pipe(
      switchMap(workroomId => this.store.select(selectWorkroomById(workroomId))),
      withLatestFrom(this.store.select(selectTenant)),
      switchMap(([workroom, tenant]) =>
        workroom?.slibResourceToken
          ? of(workroom.slibResourceToken)
          : this.serviceAccessTokenProviderService.getServiceAccessToken({
              clientId: 'slib',
              orgId: tenant
            })
      ),
      map(token => ({ Authorization: `Bearer ${token}` }))
    );
  }

  private experienceTokenResolver(req: HttpRequest<any>, service: BackendService) {
    if (!service.requiresExperienceAuthorization || req.headers.get(AuthInterceptor.SKIP_AUTHORIZATION_KEY)) {
      return of({});
    }

    return combineLatest([this.store.select(selectTenant), this.store.select(selectCurrentWorkroom)]).pipe(
      switchMap(([orgId, workroom]) =>
        combineLatest([
          // due to the current licensing model, EXP needs the B2C token as Authorization header
          this.authService.getAuthResult(),
          // and the SLIB token as well due to certain Experience endpoints that need to access SLIB
          workroom?.slibResourceToken
            ? of(workroom?.slibResourceToken)
            : this.serviceAccessTokenProviderService.getServiceAccessToken({ clientId: 'slib', orgId })
        ])
      ),
      map(tokens => ({
        Authorization: `Bearer ${tokens[0].idToken}`,
        'X-Source-Library-Authorization-Token': tokens[1]
      }))
    );
  }

  private tenantHeaderResolver(_req: HttpRequest<any>, service: BackendService) {
    if (!service.requiresTenant) {
      return of({});
    }
    return this.tenantService.resolveCurrentOrDefault().pipe(map(tenant => ({ 'X-Tenant': tenant })));
  }

  private cloneHttpRequest(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    if (!req.url.startsWith('http')) {
      return of(req);
    }

    const service = BackendProviderService.services.find(serviceItem =>
      serviceItem.urls.map(url => url.host).includes(new URL(req.url).host)
    );
    if (!service) {
      return of(req);
    }

    return combineLatest(this.headerResolvers.map(resolver => resolver(req, service))).pipe(
      take(1),
      map(headers => headers.reduce((acc, val) => ({ ...acc, ...val }))),
      map(headers => {
        const request = req.clone({ setHeaders: headers });

        return request.clone({ headers: request.headers.delete(AuthInterceptor.SKIP_AUTHORIZATION_KEY) });
      })
    );
  }
}
