import { createAction, props } from '@ngrx/store';

import { Folder, FolderPropertiesToUpdate } from './folder.model';

export const FolderUpsertOne = createAction(
  '[Folder] Upsert One',
  props<{ folder: Folder; propertiesToUpdate?: string[] }>()
);

export const FolderUpsertMany = createAction(
  '[Folder] Upsert Many',
  props<{ folders: Folder[]; propertiesToUpdate?: string[] }>()
);

export const FolderDeleteOne = createAction('[Folder] Delete One', props<{ id: string }>());

export const FolderDeleteMany = createAction('[Folder] Delete Many', props<{ ids: string[] }>());

export const FolderCreate = createAction('[Folder] Create folder', props<{ folder: Folder }>());

export const FolderCreateSucceeded = createAction('[Folder] Create folder succeeded', props<{ folder: Folder }>());

export const FolderCreateFailed = createAction('[Folder] Create folder failed', props<{ folder: Folder }>());

export const FolderUpdate = createAction(
  '[FOLDER] Update',
  props<{ folder: Folder; propertiesToUpdate: FolderPropertiesToUpdate }>()
);

export const FolderUpdateSucceeded = createAction(
  '[FOLDER] Update succeeded',
  props<{ folder: Folder; updatedProperties: FolderPropertiesToUpdate }>()
);

export const FolderUpdateFailed = createAction('[FOLDER] Update failed', props<{ folder: Folder }>());

export const ImportCollectionFolderCreated = createAction(
  '[FOLDER] Import collection folder created',
  props<{ parentId: string }>()
);
