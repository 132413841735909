import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { filter, map, mergeMap, retryWhen, switchMap, take, tap } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';

import { CommunicationException } from './communication-exception';
import { AbstractWebsocketConnectionService } from '../websocket/abstract-websocket-connection.service';

@Injectable({ providedIn: 'root' })
export class RetryInterceptor implements HttpInterceptor {
  private readonly scalingDuration: number;
  private readonly retries: number;

  constructor(private websocketConnectionService: AbstractWebsocketConnectionService) {
    this.scalingDuration = CelumPropertiesProvider.properties.queryRetryDelay;
    this.retries = CelumPropertiesProvider.properties.queryRetries;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // retry is only done for GET requests --> TODO do this for all requests? Can the server handle this?
    if (req.method === 'GET' && !req.url.includes('ping')) {
      return next.handle(req).pipe(
        retryWhen(
          retryStrategy(this.websocketConnectionService.isConnected$, req.url, {
            scalingDuration: this.scalingDuration,
            maxRetryAttempts: this.retries
          })
        )
      );
    } else {
      return next.handle(req);
    }
  }
}

export const COMM_EXCEPTION = 'WORK_ERROR.COMMUNICATION';

export const retryStrategy =
  (
    isConnected$: Observable<boolean>,
    url: string,
    { maxRetryAttempts = 3, scalingDuration = 500 }: { maxRetryAttempts?: number; scalingDuration?: number } = {}
  ) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;

        // We already have interceptor for permission handling so just propagating error
        if (
          error.status === 403 ||
          error.status === 409 ||
          (error instanceof HttpErrorResponse && error.error?.errorKey)
        ) {
          return throwError(error);
        }

        // no retry for status codes below 500 or if maximum number of retries have been met
        // (exclude 0 because this is connection loss!)
        if (error.status !== 0 && (error.status < 500 || retryAttempt > maxRetryAttempts)) {
          const message = error.response
            ? error.response.messageKey || error.response.message
            : error.message || COMM_EXCEPTION;

          console.error(`Error on request to ${url}: (${error.status}) ${error.message}.`);

          return throwError(new CommunicationException(message, error.status, error.error));
        }

        // retry after 1s, 2s, etc... (depends on input parameters)
        return isConnected$.pipe(
          take(1),
          switchMap(isConnected => {
            if (isConnected) {
              console.warn(
                `ErrorInterceptor: Attempt ${retryAttempt} on "${url}" retrying in ${
                  retryAttempt * scalingDuration
                }ms...`
              );

              // if connected, just delay
              return timer(retryAttempt * scalingDuration);
            } else {
              console.debug(
                `ErrorInterceptor: Error executing call for "${url}", connection currently broken. Wait for re-connect to try again...`
              );

              // if not connected, wait until connection is established again
              return isConnected$.pipe(
                filter(connected => connected),
                map(() => void 0),
                take(1),
                tap(() => console.debug(`ErrorInterceptor: Connection re-established, retry call to url: "${url}"`))
              );
            }
          })
        );
      })
    );
  };
