import { Observable } from 'rxjs';

import {
  AutomatorSubType,
  AutomatorType,
  RobotTypes,
  RuleType
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import { ReactiveService } from '@celum/work/app/shared/util/reactive-service';

import { WorkroomConfiguration } from '../../core/model/entities/workroom';

export interface RobotServiceArgs {
  workroomConfig: WorkroomConfiguration;
  sourceId?: number;
  repositoryId?: string;
  robotSubType?: AutomatorSubType | RobotTypes;
  isTemplate?: boolean;
  robotType?: AutomatorType | RuleType;
}

export abstract class RobotService extends ReactiveService {
  public abstract definedRobotsLimitReached(args: RobotServiceArgs): Observable<boolean>;
  public abstract getDisabledTooltipKey(args: RobotServiceArgs): Observable<string | null>;
}
