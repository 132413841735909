import { EntityState } from '@ngrx/entity';

import { DataUtil, EntityType } from '@celum/core';
import { Entity } from '@celum/work/app/core/model';

/**
 * Utils for adding entities to the store.
 * Note that we do not use "Update" (https://ngrx.io/api/entity/Update),
 * because we rather want to handle the actual properties to update here and not
 * in the service/component where the action is issued. The service needs to
 * know which properties to update but should not care about whether the entity already exists in the store or not.
 */

/**
 * Merge given entities with existing ones based on the passed <code>propertiesToUpdate</code>.
 * If <code>propertiesToUpdate</code> is empty, nothing happens.
 * If <code>propertiesToUpdate</code> contains properties, ONLY those properties
 * will be updated (taken from the entities in <code>newEntities</code>).
 *
 * @param newEntities         the new entities
 * @param state               the current state for these entities
 * @param propertiesToUpdate  the properties of the entities which should be updated
 */
export function mergeEntities<S extends EntityState<E>, E extends Entity<any, EntityType>>(
  newEntities: E[],
  state: S,
  propertiesToUpdate: string[]
): E[] {
  // entities should be only partially updated
  if (!DataUtil.isEmpty(propertiesToUpdate)) {
    return newEntities.map(entity => {
      const existingEntity = state.entities[entity.id];

      if (existingEntity && !DataUtil.isEmpty(propertiesToUpdate)) {
        return doMergeEntity(propertiesToUpdate, existingEntity, entity);
      } else {
        return entity;
      }
    });
  } else {
    return newEntities;
  }
}

/**
 * Merge given entity with existing one based on the passed <code>propertiesToUpdate</code>.
 * If <code>propertiesToUpdate</code> is empty, nothing happens.
 * If <code>propertiesToUpdate</code> contains properties, ONLY those properties
 * will be updated (taken from the <code>newEntity</code>).
 *
 * @param newEntity           the new entity
 * @param state               the current state for this type of entity
 * @param propertiesToUpdate  properties of the entity which should be updated
 */
export function mergeEntity<S extends EntityState<E>, E extends Entity<any, EntityType>>(
  newEntity: E,
  state: S,
  propertiesToUpdate: string[]
): E {
  const existingEntity = state.entities[newEntity.id];

  if (!existingEntity) {
    return newEntity;
  } else {
    if (!DataUtil.isEmpty(propertiesToUpdate)) {
      return doMergeEntity(propertiesToUpdate, existingEntity, newEntity);
    } else {
      return newEntity;
    }
  }
}

function doMergeEntity<E extends Entity<number, EntityType>>(
  propertiesToUpdate: string[],
  existingTask: E,
  newTask: E
): E {
  const mergedTask = { ...existingTask };

  // only update the properties defined to be updated
  propertiesToUpdate.forEach(property => {
    if (mergedTask.hasOwnProperty(property)) {
      mergedTask[property] = newTask[property];
    } else {
      console.warn(`Could not update property ${property} because it does not exist on object: `, mergedTask);
    }
  });

  return mergedTask;
}
