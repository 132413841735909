import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractWebsocketListener } from './abstract-websocket-listener';
import { AbstractWebsocketWatcher } from './watcher/abstract-websocket-watcher';

@Injectable({ providedIn: 'root' })
export class ReplyWebsocketListener extends AbstractWebsocketListener {
  constructor(public websocketWatcher: AbstractWebsocketWatcher) {
    super(websocketWatcher);
  }

  public startListening<T>(replyId: string): Observable<T> {
    return this.watchTopic(`reply.${replyId}`);
  }
}
