<content-hub-menu-item 
        [icon]="exportIcon"
        [title]="files?.length === 1 ? 'CONTENT_HUB.CONTEXT_MENU.EXPORT.FILE.SINGULAR' : 'CONTENT_HUB.CONTEXT_MENU.EXPORT.FILE.PLURAL'"
        [dataOperationId]="'export-as-new-file'"
        (click)="exportAsNewAssets()">
</content-hub-menu-item>
<content-hub-menu-item 
        *ngIf="files.length === 1"
        [icon]="exportAsNewVersionOfAnotherAssetIcon"
        [title]="'CONTENT_HUB.CONTEXT_MENU.EXPORT.FILE_VERSION.NEW'"
        [dataOperationId]="'export-as-new-version-of-another-asset'"
        (click)="exportAsNewVersionOfAnotherAsset()">
</content-hub-menu-item>
<content-hub-menu-item 
        *ngIf="isVisibleExportAsNewVersionsOfCopiedAssets"
        [icon]="exportAsNewVersionOfCopiedAssetIcon"
        [title]="files?.length === 1 ? 'CONTENT_HUB.CONTEXT_MENU.EXPORT.FILE_VERSION.COPIED.SINGULAR' : 'CONTENT_HUB.CONTEXT_MENU.EXPORT.FILE_VERSION.COPIED.PLURAL'"
        [disabled]="!isEnabledExportAsNewVersionsOfCopiedAssets"
        [dataOperationId]="'export-as-new-version-of-copied-asset'"
        (click)="exportAsNewVersionsOfCopiedAssets()">
</content-hub-menu-item>
