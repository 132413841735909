import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CelumPropertiesProvider } from '@celum/core';

import { ImportedFolderData } from './watch-collections-automator.component';

@Injectable({ providedIn: 'root' })
export class WatchCollectionsAutomatorService {
  constructor(private readonly httpClient: HttpClient) {}

  public getImportedFolders(libraryId: string): Observable<ImportedFolderData[]> {
    return this.httpClient.post<ImportedFolderData[]>(
      CelumPropertiesProvider.properties.librariesHttpBaseAddress + '/content/imported-folders',
      { libraryId }
    );
  }
}
