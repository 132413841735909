import { ContentHub69XStrategy } from './content-hub-6-9-x-strategy';
import { ContentHubStrategy } from './content-hub-strategy';

export class ContentHub610XStrategy extends ContentHubStrategy {
  public defaultStrategy(): ContentHubStrategy {
    return new ContentHub69XStrategy();
  }

  public buildNumber(): string {
    return '6.10.0';
  }
}
