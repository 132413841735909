import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { ContentHubAutomatorService } from '@celum/work/app/robots/services/content-hub-automator.service';
import { RobotsFactory } from '@celum/work/app/robots/services/robots-factory';
import { MessageBoxConfigType } from '@celum/work/app/shared/components/message/message-box';

import { TaskList } from '../../../../core/model/entities/task/task-list.model';
import {
  RobotContext,
  RobotTriggerType,
  RuleType,
  TaskAssignmentOnMovementRule
} from '../../../../core/model/entities/workroom/robot.model';
import { RobotDialogConfiguration } from '../../robot-dialog/robot-dialog-config';
import { BaseRobot } from '../base-robot/base-robot.component';

@Component({
  selector: 'mandatory-assignment-rule',
  templateUrl: './mandatory-assignment-rule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MandatoryAssignmentRuleComponent extends BaseRobot<TaskList> {
  public readonly TARGETING_ROBOT_TYPE = RuleType.TASK_MANDATORY_ASSIGNMENT;

  constructor(
    protected robotsFactory: RobotsFactory,
    protected automatorService: ContentHubAutomatorService,
    translateService: TranslateService
  ) {
    super(robotsFactory, translateService, automatorService);
  }

  public get messageBoxes$(): Observable<MessageBoxConfigType[]> {
    return of([
      {
        type: 'info',
        text: this.translateService.instant(`ROBOTS.MANDATORY_ASSIGNMENT.INFO`)
      }
    ]);
  }

  public configure(configuration: RobotDialogConfiguration<TaskList>): void {
    super.configure(configuration);
  }

  public getResult(): TaskAssignmentOnMovementRule {
    return {
      type: RuleType.TASK_MANDATORY_ASSIGNMENT,
      sourceContext: RobotContext.TASK_LIST,
      sourceId: this.sourceEntity.id,
      trigger: {
        type: RobotTriggerType.TASK_MOVED,
        taskListId: this.sourceEntity.id
      }
    };
  }
}
