import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityTranslator } from '@celum/work/app/core/model';

import { Activity, ActivityType, ItemContext } from './activity.model';
import { selectActivityById } from './activity.selectors';
import { nullableString } from '../../nullable-string';
import { selectFileEntities } from '../file/file.selectors';
import { selectPersonEntities } from '../person/person.selectors';
import { selectTaskEntities } from '../task/task.selectors';

export class ActivityTranslator implements EntityTranslator<Activity> {
  public translateToEntity(json: any): Activity {
    const id = json.id;

    return {
      id,
      key: json.key,
      data: json.data,
      objectId: json.objectId,
      context: nullableString(json.context) as ItemContext,
      createdBy: json.createdById,
      changedBy: json.changedById,
      changedOn: json.changedOn,
      createdOn: json.createdOn,
      workroomId: json.workroomId,
      entityType: ActivityType.instance(),
      affected: json.affected,
      fileVersionId: json.fileVersionId,
      versionNumber: json.versionNumber,
      object: (store: Store<any>) =>
        combineLatest([
          store.select(selectTaskEntities),
          store.select(selectFileEntities),
          store.select(selectActivityById(id))
        ]).pipe(
          map(([tasks, files, activity]) => {
            const objectId = activity.objectId;
            if (!objectId) {
              return null;
            }
            if (tasks[objectId]) {
              return tasks[objectId];
            }
            if (files[objectId]) {
              return files[objectId];
            }
            return null;
          })
        ),
      creator: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectActivityById(id))]).pipe(
          map(([persons, activity]) => persons[activity.createdBy])
        ),
      modifier: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectActivityById(id))]).pipe(
          map(([persons, activity]) => (activity.changedBy ? persons[activity.changedBy] : null))
        )
    };
  }
}
