import { EntityTranslator } from '@celum/work/app/core/model';
import { Member, MemberType } from '@celum/work/app/core/model/entities/member/member.model';

export class MemberTranslator implements EntityTranslator<Member> {
  public translateToEntity(json: any): Member {
    return {
      id: `${json.teamspaceId}_${json.personId}`,
      personId: json.personId,
      teamspaceId: json.teamspaceId,
      status: json.status,
      invitationStatus: json.invitationStatus,
      externalId: json.externalId,
      entityType: MemberType.instance()
    };
  }
}
