import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Roles } from '@celum/work/app/core/model';
import { Person } from '@celum/work/app/core/model/entities/person';
import { PersonListItemService } from '@celum/work/app/person/people-list/services/person-list-item.service';

import { WorkroomWizardPeopleService } from './workroom-wizard-people.service';

@Injectable()
export class PersonListItemTemplateService extends PersonListItemService<Person> {
  constructor(private workroomWizardPeopleService: WorkroomWizardPeopleService) {
    super();
  }

  public getRoles(person: Person): Observable<Roles[]> {
    return of(this.workroomWizardPeopleService.getRoles(person));
  }
}
