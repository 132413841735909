import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { CelumPropertiesProvider, DataUtil } from '@celum/core';
import { FileVersionService } from '@celum/work/app/core/api/file-version/file-version.service';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { Paging, Sorting } from '@celum/work/app/core/model';
import {
  Comment,
  CommentPropertiesToUpdate,
  CommentType
} from '@celum/work/app/core/model/entities/comment/comment.model';
import { File, FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { MetaInfo } from '@celum/work/app/core/model/meta-info.model';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

// type created because Object was used before and threw test errors; refactor to typed interface if needed later
type CommentResponse = Record<string, any>;

@Injectable({ providedIn: 'root' })
export class CommentService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService,
    private fileVersionService: FileVersionService
  ) {}

  public createComment(comment: Comment, fileId: string): Observable<Comment> {
    const metaInfo = MetaInfo.of(
      [CommentType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED],
      CommentType.instance().getSchema({ relationsFor: [FileType.TYPE_KEY] })
    );

    const body = {
      content: comment.content,
      htmlContents: comment.htmlContents,
      marker: comment.markers,
      objectId: comment.objectId,
      context: comment.context,
      parentId: comment.parentId,
      fileVersionId: comment.fileVersionId,
      workroomId: comment.workroomId
    };

    return this.httpClient.post(`${CelumPropertiesProvider.properties.httpBaseAddress}/comment`, body).pipe(
      switchMap(res => this.populateVersionNumber(fileId, comment, res)),
      map(res => this.handleSingleCommentResult(res, metaInfo))
    );
  }

  public updateComment(
    comment: Comment,
    propertiesToUpdate: CommentPropertiesToUpdate,
    fileId: string
  ): Observable<Comment> {
    const metaInfo = MetaInfo.of([CommentType.TYPE_KEY], CommentType.instance().getSchema());

    const body: any = {
      ...propertiesToUpdate
    };

    return this.httpClient
      .patch(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/comment/${comment.id}`,
        body,
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(
        switchMap(res => this.populateVersionNumber(fileId, comment, res)),
        map(res => this.handleSingleCommentResult(res, metaInfo))
      );
  }

  public deleteComment(commentId: number): Observable<void> {
    return this.httpClient.delete(`${CelumPropertiesProvider.properties.httpBaseAddress}/comment/${commentId}`).pipe(
      map(() => {
        return void 0;
      })
    );
  }

  public getRepliesOfComment(commentId: number, offset: number, batchSize: number) {
    const body = {
      commentId,
      paging: Paging.of(offset, batchSize),
      sorting: Sorting.of('createdOn', SortDirection.DESC)
    };

    const metaInfo = MetaInfo.of(
      [CommentType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED],
      { results: [CommentType.instance().getSchema({ relationsFor: [FileType.TYPE_KEY] })] },
      [CommentType.TYPE_KEY],
      'results'
    );

    return this.httpClient.post(`${CelumPropertiesProvider.properties.httpBaseAddress}/comment/replies`, body).pipe(
      map(res => {
        const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
        return {
          replies: entitiesResult.entities[CommentType.TYPE_KEY] as Comment[],
          paginationResult: entitiesResult.paginationResult
        };
      })
    );
  }

  public getAttachments(workroomId: number, contentItemIds: string[]): Observable<File[]> {
    const metaInfo = MetaInfo.of([...FileType.TYPE_KEY_NESTED], [FileType.instance().getSchema()]);

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/content/${workroomId}/attachments`, contentItemIds)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return entitiesResult.entities[FileType.TYPE_KEY] as File[];
        })
      );
  }

  private populateVersionNumber(fileId: string, comment: Comment, res: CommentResponse): Observable<CommentResponse> {
    if (!fileId) {
      return of({ ...res });
    }
    return this.fileVersionService.getFileVersion(fileId, comment.fileVersionId).pipe(
      map(fileVersion => ({
        ...res,
        versionNumber: fileVersion.versionNumber
      }))
    );
  }

  private handleSingleCommentResult(res, metaInfo): Comment {
    const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
    const comments = entitiesResult.entities[CommentType.TYPE_KEY];
    return DataUtil.isEmpty(comments) ? null : (comments[0] as Comment);
  }
}
