import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LocalizedValue, LocalizedValueMap } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

import { IconConfiguration } from '../icon';

@Component({
             selector: 'language-picker',
             templateUrl: './language-picker.html',
             styleUrls: ['./language-picker.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class LanguagePicker extends ReactiveComponent {

  @Input() public availableLanguages: LocalizedValueMap;
  @Input() public defaultLanguage: string;
  @Input() public activeLanguages = new Set<string>();
  @Input() public description = 'LANGUAGE_PICKER.LANGUAGES';

  @Output() public readonly activeLanguagesChanged = new EventEmitter<Set<string>>();

  public readonly langIcon = IconConfiguration.medium('globe').withColor('#fff');

  constructor(private translate: TranslateService) {
    super();
  }

  public handleClick(e: Event): void {
    // just preventing default menu closing
    e.stopPropagation();
  }

  public languageWasChecked(checked: boolean, keyPair: KeyValue<string, LocalizedValue>): void {
    const updatedLanguages = new Set(this.activeLanguages);
    checked ? updatedLanguages.add(keyPair.key) : updatedLanguages.delete(keyPair.key);
    this.activeLanguagesChanged.emit(updatedLanguages);
  }

  public hasAvailableLanguages(): boolean {
    return this.availableLanguages && Object.keys(this.availableLanguages).length > 0;
  }

  public sortedByLocalizedLanguage = (a: KeyValue<string, LocalizedValue>, b: KeyValue<string, LocalizedValue>): number => {
    const nameA = LanguagePicker.getValueForLocale(a.value, this.translate.currentLang, this.translate.defaultLang);
    const nameB = LanguagePicker.getValueForLocale(b.value, this.translate.currentLang, this.translate.defaultLang);
    return nameA?.localeCompare(nameB);
  };

  public static getValueForLocale(localizedValue: LocalizedValue, locale: string, fallbackLocale?: string): string {
    if (!localizedValue || (!locale && !fallbackLocale)) {
      return null;
    }
    return localizedValue[locale] ?? localizedValue[fallbackLocale];
  }
}
