import { createSelector } from '@ngrx/store';

import { memberAdapter } from './member.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';
import { selectAllPersons } from '../person';

const { selectEntities, selectAll } = memberAdapter.getSelectors();

export const selectMembers = createSelector(selectEntitiesState, (state: EntitiesState) => state.members);

export const selectAllMembers = createSelector(selectMembers, selectAll);
export const selectMemberEntities = createSelector(selectMembers, selectEntities);

export const selectMembersByUserId = (userId: number) =>
  createSelector(selectAllMembers, members => members.filter(member => member.personId === userId));

export const selectMemberByUserIdAndTeamspaceId = (userId: number, teamspaceId: number) =>
  createSelector(selectAllMembers, members =>
    members.find(member => member?.teamspaceId === teamspaceId && member?.personId === userId)
  );

export const selectMembersByUserIdsAndTeamspaceId = (userIds: (number | string)[], teamspaceId: number) =>
  createSelector(selectAllMembers, members =>
    members.filter(member => member?.teamspaceId === teamspaceId && userIds.includes(member?.personId))
  );

export const selectMemberByUserOidAndTeamspaceId = (userOid: string, teamspaceId: number) =>
  createSelector(selectAllPersons, selectAllMembers, (persons, members) =>
    members.find(
      member =>
        member?.teamspaceId === teamspaceId && member?.personId === persons.find(person => person.oid === userOid)?.id
    )
  );
