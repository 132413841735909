import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  isProgressTaskStatusActive,
  ProgressTask,
  ProgressTaskParams,
  ProgressTaskPayload,
  ProgressTaskState,
  ProgressTaskStatus,
  ProgressTaskType
} from '@celum/work/app/progress-task/store/progress-task.model';

import { progressTaskAdapter } from './progress-task.reducer';

export const PROGRESS_TASK_FEATURE_NAME = 'progressTaskState';

export const selectProgressState = createFeatureSelector<ProgressTaskState>(PROGRESS_TASK_FEATURE_NAME);

const { selectEntities, selectAll } = progressTaskAdapter.getSelectors();

export const selectProgressTaskEntities = createSelector(selectProgressState, selectEntities);
export const selectProgressTasks = createSelector(selectProgressState, selectAll);

export const selectProgressTaskById = <T extends ProgressTaskParams, S extends ProgressTaskPayload>(
  id
): ((state: ProgressTaskState) => ProgressTask<T, S>) =>
  createSelector(
    selectProgressTaskEntities,
    (progressTaskDictionary: Dictionary<ProgressTask<any, any>>) => progressTaskDictionary[id] as ProgressTask<T, S>
  );

export const selectProgressTasksByType = (type: ProgressTaskType) =>
  createSelector(selectProgressTasks, (progressTasks: ProgressTask<any, any>[]) =>
    progressTasks.filter(progressTask => progressTask.type === type)
  );

export const selectActiveProgressTasks = createSelector(selectProgressTasks, progressTasks =>
  progressTasks.filter(progressTask => isProgressTaskStatusActive(progressTask.status))
);

export const selectProgressTasksForExecution = <T extends ProgressTaskParams, S extends ProgressTaskPayload>(
  type: ProgressTaskType,
  concurrencyLimit?: number
): ((state: ProgressTaskState) => ProgressTask<any, any>[]) =>
  createSelector(selectProgressTasksByType(type), (progressTasks: ProgressTask<T, S>[]) => {
    const queuedProgressTasks = progressTasks.filter(
      ({ status, serverUpdated }) => status === ProgressTaskStatus.QUEUED && !serverUpdated
    );
    const inProgressProgressTasks = progressTasks.filter(({ status }) => status === ProgressTaskStatus.IN_PROGRESS);

    if (!concurrencyLimit) {
      return queuedProgressTasks;
    }

    if (inProgressProgressTasks.length < concurrencyLimit) {
      return queuedProgressTasks.slice(0, concurrencyLimit - inProgressProgressTasks.length);
    }

    return [];
  });

export const selectProgressTaskTypes = createSelector(selectProgressTasks, progressTasks =>
  progressTasks.reduce((acc, curr) => {
    if (!acc.includes(curr.type)) {
      acc.push(curr.type);
    }

    return acc;
  }, [])
);

export const selectProgressTaskByPayloadId = (id: number) =>
  createSelector(selectProgressTasks, progressTasks => progressTasks.find(({ payload }) => payload?.id === id));

export const selectProgressTasksByPayloadIds = (ids: number[]) =>
  createSelector(selectProgressTasks, progressTasks =>
    progressTasks.filter(progressTask => progressTask.payload?.id && ids.includes(progressTask.payload.id))
  );
