import { Pipe, PipeTransform } from '@angular/core';

import { Roles } from '../../../../../core/model';
import { InvitationStatus } from '../../../../../core/model/entities/member';
import { RoleOption } from '../role-option.model';

@Pipe({
  name: 'multilinePersonTemplateDriveWarning'
})
export class MultilinePersonTemplateDriveWarningPipe implements PipeTransform {
  public transform(
    selectedRoleOption: RoleOption,
    initialRoleOption: RoleOption,
    showDriveWarnings: boolean,
    invitationStatus: InvitationStatus
  ): boolean {
    const statusesInWhichDriveAccessIsPossible = [InvitationStatus.APPROVED, InvitationStatus.ACCEPTED];

    return (
      showDriveWarnings &&
      selectedRoleOption?.role === Roles.VISITOR &&
      initialRoleOption.role !== Roles.VISITOR &&
      statusesInWhichDriveAccessIsPossible.includes(invitationStatus)
    );
  }
}
