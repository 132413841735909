import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SharedModule } from '@celum/work/app/shared';

import { SnackbarGroupItemComponent } from './snackbar-group-item/snackbar-group-item.component';
import { SnackbarGroupComponent } from './snackbar-group.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule
  ],
  declarations: [SnackbarGroupComponent, SnackbarGroupItemComponent],
  exports: [SnackbarGroupComponent, SnackbarGroupItemComponent]
})
export class SnackbarGroupModule {}
