import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';

import { CustomFieldChipsInputValidationErrors } from './custom-field-chips-input-errors.enum';

export function customFieldChipsInputValidator(): ValidatorFn {
  return (chipsInputControl: FormGroup): CustomFieldChipsInputValidationErrors | null => {
    const { chips, chipName } = chipsInputControl.controls;
    const errors: CustomFieldChipsInputValidationErrors = {};

    if (Validators.required(chipName) && Validators.required(chips)) {
      errors.CHIP_NAME_REQUIRED = true;
    }

    if (chips.hasError('required')) {
      errors.CHIPS_NOT_DEFINED = true;
    }

    return isEmpty(errors) ? null : errors;
  };
}
