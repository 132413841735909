import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { SearchAndSelectContributors } from '@celum/work/app/shared/components/search-and-select/search-and-select-contributors/search-and-select-contributors.component';
import { SearchAndSelectTaskListComponent } from '@celum/work/app/shared/components/search-and-select/search-and-select-task-list/search-and-select-task-list.component';

type SearchAndSelectComponent = SearchAndSelectTaskListComponent | SearchAndSelectContributors;
type ComponentPortalForSearchAndSelect = ComponentPortal<SearchAndSelectComponent>;

@Injectable({ providedIn: 'root' })
export class SearchAndSelectService {
  constructor(private overlay: Overlay) {}

  public static closeOnBackdrop(overlayRef: OverlayRef, componentRef: ComponentRef<any>): void {
    overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        SearchAndSelectService.closeOverlayIfOpen(overlayRef, componentRef);
      });
  }

  public static closeOverlayIfOpen(overlayRef: OverlayRef, componentRef: ComponentRef<any>): void {
    if (overlayRef) {
      overlayRef.detach();
      overlayRef.dispose();
    }

    if (componentRef) {
      componentRef.destroy();
    }
  }

  public openSearchAndSelectOverlay(
    el: HTMLElement,
    componentPortal: ComponentPortalForSearchAndSelect
  ): { overlayRef: OverlayRef; componentRef: ComponentRef<any> } {
    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(el)
        .withPositions([
          {
            overlayX: 'start',
            overlayY: 'top',
            originX: 'start',
            originY: 'bottom'
          }
        ]),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
    const componentRef: ComponentRef<SearchAndSelectComponent> = overlayRef.attach(componentPortal);

    SearchAndSelectService.closeOnBackdrop(overlayRef, componentRef);
    return { overlayRef, componentRef };
  }
}
