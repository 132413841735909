import { Injectable } from '@angular/core';

interface CacheEntry {
  data: any;
  numberOfRequests: number;
}

@Injectable({
  providedIn: 'root'
})
export class ResultCachingService {
  private cache: { [key: string]: CacheEntry } = {};

  public getCachedResult(methodName: string, args: any, maxNumberOfTries = 100): any {
    const key = this.createCacheEntryKey(methodName, args);
    const cacheEntry: CacheEntry = this.cache[key];

    if (!cacheEntry) {
      return undefined;
    }

    if (cacheEntry.numberOfRequests > maxNumberOfTries) {
      this.deleteCacheEntry(key);
      return undefined;
    }

    cacheEntry.numberOfRequests++;
    return cacheEntry.data;
  }

  public createCacheEntry(methodName: string, args: any, data: any): void {
    this.cache[this.createCacheEntryKey(methodName, args)] = {
      data: data,
      numberOfRequests: 0
    };
  }

  private deleteCacheEntry(methodName: string): void {
    delete this.cache[methodName];
  }

  private createCacheEntryKey(methodName: string, args: any): string {
    return methodName + JSON.stringify(args);
  }
}
