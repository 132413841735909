import { createAction, props } from '@ngrx/store';

import { PaginationResult } from '@celum/core';
import { FileVersion } from '@celum/work/app/core/model/entities/file-version/file-version.model';

export const VersionSwitcherLoadFileVersions = createAction(
  '[File Version] Load File Versions',
  props<{ offset: number; limit?: number; resetExisting?: boolean; fileId: string }>()
);

export const VersionSwitcherFileVersionsLoaded = createAction(
  '[File Version] File Versions Loaded',
  props<{ fileVersions: FileVersion[]; paginationResult: PaginationResult; resetState: boolean; fileId: string }>()
);

export const VersionSwitcherFetchNextBatch = createAction(
  '[FileVersion] Fetch Next Batch',
  props<{ fileId: string }>()
);

export const VersionSwitcherChangeBatchSize = createAction(
  '[FileVersion] Change Batch Size',
  props<{ batchSize: number; fileId: string }>()
);

export const VersionSwitcherSelectFileVersion = createAction(
  '[FileVersion] Select File Version',
  props<{ fileVersionId: string }>()
);

export const VersionSwitcherDeleteFileVersion = createAction(
  '[FileVersion] Delete File Version',
  props<{ fileId: string; versionId: string; versionNumber: number }>()
);

export const VersionSwitcherFileVersionDeleted = createAction(
  '[FileVersion] File Version Deleted',
  props<{ versionId: string }>()
);

export const VersionSwitcherMakeVersionActive = createAction(
  '[FileVersion] Make File Version Active',
  props<{ fileId: string; versionId: string }>()
);

export const VersionSwitcherMakeActiveSuccess = createAction(
  '[FileVersion] Make File Version Active Success',
  props<{ fileId: string; versionId: string; previousSelectedVersionId?: string }>()
);

export const VersionSwitcherSelectCompareVersion = createAction(
  '[FileVersion] Select File Version For Compare',
  props<{ versionId: string; fileId?: string }>()
);

export const VersionSwitcherSelectCompareVersionSuccess = createAction(
  '[FileVersion] Select File Version For Compare Success',
  props<{ fileId: string; versionId: string }>()
);

export const VersionSwitcherLoadFileVersionsForCompare = createAction(
  '[File Version] Load File Versions For Compare',
  props<{ offset: number; limit: number; fileId: string }>()
);

export const VersionSwitcherFileVersionsForCompareLoaded = createAction(
  '[File Version] File Versions For Compare Loaded',
  props<{ fileVersions: FileVersion[]; paginationResult: PaginationResult; resetState: boolean; fileId: string }>()
);

export const VersionSwitcherDownloadFileVersion = createAction(
  '[File Version] File Versions Download',
  props<{ fileId: string; versionId: string }>()
);

export const VersionSwitcherResetState = createAction('[File Version] File Versions Reset state');
