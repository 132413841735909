import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, first, map, takeUntil } from 'rxjs/operators';

import { CelumDialog, CelumDialogConfiguration, ColorConstants } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Permission } from '@celum/work/app/core/api/permission';
import {
  CreateTemplate,
  FetchTemplateCategories
} from '@celum/work/app/core/api/workroom-creator/template-chooser.actions';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';
import { InvitedPerson, Template } from '@celum/work/app/core/model/entities/template/template.model';
import {
  PredefinedCategoryValues,
  TemplateCategory
} from '@celum/work/app/core/model/entities/template-category/template-category.model';
import { selectCategoriesForPermission } from '@celum/work/app/core/model/entities/template-category/template-category.selectors';
import { WORKROOM_VALIDATION_VALUES } from '@celum/work/app/core/model/entities/workroom';
import { WorkroomWizardPeopleService } from '@celum/work/app/workroom-wizard/services/workroom-wizard-people.service';

@Component({
  selector: 'workroom-details-save-template-dialog',
  templateUrl: './workroom-details-save-template-dialog.component.html',
  styleUrls: ['./workroom-details-save-template-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WorkroomDetailsSaveTemplateDialogComponent
  extends ReactiveComponent
  implements CelumDialog<WorkroomDetailsSaveTemplateDialogConfiguration>, OnInit
{
  public readonly personalCategoryKey = PredefinedCategoryValues.PERSONAL;
  public readonly validationValues = WORKROOM_VALIDATION_VALUES;
  public color: string = ColorConstants.PRIMARY;
  public confirmText: string;
  public form: UntypedFormGroup;
  public showInfoNote: boolean;
  private template: Template;
  private peopleService: WorkroomWizardPeopleService;
  private teamspace: Teamspace;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<any>,
    private dialogRef: MatDialogRef<WorkroomDetailsSaveTemplateDialogComponent>,
    private translateService: TranslateService
  ) {
    super();
    this.form = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.maxLength(this.validationValues.maxNameLength)]),
      categoryId: this.fb.control(null, [Validators.required])
    });
  }

  get categories$(): Observable<TemplateCategory[]> {
    return this.store
      .select(selectCategoriesForPermission, { permission: Permission.TEMPLATE_CREATE })
      .pipe(map(categories => categories.filter(category => category.teamspaceId === this.teamspace.id)));
  }

  get nameControl(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  get nameLength(): number {
    return this.nameControl.value.length;
  }

  get categoryIdControl(): UntypedFormControl {
    return this.form.get('categoryId') as UntypedFormControl;
  }

  get remainingNameChars(): { remaining: number } {
    return {
      remaining: this.validationValues.maxNameLength - this.nameLength
    };
  }

  get showRemainingNameCharsHint(): boolean {
    const { maxNameLength, hintOffsetPercentage } = this.validationValues;
    return this.nameLength <= maxNameLength && this.nameLength / maxNameLength >= hintOffsetPercentage;
  }

  public cancel() {
    this.dialogRef.close();
  }

  public trackByFn(index: number, category: TemplateCategory): number | string {
    return category ? category.id : index;
  }

  public ngOnInit(): void {
    this.categories$.pipe(first()).subscribe(categories => {
      if (categories?.length === 0) {
        this.store.dispatch(FetchTemplateCategories());
      }
    });

    this.categories$
      .pipe(
        takeUntil(this.unsubscribe$),
        map(categories => categories.length),
        distinctUntilChanged()
      )
      .subscribe(length => {
        const value = length > 0 ? null : 'personal';
        this.categoryIdControl.setValue(value);
      });

    this.showInfoNote = this.peopleService.peopleWithStatuses.some(person => person.status !== 'ACTIVE');
  }

  public saveTemplate() {
    const name = this.nameControl.value;
    const template: Template = this.template;
    this.categories$
      .pipe(
        first(),
        map(categories => {
          const categoryValue = this.categoryIdControl.value;
          const categoryId = categoryValue === this.personalCategoryKey ? null : Number(categoryValue);
          const newTemplate: Template = {
            ...template,
            contentHubRepositoryId: this.template.contentHubRepositoryId || null,
            name,
            categoryId
          };

          const categoryIndex = categories.map(item => item.id).indexOf(this.categoryIdControl.value);

          const category =
            categories[categoryIndex]?.name || this.translateService.instant('TEMPLATE_CHOOSER.PERSONAL_CATEGORY');

          const activeMembers = this.peopleService.peopleWithStatuses.filter(
            personWithStatus => personWithStatus.status === 'ACTIVE'
          );

          const activeInvitedPeople = this.peopleService.invitedPeople.filter((invitedPerson: InvitedPerson) => {
            return activeMembers.map(activeMember => activeMember.id).includes(invitedPerson.personId);
          });

          return {
            newTemplate,
            activeInvitedPeople,
            category
          };
        })
      )
      .subscribe(({ newTemplate, activeInvitedPeople, category }) => {
        this.store.dispatch(
          CreateTemplate({
            template: newTemplate,
            people: activeInvitedPeople,
            category
          })
        );
        this.dialogRef.close();
      });
  }

  public configure({ template, name, peopleService, teamspace }: WorkroomDetailsSaveTemplateDialogConfiguration): void {
    this.template = template;
    this.nameControl.setValue(name);
    this.peopleService = peopleService;
    this.teamspace = teamspace;

    // there is a strange behaviour in showing the maxLength error
    // needed during workroom creation
    this.nameControl.markAsTouched();
    // needed during editing of workroom
    this.nameControl.markAsDirty();
  }
}

export class WorkroomDetailsSaveTemplateDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(
    public name: string,
    public template: Template,
    public peopleService: WorkroomWizardPeopleService,
    public teamspace: Teamspace
  ) {
    super('workroom-details-save-template-dialog');
  }
}
