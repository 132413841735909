import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider, DataUtil } from '@celum/core';
import { MetaInfo } from '@celum/work/app/core/model';
import { PersonType } from '@celum/work/app/core/model/entities/person';
import {
  TaskList,
  TaskListPropertiesToUpdate,
  TaskListType
} from '@celum/work/app/core/model/entities/task/task-list.model';

import { ResultConsumerService } from '../../communication/result-consumer.service';

@Injectable({ providedIn: 'root' })
export class TaskListService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public loadTaskLists(workroomId: number): Observable<TaskList[]> {
    const metaInfo = MetaInfo.of(
      [TaskListType.TYPE_KEY, PersonType.TYPE_KEY],
      [TaskListType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY] })]
    );

    return this.httpClient
      .get(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasklists/workroom/${workroomId}`)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return entitiesResult.entities[TaskListType.TYPE_KEY] as TaskList[];
        })
      );
  }

  public createTaskList(taskList: TaskList): Observable<TaskList> {
    const metaInfo = MetaInfo.of([TaskListType.TYPE_KEY], TaskListType.instance().getSchema());

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasklists`, taskList)
      .pipe(map(res => this.handleSingleTaskListResult(res, metaInfo)));
  }

  public updateTaskList(taskList: TaskList, propertiesToUpdate: TaskListPropertiesToUpdate): Observable<TaskList> {
    const metaInfo = MetaInfo.of([TaskListType.TYPE_KEY], TaskListType.instance().getSchema());

    const body = {
      ...propertiesToUpdate
    };

    return this.httpClient
      .patch(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasklists/${taskList.id}`, body)
      .pipe(map(res => this.handleSingleTaskListResult(res, metaInfo)));
  }

  public addOwnerToTaskList(taskListId: number, personId: number): Observable<void> {
    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasklist/${taskListId}/owner/${personId}`, null)
      .pipe(
        map(() => {
          return void 0;
        })
      );
  }

  public removeOwnerFromTaskList(taskListId: number, personId: number): Observable<void> {
    return this.httpClient
      .delete(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasklist/${taskListId}/owner/${personId}`)
      .pipe(
        map(() => {
          return void 0;
        })
      );
  }

  public deleteTaskList(taskListId: number): Observable<void> {
    return this.httpClient.delete(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasklists/${taskListId}`).pipe(
      map(() => {
        return void 0;
      })
    );
  }

  private handleSingleTaskListResult(res, metaInfo) {
    const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);

    const taskLists = entitiesResult.entities[TaskListType.TYPE_KEY];

    return DataUtil.isEmpty(taskLists) ? null : (taskLists[0] as TaskList);
  }
}
