<celum-dialog (onCloseAll)="closeDialog()">
  <celum-dialog-header>
    {{ 'FOLDER.DIALOG.CREATE.HEADER' | translate }}
  </celum-dialog-header>
  <celum-dialog-content>
    <form [formGroup]="createFolderActionForm">
      <mat-form-field celum-form-field class="create-folder_form-field" [reserveHintSpace]="true">
        <input autoSelect matInput formControlName="name">
        <mat-error *ngIf="createFolderActionForm.get('name').hasError('required')">{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
        <mat-error *ngIf="createFolderActionForm.get('name').hasError('validCharacters')">
          {{ 'VALIDATION.NO_INVALID_CHARACTERS' | translate: createFolderActionForm.get('name').getError('validCharacters').params}}
        </mat-error>
        <mat-error *ngIf="createFolderActionForm.get('name').hasError('maxLength')">
          {{ 'VALIDATION.MAX_LENGTH' | translate: createFolderActionForm.get('name').getError('maxLength').params}}
        </mat-error>
      </mat-form-field>
    </form>
  </celum-dialog-content>
  <celum-dialog-footer confirmBtnText="FOLDER.DIALOG.CONFIRM"
                       cancelBtnText="FOLDER.DIALOG.CANCEL"
                       [valid]="createFolderActionForm.valid"
                       (onConfirm)="createFolder()"
                       (onCancel)="closeDialog()"></celum-dialog-footer>
</celum-dialog>
