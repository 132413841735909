import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { CelumDialogOpener } from '@celum/internal-components';
import { WorkroomContributorService } from '@celum/work/app/core/api/workroom-contributor/workroom-contributor.service';
import { InvitationStatus } from '@celum/work/app/core/model/entities/member/member.model';
import { Person } from '@celum/work/app/core/model/entities/person/index';
import { selectPersonsByIds } from '@celum/work/app/core/model/entities/person/person.selectors';
import {
  selectCurrentWorkroom,
  selectCurrentWorkroomContributingPersons
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import {
  InviteUserDialog,
  InviteUserDialogConfiguration,
  InviteUserDialogExecuteFn
} from '@celum/work/app/person/invite/components/invite-user-dialog/invite-user-dialog.component';
import { InteractiveAvatarsEvents } from '@celum/work/app/shared/components/interactive-avatar/interactive-avatars.events';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';

@Injectable()
export class InviteAndAssignService {
  public teamspaceId$: Observable<number>;

  constructor(
    private store: Store,
    private eventBus: ApplicationEventBus,
    private dialogOpener: CelumDialogOpener,
    private contributorService: WorkroomContributorService
  ) {
    this.teamspaceId$ = this.store.select(selectCurrentWorkroom).pipe(
      take(1),
      map(({ teamspaceId }) => teamspaceId)
    );
  }

  public inviteAndAssign(
    defaultSearchString: string,
    assignedPersonEventEmitter: EventEmitter<Person>,
    infoNoteKey = null
  ): void {
    const statusesToDisplay = [InvitationStatus.ACCEPTED, InvitationStatus.APPROVED, InvitationStatus.PENDING_APPROVAL];
    const executeFn: InviteUserDialogExecuteFn = people => {
      this.eventBus.publishEvent({ type: InteractiveAvatarsEvents.SHOULD_CLOSE_SEARCH });
      return this.store.select(selectCurrentWorkroom).pipe(
        take(1),
        switchMap(workroom =>
          this.contributorService.invitePerson(
            workroom.id,
            people.map(person => ({
              personId: null,
              invitationEmail: person.getInvitationEmail(),
              roles: [person.getRole()]
            }))
          )
        ),
        map(contributors => contributors.map(({ personId }) => personId)),
        switchMap(personIds => this.store.select(selectPersonsByIds(personIds)).pipe(take(1))),
        tap(persons => persons.forEach(person => assignedPersonEventEmitter.emit(person)))
      );
    };

    this.teamspaceId$
      .pipe(withLatestFrom(this.store.select(selectCurrentWorkroomContributingPersons)))
      .subscribe(([teamspaceId, contributors]) => {
        const emailsToExclude = contributors.map(({ email }) => email);
        const dialogConfig = new InviteUserDialogConfiguration({
          headerText: 'PEOPLE.INVITE.INVITE_AND_ASSIGN_TITLE',
          confirmButtonText: 'PEOPLE.INVITE.INVITE_AND_ASSIGN_BTN',
          teamspaceId,
          alreadyInvited: emailsToExclude,
          statusesToDisplay: statusesToDisplay,
          organisationMembersOnly: false,
          showMessage: true,
          infoNoteKey,
          initialPeopleSearchString: defaultSearchString,
          executeFn
        });

        this.dialogOpener.showDialog('personDialog', InviteUserDialog, dialogConfig);
      });
  }
}
