import { createSelector } from '@ngrx/store';

import { selectAllRenditions } from '@celum/work/app/core/model/entities/rendition/rendition.selectors';

import { fileVersionAdapter } from './file-version.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = fileVersionAdapter.getSelectors();

export const selectFileVersions = createSelector(selectEntitiesState, (state: EntitiesState) => state.fileVersions);

export const selectAllFileVersions = createSelector(selectFileVersions, selectAll);
export const selectFileVersionEntities = createSelector(selectFileVersions, selectEntities);

export const selectFileVersionById = (fileId: string) =>
  createSelector(selectFileVersionEntities, fileVersionDictionary => fileVersionDictionary[fileId]);

export const selectFileVersionsForFileId = (fileId: string) =>
  createSelector(selectAllFileVersions, fileVersions => fileVersions.filter(version => version.fileId === fileId));

export const selectRenditionsForFileVersion = (fileVersionId: string) =>
  createSelector(selectFileVersionById(fileVersionId), selectAllRenditions, (fileVersion, renditions) =>
    renditions.filter(({ id }) => fileVersion.renditionsIds?.includes(id))
  );

export const selectRenditionsForFileVersions = (fileVersionIds: number[]) =>
  createSelector(selectFileVersionEntities, selectAllRenditions, (fileVersionDictionary, renditions) => {
    return fileVersionIds
      .map(id => fileVersionDictionary[id])
      .map(fileVersion => renditions.filter(({ id }) => fileVersion.renditionsIds?.includes(id)));
  });

export const selectFileIdByFileVersionId = (fileVersionId: string) =>
  createSelector(selectFileVersionEntities, fileVersions => fileVersions[fileVersionId].fileId);
