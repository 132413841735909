import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectTaskListById, TaskList } from '@celum/work/app/core/model/entities/task';
import {
  Automator,
  AutomatorType,
  ContentHubAction,
  isAutomator,
  RobotActionType,
  RobotContext,
  Robots
} from '@celum/work/app/core/model/entities/workroom/robot.model';

import { ContentHubAutomatorComponent } from './content-hub-automator.component';
import { RobotChipConfig, RobotsFactory } from '../../../services/robots-factory';
import { BaseRobotConfigService, RobotNotAppliedConfig } from '../base-robot/base-robot-config.service';

@Injectable()
export class ContentHubAutomatorConfigService extends BaseRobotConfigService {
  constructor(
    public translate: TranslateService,
    private store: Store
  ) {
    super(translate);
  }

  public robotBelongsToTaskList(robot: Robots, taskList: TaskList): boolean {
    return true;
  }

  public serviceMatchesRobot(robot: Robots): boolean {
    return robot.type === AutomatorType.CONTENT_HUB && robot.sourceContext !== RobotContext.CONTENT_ITEM;
  }

  public getTaskListById(taskListId: number): Observable<TaskList> {
    return this.store.select(selectTaskListById(taskListId));
  }

  public getTaskListName(taskListId: number, sourceEntity: TaskList): Observable<string> {
    return this.getTaskListById(taskListId).pipe(map(taskList => (taskList ? taskList.name : sourceEntity.name)));
  }

  public getRobotChipConfig(robot: Robots, sourceEntity?: any): Observable<Partial<RobotChipConfig>> {
    const rows = this.getContentHubRows(robot as Automator);

    return of({
      icon: RobotsFactory.getIcon(robot.type).withIconSize(20),
      row1: rows[0],
      row2: rows[1],
      editEvent: {
        robot,
        type: ContentHubAutomatorComponent
      }
    });
  }

  public isRobotNotApplied(params: RobotNotAppliedConfig): Observable<boolean> {
    const { robot, isRobotBeingCreated } = params;
    const actionsNeedingCollectionAccess = [
      RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION,
      RobotActionType.CONTENT_HUB_EXPORT_AS_NEW_ASSET,
      RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION
    ];

    if (isAutomator(robot) && actionsNeedingCollectionAccess.includes(robot.action.type) && !isRobotBeingCreated) {
      return of((robot.action as ContentHubAction).canAccess === false);
    }

    return of(false);
  }
}
