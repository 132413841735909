import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { Color } from '../../../core/model';
import { ColorService } from '../../../shared/util/color.service';

@Component({
  selector: 'color-menu-item',
  templateUrl: './color-menu-item.component.html',
  styleUrls: ['./color-menu-item.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColorMenuItem {
  @HostBinding('class') public componentCls = 'color-menu-item';

  @Input() public currentColor: Color;
  @Input() public colors: Color[] = [];

  @Output() public readonly selected: EventEmitter<Color> = new EventEmitter<Color>();

  constructor(private colorService: ColorService) {
    this.colors = this.colorService.getPredefinedColors();
  }

  public selectColor(color: Color) {
    this.selected.emit(color);
  }

  public selectedColor(color: Color) {
    return ColorService.getColorAsRgbString(color) === ColorService.getColorAsRgbString(this.currentColor);
  }

  public convertColorDefinitionToColorString(color: Color): string {
    return color ? `rgb(${color.red},${color.green},${color.blue})` : 'rgb(41,182,246)';
  }

  public trackByFn(idx: number): number {
    return idx;
  }
}
