import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, from, of, timer } from 'rxjs';
import { map, retry, take } from 'rxjs/operators';

import { AuthService } from '@celum/authentication';
import {
  ColorConstants,
  IconConfiguration,
  ProgressSnackbar,
  RemoveSnackbar,
  ShowSnackbar,
  SimpleSnackbar,
  SnackbarButtonConfig,
  SnackbarConfiguration
} from '@celum/common-components';
import { CelumPropertiesProvider, ExecutableAction } from '@celum/core';
import { TenantService } from '@celum/work/app/core/auth/tenant.service';
import { AvatarService } from '@celum/work/app/core/avatar/avatar.service';
import { FileFormat, FileVersion } from '@celum/work/app/core/model/entities/file-version/file-version.model';
import { selectFileIdByFileVersionId } from '@celum/work/app/core/model/entities/file-version/file-version.selectors';
import { selectUiStateLanguage } from '@celum/work/app/core/ui-state/ui-state.selectors';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { FileUtilService } from '@celum/work/app/shared/util/file-util.service';

@Injectable({ providedIn: 'root' })
export class ExportAnnotatedPdfService {
  public static readonly appropriateFormats: FileFormat[] = [FileFormat.DOCUMENT, FileFormat.TEXT, FileFormat.IMAGE];

  constructor(
    private httpClient: HttpClient,
    private tenantService: TenantService,
    private avatarService: AvatarService,
    private fileUtilService: FileUtilService,
    private store: Store<any>,
    private authService: AuthService
  ) {}

  public export(fileVersion: FileVersion, strategy: 'commentsNearMarker' | 'commentsLast' = 'commentsLast'): void {
    combineLatest([
      this.authService.getAuthResult(),
      this.fileUtilService.getFileFormat(of(fileVersion)),
      this.fileUtilService.getFileView(of(fileVersion)),
      this.store.select(selectUiStateLanguage),
      this.store.select(selectCurrentWorkroom),
      this.store.select(selectFileIdByFileVersionId(fileVersion.id)),
      from(this.authService.getAuthResult()).pipe(map(result => result.idToken))
    ])
      .pipe(take(1))
      .subscribe(([authResult, fileFormat, fileView, lang, currentWorkroom, fileId, idToken]) => {
        const binaryType = this.resolveBinaryType(fileFormat);
        const tenant = this.tenantService.getStoredTenant();
        const binaryUrl = fileView.previewUrl;
        const accountAccessToken = this.avatarService.getAccountAccessTokenByAccountId(tenant);

        const body = {
          binaryType,
          binaryUrl,
          commentUrl: `${CelumPropertiesProvider.properties.httpBaseAddress}/comment/search/for-version`,
          commentAttachmentsUrl: `${CelumPropertiesProvider.properties.httpBaseAddress}/content/${currentWorkroom.id}/attachments`,
          fileVersionUrl: `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/file-version/file/${fileVersion.fileId}/version/${fileVersion.id}`,
          idToken,
          stampUrl: `${CelumPropertiesProvider.properties.httpBaseAddress}/file/${fileId}/version/${fileVersion.id}/marker/search`,
          avatarUrl: `${
            (window as any).Celum.properties.saccHttpBaseAddress
          }/api/accounts/${tenant}/users/profilePicture`,
          fileVersionId: fileVersion.id,
          workroomId: currentWorkroom.id,
          accountAccessToken,
          slibResourceToken: currentWorkroom.slibResourceToken,
          lang,
          strategy
        };

        const exportId = `export_pdf_${fileVersion.id}_${new Date().getTime()}`;

        const snackbarConfig: SnackbarConfiguration = SnackbarConfiguration.progress(
          'FILES.EXPORT_ANNOTATED_PDF.PROGRESS'
        );
        this.store.dispatch(new ShowSnackbar(exportId, ProgressSnackbar, snackbarConfig));

        const retryCount = 300;
        return this.httpClient
          .post((window as any).Celum.properties.exportPdfBaseAddress, body, {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${authResult.idToken}`,
              'X-Tenant': tenant,
              'Content-Type': 'application/json'
            }
          })
          .pipe(
            retry({
              count: retryCount,
              delay: (error: HttpErrorResponse) => {
                if ([502, 503, 504].includes(error.status)) {
                  return timer(5000); // delay retry by 5 sec
                }

                throw error;
              }
            })
          )
          .subscribe(
            (res: any) => {
              FileUtilService.downloadBlob(res, this.getExportedFileName(fileVersion));
              this.store.dispatch(new RemoveSnackbar(exportId));
              this.store.dispatch(
                new ShowSnackbar(
                  exportId,
                  SimpleSnackbar,
                  SnackbarConfiguration.success('FILES.EXPORT_ANNOTATED_PDF.SUCCESS').withIcon(
                    IconConfiguration.small('download-m')
                  )
                )
              );
            },
            _ => {
              const retryButton: SnackbarButtonConfig = {
                action: new ExecutableAction(() => {
                  this.store.dispatch(new RemoveSnackbar(exportId));
                  this.export(fileVersion, strategy);
                }, 'RETRY'),
                text: 'COMMON.RETRY',
                icon: IconConfiguration.medium('retry-m').withColor('#fff'),
                buttonColor: ColorConstants.ERROR,
                identifier: 'retry'
              };
              this.store.dispatch(new RemoveSnackbar(exportId));
              this.store.dispatch(
                new ShowSnackbar(
                  exportId,
                  SimpleSnackbar,
                  SnackbarConfiguration.error('FILES.EXPORT_ANNOTATED_PDF.FAILED')
                    .withIcon(IconConfiguration.small('download-m'))
                    .withActionButtons([retryButton])
                )
              );
            }
          );
      });
  }

  private getExportedFileName(fileVersion: FileVersion): string {
    const extensionStartingIndex = fileVersion.name.lastIndexOf('.');
    const fileNameWithoutExtension = fileVersion.name.substring(0, extensionStartingIndex);
    return `${fileNameWithoutExtension}.pdf`;
  }

  private resolveBinaryType(format: FileFormat): 'image' | 'pdf' {
    switch (format) {
      case FileFormat.IMAGE:
        return 'image';
      case FileFormat.DOCUMENT:
      case FileFormat.TEXT:
        return 'pdf';
      default:
        throw new Error('Unsupported format');
    }
  }
}
