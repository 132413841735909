import { createAction, props } from '@ngrx/store';

import { ContentItem } from '../../core/model/entities/content-item/content-item.model';
import { File } from '../../core/model/entities/file/file.model';

export class ContentHubExportActions {
  public static RequestExportOfFilesAsNewAssets = createAction(
    '[Content Hub] Request export of files as new assets',
    props<{ files: ContentItem[] }>()
  );

  public static RequestExportOfFileAsNewVersionOfAnotherAsset = createAction(
    '[Content Hub] Request export of file as new version of another asset',
    props<{ file: ContentItem }>()
  );

  public static RequestExportOfFilesAsNewVersionsOfCopiedAssets = createAction(
    '[Content Hub] Request export of files as new versions of copied assets',
    props<{ files: File[] }>()
  );

  public static ExportFilesAsNewAssets = createAction(
    '[Content Hub] Export files as new assets',
    props<{
      libraryId: string;
      targetId: number;
      fileIds: string[];
      fileIdToProgressTaskIdMap: { [id: number]: string };
    }>()
  );

  public static ExportFileAsNewVersion = createAction(
    '[Content Hub] Export file as new version of another asset',
    props<{ file: ContentItem; targetId: number; progressTaskId: string }>()
  );

  public static CheckForExportAssetSuccess = createAction(
    '[Content Hub] Check For Export Asset success',
    props<{ progressTaskId: string }>()
  );

  public static ExportAssetSuccess = createAction(
    '[Content Hub] Export Asset success',
    props<{ fileId: string; assetId: number }>()
  );
}
