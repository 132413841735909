<celum-dialog class="import-to-workrooms" (onCloseAll)="closeDialog()">
  <celum-dialog-header>{{ 'IMPORT_TO_WORKROOMS.DIALOG.HEADER' | translate }}</celum-dialog-header>
  <celum-dialog-content>
    <div class="import-to-workrooms_content_permission-wrapper" *ngIf="isTeamspacePermissionsLoaded | async">
      <div class="import-to-workrooms_content" *ngIf="paginationResult$ | async as paginationResult">
        <div class="import-to-workrooms_content_new-workroom" *ngIf="hasWorkroomCreatorRole$ | async">
          <div class="import-to-workrooms_content_new-workroom_label">
            <span class="import-to-workrooms_content_new-workroom_label_text">
              {{'IMPORT_TO_WORKROOMS.DIALOG.CREATE_AND_IMPORT_TO_NEW_WORKROOM' | translate}}
            </span>
            <span class="import-to-workrooms_content_new-workroom_label_icon" [matTooltip]="'ADD_TO_FOLDER.DIALOG.CONFIRM_SUBMENU.ADD_AND_WATCH_COLLECTION_INFO' | translate">
              <celum-icon [configuration]="infoIconSmall"></celum-icon>
            </span>
          </div>
          <div>
            <workroom-create-options-button
                [teamspace]="teamspace$ | async"
                [createDefaultWorkroomText]="createDefaultWorkroomText"
                [createWorkroomFromTemplateText]="createWorkroomFromTemplateText"
                [showCreateGroup]="false"
                [isDisableAllowed]="true">
            </workroom-create-options-button>
          </div>
        </div>

        <div
            class="import-to-workrooms_content_existing-workroom-list"
            *ngIf="existingWorkroomsInfoAndSearchEnabled | async">
          <div class="import-to-workrooms_content_existing-workroom-list_text">
            <div class="import-to-workrooms_content_existing-workroom-list_text_info">
              <span class="import-to-workrooms_content_existing-workroom-list_text_info_label">
                {{'IMPORT_TO_WORKROOMS.DIALOG.IMPORT_TO_EXISTING_WORKROOM' | translate}}
              </span>
              <span class="import-to-workrooms_content_existing-workroom-list_text_info_icon" [matTooltip]="'IMPORT_TO_WORKROOMS.DIALOG.INFO_TOOLTIP' | translate">
                <celum-icon [configuration]="infoIconSmall"></celum-icon>
              </span>
            </div>
            <span class="import-to-workrooms_content_existing-workroom-list_text_counter">
              <span class="strong"> {{ paginationResult.totalElementCount }}</span>
              <span> {{ ('IMPORT_TO_WORKROOMS.DIALOG.WORKROOMS.' + (paginationResult.totalElementCount > 1 ? 'PLURAL' : 'SINGULAR')) | translate }} </span>
            </span>
          </div>

          <search-field
              class="import-to-workrooms_content_existing-workroom-list_search"
              *ngIf="paginationResult.totalElementCount > 0 || queryString"
              [placeholder]="'IMPORT_TO_WORKROOMS.DIALOG.SEARCH_FOR_WORKROOM'"
              (valueChange)="onValueChanged($event)">
          </search-field>
        </div>

        <celum-simple-list
            class="import-to-workrooms_content_workrooms-list"
            *ngIf="paginationResult.totalElementCount > 0"
            [clearSelectionOnEmptySpaceClick]="true"
            [itemTemplate]="itemTemplate"
            [items]="workrooms$ | async"
            [hasMoreBottom]="hasMore$ | async"
            [trackByFn]="trackByFn"
            (requestNextPage)="onRequestNextPage()">
        </celum-simple-list>

        <ng-template let-item #itemTemplate>
          <workroom-card
              class="workroom-card"
              [class.workroom-card-selected]="item.id === selectedWorkroom?.id"
              [workroom]="item"
              [teamspace]="teamspace$ | async"
              [selected]="item.id === selectedWorkroom?.id"
              (workroomClicked)="workroomSelected($event)">
          </workroom-card>
        </ng-template>

        <empty-page
            *ngIf="(workroomStore.loading$ | async) === false && (paginationResult.totalElementCount === 0)"
            class="import-to-workrooms_empty_page"
            [config]="showEmptyPage$ | async ">
        </empty-page>
      </div>
    </div>
    <loading-mask *ngIf="(isTeamspacePermissionsLoaded | async) === false || (workroomStore.loading$ | async)" class="import-to-workrooms_loading-mask"></loading-mask>
  </celum-dialog-content>

  <celum-dialog-footer class="import-to-workrooms_footer" [color]="color">
    <button class="import-to-workrooms_footer_button import-to-workrooms_footer_button--dark" mat-button [disableRipple]="true"
            (click)="closeDialog()">
      <celum-icon [clickable]="true" [configuration]="cancelIcon"></celum-icon>
      {{ 'COMMON.CANCEL' | translate | uppercase}}
    </button>

    <button class="import-to-workrooms_footer_button"
              [disabled]="!selectedWorkroom"
              [disableRipple]="true"
              mat-button
              (click)="onConfirmClick()">
        <celum-icon [clickable]="true" [configuration]="submitIcon"></celum-icon>
        {{ 'IMPORT_TO_WORKROOMS.DIALOG.CONFIRM' | translate | uppercase}}
      </button>
  </celum-dialog-footer>
</celum-dialog>
