import { createSelector } from '@ngrx/store';

import { selectTaskDetailsState } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/store/task-detail.state';

export const selectCustomFormStateState = createSelector(selectTaskDetailsState, state => state.forms);

export const selectFormPanelExpandedState = createSelector(selectCustomFormStateState, state => state.expanded);

export const selectTaskFormId = createSelector(selectCustomFormStateState, state => state.formId);

export const selectTaskFormName = createSelector(selectCustomFormStateState, state => state.formName);

export const selectTaskFormFields = createSelector(selectCustomFormStateState, state => state.formFields);
export const selectTaskFormFieldById = (id: number) =>
  createSelector(selectTaskFormFields, fields => fields.find(field => field.customFieldId === id));

export const selectTaskFormLoading = createSelector(selectCustomFormStateState, state => state.loading);
