import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';

import { ColorConstants } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { TeamspaceService } from '@celum/work/app/core/api/teamspace/teamspace.service';
import { UiStateAccountAccessesLoad } from '@celum/work/app/core/ui-state/ui-state.actions';
import { AccountAccess, hasActiveLicense, LicenseTypes } from '@celum/work/app/core/ui-state/ui-state.model';
import { ProgressTaskActions } from '@celum/work/app/progress-task/store/progress-task.actions';
import { selectActiveProgressTasks } from '@celum/work/app/progress-task/store/progress-task.selectors';
import {
  ConfirmationDialog,
  ConfirmationDialogConfiguration
} from '@celum/work/app/shared/components/confirmation-dialog/confirmation-dialog.component';

import { LocalStorageService } from '..';
import { selectAccountAccesses } from '../ui-state/ui-state.selectors';

@Injectable({ providedIn: 'root' })
export class TenantService {
  public static LOCAL_STORAGE_KEY = 'tenant';
  private lazilyResolvedStoredTenant: string = null;

  constructor(
    private store: Store,
    private localStorageService: LocalStorageService,
    private teamspaceService: TeamspaceService,
    private injector: Injector,
    private translate: TranslateService
  ) {}

  public switchTenant(accountId: string) {
    this.store
      .select(selectActiveProgressTasks)
      .pipe(
        take(1),
        withLatestFrom(
          this.store
            .select(selectAccountAccesses)
            .pipe(map(accountAccesses => accountAccesses.find(accountAccess => accountAccess.accountId === accountId)))
        )
      )
      .subscribe(async ([activeProgressTasks, accountAccess]) => {
        if (!accountAccess) {
          console.error('could not find tenant ', accountId);
          return;
        }

        const changeTenant = activeProgressTasks.length < 1 || (await this.shouldCancelActiveProgressTasks());

        if (!changeTenant) {
          return;
        }

        activeProgressTasks.forEach(progressTask => this.store.dispatch(ProgressTaskActions.Cancel({ progressTask })));
        this.store
          .select(selectActiveProgressTasks)
          .pipe(
            filter(progressTasks => progressTasks.length < 1),
            take(1)
          )
          .subscribe(() => this.navigateWithTenant(accountId, '/dashboard'));
      });
  }

  public storeTenant(tenant: string) {
    if (!tenant) {
      return;
    }
    this.localStorageService.setItem(TenantService.LOCAL_STORAGE_KEY, tenant);
  }

  public removeStoredTenant() {
    this.localStorageService.removeItem(TenantService.LOCAL_STORAGE_KEY);
  }

  public getStoredTenant(): string {
    if (this.lazilyResolvedStoredTenant === null) {
      this.lazilyResolvedStoredTenant = this.localStorageService.getItem(TenantService.LOCAL_STORAGE_KEY) ?? undefined;
    }
    return this.lazilyResolvedStoredTenant;
  }

  // If you need to get tenant in application level, use getStoredTenant
  public getTenantFromUrl(): string {
    const tenantRegex = '.*tenant/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})';
    return window.location.href.match(tenantRegex)?.[1];
  }

  public resolveCurrentOrDefault(): Observable<string> {
    const localStorageTenant: string = this.getStoredTenant();
    if (localStorageTenant) {
      return of(localStorageTenant);
    }
    return this.getAccountAccesses().pipe(
      map(accountAccesses => this.filterAvailableTenants(accountAccesses).pop()),
      filter(accountAccess => !!accountAccess),
      take(1)
    );
  }

  public getAvailableTenants(): Observable<string[]> {
    return this.getAccountAccesses().pipe(
      map(accountAccesses => this.filterAvailableTenants(accountAccesses)),
      take(1)
    );
  }

  public resolveByWorkroomId(workroomId: number): Observable<string> {
    return this.teamspaceService.resolveByWorkroomId(workroomId).pipe(map(teamspace => teamspace.externalId));
  }

  public navigateWithTenant(tenant: string, relativePath: string) {
    this.storeTenant(tenant);
    window.location.assign(`${window.location.protocol}//${window.location.host}/tenant/${tenant}${relativePath}`);
  }

  public getAccountAccesses(): Observable<AccountAccess[]> {
    return this.store.select(selectAccountAccesses).pipe(
      tap(accountAccesses => {
        if (accountAccesses === null) {
          this.store.dispatch(UiStateAccountAccessesLoad());
        }
      }),
      filter(accountAccesses => !!accountAccesses),
      map(accountAccesses =>
        [...accountAccesses].sort((account1, account2) => account1.accountName.localeCompare(account2.accountName))
      )
    );
  }

  private filterAvailableTenants(accountAccesses: AccountAccess[]) {
    return accountAccesses
      .filter(
        accountAccess =>
          accountAccess.status === 'ACTIVE' && hasActiveLicense(accountAccess.licenses, LicenseTypes.WorkroomsLicense)
      )
      .map(accountAccess => accountAccess.accountId);
  }

  private shouldCancelActiveProgressTasks() {
    // Need to use injector, otherwise getting circular DI error for no apparent reason
    const dialogOpener = this.injector.get(CelumDialogOpener);
    const args = {
      header: this.translate.instant('CHANGE_TENANT_DIALOG.HEADLINE'),
      question: this.translate.instant('CHANGE_TENANT_DIALOG.MESSAGE'),
      confirmBtn: this.translate.instant('CHANGE_TENANT_DIALOG.CONFIRM')
    };
    const config = new ConfirmationDialogConfiguration(
      args.header,
      args.question,
      ColorConstants.WARNING,
      args.confirmBtn
    );
    return dialogOpener.showDialog('tenant-change', ConfirmationDialog, config);
  }
}
