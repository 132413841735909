<browsing-dialog class="select-node-dialog"
                 [title]="title"
                 [color]="color"
                 [drawerOpened]="(collections$ | async).length > 0"
                 [showContentHeader]="(collections$ | async).length > 0"
                 [submitDisabled]="(activeFolder$ | async) === null"
                 [submitIcon]="submitIcon"
                 [submitText]="submitButtonText"
                 [showEmptyPage]="missingContentHubUser$ | async"
                 [emptyPageConfig]="missingContentHubUserEmptyPageConfig"
                 (onClose)="closeDialog()"
                 (onSubmit)="onSubmit()">

  <ng-container sideContent>
    <collection-list [collections]="collections$ | async"
                     [selectedCollection]="selectedCollection"
                     (collectionSelected)="changeCollection($event)">
    </collection-list>
  </ng-container>

  <ng-container sectionContent>
    <files-tree [activeFolder]="activeFolder$ | async"
                [folders]="children$ | async"
                [paginationInformation]="paginationInformation$ | async"
                [sortFolders]="false"
                [isStrict]="isStrict"
                (expand)="loadChildren($event)"
                (selectionChanged)="selectedFolderChanged($event[0])"
                (fetchMore)="loadMoreForFolder($event)">
    </files-tree>
  </ng-container>
</browsing-dialog>
