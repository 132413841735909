import { createAction, props } from '@ngrx/store';

import { PaginationResult } from '@celum/core';
import { Task } from '@celum/work/app/core/model/entities/task';

import { Subtask } from '../../model/entities/subtask/subtask.model';

export const SubtaskLoadSubtasks = createAction(
  '[Subtask] Load Subtasks',
  props<{ taskId: number; offset: number; limit?: number }>()
);
export const SubtaskFetchNextBatchSubtasks = createAction(
  '[Subtask] Fetch Next Batch Subtasks',
  props<{ taskId: number }>()
);
export const SubtaskLoadSubtasksSucceeded = createAction(
  '[Subtask] Load Subtasks Succeeded',
  props<{ taskId: number; subtasks: Subtask[]; paginationResult: PaginationResult; resetState: boolean }>()
);
export const SubtaskLoadSubtasksFailed = createAction('[Subtask] Load Subtasks Failed', props<{ taskId: number }>());

export const SubtaskCreateSubtask = createAction('[Subtask] Create Subtask', props<{ tmpSubtask: Subtask }>());
export const SubtaskCreateSubtaskSucceeded = createAction(
  '[Subtask] Create Subtask Succeeded',
  props<{ tmpSubtask: Subtask; subtask: Subtask }>()
);
export const SubtaskCreateSubtaskFailed = createAction(
  '[Subtask] Create Subtask Failed',
  props<{ tmpSubtask: Subtask }>()
);

export const SubtaskUpdateSubtask = createAction('[Subtask] Update Subtask', props<{ subtask: Subtask }>());
export const SubtaskUpdateSubtaskSucceeded = createAction(
  '[Subtask] Update Subtask Succeeded',
  props<{ oldSubtask: Subtask; subtask: Subtask }>()
);
export const SubtaskUpdateSubtaskFailed = createAction(
  '[Subtask] Update Subtask Failed',
  props<{ oldSubtask: Subtask }>()
);

export const SubtaskDeleteSubtask = createAction('[Subtask] Delete Subtask', props<{ subtask: Subtask }>());
export const SubtaskDeleteSubtaskSucceeded = createAction(
  '[Subtask] Delete Subtask Succeeded',
  props<{ subtask: Subtask }>()
);
export const SubtaskDeleteSubtaskFailed = createAction(
  '[Subtask] Delete Subtask Failed',
  props<{ subtask: Subtask }>()
);
export const OpenSubtaskConvertToTaskDialog = createAction(
  '[Subtask] Open Convert To Task Dialog',
  props<{ subtask: Subtask }>()
);
export const SubtaskConvertToTaskConfirmed = createAction(
  '[Subtask] Convert To Task Confirmed',
  props<{ subtask: Subtask }>()
);
export const SubtaskConvertToTaskCanceled = createAction('[Subtask] Convert To Task Canceled');

export const SubtaskConvertToTask = createAction(
  '[Subtask] Convert To Task',
  props<{ subtask: Subtask; taskListId: number }>()
);
export const SubtaskConvertToTaskSucceeded = createAction(
  '[Subtask] Convert To Task Succeeded ',
  props<{ subtask: Subtask; task: Task }>()
);
export const SubtaskConvertToTaskFailed = createAction(
  '[Subtask] Convert To Task Failed',
  props<{ subtask: Subtask }>()
);

export const SubtaskAssignPerson = createAction(
  '[Subtask] Assign Person To Subtask',
  props<{ subtask: Subtask; personId: number }>()
);
export const SubtaskAssignPersonSuccess = createAction(
  '[Subtask] Assign Person To Subtask Success',
  props<{ subtask: Subtask; personId: number }>()
);

export const SubtaskAssignPersonFailure = createAction(
  '[Subtask] Assign Person To Subtask Failure',
  props<{ subtask: Subtask }>()
);

export const SubtaskUnassignPerson = createAction(
  '[Subtask] Unassign Person From Subtask',
  props<{ subtask: Subtask; personId: number }>()
);

export const SubtaskUnassignPersonSuccess = createAction(
  '[Subtask] Unassign Person From Subtask Success',
  props<{ subtask: Subtask; personId: number }>()
);

export const SubtaskUnassignPersonFailure = createAction(
  '[Subtask] Unassign Person From Subtask Failure',
  props<{ subtask: Subtask }>()
);

export const SubtaskHandleAssignee = createAction(
  '[Subtask] Handle Assignee',
  props<{ entityId: number; assigneeId: number; operation: 'add' | 'remove' }>()
);
