import Quill from 'quill';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';

import { supportedFileTypes } from '../supported-files';

export class QuillImageDropAndPasteExtended extends QuillImageDropAndPaste {
  public actionOnUnsupportedFilePaste: () => void;
  public actionOnUploadDisabled: () => void;

  private readonly canUpload: boolean = true;

  constructor(quill: Quill, option: QuillImageDropAndPasteExtendedOptions) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super(quill, option);
    this.actionOnUnsupportedFilePaste = option.actionOnUnsupportedFilePaste;
    this.actionOnUploadDisabled = option.actionOnUploadDisabled;
    this.canUpload = option.canUpload;
  }

  public handleDrop(e: DragEvent): void {
    if (!this.canUpload) {
      return this.actionOnUploadDisabled();
    }

    super.handleDrop(e);
  }

  public handleDataTransfer(
    file: DataTransferItem,
    callback: (dataUrl: string | ArrayBuffer, type: string, name?: string) => void,
    e: ClipboardEvent | DragEvent
  ): void {
    // when we paste links here it fires, we need to skip checks for this
    if (file.kind === 'string') {
      return;
    }

    if (!this.canUpload) {
      return this.actionOnUploadDisabled();
    }

    if (file.kind === 'file' && !supportedFileTypes.includes(file.type)) {
      this.actionOnUnsupportedFilePaste();
    } else {
      super.handleDataTransfer(file, callback, e);
    }
  }
}

export interface QuillImageDropAndPasteExtendedOptions {
  canUpload: boolean;

  handler?: (dataUrl: string | ArrayBuffer, type?: string, imageData?: ImageData) => void;
  actionOnUnsupportedFilePaste: () => void;
  actionOnUploadDisabled: () => void;
}
