import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { Marker, MediaMarker, PdfView, Settings, View, ViewTypes } from '@celum/annotations';
import { UUIDGenerator } from '@celum/core';
import { ContentItemOrigin } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { RenditionTypes } from '@celum/work/app/core/model/entities/file/file.model';
import { FileFormat, FileVersion } from '@celum/work/app/core/model/entities/file-version/file-version.model';
import { Rendition } from '@celum/work/app/core/model/entities/rendition/rendition.model';
import { Workroom } from '@celum/work/app/core/model/entities/workroom';
import { DownloadUrlUtil } from '@celum/work/app/shared/util/download-url-util';
import { PreviewUtil } from '@celum/work/app/shared/util/preview-util';
import { WorkroomConstants } from '@celum/work/app/shared/util/workroom-constants';

export type SupportedDto = Marker | MediaMarker;

@Injectable({ providedIn: 'root' })
export class AnnotationUtilService {
  public SKETCHFAB_QUERY_PARAMS =
    '?autospin=-0.1' +
    '&autostart=1' +
    '&preload=1' +
    '&transparent=1' +
    '&ui_animations=0' +
    '&ui_annotations=0' +
    '&ui_ar=0' +
    '&ui_ar_help=0' +
    '&ui_color=white' +
    '&ui_help=0' +
    '&ui_infos=0' +
    '&ui_stop=0' +
    '&ui_theme=dark' +
    '&ui_watermark=0';

  constructor(private http: HttpClient) {}

  public createAnnotationsConfig(personId: number, isModerator: boolean): Settings {
    const errorLogLevel = 0;
    return {
      name: 'annotations_' + UUIDGenerator.generateId(),
      ignoreEditPermissions: isModerator,
      user: String(personId),
      usePdfs: true,
      pdfJsLogLevel: errorLogLevel,
      pdfCmapsPath: '/worker-libs/pdf-cmaps',
      pdfWorkerPath: `/worker-libs/pdf.worker.min.js?t=${VERSION}`
    };
  }

  public getUserMarkers(markers: Marker[], loggedInPersonId: number): string[] {
    return markers.filter(m => String(m.creator) === String(loggedInPersonId)).map(m => m.id);
  }

  public getFileView(
    fileVersion: FileVersion,
    currentWorkroom: Workroom,
    renditions: Rendition[],
    lang: string
  ): Promise<View> {
    // for deleted audio we treat preview as image to overcome annotation trying to load that audio into the player
    const chFileDeleted = renditions.some(rendition =>
      rendition?.downloadUrl?.includes(WorkroomConstants.DISCONNECTED_FROM_CH)
    );
    let generatedView: View | Promise<View> = null;
    let format: FileFormat = this.getFileFormat(fileVersion, renditions);

    if (chFileDeleted && [FileFormat.AUDIO, FileFormat.VIDEO].includes(format)) {
      format = FileFormat.IMAGE;
    }

    switch (format) {
      case FileFormat.VIDEO:
        generatedView = this.generateVideoPreview(renditions, fileVersion, lang);
        break;
      case FileFormat.AUDIO:
        generatedView = this.generateAudioPreview(currentWorkroom, renditions, fileVersion, lang);
        break;
      case FileFormat.DOCUMENT:
      case FileFormat.TEXT:
        generatedView = this.generateTextAndDocumentPreview(currentWorkroom, renditions, fileVersion, lang);
        break;
      case FileFormat.MODEL3D:
        generatedView = this.generate3DPreview(renditions, fileVersion, lang);
        break;
      default:
        generatedView = this.generateImagePreview(renditions, fileVersion, lang);
    }

    return Promise.resolve(generatedView).then((view: View) => {
      return {
        ...view,
        previewUrl: DownloadUrlUtil.resolveDownloadUrl(view.previewUrl, currentWorkroom)
      };
    });
  }

  public getFileFormat(fileVersion: FileVersion, renditions: Rendition[]): FileFormat {
    // FileVersion's format might be unknown if we just uploaded new version
    // Rendition's format might be unknown if we just opened the file details of a CH file
    return [renditions?.[0]?.originalFormat, fileVersion?.format]
      .filter(format => !!format && format !== FileFormat.UNKNOWN)
      .pop();
  }

  private generateVideoPreview(renditions: Rendition[], fileVersion: FileVersion, lang: string): View {
    const { name, uploadedDate } = fileVersion;
    const fileVersionId = String(fileVersion.id);

    return {
      id: fileVersionId,
      previewUrl: this.getRenditionURL(renditions, [RenditionTypes.VIDEO], name, lang, uploadedDate),
      viewType: ViewTypes.VideoView
    };
  }

  private async generate3DPreview(renditions: Rendition[], fileVersion: FileVersion, lang: string): Promise<View> {
    const fileVersionId = String(fileVersion.id);

    const mainRendition = renditions.find(rendition => rendition.type === RenditionTypes.TRIDIMENSIONAL);
    if (mainRendition) {
      const previewUrl = await this.getSketchFabUrl(mainRendition.downloadUrl);
      return {
        id: fileVersionId,
        previewUrl,
        viewType: ViewTypes.TridimensionalView
      };
    } else {
      return this.generateImagePreview(renditions, fileVersion, lang);
    }
  }

  private generateAudioPreview(
    currentWorkroom: Workroom,
    renditions: Rendition[],
    fileVersion: FileVersion,
    lang: string
  ): View {
    const { name, uploadedDate, origin } = fileVersion;
    const fileVersionId = String(fileVersion.id);

    const previewItem = {
      id: fileVersionId,
      previewUrl: this.getRenditionURL(
        renditions,
        [RenditionTypes.AUDIO, RenditionTypes.WAVEFORM],
        name,
        lang,
        uploadedDate
      ),
      viewType: ViewTypes.AudioView,
      soundWaveColoredUrl: WorkroomConstants.MP3_PLAYED,
      soundWaveUrl: PreviewUtil.getPreviewFile(name, WorkroomConstants.AUDIO_PREVIEW)
    };
    // Check if file come from SASS, then create a wave, otherwise mock because CH doesn't provide wave images for now
    // Also check if there is rendition with originalFormat audio (while renditions are not there, it is unknown)
    const audioFormat = rendition => rendition.originalFormat === FileFormat.AUDIO;
    if (origin === ContentItemOrigin.SASS && renditions.some(audioFormat)) {
      previewItem.soundWaveUrl = DownloadUrlUtil.resolveDownloadUrl(
        this.getRenditionURL(renditions, [RenditionTypes.BACKGROUND], name, lang, uploadedDate),
        currentWorkroom
      );
      previewItem.soundWaveColoredUrl = DownloadUrlUtil.resolveDownloadUrl(
        this.getRenditionURL(renditions, [RenditionTypes.OVERLAY], name, lang, uploadedDate),
        currentWorkroom
      );
    }

    return previewItem;
  }

  private generateTextAndDocumentPreview(
    currentWorkroom: Workroom,
    renditions: Rendition[],
    fileVersion: FileVersion,
    lang: string
  ): View | Promise<View> {
    const { name, uploadedDate, origin } = fileVersion;
    const fileVersionId = String(fileVersion.id);
    const pdfRenditions = renditions.filter(item =>
      [RenditionTypes.MULTIPAGE, RenditionTypes.PDF, RenditionTypes.ALTERNATIVE].some(key => key === item.type)
    );

    if (renditions?.length > 0 && !isEmpty(pdfRenditions)) {
      const pdfUrl = PreviewUtil.getFileDownloadURL(pdfRenditions[0], name, lang, uploadedDate);
      if (pdfUrl.includes(WorkroomConstants.NO_PREVIEW) || pdfUrl.includes(WorkroomConstants.FILE_DELETED)) {
        return this.generateImagePreview(renditions, fileVersion, lang);
      } else {
        return this.generatePdfPreviews(pdfUrl, fileVersionId, currentWorkroom, origin);
      }
    } else {
      return this.generateImagePreview(renditions, fileVersion, lang);
    }
  }

  private generateImagePreview(renditions: Rendition[], fileVersion: FileVersion, lang: string): View {
    const { name, uploadedDate } = fileVersion;
    const fileVersionId = String(fileVersion.id);
    const previewUrl = this.getRenditionURL(renditions, [RenditionTypes.LARGE], name, lang, uploadedDate);
    let viewType = ViewTypes.ImageView;

    // getRenditionURL either return the URl of the displayed image or a constant for noPreview/loading image
    // base on that we decide if we display the image normally on the canvas or through the NoPreviewView ViewType
    if ([WorkroomConstants.NO_PREVIEW_ASSET_PATH, WorkroomConstants.LOADING_PREVIEW_PATH].includes(previewUrl)) {
      viewType = ViewTypes.NoPreviewView;
    }

    return {
      id: fileVersionId,
      previewUrl,
      viewType
    };
  }

  private async generatePdfPreviews(
    pdfUrl: string,
    id: string,
    currentWorkroom: Workroom,
    origin: string
  ): Promise<PdfView> {
    const newPdfUrl = await this.convertUrl(pdfUrl, currentWorkroom, origin);

    return {
      id: `${id}_0`,
      previewUrl: newPdfUrl,
      viewType: ViewTypes.PdfView
    };
  }

  private async convertUrl(pdfUrl: string, currentWorkroom: Workroom, origin: string): Promise<string> {
    // checks to convert only urls that comes from sass
    if (origin === ContentItemOrigin.WORKROOMS) {
      return Promise.resolve(pdfUrl);
    }
    return firstValueFrom(
      this.http.get<{ baseUrl; sasToken }>(DownloadUrlUtil.resolveDownloadUrl(pdfUrl, currentWorkroom)).pipe(
        map(res => {
          return res.baseUrl.concat(res.sasToken);
        })
      )
    );
  }

  private async getSketchFabUrl(downloadUrl: string): Promise<string> {
    return firstValueFrom(
      this.http.get<{ embedUrl; modelId }>(downloadUrl).pipe(
        map(res => {
          const configuredSketchfabUrl = res.embedUrl + this.SKETCHFAB_QUERY_PARAMS;
          return configuredSketchfabUrl;
        })
      )
    );
  }

  private getRenditionURL(
    renditions: Rendition[],
    typeKeys: RenditionTypes[],
    name: string,
    lang: string,
    uploadedDate: number
  ): string {
    const foundRendition = renditions.filter(item => typeKeys.includes(item.type));
    const renditionsToSelect = isEmpty(foundRendition) ? renditions : foundRendition;

    return PreviewUtil.getFileDownloadURL(renditionsToSelect[0], name, lang, uploadedDate);
  }
}
