<celum-standard-dialog
    class="delete-workroom"
    [color]="color"
    [confirmBtnText]="'WORKROOM.DELETE.CONFIRM' | translate"
    (onCloseAll)="onCloseAllClicked()"
    (onConfirm)="onConfirmedClicked()">

  <div class="delete-workroom_delete">
    <celum-icon [configuration]="deleteIconConfig"></celum-icon>
  </div>

  <div class="delete-workroom_content_row">
    <div class="delete-workroom_title">{{ 'WORKROOM.DELETE.TITLE' | translate }}</div>
    <div class="delete-workroom_body">
      <div>{{ 'WORKROOM.DELETE.BODY_ROW1' | translate}}</div>
    </div>

  </div>

</celum-standard-dialog>
