import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CelumPropertiesProvider, PaginationResult } from '@celum/core';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo, Paging, Sorting } from '@celum/work/app/core/model';
import { RenditionType } from '@celum/work/app/core/model/entities/rendition/rendition.model';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

import { FileVersion, FileVersionType } from '../../model/entities/file-version/file-version.model';

export interface FileVersionSearchBody {
  fileId: string;
  paging?: Paging;
  sorting?: Sorting;
}

@Injectable({
  providedIn: 'root'
})
export class FileVersionService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public loadFileVersions(
    workroomId: number,
    searchArgs: FileVersionSearchBody
  ): Observable<{ fileVersions: FileVersion[]; paginationResult: PaginationResult }> {
    const resultsSchema = {
      results: [FileVersionType.instance().getSchema()]
    };

    const metaInfo = MetaInfo.of(
      [FileVersionType.TYPE_KEY, RenditionType.TYPE_KEY],
      resultsSchema,
      [FileVersionType.TYPE_KEY],
      'results'
    );

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/file-version/${workroomId}/search`, searchArgs)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            fileVersions: entitiesResult.entities[FileVersionType.TYPE_KEY] as FileVersion[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public deleteFileVersion(workroomId: number, fileId: string, versionId: string): Observable<FileVersion> {
    return this.httpClient.delete<FileVersion>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/file-version/${workroomId}/file/${fileId}/version/${versionId}`,
      STRONGLY_CONSISTENT_OPTION
    );
  }

  public makeVersionActive(workroomId: number, fileId: string, versionId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/file-version/${workroomId}/file/${fileId}/version/${versionId}/active`,
      {}
    );
  }

  public getFileVersion(fileId: string, versionId: string): Observable<FileVersion> {
    const metaInfo = MetaInfo.of(
      [FileVersionType.TYPE_KEY, RenditionType.TYPE_KEY],
      FileVersionType.instance().getSchema()
    );
    return this.httpClient
      .get<FileVersion>(
        `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/file-version/file/${fileId}/version/${versionId}`
      )
      .pipe(tap(res => this.resultConsumerService.translateAndAddToStore(res, metaInfo)));
  }
}
