import { LocalizedValue } from '@celum/core';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = ''
}

export interface SortField {
  id: string;
  label: string | LocalizedValue;
  locale?: string;
  /**
   * Provide "true" to hide this field from the available sort fields in the sorter. If it is defined as selected, it will be shown as selected, but not appear
   * in the list of available sort options.
   */
  disabled?: boolean;
}

export interface SortValue<F extends SortField = SortField> {
  sortField: F;
  sortDirection: SortDirection;
}
