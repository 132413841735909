import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { EntityTranslator } from '@celum/work/app/core/model';
import { selectPersonEntities } from '@celum/work/app/core/model/entities/person';
import { selectTaskListById } from '@celum/work/app/core/model/entities/task/task-list.selectors';

import { TaskList, TaskListType } from './task-list.model';

export class TaskListTranslator implements EntityTranslator<TaskList> {
  public translateToEntity(json: any): TaskList {
    const color = json.color
      ? {
          red: json.color.red,
          green: json.color.green,
          blue: json.color.blue
        }
      : null;

    const taskId = json.id;
    return {
      id: taskId,
      name: json.name,
      dueDate: json.dueDate,
      color,
      workroomId: json.workroomId,
      sort: json.sort,
      entityType: TaskListType.instance(),
      ownerIds: json.ownerIds,
      owners: (store: Store<any>) =>
        combineLatest([store.select(selectTaskListById(taskId)), store.select(selectPersonEntities)]).pipe(
          filter(([taskList]) => !!taskList),
          map(([taskList, persons]) => taskList.ownerIds.map(id => persons[id]).filter(person => !!person))
        )
    };
  }
}
