import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CelumPropertiesProvider } from '@celum/core';

import { Folder } from '../../core/model/entities/folder/folder.model';
import { ListResult } from '../../core/model/list-result';

@Injectable({ providedIn: 'root' })
export class NodeService {
  constructor(private http: HttpClient) {}

  public getRootNodes(
    repositoryId: string,
    nodeTypeId: number,
    page: number,
    pageSize: number
  ): Observable<ListResult<Folder>> {
    const params: any = { repositoryId, nodeTypeId, page, pageSize };
    return this.http.get<ListResult<Folder>>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content-hub/nodes/roots`,
      { params }
    );
  }

  public getNodes(
    repositoryId: string,
    parentId: number,
    page: number,
    pageSize: number
  ): Observable<ListResult<Folder>> {
    const params: any = { repositoryId, parentId, page, pageSize };
    return this.http.get<ListResult<Folder>>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content-hub/nodes`,
      { params }
    );
  }

  public getNode(repositoryId: string, nodeId: number): Observable<Folder> {
    const params = { repositoryId };
    return this.http.get<Folder>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content-hub/nodes/${nodeId}`,
      { params }
    );
  }
}
