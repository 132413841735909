import { createAction, props } from '@ngrx/store';

import { ImportParams } from '@celum/work/app/content-hub/model/import.model';
import { File } from '@celum/work/app/core/model/entities/file/file.model';
import { Folder } from '@celum/work/app/core/model/entities/folder/folder.model';
import { TaskCreationContext } from '@celum/work/app/core/model/entities/task';
import { CreateTaskDialogConfigurationParams } from '@celum/work/app/pages/workroom/pages/files/components/create-task-dialog/create-task-dialog.component';

export class CreateTaskDialogActions {
  public static OpenTaskCreationDialogForFiles = createAction(
    '[Create Task Dialog] Open task creation dialog for files',
    props<{ createTaskDialogParams: CreateTaskDialogConfigurationParams }>()
  );

  public static OpenTaskCreationDialogForImportIntentAssets = createAction(
    '[Create Task Dialog] Open task creation dialog for import intent assets',
    props<{ importIntentId: string; importIntentAssetCount: number; folder: Folder }>()
  );

  public static OpenTaskCreationDialogForAssets = createAction(
    '[Create Task Dialog] Open task creation dialog for assets',
    props<{ importParams: ImportParams; selectedAssets: File[] }>()
  );

  public static OpenTaskCreationDialogCanceled = createAction(
    '[Create Task Dialog] Open task creation dialog canceled',
    props<{ taskCreationContext: TaskCreationContext }>()
  );

  public static OpenTaskCreationDialogForPortalApproval = createAction(
    '[Create Task Dialog] Open task creation dialog for portal approval',
    props<{ portalId: string }>()
  );
}
