<div [class.ql-editor--description-editmode]="type === 'task-description' && isEditMode"
     [class.ql-editor--comment-editmode]="type === 'comment' && isEditMode">
  <quill-editor
    class="ql"
    id="scrolling-container"
    bounds="#scrolling-container"
    [class.ql--readonly]="readonly"
    [class.ql--show-all]="showAll"
    [class.ql--focused]="isFocused && !readonly"
    [class.ql--highlight]="uncommentedMarkersCount > 0"
    [readOnly]="readonly"
    format="json"
    [formats]="formats"
    [(ngModel)]="editorValueObject"
    [placeholder]="placeholder"
    [modules]="quillConfig"
    [maxLength]="quillMaxLength"
    (onContentChanged)="onContentChanged($event)"
    trackChanges="all"
    (onEditorCreated)="onEditorCreated()">

    <div
        quill-editor-toolbar class="ql-toolbar ql-snow"
        data-cy="quill-toolbar"
        [ngClass]="{'celum-quill-editor_description': type === 'task-description', 'celum-quill-editor_hidden': shouldHideToolbar()}"
        [attr.data-quill-length]="getQuillLength()">
      <ng-container *ngIf="type === 'workroom-description' || type === 'task-description' || type === 'other'" class="ql-formats">
        <select class="ql-header marker-trigger_headers">
          <option value="1">{{ 'WORKROOM_TASK.EDITOR.HEADING' | translate }}</option>
          <option value="2">{{ 'WORKROOM_TASK.EDITOR.SUBHEADING' | translate }}</option>
          <option value="">{{ 'WORKROOM_TASK.EDITOR.NORMAL' | translate }}</option>
        </select>
        <mat-divider class="marker-trigger_header-separator" [vertical]="true"></mat-divider>
      </ng-container>
      <span class="ql-formats">
          <button
              *ngIf="canUpload"
              [matTooltip]="'WORKROOM_TASK.EDITOR.ATTACH_IMAGE' | translate"
              data-cy="comments-attach-file">
            <celum-icon [configuration]="attachFileIcon" (click)="uploadImages()"></celum-icon>
          </button>
          <mat-divider *ngIf="withMarkers || canUpload" class="marker-trigger_separator" [vertical]="true"></mat-divider>
          <button class="ql-bold" [matTooltip]="'WORKROOM_TASK.EDITOR.BOLD' | translate"></button>
          <button class="ql-italic" [matTooltip]="'WORKROOM_TASK.EDITOR.ITALIC' | translate"></button>
          <button class="ql-underline" [matTooltip]="'WORKROOM_TASK.EDITOR.UNDERLINE' | translate"></button>
          <button class="ql-strike" [matTooltip]="'WORKROOM_TASK.EDITOR.STRIKETHROUGH' | translate"></button>
          <button class="ql-clean" [matTooltip]="'WORKROOM_TASK.EDITOR.CLEAN' | translate"></button>
          <button class="ql-link" [matTooltip]="'WORKROOM_TASK.EDITOR.LINK' | translate"></button>
          <button class="ql-list" value="bullet" [matTooltip]="'WORKROOM_TASK.EDITOR.BULLET_LIST' | translate"></button>
          <button class="ql-list" value="ordered" [matTooltip]="'WORKROOM_TASK.EDITOR.NUMBERED_LIST' | translate"></button>
    </span>

      <div class="ql-toolbar_ql-vx" *ngIf="isVXIconsVisible">
        <button
          class="ql-toolbar_v-icon"
          mat-button
          celum-square-button
          data-cy="quill-cancel"
          [matTooltip]="'COMMON.CANCEL' | translate"
          (click)="handleXClicked($event)">
          <celum-icon data-action-id="cancel" [configuration]="xIcon"></celum-icon>
        </button>

        <button
          class="inline-textarea_button"
          mat-button
          celum-square-button
          data-cy="quill-confirm"
          [matTooltip]="'COMMON.SAVE' | translate"
          (click)="handleVClicked($event)">
          <celum-icon data-action-id="save" [configuration]="vIcon"></celum-icon>
        </button>

      </div>
    </div>
  </quill-editor>
  <div *ngIf="readonly && displayShowMore" class="celum-quill-editor_shadow"></div>

  <ng-template #personTemplate let-person="element">
    <search-and-select-person [person]="person" [avatarDecorator]="avatarDecorator$"></search-and-select-person>
  </ng-template>
</div>
