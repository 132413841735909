import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map, of, switchMap, takeUntil } from 'rxjs';

import { ReactiveComponent } from '@celum/ng2base';
import { Roles } from '@celum/work/app/core/model';
import { InvitationStatus, MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { isNewUser } from '@celum/work/app/core/model/entities/person';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { PermissionUtil } from '@celum/work/app/shared/util';

import { WorkroomAvatarConfiguration } from '../components/workroom-avatar/workroom-avatar-configuration';

enum PersonStatus {
  PENDING_APPROVAL = 'PENDING',
  INVITED = 'INVITED',
  DEACTIVATED = 'DEACTIVATED',
  APPROVED = 'APPROVED'
}

@Directive({
  selector: '[celumAvatarTestingAttributes]',
  standalone: true
})
export class CelumAvatarTestingAttributesDirective extends ReactiveComponent implements OnChanges {
  @Input() private celumAvatarTestingAttributesConfig: WorkroomAvatarConfiguration;

  constructor(
    private elementRef: ElementRef,
    private permissionUtil: PermissionUtil,
    private store: Store<any>
  ) {
    super();
  }

  public ngOnChanges(): void {
    this.setStatusAttribute();
    this.setRoleAttribute().pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()).subscribe();
  }

  public setRoleAttribute() {
    const currentWorkroom$ = this.store.select(selectCurrentWorkroom);

    if (this.celumAvatarTestingAttributesConfig?.roles) {
      return of(this.applyRoleAttribute(this.celumAvatarTestingAttributesConfig.roles));
    } else if (this.celumAvatarTestingAttributesConfig?.person) {
      return currentWorkroom$.pipe(
        switchMap(currentWorkroom =>
          this.permissionUtil.getWorkroomRoles(this.celumAvatarTestingAttributesConfig.person, currentWorkroom).pipe(
            map(roles => {
              return this.applyRoleAttribute(roles);
            })
          )
        )
      );
    } else {
      return of(null);
    }
  }

  private applyRoleAttribute(roles: Roles[]): void {
    if (roles.includes(Roles.MODERATOR)) {
      this.elementRef.nativeElement.setAttribute('data-person-role', Roles.MODERATOR);
    } else if (roles.includes(Roles.VISITOR)) {
      this.elementRef.nativeElement.setAttribute('data-person-role', Roles.VISITOR);
    } else if (roles.includes(Roles.CONTRIBUTOR)) {
      this.elementRef.nativeElement.setAttribute('data-person-role', Roles.CONTRIBUTOR);
    }
  }

  private setStatusAttribute() {
    if (this.celumAvatarTestingAttributesConfig?.member?.status === MembershipStatus.INACTIVE) {
      this.elementRef.nativeElement.setAttribute('data-person-status', PersonStatus.DEACTIVATED);
    } else if (isNewUser(this.celumAvatarTestingAttributesConfig?.person)) {
      this.elementRef.nativeElement.setAttribute('data-person-status', PersonStatus.INVITED);
    } else if (this.celumAvatarTestingAttributesConfig?.person?.invitationStatus) {
      switch (this.celumAvatarTestingAttributesConfig?.person?.invitationStatus) {
        case InvitationStatus.PENDING_APPROVAL:
          this.elementRef.nativeElement.setAttribute('data-person-status', PersonStatus.PENDING_APPROVAL);
          break;
        case InvitationStatus.INVITED:
          this.elementRef.nativeElement.setAttribute('data-person-status', PersonStatus.INVITED);
          break;
      }
    } else {
      switch (this.celumAvatarTestingAttributesConfig?.member?.invitationStatus) {
        case InvitationStatus.PENDING_APPROVAL:
          this.elementRef.nativeElement.setAttribute('data-person-status', PersonStatus.PENDING_APPROVAL);
          break;
        case InvitationStatus.INVITED:
          this.elementRef.nativeElement.setAttribute('data-person-status', PersonStatus.INVITED);
          break;
        default:
          this.elementRef.nativeElement.setAttribute('data-person-status', PersonStatus.APPROVED);
          break;
      }
    }
  }
}
