import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ReactiveComponent } from '@celum/ng2base';
import { Roles } from '@celum/work/app/core/model';
import { InvitationStatus, MembershipStatus } from '@celum/work/app/core/model/entities/member';

@Component({
  selector: 'person-label',
  templateUrl: './person-label.component.html',
  styleUrls: ['./person-label.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule]
})
export class PersonLabelComponent extends ReactiveComponent implements OnInit, OnChanges {
  @Input() public roles: Roles[];
  @Input() public status: string;
  @Input() public invitationStatus: string;

  public labelKey: string;
  public labelClass: string;
  public labelTooltip: string;

  constructor(private changeDetector: ChangeDetectorRef) {
    super();
  }

  @HostBinding('hidden')
  public get hidden(): boolean {
    return !this.labelKey;
  }

  public ngOnInit(): void {
    this.setLabel(this.roles, this.status, this.invitationStatus);
    this.changeDetector.markForCheck();
  }

  public ngOnChanges(): void {
    this.setLabel(this.roles, this.status, this.invitationStatus);
    this.changeDetector.markForCheck();
  }

  private setLabel(roles, status, invitationStatus): void {
    this.labelKey = '';
    this.labelClass = '';

    if (roles.includes(Roles.VISITOR)) {
      this.labelKey = 'PEOPLE.VISITOR';
      this.labelClass = 'person-label--visitor';
      this.labelTooltip = 'PEOPLE.TOOLTIP.VISITOR';
    }

    if (roles.includes(Roles.MODERATOR)) {
      this.labelKey = 'PEOPLE.MODERATOR';
      this.labelClass = 'person-label--moderator';
      this.labelTooltip = 'PEOPLE.TOOLTIP.MODERATOR';
    }

    if (status === MembershipStatus.INACTIVE) {
      this.labelKey = 'PEOPLE.DEACTIVATED.TEXT';
      this.labelClass = 'person-label--deactivated';
      this.labelTooltip = 'PEOPLE.TOOLTIP.DEACTIVATED';
    }

    if (invitationStatus === InvitationStatus.PENDING_APPROVAL) {
      this.labelKey = 'PEOPLE.DEACTIVATED.PENDING_APPROVAL';
      this.labelTooltip = 'PEOPLE.TOOLTIP.PENDING_APPROVAL';
      this.labelClass = 'person-label--pending-approval';
    }

    if (invitationStatus === InvitationStatus.INVITED) {
      this.labelKey = 'PEOPLE.INVITED';
      this.labelTooltip = 'PEOPLE.TOOLTIP.INVITED';
      this.labelClass = 'person-label--new-user';
    }
  }
}
