import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { PermissionService } from '@celum/work/app/core/api/permission';
import { AuthLogin } from '@celum/work/app/core/auth/auth.actions';
import { selectLoggedInPersonId } from '@celum/work/app/core/ui-state/ui-state.selectors';
import { ColorService } from '@celum/work/app/shared/util/color.service';

@Injectable()
export class InitialDataLoadedGuard implements CanActivate {
  constructor(
    private store: Store<any>,
    private colorService: ColorService,
    private permissionService: PermissionService
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.readyToNavigate().pipe(map(() => true));
  }

  public readyToNavigate(): Observable<number> {
    this.store.dispatch(AuthLogin());
    return this.store.select(selectLoggedInPersonId).pipe(
      filter(person => !!person),
      tap(_ => {
        this.permissionService.loadRolePermissionMapping();
        this.colorService.loadColorConfiguration();
      }),
      take(1)
    );
  }
}
