import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs/operators';

import { ProgressTask } from '@celum/work/app/progress-task/store/progress-task.model';
import { selectProgressTasks } from '@celum/work/app/progress-task/store/progress-task.selectors';

import { ProgressTaskHandlerService } from './progress-task-handler.service';
import { AbstractWebsocketListener } from '../../core/websocket/abstract-websocket-listener';
import { AbstractWebsocketWatcher } from '../../core/websocket/watcher/abstract-websocket-watcher';

@Injectable()
export class ProgressTaskWebsocketListener extends AbstractWebsocketListener {
  constructor(
    private store: Store<any>,
    private progressTaskHandlerService: ProgressTaskHandlerService,
    public websocketWatcher: AbstractWebsocketWatcher
  ) {
    super(websocketWatcher);
    this.startListening();
  }

  public startListening(): void {
    this.watchUserTopic<ProgressTask<any, any>[]>('progress-task')
      .pipe(withLatestFrom(this.store.select(selectProgressTasks)))
      .subscribe(([progressTaskMsg, progressTasksFromStore]) =>
        progressTaskMsg.forEach(progressTask =>
          this.progressTaskHandlerService.handleProgressTask(progressTask, progressTasksFromStore, true)
        )
      );
  }
}
