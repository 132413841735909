import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CelumIconModule } from '@celum/common-components';

import { NavigationButtons } from './component/navigation-buttons';

@NgModule({
            imports: [
              CommonModule, CelumIconModule
            ],
            declarations: [
              NavigationButtons
            ],
            exports: [
              NavigationButtons
            ]
          })
export class NavigationButtonsModule {
}
