<button
    mat-button celum-square-button
    [matMenuTriggerFor]="menuRef"
    data-cy="workroom-context-menu-trigger">
  <celum-icon [configuration]="menuIcon"></celum-icon>
</button>

<mat-menu #menuRef xPosition="before">
  <ng-template matMenuContent>
    <div
        *ngFor="let config of menuItems$ | async; trackBy: trackByFn"
        [matTooltip]="(config.disabledTooltip$ | async) | translate">
      <button
          mat-menu-item class="workroom-context-menu_item"
          [class.workroom-context-menu_item--disabled]="config.disabled$ | async"
          [disabled]="(config.disabled$ | async)"
          (click)="config.actionFn()">
        <celum-icon class="workroom-context-menu_item_icon" [configuration]="config.icon"></celum-icon>
        <span>{{ config.name | translate }}</span>
      </button>
    </div>
  </ng-template>
</mat-menu>
