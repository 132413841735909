import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CelumPropertiesProvider } from '@celum/core';
import { LocalStorageService } from '@celum/work/app/core/local-storage/local-storage.service';
import { LanguageUtil } from '@celum/work/app/core/translations/language-util';
@Injectable({ providedIn: 'root' })
export class TranslationInitializerService {
  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    this.initTranslations();
  }
  public initTranslations() {
    const defaultLanguage = CelumPropertiesProvider.properties.defaultLanguage;
    const storedLocale = this.localStorageService.getItem<string>(LanguageUtil.LOCAL_STORAGE_KEY) || defaultLanguage;
    this.translateService.setDefaultLang(defaultLanguage);
    this.changeLanguage(storedLocale);
  }
  public changeLanguage(locale: string) {
    this.localStorageService.setItem(LanguageUtil.LOCAL_STORAGE_KEY, locale);
    this.translateService.use(locale);
  }
}
