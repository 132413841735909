import { EntityTranslator } from '@celum/work/app/core/model/entity';

import { WorkroomGroup, WorkroomGroupType } from './workroom-group.model';

export class WorkroomGroupTranslator implements EntityTranslator<WorkroomGroup> {
  public translateToEntity(json: any): WorkroomGroup {
    const { id, name, personId, teamspaceId, sort, editable } = json;
    return {
      id,
      name,
      personId,
      teamspaceId,
      sort,
      editable,
      entityType: WorkroomGroupType.instance()
    };
  }
}
