import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { ContentHubBuildNumberStrategyResolverService } from '@celum/work/app/content-hub/services/content-hub-build-number-strategy-resolver.service';
import { selectContentHubRepositoryId } from '@celum/work/app/content-hub/store/content-hub.selectors';
import { Person } from '@celum/work/app/core/model/entities/person';
import { getRepository, selectTeamspaceById } from '@celum/work/app/core/model/entities/teamspace';
import { FeatureType, WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import { AutomatorType, RobotContext, RobotTypes } from '@celum/work/app/core/model/entities/workroom/robot.model';
import { FeatureService } from '@celum/work/app/shared/components/features/feature.service';

@Injectable()
export class RobotVisibilityService {
  constructor(
    private store: Store<any>,
    private featureService: FeatureService,
    private chStrategyResolver: ContentHubBuildNumberStrategyResolverService
  ) {}

  public robotVisible(
    robotType: RobotTypes,
    robotContext: RobotContext,
    templatePeople: Person[],
    workroomConfig: WorkroomConfiguration,
    teamspaceId: number,
    selectedRepositoryId: string
  ): Observable<boolean> {
    if (robotType === AutomatorType.TASK_ASSIGNMENT && templatePeople.length === 0) {
      return of(false);
    }

    if (robotType !== AutomatorType.CONTENT_HUB || robotContext === RobotContext.CONTENT_ITEM) {
      return of(true);
    }

    return this.store.select(selectContentHubRepositoryId).pipe(
      withLatestFrom(
        this.store.select(selectTeamspaceById(teamspaceId)),
        this.chStrategyResolver.resolve(selectedRepositoryId)
      ),
      map(([repositoryId, teamspace, strategy]) => {
        const canExportToCh =
          !!selectedRepositoryId && !!getRepository(teamspace, selectedRepositoryId) && strategy.supportsRelayCalls();
        const hasWorkflowFeature = this.featureService.hasFeatureInWorkroomConfig(workroomConfig, FeatureType.WORKFLOW);
        return canExportToCh || (hasWorkflowFeature && !!repositoryId);
      })
    );
  }
}
