import { createSelector } from '@ngrx/store';

import { ItemLinkRelationType } from '@celum/work/app/core/model/entities/item-link/item-link.model';
import { selectAllItemLinks } from '@celum/work/app/core/model/entities/item-link/item-link.selectors';

import { selectTaskDetailsState } from '../../../store/task-detail.state';

export const selectItemLinksState = createSelector(selectTaskDetailsState, state => state.itemLinks);

export const selectItemLinkRelationSlice = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(selectItemLinksState, state => state[itemLinkRelationType]);

export const selectItemLinkRelationSliceEntityIds = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(selectItemLinkRelationSlice(itemLinkRelationType), state => state.entityIds);

export const selectItemLinkRelationSliceItemLinks = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(selectAllItemLinks, selectItemLinkRelationSliceEntityIds(itemLinkRelationType), (itemLinks, ids) =>
    ids.map(id => itemLinks.find(itemLink => itemLink.id === id))
  );

export const selectItemLinkRelationSliceBatchSize = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(selectItemLinkRelationSlice(itemLinkRelationType), slice => slice.batchSize);

export const selectItemLinkRelationSliceLoading = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(selectItemLinkRelationSlice(itemLinkRelationType), state => state.loading);

export const selectItemLinkRelationSliceLastOffset = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(selectItemLinkRelationSlice(itemLinkRelationType), state => state.lastOffset);

export const selectItemLinkRelationSliceHasMoreBottom = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(
    selectItemLinkRelationSlice(itemLinkRelationType),
    state => state.paginationResult?.hasBottom ?? false
  );

export const selectItemLinkRelationSliceCreationDisabled = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(selectItemLinkRelationSlice(itemLinkRelationType), state => state.creationDisabled);

export const selectItemLinkRelationSliceTotalElementCount = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(
    selectItemLinkRelationSlice(itemLinkRelationType),
    state => state.paginationResult?.totalElementCount ?? 0
  );

export const selectItemLinkRelationSliceDisplayedItemLinksCount = (itemLinkRelationType: ItemLinkRelationType) =>
  createSelector(selectItemLinkRelationSlice(itemLinkRelationType), state => state.displayedItemLinksCount);

export const selectItemLinkPanelExpandedState = createSelector(selectItemLinksState, state => state.expanded);
