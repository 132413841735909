import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import * as FilesTreeActions from './files-tree.actions';
import { selectFilesTreeState, selectSelectedFolderId } from './files-tree.selector';
import { ContentItemService } from '../../../core/api/content-item/content-item.service';
import { Paging } from '../../../core/model';
import { selectCurrentWorkroomRootFolder } from '../../../core/model/entities/folder/folder.selector';
import {
  selectCurrentLibraryId,
  selectCurrentWorkroomId
} from '../../../pages/workroom/store/workroom-wrapper.selectors';
import * as ContentItemActions from '../content-item-list/content-item-list.actions';

export const TREE_BATCH_SIZE = 100;

@Injectable()
export class FilesTreeEffects {
  public loadSubfolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilesTreeActions.FilesTreeLoadSubfolders),
      mergeMap(action =>
        combineLatest([
          of(action),
          this.store.select(selectCurrentLibraryId),
          this.store.select(selectFilesTreeState(action.treeId))
        ]).pipe(take(1))
      ),
      mergeMap(([action, libraryId, state]) => {
        return this.contentItemService
          .loadSubfolders(libraryId, action.parentId, Paging.of(action.offset, action.limit || state.batchSize))
          .pipe(
            map(({ folders, paginationResult }) =>
              FilesTreeActions.FilesTreeSubfoldersLoaded({
                parentId: action.parentId,
                offset: action.offset,
                limit: action.limit,
                folders,
                paginationResult,
                treeId: action.treeId
              })
            )
          );
      })
    )
  );

  public fetchNextBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilesTreeActions.FilesTreeFetchNextBatch),
      switchMap(action =>
        combineLatest([of(action), this.store.select(selectFilesTreeState(action.treeId))]).pipe(take(1))
      ),
      filter(([action, state]) => {
        if (state.lists && state.lists[action.parentId]?.paginationResult?.hasBottom) {
          return true;
        } else {
          console.warn(`FilesTreeEffects: No more elements available for files tree ${action.parentId}!`);
          return false;
        }
      }),
      switchMap(([action, state]) => {
        return of(
          FilesTreeActions.FilesTreeLoadSubfolders({
            offset: action.offset,
            parentId: action.parentId,
            limit: state.batchSize || TREE_BATCH_SIZE,
            treeId: action.treeId
          })
        );
      })
    )
  );

  public deeplinksSearch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilesTreeActions.FilesTreeDeeplinkSearch),
        concatLatestFrom(() => this.store.select(selectCurrentLibraryId)),
        switchMap(([action, libraryId]) => this.contentItemService.deeplinkSearch(libraryId, action.objectId))
      ),
    { dispatch: false }
  );

  public navigateToRootIfCurrentFolderDeleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ContentItemActions.ContentItemDeleteContentItems),
        withLatestFrom(
          this.store.select(selectSelectedFolderId),
          this.store.select(selectCurrentWorkroomRootFolder),
          this.store.select(selectCurrentWorkroomId)
        ),
        filter(([{ contentItemIds }, selectedFolderId]) => contentItemIds.includes(selectedFolderId)),
        tap(([, , rootFolder, workroomId]) =>
          this.router.navigate(['workroom', workroomId, 'assets'], { queryParams: { selectedFolder: rootFolder.id } })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private contentItemService: ContentItemService,
    private router: Router
  ) {}
}
