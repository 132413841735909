import { Store } from '@ngrx/store';

import { EntityTranslator, EntityUtil } from '@celum/work/app/core/model';
import { Workroom, WorkroomType } from '@celum/work/app/core/model/entities/workroom/workroom.model';

import { Contributor, ContributorType } from '../contributor/contributor.model';
import { selectContributorsByIds } from '../contributor/contributor.selectors';
import { Person } from '../person/person.model';
import { selectPersonEntities } from '../person/person.selectors';

export class WorkroomTranslator implements EntityTranslator<Workroom> {
  public translateToEntity(json: any): Workroom {
    const color = json.color
      ? {
          red: json.color.red,
          green: json.color.green,
          blue: json.color.blue
        }
      : null;

    const id = json.id;
    return {
      id,
      libraryId: json.libraryId,
      renditionLibraryId: json.renditionLibraryId,
      name: json.name,
      description: json.description,
      creationDate: json.creationDate,
      dueDate: json.dueDate,
      icon: json.icon,
      status: json.status,
      color,
      headerUrl: json.headerUrl,
      teamspaceId: json.teamspaceId,
      configuration: json.configuration,
      modifiedDate: json.modifiedDate,
      driveSubscribed: json.driveSubscribed,
      slibResourceToken: json.slibResourceToken,
      entityType: WorkroomType.instance(),
      contributorIds: json.contributors, // relation IDs resolved via Normalizer
      contentHubRepositoryId: json.contentHubRepositoryId,
      numberOfFiles: json.numberOfFiles,
      numberOfTasks: json.numberOfTasks,
      contentItemCount: json.contentItemCount,
      lastActivity: json.lastActivity,
      rootFolder: json.rootFolder,
      commentAttachmentsFolder: json.commentAttachmentsFolder,
      trashBinFolder: json.trashBinFolder,
      persons: (store: Store<any>) =>
        EntityUtil.getRelatedEntities<Person>(
          store,
          selectContributorsByIds(json.contributors),
          selectPersonEntities,
          {
            id: id,
            key: ContributorType.WORKROOM_REF
          },
          ContributorType.PERSON_REF
        ),
      contributors: (store: Store<any>) =>
        EntityUtil.getRelationEntities<Contributor>(store, selectContributorsByIds(json.contributors), {
          id: id,
          key: ContributorType.WORKROOM_REF
        }),
      contributor: (store: Store<any>, personId: number) =>
        EntityUtil.getRelationEntity<Contributor>(
          store,
          selectContributorsByIds(json.contributors),
          {
            id: id,
            key: ContributorType.WORKROOM_REF
          },
          {
            id: personId,
            key: ContributorType.PERSON_REF
          }
        )
    };
  }
}
