import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { DataUtil, EntityType } from '@celum/core';
import { Entity } from '@celum/work/app/core/model';
import { ContentItemOrigin } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { Rendition, RenditionType } from '@celum/work/app/core/model/entities/rendition/rendition.model';

import { PersonType } from '../person/person.model';

export enum FileFormat {
  UNKNOWN = 'UNKNOWN',
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  MODEL3D = 'MODEL3D',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  TEXT = 'TEXT'
}

export interface FileVersion extends Entity<string, FileVersionType> {
  fileId: string;
  name: string;
  format: FileFormat;
  originalFileSize: number;
  versionNumber: number;
  renditionsIds: number[];
  downloadUrl: string;
  creatorName: string;
  uploadedDate: number;
  origin: ContentItemOrigin;
  renditions: (store: Store<any>) => Observable<Rendition[]>;
}

export class FileVersionType implements EntityType {
  public static readonly TYPE_KEY: string = 'FileVersion';

  private static _instance: FileVersionType;

  public id = FileVersionType.TYPE_KEY;
  public inheritsFrom: Set<EntityType>;

  public static instance(): FileVersionType {
    return this._instance || (this._instance = new this());
  }

  public static equals(x: FileVersion, y: FileVersion): boolean {
    return x?.id === y?.id && isEqual(x?.renditionsIds, y?.renditionsIds);
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {})?.relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(PersonType.TYPE_KEY)) {
        relationDefinitions.createdBy = PersonType.instance().getSchema();
        relationDefinitions.changedBy = PersonType.instance().getSchema();
      }
    }

    relationDefinitions.renditions = [RenditionType.instance().getSchema()];
    return new schema.Entity(FileVersionType.TYPE_KEY, relationDefinitions, {
      processStrategy: (entity, parent, c) => {
        if (parent?.typeKey === FileType.TYPE_KEY) {
          parent.activeVersionId = entity.id;
        }
        return entity;
      }
    });
  }
}

export interface FileVersionsState extends EntityState<FileVersion> {}
