<celum-simple-list
  class="people-list"
  clear-selection-on-document-click
  [clearSelectionOnEmptySpaceClick]="true"
  [customItemClass]="'people-item card'"
  [selectionHandler]="listSelectionHandler"
  [items]="people"
  [itemTemplate]="itemTemplate"
  [trackByFn]="trackByFn"
  [hasMoreTop]="false"
  [hasMoreBottom]="hasMoreBottom"
  (openContextMenu)="onOpenContextMenu($event)"
  (requestNextPage)="onRequestNextPage()">
  <ng-content top-item></ng-content>

  <ng-template let-item #itemTemplate>
    <people-card-item
      [entity]="item"
      [size]="size"
      [roles]="getRoles(item) | async"
      [status]="(getMembership(item) | async)?.status || item.status"
      [invitationStatus]="(getMembership(item) | async)?.invitationStatus || item.invitationStatus"
      (click)="itemClick.emit(item)">
    </people-card-item>
  </ng-template>
</celum-simple-list>
