import { createSelector } from '@ngrx/store';

import { activityAdapter } from './activity.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = activityAdapter.getSelectors();

export const selectActivities = createSelector(selectEntitiesState, (state: EntitiesState) => state.activities);

export const selectAllActivities = createSelector(selectActivities, selectAll);
export const selectActivityEntities = createSelector(selectActivities, selectEntities);

export const selectActivityById = (id: number) =>
  createSelector(selectActivityEntities, activityDictionary => activityDictionary[id]);

export const selectSelectedActivityId = createSelector(selectActivities, state => state.selectedId);

export const selectSelectedActivity = createSelector(
  selectActivityEntities,
  selectSelectedActivityId,
  (activityDictionary, selectedId) => activityDictionary[selectedId]
);
