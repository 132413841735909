import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { isTruthy } from '@celum/core';
import { Person } from '@celum/work/app/core/model/entities/person';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import { WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import {
  AutomatorSubType,
  AutomatorType,
  RobotContext,
  RobotTypes,
  RuleType
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import {
  RobotCreateEvent,
  RobotMenuItem,
  RobotsFactory,
  RobotSubMenuItem
} from '@celum/work/app/robots/services/robots-factory';
import { RobotsMenuItemFactory } from '@celum/work/app/robots/services/robots-menu-item.factory';
import { WorkroomWizardData } from '@celum/work/app/workroom-wizard/components/workroom-wizard.component';
import { RobotVisibilityService } from '@celum/work/app/workroom-wizard/services/robot-visibility.service';

@Component({
  selector: 'robot-menu',
  templateUrl: './robot-menu.component.html',
  styleUrls: ['./robot-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RobotMenuComponent implements OnInit {
  @Input() public source: TaskList | null;
  @Input() public templatePeople: Person[];
  @Input() public workroomConfig: WorkroomConfiguration;
  @Input() public teamspaceId: number;
  @Input() public repositoryId: string;
  @Input() public robotContext: RobotContext;

  @Output() public readonly createRobot: EventEmitter<RobotCreateEvent> = new EventEmitter<RobotCreateEvent>();

  public readonly robotAddIcon = IconConfiguration.medium('robot-add')
    .withIconSize(30)
    .withColor(ColorConstants.BLUE_GRAY_500)
    .withHoverColor(ColorConstants.BLUE_GRAY_700);

  public robotMenuItems: RobotMenuItem[];
  public isForGeneralRobots: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WorkroomWizardData,
    private translator: TranslateService,
    private robotFactory: RobotsFactory,
    private robotVisibilityService: RobotVisibilityService,
    public robotsMenuItemFactory: RobotsMenuItemFactory
  ) {}

  public ngOnInit(): void {
    this.isForGeneralRobots = this.robotContext !== RobotContext.TASK_LIST;
    this.robotMenuItems = this.robotsMenuItemFactory.getRobotsMenuItems(this.isForGeneralRobots);
  }

  public getMenuToolTip(menuItem: RobotMenuItem, robotSubType?: AutomatorSubType | RuleType): Observable<string> {
    const service = this.robotFactory.getService(robotSubType ?? menuItem.robotType, this.isForGeneralRobots);
    if (!service) {
      return of('');
    }
    return service
      .getDisabledTooltipKey({
        workroomConfig: this.workroomConfig,
        sourceId: this.source?.id,
        robotSubType: robotSubType ?? this.getDefaultRobotSubTypeIfExists(menuItem.robotType),
        repositoryId: this.repositoryId,
        isTemplate: this.data.type.targetEntity === 'TEMPLATE'
      })
      .pipe(
        isTruthy(),
        map(disabledKey => this.translator.instant(disabledKey))
      );
  }

  public getParentMenuToolTip(menuItem: RobotMenuItem): Observable<string> {
    return this.hasAllSubRobotsDisabled(menuItem).pipe(
      withLatestFrom(this.isRobotDisabled(menuItem)),
      map(([subRobotsLimitReached, isRobotDisabled]) =>
        subRobotsLimitReached || isRobotDisabled ? this.translator.instant('ROBOTS.ALREADY_USED') : ''
      )
    );
  }

  public isRobotDisabled(menuItem: RobotMenuItem, robotSubType?: AutomatorSubType | RobotTypes): Observable<boolean> {
    const service = this.robotFactory.getService(robotSubType ?? menuItem.robotType, this.isForGeneralRobots);
    if (!service) {
      return of(false);
    }

    return service
      .getDisabledTooltipKey({
        workroomConfig: this.workroomConfig,
        sourceId: this.source?.id,
        robotSubType: robotSubType ?? this.getDefaultRobotSubTypeIfExists(menuItem.robotType),
        repositoryId: this.repositoryId,
        isTemplate: this.data.type.targetEntity === 'TEMPLATE'
      })
      .pipe(map(key => !!key));
  }

  public hasAllSubRobotsDisabled(menuItem: RobotMenuItem): Observable<boolean> {
    return forkJoin(
      menuItem.subMenuItems.map(subMenuItem => this.isRobotDisabled(menuItem, subMenuItem.robotSubType))
    ).pipe(map(definedRobotsLimitReachedValues => definedRobotsLimitReachedValues.every(limitReached => limitReached)));
  }

  public robotVisible(robotType: RobotTypes): Observable<boolean> {
    return this.robotVisibilityService.robotVisible(
      robotType,
      this.robotContext,
      this.templatePeople,
      this.workroomConfig,
      this.teamspaceId,
      this.repositoryId
    );
  }

  public subMenuItemsVisible(menuItem: RobotMenuItem): Observable<boolean> {
    if (!menuItem.subMenuItems) {
      return of(false);
    }

    return of(true);
  }

  public onMenuItemClicked(
    menuItem: RobotMenuItem,
    robotSubType?: AutomatorSubType | RuleType,
    subMenuItem?: RobotMenuItem | RobotSubMenuItem
  ): void {
    this.isRobotDisabled(menuItem, robotSubType)
      .pipe(
        take(1),
        filter(isDisabled => !isDisabled)
      )
      .subscribe(() => {
        const createEvent: RobotCreateEvent = {
          source: this.source,
          type: (subMenuItem as RobotMenuItem)?.componentType ?? menuItem.componentType,
          robotSubType: robotSubType ?? this.getDefaultRobotSubTypeIfExists(menuItem.robotType)
        };
        this.createRobot.emit(createEvent);
      });
  }

  public trackByMenuItemFn(_: number, menuItem: RobotMenuItem) {
    return menuItem;
  }

  public trackBySubMenuItemFn(_: number, subMenuItem: RobotSubMenuItem) {
    return subMenuItem;
  }

  private getDefaultRobotSubTypeIfExists(robotType: RobotTypes) {
    return robotType === AutomatorType.CONTENT_HUB ? AutomatorSubType.CONTENT_HUB_ASSETS : null;
  }
}
