import { EntityState } from '@ngrx/entity';

import { UUIDGenerator } from '@celum/core';
import { SnackbarStateButtonConfig } from '@celum/work/app/shared/components/snackbar-group/config/snackbar-state-button.config';

import { AppState } from '../../core';
import { ErrorKey } from '../../core/error/error-key';

export enum ProgressTaskType {
  UPLOAD = 'UPLOAD',
  IMPORT_FROM_CH = 'IMPORT_FROM_CH',
  EXPORT_TO_CH = 'EXPORT_TO_CH'
}

export enum ProgressTaskStatus {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED'
}

export function isProgressTaskStatusActive(progressTaskStatus: ProgressTaskStatus) {
  return [ProgressTaskStatus.QUEUED, ProgressTaskStatus.IN_PROGRESS].includes(progressTaskStatus);
}

export class ProgressTask<T extends ProgressTaskParams, S extends ProgressTaskPayload> {
  public readonly id: string = UUIDGenerator.generateId();
  public readonly params: T;
  public payload?: S;
  public status: ProgressTaskStatus;
  public readonly type: ProgressTaskType;
  public errorKey?: ErrorKey;
  public serverUpdated?: boolean;
  public hiddenFromUi?: boolean;
  public snackbarActions: SnackbarStateButtonConfig[] = [];
  constructor(params: T, type: ProgressTaskType, status: ProgressTaskStatus) {
    this.params = params;
    this.type = type;
    this.status = status;
  }

  public static queued<T extends ProgressTaskParams, S extends ProgressTaskPayload>(
    params: T,
    type: ProgressTaskType
  ): ProgressTask<T, S> {
    return new ProgressTask<T, S>(params, type, ProgressTaskStatus.QUEUED);
  }
}

export interface ProgressTaskPayload {
  id: any;
}

export interface ProgressTaskFilePayload extends ProgressTaskPayload {
  fileId: string;
}

export interface ProgressTaskParams {
  getTitle(): string;
}

export interface ProgressTaskState extends EntityState<ProgressTask<any, any>> {}

export interface State extends AppState {
  progressTaskState: ProgressTaskState;
}
