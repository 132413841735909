import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { difference, differenceBy, uniq } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { AVATAR_SIZE, IconConfiguration } from '@celum/common-components';
import { flattenObservableArray } from '@celum/core';
import { CelumDialogOpener } from '@celum/internal-components';
import { InvitationStatus, MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { selectMemberByUserIdAndTeamspaceId } from '@celum/work/app/core/model/entities/member/member.selectors';
import { filterBySearchString, Person } from '@celum/work/app/core/model/entities/person';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import {
  Automator,
  AutomatorType,
  ExistingAssigneesStrategy,
  RobotActionType,
  RobotContext,
  RobotTriggerType,
  RobotWithSubsequentAction,
  TaskAddAssigneesAction,
  TaskTrigger
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import {
  InviteUserDialog,
  InviteUserDialogConfiguration,
  InviteUserDialogExecuteFn
} from '@celum/work/app/person/invite/components/invite-user-dialog/invite-user-dialog.component';
import { AssignmentAutomatorService } from '@celum/work/app/robots/services/assignment-automator.service';
import { RobotsFactory } from '@celum/work/app/robots/services/robots-factory';
import { InteractiveAvatarsEvents } from '@celum/work/app/shared/components/interactive-avatar/interactive-avatars.events';
import { MessageBoxConfigType } from '@celum/work/app/shared/components/message/message-box';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';
import { AvatarDecoratorFn, AvatarUtil } from '@celum/work/app/shared/util/avatar-util';
import { WorkroomWizardData } from '@celum/work/app/workroom-wizard/components/workroom-wizard.component';
import { WorkroomWizardPeopleService } from '@celum/work/app/workroom-wizard/services/workroom-wizard-people.service';

import { RobotDialogConfiguration } from '../../robot-dialog/robot-dialog-config';
import { BaseRobot } from '../base-robot/base-robot.component';
import { TokenStyle } from '../robot-components.model';

@Component({
  selector: 'assignment-automator',
  templateUrl: './assignment-automator.component.html',
  styleUrls: ['./assignment-automator.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentAutomatorComponent extends BaseRobot<TaskList> {
  public assignees: Person[] = [];
  public TARGETING_ROBOT_TYPE = AutomatorType.TASK_ASSIGNMENT;
  public assignIconConfig: IconConfiguration;
  public allPeople$: Observable<Person[]>;
  public avatarDecorator$: AvatarDecoratorFn;
  public searchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public selectAllTLO = false;
  public tloIcon: IconConfiguration;
  public robotSubsequentActionType = ExistingAssigneesStrategy;
  public selectedExistingAssigneesStrategy: ExistingAssigneesStrategy = ExistingAssigneesStrategy.KEEP;

  constructor(
    translateService: TranslateService,
    protected robotsFactory: RobotsFactory,
    protected automatorService: AssignmentAutomatorService,
    private store: Store<any>,
    private dialogOpener: CelumDialogOpener,
    private cdRef: ChangeDetectorRef,
    private eventBus: ApplicationEventBus,
    private avatarUtil: AvatarUtil,
    @Optional() private workroomWizardPeopleService: WorkroomWizardPeopleService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: WorkroomWizardData
  ) {
    super(robotsFactory, translateService, automatorService);
    this.assignIconConfig = IconConfiguration.medium('robot-task-assignee').withIconSize(30);
    this.avatarDecorator$ = this.setupAvatarDecorator();
  }

  public get whenTranslationsCategory(): string {
    if (this.selectedTriggerType === RobotTriggerType.TASK_MOVED) {
      return TranslationCategories.TASK_MOVED;
    }
    return TranslationCategories.TASK_CREATED;
  }

  public get thenTranslationsCategory(): string {
    return this.getTranslationCategoryFromActionAndTrigger(this.whenTranslationsCategory, this.selectedActionType);
  }

  public get messageBoxes$(): Observable<MessageBoxConfigType[]> {
    return this.notApplied$.pipe(
      map(notApplied => {
        if (this.assignees.length < 1 && this.selectAllTLO) {
          return [
            {
              type: 'warn',
              text: this.translateService.instant(`ROBOTS.TASK_LIST_OWNER.ERROR`)
            }
          ];
        }

        // Don't show false warning when creating robot
        if (notApplied && !!this.robot) {
          return [
            {
              type: 'warn',
              text: this.translateService.instant(`ROBOTS.ASSIGNMENT.WARNING`)
            }
          ];
        }

        return [];
      })
    );
  }

  public get avatarSize(): number {
    return !this.isReadonly ? AVATAR_SIZE.m : AVATAR_SIZE.s;
  }

  public get needsActionDropdown(): boolean {
    return this.selectedTriggerType === RobotTriggerType.TASK_MOVED;
  }

  public getThenTokenStyle(token: string) {
    return super.getThenTokenStyle(this.thenTranslationsCategory, token);
  }

  public getWhenTokenStyle(token: string) {
    return super.getWhenTokenStyle(this.whenTranslationsCategory, token);
  }

  public isValid(): boolean {
    return (
      this.selectedActionType !== RobotActionType.TASK_ADD_ASSIGNEES || this.assignees.length > 0 || this.selectAllTLO
    );
  }

  public getPeopleToExcludeInAvatarList(): Person[] {
    return this.assignees.filter(({ id }) => this.selectAllTLO && this.sourceEntity.ownerIds.includes(id));
  }

  public configure(configuration: RobotDialogConfiguration<TaskList>): void {
    super.configure(configuration);
    this.splitIntoTokens();
    this.setupInputs();
    this.tloIcon = IconConfiguration.medium('tlo').withIconSize(this.isReadonly ? 20 : 30);
  }

  public getTriggerTranslation(selectedTriggerType: RobotTriggerType): string {
    return `ROBOTS.ASSIGNMENT.TRIGGER.${selectedTriggerType}`;
  }

  public getActionTranslation(selectedActionType: RobotActionType): string {
    return `ROBOTS.ASSIGNMENT.ACTION.${selectedActionType}`;
  }

  public getResult(): Automator {
    const action: RobotWithSubsequentAction = {
      type: this.selectedActionType
    };

    if (this.selectedActionType === RobotActionType.TASK_ADD_ASSIGNEES) {
      const taskAddAssigneesAction = action as TaskAddAssigneesAction;
      taskAddAssigneesAction.personIds = this.assignees.map(assignee => assignee.id);
      taskAddAssigneesAction.allTaskListOwners = this.selectAllTLO;

      if (this.selectedTriggerType === RobotTriggerType.TASK_MOVED) {
        action.existingAssigneesStrategy = this.selectedExistingAssigneesStrategy;
      }
    }

    const trigger: TaskTrigger = {
      type: this.selectedTriggerType,
      taskListId: this.sourceEntity.id
    };

    return {
      type: AutomatorType.TASK_ASSIGNMENT,
      sourceContext: RobotContext.TASK_LIST,
      sourceId: this.sourceEntity.id,
      action,
      trigger
    };
  }

  public addAssignee(person: Person): void {
    this.assignees = [...this.assignees, person];

    const { ownerIds } = this.sourceEntity;
    const assigneeIds = this.assignees.map(({ id }) => id);
    const allTlosAssigned = ownerIds.length > 0 && difference(ownerIds, assigneeIds).length < 1;

    if (allTlosAssigned) {
      this.selectAllTLO = true;
    }
  }

  public removeAssignee(person: Person): void {
    this.assignees = this.assignees.filter(assignee => assignee.id !== person.id);

    if (this.selectAllTLO && this.sourceEntity.ownerIds.includes(person.id)) {
      this.selectAllTLO = false;
    }
  }

  public getWhenTokens(): TokenStyle[] {
    return this.whenTokens[this.whenTranslationsCategory].textTokens;
  }

  public getThenTokens(): TokenStyle[] {
    return this.thenTokens[this.thenTranslationsCategory].textTokens;
  }

  public allTloSelectionChanged(selected: boolean): void {
    this.selectAllTLO = selected;
    if (selected) {
      const owners = this.templatePeople.filter(({ id }) => this.sourceEntity.ownerIds.includes(id));
      this.assignees = uniq([...this.assignees, ...owners]);
    } else {
      this.assignees = this.assignees.filter(({ id }) => !this.sourceEntity.ownerIds.includes(id));
    }
  }

  public inviteAndAssign(): void {
    const executeFn: InviteUserDialogExecuteFn = people => {
      const newTemplatePeople = this.workroomWizardPeopleService.addNewPeople(people);
      const addedPeople = differenceBy(newTemplatePeople, this.templatePeople, 'id');
      addedPeople.forEach(person => this.addAssignee(person));
      this.eventBus.publishEvent({ type: InteractiveAvatarsEvents.SHOULD_CLOSE_SEARCH });
      this.cdRef.markForCheck();
      return of(true);
    };

    const statusesToDisplay = [InvitationStatus.ACCEPTED, InvitationStatus.APPROVED, InvitationStatus.PENDING_APPROVAL];
    const isTemplate = this.data.type.targetEntity === 'TEMPLATE';
    const excludedEmails = this.workroomWizardPeopleService.invitedPeople.map(
      ({ invitationEmail }) => invitationEmail.email
    );

    const dialogConfig = new InviteUserDialogConfiguration({
      headerText: 'PEOPLE.INVITE.INVITE_AND_ASSIGN_TITLE',
      confirmButtonText: 'PEOPLE.INVITE.INVITE_AND_ASSIGN_BTN',
      teamspaceId: this.teamspaceId,
      alreadyInvited: excludedEmails,
      statusesToDisplay: statusesToDisplay,
      organisationMembersOnly: isTemplate,
      showMessage: !isTemplate,
      initialPeopleSearchString: this.searchQuery$.getValue(),
      executeFn
    });

    this.dialogOpener.showDialog('personDialog', InviteUserDialog, dialogConfig);
  }

  private splitIntoTokens(): void {
    [TranslationCategories.TASK_MOVED, TranslationCategories.TASK_CREATED].forEach(translationCategoryKey => {
      this.whenTokens[translationCategoryKey] = this.tokenizeTranslation(
        `ROBOTS.ASSIGNMENT.ASSIGNMENT_TRIGGERS.${translationCategoryKey}.WHEN`
      );
      this.addCombinedTranslationCategoryToThenTokens(translationCategoryKey, RobotActionType.TASK_ADD_ASSIGNEES);
      this.addCombinedTranslationCategoryToThenTokens(translationCategoryKey, RobotActionType.TASK_REMOVE_ASSIGNEES);
    });
  }

  private addCombinedTranslationCategoryToThenTokens(
    translationCategoryKey: TranslationCategories,
    robotActionType: RobotActionType
  ) {
    const combinedTranslationCategory = this.getTranslationCategoryFromActionAndTrigger(
      translationCategoryKey,
      robotActionType
    );
    this.thenTokens[combinedTranslationCategory] = this.tokenizeTranslation(
      `ROBOTS.ASSIGNMENT.ASSIGNMENT_TRIGGERS.${translationCategoryKey}.THEN_${robotActionType}`
    );
  }

  private getTranslationCategoryFromActionAndTrigger(action: string, trigger: string): string {
    return `trigger_${trigger}_action_${action}`;
  }

  private setupInputs(): void {
    if (!!this.robot || this.isReadonly) {
      if (this.selectedActionType === RobotActionType.TASK_ADD_ASSIGNEES) {
        const taskAddAssigneesAction = (this.robot as Automator).action as TaskAddAssigneesAction;
        const selectedIds = taskAddAssigneesAction.personIds;
        this.assignees = this.templatePeople.filter(person => selectedIds.includes(person.id));
        this.selectAllTLO = taskAddAssigneesAction.allTaskListOwners;
        this.selectedExistingAssigneesStrategy =
          (taskAddAssigneesAction as RobotWithSubsequentAction).existingAssigneesStrategy ||
          this.robotSubsequentActionType.KEEP;
      }
    }

    this.allPeople$ = this.searchQuery$.pipe(
      withLatestFrom(
        flattenObservableArray(
          this.templatePeople.map(person =>
            this.store.select(selectMemberByUserIdAndTeamspaceId(person.id, this.teamspaceId))
          )
        )
      ),
      map(([searchQuery, members]) => {
        const activePeople = this.templatePeople.filter(
          (_, index) => members[index]?.status !== MembershipStatus.INACTIVE
        );
        return filterBySearchString(searchQuery, activePeople);
      })
    );
  }

  private setupAvatarDecorator(): AvatarDecoratorFn {
    if (this.workroomWizardPeopleService) {
      return this.workroomWizardPeopleService.getAvatarDecoratorFn();
    }

    return this.avatarUtil.getAvatarDecoratorForCurrentWorkroom();
  }
}

enum TranslationCategories {
  TASK_MOVED = 'TASK_MOVED',
  TASK_CREATED = 'TASK_CREATED'
}
