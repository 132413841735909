import { Pipe, PipeTransform } from '@angular/core';

import { DateFormats, DateUtil } from '@celum/work/app/shared/util';

@Pipe({
  name: 'localizedDate',
  pure: true
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private dateUtil: DateUtil) {}

  public transform(value: number | undefined, format: DateFormats = DateFormats.COMMENT): string | null {
    if (!value) {
      return null;
    }
    const formatString = this.dateUtil.getFormatString(format);
    return this.dateUtil.format(value, formatString);
  }
}
