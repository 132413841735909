import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { PaginationResult } from '@celum/core';
import {
  WorkroomDeleteWorkroom,
  WorkroomDeleteWorkroomFailed,
  WorkroomService
} from '@celum/work/app/core/api/workroom';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { WorkroomStatus } from '@celum/work/app/core/model/entities/workroom';
import { ReactiveService } from '@celum/work/app/shared/util/reactive-service';

@Injectable()
export class WorkroomsManagementService extends ReactiveService {
  public readonly BATCH_SIZE = 15;
  public paginationResult: PaginationResult;
  private readonly workroomIds = new BehaviorSubject<number[]>([]);
  private readonly isLoading = new BehaviorSubject<boolean>(false);

  private lastRequestBody: {
    searchValue: string;
    paging: Paging;
    sorting: Sorting;
  };

  constructor(
    private actions$: Actions,
    private workroomService: WorkroomService
  ) {
    super();
    this.actions$
      .pipe(takeUntil(this.unsubscribe$), ofType(WorkroomDeleteWorkroomFailed))
      .subscribe(_ => this.loadWorkrooms({}));

    this.actions$
      .pipe(takeUntil(this.unsubscribe$), ofType(WorkroomDeleteWorkroom))
      .subscribe(({ workroom }) => this.deleteWorkroomId(workroom.id));
  }

  public get workroomsIds$(): Observable<number[]> {
    return this.workroomIds.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public get hasWorkrooms$(): Observable<boolean> {
    return this.workroomIds.pipe(map(workrooms => workrooms.length > 0));
  }

  public getLastBatchSize() {
    return this.lastRequestBody?.paging.limit ?? -1;
  }

  public loadWorkrooms(config: { searchValue?: string; sorting?: Sorting; paging?: Paging }): void {
    const {
      searchValue = '',
      paging = Paging.of(0, this.BATCH_SIZE),
      sorting = Sorting.of('name', SortDirection.ASC)
    } = config;

    this.lastRequestBody = {
      searchValue,
      paging,
      sorting
    };
    this.isLoading.next(true);
    this.workroomService
      .loadWorkrooms(searchValue, paging, sorting, [WorkroomStatus.INACTIVE, WorkroomStatus.ACTIVE], false, null)
      .pipe(
        catchError(_ => {
          this.isLoading.next(false);
          return EMPTY;
        })
      )
      .subscribe(result => {
        if (paging.offset === 0) {
          this.workroomIds.next(result.workrooms.map(({ id }) => id));
        } else {
          this.workroomIds.next([...this.workroomIds.getValue(), ...result.workrooms.map(({ id }) => id)]);
        }
        this.paginationResult = result.paginationResult;
        this.isLoading.next(false);
      });
  }

  public loadNextBatch(): void {
    if (!this.isLoading.getValue() && this.paginationResult?.hasBottom) {
      this.lastRequestBody.paging.offset = this.workroomIds.getValue().length;
      this.loadWorkrooms(this.lastRequestBody);
    }
  }

  public deleteWorkroomId(workroomId: number): void {
    this.workroomIds.next(this.workroomIds.getValue().filter(id => id !== workroomId));
  }
}
