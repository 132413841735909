import { createSelector } from '@ngrx/store';

import { selectAllSubtasks } from '@celum/work/app/core/model/entities/subtask/subtask.selectors';

import { selectTaskDetailsState } from '../../../store/task-detail.state';

export const selectSubtaskStateState = createSelector(selectTaskDetailsState, state => state.subtasks);

export const selectCurrentSubtaskIds = createSelector(selectSubtaskStateState, state => state.entityIds);

export const selectCurrentSubtasks = createSelector(selectAllSubtasks, selectCurrentSubtaskIds, (subtasks, ids) =>
  subtasks.filter(subtask => ids.includes(subtask.id))
);

export const selectIsSubtasksLoading = createSelector(selectSubtaskStateState, state => state.loading);
export const selectSubtasksBatchSize = createSelector(selectSubtaskStateState, state => state.batchSize);
export const selectLasOffset = createSelector(selectSubtaskStateState, state => state.lastOffset);
export const selectHasMoreBottom = createSelector(
  selectSubtaskStateState,
  state => state.paginationResult?.hasBottom ?? false
);
export const selectTotalElementCount = createSelector(
  selectSubtaskStateState,
  state => state.paginationResult?.totalElementCount ?? 0
);

export const selectDisplayedSubtasksCount = createSelector(
  selectSubtaskStateState,
  state => state.displayedSubtasksCount
);

export const selectSubtasksPanelExpandedState = createSelector(selectSubtaskStateState, state => state.expanded);
