import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { MarkerDeleteMany, MarkerDeleteOne, MarkerUpsertMany, MarkerUpsertOne } from './marker.actions';
import { Marker, markerProperties, MarkerState } from './marker.model';
import { mergeEntities } from '../entities-state-util';

export const markerAdapter: EntityAdapter<Marker> = createEntityAdapter<Marker>();

export const initialState: MarkerState = markerAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(MarkerUpsertOne, (state: MarkerState, { marker }) => {
    return markerAdapter.upsertOne(marker, state);
  }),
  on(MarkerUpsertMany, (state: MarkerState, { markers }) => {
    return markerAdapter.upsertMany(mergeEntities(markers, state, markerProperties), state);
  }),
  on(MarkerDeleteOne, (state: MarkerState, { id }) => {
    return markerAdapter.removeOne(id, state);
  }),
  on(MarkerDeleteMany, (state: MarkerState, { ids }) => {
    return markerAdapter.removeMany(ids, state);
  })
);

export function markerReducer(state: MarkerState | undefined, action: Action) {
  return reducer(state, action);
}
