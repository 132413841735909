<div [class.task-list-board_content--on-drag]="dragging$ | async" #taskBoardContent class="task-list-board_content">
  <div class="task-list-board_content_header">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="task-list-board_content_lists" [dragula]="dragNDropListBag">
    <ng-content></ng-content>
    <task-list
      *ngIf="columnCreating"
      [classList]="taskListClasses"
      [taskList]="dummyTaskList"
      (nameChanged)="onCreateNewTaskList($event)">
    </task-list>
  </div>
</div>

<div
    *ngxPermissionsOnly="[permissionCheckStrategy.canCreate() | async]"
    class="task-list-board_add-button"
    [class.task-list-board_add-button_no-robots]="!showWorkroomRobots"
    [matTooltip]="'WORKROOM_TASK.NEW_TASK_LIST' | translate"
    (click)="addList($event);">
  <celum-icon
    [configuration]="addListIcon"
    data-cy="create-task-list"
    data-operation-id="create_list">
  </celum-icon>
</div>

