import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Roles } from '@celum/work/app/core/model';
import { InvitationStatus, MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { selectMemberByUserOidAndTeamspaceId } from '@celum/work/app/core/model/entities/member/member.selectors';
import { Person } from '@celum/work/app/core/model/entities/person';
import { Template } from '@celum/work/app/core/model/entities/template/template.model';
import { UserRoleTuple } from '@celum/work/app/core/model/user-role-tuple.model';
import { selectLoggedInPersonId } from '@celum/work/app/core/ui-state/ui-state.selectors';
import {
  InviteUserDialog,
  InviteUserDialogConfiguration
} from '@celum/work/app/person/invite/components/invite-user-dialog/invite-user-dialog.component';
import {
  PersonData,
  UpdateUserRoleDialogComponent,
  UpdateUsersRoleDialogConfiguration
} from '@celum/work/app/person/invite/components/update-user-role-dialog/update-user-role-dialog.component';
import { OpenPeopleContextMenuEvent } from '@celum/work/app/person/people-list/people-list.component';
import { PersonListItemService } from '@celum/work/app/person/people-list/services/person-list-item.service';
import { WorkroomWizardType } from '@celum/work/app/workroom-wizard/components/workroom-wizard.component';
import { PersonListItemTemplateService } from '@celum/work/app/workroom-wizard/services/person-list-template.service';
import {
  PersonWithStatuses,
  WorkroomWizardPeopleService
} from '@celum/work/app/workroom-wizard/services/workroom-wizard-people.service';
import { WorkroomWizardTranslations } from '@celum/work/app/workroom-wizard/workroom-wizard-translations';

@Component({
  selector: 'workroom-wizard-people',
  templateUrl: './workroom-wizard-people.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./workroom-wizard-people.component.less'],
  providers: [
    {
      provide: PersonListItemService,
      useClass: PersonListItemTemplateService
    }
  ]
})
export class WorkroomWizardPeopleComponent extends ReactiveComponent implements OnInit {
  @ViewChild('personMenu') public userMenu: TemplateRef<any>;

  @Input() public workroomWizardType: WorkroomWizardType;
  @Input() public template: Template;
  @Input() public teamspaceId: number;
  @Input() public workroomWizardForm: UntypedFormGroup;
  @Input() public translations: WorkroomWizardTranslations;

  public inviteIcon = IconConfiguration.xLargeDarkRight('add-m_1', '', '', 33).withColor(ColorConstants.BLUE_GRAY_700);
  public updateUserIconConfiguration = IconConfiguration.medium('update-user');
  public removeIconConfiguration = IconConfiguration.small('remove-assignee');
  public overlayRef: OverlayRef | null;
  public role: typeof Roles = Roles;
  public invitationStatus: typeof InvitationStatus = InvitationStatus;
  public selection: Person[];

  public canShowPeopleList$: Observable<boolean>;
  public peopleWithStatuses$: Observable<PersonWithStatuses[]>;

  constructor(
    public workroomWizardPeopleService: WorkroomWizardPeopleService,
    private dialogOpener: CelumDialogOpener,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private store: Store<any>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.canShowPeopleList$ = this.workroomWizardPeopleService.people$.pipe(
      map(people => this.workroomWizardType.targetEntity !== 'WORKROOM' || people.length > 1)
    );
    this.peopleWithStatuses$ = this.workroomWizardPeopleService.people$.pipe(
      map(people =>
        people.map(person => {
          return {
            ...person,
            status: this.workroomWizardPeopleService.peopleWithStatuses.find(({ id }) => id === person.id)?.status
          };
        })
      )
    );
  }

  public openInviteDialog() {
    const excludedEmails = this.workroomWizardPeopleService.invitedPeople.map(
      ({ invitationEmail }) => invitationEmail.email
    );
    const isTemplate = this.workroomWizardType.targetEntity === 'TEMPLATE';
    const statusesToDisplay = [InvitationStatus.ACCEPTED, InvitationStatus.APPROVED];

    if (!isTemplate) {
      statusesToDisplay.push(InvitationStatus.PENDING_APPROVAL);
    }

    const executeFn = people => of(this.workroomWizardPeopleService.addNewPeople(people));
    const dialogConfig = new InviteUserDialogConfiguration({
      headerText: this.translations.inviteDialogHeadline,
      confirmButtonText: this.translations.inviteDialogButton,
      teamspaceId: this.teamspaceId,
      alreadyInvited: excludedEmails,
      statusesToDisplay: statusesToDisplay,
      organisationMembersOnly: isTemplate,
      showMessage: !isTemplate,
      executeFn
    });

    this.dialogOpener.showDialog('personDialog', InviteUserDialog, dialogConfig);
  }

  public openContextMenu(event: OpenPeopleContextMenuEvent) {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(event.position)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]);

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'people-list_backdrop',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
    this.overlayRef.attach(
      new TemplatePortal(this.userMenu, this.viewContainerRef, {
        $implicit: event.selection[0]
      })
    );

    this.overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => this.close());
  }

  public updateRole() {
    forkJoin(this.selection.map(person => this.getPersonData(person))).subscribe(personData => {
      const invitationDialogConfig = new UpdateUsersRoleDialogConfiguration(personData);
      this.dialogOpener
        .showDialog('updateUsersRolesDialog', UpdateUserRoleDialogComponent, invitationDialogConfig)
        .then((result: UserRoleTuple[]) => {
          if (result) {
            this.workroomWizardPeopleService.setRole(result);
          }
        });
      this.close();
    });
  }

  public remove() {
    this.workroomWizardPeopleService.removePerson(this.selection);
    this.close();
  }

  public close() {
    this.overlayRef?.dispose();
    this.overlayRef?.detach();
  }

  public onSelectionChanged(selection: Person[]): void {
    this.selection = selection;
  }

  public isWorkroomAndSelfSelected(): Observable<boolean> {
    return this.store.select(selectLoggedInPersonId).pipe(
      take(1),
      map(
        loggedInPersonId =>
          this.workroomWizardType.targetEntity === 'WORKROOM' &&
          this.selection.map(({ id }) => id).includes(loggedInPersonId)
      )
    );
  }

  public everySelectedIsActiveOrInitialized(): Observable<boolean> {
    return forkJoin(
      this.selection.map(person =>
        this.store.select(selectMemberByUserOidAndTeamspaceId(person.oid, this.teamspaceId)).pipe(
          take(1),
          withLatestFrom(this.peopleWithStatuses$),
          map(([member, people]) => {
            const personWithStatus = people.find(({ id }) => id === person.id);
            const acceptableStatuses = [MembershipStatus.ACTIVE, MembershipStatus.INIT];
            return (
              (member?.status && acceptableStatuses.includes(member.status)) ||
              acceptableStatuses.includes(personWithStatus?.status)
            );
          })
        )
      )
    ).pipe(map(activeApprovedMembers => activeApprovedMembers.every(Boolean)));
  }

  private getPersonData(person: Person): Observable<PersonData> {
    const roles = this.workroomWizardPeopleService.getRoles(person);
    const mainRole = roles.includes(Roles.MODERATOR) ? Roles.MODERATOR : roles[0];
    return this.store.select(selectMemberByUserOidAndTeamspaceId(person.oid, this.teamspaceId)).pipe(
      take(1),
      withLatestFrom(this.peopleWithStatuses$),
      map(([member, peopleWithStatuses]) => {
        const personWithStatus = peopleWithStatuses.find(({ id }) => id === person.id);
        return {
          person,
          invitationStatus:
            (member?.invitationStatus || personWithStatus.invitationStatus) ?? InvitationStatus.APPROVED,
          role: mainRole
        };
      })
    );
  }
}
