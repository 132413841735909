import { normalize } from 'normalizr';

import { EntityType } from '@celum/core';

import { Entity } from '../model/entity';
import { MetaInfo } from '../model/meta-info.model';

export interface NormalizedResult {
  paginationInformation?: { totalElements: number; elementsFollow: boolean };
  entities: { [key: string]: Entity<any, EntityType>[] };
  combinedEntities: Entity<any, EntityType>[];
  objects: { [key: string]: any[] };
  entityTypes: string[];
}

export class ResultNormalizer {
  public normalize(body: { [key: string]: any }, metaInfo: MetaInfo): NormalizedResult {
    if (!metaInfo || !metaInfo.schema) {
      throw new Error('ResultNormalizerService: Error, no schema defined!');
    }

    // eslint-disable-next-line eqeqeq
    if (body == null) {
      return {
        entities: {},
        combinedEntities: [],
        objects: {},
        entityTypes: []
      };
    }

    const json = body;
    const data = normalize(json, metaInfo.schema);
    const results = data.result[metaInfo.resultKey] || [];
    const entities = data.entities;

    const resultEntities: { [key: string]: Entity<any, EntityType>[] } = {};
    let resultCombinedEntities: Entity<any, EntityType>[] = [];
    const resultObjects: { [key: string]: any[] } = {};
    const entityTypes: string[] = [];

    // extract all entities and add them to result
    metaInfo.entities.forEach(type => {
      if (entities[type]) {
        if (metaInfo.resultTypes.includes(type)) {
          // special handling for entities which are of the type defined as resultTypes -> grab them from "results" in order
          // to keep the order of how they were returned!

          resultEntities[type] = results
            .map(id => {
              return id instanceof Object ? entities[type][id.id] : entities[type][id];
            })
            .filter(e => !!e);
        } else {
          resultEntities[type] = Object.keys(entities[type]).map((id: string) => entities[type][id]);
        }
      } else {
        resultEntities[type] = [];
      }

      entityTypes.push(type);
    });

    if (metaInfo.resultTypes.length > 1) {
      resultCombinedEntities = results.filter(id => id instanceof Object).map(id => entities[id.schema][id.id]);
    }

    // add all entities from the normalized result to the resultObjects that are not already in resultEntities...
    Object.keys(entities)
      .filter(entityType => !metaInfo.entities.has(entityType))
      .forEach(entityType => {
        resultObjects[entityType] = Object.keys(entities[entityType]).map(key => entities[entityType][key]);
      });

    if (json.paginationInformation) {
      const paginationInformation = {
        elementsFollow: json.paginationInformation.elementsFollow,
        totalElements: json.paginationInformation.totalElementCount
      };

      return {
        paginationInformation: paginationInformation,
        objects: resultObjects,
        entities: resultEntities,
        combinedEntities: resultCombinedEntities,
        entityTypes
      };
    } else {
      return {
        objects: resultObjects,
        entities: resultEntities,
        combinedEntities: resultCombinedEntities,
        entityTypes
      };
    }
  }
}
