<div 
    class="custom-field-card-read-only-view custom-field-card custom-field-card-no-min-height"
    [class.custom-field-card-read-only-view--collapsed]="!isExpanded">
  <div class="custom-field-card-read-only-view_header"
      [ngClass]="{'custom-field-card-read-only-header_with-border': customFieldValue.chips && customFieldValue.chips.length > 0}"
      (click)="$event.stopPropagation(); toggleExpandClicked.emit()">
    <div class="custom-field-card-read-only-view_header-container">
      <div class="custom-field-card-read-only-view_header-title">
        {{ customFieldValue.name || 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.NAME_INPUT_LABEL' | translate }}
      </div>
      <hr class="custom-field-card-read-only-view_header-divider">
      <div class="custom-field-card-read-only-view_header-type">
        {{ 'TEAMSPACE_MANAGEMENT.FIELDS.FIELD_TYPES.' + customFieldValue.type | translate }}
      </div>
    </div>
    <button
        *ngIf="isRemovable"
        mat-button
        celum-square-button
        [disableRipple]="true"
        [matTooltip]="'WORK_SHARED.REMOVE' | translate"
        class="custom-field-card-read-only-view_remove-button"
        (click)="removeClicked.emit()">
      <celum-icon class="custom-field-card-read-only-view_remove-button_icon" [clickable]="true" [configuration]="cancelIcon"></celum-icon>
    </button>
    <collapse-button
        *ngIf="customFieldValue.chips && customFieldValue.chips.length > 0"
        class="custom-field-card-read-only-view_toggle-expansion-button"
        [expanded]="isExpanded"
        [expandTooltip]="'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.EXPANSION.EXPAND_FIELD' | translate"
        [collapseTooltip]="'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.EXPANSION.COLLAPSE_FIELD' | translate"
        [buttonSize]="24"
        [iconSize]="12"
        (toggle)="toggleExpandClicked.emit()">
    </collapse-button>
  </div>

  <div *ngIf="customFieldValue.chips && customFieldValue.chips.length > 0" class="custom-field-card-read-only-view_chips-container">
    <mat-chip-listbox [selectable]='false'>
      <mat-chip-option
        *ngFor="let chip of customFieldValue.chips"
        class="mat-basic-chip">
        {{ chip }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
