import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { CelumDialog, CelumDialogConfiguration, IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { WorkroomDeleteWorkroom } from '@celum/work/app/core/api/workroom';

import { Workroom } from '../../../../../../core/model/entities/workroom';
import { ColorService } from '../../../../../../shared/util/color.service';

@Component({
  selector: 'delete-workroom',
  templateUrl: './delete-workroom-dialog.component.html',
  styleUrls: ['./delete-workroom-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DeleteWorkroomDialog
  extends ReactiveComponent
  implements OnInit, CelumDialog<DeleteWorkroomDialogConfiguration>
{
  public workroomName: string;
  public readonly color: string = '#ff7d09';

  @Output() public readonly onCloseAll: EventEmitter<true | undefined> = new EventEmitter<true | undefined>();

  public deleteIconConfig = IconConfiguration.xLarge('information', '', 64);

  private workroom: Workroom;

  constructor(
    private dialogRef: MatDialogRef<DeleteWorkroomDialog>,
    private store: Store<any>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.workroomName = this.workroom.name;
  }

  public configure(conf: DeleteWorkroomDialogConfiguration): void {
    this.workroom = conf.workroom;
  }

  public onCloseAllClicked() {
    this.onCloseAll.emit();
  }

  public onConfirmedClicked() {
    this.store.dispatch(WorkroomDeleteWorkroom({ workroom: this.workroom }));
    this.dialogRef.close(true);
  }
}

export class DeleteWorkroomDialogConfiguration extends CelumDialogConfiguration {
  constructor(public workroom: Workroom) {
    super('main');

    this.color = ColorService.getColorAsRgbString(workroom.color);
  }
}
