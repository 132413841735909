<celum-dialog (onCloseAll)="cancel()">

  <celum-dialog-header>
    <span [innerHTML]="headerText"></span>
  </celum-dialog-header>

  <celum-dialog-content>

    <div class="finish-workroom-dialog_hint">
      <celum-icon [configuration]="mailHint" [clickable]="false"></celum-icon>
      <span [innerHTML]="personHintText"></span>
    </div>
    <div class="finish-workroom-dialog_hint">
      <celum-icon [configuration]="accessHint" [clickable]="false"></celum-icon>
      <span [innerHTML]="accessHintText"></span>
    </div>
    <div class="finish-workroom-dialog_hint">
      <celum-icon [configuration]="fileHint" [clickable]="false"></celum-icon>
      <span [innerHTML]="unsavedHintText"></span>
    </div>
    <div class="finish-workroom-dialog_hint" *ngIf="openTasks > 0">
      <celum-icon [configuration]="taskHint" [clickable]="false"></celum-icon>
      <span [innerHTML]="openTasksText"></span>
    </div>
    <div class="finish-workroom-dialog_note">
      <celum-icon [configuration]="infoHint" [clickable]="false"></celum-icon>
      <!-- note: days not considered at timed restriction is not implemented. message postfix: _DAYS -->
      <span [innerHTML]="reopenHintText"></span>
    </div>
    <ng-container>
      <mat-divider class="finish-workroom-dialog_divider"></mat-divider>
      <mat-checkbox [(ngModel)]="keepContent"
                    class="finish-workroom-dialog_checkbox">
        {{ 'FINISH_WORKROOM.KEEP_FILES' | translate }}
      </mat-checkbox>
    </ng-container>
  </celum-dialog-content>

  <celum-dialog-footer
      confirmBtnText="{{ 'FINISH_WORKROOM.ACTION' | translate }}"
      (onConfirm)="finishWorkroom()"
      (onCancel)="cancel()"
      [loading]="loading"
      [color]="color">
  </celum-dialog-footer>

</celum-dialog>
