import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { WorkroomGroupItem } from './workroom-group-item.model';

export const WorkroomGroupItemUpsertMany = createAction(
  '[WorkroomGroupItem] Upsert Many',
  props<{ workroomGroupItems: WorkroomGroupItem[] }>()
);

export const WorkroomGroupItemUpdateOne = createAction(
  '[WorkroomGroupItem] Update One',
  props<{ groupToUpdate: Update<WorkroomGroupItem> }>()
);

export const WorkroomGroupItemMove = createAction(
  '[WorkroomGroupItem] Move',
  props<{
    sourceGroupId: number;
    targetGroupId: number;
    workroomGroupItemId: number;
    currentSort: number;
    previousSort: number;
  }>()
);

export const WorkroomGroupItemMoveFailed = createAction(
  '[WorkroomGroupItem] Move Failed',
  props<{ sourceGroupId: number; targetGroupId: number; workroomGroupItemId: number; sort: number }>()
);
