import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { Folder } from '@celum/work/app/core/model/entities/folder/folder.model';
import { Person } from '@celum/work/app/core/model/entities/person/index';
import { TaskList } from '@celum/work/app/core/model/entities/task';

import { Automator, Robots, RobotTypes } from '../../../../core/model/entities/workroom/robot.model';
import { DateUtil } from '../../../../shared/util';
import { RobotChipConfig } from '../../../services/robots-factory';
import { getTaskCreatedTranslationKeyForContentHub } from '../robot-components.model';

export abstract class BaseRobotConfigService {
  constructor(
    public translate: TranslateService,
    public dateUtil?: DateUtil
  ) {}

  public abstract serviceMatchesRobot(robot: Robots): boolean;
  public abstract getRobotChipConfig(robot: Robots, sourceEntity?: any): Observable<Partial<RobotChipConfig>>;

  public robotBelongsToTaskList(robot: Robots, taskList: TaskList) {
    return false;
  }
  public robotBelongsToFolder(robot: Robots, folder: Folder) {
    return false;
  }

  public isRobotNotApplied(_params: RobotNotAppliedConfig): Observable<boolean> {
    return of(false);
  }

  public getContentHubRows(robot: Automator): [string, string] {
    const rows: [string, string] = ['', ''];
    rows[0] = this.translate.instant('ROBOTS.CHIP.FIRST_ROW.' + robot.action.type);
    const triggerTranslationKey = getTaskCreatedTranslationKeyForContentHub(robot.trigger.type);
    rows[1] = this.translate.instant('ROBOTS.CHIP.SECOND_ROW.' + triggerTranslationKey);
    return rows;
  }
}

export interface RobotNotAppliedConfig {
  robot: Robots;
  type?: RobotTypes;
  sourceEntity?: any;
  invitedPeople?: Person[];
  teamspaceId?: number;
  workroomId?: number;
  isRobotBeingCreated: boolean;
}
