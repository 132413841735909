import { AvatarConfigBuilder, AvatarConfiguration } from '@celum/common-components';
import { Roles } from '@celum/work/app/core/model';
import { Member } from '@celum/work/app/core/model/entities/member';
import { Person } from '@celum/work/app/core/model/entities/person/person.model';

// @dynamic
export class WorkroomAvatarConfiguration extends AvatarConfiguration {
  public person: Person;
  public interactive = false;
  public member: Member;
  public roles: Roles[];

  public static compare(a: WorkroomAvatarConfiguration, b: WorkroomAvatarConfiguration): number {
    if (a.cssClass === b.cssClass) {
      return 0;
    }

    // users who are creator AND moderator are listed first (there can only be one per workroom)
    if (a.cssClass.indexOf('creator') > -1 && a.cssClass.indexOf('moderator') > -1) {
      return -1;
    }

    if (b.cssClass.indexOf('creator') > -1 && b.cssClass.indexOf('moderator') > -1) {
      return 1;
    }

    // other moderators are listed next
    // note: creator who is not moderator anymore does not result in special highlighting
    return a.cssClass.indexOf('moderator') > -1 ? -1 : 1;
  }
}

export class WorkroomAvatarConfigBuilder extends AvatarConfigBuilder<WorkroomAvatarConfiguration> {
  public withInteractive(interactive: boolean): WorkroomAvatarConfigBuilder {
    this.config.interactive = interactive;
    return this;
  }

  public withPerson(person: Person): WorkroomAvatarConfigBuilder {
    this.config.person = person;
    return this;
  }

  public withMember(member: Member): WorkroomAvatarConfigBuilder {
    this.config.member = member;
    return this;
  }

  public build(): WorkroomAvatarConfiguration {
    return this.config;
  }

  protected createConfig(): WorkroomAvatarConfiguration {
    return new WorkroomAvatarConfiguration();
  }
}
