<celum-dialog (onCloseAll)="closeDialog()">
  <celum-dialog-header>
      {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CREATE_FIELD_DIALOG.EDIT_HEADING' | translate }}
  </celum-dialog-header>
  <celum-dialog-content>
    <message-box
        *ngIf="fieldInUseWarning"
        type="warn"
        [text]="fieldInUseWarning | translate: { count: editedField.formsUsageCounter }">
    </message-box>
    <custom-field-card [formControl]="customFieldCard" [isTypeChangingAllowed]="fieldInUseWarning === null"></custom-field-card>
  </celum-dialog-content>
  <celum-dialog-footer
      [confirmBtnText]="'COMMON.SAVE'"
      cancelBtnText="COMMON.CANCEL"
      [loading]="loading"
      [valid]="
        (!customFieldCard.pristine && !customFieldCard.invalid) ||
        (customFieldCard.invalid && customFieldCard.hasError(customFieldCardValidationErrors.NAME_NOT_UNIQUE))
      "
      (onConfirm)="onSubmit()"
      (onCancel)="closeDialog()">
  </celum-dialog-footer>
</celum-dialog>
