<div class="task-list-columns-menu-item_text">{{'TASK_BOARD.ACTIONS.TASK_GRID' | translate}}</div>
<div class="task-list-columns-menu-item_wrap">
  <div class="task-list-columns-menu-item_lines-entry"
       [class.task-list-columns-menu-item_lines-entry--active]="visibleColumns === 0"
       data-cy="task-list-visible-columns-setting-0"
       (click)="changeVisibleColumns(0)">
    <span>0</span>
    <celum-icon [configuration]="visibleColumns === 0 ? collapseIconActive : collapseIconInactive"></celum-icon>
  </div>
  <ng-container *ngFor="let visibleColumnsSetting of possibleVisibleColumns; trackBy: trackByFn">
    <task-list-column-setting [active]="visibleColumns === visibleColumnsSetting"
                              [columns]="visibleColumnsSetting"
                              (click)="changeVisibleColumns(visibleColumnsSetting)">
    </task-list-column-setting>
  </ng-container>

</div>
