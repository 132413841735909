import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContentHubBuildNumberStrategyResolverService } from '@celum/work/app/content-hub/services/content-hub-build-number-strategy-resolver.service';
import { RobotActionType, RobotTriggerType } from '@celum/work/app/core/model/entities/workroom/robot.model';
import { AutomatorService } from '@celum/work/app/robots/services/automator.service';
import { RobotServiceArgs } from '@celum/work/app/robots/services/robot.service';
import { isNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

@Injectable({ providedIn: 'root' })
export class ContentHubWatchCollectionsAutomatorService extends AutomatorService {
  constructor(private chStrategyResolver: ContentHubBuildNumberStrategyResolverService) {
    super();
  }

  public getAllTriggers(): Observable<RobotTriggerType[]> {
    const triggers = [RobotTriggerType.CONTENT_HUB_ASSET_ADDED];

    return of(triggers);
  }

  public getAllActions(): Observable<RobotActionType[]> {
    const actions = [RobotActionType.CONTENT_HUB_IMPORT_ASSET];

    return of(actions);
  }

  public getAutomatorPreselection(): Observable<{ triggerType: RobotTriggerType; actionType: RobotActionType }> {
    return of({
      triggerType: RobotTriggerType.CONTENT_HUB_ASSET_ADDED,
      actionType: RobotActionType.CONTENT_HUB_IMPORT_ASSET
    });
  }

  public getDisabledTooltipKey(args: RobotServiceArgs): Observable<string | null> {
    if (args.isTemplate) {
      return of('ROBOTS.CONTENT_HUB.WATCH_COLLECTION.DISABLE_TOOLTIPS.TEMPLATE');
    }

    if (!args.repositoryId) {
      return of('ROBOTS.CONTENT_HUB.WATCH_COLLECTION.DISABLE_TOOLTIPS.REPOSITORY_NEEDED');
    }

    return this.chStrategyResolver.resolve(args.repositoryId).pipe(
      map(strategy => {
        if (isNullOrUndefined(strategy) || !strategy.supportsImportCollection()) {
          return 'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.DISABLE_TOOLTIPS.CONTENT_HUB_TOO_OLD';
        }
      })
    );
  }
}
