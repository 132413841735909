import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { selectLibraryId } from '@celum/work/app/core/model/entities/workroom';
import { selectCurrentWorkroomId } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { selectImportIntentCollectionName } from '@celum/work/app/pages/workroom-creator/store/workroom-creator.selectors';
import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { Workroom } from './../../../../core/model/entities/workroom/workroom.model';
import { MessageBoxConfigType } from './../../../../shared/components/message/message-box';
import { RobotDialogConfiguration } from './../../robot-dialog/robot-dialog-config';
import {
  SelectFoldersDialogComponent,
  SelectFoldersDialogConfiguration
} from './select-folders-dialog/select-folders-dialog.component';
import { WatchCollectionsAutomatorService } from './watch-collections-automator.service';
import { selectedFoldersChanged, trackCollectionFn } from './watch-collections-automator.util';
import { ImportIntent } from '../../../../content-hub/model/import-intent.model';
import { selectImportIntentById } from '../../../../content-hub/store/import-intent.selector';
import { TenantGuard } from '../../../../core/auth/tenant.guard';
import {
  Automator,
  AutomatorSubType,
  AutomatorType,
  ContentHubAssetAddedTrigger,
  RobotActionType,
  RobotContext,
  RobotTriggerType
} from '../../../../core/model/entities/workroom/robot.model';
import { selectRouterQueryParam } from '../../../../core/router/router.selectors';
import { ContentHubWatchCollectionsAutomatorService } from '../../../services/content-hub-watch-collections-automator.service';
import { RobotsFactory } from '../../../services/robots-factory';
import { BaseRobot } from '../base-robot/base-robot.component';

export interface ImportedFolderData {
  collectionId: number;
  folderId: string;
  folderName: string;
}

@Component({
  selector: 'watch-collections-automator',
  templateUrl: './watch-collections-automator.component.html',
  styleUrls: ['./watch-collections-automator.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WatchCollectionsAutomatorComponent extends BaseRobot<Workroom> {
  public readonly TARGETING_ROBOT_TYPE = AutomatorType.CONTENT_HUB;

  public collectionIcon: IconConfiguration;
  public cancelIcon: IconConfiguration;
  public contentHubFolderIcon: IconConfiguration;

  public allCollections: ImportedFolderData[] = [];
  public selectedCollections: ImportedFolderData[] = [];
  public initialySelectedCollections: ImportedFolderData[] = [];
  public trackByFn = trackCollectionFn;
  public selectedImportIntent: ImportIntent;
  public importIntentCollectionName$: Observable<string>;

  constructor(
    protected robotsFactory: RobotsFactory,
    protected translateService: TranslateService,
    protected automatorService: ContentHubWatchCollectionsAutomatorService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogOpener: CelumDialogOpener,
    private endpointsService: WatchCollectionsAutomatorService,
    private store: Store<any>
  ) {
    super(robotsFactory, translateService, automatorService);
  }

  public get messageBoxes$(): Observable<MessageBoxConfigType[]> {
    return this.notApplied$.pipe(
      withLatestFrom(this.store.select(selectCurrentWorkroomId)),
      map(([notApplied, workroomId]) => {
        return notApplied ? this.createWarningMessage(notNullOrUndefined(workroomId)) : this.createInfoMessage();
      })
    );
  }

  public createInfoMessage(): MessageBoxConfigType[] {
    return [
      {
        type: 'info',
        text: this.translateService.instant(`ROBOTS.CONTENT_HUB.WATCH_COLLECTION.INFO_NOTE`)
      }
    ];
  }

  public createWarningMessage(insideExistingWorkroom: boolean): MessageBoxConfigType[] {
    return insideExistingWorkroom
      ? [
          {
            type: 'warn',
            text: this.translateService.instant(`ROBOTS.CONTENT_HUB.WATCH_COLLECTION.ERROR_NOTE`)
          }
        ]
      : [
          {
            type: 'warn',
            text: this.translateService.instant(`ROBOTS.CONTENT_HUB.WATCH_COLLECTION.NO_COLLECTION_YET_ERROR_NOTE`)
          }
        ];
  }

  public configure(configuration: RobotDialogConfiguration<Workroom>): void {
    super.configure(configuration);

    this.initializeIcons();
    this.initSelectedCollections(configuration.args.workroomId);
    this.handleImportIntent();
  }

  public removeCollection(folderId: string): void {
    this.selectedCollections = this.selectedCollections.filter(collection => collection.folderId !== folderId);
  }

  public isValid(): boolean {
    return (
      (this.selectedCollections.length !== 0 &&
        selectedFoldersChanged(this.initialySelectedCollections, this.selectedCollections)) ||
      this.selectedImportIntent?.collectionCounter > 0
    );
  }

  public async openSelectCollectionsFolder(): Promise<void> {
    const selectedCollections = await this.dialogOpener.showDialog(
      SelectFoldersDialogComponent.DIALOG_ID,
      SelectFoldersDialogComponent,
      new SelectFoldersDialogConfiguration(this.allCollections, this.selectedCollections),
      { disableClose: false }
    );

    if (!selectedCollections) {
      return;
    }

    this.selectedCollections = selectedCollections;

    this.changeDetectorRef.detectChanges();
  }

  public getResult(): Automator {
    const trigger: ContentHubAssetAddedTrigger = {
      type: RobotTriggerType.CONTENT_HUB_ASSET_ADDED,
      collectionFolderPairs: this.selectedCollections.map(({ folderName, ...pair }) => pair)
    };

    return {
      type: AutomatorType.CONTENT_HUB,
      sourceContext: RobotContext.CONTENT_ITEM,
      subType: AutomatorSubType.CONTENT_HUB_ASSETS,
      action: {
        type: RobotActionType.CONTENT_HUB_IMPORT_ASSET
      },
      trigger
    };
  }

  private handleImportIntent(): void {
    // TODO @anyone refactor this and isValid BASE function to return observables
    // it would be  nicer to do it the rxJS way and not subscribe just to unwrap the value
    // and then use it unwrapped  in the app
    this.store
      .select(selectRouterQueryParam(TenantGuard.IMPORT_INTENT_PARAM))
      .pipe(
        take(1),
        switchMap(importIntentId => this.store.select(selectImportIntentById(importIntentId)))
      )
      .subscribe(importIntent => (this.selectedImportIntent = importIntent));

    this.importIntentCollectionName$ = this.store
      .select(selectImportIntentCollectionName)
      .pipe(map(collectionName => collectionName));
  }

  private initSelectedCollections(workroomId: number): void {
    const automator = this.robot as Automator;
    const collectionFolderPairs = automator
      ? (automator.trigger as ContentHubAssetAddedTrigger).collectionFolderPairs
      : [];

    this.selectedCollections = collectionFolderPairs.map(collectionFolderPair => ({
      ...collectionFolderPair,
      folderName: ''
    }));
    this.initialySelectedCollections = this.selectedCollections;

    if (notNullOrUndefined(workroomId)) {
      this.configureSelectedCollectionNames(workroomId);
    }
  }

  private configureSelectedCollectionNames(workroomId: number) {
    this.store
      .select(selectLibraryId(workroomId))
      .pipe(
        take(1),
        switchMap(libraryId => this.endpointsService.getImportedFolders(libraryId))
      )
      .subscribe((collections: ImportedFolderData[]) => {
        this.allCollections = collections;

        const collectionNamesDictionary = collections.reduce(
          (acc, curr) => ({ ...acc, [curr.folderId]: curr.folderName }),
          {}
        );
        this.selectedCollections.forEach(
          collection => (collection.folderName = collectionNamesDictionary[collection.folderId])
        );

        this.changeDetectorRef.markForCheck();
      });
  }

  private initializeIcons(): void {
    this.collectionIcon = IconConfiguration.small('ch-collection').withIconSize(30);
    this.cancelIcon = IconConfiguration.small('cancel-s');
    this.contentHubFolderIcon = IconConfiguration.large('icon-content-hub-folder').withIconSize(this.iconSize);
    this.automatorIcon = IconConfiguration.large('icon-general-robot-import').withIconSize(this.iconSize);
  }
}
