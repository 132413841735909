import { PageContext } from '@celum/common-components';
import { Operation, OperationGroup } from '@celum/core';

export const SEPARATOR = 'context-menu-sep' as const;

export interface OperationGroupWrapper {
  group: OperationGroup;
  operationsAndSubGroups: OperationOrGroup[];
}

export interface OperationMenuInfo {
  position?: ContextMenuPosition;
  context?: PageContext;
  operations?: OperationOrGroup[];
}

export interface ContextMenuPosition {
  x: number;
  y: number;
}

export type OperationOrGroup = Operation | typeof SEPARATOR | OperationGroupWrapper;
