import { createSelector } from '@ngrx/store';

import { selectCurrentFileId, selectFileEntities } from '@celum/work/app/core/model/entities/file/file.selectors';
import { RuleType } from '@celum/work/app/core/model/entities/workroom/robot.model';
import { selectRouterParam } from '@celum/work/app/core/router/router.selectors';
import { selectLoggedInPersonId } from '@celum/work/app/core/ui-state/ui-state.selectors';
import {
  selectCurrentWorkroom,
  selectCurrentWorkroomId
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { hasTaskListRule, isTaskListRobot } from '@celum/work/app/robots/services/robots-util';
import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { taskAdapter } from './task.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';
import { Person } from '../person';

const { selectEntities, selectAll } = taskAdapter.getSelectors();

export const selectTasks = createSelector(selectEntitiesState, (state: EntitiesState) => state.tasks);

export const selectAllTasks = createSelector(selectTasks, selectAll);
export const selectTaskEntities = createSelector(selectTasks, selectEntities);

export const selectTaskById = (id: number) => createSelector(selectTaskEntities, taskDictionary => taskDictionary[id]);

export const selectCurrentTaskAttachment = createSelector(
  selectCurrentFileId,
  selectFileEntities,
  (attachmentId, fileEntities) => attachmentId && fileEntities[attachmentId]
);

export const selectCurrentTaskId = createSelector(selectRouterParam('taskId'), taskId => taskId);

export const selectCurrentTask = createSelector(
  selectCurrentTaskId,
  selectTaskEntities,
  (taskId, tasks) => tasks[taskId]
);

export const selectTaskPreviewByTaskId = (taskId: number) =>
  createSelector(selectTaskById(taskId), selectFileEntities, (task, fileEntities) => fileEntities[task?.previewFileId]);

// FE receives the attachments already sorted by attachment date, in descending order
export const selectSortedAttachmentsByTaskId = (taskId: number) =>
  createSelector(
    selectTaskById(taskId),
    selectFileEntities,
    (task, fileEntities) =>
      task?.attachmentIds.map(id => fileEntities[id]).filter(attachment => notNullOrUndefined(attachment)) || []
  );

export const selectTaskIdsByAttachmentId = (fileId: string) =>
  createSelector(selectAllTasks, tasks =>
    tasks.filter(task => task.attachmentIds.includes(fileId)).map(task => task.id)
  );

export const selectTasksOfCurrentWorkroom = createSelector(
  selectAllTasks,
  selectCurrentWorkroomId,
  (tasks, workroomId) => tasks.filter(task => task.workroomId === workroomId)
);

export const selectIsTaskCreator = (person: Person) =>
  createSelector(selectCurrentTask, task => task?.createdBy === person.id);

export const selectIsTaskAssignee = (person: Person) =>
  createSelector(selectCurrentTask, task => task?.assigneeIds.includes(person.id));

export const selectLoggedInPersonIsTaskAssignee = createSelector(
  selectCurrentTask,
  selectLoggedInPersonId,
  (task, loggedInPersonId) => task?.assigneeIds.includes(loggedInPersonId)
);
export const selectTaskHasTaskEditingRestrictionRobot = createSelector(
  selectCurrentTask,
  selectCurrentWorkroom,
  (task, workroom) =>
    workroom?.configuration?.rules.some(
      rule => isTaskListRobot(task?.taskListId, rule) && rule.type === RuleType.TASK_EDITING_RESTRICTION
    )
);

export const selectTaskHasTaskCreationRestrictionRobot = createSelector(
  selectCurrentTask,
  selectCurrentWorkroom,
  (task, workroom) => hasTaskListRule(workroom?.configuration, task?.taskListId, RuleType.TASK_CREATION_RESTRICTION)
);
