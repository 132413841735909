<div class="due-date_wrapper">
  <!-- If only calendar icon is displayed (e.g. task list visible columns === 0) -->
  <ng-container *ngIf="displayCalendarIconOnly || !isDueDateSet">
    <!-- Calendar icon with the date tooltip-->
    <ng-container *ngIf="isDueDateSet">
      <celum-icon
          [configuration]="dueDateIcon"
          [matTooltip]="tooltipInfo"
          [matTooltipDisabled]="isTooltipDisabled"
          [clickable]="hasPermission"
          (click)="addDueDate()">
      </celum-icon>
    </ng-container>

    <!-- Add due date button -->
    <ng-container *ngIf="!isDueDateSet && hasPermission">
      <celum-icon
          *ngIf="hasPermission"
          [configuration]="addDueDateIcon"
          [matTooltip]="tooltipInfo"
          [matTooltipDisabled]="isTooltipDisabled"
          (click)="addDueDate()">
      </celum-icon>
    </ng-container>

    <!-- No permission to change due date button -->
    <ng-container *ngIf="!isDueDateSet && !hasPermission">
      <celum-icon
          [configuration]="noPermissionDueDateIcon"
          [matTooltip]="tooltipInfo"
          [matTooltipDisabled]="isTooltipDisabled"
          [clickable]="false">
      </celum-icon>
    </ng-container>
  </ng-container>

  <!-- Due date label -->
  <ng-container *ngIf="displayLabelOnly">
    <div class="due-date-label"
         data-cy="due-date-label"
         [class.due-date--set]="isDueDateSet && hasPermission"
         [style.backgroundColor]="getBackgroundColor()"
         [matTooltip]="tooltipInfo"
         [matTooltipPosition]="'below'"
         [matTooltipDisabled]="isTooltipDisabled"
         [attr.data-has-permission]="hasPermission">
      {{ dueDateDisplayText }}
    </div>
  </ng-container>

  <!-- Icon with due date text -->
  <ng-container *ngIf="!displayCalendarIconOnly && !displayLabelOnly && isDueDateSet">
    <div class="due-date"
         [class.due-date--set]="isDueDateSet && hasPermission && !displayCalendarIconOnly"
         [style.backgroundColor]="getBackgroundColor()"
         [attr.data-has-permission]="hasPermission">
      <!-- Calendar with due date text -->
      <celum-icon
          [class.due-date_add-icon]="hasPermission"
          [matTooltip]="tooltipInfo"
          [matTooltipPosition]="'below'"
          [matTooltipDisabled]="isTooltipDisabled"
          [configuration]="dueDateIconWithText"
          [clickable]="hasPermission"
          (click)="addDueDate()">
      </celum-icon>

      <!-- X icon to remove due date -->
      <button
          *ngIf="hasPermission"
          mat-button celum-square-button
          [matTooltip]="'ROBOTS.CHIP.FIRST_ROW.TASK_REMOVE_DUE_DATE' | translate"
          (click)="removeDueDate()">
        <celum-icon
            class="due-date_wrapper_remove-date"
            [configuration]="removeIcon"
            data-operation-id="remove-due-date">
        </celum-icon>
      </button>
    </div>
  </ng-container>

  <input
      class="due-date_input"
      matInput
      [matDatepicker]="datePicker"
      [min]="minDate"
      [formControl]="calendarDueDateCtrl"
      (dateChange)="setNewDueDate($event.value)">
  <mat-datepicker #datePicker></mat-datepicker>
</div>
