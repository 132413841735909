import { Store } from '@ngrx/store';

import { EntityTranslator } from '@celum/work/app/core/model';
import { selectRenditionsForFileVersion } from '@celum/work/app/core/model/entities/file-version/file-version.selectors';

import { FileVersion, FileVersionType } from './file-version.model';

export class FileVersionTranslator implements EntityTranslator<FileVersion> {
  public translateToEntity(json: any): FileVersion {
    return {
      id: json.id,
      renditionsIds: [],
      fileId: json.fileId,
      originalFileSize: json.originalFileSize,
      versionNumber: json.versionNumber,
      downloadUrl: json.downloadUrl,
      name: json.name,
      format: json.format,
      creatorName: json.creatorName,
      uploadedDate: json.uploadedDate,
      origin: json.origin,
      entityType: FileVersionType.instance(),
      renditions: (store: Store<any>) => store.select(selectRenditionsForFileVersion(json.id))
    };
  }
}
