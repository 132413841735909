import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

import {
  AuthService,
  TenantService as AuthTenantService,
  CelumAuthModule,
  ServiceAccessTokenProvider
} from '@celum/authentication';
import { CelumSnackbarModule } from '@celum/common-components';
import { CelumSnackbarListModule, SnackbarList } from '@celum/internal-components';
import { SystembarComponent } from '@celum/shared/ui';
import { AuthInterceptor } from '@celum/work/app/core/auth/auth-interceptor.service';
import { DummyRedirectToComponent } from '@celum/work/app/core/auth/dummy-redirect-to.component';
import { TenantService } from '@celum/work/app/core/auth/tenant.service';
import { RetryInterceptor } from '@celum/work/app/core/communication/retry.interceptor';
import { CoreModule } from '@celum/work/app/core/core.module';
import { ProgressTaskSnackbarGroupWrapperComponent } from '@celum/work/app/progress-task/components/progress-task-snackbar-group-wrapper/progress-task-snackbar-group-wrapper.component';
import { ProgressTaskModule } from '@celum/work/app/progress-task/progress-task.module';
import { RobotNotificationSnackbarGroupComponent } from '@celum/work/app/robots/notification-snackbar-group/robot-notification-snackbar-group.component';
import { RobotNotificationSnackbarGroupModule } from '@celum/work/app/robots/notification-snackbar-group/robot-notification-snackbar-group.module';
import { SharedModule } from '@celum/work/app/shared';
import { DateModule } from '@celum/work/app/shared/date.module';
import { TeamspaceManagementModule } from '@celum/work/app/teamspace-management/teamspace-management.module';

import { initializer } from './app-init';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LongRequestInterceptor } from './core/communication/long-request.interceptor';
import { WorkroomCreatorModule } from './pages/workroom-creator/workroom-creator.module';

export const CUSTOM_TOOLTIP_DEFAULTS: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity: true
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    NgxPermissionsModule.forRoot(),
    CelumAuthModule.forRoot(),
    CoreModule,
    CelumSnackbarModule,
    CelumSnackbarListModule,
    SharedModule,
    AppRoutingModule,
    WorkroomCreatorModule,
    ProgressTaskModule,
    RobotNotificationSnackbarGroupModule,
    TeamspaceManagementModule,
    DateModule.forRoot(),
    SystembarComponent
  ],
  declarations: [AppComponent, DummyRedirectToComponent],
  providers: [
    {
      provide: APP_BASE_HREF,
      deps: [TenantService, AuthTenantService],
      useFactory: baseHrefFactory
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [NgxPermissionsService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LongRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      deps: [TenantService, Store, AuthService, ServiceAccessTokenProvider],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CUSTOM_TOOLTIP_DEFAULTS },
    CookieService
  ],
  bootstrap: [
    AppComponent,
    SnackbarList,
    ProgressTaskSnackbarGroupWrapperComponent,
    RobotNotificationSnackbarGroupComponent
  ]
})
export class AppModule {}

function baseHrefFactory(tenantService: TenantService, authTenantService: AuthTenantService): string {
  const urlTenantId: string = tenantService.getTenantFromUrl();

  if (urlTenantId) {
    tenantService.storeTenant(urlTenantId);
  }

  const resolvedTenantId = urlTenantId ?? tenantService.getStoredTenant();

  // Needed for the shared systembar
  authTenantService.storeTenant(resolvedTenantId);

  return resolvedTenantId ? `/tenant/${resolvedTenantId}` : '/';
}
