import { ChangeDetectionStrategy, Component, Optional, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Roles } from '@celum/work/app/core/model';
import { MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import { RobotContext, Rule, RuleType } from '@celum/work/app/core/model/entities/workroom/robot.model';
import { selectAllPersonsHaveSpecificRole } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { RobotDialogConfiguration } from '@celum/work/app/robots/components/robot-dialog/robot-dialog-config';
import { BaseRobot } from '@celum/work/app/robots/components/robot-dialog-templates/base-robot/base-robot.component';
import { TokenStyle } from '@celum/work/app/robots/components/robot-dialog-templates/robot-components.model';
import { RobotsFactory } from '@celum/work/app/robots/services/robots-factory';
import { MessageBoxConfigType } from '@celum/work/app/shared/components/message/message-box';
import { WorkroomWizardPeopleService } from '@celum/work/app/workroom-wizard/services/workroom-wizard-people.service';

@Component({
  selector: 'task-list-owner',
  templateUrl: './task-list-owner-rule.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TaskListOwnerRuleComponent extends BaseRobot<TaskList> {
  public readonly TARGETING_ROBOT_TYPE = RuleType.TASK_EDITING_RESTRICTION;
  public possibleTypes: RuleType[];

  private readonly DEFAULT_COMPONENT_KEY: string = 'DEFAULT_COMPONENT_KEY';

  constructor(
    protected robotsFactory: RobotsFactory,
    protected translateService: TranslateService,
    @Optional() private workroomWizardPeopleService: WorkroomWizardPeopleService,
    private store: Store
  ) {
    super(robotsFactory, translateService, null);
  }

  public get messageBoxes$(): Observable<MessageBoxConfigType[]> {
    const ownerIds = this.sourceEntity.ownerIds;
    return combineLatest([
      this.notApplied$,
      this.store.select(selectAllPersonsHaveSpecificRole(ownerIds, Roles.VISITOR))
    ]).pipe(
      map(([notApplied, allStoreTLOsHaveVisitorRole]) => {
        const taskListOwners = this.templatePeople.filter(({ id }) => ownerIds.includes(id));
        const pendingInvitedTLOs =
          this.workroomWizardPeopleService?.invitedPeople.filter(person =>
            taskListOwners.find(owner => owner.id === person.personId)
          ) || [];
        const isAnyTaskListOwnerActive = taskListOwners.some(({ status }) => status === MembershipStatus.ACTIVE);
        const isEveryPendingTLOVisitor = pendingInvitedTLOs.every(person => person.roles.includes(Roles.VISITOR));
        return this.resolveMessages(
          notApplied,
          isAnyTaskListOwnerActive,
          allStoreTLOsHaveVisitorRole || isEveryPendingTLOVisitor
        );
      })
    );
  }

  public configure(configuration: RobotDialogConfiguration<TaskList>): void {
    super.configure(configuration);
    this.possibleTypes = this.robotsFactory.getPossibleTaskListOwnerRuleTypes();
    this.preselectType();
    this.splitIntoTokens();
  }

  public getThenTokenStyle(token: string) {
    return super.getThenTokenStyle(this.DEFAULT_COMPONENT_KEY, token);
  }

  public resolveMessages(
    notApplied: boolean,
    anyTaskListOwnerActive: boolean,
    allTLOsHaveVisitorRole: boolean
  ): MessageBoxConfigType[] {
    const resolvedMessages = [];
    if (notApplied) {
      return [
        {
          type: 'warn',
          text: this.translateService.instant('ROBOTS.TASK_LIST_OWNER.ERROR')
        }
      ];
    }

    if (!anyTaskListOwnerActive) {
      resolvedMessages.push({
        type: 'info',
        text: this.translateService.instant('ROBOTS.TASK_LIST_OWNER.INFO')
      });
    }

    if (allTLOsHaveVisitorRole && this.selectedRuleType === RuleType.TASK_EDITING_RESTRICTION) {
      resolvedMessages.push({
        type: 'info',
        text: this.translateService.instant('ROBOTS.TASK_LIST_OWNER.VISITOR_INFO')
      });
    }

    return resolvedMessages;
  }

  public getResult(): Rule {
    return {
      type: this.selectedRuleType,
      sourceContext: RobotContext.TASK_LIST,
      sourceId: this.sourceEntity.id
    };
  }

  public getTypeTranslation(selectedType: RuleType): string {
    return `ROBOTS.TASK_LIST_OWNER.RULE.${selectedType}`;
  }
  public getThenTokens(): TokenStyle[] {
    return this.thenTokens[this.DEFAULT_COMPONENT_KEY].textTokens;
  }

  private splitIntoTokens(): void {
    this.thenTokens[this.DEFAULT_COMPONENT_KEY] = this.tokenizeTranslation(`ROBOTS.TASK_LIST_OWNER.ROW2`);
  }

  private preselectType(): void {
    if (this.selectedRuleType) {
      return;
    }

    this.selectedRuleType = this.possibleTypes[0];
  }
}
