import { Person } from '@celum/work/app/core/model/entities/person/person.model';
import { Task } from '@celum/work/app/core/model/entities/task';
import { Roles } from '@celum/work/app/core/model/roles.model';

export function getMainRole(roles: Roles[]): Roles {
  return roles.includes(Roles.MODERATOR) ? Roles.MODERATOR : roles[0];
}

export function getAllRoles(mainRole: Roles): Roles[] {
  return mainRole === Roles.MODERATOR ? [Roles.MODERATOR, Roles.CONTRIBUTOR] : [mainRole];
}

export function getPersonRolesFromTask(task: Task, person: Person): Roles[] {
  const roles = [];
  for (const role in Roles) {
    if (task.workroomAdditionalInfo?.peopleByRoles[role]?.includes(person.id)) {
      roles.push(role);
    }
  }

  return roles;
}
