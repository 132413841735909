<celum-standard-dialog confirmBtnText="{{ confirmationButtonText | translate }}"
                       headerText="{{ dialogTitle | translate }}"
                       [color]="color"
                       [disableOnConfirmClosing]="disableOnConfirmClosing"
                       [loading]="loading"
                       (onConfirm)="okClicked()">

  <div class="person-dialog_person-list">
    <div>
      <celum-avatar-list [avatarConfigs]="getAvatarConfigs() | async">
        <ng-template let-config="config">
          <workroom-avatar [configuration]="config"></workroom-avatar>
        </ng-template>
      </celum-avatar-list>
    </div>
  </div>

  <div *ngIf="additionalInformation" class="person-dialog_content-row person-dialog_task-info text">
    <celum-icon [configuration]="infoIcon" class="person-dialog_info-icon" [clickable]="false"></celum-icon>
    {{ additionalInformation | translate: additionalParam }} <br> {{ 'PEOPLE.REMOVE.TUBEMAIL_INFORMATION' | translate }}
  </div>

  <div *ngIf="additionalHtmlInformation" class="person-dialog_content-row person-dialog_html-info text">
    <div [innerHtml]="sanitizeHtml(additionalHtmlInformation | translate: additionalParam)"></div>
  </div>

  <div *ngIf="hasDriveSubscription" class="person-dialog_content-row person-dialog_html-info text person-dialog_keep-content-wrapper">
    <mat-checkbox [(ngModel)]="keepContent">{{ 'DRIVE_SUBSCRIPTION_DIALOG.UNSUBSCRIBE.KEEP_CONTENT' | translate }}</mat-checkbox>
  </div>

</celum-standard-dialog>
