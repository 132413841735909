<fullscreen-dialog [title]="data.title" [light]="true" [actions]="actions" [loading]="loading">
  <stepper #stepper linear [stepsConfiguration]="stepsConfiguration" [collapseButtonTooltipConfig]="collapseButtonTooltipConfig">
    <cdk-step [completed]="workroomWizardForm.valid">
      <workroom-details #workroomDetails
          [workroomsLimitReached]="workroomsLimitReached$ | async"
          [workroomWizardForm]="workroomWizardForm"
          [workroomWizardType]="data.type"
          [permissionStrategy]="permissionStrategy"
          [template]="data.template"
          [translations]="translations">
      </workroom-details>
    </cdk-step>
    <cdk-step [class.stepper_step--disabled]="workroomWizardForm.invalid" [optional]="true">
      <taskboard-and-robots
          *ngIf="stepper.selectedIndex === 1"
          [template]="data.template"
          [targetEntity]="data.type.targetEntity"
          [teamspaceId]="workroomDetails.teamspaceId$ | async"
          [repositoryId]="repositoryId$ | async"
          [permissionCheckStrategy]="permissionStrategy">
      </taskboard-and-robots>
    </cdk-step>
    <cdk-step [class.stepper_step--disabled]="workroomWizardForm.invalid" [optional]="true">
      <workflow
          *ngIf="stepper.selectedIndex === 2"
          [(processViewHeight)]="processViewHeight"
          [template]="data.template"
          [taskLists]="data.template.taskLists"
          [permissionCheckStrategy]="permissionStrategy">
      </workflow>
    </cdk-step>
  </stepper>
</fullscreen-dialog>

<ng-template #actions>
  <div class="workroom-wizard_actions">
    <ng-container *ngIf="!data.type.editMode">
      <button
          class="workroom-wizard_actions_stepper-button"
          data-cy="workroom-wizard-previous"
          [disabled]="workroomWizardForm.invalid"
          [style.visibility]="stepper.selectedIndex > 0 ? 'visible' : 'hidden'"
          (click)="stepper.previous()"
          mat-button mat-celum-button>
        <celum-icon [clickable]="true" [configuration]="previousIcon"></celum-icon>
        {{ 'COMMON.BACK' | translate | uppercase }}
      </button>
      <button
          class="workroom-wizard_actions_stepper-button"
          data-cy="workroom-wizard-next"
          [disabled]="workroomWizardForm.invalid"
          [style.visibility]="stepper.selectedIndex < stepper.steps.length - 1 ? 'visible' : 'hidden'"
          (click)="stepper.next()"
          mat-button mat-celum-button>
        {{ 'COMMON.NEXT' | translate | uppercase }}
        <celum-icon [clickable]="true" [configuration]="nextIcon"></celum-icon>
      </button>
    </ng-container>

    <button
        mat-button
        celum-icon-button
        size="small"
        class="workroom-wizard_actions_submit-button"
        data-cy="workroom-wizard-submit"
        [disableRipple]="true"
        [disabled]="workroomWizardForm.invalid || isPristineInEditMode() || (subscriptionSynced$ | async) === false || ((workroomsLimitReached$ | async) && !data.type.editMode && data.type.targetEntity === 'WORKROOM')"
        (click)="saveTemplate()">
      <celum-icon [configuration]="checkIcon"></celum-icon>
      {{ (data.type.editMode ? 'COMMON.SAVE' : 'COMMON.CREATE') | translate | uppercase }}
    </button>
  </div>
</ng-template>
