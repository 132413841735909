import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration, SnackbarState } from '@celum/common-components';
import { ExecutableAction } from '@celum/core';
import { ApplicationInsightsService } from '@celum/work/app/core/application-insights.service';
import { ErrorKey } from '@celum/work/app/core/error/error-key';
import { ErrorActions } from '@celum/work/app/core/error/error.actions';
import { TranslationUtilService } from '@celum/work/app/core/translations/translation-util.service';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';

import { AbstractWebsocketConnectionService } from '../websocket/abstract-websocket-connection.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public static readonly SNACKBAR_ID = 'error-interceptor';

  private readonly errorKeyTranslationPrefix: string = 'WORK_ERROR.';
  private readonly errorFallback: string = 'UNEXPECTED_ERROR';

  constructor(
    private store: Store<any>,
    private translationUtilService: TranslationUtilService,
    private appInsights: ApplicationInsightsService,
    private websocketConnectionService: AbstractWebsocketConnectionService,
    private eventBus: ApplicationEventBus
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: Error) => {
        if (err instanceof HttpErrorResponse && req.responseType !== 'blob') {
          this.handleHttpErrorResponse(err);
        }
        return throwError(err);
      })
    );
  }

  private handleHttpErrorResponse(err: HttpErrorResponse): void {
    combineLatest([this.websocketConnectionService.seenFirstConnection$, this.websocketConnectionService.isConnected$])
      .pipe(
        take(1),
        filter(([seenFirstConnection, connected]) => !seenFirstConnection || connected)
      )
      .subscribe(_ => {
        this.appInsights.logError(err);
        const errorKey = err?.error?.errorKey || '';

        // If there's some error key, dispatch an action - not every error key might have a handler
        if (errorKey) {
          this.store.dispatch(ErrorActions.errorKeyReceived({ errorKey }));
          this.eventBus.publishEvent({ type: errorKey });
        }

        const translation = this.getTranslation(errorKey);

        // Show translation only for those error keys we defined error messages for
        // if error doesn't originate from WR bE (there's no error key) we are showing default fallback
        if (this.translationUtilService.wasTranslated(translation, this.errorKeyTranslationPrefix)) {
          this.showSnackbar(err, translation);
        }
      });
  }

  private getTranslation(errorKey: string): string {
    const isErrorFromWorkroomsBackend = !!errorKey;

    if (isErrorFromWorkroomsBackend) {
      return this.translationUtilService.getTranslation(this.errorKeyTranslationPrefix, errorKey);
    } else {
      return this.translationUtilService.getTranslation(this.errorKeyTranslationPrefix, this.errorFallback);
    }
  }

  private showSnackbar(err: HttpErrorResponse, translation: string): void {
    const config = SnackbarConfiguration.error(translation);
    this.addSnackbarAction(err, config);
    this.store.dispatch(new ShowSnackbar(ErrorInterceptor.SNACKBAR_ID, SimpleSnackbar, config));
  }

  private addSnackbarAction(err: HttpErrorResponse, config: SnackbarConfiguration) {
    const nonReloadActions = [ErrorKey.WORKROOM_INACTIVE, ErrorKey.LICENSE_LIMIT_REACHED];
    const shouldOfferReload = err.error && !nonReloadActions.includes(err.error.errorKey);

    if ((err.status === 403 || err.status === 409) && shouldOfferReload) {
      config.actions[SnackbarState.ERROR] = [
        new ExecutableAction(() => location.reload(), 'WORK_ERROR.ACTION.RELOAD_PAGE')
      ];
    }
  }
}
