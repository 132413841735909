import { CdkDragStart } from '@angular/cdk/drag-drop';
import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Optional, Output, SimpleChanges, TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { of } from 'rxjs';

import { DataUtil, Entity } from '@celum/core';

import { DragAndDropService } from '../../../drag-and-drop';
import { ListSelectableDirective } from '../../directives/list-selectable.directive';
import { ClickHandlerService } from '../../services/click-handler.service';
import { ScrollPositionHelperService } from '../../services/scroll-position-helper.service';
import { AbstractList } from '../abstract-list';

@Component({
             selector: 'celum-list',
             templateUrl: './celum-list.html',
             styleUrls: ['./celum-list.less'],
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class CelumList<E extends Entity> extends AbstractList<E> implements OnChanges, AfterViewInit {

  @Input() public fromRoot = false;
  @Input() public itemTemplate: TemplateRef<any>;
  @Input() public customItemClass = '';
  @Input() public clickHandler: ClickHandlerService<E>;
  @Input() public dragAndDropService: DragAndDropService<any, any>;

  @Output() public readonly itemLongPress = new EventEmitter<E>();

  protected readonly of = of;

  private scrollPositionHelper = new ScrollPositionHelperService();

  constructor(protected changeDetectorRef: ChangeDetectorRef, @Optional() protected listSelectionDirective?: ListSelectableDirective<E>,) {
    super(changeDetectorRef);

    this.scrollPositionHelper.handleScrollPosition();
  }

  // for FTA - not perfect, but "cheapest" solution...
  @Input() public getItemDataAttributeNameFn: (item: any) => string = item => item.name;

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public ngAfterViewInit(): void {
    // if no selection handler provided, try to take it from directive
    this.selectionHandler = this.selectionHandler ?? this.listSelectionDirective;
    this.selectionHandler?.setCompareFn((a, b) => a.id === b.id);

    super.ngAfterViewInit();
  }

  protected isSelected(selection: E[], item: E): boolean {
    return !!selection.find(selectedItem => selectedItem.id === item.id);
  }

  protected trackByFn(index: number, item: E): string {
    return item?.entityType?.id;
  }

  protected getItemBackground(item: any): string {
    if (DataUtil.isEmpty(item.backgroundImage)) {
      if (!DataUtil.isEmpty(item.backgroundColor)) {
        return item.backgroundColor;
      }
      return undefined;
    } else {
      return `url("${item.backgroundImage}") center bottom cover no-repeat`;
    }
  }

  protected draggingStarted($event: CdkDragStart, item: any): void {
    if (!this.selectionHandler) {
      return;
    }

    if (!this.dragAndDropService) {
      return;
    }

    if (!this.selectionHandler.isInSelection(item)) {
      this.clickHandler.onClick(new MouseEvent('mousedown'), item);
    }

    this.dragAndDropService.startDragging($event, item, this.selectionHandler.getCurrentSelection());
  }

}
