<div
    class="task-list-transitions_add-transition"
    matRipple
    cdkOverlayOrigin
    #originOverlay="cdkOverlayOrigin"
    (click)="openSearchAndSelectOverlay()">
  <celum-icon
      class="task-list-transitions_add-transition-icon"
      [matTooltip]="'WORKROOM_WIZARD.DIALOG.SELECT_TRANSITIONS' | translate"
      [configuration]="addTransitionIcon"
      [clickable]="false"
      data-cy="task-list-transitions-open">
  </celum-icon>

  <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOffsetY]="1"
      cdkConnectedOverlayPanelClass="mat-elevation-z3"
      [cdkConnectedOverlayBackdropClass]="'task-list-transitions_add-transition-backdrp'"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOrigin]="originOverlay"
      [cdkConnectedOverlayOpen]="isSearchAndSelectVisible"
      [cdkConnectedOverlayPositions]="[{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }]"
      (backdropClick)="closeSearchAndSelectOverlay()">
    <search-and-select-task-list
        [taskLists]="selectableTaskLists"
        [selectedTaskLists]="selectedTaskLists"
        (elementSelected)="addTransition($event)"
        (elementDeselected)="removeTransition($event)"
        data-cy="create-custom-form-dialog-field-dropdown">
    </search-and-select-task-list>
  </ng-template>
</div>

<div class="task-list-transitions_list-active">
  <div class="task-list-transitions_list-active_item"
       [attr.data-transition-target]="selected.taskList.name"
       [style.background-color]="getTaskListColor(selected.taskList)"
       [attr.data-cy]="'task-list-transition-target-' + selected.taskList.id"
       *ngFor="let selected of selectedTransitionsWithTaskList; trackBy: trackByFn">
    <div *ngIf="selected.transition.reverse" class="task-list-transitions_list-active_item_icon-wrapper" [matTooltip]="'TRANSITIONS.BIDIRECTIONAL' | translate">
      <celum-icon
          matSuffix
          data-cy="task-list-transition-bidirectional"
          [configuration]="bidirectionalIcon(selected.taskList)"
          (click)="onBidirectionalTransitionClick(selected.transition)">
      </celum-icon>
    </div>
    <div *ngIf="!selected.transition.reverse" class="task-list-transitions_list-active_item_icon-wrapper"
         [matTooltip]="'TRANSITIONS.UNIDIRECTIONAL' | translate">
      <celum-icon
          matSuffix
          data-cy="task-list-transition-unidirectional"
          [configuration]="unidirectionalIcon"
          (click)="onUnidirectionalTransitionClick(selected.transition)">
      </celum-icon>
    </div>
    <text-truncator [text]="selected.taskList.name"></text-truncator>
    <div class="task-list-transitions_list-active_item_icon-wrapper" data-cy="task-list-transition-remove">
      <button class="task-list-transitions_list-active_item_icon-wrapper_button" mat-button celum-square-button [isRippleLight]="true">
        <celum-icon
            matSuffix
            class="ql-toolbar_x-icon"
            [configuration]="xIcon"
            [matTooltip]="'TRANSITIONS.REMOVE' | translate"
            (click)="removeTransition(selected.taskList)">
        </celum-icon>
      </button>
    </div>
  </div>
</div>
