import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { DataUtil, EntityType } from '@celum/core';
import { Entity } from '@celum/work/app/core/model/entity';

import { WorkroomGroupItemType } from '../group-item/workroom-group-item.model';

export interface WorkroomGroupCreate {
  name: string;
}

export interface WorkroomGroup extends Entity<number, WorkroomGroupType> {
  name: string;
  personId: number;
  teamspaceId: number;
  sort: number;
  editable: boolean;
}

export class WorkroomGroupType implements EntityType {
  public static readonly TYPE_KEY: string = 'WorkroomGroup';
  private static _instance: WorkroomGroupType;

  public id = WorkroomGroupType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): WorkroomGroupType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(WorkroomGroupItemType.TYPE_KEY)) {
        relationDefinitions.workroomGroupItems = { results: [WorkroomGroupItemType.instance().getSchema()] };
      }
    }
    return new schema.Entity(WorkroomGroupType.TYPE_KEY, relationDefinitions);
  }
}
export interface WorkroomGroupState extends EntityState<WorkroomGroup> {}
