import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type ToggleDrawerButtonTooltipConfig = {
  open: string;
  close: string;
};

@Component({
  selector: 'toggle-drawer-button',
  templateUrl: './toggle-drawer-button.component.html',
  styleUrls: ['./toggle-drawer-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleDrawerButtonComponent {
  @Input() public opened: boolean;
  @Input() public vertical = false;
  @Input() public tooltipConfig: ToggleDrawerButtonTooltipConfig;

  @Output() public readonly openedChange = new EventEmitter<any>();

  public onClick() {
    this.openedChange.emit(!this.opened);
  }
}
