import { createAction, props } from '@ngrx/store';

import { PaginationResult } from '@celum/core';
import { Folder } from '@celum/work/app/core/model/entities/folder/folder.model';

import { ContentItem } from '../../../core/model/entities/content-item/content-item.model';

export const ContentItemLoadContentItems = createAction(
  '[ContentItem] Load Content Items',
  props<{ offset: number; limit?: number }>()
);

export const ContentItemLoadingContentItems = createAction(
  '[ContentItem] Loading Content Items',
  props<{ offset: number }>()
);

export const ContentItemFailedLoadingContentItems = createAction(
  '[ContentItem] Failed Loading Content Items',
  props<{ offset: number }>()
);

export const ContentItemContentItemsLoaded = createAction(
  '[ContentItem] Content Items Loaded',
  props<{ contentItems: ContentItem[]; paginationResult: PaginationResult; resetState: boolean }>()
);

export const ContentItemFetchNextBatch = createAction('[ContentItem] Fetch Next Batch');

export const ContentItemChangeBatchSize = createAction(
  '[ContentItem] Change Batch Size',
  props<{ batchSize: number }>()
);

export const ContentItemSelected = createAction(
  '[ContentItem] Content Item Selected',
  props<{ contentItems: ContentItem[] }>()
);

export const ContentItemMoveContentItems = createAction(
  '[ContentItem] Move Content Items',
  props<{ targetFolder: Folder; contentItemIds: string[] }>()
);

export const ContentItemContentItemsMoved = createAction(
  '[ContentItem] Content Items Moved',
  props<{ contentItems: ContentItem[]; sourceParentIds: string[]; targetParentId: string }>()
);

export const ContentItemDeleteContentItems = createAction(
  '[ContentItem] Delete Content Items',
  props<{ contentItemIds: string[]; permanently?: boolean }>()
);

export const ContentItemAddContentItem = createAction(
  '[ContentItem] Add Content Item',
  props<{ contentItem: ContentItem }>()
);

export const ContentItemRestoreContentItems = createAction(
  '[ContentItem] Restore Content Items',
  props<{ contentItemIds: string[] }>()
);

export const ContentItemContentItemsDeleted = createAction(
  '[ContentItem] Content Items Deleted',
  props<{ contentItemIds: string[] }>()
);

export const ContentItemContentItemsRestored = createAction(
  '[ContentItem] Content Items Restored',
  props<{ contentItemIds: string[]; parentIds: string[] }>()
);

export const ContentItemRefreshLoadedContentItems = createAction(
  '[ContentItem] Content Items Refresh Loaded Content Items'
);

export const ContentItemEmptyTrashBin = createAction('[ContentItem] Content Item Empty Trash Bin');

export const ContentItemRestoreAll = createAction('[ContentItem] Content Item Restore All');

export const ContentItemsResetState = createAction('[ContentItem] Reset State');
