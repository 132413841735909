import { createAction, props } from '@ngrx/store';

import { ContentItem } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { File } from '@celum/work/app/core/model/entities/file/file.model';

export class ShareDialogActions {
  public static OpenShareDialogForFiles = createAction(
    '[ShareDialog] Share dialog for files triggered',
    props<{ filesToShare: File[]; skipDialog?: boolean }>()
  );

  public static OpenShareDialogForFoldersAndFiles = createAction(
    '[ShareDialog] Share dialog for folders and files triggered',
    props<{ itemsToShare: ContentItem[]; skipDialog?: boolean }>()
  );

  public static ShowDialogWithRenditions = createAction(
    '[ShareDialog] Show dialog with renditions',
    props<{ filesToShare: File[]; skipDialog?: boolean }>()
  );

  public static ShowFileLimitReachedDialog = createAction('[ShareDialog] Show 1000 files limit reached dialog');

  public static ShowNoFileSelectedSnackbar = createAction('[ShareDialog] Show no file selected snackbar');
}
