import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationExtras,
  NavigationStart,
  Router
} from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterNavigationHelperService {
  private navigationPendingSubj$: Subject<boolean> = new ReplaySubject(1);
  private navigationQueue = Promise.resolve(true);

  constructor(private router: Router) {
    this.listenToRouterEvents();
  }

  public get navigationPending$(): Observable<boolean> {
    return this.navigationPendingSubj$.asObservable();
  }

  /**
   * Puts router navigations into a queue and navigates in a sequence (if needed).
   * Use this on colliding router navigations.
   *
   * @param commands same as in Router.navigate()
   * @param extras same as in Router.navigate()
   */
  public safeNavigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    const enqueue = async () => {
      await this.navigationQueue;
      return await this.router.navigate(commands, extras);
    };

    this.navigationQueue = enqueue();
    return this.navigationQueue;
  }

  private listenToRouterEvents(): void {
    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          // needed to prevent ExpressionChangedAfterItHasBeenCheckedError as sometimes the router events are tiggered after (first) CD cycle ends
          setTimeout(() => this.navigationPendingSubj$.next(true));
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          // needed to prevent ExpressionChangedAfterItHasBeenCheckedError as sometimes the router events are tiggered after (first) CD cycle ends
          setTimeout(() => this.navigationPendingSubj$.next(false));
          break;
        }
      }
    });
  }
}
