import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  getTaskListAutomatorsOfType,
  isTaskListOwnerRestrictionRule,
  isTaskListRobot
} from '@celum/work/app/robots/services/robots-util';

import { RobotService, RobotServiceArgs } from './robot.service';
import {
  AutomatorType,
  RobotActionType,
  RobotTriggerType,
  RuleType
} from '../../core/model/entities/workroom/robot.model';

export class RuleService extends RobotService {
  constructor(private ruleType: RuleType) {
    super();
  }

  public definedRobotsLimitReached(args: RobotServiceArgs): Observable<boolean> {
    const { workroomConfig, sourceId } = args;
    let limitReached;
    switch (this.ruleType) {
      case RuleType.TASK_MANDATORY_ASSIGNMENT:
        limitReached =
          workroomConfig.automators
            .filter(automator => isTaskListRobot(sourceId, automator))
            .find(automator => automator.action?.type === RobotActionType.TASK_REMOVE_ASSIGNEES) !== undefined;
        break;
      case RuleType.TASK_EDITING_RESTRICTION:
      case RuleType.TASK_MOVEMENT_RESTRICTION:
        limitReached = workroomConfig.rules
          .filter(rule => isTaskListRobot(sourceId, rule))
          .some(rule => isTaskListOwnerRestrictionRule(rule));
        break;
      case RuleType.TASK_CREATION_RESTRICTION:
        limitReached =
          getTaskListAutomatorsOfType(args.workroomConfig, args.sourceId, AutomatorType.TASK_CREATION).length > 0 ||
          workroomConfig.automators
            .filter(automator => isTaskListRobot(sourceId, automator))
            .some(automator => automator.trigger?.type === RobotTriggerType.TASK_CREATED);
        break;
      default:
        limitReached = false;
    }

    const alreadyUsed = workroomConfig.rules.some(
      rule => rule.type === this.ruleType && isTaskListRobot(sourceId, rule)
    );
    return of(alreadyUsed || limitReached);
  }

  public getDisabledTooltipKey(args: RobotServiceArgs): Observable<string | null> {
    return this.definedRobotsLimitReached(args).pipe(
      map(limitReached => (limitReached ? 'ROBOTS.ALREADY_USED' : null))
    );
  }
}
