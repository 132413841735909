import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';

import { AVATAR_SIZE } from '@celum/common-components';

import { Person } from '../../../../core/model/entities/person';
import { AvatarDecoratorFn, AvatarUtil } from '../../../util/avatar-util';
import { WorkroomAvatarConfiguration } from '../../workroom-avatar/workroom-avatar-configuration';

@Component({
  selector: 'search-and-select-person',
  templateUrl: './search-and-select-person.component.html',
  styleUrls: ['./search-and-select-person.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SearchAndSelectPersonComponent {
  @HostBinding('class') public hostCls = 'search-and-select-person';

  @Input() public person: Person;
  @Input() public withEmail = true;

  constructor(private avatarUtil: AvatarUtil) {}

  @Input() public avatarDecorator: AvatarDecoratorFn = avatar => of(avatar);

  public getAvatar(person: Person): Observable<WorkroomAvatarConfiguration> {
    const size = this.withEmail ? AVATAR_SIZE.m : AVATAR_SIZE.s;
    return this.avatarUtil
      .getAvatarConfigWithImageForCurrentTeamspace({
        person,
        size
      })
      .pipe(switchMap(avatarConfig => this.avatarDecorator(avatarConfig)));
  }

  public getUserName(person: Person): string {
    return this.avatarUtil.getUserDisplayName(person);
  }

  public shouldDisplayEmail(person: Person) {
    return this.getUserName(person) !== person.email && this.withEmail;
  }
}
