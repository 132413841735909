import { EntityTranslator } from '@celum/work/app/core/model';

import { Person, PersonType } from './person.model';

export class PersonTranslator implements EntityTranslator<Person> {
  public translateToEntity(json: any): Person {
    const color = json.color
      ? {
          red: json.color.red,
          green: json.color.green,
          blue: json.color.blue
        }
      : null;

    const id = json.id;

    return {
      id,
      username: json.username,
      oid: json.oid,
      firstName: json.firstName,
      lastName: json.lastName,
      color: color,
      position: json.position,
      email: json.email,
      emailInvitedFrom: json.emailInvitedFrom,
      trialPlanActiveSince: json.trialPlanActiveSince,
      avatarUrl: json.avatarUrl,
      uiLanguage: json.uiLanguage,
      invitationStatus: json.invitationStatus,
      entityType: PersonType.instance(),
      createdOn: json.createdOn
    };
  }
}
