import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'portrait-restrictor',
  templateUrl: './portrait-restrictor.component.html',
  styleUrls: ['./portrait-restrictor.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortraitRestrictorComponent {
  @HostBinding('class') public hostClass = 'portrait-restrictor';

  public switchGraphicConfig = IconConfiguration.xLarge('switch-graphic', 'Title', 260);
}
