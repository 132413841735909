<virtual-scroll-table>
  <ng-template #itemTemplate let-customForm let-id="index">
    <custom-forms-table-row
      [customForm]="customForm"
      [attr.data-cy]="'custom-form-row-' + id"
      [hasWorkroomsFinishPermission]="hasWorkroomsFinishPermission"
      (click)="openEditFormDialog(customForm.id)"
      (copyAsNewDialogOpened)="openCopyAsNewDialog(customForm.id)"
      (deleteDialogOpened)="openDeleteDialog(customForm)">
    </custom-forms-table-row>
  </ng-template>
</virtual-scroll-table>
  