<div class="organization-information_header">
  <celum-icon *ngIf="category?.id" class="template-chooser-template-category_header-company-logo"
              [configuration]="companyLogoIconConfiguration"></celum-icon>
  <span class="organization-information_header-name">
    {{ category ? (category | templateCategoryName) : ('TEMPLATE_CHOOSER.PERSONAL_CATEGORY' | translate) }}
  </span>
  <span *ngIf="category?.id > 1 && hasCreateWorkroomPermission"
        class="organization-information_header_workroom-count"
        [innerHTML]="'TEMPLATE_CHOOSER.WORKROOM_COUNT' | translate: { workroomsUsed: teamspace?.workroomsUsed, workroomsLimit: teamspace?.workroomsLimit}"></span>
</div>

<mat-divider class="organization-information_divider"></mat-divider>
