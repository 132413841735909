import { Directive } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { EmptyPage, EmptyPageConfig } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Collection } from '@celum/work/app/content-hub/model/content-hub.model';

import { Folder } from '../../core/model/entities/folder/folder.model';
import { FolderTreePaginationInformation } from '../../files/components/files-tree/files-tree.component';
import { ContentHubBrowserService } from '../services/content-hub-browser.service';

@Directive()
export abstract class BaseContentHubDialog extends ReactiveComponent {
  public paginationInformation$: Observable<FolderTreePaginationInformation>;
  public activeFolder$: BehaviorSubject<Folder> = new BehaviorSubject<Folder>(null);
  public children$: Observable<Folder[]>;
  public collections$: Observable<Collection[]>;
  public selectedCollection: Collection;
  public rootCollectionSelected = false;
  public missingContentHubUser$: Observable<boolean>;
  public missingContentHubUserEmptyPageConfig: EmptyPageConfig;
  public repositoryId?: string;

  protected constructor(protected browseService: ContentHubBrowserService) {
    super();
    this.missingContentHubUser$ = browseService.missingContentHubUser$.asObservable();
    this.missingContentHubUserEmptyPageConfig = EmptyPage.noActionConfig(
      'no-ch-permission',
      'no-ch-permission',
      'CONTENT_HUB.ERROR_MESSAGE.PERMISSION_DENIED',
      'normal',
      180
    );
  }

  public abstract selectedFolderChanged(folder: Folder): void;

  public changeCollection(collection: Collection): void {
    this.selectedCollection = collection;

    this.browseService
      .changeCollection(collection.id, this.repositoryId)
      .pipe(
        switchMap(() =>
          this.children$.pipe(
            map(folders =>
              folders.length > 1
                ? folders.filter(folder => folder.id !== (collection.id as any))[0]
                : folders.filter(folder => folder.id === (collection.id as any))[0]
            ),
            take(1)
          )
        )
      )
      .subscribe(folder => {
        this.rootCollectionSelected = folder.id === (this.selectedCollection.id as any);
        this.activeFolder$.next(folder);
      });
  }

  public loadChildren(params: any): void {
    this.browseService.loadMoreNodes(params, this.repositoryId).subscribe();
  }

  public loadMoreForFolder(params: any): void {
    this.browseService.loadMoreNodes(params, this.repositoryId).subscribe();
  }

  protected initData(): void {
    this.browseService
      .init(this.repositoryId)
      .pipe(
        switchMap(() =>
          this.children$.pipe(
            withLatestFrom(this.collections$),
            tap(([_, collections]) => (this.selectedCollection = collections[0])),
            map(
              ([folders, collections]) =>
                folders.filter(folder => !collections.map(({ id }) => id).includes(folder.id as any))[0]
            ),
            take(1)
          )
        )
      )
      .subscribe(folder => this.activeFolder$.next(folder));
  }

  protected getPaginationInformation(): Observable<FolderTreePaginationInformation> {
    return this.browseService.folders$.pipe(
      map(folders =>
        Object.keys(folders).reduce((acc, curr) => {
          acc[curr] = {
            hasBottom: folders[curr].paginationInformation.elementsFollow,
            totalElementCount: folders[curr].paginationInformation.totalElementCount
          };
          return acc;
        }, {})
      )
    );
  }
}
