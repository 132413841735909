import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { DataUtil } from '@celum/core';
import { EntityUtil } from '@celum/work/app/core/model';

import {
  ActivityDeleteOne,
  ActivityRemoveSelection,
  ActivitySelectOne,
  ActivityUpsertMany,
  ActivityUpsertOne
} from './activity.actions';
import { Activity, ActivityState } from './activity.model';
import { mergeEntities, mergeEntity } from '../entities-state-util';

export const activityAdapter: EntityAdapter<Activity> = createEntityAdapter<Activity>();

const initialState: ActivityState = activityAdapter.getInitialState({ selectedId: null });

const properties = ['key', 'data', 'changedBy'];

const reducer = createReducer(
  initialState,
  on(ActivityUpsertOne, (state: ActivityState, { activity, propertiesToUpdate }) => {
    const activities = EntityUtil.changedEntities(properties, [activity], state.entities);

    if (!DataUtil.isEmpty(activities)) {
      return activityAdapter.upsertOne(mergeEntity(activities[0], state, propertiesToUpdate), state);
    } else {
      return state;
    }
  }),

  on(ActivityUpsertMany, (state: ActivityState, { activities, propertiesToUpdate }) => {
    const newActivities = EntityUtil.changedEntities(properties, activities, state.entities);
    return activityAdapter.upsertMany(mergeEntities(newActivities, state, propertiesToUpdate), state);
  }),

  on(ActivityDeleteOne, (state: ActivityState, { activity }) => {
    return activityAdapter.removeOne(activity.id, state);
  }),

  on(ActivitySelectOne, (state: ActivityState, { activity }) => ({
    ...state,
    selectedId: activity?.id
  })),

  on(ActivityRemoveSelection, (state: ActivityState) => ({
    ...state,
    selectedId: null
  }))
);

export function activityReducer(state: ActivityState = initialState, action: Action): ActivityState {
  return reducer(state, action);
}
