import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ContentItemStatus } from '@celum/work/app/core/model/entities/content-item/content-item.model';

import { ContentItemsState } from './content-item-list.model';
import { selectFileEntities } from '../../../core/model/entities/file/file.selectors';
import { selectFolderEntities } from '../../../core/model/entities/folder/folder.selector';

export const FEATURE_NAME_CONTENT_ITEMS = 'contentItems';

export const selectContentItemsState = createFeatureSelector<ContentItemsState>(FEATURE_NAME_CONTENT_ITEMS);

export const selectContentItemsLoading = createSelector(selectContentItemsState, state => state.loading);

export const selectContentItemsHasMoreBottom = createSelector(
  selectContentItemsState,
  state => state.paginationResult?.hasBottom
);

export const selectContentItemsTotalElementCount = createSelector(
  selectContentItemsState,
  state => state.paginationResult?.totalElementCount
);

export const selectContentItemIds = createSelector(selectContentItemsState, state => state.entityIds);

export const selectFilesIds = createSelector(selectContentItemIds, selectFileEntities, (contentItemIds, fileEntities) =>
  contentItemIds.filter(contentItemId => fileEntities[contentItemId])
);

export const selectFolderIds = createSelector(
  selectContentItemIds,
  selectFolderEntities,
  (contentItemIds, folderEntities) => contentItemIds.filter(contentItemId => folderEntities[contentItemId])
);

// Filtering null | undefined values after content item is deleted is just a quick fix, we should rather remove the ID from the content item list
export const selectContentItems = createSelector(
  selectContentItemIds,
  selectFolderEntities,
  selectFileEntities,
  (ids, folders, files) => ids?.map(id => folders[id] || files[id]).filter(contentItem => !!contentItem)
);

export const selectSelectedContentItems = createSelector(selectContentItemsState, state => state.selectedContentItems);

export const selectSoftDeletedContentItems = createSelector(selectContentItems, contentItems =>
  contentItems.filter(contentItem => contentItem.status === ContentItemStatus.SOFT_DELETED)
);
