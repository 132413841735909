import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { EntityType } from '@celum/core';
import { Entity } from '@celum/work/app/core/model';
import { Portal, PortalType } from '@celum/work/app/core/model/entities/portal/portal.model';

import { FileType } from '../file/file.model';
import { PersonType } from '../person';
import { Task, TaskType } from '../task/task.model';

export enum TargetContext {
  TASK = 'TASK',
  PORTAL = 'PORTAL'
}

export enum SourceContext {
  ASSET = 'ASSET'
}

export enum ItemLinkRelationType {
  NECESSARY_FOR = 'NECESSARY_FOR',
  DEPENDS_ON = 'DEPENDS_ON'
}

export function getOppositeTaskLinkRelationType(type: ItemLinkRelationType): ItemLinkRelationType {
  return type === ItemLinkRelationType.DEPENDS_ON
    ? ItemLinkRelationType.NECESSARY_FOR
    : ItemLinkRelationType.DEPENDS_ON;
}

export interface ItemLink extends Entity<string, ItemLinkType> {
  type: ItemLinkRelationType;
  targetId: string;
  sourceId: string;
  targetContext: TargetContext;
  targetObject: <T extends Task | Portal>(store: Store<any>) => Observable<T>;
}

export class ItemLinkType implements EntityType {
  public static TYPE_KEY = 'ItemLink';

  private static _instance: ItemLinkType;

  public id = ItemLinkType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): ItemLinkType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(withRelations = true): schema.Entity {
    const relationDefinitions: { [key: string]: any } = {};

    if (withRelations === true) {
      const task = TaskType.instance().getSchema({
        relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY]
      });

      const portal = new schema.Entity(PortalType.TYPE_KEY);

      relationDefinitions.targetObject = new schema.Union(
        {
          task,
          portal
        },
        (input, _parent) => {
          return input.typeKey === PortalType.TYPE_KEY ? 'portal' : 'task';
        }
      );
    }

    return new schema.Entity(ItemLinkType.TYPE_KEY, relationDefinitions, {
      idAttribute: value => `${value.sourceId}_${value.type}_${value.targetId}`
    });
  }
}

export interface ItemLinkState extends EntityState<ItemLink> {}
