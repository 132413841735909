import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  constructor(private httpClient: HttpClient) {}

  public loadConfiguration(key: string): Observable<any> {
    const body: any = {
      key: key
    };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/configuration`, body)
      .pipe(map((res: any) => res.value));
  }
}
