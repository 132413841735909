import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { EntityUtil } from '@celum/work/app/core/model';

import { TemplateDeleteOne, TemplateUpsertMany } from './template.actions';
import { Template, TemplateState } from './template.model';

export const templateAdapter: EntityAdapter<Template> = createEntityAdapter<Template>();

export const initialState: TemplateState = templateAdapter.getInitialState();

export const templateUpdatableProperties = [
  'name',
  'categoryId',
  'taskLists',
  'workroomConfiguration',
  'description',
  'dueDate',
  'color',
  'colorVariant',
  'people'
];

const reducer = createReducer(
  initialState,
  on(TemplateUpsertMany, (state: TemplateState, { templates }) => {
    const changedTemplates = EntityUtil.changedEntities(templateUpdatableProperties, templates, state.entities);
    return templateAdapter.upsertMany(changedTemplates, state);
  }),
  on(TemplateDeleteOne, (state: TemplateState, { id }) => {
    return templateAdapter.removeOne(id, state);
  })
);

export function templateReducer(state: TemplateState = initialState, action: Action): TemplateState {
  return reducer(state, action);
}
