import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { AppState } from '@celum/work/app/core';
import { ActivityState } from '@celum/work/app/core/model/entities/activity/activity.model';
import { activityReducer } from '@celum/work/app/core/model/entities/activity/activity.reducer';
import { CustomFieldsState } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { customFieldReducer } from '@celum/work/app/core/model/entities/custom-field/custom-field.reducer';
import { CustomFormsState } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { customFormReducer } from '@celum/work/app/core/model/entities/custom-form/custom-form.reducer';
import { FolderState } from '@celum/work/app/core/model/entities/folder/folder.model';
import { folderReducer } from '@celum/work/app/core/model/entities/folder/folder.reducer';
import { ItemLinkState } from '@celum/work/app/core/model/entities/item-link/item-link.model';
import { itemLinkReducer } from '@celum/work/app/core/model/entities/item-link/item-link.reducer';
import { PortalState } from '@celum/work/app/core/model/entities/portal/portal.model';
import { portalReducer } from '@celum/work/app/core/model/entities/portal/portal.reducer';
import { RenditionsState } from '@celum/work/app/core/model/entities/rendition/rendition.model';
import { renditionReducer } from '@celum/work/app/core/model/entities/rendition/rendition.reducer';
import { taskReducer } from '@celum/work/app/core/model/entities/task/task.reducer';
import { workroomGroupReducer } from '@celum/work/app/core/model/entities/workroom/group/workroom-group.reducer';
import { workroomGroupItemReducer } from '@celum/work/app/core/model/entities/workroom/group-item/workroom-group-item.reducer';

import { CommentState } from './comment/comment.model';
import { commentReducer } from './comment/comment.reducer';
import { ContributorState } from './contributor/contributor.model';
import { contributorReducer } from './contributor/contributor.reducer';
import { FileState } from './file/file.model';
import { fileReducer } from './file/file.reducer';
import { FileVersionsState } from './file-version/file-version.model';
import { fileVersionReducer } from './file-version/file-version.reducer';
import { MarkerState } from './marker/marker.model';
import { markerReducer } from './marker/marker.reducer';
import { MemberState } from './member/member.model';
import { memberReducer } from './member/member.reducer';
import { PersonState } from './person/person.model';
import { personReducer } from './person/person.reducer';
import { TaskListState } from './task/task-list.model';
import { taskListReducer } from './task/task-list.reducer';
import { TaskState } from './task/task.model';
import { TeamspaceState } from './teamspace/teamspace.model';
import { teamspaceReducer } from './teamspace/teamspace.reducer';
import { TemplateState } from './template/template.model';
import { templateReducer } from './template/template.reducer';
import { TemplateCategoryState } from './template-category/template-category.model';
import { templateCategoryReducer } from './template-category/template-category.reducer';
import { WorkroomGroupState } from './workroom/group/workroom-group.model';
import { WorkroomGroupItemState } from './workroom/group-item/workroom-group-item.model';
import { WorkroomState } from './workroom/workroom.model';
import { workroomReducer } from './workroom/workroom.reducer';
import { ImportIntentState } from '../../../content-hub/model/import-intent.model';
import { importIntentReducer } from '../../../content-hub/store/import-intent.reducer';

export const FEATURE_NAME = 'entities';
export const selectEntities = createFeatureSelector<EntitiesState>(FEATURE_NAME);

export const reducers: ActionReducerMap<EntitiesState> = {
  persons: personReducer,
  teamspaces: teamspaceReducer,
  workrooms: workroomReducer,
  members: memberReducer,
  contributors: contributorReducer,
  tasks: taskReducer,
  taskLists: taskListReducer,
  itemLinks: itemLinkReducer,
  files: fileReducer,
  folders: folderReducer,
  comments: commentReducer,
  markers: markerReducer,
  activities: activityReducer,
  templateCategories: templateCategoryReducer,
  template: templateReducer,
  fileVersions: fileVersionReducer,
  renditions: renditionReducer,
  workroomGroups: workroomGroupReducer,
  workroomGroupItems: workroomGroupItemReducer,
  importIntents: importIntentReducer,
  customFields: customFieldReducer,
  customForms: customFormReducer,
  portals: portalReducer
};

export interface EntitiesState {
  persons: PersonState;
  teamspaces: TeamspaceState;
  workrooms: WorkroomState;
  members: MemberState;
  contributors: ContributorState;
  tasks: TaskState;
  taskLists: TaskListState;
  itemLinks: ItemLinkState;
  files: FileState;
  folders: FolderState;
  comments: CommentState;
  markers: MarkerState;
  activities: ActivityState;
  templateCategories: TemplateCategoryState;
  template: TemplateState;
  fileVersions: FileVersionsState;
  renditions: RenditionsState;
  workroomGroups: WorkroomGroupState;
  workroomGroupItems: WorkroomGroupItemState;
  importIntents: ImportIntentState;
  customFields: CustomFieldsState;
  customForms: CustomFormsState;
  portals: PortalState;
}

export interface State extends AppState {
  entities: EntitiesState;
}
