<div class="snackbar-group-item_header">
  <div [style.background]="iconBackgroundColor" class="snackbar-group-item_header_icon-wrapper">
    <ng-container *ngIf="icon; else progressSpinner">
      <celum-icon [clickable]="false" [configuration]="icon"></celum-icon>
    </ng-container>

    <ng-template #progressSpinner>
      <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
    </ng-template>
  </div>

  <div class="snackbar-group-item_header_text">
    <span class="snackbar-group-item_header_text_title" [title]="title | translate: titleArgs">{{ title | translate: titleArgs }}</span>
    <span *ngIf="error" class="snackbar-group-item_header_text_caption" >{{ error }}</span>
  </div>
</div>

<button mat-flat-button celum-button *ngFor="let buttonConfig of getFilteredButtonStateConfigs(); trackBy: trackByFn"
    class="snackbar_action-button"
    size="small"
    color="primary"
    [ngStyle]="{ backgroundColor: buttonConfig.button.buttonColor }"
    (click)="buttonConfig.button.action.execute()">
  <celum-icon *ngIf="buttonConfig.button.icon"
        [configuration]="buttonConfig.button.icon"></celum-icon>
  {{ buttonConfig.button.text | translate | uppercase }}
</button>
