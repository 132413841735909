import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TemplateChooserService } from '@celum/work/app/core/api/workroom-creator';
import { InvitedPerson, Template } from '@celum/work/app/core/model/entities/template/template.model';
import { PredefinedCategoryValues } from '@celum/work/app/core/model/entities/template-category/template-category.model';

import { SaveStrategy } from './save-strategy';
import * as TemplateChooserActions from '../store/workroom-creator.actions';

@Injectable({
  providedIn: 'root'
})
export class CreateTemplateStrategy implements SaveStrategy {
  constructor(
    private templateService: TemplateChooserService,
    private store: Store<any>
  ) {}

  public save(
    { workroomConfiguration, ...template }: Template,
    { categoryId, repositoryId, ...form }: any,
    people: InvitedPerson[]
  ): Observable<any> {
    return this.templateService
      .create(
        {
          ...template,
          ...form,
          contentHubRepositoryId: repositoryId || null,
          categoryId: categoryId === PredefinedCategoryValues.PERSONAL ? null : categoryId,
          workroomConfiguration
        },
        people
      )
      .pipe(
        tap(templateItem =>
          this.store.dispatch(TemplateChooserActions.WorkroomCreatorTemplateCreated({ id: templateItem.id }))
        )
      );
  }
}
