<people-list *ngIf="canShowPeopleList$ | async; else addButton"
             [size]="'medium'"
             [teamspaceId]="teamspaceId"
             [people]="peopleWithStatuses$ | async"
             [isMultiSelection]="true"
             (selectionChanged)="onSelectionChanged($event)"
             (openContextMenu)="openContextMenu($event)">
  <ng-container *ngTemplateOutlet="addButton"></ng-container>
</people-list>

<ng-template
  #personMenu
  let-item>
  <div class="members-list-menu">
    <ng-container *ngIf="(isWorkroomAndSelfSelected() | async) === false; else noActionPossible">
      <div data-cy="members-list-menu-assign-moderator"
           class="members-list-menu_item"
           *ngIf="everySelectedIsActiveOrInitialized() | async"
           (click)="updateRole()">
        <celum-icon [configuration]="updateUserIconConfiguration"></celum-icon>
        {{'PEOPLE.OPERATIONS.UPDATE_ROLE' | translate}}
      </div>
      <div data-cy="members-list-menu-remove"
           class="members-list-menu_item"
           (click)="remove()">
        <celum-icon [configuration]="removeIconConfiguration"></celum-icon>
        {{'PEOPLE.OPERATIONS.REMOVE_PERSON' | translate}}
      </div>
    </ng-container>

    <ng-template #noActionPossible>
      <celum-menu-item [disabled]="true">
        <span>{{'WORKROOM_WIZARD.DIALOG.DISABLED_PERSON_SELECTION' | translate}}</span>
      </celum-menu-item>
    </ng-template>
  </div>
</ng-template>

<ng-template #addButton>
  <div class="workroom-details_people_add-button-wrapper">
    <div data-cy="workroom-wizard-add-people"
         class="workroom-details_people_add-button" (click)="$event.stopPropagation(); openInviteDialog();" >
      <celum-icon [configuration]="inviteIcon"></celum-icon>
      <span class="workroom-details_people_add-button_caption">{{ translations.inviteButton | translate }}</span>
    </div>
  </div>
</ng-template>
