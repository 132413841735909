import { DataUtil } from '@celum/core';

import { ContentItem } from './content-item.model';

export function hasAnyActiveContentHubConnections(contentItem: ContentItem): boolean {
  return (
    !DataUtil.isEmpty(contentItem.contentHubConnections) &&
    contentItem.contentHubConnections.some(connection => connection.active)
  );
}
