import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectAllMembers } from '@celum/work/app/core/model/entities/member/member.selectors';

import { UiStateState } from './ui-state.model';
import { Person, selectPersonEntities } from '../model/entities/person';
import { selectAllTeamspaces } from '../model/entities/teamspace';

export const FEATURE_NAME = 'uiState';

export const selectUiStateState = createFeatureSelector<UiStateState>(FEATURE_NAME);

export const selectUiState = createSelector(selectUiStateState, (state: UiStateState) => state);

export const selectUiStateLanguage = createSelector(selectUiState, (state: UiStateState) => state.language);

export const selectLoggedInPersonId = createSelector(selectUiState, (state: UiStateState) => state.loggedInPersonId);

export const selectLoggedInPerson = createSelector(
  selectLoggedInPersonId,
  selectPersonEntities as any,
  (personId, personEntities) => personEntities[personId] as Person
);

export const selectTenant = createSelector(selectUiState, state => state.tenant);

export const selectTenantTeamspace = createSelector(selectAllTeamspaces, selectTenant as any, (teamspaces, tenant) =>
  teamspaces.filter(({ externalId }) => externalId === tenant).pop()
);

export const selectAccountAccesses = createSelector(selectUiState, (state: UiStateState) => state.accountAccesses);

export const selectCurrentAccountAccess = createSelector(
  selectAccountAccesses,
  selectTenant,
  (accountAccesses, tenant) => accountAccesses?.filter(({ accountId }) => accountId === tenant).pop()
);

export const selectIsAccountOwner = createSelector(
  selectCurrentAccountAccess,
  selectLoggedInPerson,
  (accountAccess, { email }) => accountAccess?.ownerEmail === email
);

// NOTE: would put that to member.selectors but there's circular dependency then
export const selectLoggedInPersonMemberships = createSelector(
  selectAllMembers,
  selectLoggedInPersonId as any,
  (members, loggedInPersonId) => members?.filter(member => member.personId === loggedInPersonId)
);

export const selectCurrentViewContext = createSelector(selectUiStateState, state => state.viewContext.peek());

export const selectLatestUpdateTimestampOfCurrentViewContext = createSelector(
  selectCurrentViewContext,
  selectUiStateState,
  (currentViewContext, state) => state.viewContextLastUpdateTimestamp[currentViewContext] || Date.now()
);
