import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { CelumResizeModule } from '@celum/common-components';
import { CelumValidatorsModule } from '@celum/ng2base';
import { RobotsModule } from '@celum/work/app/robots/robots.module';
import { CelumQuillModule } from '@celum/work/app/shared/components/celum-quill/celum-quill.module';
import { MapPipe } from '@celum/work/app/shared/pipes/map.pipe';
import { OpenRobotDialogService } from '@celum/work/app/workroom-wizard/services/open-robot-dialog.service';

import { TaskListsConfigurationBoardComponent } from './components/task-lists-configuration-board/task-lists-configuration-board.component';
import { RobotMenuComponent } from './components/taskboard-and-robots/robot-menu/robot-menu.component';
import { TaskboardAndRobotsComponent } from './components/taskboard-and-robots/taskboard-and-robots.component';
import { TaskListTransitionsComponent } from './components/workflow/task-list-transitions/task-list-transitions.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { WorkroomDetailsComponent } from './components/workroom-details/workroom-details.component';
import { WorkroomWizardPeopleComponent } from './components/workroom-details/workroom-wizard-people/workroom-wizard-people.component';
import { WorkroomDetailsSaveTemplateDialogComponent } from './components/workroom-details-save-template-dialog/workroom-details-save-template-dialog.component';
import { WorkroomWizardComponent } from './components/workroom-wizard.component';
import { RobotVisibilityService } from './services/robot-visibility.service';
import { LicenseLimitReachedPipe } from '../pages/workroom-creator/pipes/license-limit-reached.pipe';
import { PeopleListComponent } from '../person/people-list/people-list.component';
import { SharedModule } from '../shared';
import { DueDateModule } from '../shared/components/due-date/due-date.module';
import { StepperModule } from '../shared/components/stepper/stepper.module';
import { TaskListModule } from '../task-list/task-list.module';

@NgModule({
  declarations: [
    WorkroomWizardComponent,
    WorkroomDetailsComponent,
    TaskboardAndRobotsComponent,
    WorkflowComponent,
    TaskListsConfigurationBoardComponent,
    TaskListTransitionsComponent,
    WorkroomWizardPeopleComponent,
    WorkroomDetailsSaveTemplateDialogComponent,
    RobotMenuComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    StepperModule,
    TaskListModule,
    MatExpansionModule,
    CelumResizeModule,
    DueDateModule,
    RobotsModule,
    CelumValidatorsModule,
    CelumQuillModule,
    PeopleListComponent,
    MapPipe
  ],
  exports: [WorkroomWizardComponent],
  providers: [LicenseLimitReachedPipe, RobotVisibilityService, OpenRobotDialogService]
})
export class WorkroomWizardModule {}
