import { createSelector } from '@ngrx/store';

import { MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { RuleType } from '@celum/work/app/core/model/entities/workroom/robot.model';
import {
  selectCurrentWorkroom,
  selectCurrentWorkroomContributingPersonsWithMembershipStatus
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import {
  hasAutomatorAddAssigneesActionActiveContributors,
  hasContentHubAutomator,
  hasTaskListRule
} from '@celum/work/app/robots/services/robots-util';

export const selectHasMandatoryAssignment = (taskListId: number) =>
  createSelector(
    selectCurrentWorkroom,
    selectCurrentWorkroomContributingPersonsWithMembershipStatus([MembershipStatus.ACTIVE, MembershipStatus.INIT]),
    (workroom, contributors) =>
      hasTaskListRule(workroom.configuration, taskListId, RuleType.TASK_MANDATORY_ASSIGNMENT) && contributors.length > 1
  );

export const selectHasContentHubAutomator = (taskListId: number) =>
  createSelector(selectCurrentWorkroom, workroom => hasContentHubAutomator(workroom?.configuration, taskListId));

export const hasAssignmentAutomatorWithActiveAssignees = (taskListId: number) =>
  createSelector(
    selectCurrentWorkroom,
    selectCurrentWorkroomContributingPersonsWithMembershipStatus([MembershipStatus.ACTIVE, MembershipStatus.INIT]),
    (workroom, contributors) =>
      hasAutomatorAddAssigneesActionActiveContributors(workroom.configuration, taskListId, contributors)
  );

export const selectAutomators = createSelector(selectCurrentWorkroom, workroom => workroom.configuration.automators);
