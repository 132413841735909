export class WorkroomWizardTranslations {
  public readonly companySelection: string = `WORKROOM_WIZARD.DIALOG.${this.type}.COMPANY_SELECTION`;
  public readonly description: string = `WORKROOM_WIZARD.DIALOG.${this.type}.DESCRIPTION`;
  public readonly descriptionPlaceholder: string = `WORKROOM_WIZARD.DIALOG.${this.type}.DESCRIPTION_PLACEHOLDER`;
  public readonly details: string = `WORKROOM_WIZARD.DIALOG.${this.type}.DETAILS`;
  public readonly header: string = `WORKROOM_WIZARD.DIALOG.${this.type}.HEADER`;
  public readonly name: string = `WORKROOM_WIZARD.DIALOG.${this.type}.NAME`;
  public readonly namePlaceholder: string = `WORKROOM_WIZARD.DIALOG.${this.type}.NAME_PLACEHOLDER`;
  public readonly dueDate: string = `WORKROOM_WIZARD.DIALOG.DUE_DATE.${this.type}`;
  public readonly inviteButton: string = `WORKROOM_WIZARD.DIALOG.${this.type}.INVITE.BUTTON`;
  public readonly inviteDialogHeadline: string = `WORKROOM_WIZARD.DIALOG.${this.type}.INVITE.DIALOG.HEADLINE`;
  public readonly inviteDialogButton: string = `WORKROOM_WIZARD.DIALOG.${this.type}.INVITE.DIALOG.BUTTON`;

  constructor(private type: 'WORKROOM' | 'TEMPLATE') {}
}
