import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RouterStateUrl } from '@celum/work/app/core';
import { tryToParseInt } from '@celum/work/app/shared/util/number-util';

export const FEATURE_NAME = 'router';

export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>(FEATURE_NAME);
export const selectRouterQueryParams = (paramKeys: string[]) =>
  createSelector(selectRouterState, routerState => {
    return paramKeys.map(paramKey => tryToParseInt(routerState?.state.queryParams[paramKey]));
  });

export const selectRouterQueryParam = (paramKey: string) =>
  createSelector(selectRouterState, routerState => {
    const queryParam = routerState?.state.queryParams[paramKey];
    return tryToParseInt(queryParam);
  });
export const selectRouterParam = (paramKey: string) =>
  createSelector(selectRouterState, routerState => {
    const param = routerState?.state.params[paramKey];
    return tryToParseInt(param);
  });
export const selectRouterData = createSelector(selectRouterState, routerState => routerState?.state.data);
