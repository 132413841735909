import { createSelector } from '@ngrx/store';

import { importIntentAdapter } from './import-intent.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../../core/model/entities/entities.state';

const { selectEntities, selectAll } = importIntentAdapter.getSelectors();

export const selectImportIntents = createSelector(selectEntitiesState, (state: EntitiesState) => state.importIntents);

export const selectAllImportIntents = createSelector(selectImportIntents, selectAll);
export const selectImportIntentEntities = createSelector(selectImportIntents, selectEntities);

export const selectImportIntentById = (id: string) =>
  createSelector(selectImportIntentEntities, importIntentDictionary => importIntentDictionary[id]);
