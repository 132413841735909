import { ColorConstants, IconConfiguration } from '@celum/common-components';

export const DATE_NAMES = ['createdOn', 'changedOn', 'dueDate'];
export const TASK_LIST = 'taskList';

export class IconConfigurationValue {
  constructor(
    public resource: string,
    public color = '#fff',
    public backgroundColor: string = ColorConstants.BLUE_GRAY_500
  ) {}

  public asIconConfiguration() {
    return IconConfiguration.small(this.resource)
      .withColor(this.color)
      .withBackground(this.backgroundColor)
      .withIconSize(16);
  }
}

export const ICON_CONFIGURATION = new Map<string, IconConfigurationValue>([
  ['task_created', new IconConfigurationValue('task-created', '#fff', 'rgba(55, 186, 99, 0.9)')],
  ['task_name_changed', new IconConfigurationValue('edit')],
  ['task_assignee_added', new IconConfigurationValue('assignment')],
  ['task_assignee_removed', new IconConfigurationValue('remove-assignee')],
  ['task_description_added', new IconConfigurationValue('edit')],
  ['task_description_changed', new IconConfigurationValue('edit')],
  ['task_description_removed', new IconConfigurationValue('edit')],
  ['task_priority_added', new IconConfigurationValue('activity-priority')],
  ['task_priority_changed', new IconConfigurationValue('activity-priority')],
  ['task_priority_removed', new IconConfigurationValue('activity-priority')],
  ['task_dueDate_added', new IconConfigurationValue('calendar')],
  ['task_dueDate_changed', new IconConfigurationValue('calendar')],
  ['task_dueDate_removed', new IconConfigurationValue('calendar')],
  ['task_taskList_changed', new IconConfigurationValue('move')],
  ['task_attachment_added', new IconConfigurationValue('file')],
  ['task_attachment_removed', new IconConfigurationValue('remove-file')],
  ['subtask_created', new IconConfigurationValue('subtask-icon')],
  ['subtask_removed', new IconConfigurationValue('subtask-icon')],
  ['task_link_created', new IconConfigurationValue('link-task')],
  ['task_link_removed', new IconConfigurationValue('link-task')],
  ['task_linked_removed', new IconConfigurationValue('link-task')],
  ['task_form_added', new IconConfigurationValue('form')],
  ['task_form_removed', new IconConfigurationValue('form')],
  ['subtask_name_changed', new IconConfigurationValue('subtask-icon')],
  ['subtask_status_changed_done', new IconConfigurationValue('subtask-icon')],
  ['subtask_status_changed_open', new IconConfigurationValue('subtask-icon')],
  ['subtask_converted', new IconConfigurationValue('subtask-icon')],
  ['subtask_assignee_added', new IconConfigurationValue('subtask-icon')],
  ['subtask_assignee_removed', new IconConfigurationValue('subtask-icon')],
  ['file_created', new IconConfigurationValue('file', '#fff', 'rgba(55, 186, 99, 0.9)')],
  ['file_removed', new IconConfigurationValue('remove-file')],
  ['file_status_changed', new IconConfigurationValue('restore')],
  ['file_activeVersion_added', new IconConfigurationValue('file')],
  ['file_activeVersion_changed', new IconConfigurationValue('file')],
  ['fileVersion_created', new IconConfigurationValue('file')],
  ['fileVersion_removed', new IconConfigurationValue('file')],
  ['ch_import_asset_as_file', new IconConfigurationValue('content-hub-icon')],
  ['ch_import_asset_as_file_version', new IconConfigurationValue('content-hub-icon')],
  ['ch_export_file_as_asset', new IconConfigurationValue('content-hub-icon')],
  ['ch_export_file_as_asset_version', new IconConfigurationValue('content-hub-icon')],
  ['ch_moved_to_target', new IconConfigurationValue('content-hub-icon')],
  ['ch_linked_to_target', new IconConfigurationValue('content-hub-icon')],
  ['ch_export_to_target', new IconConfigurationValue('content-hub-icon')],
  ['ch_added_to_collection', new IconConfigurationValue('content-hub-icon')],
  ['ch_moved_to_collection', new IconConfigurationValue('content-hub-icon')],
  ['comment_removed', new IconConfigurationValue('icon-21-delete')],
  ['comment_removed_by_moderator', new IconConfigurationValue('icon-21-delete')],
  ['comment_reply_removed', new IconConfigurationValue('icon-21-delete')],
  ['comment_reply_removed_by_moderator', new IconConfigurationValue('icon-21-delete')],
  ['stampMarker_like_created', new IconConfigurationValue('stamp')],
  ['stampMarker_dislike_created', new IconConfigurationValue('stamp')],
  ['stampMarker_like_removed', new IconConfigurationValue('stamp')],
  ['stampMarker_dislike_removed', new IconConfigurationValue('stamp')]
]);

export interface IncludeExcludeFilter {
  include?: any[];
  exclude?: any[];
}

export interface CollaborationPropertyFilter {
  workroomId: number;
  objectIdsInContext?: IncludeExcludeFilter;
  context?: IncludeExcludeFilter;
  activity?: IncludeExcludeFilter;
  from?: number;
  excludeLoggedIn?: boolean;
  onlyLoggedIn?: boolean;
  fileVersionIds?: string[];
  collapseOnActivityKey?: boolean;
}

export enum ActivityKey {
  TASK_NAME_CHANGED = 'task_name_changed',
  TASK_TASK_LIST_CHANGED = 'task_taskList_changed',
  TASK_SORT_CHANGED = 'task_sort_changed',
  TASK_DUE_DATE_ADDED = 'task_dueDate_added',
  TASK_DUE_DATE_CHANGED = 'task_dueDate_changed',
  TASK_DUE_DATE_REMOVED = 'task_dueDate_removed',
  TASK_DESCRIPTION_ADDED = 'task_description_added',
  TASK_DESCRIPTION_CHANGED = 'task_description_changed',
  TASK_DESCRIPTION_REMOVED = 'task_description_removed',
  TASK_PRIORITY_ADDED = 'task_priority_added',
  TASK_PRIORITY_CHANGED = 'task_priority_changed',
  TASK_PRIORITY_REMOVED = 'task_priority_removed',
  TASK_ASSIGNEE_ADDED = 'task_assignee_added',
  TASK_ASSIGNEE_REMOVED = 'task_assignee_removed',
  TASK_CREATED = 'task_created',
  TASK_LINK_CREATED = 'task_link_created',
  TASK_LINK_REMOVED = 'task_link_removed',
  TASK_LINKED_REMOVED = 'task_linked_removed',
  TASK_REMOVED = 'task_removed',
  TASK_ATTACHMENT_ADDED = 'task_attachment_added',
  TASK_ATTACHMENT_REMOVED = 'task_attachment_removed',

  TASK_FORM_ADDED = 'task_form_added',
  TASK_FORM_REMOVED = 'task_form_removed',

  SUBTASK_CREATED = 'subtask_created',
  SUBTASK_REMOVED = 'subtask_removed',
  SUBTASK_NAME_CHANGED = 'subtask_name_changed',
  SUBTASK_STATUS_CHANGED_DONE = 'subtask_status_changed_done',
  SUBTASK_STATUS_CHANGED_OPEN = 'subtask_status_changed_open',
  SUBTASK_CONVERTED = 'subtask_converted',
  SUBTASK_SORT_CHANGED = 'subtask_sort_changed',
  SUBTASK_ASSIGNEE_ADDED = 'subtask_assignee_added',
  SUBTASK_ASSIGNEE_REMOVED = 'subtask_assignee_removed',

  COMMENT_CREATED = 'comment_created',
  COMMENT_CONTENT_CHANGED = 'comment_content_changed',
  COMMENT_REMOVED = 'comment_removed',

  STAMP_MARKER_LIKE_CREATED = 'stampMarker_like_created',
  STAMP_MARKER_DISLIKE_CREATED = 'stampMarker_dislike_created',

  TASK_LIST_SORT_CHANGED = 'taskList_sort_changed',
  TASK_LIST_NAME_CHANGED = 'taskList_name_changed',
  TASK_LIST_DELETED = 'taskList_removed',
  TASK_LIST_DUE_DATE_ADDED = 'taskList_dueDate_added',
  TASK_LIST_DUE_DATE_CHANGED = 'taskList_dueDate_changed',
  TASK_LIST_DUE_DATE_REMOVED = 'taskList_dueDate_removed',
  TASK_LIST_COLOR_CHANGED = 'taskList_color_changed',
  TASK_LIST_CREATED = 'taskList_created',

  RENDITION_PREVIEW_CREATED = 'rendition_preview_created',
  RENDITION_PREVIEW_CHANGED = 'rendition_preview_changed',

  FILE_CREATED = 'file_created',

  FILE_VERSION_CREATED = 'fileVersion_created',
  FILE_VERSION_REMOVED = 'fileVersion_removed',
  FILE_ACTIVE_VERSION_CHANGED = 'file_activeVersion_changed',

  WORKROOM_CREATED = 'workroom_created',
  WORKROOM_CONFIGURATION_CHANGED = 'workroom_configuration_changed',

  WORKROOM_CONTRIBUTOR_ADDED = 'workroom_contributor_added'
}

export const TASK_LIST_ACTIVITIES = [
  ActivityKey.TASK_LIST_CREATED,
  ActivityKey.TASK_LIST_COLOR_CHANGED,
  ActivityKey.TASK_LIST_NAME_CHANGED,
  ActivityKey.TASK_LIST_SORT_CHANGED,
  ActivityKey.TASK_LIST_DELETED,
  ActivityKey.TASK_LIST_DUE_DATE_ADDED,
  ActivityKey.TASK_LIST_DUE_DATE_CHANGED,
  ActivityKey.TASK_LIST_DUE_DATE_REMOVED,
  ActivityKey.TASK_CREATED,
  ActivityKey.TASK_REMOVED,
  ActivityKey.WORKROOM_CONFIGURATION_CHANGED
];

export const TASK_ACTIVITIES_FOR_BOARD_CONTEXT = [
  ActivityKey.TASK_NAME_CHANGED,
  ActivityKey.TASK_SORT_CHANGED,
  ActivityKey.TASK_DUE_DATE_ADDED,
  ActivityKey.TASK_DUE_DATE_CHANGED,
  ActivityKey.TASK_DUE_DATE_REMOVED,
  ActivityKey.TASK_ASSIGNEE_ADDED,
  ActivityKey.TASK_ASSIGNEE_REMOVED
];

export const SUBTASK_ACTIVITIES = [
  ActivityKey.SUBTASK_CREATED,
  ActivityKey.SUBTASK_REMOVED,
  ActivityKey.SUBTASK_NAME_CHANGED,
  ActivityKey.SUBTASK_STATUS_CHANGED_DONE,
  ActivityKey.SUBTASK_STATUS_CHANGED_OPEN,
  ActivityKey.SUBTASK_SORT_CHANGED,
  ActivityKey.SUBTASK_ASSIGNEE_ADDED,
  ActivityKey.SUBTASK_ASSIGNEE_REMOVED
];

export const FILE_VERSION_ACTIVITIES = [
  ActivityKey.FILE_VERSION_CREATED,
  ActivityKey.FILE_VERSION_REMOVED,
  ActivityKey.FILE_ACTIVE_VERSION_CHANGED
];
