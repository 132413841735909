import { createAction, props } from '@ngrx/store';

import { Comment, CommentPropertiesToUpdate } from '@celum/work/app/core/model/entities/comment/comment.model';

export const CommentUpdateComment = createAction(
  '[Comment] Update Comment',
  props<{ comment: Comment; propertiesToUpdate: CommentPropertiesToUpdate }>()
);

export const CommentCreateComment = createAction('[Comment] Create Comment', props<{ comment: Comment }>());

export const CommentCreateCommentSucceeded = createAction(
  '[Comment] Create Comment Succeeded',
  props<{ comment: Comment; tempId: any }>()
);

export const CommentCreateCommentFailed = createAction(
  '[Comment] Create Comment Failed',
  props<{ comment: Comment }>()
);

export const CommentDeleteComment = createAction('[Comment] Delete Comment', props<{ comment: Comment }>());

export const CommentDeleteCommentSucceeded = createAction(
  '[Comment] Delete Comment Succeeded',
  props<{ comment: Comment }>()
);

export const CommentDeleteCommentFailed = createAction(
  '[Comment] Delete Comment Failed',
  props<{ comment: Comment }>()
);
