import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';
import { AggregationResponse, Collection } from '@celum/work/app/content-hub/model/content-hub.model';

import { AbstractContentHubService } from './abstract-content-hub.service';
import { Folder } from '../../core/model/entities/folder/folder.model';

@Injectable({ providedIn: 'root' })
export class AggregationService extends AbstractContentHubService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getCollectionsNodesAndAssets(
    repositoryId: string,
    assetsPage: number,
    assetsPageSize: number,
    nodesPage: number,
    nodesPageSize: number
  ): Observable<AggregationResponse> {
    const params: any = {
      repositoryId,
      assetsPage,
      assetsPageSize,
      nodesPage,
      nodesPageSize
    };

    return this.httpClient
      .get(
        `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content-hub/aggregation/collections-root-folders-assets`,
        { params }
      )
      .pipe(map(res => this.handleResult(res)));
  }

  public getRootFoldersAndAssets(
    repositoryId: string,
    nodeTypeId: number,
    assetsPage: number,
    assetsPageSize: number,
    nodesPage: number,
    nodesPageSize: number,
    collectionId: string
  ): Observable<AggregationResponse> {
    const params: any = {
      repositoryId,
      nodeTypeId,
      assetsPage,
      assetsPageSize,
      nodesPage,
      nodesPageSize
    };

    return this.httpClient
      .get(
        `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content-hub/aggregation/root-folders-assets`,
        {
          params
        }
      )
      .pipe(map(res => this.handleResult(res, collectionId)));
  }

  private handleResult(res: any, collectionId?: string): AggregationResponse {
    const collection: Collection = res.collections[0] as Collection;
    const folders = this.getFoldersWithParentId(res.folders.results, collectionId || (collection.id as any));
    const files = this.processFilesResult(res.files.results);

    return {
      ...res,
      folders: {
        ...res.folders,
        results: folders
      },
      files: {
        ...res.files,
        results: files
      }
    };
  }

  private getFoldersWithParentId(folders: Folder[], parentId: string): Folder[] {
    // Received folders have parentId null, but only root in the tree component has parentId null
    // So we set parentId of the first collection to the folders (those folders belong to the collection)
    return folders.map(folder => ({
      ...folder,
      parentId
    }));
  }
}
