import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, take } from 'rxjs';

import { AVATAR_SIZE, CelumDialog, CelumDialogConfiguration, ColorConstants } from '@celum/common-components';
import { InvitationStatus } from '@celum/work/app/core/model/entities/member';
import { Person } from '@celum/work/app/core/model/entities/person';
import { Roles } from '@celum/work/app/core/model/roles.model';
import { InviteDialogOkClicked } from '@celum/work/app/person/invite/components/invite-user-dialog/invite-dialog.actions';
import { WorkroomAvatarConfiguration } from '@celum/work/app/shared/components/workroom-avatar/workroom-avatar-configuration';
import { AvatarUtil, ColorService } from '@celum/work/app/shared/util';

import { InvitedPersonDialogModel } from '../../invited-person.model';
import { MailItem } from '../../mail-item.model';

@Component({
  selector: 'invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteUserDialog implements CelumDialog<InviteUserDialogConfiguration> {
  // necessary for parent class
  public confirmBtnText = 'PEOPLE.INVITE.INVITE';
  public headerText = 'PEOPLE.INVITE.INVITE_TO_WR';
  public invitedText = 'PEOPLE.INVITE.INVITED';
  public invitePlaceholderText = 'PEOPLE.INVITE.INVITE_QUESTION';
  public minCharText = 'PEOPLE.INVITE.MIN_CHAR';
  public initialPeopleSearchString;

  public color: string;

  public loading = false;

  public teamspaceId: number;

  public organisationMembersOnly: boolean;
  public statusesToDisplay: InvitationStatus[];
  public alreadyInvited: string[] = [];
  public executeFn: InviteUserDialogExecuteFn;
  public showMessage: boolean;
  public message = '';
  public infoNoteKey = '';
  public readonly maxMessageLength = 1000;
  public readonly hintOffset = 10;

  protected selection: InvitedPersonDialogModel[] = [];

  private avatarConfigsSubject$: BehaviorSubject<WorkroomAvatarConfiguration[]> = new BehaviorSubject([]);

  constructor(
    public dialogRef: MatDialogRef<InviteUserDialog>,
    private colorService: ColorService,
    private avatarUtil: AvatarUtil,
    private store: Store
  ) {}

  public get avatarConfigs$(): Observable<WorkroomAvatarConfiguration[]> {
    return this.avatarConfigsSubject$.asObservable();
  }

  public get selectedPersons(): Person[] {
    return this.selection.map(personObj => personObj.getPerson());
  }

  public get remainingMessageChars(): { remaining: number } {
    return {
      remaining: this.maxMessageLength - this.message.length
    };
  }

  public get showRemainingMessageCharsHint(): boolean {
    return this.remainingMessageChars.remaining <= this.hintOffset;
  }

  public trackByFn(index, item: WorkroomAvatarConfiguration) {
    return item.person?.id || item.email || index;
  }

  public configure({ color, args }: InviteUserDialogConfiguration): void {
    this.color = color;
    this.teamspaceId = args.teamspaceId;
    this.organisationMembersOnly = args?.organisationMembersOnly ?? false;
    this.alreadyInvited = args.alreadyInvited;
    this.headerText = args.headerText;
    this.confirmBtnText = args.confirmButtonText;
    this.statusesToDisplay = args.statusesToDisplay;
    this.initialPeopleSearchString = args.initialPeopleSearchString;
    this.executeFn = args.executeFn;
    this.showMessage = args.showMessage;
    this.infoNoteKey = args.infoNoteKey;
  }

  public onEmailAdded(email: string) {
    const mailItem = new MailItem(email.toLowerCase());
    mailItem.setColorForInvitedUserByEmail(this.colorService.getRandomColor());
    const invitedPerson = new InvitedPersonDialogModel(mailItem);
    invitedPerson.setPerson({
      email,
      id: email as any,
      invitationStatus: InvitationStatus.INVITED
    } as Person);
    this.checkForDuplicateAndAdd(invitedPerson);
  }

  public onItemSelected(person: Person) {
    const invitedPerson = new InvitedPersonDialogModel(new MailItem(person.email.toLowerCase()));
    invitedPerson.setPerson(person);
    this.checkForDuplicateAndAdd(invitedPerson);
  }

  public onOkClicked() {
    this.store.dispatch(InviteDialogOkClicked());

    const persons = this.selection;
    if (!persons || !persons.length) {
      return;
    }

    if (this.message.length > 0) {
      persons.forEach(person => person.setMessage(this.message));
    }

    this.loading = true;

    this.executeFn(persons).subscribe(res => {
      this.loading = false;
      this.dialogRef.close(res);
    });
  }

  public onRemoveUser(config: WorkroomAvatarConfiguration) {
    this.removeUserFromLists(config);
  }

  public checkForDuplicateAndAdd(invitedPerson: InvitedPersonDialogModel) {
    this.checkForDuplicateAndAddToLists(invitedPerson);
  }

  public getAvatar(item: InvitedPersonDialogModel): Observable<WorkroomAvatarConfiguration> {
    const person = item.getPerson();
    const mailItem = item.mailItem;

    return person
      ? this.avatarUtil.getAvatarConfigWithImageForCurrentTeamspace({
          person: item.getPerson(),
          size: AVATAR_SIZE.m
        })
      : of(this.avatarUtil.getAvatarConfigForEmail(mailItem.mail, mailItem.getDefaultColorForEmail(), AVATAR_SIZE.m));
  }

  public removeUserFromLists(config: WorkroomAvatarConfiguration) {
    const elementIndex = this.getInvitedPersonIndexByConfig(config);

    this.selection.splice(elementIndex, 1);

    const configs = this.avatarConfigsSubject$.getValue();
    configs.splice(elementIndex, 1);

    this.avatarConfigsSubject$.next(configs);
  }

  public onRoleChanged(config: WorkroomAvatarConfiguration, role: Roles) {
    const elementIndex = this.getInvitedPersonIndexByConfig(config);
    this.selection[elementIndex].setRole(role);
  }

  public checkForDuplicateAndAddToLists(invitedPerson: InvitedPersonDialogModel) {
    let matchedItems: InvitedPersonDialogModel[] = [];

    if (this.selection?.length > 0) {
      matchedItems = this.selection.filter(itm => itm.mailItem.getMail() === invitedPerson.mailItem.getMail());
    }

    if (matchedItems.length === 0) {
      this.selection.unshift(invitedPerson);

      const configs = this.avatarConfigsSubject$.getValue();

      this.getAvatar(invitedPerson)
        .pipe(take(1))
        .subscribe(config => {
          configs.unshift(config);

          this.avatarConfigsSubject$.next(configs);
        });
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  private getInvitedPersonIndexByConfig(config: WorkroomAvatarConfiguration) {
    return this.selection.findIndex(element => {
      const mail = element.mailItem.getMail();
      return [config.title, config.person.email].includes(mail);
    });
  }
}

export class InviteUserDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(public args: InviteUserDialogConfigurationArgs) {
    super('main');
  }

  public static forPeoplePage(
    teamspaceId: number,
    contributorEmails: string[],
    executeFn: InviteUserDialogExecuteFn
  ): InviteUserDialogConfiguration {
    const statusesToDisplay = [InvitationStatus.PENDING_APPROVAL, InvitationStatus.ACCEPTED, InvitationStatus.APPROVED];
    return new InviteUserDialogConfiguration({
      headerText: 'PEOPLE.INVITE.INVITE_TO_WR',
      confirmButtonText: 'PEOPLE.INVITE.INVITE',
      teamspaceId: teamspaceId,
      alreadyInvited: contributorEmails,
      statusesToDisplay: statusesToDisplay,
      organisationMembersOnly: false,
      showMessage: true,
      executeFn
    });
  }
}

export type InviteUserDialogExecuteFn = (people: InvitedPersonDialogModel[]) => Observable<any>;

export interface InviteUserDialogConfigurationArgs {
  headerText: string;
  confirmButtonText: string;
  teamspaceId: number;
  alreadyInvited: string[];
  statusesToDisplay: InvitationStatus[];
  organisationMembersOnly: boolean;
  showMessage: boolean;
  infoNoteKey?: string;
  initialPeopleSearchString?: string;
  executeFn: InviteUserDialogExecuteFn;
}
