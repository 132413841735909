import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';

import {
  SubtaskConvertToTask,
  SubtaskConvertToTaskFailed,
  SubtaskCreateSubtask,
  SubtaskCreateSubtaskFailed,
  SubtaskCreateSubtaskSucceeded,
  SubtaskDeleteSubtask,
  SubtaskDeleteSubtaskFailed,
  SubtaskLoadSubtasks,
  SubtaskLoadSubtasksFailed,
  SubtaskLoadSubtasksSucceeded
} from '@celum/work/app/core/api/subtask/subtask.actions';
import { initialPaginatedListState, initialSimpleListState } from '@celum/work/app/core/model/list.model';

import {
  SubtaskStateChangeDisplayedSubtasksCount,
  SubtaskStateChangeDisplayedSubtasksCountByOne,
  SubtaskStateSetSubtasksPanelExpandedState
} from './subtask-state.actions';
import { SubtaskStateState } from './subtask-state.model';

export const SUBTASKS_BATCH_SIZE = 5;

export const initialState: SubtaskStateState = {
  ...initialSimpleListState,
  ...initialPaginatedListState,
  batchSize: SUBTASKS_BATCH_SIZE,
  displayedSubtasksCount: 0,
  expanded: true
};

const reducer = createReducer(
  initialState,
  on(SubtaskLoadSubtasks, (state: SubtaskStateState, { offset }) =>
    produce(state, draft => {
      if (offset === 0) {
        draft.loading = true;
        draft.entityIds = [];
        draft.lastOffset = 0;
      } else {
        draft.loading = true;
      }
    })
  ),
  on(SubtaskLoadSubtasksSucceeded, (state: SubtaskStateState, { subtasks, paginationResult, resetState }) =>
    produce(state, draft => {
      const subtaskIds = subtasks.map(subtask => subtask.id);

      draft.loading = false;

      draft.entityIds = resetState ? [...subtaskIds] : [...state.entityIds, ...subtaskIds];
      draft.lastOffset = draft.entityIds.length;
      draft.paginationResult = paginationResult;
      draft.displayedSubtasksCount = draft.entityIds.length;
    })
  ),
  on(SubtaskLoadSubtasksFailed, (state: SubtaskStateState) =>
    produce(state, draft => {
      draft.loading = false;
    })
  ),
  on(SubtaskDeleteSubtask, SubtaskConvertToTask, (state: SubtaskStateState, { subtask }) =>
    produce(state, draft => {
      draft.entityIds = draft.entityIds.filter(id => id !== subtask.id);
      draft.lastOffset = draft.lastOffset - 1;
      draft.paginationResult.totalElementCount = draft.paginationResult.totalElementCount - 1;
    })
  ),
  on(SubtaskDeleteSubtaskFailed, SubtaskConvertToTaskFailed, (state: SubtaskStateState, { subtask }) =>
    produce(state, draft => {
      draft.entityIds = [...draft.entityIds, subtask.id];
      draft.lastOffset = draft.lastOffset + 1;
      draft.paginationResult.totalElementCount = draft.paginationResult.totalElementCount + 1;
    })
  ),
  on(SubtaskCreateSubtask, (state: SubtaskStateState, { tmpSubtask }) =>
    produce(state, draft => {
      draft.entityIds = [...draft.entityIds, tmpSubtask.id];
      draft.lastOffset = draft.lastOffset + 1;
      draft.paginationResult.totalElementCount = draft.paginationResult.totalElementCount + 1;
    })
  ),
  on(SubtaskCreateSubtaskSucceeded, (state: SubtaskStateState, { tmpSubtask, subtask }) =>
    produce(state, draft => {
      draft.entityIds = draft.entityIds.map(id => (id === tmpSubtask.id ? subtask.id : id));
    })
  ),
  on(SubtaskCreateSubtaskFailed, (state: SubtaskStateState, { tmpSubtask }) =>
    produce(state, draft => {
      draft.entityIds = draft.entityIds.filter(id => id !== tmpSubtask.id);
      draft.lastOffset = draft.lastOffset - 1;
      draft.paginationResult.totalElementCount = draft.paginationResult.totalElementCount - 1;
    })
  ),
  on(SubtaskStateChangeDisplayedSubtasksCount, (state: SubtaskStateState, { count }) =>
    produce(state, draft => {
      draft.displayedSubtasksCount = count;
    })
  ),
  on(SubtaskStateChangeDisplayedSubtasksCountByOne, (state: SubtaskStateState, { delta }) =>
    produce(state, draft => {
      draft.displayedSubtasksCount += delta;
    })
  ),
  on(SubtaskStateSetSubtasksPanelExpandedState, (state, { expanded }) =>
    produce(state, draft => {
      draft.expanded = expanded;
    })
  )
);

export function subtaskStateReducer(state: SubtaskStateState = initialState, action: Action): SubtaskStateState {
  return reducer(state, action);
}
