import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';

import {
  CelumAvatarListModule,
  CelumAvatarModule,
  CelumDialogModule,
  CelumEmptyPageModule,
  CelumIconModule,
  CelumInputsModule,
  CelumLoadingMaskModule,
  CelumResizeModule,
  InlineTextareaModule
} from '@celum/common-components';
import {
  CelumAutocompleteAreaModule,
  CelumListModule,
  CelumMagicButtonModule,
  CelumMenuModule,
  ClearSelectionOnDocumentClickDirective,
  NavigationButtonsModule
} from '@celum/internal-components';
import { CelumPipesModule } from '@celum/ng2base';
import { ContentHubMenuItemComponent } from '@celum/work/app/content-hub/components/content-hub-menu-item/content-hub-menu-item.component';
import { ContentHubExportMenuComponent } from '@celum/work/app/content-hub/components/export-menu/export-menu.component';
import { ContentHubImportMenuComponent } from '@celum/work/app/content-hub/components/import-menu/import-menu.component';
import { DownloadDriveDialog } from '@celum/work/app/shared/components/download-drive-dialog/download-drive-dialog';
import { FinishWorkroomDialog } from '@celum/work/app/shared/components/finish-workroom-dialog/finish-workroom-dialog.component';
import { InteractiveAvatars } from '@celum/work/app/shared/components/interactive-avatar/interactive-avatars.component';
import { LicenseLimitReachedComponent } from '@celum/work/app/shared/components/license-limit/license-limit-reached.component';
import { MenuSectionComponent } from '@celum/work/app/shared/components/menu-section/menu-section.component';
import { MessageBox } from '@celum/work/app/shared/components/message/message-box';
import { PortraitRestrictorComponent } from '@celum/work/app/shared/components/portrait-restrictor/portrait-restrictor.component';
import { SelectionTriggerWrapperComponent } from '@celum/work/app/shared/components/selection-trigger/selection-trigger-wrapper.component';
import { WorkroomAvatarList } from '@celum/work/app/shared/components/workroom-avatar-list/workroom-avatar-list.component';
import { DroppableDirective } from '@celum/work/app/shared/directives/droppable.directive';
import { ActiveVersionPipe } from '@celum/work/app/shared/pipes/active-version.pipe';
import { ImpureLocalizedDatePipe } from '@celum/work/app/shared/pipes/impure-localized-date.pipe';
import { TemplateCategoryNamePipe } from '@celum/work/app/shared/pipes/template-category-name.pipe';
import { ColorService } from '@celum/work/app/shared/util';

import { BrowsingDialogComponent } from './components/browsing-dialog/browsing-dialog.component';
import { ClickablePopupDialogComponent } from './components/clickable-popup-dialog/clickable-popup-dialog.component';
import { ConfirmationDialog } from './components/confirmation-dialog/confirmation-dialog.component';
import { FullscreenDialogHeaderComponent } from './components/fullscreen-dialog/fullscreen-dialog-header/fullscreen-dialog-header.component';
import { FullscreenDialogComponent } from './components/fullscreen-dialog/fullscreen-dialog.component';
import { InfoNoteComponent } from './components/info-note/info-note.component';
import { MultilinePersonTemplate } from './components/people-lookup/multiline-person-template/multiline-person-template.component';
import { MultilinePersonTemplateDriveWarningPipe } from './components/people-lookup/multiline-person-template/pipes/multiline-person-template-drive-warning.pipe';
import { MultilinePersonTemplateUserStatusPipe } from './components/people-lookup/multiline-person-template/pipes/multiline-person-template-user-status.pipe';
import { PeopleLookup } from './components/people-lookup/people-lookup/people-lookup.component';
import { PersonDialogView } from './components/person-dialog-view/person-dialog-view.component';
import { SearchAndSelectContributors } from './components/search-and-select/search-and-select-contributors/search-and-select-contributors.component';
import { SearchAndSelectEntryComponent } from './components/search-and-select/search-and-select-entry/search-and-select-entry.component';
import { SearchAndSelectPersonComponent } from './components/search-and-select/search-and-select-person/search-and-select-person.component';
import { SearchAndSelectTaskListComponent } from './components/search-and-select/search-and-select-task-list/search-and-select-task-list.component';
import { SearchAndSelect } from './components/search-and-select/search-and-select.component';
import { SearchField } from './components/search-field/search-field.component';
import { TextTruncatorComponent } from './components/text-truncator/text-truncator.component';
import { ToggleDrawerButtonComponent } from './components/toggle-drawer-button/toggle-drawer-button.component';
import { VerticalButtonComponent } from './components/vertical-button/vertical-button.component';
import { WorkroomAvatar } from './components/workroom-avatar/workroom-avatar.component';
import { WorkroomsLogoSpinner } from './components/workrooms-logo-spinner/workrooms-logo-spinner.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { AutoSelectDirective } from './directives/auto-select.directive';
import { CelumTooltipDirective } from './directives/celum-tooltip.directive';
import { ConfigurabableClickDirective } from './directives/configurable-click.directive';
import { DocumentDroppableDirective } from './directives/document-droppable.directive';
import { HasFeatureAccessDirective } from './directives/has-feature-access.directive';
import { WithImagePlaceholderDirective } from './directives/image-placeholder.directive';
import { MatSelectTooltipAutoDisablingDirective } from './directives/mat-select-tooltip-auto-disabling.directive';
import { StopClickPropagationDirective } from './directives/stop-click-propagation.directive';
import { TitleOnEllipsisDirective } from './directives/title-on-ellipsis.directive';
import { ColorPipe } from './pipes/color.pipe';
import { SlibResourceTokenPipe } from './pipes/download-url.pipe';
import { FileExtensionPipe } from './pipes/file-extension.pipe';
import { IsNullOrUndefinedPipe } from './pipes/is-null-or-undefined.pipe';
import { IsNumberPipe } from './pipes/is-number.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { PreviewUrlPipe } from './pipes/preview-url.pipe';
import { TeamspacePipe } from './pipes/teamspace.pipe';

const PIPES = [
  LocalizedDatePipe,
  ImpureLocalizedDatePipe,
  ColorPipe,
  SlibResourceTokenPipe,
  TeamspacePipe,
  ActiveVersionPipe,
  FileExtensionPipe,
  PreviewUrlPipe,
  TemplateCategoryNamePipe,
  IsNumberPipe,
  IsNullOrUndefinedPipe,
  MultilinePersonTemplateUserStatusPipe,
  MultilinePersonTemplateDriveWarningPipe
];

const DIRECTIVES = [
  TitleOnEllipsisDirective,
  DroppableDirective,
  DocumentDroppableDirective,
  WithImagePlaceholderDirective,
  AutoSelectDirective,
  HasFeatureAccessDirective,
  CelumTooltipDirective,
  ConfigurabableClickDirective,
  MatSelectTooltipAutoDisablingDirective,
  StopClickPropagationDirective
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    TranslateModule,
    CelumAvatarModule,
    CelumAvatarListModule,
    CelumAutocompleteAreaModule,
    MatAutocompleteModule,
    CelumDialogModule,
    CelumInputsModule,
    MatCheckboxModule,
    MatSelectModule,
    CelumIconModule,
    MatMenuModule,
    CelumMenuModule,
    InlineTextareaModule,
    CelumListModule,
    MatFormFieldModule,
    MatDividerModule,
    MatDialogModule,
    CelumLoadingMaskModule,
    CelumMagicButtonModule,
    CelumEmptyPageModule,
    NavigationButtonsModule,
    CelumPipesModule,
    MatTooltipModule,
    PlatformModule,
    MatSidenavModule,
    MatChipsModule,
    OverlayModule,
    MatButtonModule,
    MatListModule,
    CelumResizeModule,
    WorkroomAvatar,
    SelectionTriggerWrapperComponent,
    SearchField,
    AutoFocusDirective,
    TextTruncatorComponent,
    MatRippleModule,
    ClearSelectionOnDocumentClickDirective
  ],
  declarations: [
    MultilinePersonTemplate,
    PeopleLookup,
    PersonDialogView,
    ConfirmationDialog,
    SearchAndSelect,
    SearchAndSelectContributors,
    SearchAndSelectPersonComponent,
    SearchAndSelectTaskListComponent,
    InteractiveAvatars,
    WorkroomAvatarList,
    FinishWorkroomDialog,
    VerticalButtonComponent,
    FullscreenDialogComponent,
    FullscreenDialogHeaderComponent,
    MessageBox,
    DownloadDriveDialog,
    WorkroomsLogoSpinner,
    PortraitRestrictorComponent,
    ContentHubImportMenuComponent,
    BrowsingDialogComponent,
    ContentHubExportMenuComponent,
    MenuSectionComponent,
    ToggleDrawerButtonComponent,
    InfoNoteComponent,
    SearchAndSelectEntryComponent,
    LicenseLimitReachedComponent,
    ContentHubMenuItemComponent,
    ClickablePopupDialogComponent,
    ...PIPES,
    ...DIRECTIVES
  ],
  providers: [ColorService],
  exports: [
    CommonModule,
    NgxPermissionsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PeopleLookup,
    MultilinePersonTemplate,
    CelumAvatarModule,
    WorkroomAvatar,
    PersonDialogView,
    SearchField,
    ConfirmationDialog,
    SearchAndSelect,
    SearchAndSelectContributors,
    SearchAndSelectPersonComponent,
    SearchAndSelectTaskListComponent,
    InteractiveAvatars,
    WorkroomAvatarList,
    FinishWorkroomDialog,
    VerticalButtonComponent,
    ToggleDrawerButtonComponent,
    CelumAutocompleteAreaModule,
    MatAutocompleteModule,
    CelumDialogModule,
    CelumInputsModule,
    MatCheckboxModule,
    MatSelectModule,
    CelumIconModule,
    MatMenuModule,
    MatDialogModule,
    CelumMenuModule,
    InlineTextareaModule,
    CelumListModule,
    MatFormFieldModule,
    MatDividerModule,
    CelumLoadingMaskModule,
    CelumMagicButtonModule,
    CelumEmptyPageModule,
    NavigationButtonsModule,
    CelumPipesModule,
    FullscreenDialogComponent,
    MatTooltipModule,
    MessageBox,
    WorkroomsLogoSpinner,
    SelectionTriggerWrapperComponent,
    PortraitRestrictorComponent,
    ContentHubImportMenuComponent,
    BrowsingDialogComponent,
    ContentHubExportMenuComponent,
    MenuSectionComponent,
    TextTruncatorComponent,
    OverlayModule,
    InfoNoteComponent,
    MatButtonModule,
    SearchAndSelectEntryComponent,
    LicenseLimitReachedComponent,
    MatChipsModule,
    MatListModule,
    ContentHubMenuItemComponent,
    CelumResizeModule,
    ClickablePopupDialogComponent,
    AutoFocusDirective,
    ClearSelectionOnDocumentClickDirective,
    ...PIPES,
    ...DIRECTIVES
  ]
})
export class SharedModule {}
