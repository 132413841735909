import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, from, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { View } from '@celum/annotations';
import { FileFormat, FileVersion } from '@celum/work/app/core/model/entities/file-version/file-version.model';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { AnnotationUtilService } from '@celum/work/app/shared/util/annotation-util.service';

@Injectable({ providedIn: 'root' })
export class FileUtilService {
  constructor(
    private translateService: TranslateService,
    private annotationUtilService: AnnotationUtilService,
    private store: Store<any>
  ) {}

  public static downloadUrl(url: string, downloadName?: string): void {
    const link = document.createElement('a');
    link.href = url;

    if (downloadName) {
      link.download = downloadName;
    }

    link.click();
    link.remove();
  }

  public static downloadBlob(data: BlobPart, downloadName?: string): void {
    const blob = new Blob([data]);
    const blobUrl = window.URL.createObjectURL(blob);
    this.downloadUrl(blobUrl, downloadName);
    URL.revokeObjectURL(blobUrl);
  }

  public getFileView(fileVersion$: Observable<FileVersion>): Observable<View> {
    const renditions$ = fileVersion$.pipe(mergeMap(fileVersion => fileVersion.renditions(this.store)));

    return combineLatest([fileVersion$, renditions$, this.store.select(selectCurrentWorkroom)]).pipe(
      mergeMap(([fileVersion, renditions, currentWorkroom]) =>
        from(
          this.annotationUtilService.getFileView(
            fileVersion,
            currentWorkroom,
            renditions,
            this.translateService.currentLang
          )
        )
      )
    );
  }

  public hasAppropriateFileFormat(
    fileVersion$: Observable<FileVersion>,
    appropriateFormats: FileFormat[]
  ): Observable<boolean> {
    return this.getFileFormat(fileVersion$).pipe(
      map(format => {
        return appropriateFormats.includes(format);
      })
    );
  }

  public getFileFormat(fileVersion$: Observable<FileVersion>): Observable<FileFormat> {
    const renditions$ = fileVersion$.pipe(
      filter(fileVersion => !!fileVersion),
      mergeMap(fileVersion => fileVersion.renditions(this.store))
    );

    return combineLatest([fileVersion$, renditions$]).pipe(
      map(([fileVersion, renditions]) => {
        return this.annotationUtilService.getFileFormat(fileVersion, renditions);
      })
    );
  }
}
