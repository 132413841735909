<mat-tab-group
    class='forms-management-tabs'
    [(selectedIndex)]="selectedTabIndex"
    [disableRipple]="true"
    mat-stretch-tabs="false"
    mat-align-tabs="start" >
  <mat-tab
      [label]="'TEAMSPACE_MANAGEMENT.FORMS.TAB' | translate | uppercase"
      [labelClass]="!hasWorkroomsFinishPermission ? 'forms-tab--disabled' : null">
    <ng-template matTabContent>
      <forms-overview-tab
          [hasWorkroomsFinishPermission]="hasWorkroomsFinishPermission">
      </forms-overview-tab>
    </ng-template>
  </mat-tab>

  <mat-tab
      *ngIf="hasWorkroomsFinishPermission"
      [label]="'TEAMSPACE_MANAGEMENT.FIELDS.TAB' | translate | uppercase">
    <ng-template matTabContent>
      <fields-overview-tab [teamspace]="teamspace" ></fields-overview-tab>
    </ng-template>
  </mat-tab>
</mat-tab-group>
