import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { WorkroomStatus } from '@celum/work/app/core/model/entities/workroom';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { QuillEvents, QuillImagesLoadedEvent } from '@celum/work/app/shared/components/celum-quill/model/quill.events';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';

import { ResultConsumerService } from '../../../core/communication/result-consumer.service';
import { MetaInfo } from '../../../core/model';
import { selectFileVersionEntities } from '../../../core/model/entities/file-version/file-version.selectors';
import { Rendition, RenditionType } from '../../../core/model/entities/rendition/rendition.model';
import { AbstractWebsocketListener } from '../../../core/websocket/abstract-websocket-listener';
import { AbstractWebsocketWatcher } from '../../../core/websocket/watcher/abstract-websocket-watcher';

export interface RenditionMessage {
  rendition: Rendition;
  fileVersionNumber: number;
  fileId: string;
}

@Injectable()
export class LibrariesRenditionWebsocketListener extends AbstractWebsocketListener {
  private websocketSubscription: Subscription;

  constructor(
    private store: Store<any>,
    public websocketWatcher: AbstractWebsocketWatcher,
    private resultConsumerService: ResultConsumerService,
    private eventBus: ApplicationEventBus
  ) {
    super(websocketWatcher);

    this.store
      .select(selectCurrentWorkroom)
      .pipe(
        filter(
          workroom =>
            (!!workroom && !!workroom.slibResourceToken && workroom.status === WorkroomStatus.ACTIVE) || !workroom
        ),
        map(workroom => workroom?.renditionLibraryId),
        distinctUntilChanged((prev, curr) => prev === curr)
      )
      .subscribe(renditionLibraryId => {
        if (renditionLibraryId) {
          this.startListening(renditionLibraryId);
        } else {
          this.stopListening();
        }
      });
  }

  public startListening(renditionLibraryId: string): void {
    this.websocketSubscription = this.watchTopic<RenditionMessage>(`library.${renditionLibraryId}.rendition`)
      .pipe(
        withLatestFrom(this.store.select(selectFileVersionEntities)),
        tap(([renditionMessage, _]) => {
          this.eventBus.publishEvent({
            type: QuillEvents.QUILL_IMAGE_LOADED,
            data: {
              rendition: renditionMessage.rendition,
              fileId: renditionMessage.fileId
            }
          } as QuillImagesLoadedEvent);
        }),
        filter(([{ rendition }, fileVersions]) => !!fileVersions[rendition.fileVersionId])
      )
      .subscribe(([{ rendition }, _]) => {
        const metaInfo = MetaInfo.of([RenditionType.TYPE_KEY], RenditionType.instance().getSchema());
        this.resultConsumerService.translateAndAddToStore(rendition, metaInfo);
      });
  }

  public stopListening(): void {
    this.websocketSubscription?.unsubscribe();
  }
}
