import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';
import { TeamspaceAddAllPermissions } from '@celum/work/app/core/model/entities/teamspace/teamspace.actions';
import { WorkroomsUpsertPermissions } from '@celum/work/app/core/model/entities/workroom/workroom.actions';

export enum Permission {
  WORKROOM_READ = 'workroom.read',
  WORKROOM_CREATE = 'workroom.create',
  WORKROOM_UPDATE = 'workroom.update',
  WORKROOM_INVITE = 'workroom.invite',
  WORKROOM_FINISH = 'workroom.finish',
  WORKROOM_REOPEN = 'workroom.reopen',
  WORKROOM_DELETE = 'workroom.delete',
  WORKROOM_PERSON_ROLE_UPDATE = 'workroom.role_update',
  WORKROOM_PERSON_REMOVE = 'workroom.remove',

  TASK_LIST_UPDATE = 'task_list.update',
  TASK_LIST_MOVE = 'task_list.move',
  TASK_LIST_CREATE = 'task_list.create',
  TASK_LIST_DELETE = 'task_list.delete',

  TASK_LIST_OWNER_ADD = 'task_list.owner.add',
  TASK_LIST_OWNER_REMOVE = 'task_list.owner.remove',

  TASK_CREATE = 'task.create',
  TASK_MOVE = 'task.move',
  TASK_UPDATE = 'task.update',
  TASK_DELETE = 'task.delete',

  TASK_ATTACHMENT_REMOVE = 'task.attachment.remove',
  TASK_ATTACHMENT_ADD = 'task.attachment.add',
  UPLOAD_AND_ATTACH = 'task.upload_attach',
  TASK_ASSIGNEE_ADD = 'task.assignee.add',

  TASK_ASSIGNEE_REMOVE = 'task.assignee.remove',

  TEMPLATE_UPDATE = 'template.update',
  TEMPLATE_DELETE = 'template.delete',
  TEMPLATE_CREATE = 'template.create',
  TEMPLATE_READ = 'template.read',

  COMMENT_CREATE = 'comment.create',

  ALLOWED = 'permission.allowed',
  DENIED = 'permission.denied'
}

export enum WorkRoleScope {
  TEAMSPACE = 'TEAMSPACE',
  WORKROOM = 'WORKROOM'
}

export enum RoleName {
  WORKROOM_CREATOR = 'WORKROOM_CREATOR',
  WORKROOM_MAINTAINER = 'WORKROOM_MAINTAINER',
  TEMPLATE_MAINTAINER = 'TEMPLATE_MAINTAINER'
}

export interface Role {
  name: string;
  permissions?: string[];
}

export interface RolesWithPermissions {
  permissions: string[];
  roles: Role[];
}

export const NO_PERMISSIONS: RolesWithPermissions = {
  permissions: [],
  roles: []
};

@Injectable({ providedIn: 'root' })
export class PermissionService {
  public rolePermissionMapping: RolesWithPermissions = NO_PERMISSIONS;

  constructor(
    private httpClient: HttpClient,
    private store: Store<any>
  ) {}

  public loadWorkroomPermissions(workroomId: number): Observable<RolesWithPermissions> {
    return this.loadPermissionsForContextAndObjects(WorkRoleScope.WORKROOM, [workroomId]).pipe(
      map((res: RolesWithPermissions) => {
        const permissions = res[workroomId] as RolesWithPermissions;
        this.store.dispatch(
          WorkroomsUpsertPermissions({
            workroomId,
            permissions
          })
        );
        return permissions;
      })
    );
  }

  public loadTeamspacePermissions(teamspaceIds: number[]): Observable<{ [key: string]: RolesWithPermissions }> {
    return this.loadPermissionsForContextAndObjects(WorkRoleScope.TEAMSPACE, teamspaceIds).pipe(
      map((permissions: { [key: string]: RolesWithPermissions }) => {
        this.store.dispatch(
          TeamspaceAddAllPermissions({
            teamspaceIds,
            permissions
          })
        );
        return permissions;
      })
    );
  }

  public loadRolePermissionMapping() {
    return this.httpClient
      .get(`${CelumPropertiesProvider.properties.httpBaseAddress}/permission/all`)
      .subscribe((res: RolesWithPermissions) => (this.rolePermissionMapping = res));
  }

  private loadPermissionsForContextAndObjects(roleScope: WorkRoleScope, objectIds: number[]) {
    return this.httpClient.post(`${CelumPropertiesProvider.properties.httpBaseAddress}/permission/for-context`, {
      roleScope,
      objectIds
    });
  }
}
