import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';
import { MessageBoxConfigType } from '@celum/work/app/shared/components/message/message-box';

@Component({
  selector: 'robot',
  templateUrl: './robot.component.html',
  styleUrls: ['./robot.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RobotComponent {
  @Input() public title: string;
  @Input() public whenIcon: IconConfiguration;
  @Input() public thenIcon: IconConfiguration;
  @Input() public notApplied: boolean;
  @Input() public readonly: boolean;
  @Input() public messageBoxes: MessageBoxConfigType[];

  public get cssClasses() {
    const classes = ['robot-dialog_content_card'];
    if (this.readonly) {
      classes.push('robot-dialog_content_card--readonly');
    }
    if (this.notApplied) {
      classes.push('robot-dialog_content_card--not-applied');
    }
    return classes;
  }

  public get showMessageSection() {
    return this.messageBoxes?.length > 0 && (!this.readonly || this.notApplied);
  }

  public trackByIdxFn(idx: number): number {
    return idx;
  }
}
