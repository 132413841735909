import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, switchMap } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { ContentItemService } from '@celum/work/app/core/api/content-item/content-item.service';
import { Folder } from '@celum/work/app/core/model/entities/folder/folder.model';
import { selectTaskListById } from '@celum/work/app/core/model/entities/task';
import { selectLibraryId, Workroom } from '@celum/work/app/core/model/entities/workroom';
import {
  Automator,
  AutomatorSubType,
  AutomatorType,
  CreateTaskBasedOnFileAction,
  RobotActionType,
  RobotContext,
  RobotTriggerType,
  TaskCreationFileCreatedTrigger
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import { selectCurrentWorkroomId } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import {
  FolderTreeSelectionDialogComponent,
  FolderTreeSelectionDialogConfiguration
} from '@celum/work/app/robots/components/robot-dialog-templates/task-creation-automator/folder-tree-selection-dialog/folder-tree-selection-dialog.component';
import { FileFilterSectionType } from '@celum/work/app/shared/components/filter-sort/filter/filter.model';
import { MessageBoxConfigType } from '@celum/work/app/shared/components/message/message-box';
import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { RobotDialogConfiguration } from './../../robot-dialog/robot-dialog-config';
import { selectedFoldersChanged, trackFolderFn } from './task-creation-automator.util';
import { RobotsFactory } from '../../../services/robots-factory';
import { TaskCreationAutomatorService } from '../../../services/task-creation-automator.service';
import { BaseRobot } from '../base-robot/base-robot.component';

export interface FolderData {
  folderId: string;
  folderName: string;
}

@Component({
  selector: 'task-creation-automator',
  templateUrl: './task-creation-automator.component.html',
  styleUrls: ['./task-creation-automator.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCreationAutomatorComponent extends BaseRobot<Workroom> {
  public readonly TARGETING_ROBOT_TYPE = AutomatorType.TASK_CREATION;

  public folderIcon: IconConfiguration;
  public folderSelectorIcon: IconConfiguration;
  public cancelIcon: IconConfiguration;
  public taskIcon: IconConfiguration;

  public allFolders: FolderData[] = [];
  public selectedFolders: FolderData[] = [];
  public initialySelectedFolders: FolderData[] = [];
  public trackByFn = trackFolderFn;
  public maxNumberOfDisplayableFolders = 1;

  private workroomId: number;

  constructor(
    protected robotsFactory: RobotsFactory,
    protected translateService: TranslateService,
    protected automatorService: TaskCreationAutomatorService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogOpener: CelumDialogOpener,
    private contentItemService: ContentItemService,
    private store: Store<any>
  ) {
    super(robotsFactory, translateService, automatorService);
  }

  public get messageBoxes$(): Observable<MessageBoxConfigType[]> {
    return this.notApplied$.pipe(
      withLatestFrom(this.store.select(selectCurrentWorkroomId)),
      map(([notApplied, workroomId]) => {
        return notApplied ? this.createWarningMessage(notNullOrUndefined(workroomId)) : [];
      })
    );
  }

  public get taskListName(): Observable<string> {
    return this.sourceEntity
      ? of(this.sourceEntity.name)
      : this.store.select(selectTaskListById(this.taskListId)).pipe(map(taskList => (taskList ? taskList.name : '')));
  }

  public get taskListId(): number {
    return this.sourceEntity
      ? this.sourceEntity.id
      : ((this.robot as Automator).action as CreateTaskBasedOnFileAction).taskListId;
  }

  public createWarningMessage(insideExistingWorkroom: boolean): MessageBoxConfigType[] {
    return insideExistingWorkroom
      ? [
          {
            type: 'warn',
            text: this.translateService.instant(`ROBOTS.TASK_MANAGEMENT.TASK_CREATION.ERROR_NOTE`)
          }
        ]
      : [
          {
            type: 'warn',
            text: this.translateService.instant('ROBOTS.TASK_MANAGEMENT.TASK_CREATION.NO_FOLDER_YET_ERROR_NOTE')
          }
        ];
  }

  public configure(configuration: RobotDialogConfiguration<Workroom>): void {
    super.configure(configuration);
    this.workroomId = configuration.args.workroomId;
    this.initSelectedFolders(this.workroomId);
    this.initializeIcons();
  }

  public removeFolder(folderId: string): void {
    this.selectedFolders = this.selectedFolders.filter(folder => folder.folderId !== folderId);
  }

  public isValid(): boolean {
    return (
      this.selectedFolders.length !== 0 && selectedFoldersChanged(this.initialySelectedFolders, this.selectedFolders)
    );
  }

  public getResult(): Automator {
    const trigger: TaskCreationFileCreatedTrigger = {
      type: RobotTriggerType.FILE_CREATED,
      folderIds: this.selectedFolders.map(folder => folder.folderId)
    };
    const action: CreateTaskBasedOnFileAction = {
      type: RobotActionType.CREATE_TASK_BASED_ON_FILE,
      taskListId: this.taskListId
    };
    return {
      type: AutomatorType.TASK_CREATION,
      sourceContext: RobotContext.TASK_LIST,
      subType: AutomatorSubType.AUTOMATIC_TASK_CREATION,
      sourceId: this.taskListId,
      trigger: trigger,
      action: action
    };
  }

  public async openSelectFolders(): Promise<void> {
    const alreadySelectedFolders: Folder[] = this.selectedFolders.map(folder => {
      return {
        id: folder.folderId,
        name: folder.folderName
      } as any;
    });

    const result: Folder[] = await this.dialogOpener.showDialog(
      FolderTreeSelectionDialogComponent.DIALOG_ID,
      FolderTreeSelectionDialogComponent,
      new FolderTreeSelectionDialogConfiguration(alreadySelectedFolders),
      { disableClose: false }
    );

    if (result) {
      this.selectedFolders = result.map(folder => ({
        folderId: folder.id,
        folderName: folder.name
      }));
      this.changeDetectorRef.detectChanges();
    }
  }

  public getFolderDisplayName(name: string, index: number, totalCount: number): string {
    const total = notNullOrUndefined(totalCount) ? totalCount : 0;
    const textWhenPart2 = this.translateService.instant('ROBOTS.TASK_MANAGEMENT.TASK_CREATION.WHEN.PART_2');
    const delimiter = index < total - 1 || textWhenPart2 === '' ? ',' : '';

    return name !== undefined ? `${name}${delimiter}` : '';
  }

  public getDisplayableSelectedFolders(): FolderData[] {
    return this.selectedFolders.slice(0, this.maxNumberOfDisplayableFolders);
  }

  public getDisplayableFoldersDifference(): number {
    const totalFolders = this.selectedFolders.length;
    return totalFolders > 0 ? totalFolders - this.maxNumberOfDisplayableFolders : 0;
  }

  private initSelectedFolders(workroomId: number): void {
    const automator = this.robot as Automator;
    const folderIds = automator ? (automator.trigger as TaskCreationFileCreatedTrigger).folderIds : [];

    this.selectedFolders = folderIds.map(folderId => ({
      folderId: folderId,
      folderName: ''
    }));
    this.initialySelectedFolders = this.selectedFolders;

    if (notNullOrUndefined(workroomId)) {
      this.configureSelectedFolderNames(workroomId);
    }
  }

  private configureSelectedFolderNames(workroomId: number) {
    this.store
      .select(selectLibraryId(workroomId))
      .pipe(
        take(1),
        map(libraryId => this.buildContentItemSearchArgs(libraryId)),
        switchMap(args => this.contentItemService.loadContentItemsWithoutStore(args))
      )
      .subscribe(response => {
        const folders = response.results;
        this.allFolders = folders.map(folder => ({ folderId: folder.id, folderName: folder.name }));
        const folderNamesDictionary = folders.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {});
        this.selectedFolders.forEach(folder => (folder.folderName = folderNamesDictionary[folder.folderId]));
        this.changeDetectorRef.markForCheck();
      });
  }

  private buildContentItemSearchArgs(libraryId: string) {
    return {
      filter: {
        sections: [
          {
            type: FileFilterSectionType.CONTENT_TYPE,
            items: [
              {
                id: 'temp-folder-filter',
                value: 'FOLDER'
              }
            ]
          }
        ]
      },
      objectId: this.selectedFolders.map(sf => sf.folderId.toString()),
      libraryId
    };
  }

  private initializeIcons(): void {
    this.folderIcon = IconConfiguration.small('content-hub-folder-empty').withIconSize(this.iconSize);
    this.cancelIcon = IconConfiguration.small('cancel-s');
    this.folderSelectorIcon = IconConfiguration.small('ch-collection').withIconSize(30);
    this.taskIcon = IconConfiguration.small('robot-task-create').withIconSize(this.iconSize);
    this.automatorIcon = IconConfiguration.large('icon-general-robot-import').withIconSize(this.iconSize);
  }
}
