import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';
import { LocalizedValue } from '@celum/core';

/**
 * Basic context menu item providing the default styling of a context menu element.
 */
@Component({
             selector: 'icon-menu-item',
             templateUrl: './icon-menu-item.html',
             styleUrls: ['./icon-menu-item.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             exportAs: 'iconMenuItem'
           })
export class IconMenuItem {

  @Input() public disabled = false;
  @Input() public icon: IconConfiguration;
  @Input() public text: string | LocalizedValue;
  @Input() public tooltipText?: string | LocalizedValue;

  @HostBinding('class')
  public cssClass = 'icon-menu-item';

}
