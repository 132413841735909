import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { WorkroomGroupService } from '@celum/work/app/core/api/workroom-group/workroom-group.service';
import {
  WorkroomGroupDelete,
  WorkroomGroupDeleteFailed,
  WorkroomGroupDeleteSucceded,
  WorkroomGroupEditName,
  WorkroomGroupEditNameFailed,
  WorkroomGroupMove,
  WorkroomGroupMoveFailed
} from '@celum/work/app/core/model/entities/workroom/group/workroom-group.actions';
import { WorkroomGroup } from '@celum/work/app/core/model/entities/workroom/group/workroom-group.model';

import { FailureHandler } from '../../error/failure-handler.service';
import {
  WorkroomGroupItemMove,
  WorkroomGroupItemMoveFailed
} from '../../model/entities/workroom/group-item/workroom-group-item.actions';

@Injectable()
export class WorkroomGroupEffects {
  public moveWorkroom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkroomGroupItemMove),
        mergeMap(action =>
          this.workroomGroupService
            .moveWorkroom(action.workroomGroupItemId, action.targetGroupId, action.currentSort)
            .pipe(
              catchError(err => {
                this.failureHandler.handleError(err);
                this.store.dispatch(
                  WorkroomGroupItemMoveFailed({
                    ...action,
                    sourceGroupId: action.targetGroupId,
                    targetGroupId: action.sourceGroupId,
                    sort: action.previousSort
                  })
                );
                return EMPTY;
              })
            )
        )
      ),
    { dispatch: false }
  );

  public moveGroup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkroomGroupMove),
        mergeMap(({ workroomGroupId, currentSort, previousSort }) =>
          this.workroomGroupService.patchWorkroomGroup(workroomGroupId, { sort: currentSort } as WorkroomGroup).pipe(
            catchError(err => {
              this.failureHandler.handleError(err);
              this.store.dispatch(
                WorkroomGroupMoveFailed({
                  workroomGroupId,
                  sort: previousSort
                })
              );
              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  public editGroupName$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkroomGroupEditName),
        mergeMap(({ workroomGroupId, currentName, previousName }) =>
          this.workroomGroupService.patchWorkroomGroup(workroomGroupId, { name: currentName } as WorkroomGroup).pipe(
            catchError(err => {
              this.failureHandler.handleError(err);
              this.store.dispatch(
                WorkroomGroupEditNameFailed({
                  workroomGroupId,
                  name: previousName
                })
              );
              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  public deleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomGroupDelete),
      mergeMap(({ group }) =>
        this.workroomGroupService.deleteWorkroomGroup(group.id).pipe(
          map(() => WorkroomGroupDeleteSucceded({ group })),
          catchError(err => {
            this.failureHandler.handleError(err);
            this.store.dispatch(
              WorkroomGroupDeleteFailed({
                group
              })
            );
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private workroomGroupService: WorkroomGroupService,
    private actions$: Actions,
    private failureHandler: FailureHandler,
    private store: Store<any>
  ) {}
}
