import { createAction, props } from '@ngrx/store';

import { Task } from './task.model';

export const TasksUpsertOne = createAction(
  '[Tasks] Upsert One',
  props<{ task: Task; propertiesToUpdate?: string[] }>()
);

export const TasksUpsertMany = createAction(
  '[Tasks] Upsert Many',
  props<{ tasks: Task[]; propertiesToUpdate?: string[] }>()
);

export const TasksDeleteOne = createAction('[Tasks] Delete One', props<{ task: Task }>());

export const TasksDeleteMany = createAction('[Tasks] Delete Many', props<{ ids: number[] }>());

export const TaskHandleAssignee = createAction(
  '[Task] Handle Assignee',
  props<{ entityId: number; assigneeId: number; operation: 'add' | 'remove' }>()
);
