import Parchment from 'parchment';
import Quill, { QuillOptionsStatic } from 'quill';

const BlockEmbed = Quill.import('blots/embed') as typeof Parchment.Embed;

export class QuillImageRenderer {
  public quill: Quill;
  public options: QuillImageRendererOptions;

  constructor(quill: Quill, options: QuillImageRendererOptions) {
    this.quill = quill;
    this.options = options;
  }

  public reloadImages() {
    this.quill.setContents(this.quill.getContents());
  }

  public removeBrokenImages(fileIds: string[]): void {
    const delta = this.quill.getContents();
    delta.ops = delta.ops.filter(
      operation =>
        !this.isQuillImageFn(operation) ||
        (this.isQuillImageFn(operation) && !fileIds.includes(operation.insert[QuillImageBlot.className].data_file_id))
    );
    this.quill.setContents(delta);
  }

  public getImagesFileIds(): string[] {
    const delta = this.quill.getContents();
    return delta.ops
      .filter(operation => this.isQuillImageFn(operation))
      .map(operation => operation.insert[QuillImageBlot.className].data_file_id);
  }

  public handleImageLoaded(fileId: string, imgUrl: string) {
    const imageNode = this.quill.root.querySelector(
      `img[${QuillImageBlot.fileIdAttribute}="${fileId}"]`
    ) as HTMLImageElement;
    if (!imageNode) {
      return;
    }
    imageNode.src = imgUrl;
  }

  public insertImagePlaceholder(fileId: string): void {
    let index = (this.quill.getSelection() || {}).index;
    if (index === undefined || index < 0) {
      index = this.quill.getLength();
    }

    const quillImageValue: QuillImageValue = {
      // eslint-disable-next-line camelcase
      data_file_id: fileId.toString()
    };

    this.quill.insertText(index++, '\n');
    this.quill.insertEmbed(index++, QuillImageBlot.className, quillImageValue, 'user');
    this.quill.insertText(index++, '\n');
    this.quill.setSelection(index, 0);
  }

  private isQuillImageFn(operation): boolean {
    return typeof operation.insert === `object` && operation.insert.hasOwnProperty(QuillImageBlot.className);
  }
}

export class QuillImageBlot extends BlockEmbed {
  public static blotName = 'quill-image';
  public static className = 'quill-image';
  public static tagName = 'img';
  public static fileIdAttribute = 'data-file-id';

  constructor(node: Node) {
    super(node);

    node.addEventListener('click', () =>
      this.getQuillImagePlugin().options.handleImageClicked(
        (node as HTMLElement).getAttribute(QuillImageBlot.fileIdAttribute)
      )
    );

    node.addEventListener('load', () =>
      this.getQuillImagePlugin().options.handleImageTagLoaded?.call(
        null,
        (node as HTMLElement).getAttribute(QuillImageBlot.fileIdAttribute)
      )
    );
  }

  public static create(value: QuillImageValue) {
    const node = super.create(value) as HTMLElement;
    node.setAttribute(QuillImageBlot.fileIdAttribute, value.data_file_id);
    return node;
  }

  public static value(node: HTMLElement): QuillImageValue {
    return {
      // eslint-disable-next-line camelcase
      data_file_id: node.getAttribute(QuillImageBlot.fileIdAttribute)
    };
  }

  public attach() {
    super.attach();

    this.getQuillImagePlugin()
      .options.getImageUrl(this.imageNode.getAttribute(QuillImageBlot.fileIdAttribute))
      .then(url => (this.imageNode.src = url || this.getQuillImagePlugin().options.placeholderImageUrl));
  }

  // This will NOT work before attach() is called.
  private get quill(): Quill {
    if (!this.scroll || !this.scroll.domNode.parentNode) {
      return null;
    }

    return Quill.find(this.scroll.domNode.parentNode!);
  }

  private get imageNode(): HTMLImageElement {
    return this.domNode as HTMLImageElement;
  }

  private getQuillImagePlugin(): QuillImageRenderer {
    const plugin: QuillImageRenderer = this.quill.getModule('quillImageRenderer');
    return plugin;
  }
}

export interface QuillImageValue {
  data_file_id: string;
}

export interface QuillImageRendererOptions extends QuillOptionsStatic {
  placeholderImageUrl: string;
  quillImageHandlerUid: string;
  getImageUrl: (fileId: string) => Promise<string | undefined>;
  handleImageClicked: (fileId: string) => void;
  handleImageTagLoaded?: (fileId: string) => void;
}

Quill.register(QuillImageBlot);
