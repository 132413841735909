<celum-dialog class="move-template" (onCloseAll)="closeDialog()">
  <celum-dialog-header class="move-template_header">
    {{ 'TEMPLATE_CHOOSER.ACTION_DIALOG.MOVE_DIALOG.HEADLINE' | translate }}
  </celum-dialog-header>
  <celum-dialog-content>
    <div class="move-template_label">{{ 'TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.CATEGORY' | translate }}</div>

    <ng-container *ngIf="template.categoryId !== null; else categoryName">
      <div>{{ 'TEMPLATE_CHOOSER.PERSONAL_CATEGORY' | translate }}</div>
    </ng-container>

    <ng-template #categoryName>
      <div>{{ category$ | async | templateCategoryName }}</div>
    </ng-template>
  </celum-dialog-content>

  <celum-dialog-footer
      [loading]="loading$ | async"
      [confirmBtnText]="'TEMPLATE_CHOOSER.ACTION_DIALOG.MOVE_DIALOG.CONFIRM'"
      [cancelBtnText]="'TEMPLATE_CHOOSER.ACTION_DIALOG.MOVE_DIALOG.CANCEL'"
      (onConfirm)="moveTemplate()"
      (onCancel)="closeDialog()">
  </celum-dialog-footer>
</celum-dialog>
