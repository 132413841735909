import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { RolesWithPermissions } from '@celum/work/app/core/api/permission';

import { StorageDetails, Teamspace } from './teamspace.model';

export const TeamspacesUpsertOne = createAction('[Teamspaces] Upsert One', props<{ teamspace: Teamspace }>());

export const TeamspacesUpsertMany = createAction('[Teamspaces] Upsert Many', props<{ teamspaces: Teamspace[] }>());

export const TeamspacesDeleteOne = createAction('[Teamspaces] Delete One', props<{ id: number }>());

export const TeamspaceAddAllPermissions = createAction(
  '[Teamspaces] Add All Permissions',
  props<{ teamspaceIds: number[]; permissions: { [key: number]: RolesWithPermissions } }>()
);

export const TeamspacesLoaded = createAction('[Teamspaces] Loaded');

export const TeamspacesUpdateOne = createAction(
  '[Teamspaces] Update One',
  props<{ teamspaceUpdate: Update<Teamspace> }>()
);

export const UpdateTeamspaceStorageDetails = createAction(
  '[Teamspace] Update Storage Details',
  props<{
    teamspaceId: number;
    storageDetails: StorageDetails;
  }>()
);

export const TeamspaceUpdateUsedStorage = createAction(
  '[Teamspace] Update used storage',
  props<{ teamspaceId: number; fileSize: number }>()
);
