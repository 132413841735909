import packageJson from '../../../../package.json';

export const environment = {
  envName: 'PROD',
  production: true,
  test: false,
  versions: {
    app: packageJson.version
  }
};
