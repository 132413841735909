<robot
    [title]="'ROBOTS.TASK_MANAGEMENT.TASK_CREATION.TITLE'"
    [whenIcon]="folderIcon"
    [thenIcon]="taskIcon"
    [readonly]="isReadonly"
    [messageBoxes]="messageBoxes$ | async"
    [notApplied]="notApplied$ | async">
  <ng-container when>
    <span class="robot-dialog_content_text" [innerHTML]="'ROBOTS.TASK_MANAGEMENT.TASK_CREATION.WHEN.PART_1' | translate"></span>

    <ng-container *ngIf="selectedFolders && !isReadonly">
      <mat-chip-option
          *ngFor="let folder of selectedFolders; trackBy:trackByFn"
          class="folder-chip cursor-default"
          disableRipple
          [selectable]='false'>
        <text-truncator
            class="robot-dialog_folder-chip_text"
            [text]="folder.folderName"
            [updateOnAfterViewChecked]="true"></text-truncator>
        <celum-icon
            class="robot-dialog_folder-chip_remove-icon"
            [configuration]="cancelIcon"
            [clickable]="true"
            (click)="removeFolder(folder.folderId)">
        </celum-icon>
      </mat-chip-option>
    </ng-container>

    <ng-container *ngIf="selectedFolders && isReadonly">
      <mat-chip-option
          *ngFor="let folder of getDisplayableSelectedFolders(); trackBy:trackByFn, index as i"
          class="folder-chip">
        <text-truncator
            class="robot-dialog_folder-chip_text"
            [text]="folder.folderName">
        </text-truncator>
      </mat-chip-option>
      <mat-chip-option class="folder-chip robot-dialog_folder-count" *ngIf="getDisplayableFoldersDifference() > 0">
        <strong>{{"+" + getDisplayableFoldersDifference()}}</strong>
      </mat-chip-option>
    </ng-container>

    <ng-container *ngTemplateOutlet="folderSelectionTemplate"></ng-container>

    <span class="robot-dialog_content_text" [innerHTML]="'ROBOTS.TASK_MANAGEMENT.TASK_CREATION.WHEN.PART_2' | translate"></span>

  </ng-container>

  <ng-container then>
    <span
        class="robot-dialog_content_text"
        [innerHTML]="'ROBOTS.TASK_MANAGEMENT.TASK_CREATION.THEN' | translate: {taskListName: (taskListName | async).toUpperCase()}"></span>
  </ng-container>
</robot>

<ng-template #folderSelectionTemplate>
  <celum-icon
      *ngIf="!isReadonly"
      [matTooltip]="'ROBOTS.TASK_MANAGEMENT.TASK_CREATION.SELECT_FOLDER_TOOLTIP' | translate"
      class="folder-selector"
      [attr.data-cy]="'folder-selector'"
      [configuration]="folderSelectorIcon"
      (click)="openSelectFolders()"
      [clickable]="true">
  </celum-icon>
</ng-template>
