import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';
import { ReactiveService } from '@celum/core';
import { ClientNotification } from '@celum/work/app/core/client-notification/model/client-notification.model';
import { NotificationWebsocketListener } from '@celum/work/app/core/client-notification/notification-websocket-listener.service';
import { WorkroomsUpsertConfiguration } from '@celum/work/app/core/model/entities/workroom';

@Injectable({
  providedIn: 'root'
})
export class SuccessClientNotificationsListener extends ReactiveService {
  public static readonly SNACKBAR_ID = 'success-client-notifications';

  constructor(
    private store: Store<any>,
    private clientNotificationWebsocketListener: NotificationWebsocketListener<ClientNotification>
  ) {
    super();
    this.startListening();
  }

  public startListening(): void {
    this.clientNotificationWebsocketListener.clientNotificationsSubj
      .pipe(
        filter(notification => notification.typeKey === 'success'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(notification => {
        if (notification.key) {
          const config = SnackbarConfiguration.success(`CLIENT_NOTIFICATION.SUCCESS.${notification.key}`);
          this.store.dispatch(new ShowSnackbar(SuccessClientNotificationsListener.SNACKBAR_ID, SimpleSnackbar, config));
        }
        this.store.dispatch(
          WorkroomsUpsertConfiguration({
            workroomId: notification.data.workroomId,
            configuration: notification.data.workroomConfiguration
          })
        );
      });
  }
}
