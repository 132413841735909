import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { IconConfiguration } from '../../icon/index';

@Component({
             selector: 'inline-textarea',
             templateUrl: './inline-textarea.html',
             styleUrls: ['./inline-textarea.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class InlineTextarea implements AfterViewInit {

  @HostBinding('class') public componentCls = 'inline-textarea';

  @Input() public value = '';
  @Input() public maxLength: number;
  @Input() public clearOnSave = false;
  @Input() public toggleEditMode = true;
  @Input() public placeholder = '';

  @Output() public readonly save = new EventEmitter<string>();
  @Output() public readonly cancel = new EventEmitter<void>();
  @Output() public readonly startEdit = new EventEmitter<void>();
  @Output() public readonly endEdit = new EventEmitter<void>();

  @ViewChild('textArea', { static: false }) public textArea: ElementRef;

  public okIcon = IconConfiguration.medium('check-m', 'COMMON.SAVE');
  public cancelIcon = IconConfiguration.medium('cancel-m', 'COMMON.CANCEL');
  public editIcon = IconConfiguration.medium('edit-m');

  public editing = false;
  private initialized = false;

  constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) { }

  public ngAfterViewInit(): void {
    this.initialized = true;

    if (!this.toggleEditMode) {
      this.textArea.nativeElement.focus();
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    // prevent too early consumption of clicks... -> otherwise this will lead to immediate close again
    if (!this.initialized) {
      return;
    }

    if (this.el.nativeElement.contains(targetElement)) {
      if (!this.editing) {
        this.editing = true;
        this.startEdit.emit();

        this.cdRef.detectChanges();
        this.textArea.nativeElement.value = this.value;
        this.textArea.nativeElement.focus();
      }
    } else {
      this.onSave();
    }
  }

  public onSave(event?: Event): void {
    this.editing = false;

    if (event) {
      // prevent enter key being actually added
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.textArea) {
      const value = this.textArea.nativeElement.value;
      const valueChanged = this.value !== value;

      this.value = value;

      if (this.clearOnSave) {
        this.textArea.nativeElement.value = '';
        this.textArea.nativeElement.focus();
        this.value = '';
      }

      if (valueChanged) {
        this.save.emit(value);
      } else {
        this.cancel.emit();
      }
    }
  }

  public onCancel(event?: Event): void {
    this.editing = false;

    this.endEdit.emit();
    this.cancel.emit();

    this.textArea.nativeElement.value = this.value;

    if (event) {
      event.stopPropagation();
    }
  }

  public reset(): void {
    this.editing = false;

    if (this.textArea && this.textArea.nativeElement) {
      this.textArea.nativeElement.value = this.value;
      this.textArea.nativeElement.blur();
    }
  }

}
