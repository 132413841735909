import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { ExecutableAction } from '@celum/core';
import { CelumDialogOpener } from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';
import { ProgressTaskActions } from '@celum/work/app/progress-task/store/progress-task.actions';
import {
  ProgressTask,
  ProgressTaskStatus,
  ProgressTaskType
} from '@celum/work/app/progress-task/store/progress-task.model';
import {
  selectProgressTaskById,
  selectProgressTasksByType
} from '@celum/work/app/progress-task/store/progress-task.selectors';
import {
  ConfirmationDialog,
  ConfirmationDialogConfiguration
} from '@celum/work/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { SnackbarStateButtonConfig } from '@celum/work/app/shared/components/snackbar-group/config/snackbar-state-button.config';

export type ProgressSnackbarState = 'error' | 'in-progress' | 'finished';

@Component({
  selector: 'progress-task-snackbar-group',
  templateUrl: './progress-task-snackbar-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressTaskSnackbarGroup extends ReactiveComponent implements OnInit {
  @Input()
  public progressTaskType: ProgressTaskType;

  public progressTasks$: Observable<ProgressTask<any, any>[]>;
  public titleConfig$: Observable<{
    titleIcon: IconConfiguration;
    title: string;
    titleArgs: object;
    progress: number;
    failed: number;
  }>;

  constructor(
    private store: Store<any>,
    private dialogOpener: CelumDialogOpener,
    private translateService: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.progressTasks$ = this.store.select(selectProgressTasksByType(this.progressTaskType)).pipe(
      map(progressTasks => progressTasks.filter(progressTask => !progressTask.hiddenFromUi)),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr))
    );
    this.titleConfig$ = this.progressTasks$.pipe(map(uploadItems => this.getTitleConfig(uploadItems)));
  }

  public onCloseAll(): void {
    this.progressTasks$.pipe(take(1)).subscribe(async progressTasks => {
      const pendingProgressTasks = progressTasks.filter(({ status }) =>
        [ProgressTaskStatus.IN_PROGRESS, ProgressTaskStatus.QUEUED].includes(status)
      );

      if (pendingProgressTasks.length) {
        const proceed = await this.shouldProceed();

        if (!proceed) {
          return;
        }

        pendingProgressTasks.forEach(progressTask => this.cancel(progressTask));
      }

      this.store.dispatch(ProgressTaskActions.CloseSnackbarGroup({ progressTaskType: this.progressTaskType }));
    });
  }

  public onDismiss(): void {
    this.store.dispatch(ProgressTaskActions.CloseSnackbarGroup({ progressTaskType: this.progressTaskType }));
  }

  public cancel({ id }: ProgressTask<any, any>): void {
    this.store
      .select(selectProgressTaskById(id))
      .pipe(take(1))
      .subscribe(progressTask => this.store.dispatch(ProgressTaskActions.Cancel({ progressTask })));
  }

  public trackByFn(index, item: ProgressTask<any, any>) {
    return item.id || index;
  }

  public getProgressTaskIcon(progressTask: ProgressTask<any, any>): IconConfiguration {
    const progressSnackbarState = this.getProgressSnackbarState(progressTask);
    if (progressSnackbarState === 'error') {
      return IconConfiguration.medium('error-circle-m').withColor(ColorConstants.ERROR);
    }

    if (progressSnackbarState === 'finished') {
      return IconConfiguration.medium('check-circle-m').withColor(ColorConstants.SUCCESS);
    }

    return null;
  }

  public getProgressSnackbarState(progressTask: ProgressTask<any, any>): ProgressSnackbarState {
    switch (progressTask.status) {
      case ProgressTaskStatus.IN_PROGRESS:
      case ProgressTaskStatus.QUEUED:
        return 'in-progress';
      case ProgressTaskStatus.FAILED:
        return 'error';
      case ProgressTaskStatus.FINISHED:
        return 'finished';
    }
  }

  public getProgressTaskButtonConfigs(progressTask: ProgressTask<any, any>): SnackbarStateButtonConfig[] {
    const abortButton = {
      action: new ExecutableAction(() => {
        this.cancel(progressTask);
      }, 'AbortTask'),
      text: 'COMMON.ABORT',
      buttonColor: ColorConstants.BLUE_GRAY_800,
      identifier: 'AbortTask'
    };

    return [
      {
        button: abortButton,
        applicableWhenState: [ProgressTaskStatus.QUEUED, ProgressTaskStatus.IN_PROGRESS]
      },
      ...progressTask.snackbarActions
    ];
  }

  private shouldProceed(): Promise<boolean> {
    const header = this.translateService.instant(`PROGRESS_TASK.${this.progressTaskType}.ABORT_DIALOG.HEADER`);
    const body = this.translateService.instant(`PROGRESS_TASK.${this.progressTaskType}.ABORT_DIALOG.BODY`);
    const confirm = this.translateService.instant(`PROGRESS_TASK.${this.progressTaskType}.ABORT_DIALOG.CONFIRM`);
    const dialogConfig = new ConfirmationDialogConfiguration(header, body, ColorConstants.WARNING, confirm);
    return this.dialogOpener.showDialog('cancel-progress-task-snackbar-group', ConfirmationDialog, dialogConfig);
  }

  private getTitleConfig(progressTasks: ProgressTask<any, any>[]) {
    const { inProgress, finished, failed } = this.getUploadStats(progressTasks);

    const total = inProgress + finished;
    const progress = total > 0 ? (finished / total) * 100 : 0;

    return inProgress
      ? this.getInProgressConfiguration(inProgress, progress, failed)
      : this.getFinishedConfiguration(finished, failed, progress);
  }

  private getInProgressConfiguration(inProgress: number, progress: number, failed: number) {
    return {
      titleIcon: IconConfiguration.medium(this.getIcon()).withColor(ColorConstants.PRIMARY),
      title: `PROGRESS_TASK.${this.progressTaskType}.IN_PROGRESS.${inProgress === 1 ? 'SINGULAR' : 'PLURAL'}`,
      titleArgs: { fileCount: inProgress },
      progress,
      failed
    };
  }

  private getFinishedConfiguration(finished: number, failed: number, progress: number) {
    const iconColor = failed > 0 && finished === 0 ? ColorConstants.ERROR : ColorConstants.SUCCESS;

    return {
      titleIcon: IconConfiguration.medium(this.getIcon()).withColor(iconColor),
      title:
        failed === 0
          ? `PROGRESS_TASK.${this.progressTaskType}.DONE.${finished === 1 ? 'SINGULAR' : 'PLURAL'}`
          : finished > 0
            ? `PROGRESS_TASK.${this.progressTaskType}.DONE.PARTIAL`
            : `PROGRESS_TASK.${this.progressTaskType}.DONE.NONE`,
      titleArgs:
        failed === 0
          ? { fileCount: finished }
          : finished > 0
            ? {
                success: finished,
                total: finished + failed
              }
            : { fileCount: failed },
      progress,
      failed
    };
  }

  private getIcon(): string {
    switch (this.progressTaskType) {
      case ProgressTaskType.EXPORT_TO_CH:
        return 'export';
      case ProgressTaskType.IMPORT_FROM_CH:
        return 'import';
      case ProgressTaskType.UPLOAD:
        return 'upload-l';
      default:
        return 'upload-l';
    }
  }

  private getUploadStats(progressTasks: ProgressTask<any, any>[]): {
    finished: number;
    inProgress: number;
    failed: number;
  } {
    const initialState = {
      finished: 0,
      inProgress: 0,
      failed: 0
    };

    return progressTasks.reduce((acc, currentValue) => {
      switch (currentValue.status) {
        case ProgressTaskStatus.FINISHED:
          acc.finished++;
          break;
        case ProgressTaskStatus.QUEUED:
        case ProgressTaskStatus.IN_PROGRESS:
          acc.inProgress++;
          break;
        case ProgressTaskStatus.FAILED:
          acc.failed++;
          break;
      }
      return acc;
    }, initialState);
  }
}
