import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ItemLinkDeleteOne, ItemLinkUpsertMany, ItemLinkUpsertOne } from './item-link.actions';
import { ItemLink, ItemLinkState } from './item-link.model';

export const itemLinkAdapter: EntityAdapter<ItemLink> = createEntityAdapter<ItemLink>();

export const initialState: ItemLinkState = itemLinkAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(ItemLinkUpsertOne, (state: ItemLinkState, { itemLink }) => itemLinkAdapter.upsertOne(itemLink, state)),
  on(ItemLinkUpsertMany, (state: ItemLinkState, { itemLinks }) => itemLinkAdapter.upsertMany(itemLinks, state)),
  on(ItemLinkDeleteOne, (state: ItemLinkState, { itemLink }) => itemLinkAdapter.removeOne(itemLink.id, state))
);

export function itemLinkReducer(state: ItemLinkState = initialState, action: Action): ItemLinkState {
  return reducer(state, action);
}
