import { Injector, Type } from '@angular/core';

import { CelumDialogConfiguration, ColorConstants } from '@celum/common-components';
import { Entity } from '@celum/work/app/core/model';
import { Person } from '@celum/work/app/core/model/entities/person';
import { WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import { AutomatorSubType, Robots, RuleType } from '@celum/work/app/core/model/entities/workroom/robot.model';

import { BaseRobot } from '../robot-dialog-templates/base-robot/base-robot.component';

export class RobotDialogConfiguration<T extends Entity<any, any>> extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(public args: RobotDialogConfigurationArgs<T>) {
    super('main');
  }
}

export interface RobotDialogConfigurationArgs<T extends Entity<any, any>> {
  component?: Type<BaseRobot<T>>;
  parentInjector?: Injector;
  sourceEntity: T;
  robot?: Robots;
  workroomConfig?: WorkroomConfiguration;
  templatePeople?: Person[];
  readonly?: boolean;
  teamspaceId?: number;
  workroomId?: number;
  repositoryId?: string;
  robotSubType?: AutomatorSubType | RuleType;
}
