<search-and-select
  [elements]="items$ | async"
  [isSearchEnabled]="true"
  [showCheckbox]="false"
  [noResultsKey]="noResultsKey"
  [searchFieldLabelKey]="searchFieldLabelKey"
  [isLoading]="loading$ | async"
  [hasMore]="hasMore$ | async"
  [hasMoreKey]="hasMoreKey"
  [hasMoreKeySingular]="hasMoreKeySingular"
  [hasMoreKeyPlural]="hasMoreKeyPlural"
  [hasMoreAmount]="hasMoreAmount$ | async"
  [placeholder]="placeholder"
  (searchValueChanged)="searchEntities($event)"
  (elementSelected)="elementSelected.emit($event)">
  <ng-template let-item="element">
    <text-truncator
        [text]="item[displayedValue]"
        [updateOnAfterViewChecked]="true">
    </text-truncator>
  </ng-template>
</search-and-select>