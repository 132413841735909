export enum WorkroomWizardEvent {
  TEAMSPACE_CHANGED = 'TEAMSPACE_CHANGED',
  PEOPLE_REMOVED = 'PEOPLE_REMOVED',
  PEOPLE_ROLE_UPDATED = 'PEOPLE_ROLE_UPDATED',
  TLO_ADDED = 'TLO_ADDED',
  TLO_REMOVED = 'TLO_REMOVED',
  REPOSITORY_REMOVED = 'REPOSITORY_REMOVED',
  REPOSITORY_CHANGED = 'REPOSITORY_CHANGED',
  DUE_DATE_CHANGED = 'DUE_DATE_CHANGED'
}
