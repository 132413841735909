import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, take, takeUntil } from 'rxjs';

import { EmptyPage, EmptyPageConfig, SortDirection } from '@celum/common-components';
import { ReactiveService } from '@celum/core';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { CustomForm } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { selectCustomFormEntities } from '@celum/work/app/core/model/entities/custom-form/custom-form.selectors';
import { VirtualScrollTableService } from '@celum/work/app/shared/components/virtual-scroll-table/virtual-scroll-table.base';
import { WindowResizeService } from '@celum/work/app/shared/util';

import { CustomFormsStore } from '../../forms-overview-tab.store';

@Injectable()
export class CustomFormsVirtualScrollTableService
  extends ReactiveService
  implements VirtualScrollTableService<CustomForm>
{
  public readonly items$: Observable<CustomForm[]>;
  public readonly emptyPageConfig: EmptyPageConfig;
  public readonly isEmpty$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;
  public readonly stickyRowConfig: string[];

  public lastEmitCount: number;
  public itemSize: number;

  constructor(
    public customFormsStore: CustomFormsStore,
    private store: Store<any>
  ) {
    super();

    this.items$ = combineLatest([
      this.store.select(selectCustomFormEntities),
      this.customFormsStore.customFormIds$
    ]).pipe(map(([customForms, customFormIds]) => customFormIds.map(id => customForms[id])));

    this.emptyPageConfig = EmptyPage.noActionConfig(
      'no-forms',
      'no-forms',
      'TEAMSPACE_MANAGEMENT.FORMS.EMPTY',
      'normal',
      232
    );
    this.emptyPageConfig.iconConfiguration.iconHeight = 188;

    this.isEmpty$ = combineLatest([this.customFormsStore.loading$, this.customFormsStore.hasCustomForms$]).pipe(
      takeUntil(this.unsubscribe$),
      map(([loading, hasCustomForms]) => !loading && !hasCustomForms)
    );

    this.loading$ = this.customFormsStore.loading$;

    this.stickyRowConfig = this.generateStickyRowConfig();

    this.itemSize = 56;
  }

  public loadItems(): void {
    this.customFormsStore.changeBatchSize(this.getBatchSize());
    this.customFormsStore.loadCustomForms$({
      paging: Paging.of(0, this.getBatchSize()),
      sorting: Sorting.of('createdOn', SortDirection.DESC),
      offset: 0
    });
  }

  public onRequestNextPage(viewport: CdkVirtualScrollViewport): void {
    this.customFormsStore.paginationResult$.pipe(take(1)).subscribe(paginationResult => {
      if (viewport && (!paginationResult || paginationResult.hasBottom)) {
        const end = viewport.getRenderedRange().end;
        const total = viewport.getDataLength();

        if (end >= total && end !== this.lastEmitCount) {
          // if scrolled while loading, this would emit multiple times - so remember last end count of items and check if it changed
          this.lastEmitCount = end;
          this.loadNextBatch();
        }
      }
    });
  }

  public loadNextBatch(): void {
    this.customFormsStore.loadNextBatch$();
  }

  public getBatchSize(): number {
    return WindowResizeService.calculateBatchSize({
      elementSize: this.itemSize * window.innerWidth,
      valueModifier: { height: -115 }
    });
  }

  private generateStickyRowConfig(): string[] {
    return [
      'TEAMSPACE_MANAGEMENT.FORMS.TABLE.HEADERS.NAME',
      'TEAMSPACE_MANAGEMENT.FORMS.TABLE.HEADERS.TASK_COUNT',
      'TEAMSPACE_MANAGEMENT.FORMS.TABLE.HEADERS.CREATED_BY',
      'TEAMSPACE_MANAGEMENT.FORMS.TABLE.HEADERS.CREATION_DATE'
    ];
  }
}
