<ng-container *ngIf="headlineAndTextPipeArgs | storageBannerTranslations; let headlineAndText">
  <message-box
      type="warn"
      class="storage-limit-banner_message-box"
      [headline]="headlineAndText.headline"
      [text]="headlineAndText.text"
      [actionText]="'STORAGE.BANNER.ACTION.' + (isOwner? 'OWNER' : 'MEMBER')"
      (invokeAction)="onStorageActionInvoked()">
  </message-box>
</ng-container>
