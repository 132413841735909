import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { CelumPropertiesProvider, DataUtil, PaginationResult } from '@celum/core';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { MemberType } from '@celum/work/app/core/model/entities/member';
import { PersonType } from '@celum/work/app/core/model/entities/person';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import {
  InvitedPerson,
  Template,
  TemplateResponse,
  TemplateType
} from '@celum/work/app/core/model/entities/template/template.model';
import { TemplateTranslator } from '@celum/work/app/core/model/entities/template/template.translator';
import {
  TemplateCategory,
  TemplateCategoryType
} from '@celum/work/app/core/model/entities/template-category/template-category.model';
import { ListResult } from '@celum/work/app/core/model/list-result';
import { MetaInfo } from '@celum/work/app/core/model/meta-info.model';

import { STRONGLY_CONSISTENT_OPTION } from '../../../shared/util/api-util';

@Injectable({ providedIn: 'root' })
export class TemplateChooserService {
  private readonly templateTranslator: TemplateTranslator = new TemplateTranslator();

  constructor(
    private http: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public fetchTemplateCategories(): Observable<TemplateCategory[]> {
    const metaInfo = MetaInfo.of([TemplateCategoryType.TYPE_KEY], [TemplateCategoryType.instance().getSchema()]);
    return this.http
      .get<TemplateCategory[]>(`${CelumPropertiesProvider.properties.httpBaseAddress}/template-category`)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          const templates = entitiesResult.entities[TemplateCategoryType.TYPE_KEY];
          return DataUtil.isEmpty(templates) ? null : (templates as unknown as TemplateCategory[]);
        })
      );
  }

  public fetchTemplates(
    categoryIds: number[],
    personalIncluded = false,
    paging: Paging
  ): Observable<{ templates: Template[]; paginationResult: PaginationResult }> {
    const metaInfo = MetaInfo.of([TemplateType.TYPE_KEY], [TemplateType.instance().getSchema()]);

    return this.http
      .post<ListResult<TemplateResponse>>(`${CelumPropertiesProvider.properties.httpBaseAddress}/template/search`, {
        categoryIds,
        personalIncluded,
        paging,
        sorting: Sorting.of('categoryId', SortDirection.ASC)
      })
      .pipe(
        map((res: any) => {
          const members = res.results.map(person => person.members).reduce((acc, curr) => [...acc, ...curr], []);
          const memberMetaInfo = MetaInfo.of(
            [MemberType.TYPE_KEY, PersonType.TYPE_KEY],
            [MemberType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY] })]
          );
          this.resultConsumerService.translateAndAddToStore(members, memberMetaInfo);

          res.results = (res.results || []).map(({ template, createdOn }) => ({
            ...template,
            createdOn
          }));

          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res.results, metaInfo);
          const templates = entitiesResult.entities[TemplateType.TYPE_KEY] as Template[];

          return {
            templates,
            paginationResult: {
              ...res.paginationInformation,
              hasBottom: res.paginationInformation.elementsFollow
            }
          };
        })
      );
  }

  public create(template: Template, people: InvitedPerson[]): Observable<Template> {
    return this.http
      .post<TemplateResponse>(`${CelumPropertiesProvider.properties.httpBaseAddress}/template`, { template, people })
      .pipe(map((res: TemplateResponse) => this.handleTemplateResponseResult(res)));
  }

  public copy(
    name: string,
    description: string,
    templateId: number,
    targetCategoryId: number,
    taskLists: TaskList[]
  ): Observable<Template> {
    return this.http
      .post<TemplateResponse>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/template/copy`,
        {
          name,
          description,
          templateId,
          targetCategoryId,
          taskLists
        },
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(map((res: TemplateResponse) => this.handleTemplateResponseResult(res)));
  }

  public move(templateId: number, targetCategoryId: number): Observable<Template> {
    return this.http
      .post<TemplateResponse>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/template/move`,
        {
          templateId,
          targetCategoryId
        },
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(map((res: TemplateResponse) => this.handleTemplateResponseResult(res)));
  }

  public patch(template: Partial<Template>, people: InvitedPerson[]): Observable<Template> {
    return this.http
      .patch<TemplateResponse>(`${CelumPropertiesProvider.properties.httpBaseAddress}/template/${template.id}`, {
        ...template,
        people
      })
      .pipe(map((res: TemplateResponse) => this.handleTemplateResponseResult(res)));
  }

  public getTemplate(templateId: number): Observable<Template> {
    return this.http
      .get<TemplateResponse>(`${CelumPropertiesProvider.properties.httpBaseAddress}/template/${templateId}`)
      .pipe(map(res => this.handleTemplateResponseResult(res)));
  }

  public generateTemplateBasedOnWorkroom(workroomId: number): Observable<Template> {
    return this.http
      .get<Template>(`${CelumPropertiesProvider.properties.httpBaseAddress}/template/workroom/${workroomId}`)
      .pipe(map(res => this.templateTranslator.translateToEntity(res)));
  }

  public delete({ id }: Partial<Template>): Observable<any> {
    return this.http.delete(`${CelumPropertiesProvider.properties.httpBaseAddress}/template/${id}`);
  }

  private handleTemplateResponseResult({ template, createdOn, members }: TemplateResponse): Template {
    const res = {
      ...template,
      createdOn
    };
    const memberMetaInfo = MetaInfo.of(
      [MemberType.TYPE_KEY, PersonType.TYPE_KEY],
      [MemberType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY] })]
    );
    this.resultConsumerService.translateAndAddToStore(members, memberMetaInfo);
    return this.handleTemplateResult(res);
  }

  private handleTemplateResult(template: Template): Template {
    const templateMetaInfo = MetaInfo.of([TemplateType.TYPE_KEY], TemplateType.instance().getSchema());
    const entitiesResult = this.resultConsumerService.translateAndAddToStore(template, templateMetaInfo);
    const templates = entitiesResult.entities[TemplateType.TYPE_KEY];
    return templates[0] as Template;
  }
}
