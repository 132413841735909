import { HttpErrorResponse } from '@angular/common/http';

export enum CustomFormErrorKeyEnum {
  FORM_CONFLICTING_STATE = 'FORM_CONFLICTING_STATE',
  FORM_LIMIT_EXCEEDED = 'FORM_LIMIT_EXCEEDED'
}

export interface CustomFormHttpErrorResponse extends HttpErrorResponse {
  error: {
    data: any;
    errorKey?: CustomFormErrorKeyEnum;
  };
}
