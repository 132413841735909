import { createAction, props } from '@ngrx/store';

import { ApplicationQueryParamKey } from '@celum/work/app/core';

export const SetQueryParam = createAction(
  '[Router] Set Query Param',
  props<{ key: ApplicationQueryParamKey; value: any }>()
);

export const DeleteQueryParam = createAction('[Router] Delete Query Param', props<{ key: ApplicationQueryParamKey }>());
