import { createAction, props } from '@ngrx/store';

import { Roles } from '@celum/work/app/core/model';
import { Contributor } from '@celum/work/app/core/model/entities/contributor';
import { Workroom } from '@celum/work/app/core/model/entities/workroom';

export const WorkroomContributorsUpdateRole = createAction(
  '[WorkroomContributor] Update Role',
  props<{ workroomId: number; contributorRoles: { contributor: Contributor; role: Roles }[] }>()
);

export const WorkroomContributorsRemove = createAction(
  '[WorkroomContributor] Remove contributors',
  props<{ contributors: Contributor[]; workroomId: number; keepContent: boolean }>()
);

export const WorkroomContributorsRemoveSuccess = createAction(
  '[WorkroomContributor] Remove contributors success',
  props<{ contributors: Contributor[] }>()
);

export const WorkroomContributorsRemoveError = createAction(
  '[WorkroomContributor] Remove contributors error',
  props<{ contributors: Contributor[] }>()
);

export const WorkroomContributorExit = createAction(
  '[WorkroomContributor] Contributor exits',
  props<{ workroom: Workroom; keepContent: boolean }>()
);

export const WorkroomContributorExitSucceeded = createAction('[WorkroomContributor] Contributor exited successfully');

export const WorkroomContributorExitFailed = createAction('[WorkroomContributor] Contributor exit failed');

export const WorkroomContributorsInviteSuccessful = createAction(
  '[WorkroomContributor] Invite Successful',
  props<{ workroomId: number; contributors: Contributor[] }>()
);
