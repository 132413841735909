import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { takeUntil } from 'rxjs';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { CustomFieldDropdownOption } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';

@Component({
  selector: 'custom-field-card-chips',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatChipsModule, CelumIconModule],
  templateUrl: './custom-field-card-chips.component.html',
  styleUrls: ['./custom-field-card-chips.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldCardChipsComponent),
      multi: true
    }
  ]
})
export class CustomFieldCardChipsComponent extends ReactiveComponent implements OnInit, ControlValueAccessor {
  @HostBinding('class') public componentCls = 'custom-field-card_chip-list';

  public onChangeFn: (value: CustomFieldDropdownOption[]) => void;
  public onTouchFn: () => void;

  public cancelIcon: IconConfiguration = IconConfiguration.small('cancel-s');

  public readonly chipsControl = new FormControl<CustomFieldDropdownOption[]>(null, [Validators.required]);

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this.chipsControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(chips => this.onChangeFn(chips));
  }

  public writeValue(value: CustomFieldDropdownOption[] | null): void {
    if (value) {
      this.chipsControl.setValue(value, { emitEvent: false });
      this.cdr.detectChanges();
    }
  }

  public registerOnChange(fn: (value: CustomFieldDropdownOption[]) => void): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.chipsControl.disable({ emitEvent: false }) : this.chipsControl.enable({ emitEvent: false });
    this.cdr.detectChanges();
  }

  public removeChip(chip: CustomFieldDropdownOption): void {
    this.chipsControl.setValue(this.chipsControl.value.filter(val => val !== chip));
  }
}
