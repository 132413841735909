import { createSelector } from '@ngrx/store';

import { portalAdapter } from './portal.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = portalAdapter.getSelectors();

export const selectPortals = createSelector(selectEntitiesState, (state: EntitiesState) => state.portals);

export const selectAllPortals = createSelector(selectPortals, selectAll);
export const selectPortalEntities = createSelector(selectPortals, selectEntities);

export const selectPortalById = (id: string) => createSelector(selectPortalEntities, portals => portals[id]);
