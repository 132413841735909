import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, filter, finalize, take } from 'rxjs/operators';

import { CelumDialog } from '@celum/common-components';
import { TemplateUtil } from '@celum/work/app/shared/util/template-util';

import { NotificationService } from '../../../../../core';
import { Permission } from '../../../../../core/api/permission';
import { TemplateChooserService } from '../../../../../core/api/workroom-creator';
import { Template } from '../../../../../core/model/entities/template/template.model';
import {
  PredefinedCategoryValues,
  TemplateCategory
} from '../../../../../core/model/entities/template-category/template-category.model';
import { selectCategoriesForPermission } from '../../../../../core/model/entities/template-category/template-category.selectors';
import { WORKROOM_VALIDATION_VALUES } from '../../../../../core/model/entities/workroom';
import {
  CopyTemplateCallback,
  TemplateMaintenanceDialogConfiguration
} from '../template-maintenance-dialog-config.abstract';

@Component({
  selector: 'copy-template-dialog',
  templateUrl: './copy-template-dialog.component.html',
  styleUrls: ['./copy-template-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CopyTemplateDialogComponent implements OnInit, CelumDialog<CopyTemplateDialogConfiguration> {
  public readonly validation = WORKROOM_VALIDATION_VALUES;
  public readonly personalCategoryKey = PredefinedCategoryValues.PERSONAL;
  public color: string;
  public callback: CopyTemplateCallback;
  public template: Template;
  public copyActionForm: UntypedFormGroup;
  public categories$: Observable<TemplateCategory[]>;
  public loading$ = new Subject<boolean>();

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<CopyTemplateDialogComponent>,
    private fb: UntypedFormBuilder,
    private templateService: TemplateChooserService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  private get initialTemplateName() {
    return [
      this.translateService.instant('TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.NAME_PREFIX'),
      this.translateService.instant(this.template.name)
    ]
      .join(' - ')
      .trim();
  }

  public configure({ color, template, callback }: CopyTemplateDialogConfiguration) {
    this.color = color;
    this.template = template;
    this.callback = callback;
  }

  public ngOnInit() {
    const { description, categoryId } = this.template;

    this.copyActionForm = this.fb.group({
      name: new UntypedFormControl(this.initialTemplateName, [
        Validators.required,
        Validators.maxLength(WORKROOM_VALIDATION_VALUES.maxNameLength)
      ]),
      categoryId: new UntypedFormControl(null, [Validators.required]),
      description: new UntypedFormControl(
        categoryId === PredefinedCategoryValues.CELUM_TEMPLATE_CATEGORY_ID && description
          ? this.prepareDescriptionForCelumTemplate(description)
          : description
      )
    });

    this.categories$ = this.store.select(selectCategoriesForPermission, { permission: Permission.TEMPLATE_UPDATE });

    this.categories$
      .pipe(
        take(1),
        filter(categories => categories.length < 1)
      )
      .subscribe(() => this.copyActionForm.get('categoryId').setValue(this.personalCategoryKey));
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public copyTemplate() {
    const { categoryId: categoryIdRaw, name, description } = this.copyActionForm.value;
    const categoryId = categoryIdRaw === this.personalCategoryKey ? null : categoryIdRaw;
    TemplateUtil.updateTemplateTaskListsSort(this.template);

    this.loading$.next(true);
    this.templateService
      .copy(name, description, this.template.id, categoryId, this.template.taskLists)
      .pipe(
        catchError(() => this.handleError()),
        finalize(() => this.finalizeRequest())
      )
      .subscribe();
  }

  public trackByFn(index: number, category: TemplateCategory): number | string {
    return category ? category.id : index;
  }

  private handleError(): Observable<never> {
    this.notificationService.error('WORKROOM_WIZARD.ERROR');
    return EMPTY;
  }

  private finalizeRequest(): void {
    this.callback(this.copyActionForm.get('categoryId').value);
    this.closeDialog();
    this.loading$.next(false);
  }

  private prepareDescriptionForCelumTemplate(description: string): string {
    const translation = this.translateService.instant(description);
    return `"{\\"ops\\":[{\\"insert\\":\\"${translation}\\\\n\\"}]}"`;
  }
}

export class CopyTemplateDialogConfiguration extends TemplateMaintenanceDialogConfiguration {}
