import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AutomatorService, AutomatorServiceArgs } from './automator.service';
import { getTaskListAutomatorsOfType } from './robots-util';
import { AutomatorType, RobotActionType, RobotTriggerType } from '../../core/model/entities/workroom/robot.model';

@Injectable({ providedIn: 'root' })
export class AssignmentAutomatorService extends AutomatorService {
  public getAllTriggers(args: AutomatorServiceArgs): Observable<RobotTriggerType[]> {
    const triggers = [RobotTriggerType.TASK_MOVED];

    if (!args.currentRobotState || args.currentRobotState?.action.type !== RobotActionType.TASK_REMOVE_ASSIGNEES) {
      triggers.push(RobotTriggerType.TASK_CREATED);
    }

    return of(triggers);
  }

  public getAllActions(args: AutomatorServiceArgs): Observable<RobotActionType[]> {
    const actions = [RobotActionType.TASK_ADD_ASSIGNEES];

    if (!args.currentRobotState || args.currentRobotState?.trigger.type === RobotTriggerType.TASK_MOVED) {
      actions.push(RobotActionType.TASK_REMOVE_ASSIGNEES);
    }

    return of(actions);
  }

  public getPossibleTriggers(args?: AutomatorServiceArgs): Observable<RobotTriggerType[]> {
    const possibleTriggers = super.getPossibleTriggers(args);
    let usedTriggers = getTaskListAutomatorsOfType(
      args.workroomConfig,
      args.sourceId,
      AutomatorType.TASK_ASSIGNMENT
    ).map(({ trigger }) => trigger.type);

    if (usedTriggers.length === 1 && !!args.initialRobotState) {
      return possibleTriggers;
    }

    if (usedTriggers.length > 1 && !!args.initialRobotState) {
      usedTriggers = usedTriggers.filter(trigger => trigger !== args.currentRobotState?.trigger.type);
    }

    const isUnused = (trigger: RobotTriggerType) => !usedTriggers.includes(trigger);
    return possibleTriggers.pipe(map(triggers => triggers.filter(possibleTrigger => isUnused(possibleTrigger))));
  }

  public getPossibleActions(args?: AutomatorServiceArgs): Observable<RobotActionType[]> {
    const usedActions = getTaskListAutomatorsOfType(
      args.workroomConfig,
      args.sourceId,
      AutomatorType.TASK_ASSIGNMENT
    ).map(({ action }) => action.type);
    const allActions = super.getPossibleActions(args);

    if (usedActions.length === 1 && !!args.initialRobotState) {
      return allActions;
    }

    return this.getPossibleTriggers(args).pipe(
      switchMap(triggers =>
        allActions.pipe(
          map(actions => {
            let res = actions;

            if (
              args.currentRobotState?.trigger.type === RobotTriggerType.TASK_CREATED ||
              !triggers.includes(RobotTriggerType.TASK_MOVED)
            ) {
              res = res.filter(action => action !== RobotActionType.TASK_REMOVE_ASSIGNEES);
            }

            return res;
          })
        )
      )
    );
  }

  public getAutomatorPreselection(
    args: AutomatorServiceArgs
  ): Observable<{ triggerType: RobotTriggerType; actionType: RobotActionType }> {
    return combineLatest([
      this.getAllTriggers(args),
      this.getAllActions(args),
      this.getPossibleTriggers(args),
      this.getPossibleActions(args)
    ]).pipe(
      map(([allTriggers, allActions, possibleTriggers, possibleActions]) => {
        if (allTriggers.length === possibleTriggers.length && allActions.length === possibleActions.length) {
          return {
            triggerType: RobotTriggerType.TASK_MOVED,
            actionType: RobotActionType.TASK_ADD_ASSIGNEES
          };
        } else {
          return {
            triggerType: possibleTriggers[0],
            actionType: possibleActions[0]
          };
        }
      })
    );
  }
}
