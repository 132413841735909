import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { DataUtil, EntityType } from '@celum/core';
import { ContributorType } from '@celum/work/app/core/model/entities/contributor/contributor.model';
import { Entity } from '@celum/work/app/core/model/entity';

import { WorkroomGroupType } from '../group/workroom-group.model';
import { Workroom, WorkroomType } from '../workroom.model';

export interface WorkroomGroupItem extends Entity<number, WorkroomGroupType> {
  sort: number;
  groupId: number;
  workroomId: number;
  workroom: (store: Store<any>) => Observable<Workroom>;
}

export class WorkroomGroupItemType implements EntityType {
  public static readonly TYPE_KEY: string = 'WorkroomGroupItem';
  public static readonly GROUP_REF: string = 'groupId';
  private static _instance: WorkroomGroupItemType;

  public id = WorkroomGroupItemType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): WorkroomGroupItemType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(WorkroomType.TYPE_KEY)) {
        relationDefinitions.workroom = WorkroomType.instance().getSchema({ relationsFor: [ContributorType.TYPE_KEY] });
      }
    }
    return new schema.Entity(WorkroomGroupItemType.TYPE_KEY, relationDefinitions);
  }
}
export interface WorkroomGroupItemState extends EntityState<WorkroomGroupItem> {}
