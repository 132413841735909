import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { CelumDialog, CelumDialogConfiguration, ColorConstants } from '@celum/common-components';
import { CelumValidators } from '@celum/ng2base';

import { FolderCreate } from '../../../../../../core/model/entities/folder/folder.actions';
import { Folder } from '../../../../../../core/model/entities/folder/folder.model';
import { INVALID_CHARS } from '../../common';

@Component({
  selector: 'create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.less']
})
export class CreateFolderDialog implements OnInit, CelumDialog<CreateFolderDialogConfiguration> {
  public color: string;
  public folder: Folder;
  public createFolderActionForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<CreateFolderDialog>,
    private fb: UntypedFormBuilder,
    private store: Store<any>,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.createFolderActionForm = this.fb.group({
      name: new UntypedFormControl(this.translateService.instant('FOLDER.DIALOG.CREATE.DEFAULT_NAME'), [
        CelumValidators.required,
        CelumValidators.validCharacters(INVALID_CHARS),
        CelumValidators.maxLength(250)
      ])
    });
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public createFolder() {
    const folderToCreate = {
      name: this.createFolderActionForm.get('name').value,
      parentId: this.folder.id,
      libraryId: this.folder.libraryId
    } as Folder;

    this.store.next(FolderCreate({ folder: folderToCreate }));
    this.dialogRef.close();
  }

  public configure(configuration: CreateFolderDialogConfiguration): void {
    this.folder = configuration.folder;
    this.color = configuration.color;
  }
}

export class CreateFolderDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(public folder: Folder) {
    super('main');
  }
}
