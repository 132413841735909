import { createAction, props } from '@ngrx/store';

import { ImportIntent } from '../model/import-intent.model';

export const ImportIntentUpsertOne = createAction(
  '[ImportIntent] Upsert One',
  props<{ importIntent: ImportIntent; propertiesToUpdate?: string[] }>()
);

export const ImportIntentFetchCollectionName = createAction(
  '[ImportIntent] Fetch Collection Name',
  props<{ repositoryId: string; collectionId: number }>()
);
