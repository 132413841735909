<interactive-avatars
    [hasAddPermissions]="hasAddPermission"
    [hasRemovePermissions]="hasRemovePermission"
    [selectedPeople]="assignees"
    [startingIcon]="selectPersonIcon"
    [darkOverflowBackground]="true"
    addContributorTooltip="WORKROOM_TASK.ADD_ASSIGNEE"
    noPermissionTooltip="WORKROOM_TASK.NO_ASSIGNEE_PERMISSION"
    [avatarDecorator]="avatarDecorator$"
    [allPeople]="allPeople$ | async"
    (searchValueChanged)="searchInCurrentWorkroom($event)"
    (personSelected)="assignPerson.emit($event)"
    (personDeselected)="onPersonUnassign($event)"
    (avatarRemoved)="onPersonUnassign($event)">
  <button *ngIf="isModerator$ | async" fallback-action mat-button celum-button size="small" (click)="inviteAndAssign()">
    {{ 'SEARCH_AND_SELECT.NO_RESULTS_BTN' | translate}}
  </button>
</interactive-avatars>
