import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';

import { EntityType, PaginationResult } from '@celum/core';

import { EntityTranslator } from './entity-translator';
import { ResultNormalizer } from './result-normalizer';
import { ACTION_BY_TYPE } from './upsert-action-by-type';
import { Entity } from '../model/entity';
import { MetaInfo } from '../model/meta-info.model';

@Injectable({ providedIn: 'root' })
export class ResultConsumerService {
  private resultNormalizer = new ResultNormalizer();
  private entityTranslator = new EntityTranslator();

  constructor(private store: Store<any>) {}

  public translateAndAddToStore(json: any, metaInfo: MetaInfo): EntitiesResult {
    const result = this.resultNormalizer.normalize(json, metaInfo);
    const entitiesResult: { [key: string]: Entity<any, EntityType>[] } = {};

    result.entityTypes.forEach(type => {
      const entities = result.entities[type];

      const models = this.entityTranslator.translateEntities(entities);

      entitiesResult[type] = models;

      const action = ACTION_BY_TYPE[type];

      if (!action) {
        console.error(
          `ResultConsumer: Found no update/add action for type ${type}, did you forget to define it in 'upsert-action-by-type'?`
        );
      }

      this.store.dispatch(action(models, metaInfo.partialUpdates[type]));
    });

    const objectsResult: { [key: string]: any[] } = {};

    Object.keys(result.objects).forEach(entityType => {
      objectsResult[entityType] = result.objects[entityType];
    });

    if (result.paginationInformation) {
      return {
        entities: entitiesResult,
        combinedEntities: this.entityTranslator.translateEntities(result.combinedEntities),
        objects: objectsResult,
        paginationResult: {
          totalElementCount: result.paginationInformation.totalElements,
          hasTop: false,
          hasBottom: result.paginationInformation.elementsFollow
        }
      };
    } else {
      return {
        objects: objectsResult,
        entities: entitiesResult,
        combinedEntities: this.entityTranslator.translateEntities(result.combinedEntities)
      };
    }
  }

  public translate(json: any, metaInfo: MetaInfo): TranslateEntitiesResult {
    const result = this.resultNormalizer.normalize(json, metaInfo);
    const entitiesResult: { [key: string]: Entity<any, EntityType>[] } = {};
    const dataToStore: TranslatedDataToStore[] = [];

    result.entityTypes.forEach(type => {
      const entities = result.entities[type];

      const models = this.entityTranslator.translateEntities(entities);

      entitiesResult[type] = models;

      const action = ACTION_BY_TYPE[type];

      if (!action) {
        console.error(
          `ResultConsumer: Found no update/add action for type ${type}, did you forget to define it in 'upsert-action-by-type'?`
        );
      }

      dataToStore.push({ action, models, metaInfo, type });
    });

    const objectsResult: { [key: string]: any[] } = {};

    Object.keys(result.objects).forEach(entityType => {
      objectsResult[entityType] = result.objects[entityType];
    });

    if (result.paginationInformation) {
      return {
        dataToStore,
        entities: entitiesResult,
        combinedEntities: this.entityTranslator.translateEntities(result.combinedEntities),
        objects: objectsResult,
        paginationResult: {
          totalElementCount: result.paginationInformation.totalElements,
          hasTop: false,
          hasBottom: result.paginationInformation.elementsFollow
        }
      };
    } else {
      return {
        dataToStore: dataToStore,
        objects: objectsResult,
        entities: entitiesResult,
        combinedEntities: this.entityTranslator.translateEntities(result.combinedEntities)
      };
    }
  }

  public addToStore(dataToStore: TranslatedDataToStore[]): void {
    dataToStore.forEach(data => this.store.dispatch(data.action(data.models, data.metaInfo.partialUpdates[data.type])));
  }
}

export interface EntitiesResult {
  entities: { [key: string]: Entity<any, EntityType>[] };

  combinedEntities: Entity<any, EntityType>[];

  objects: { [key: string]: any[] };

  paginationResult?: PaginationResult;
}

export interface TranslateEntitiesResult extends EntitiesResult {
  dataToStore: TranslatedDataToStore[];
}

export interface TranslatedDataToStore {
  action: (models: Entity<any, EntityType>[], partialUpdates: string[]) => Action;
  models: Entity<any, EntityType>[];
  metaInfo: MetaInfo;
  type: string;
}
