import { createAction, props } from '@ngrx/store';

import { Task, TaskList, TaskListPropertiesToUpdate } from '@celum/work/app/core/model/entities/task';

export const TaskListCreateTaskList = createAction('[TaskList] Create Task List', props<{ taskList: TaskList }>());

export const TaskListCreateTaskListSucceeded = createAction(
  '[TaskList] Create Task List Succeeded',
  props<{ taskList: TaskList; tempId: number }>()
);

export const TaskListCreateTaskListFailed = createAction(
  '[TaskList] Create Task List Failed',
  props<{ taskList: TaskList }>()
);

export const TaskListUpdateTaskList = createAction(
  '[TaskList] Update Task List',
  props<{ taskList: TaskList; propertiesToUpdate: TaskListPropertiesToUpdate }>()
);

export const TaskListAddOwner = createAction('[TaskList] Add Owner', props<{ taskList: TaskList; personId: number }>());

export const TaskListAddOwnerSuccess = createAction(
  '[TaskList] Add Owner Succeeded',
  props<{ taskList: TaskList; personId: number }>()
);

export const TaskListRemoveOwner = createAction(
  '[TaskList] Remove Owner',
  props<{ taskList: TaskList; personId: number }>()
);

export const TaskListRemoveOwnerSuccess = createAction(
  '[TaskList] Remove Owner Succeeded',
  props<{ taskList: TaskList; personId: number }>()
);

export const TaskListDeleteTaskList = createAction(
  '[TaskList] Delete Task List',
  props<{ taskList: TaskList; tasks: Task[] }>()
);

export const TaskListDeleteTaskListSucceeded = createAction(
  '[TaskList] Delete Task List Succeeded',
  props<{ taskList: TaskList; tasks: Task[] }>()
);

export const TaskListDeleteTaskListFailed = createAction(
  '[TaskList] Delete Task List Failed',
  props<{ taskList: TaskList }>()
);

export const TaskListLoadTaskLists = createAction('[TaskList] Load Task Lists', props<{ workroomId: number }>());

export const TaskListLoadTaskListsSuccess = createAction(
  '[TaskList] Loading task lists succeeded',
  props<{ workroomId: number }>()
);

export const TaskListLoadTaskListsFailed = createAction(
  '[TaskList] Loading task lists has failed',
  props<{ workroomId: number }>()
);
