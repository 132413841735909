import { AppState } from '@celum/work/app/core';
import { Stack } from '@celum/work/app/shared/util/stack';

export enum Language {
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr'
}

export enum UiViewContext {
  SELECT_FOLDER_DIALOG = 'MOVE_DIALOG',
  TASK_OVERVIEW = 'TASK_OVERVIEW',
  TASK_DETAIL = 'TASK_DETAIL',
  FILE_OVERVIEW = 'FILE_OVERVIEW',
  FILE_DETAIL = 'FILE_DETAIL',
  COMMENT_ATTACHMENT_DIALOG = 'COMMENT_ATTACHMENT_DIALOG',
  LINK_FILE_DIALOG = 'LINK_FILE_DIALOG',
  FOLDER_TREE_SELECTION_DIALOG = 'FOLDER_TREE_SELECTION_DIALOG',
  MY_TASKS = 'MY_TASKS',
  PEOPLE_OVERVIEW = 'PEOPLE_OVERVIEW',
  DASHBOARD = 'DASHBOARD'
}

export enum LicenseTypes {
  ContentHubLicense = 'ContentHubLicense',
  WorkroomsLicense = 'WorkroomsLicense',
  ExperienceLicense = 'ExperienceLicense'
}

export interface SACCResponse {
  locale: Language;
  accountAccesses: AccountAccess[];
}

export enum AccountAccessRole {
  MEMBER = 'MEMBER',
  MANAGER = 'MANAGER'
}

export interface AccountAccess {
  accountId: string;
  accountName: string;
  licenses: Array<CHLicense | WorkroomLicense>;
  status: 'ACTIVE' | 'INACTIVE';
  ownerName: string;
  ownerEmail: string;
  role: AccountAccessRole;
  accountAccessToken: string;
  workroomPermissions: 'CreateWorkrooms' | 'ManageTemplates' | 'FinishWorkrooms';
}

export interface AccountAccessToken {
  accountId: string;
  token: string;
}

export interface License {
  active: boolean;
  type: LicenseTypes;
}

export interface CHLicense extends License {
  chRepositories: ContentHubRepository[];
}

export interface WorkroomLicense extends License {
  creationLimit: number;
}

export interface ContentHubRepository {
  url: string;
  repositoryId: string;
}

export interface UiStateState {
  tenant: string;
  language: Language;
  loggedInPersonId: number;
  accountAccesses: AccountAccess[];
  viewContext: Stack<UiViewContext>;
  viewContextLastUpdateTimestamp: { [K in UiViewContext]?: number };
}

export interface State extends AppState {
  uiState: UiStateState;
}

export function hasActiveLicense(licenses: (CHLicense | WorkroomLicense)[], type: LicenseTypes): boolean {
  return licenses.some(license => isLicenseType(license, type) && license.active);
}

export function isLicenseType<T extends CHLicense | WorkroomLicense>(
  license: CHLicense | WorkroomLicense,
  type: LicenseTypes
): license is T {
  return license.type === type;
}
