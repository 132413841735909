import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';

import { CelumDialogOpener } from '@celum/internal-components';
import { ErrorKey } from '@celum/work/app/core/error/error-key';
import { ErrorActions } from '@celum/work/app/core/error/error.actions';

@Injectable()
export class ErrorEffects {
  public onWorkroomInactive$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActions.errorKeyReceived),
        filter(({ errorKey }) => ErrorKey.WORKROOM_INACTIVE === errorKey),
        tap(() => {
          this.dialogOpener.closeAll();
          this.router.navigate(['dashboard']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<any>,
    private router: Router,
    private dialogOpener: CelumDialogOpener
  ) {}
}
