import { Inject, NgModule } from '@angular/core';
import { Store } from '@ngrx/store';

import { AbstractWebsocketListener } from '@celum/work/app/core/websocket/abstract-websocket-listener';
import { FailureClientNotificationsListener } from '@celum/work/app/pages/workroom/services/failure-client-notifications-listener.service';
import { FileSoftDeletedNotificationListener } from '@celum/work/app/pages/workroom/services/file-soft-deleted-notification-listener.service';
import { InvitationClientNotificationListener } from '@celum/work/app/pages/workroom/services/invitation-client-notification-listener.service';
import { LibrariesRenditionWebsocketListener } from '@celum/work/app/pages/workroom/services/libraries-rendition-websocket-listener.service';
import { SuccessClientNotificationsListener } from '@celum/work/app/pages/workroom/services/success-client-notifications-listener.service';
import { TaskAttachmentAddedNotificationListener } from '@celum/work/app/pages/workroom/services/task-attachment-added-notification-listener.service';
import { TaskCreationNotificationListener } from '@celum/work/app/pages/workroom/services/task-creation-notification-listener.service';
import { TaskFormNotificationListener } from '@celum/work/app/pages/workroom/services/task-form-notification-listener.service';

import { AbstractWebsocketConnectionService } from './abstract-websocket-connection.service';
import { PusherAuthService } from './pusher-auth.service';
import { AbstractWebsocketWatcher } from './watcher/abstract-websocket-watcher';
import { PusherWebsocketWatcher } from './watcher/pusher-websocket-watcher';
import { WebsocketPusherConnectionService } from './websocket-pusher-connection.service';

@NgModule({
  providers: [
    {
      provide: AbstractWebsocketListener,
      useClass: LibrariesRenditionWebsocketListener,
      multi: true
    },
    {
      provide: AbstractWebsocketWatcher,
      useFactory: websocketWatcherFactory,
      multi: false
    },
    {
      provide: AbstractWebsocketConnectionService,
      useFactory: websocketConnectionFactory,
      multi: false,
      deps: [Store, PusherAuthService]
    },
    FailureClientNotificationsListener,
    SuccessClientNotificationsListener
  ]
})
export class WebsocketModule {
  constructor(
    @Inject(AbstractWebsocketListener) websocketListeners: AbstractWebsocketListener[],
    public failureClientNotificationsListener: FailureClientNotificationsListener,
    public successClientNotificationsListener: SuccessClientNotificationsListener,
    public taskCreationNotificationListener: TaskCreationNotificationListener,
    public invitationClientNotificationListener: InvitationClientNotificationListener,
    public fileSoftDeletedNotificationListener: FileSoftDeletedNotificationListener,
    public taskAttachmentAddedNotificationListener: TaskAttachmentAddedNotificationListener,
    public taskFormNotificationListener: TaskFormNotificationListener
  ) {
    // intentionally empty - injection of websocketListeners is necessary to create instances
  }
}

export function websocketWatcherFactory() {
  return new PusherWebsocketWatcher();
}

export function websocketConnectionFactory(store: Store, pusherAuthService: PusherAuthService) {
  return new WebsocketPusherConnectionService(store, pusherAuthService);
}
