import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FeatureType, WorkroomConfiguration } from '../../../core/model/entities/workroom';
import { selectHasCurrentWorkroomFeature } from '../../../pages/workroom/store/workroom-wrapper.selectors';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  constructor(private store: Store<any>) {}

  public hasFeatureInCurrentWorkroom(feature: FeatureType): Observable<boolean> {
    return this.store.select(selectHasCurrentWorkroomFeature(feature));
  }

  public hasFeatureInWorkroomConfig(config: WorkroomConfiguration, featureType: FeatureType): boolean {
    return !!config?.features?.find(feature => feature.name === featureType);
  }
}
