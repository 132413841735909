import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs';

import { ItemLinkLoadItemLinksByType } from '@celum/work/app/core/api/item-link/item-link.actions';
import { selectTaskById } from '@celum/work/app/core/model/entities/task';
import { UserSettingsService } from '@celum/work/app/core/user-settings.service';
import { getDisplayedItemsCount } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/utils/get-displayed-items';
import { WorkroomConstants } from '@celum/work/app/shared/util';

import { ItemLinkChangeDisplayedItemLinksCount, ItemLinkSetItemLinksPanelExpandedState } from './item-link.actions';
import { ITEM_LINK_BATCH_SIZE } from './item-link.reducer';

@Injectable()
export class ItemLinkEffects {
  public changeLoadingSubtasksCountOnSubtasksLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemLinkLoadItemLinksByType),
      concatLatestFrom(({ itemLinkRelationType, sourceId }) => this.store.select(selectTaskById(sourceId))),
      map(([{ itemLinkRelationType, offset }, task]) =>
        ItemLinkChangeDisplayedItemLinksCount({
          count: getDisplayedItemsCount(
            task?.taskLinkInformation?.numberOfLinksPerType[itemLinkRelationType] ?? 0,
            offset,
            ITEM_LINK_BATCH_SIZE
          ),
          itemLinkRelationType
        })
      )
    )
  );

  public setTaskLinksPanelExpandedState$s = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ItemLinkSetItemLinksPanelExpandedState),
        tap(({ expanded }) =>
          this.userSettingsService.setApplicationSettings(
            WorkroomConstants.LOCAL_STORAGE_IS_LINKED_TASKS_SECTION_OPENED,
            expanded
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private userSettingsService: UserSettingsService
  ) {}
}
