<content-hub-menu-item 
        *ngIf="file.importId"
        [icon]="chImportAsNewVersionIcon"
        [title]="'CONTENT_HUB.CONTEXT_MENU.IMPORT.FILE_VERSION.COPIED'"
        [disabled]="!isEnabledImportCurrentVersion"
        [dataOperationId]="'import-as-new-version'"
        (click)="importCurrentVersion()">
</content-hub-menu-item>
<content-hub-menu-item 
        [icon]="chImportAsNewVersionFromAnotherAssetIcon"
        [title]="'CONTENT_HUB.CONTEXT_MENU.IMPORT.FILE_VERSION.NEW'"
        [dataOperationId]="'import-new-version-from-another-asset'"
        (click)="importDifferentAssetAsCurrentVersion()">
</content-hub-menu-item>
