import { isEqual } from 'lodash';

import { ImportedFolderData } from './watch-collections-automator.component';

export function selectedFoldersChanged(
  initiallySelectedFolders: ImportedFolderData[],
  selectedFolders: ImportedFolderData[]
): boolean {
  return !isEqual(selectedFolders.sort(sortCompareFn), initiallySelectedFolders.sort(sortCompareFn));
}

export const sortCompareFn = (a: ImportedFolderData, b: ImportedFolderData) => a.folderName.localeCompare(b.folderName);

export function trackCollectionFn(_: number, item: ImportedFolderData): string {
  return item.folderId;
}
