import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { DataUtil } from '@celum/core';

import { MembersDeleteOne, MembersUpsertMany, MembersUpsertOne } from './member.actions';
import { Member, MemberState } from './member.model';
import { EntityUtil } from '../../entity-util';

export const memberAdapter: EntityAdapter<Member> = createEntityAdapter<Member>();

export const initialState: MemberState = memberAdapter.getInitialState();

const properties = ['status', 'invitationStatus'];

const reducer = createReducer(
  initialState,
  on(MembersUpsertOne, (state: MemberState, { member }) => {
    const members = EntityUtil.changedEntities(properties, [member], state.entities);
    if (!DataUtil.isEmpty(members)) {
      return memberAdapter.upsertOne(members[0], state);
    } else {
      return state;
    }
  }),

  on(MembersUpsertMany, (state: MemberState, { members }) => {
    const changedMembers = EntityUtil.changedEntities(properties, members, state.entities);
    return memberAdapter.upsertMany(changedMembers, state);
  }),

  on(MembersDeleteOne, (state: MemberState, { id }) => {
    return memberAdapter.removeOne(id, state);
  })
);

export function memberReducer(state: MemberState = initialState, action: Action): MemberState {
  return reducer(state, action);
}
