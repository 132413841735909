import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable, of } from 'rxjs';

import { DataUtil, EntityType, UUIDGenerator } from '@celum/core';
import { Color } from '@celum/work/app/core/model';
import { Person, PersonType } from '@celum/work/app/core/model/entities/person';
import { RequireAtLeastOne } from '@celum/work/app/core/model/requires-at-least-one';
import { ColorService } from '@celum/work/app/shared/util/color.service';
import { MAX_SORT } from '@celum/work/app/shared/util/sort.service';

import { Entity } from '../../entity';

export interface TaskList extends Entity<number, TaskListType> {
  name: string;
  dueDate: number;
  color: Color;
  workroomId: number;
  sort: number;
  ownerIds: number[];
  isPlaceholder?: boolean;

  /**
   * Returns the {@link Person} entities for the owners of this task list.
   * @param store the store
   */
  owners: (store: Store<any>) => Observable<Person[]>;
}

export function getTaskListPlaceholder(previousColor: Color): TaskList {
  return {
    id: UUIDGenerator.generateId() as any,
    color: ColorService.getTaskListColor(previousColor),
    entityType: TaskListType.instance(),
    name: '',
    sort: MAX_SORT,
    dueDate: null,
    workroomId: null,
    ownerIds: [],
    owners: () => of([]),
    isPlaceholder: true
  };
}

export class TaskListType implements EntityType {
  public static readonly TYPE_KEY: string = 'TaskList';

  private static _instance: TaskListType;

  public id = TaskListType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): TaskListType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(PersonType.TYPE_KEY)) {
        relationDefinitions.owners = [PersonType.instance().getSchema({})];
      }
    }

    return new schema.Entity(TaskListType.TYPE_KEY, relationDefinitions);
  }
}

export interface TaskListState extends EntityState<TaskList> {}

export type TaskListPropertiesToUpdate = RequireAtLeastOne<TaskList, 'name' | 'sort' | 'dueDate' | 'color'>;
