<interactive-avatars
    [hasAddPermissions]="canAddTaskListOwners"
    [hasRemovePermissions]="canRemoveTaskListOwners"
    [selectedPeople]="owners"
    [maxNumberOfAvatars]="maxDisplayedOwners"
    [startingIcon]="iconConfig"
    [areTaskListOwners]="true"
    [noPermissionColor]="buttonColor"
    [darkOverflowBackground]="darkOverflowBackground"
    addContributorTooltip="TASK_LIST_OWNERS.ADD_TOOLTIP"
    noPermissionTooltip="TASK_LIST_OWNERS.NO_PERMISSION_TOOLTIP"
    [allPeople]="filteredPeople"
    [avatarDecorator]="avatarDecorator"
    (searchValueChanged)="searchedValueChanged($event)"
    (personSelected)="addOwner.emit($event)"
    (personDeselected)="removeOwner.emit($event)"
    (avatarRemoved)='removeOwner.emit($event)'>
  <button *ngIf="canInviteAndAssign" fallback-action mat-button celum-button size="small" (click)="inviteAndAssign()">
    {{ 'SEARCH_AND_SELECT.NO_RESULTS_BTN' | translate}}
  </button>
</interactive-avatars>
