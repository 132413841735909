export enum RobotContext {
  TASK_LIST = 'TASK_LIST',
  CONTENT_ITEM = 'CONTENT_ITEM'
}

export interface Robot<T extends RobotTypes> {
  type: T;
  sourceId?: number;
  sourceContext: RobotContext;
}

export interface RobotTrigger {
  type: RobotTriggerType;
}

export enum RobotTriggerType {
  WORKROOM_FINISHED = 'WORKROOM_FINISHED',
  TASK_CREATED = 'TASK_CREATED',
  TASK_MOVED = 'TASK_MOVED',
  TASK_ATTACHMENT_ADDED = 'TASK_ATTACHMENT_ADDED',
  CONTENT_HUB_ASSET_ADDED = 'CONTENT_HUB_ASSET_ADDED',
  FILE_CREATED = 'FILE_CREATED'
}

export interface Automator extends Robot<AutomatorType> {
  trigger: RobotTrigger;
  action: RobotAction;
  subType?: AutomatorSubType;
}

export interface Rule extends Robot<RuleType> {}

export enum RuleType {
  TASK_CREATION_RESTRICTION = 'TASK_CREATION_RESTRICTION',
  TASK_MANDATORY_ASSIGNMENT = 'TASK_MANDATORY_ASSIGNMENT',
  TASK_EDITING_RESTRICTION = 'TASK_EDITING_RESTRICTION',
  TASK_MOVEMENT_RESTRICTION = 'TASK_MOVEMENT_RESTRICTION'
}

export enum AutomatorType {
  CONTENT_HUB = 'CONTENT_HUB',
  TASK_ASSIGNMENT = 'TASK_ASSIGNMENT',
  TASK_DUE_DATE = 'TASK_DUE_DATE',
  TASK_CREATION = 'TASK_CREATION'
}

export enum AutomatorSubType {
  CONTENT_HUB_ASSETS = 'CONTENT_HUB_ASSETS',
  WORKROOMS_FILES = 'WORKROOMS_FILES',
  AUTOMATIC_TASK_CREATION = 'AUTOMATIC_TASK_CREATION'
}

export enum RobotActionType {
  CONTENT_HUB_EXPORT_AS_NEW_ASSET = 'CONTENT_HUB_EXPORT_AS_NEW_ASSET',
  CONTENT_HUB_EXPORT_TO_IMPORTED_ASSET = 'CONTENT_HUB_EXPORT_TO_IMPORTED_ASSET',
  CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION = 'CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION',
  CONTENT_HUB_IMPORT_ASSET = 'CONTENT_HUB_IMPORT_ASSET',
  CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION = 'CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION',
  TASK_ADD_ASSIGNEES = 'TASK_ADD_ASSIGNEES',
  TASK_REMOVE_ASSIGNEES = 'TASK_REMOVE_ASSIGNEES',
  TASK_SET_DUE_DATE = 'TASK_SET_DUE_DATE',
  TASK_SET_TIME_PERIOD_DUE_DATE = 'TASK_SET_TIME_PERIOD_DUE_DATE',
  TASK_REMOVE_DUE_DATE = 'TASK_REMOVE_DUE_DATE',
  TASK_SET_TASKLIST_DUE_DATE = 'TASK_SET_TASKLIST_DUE_DATE',
  CREATE_TASK_BASED_ON_FILE = 'CREATE_TASK_BASED_ON_FILE'
}

export interface ContentHubAction extends RobotAction {
  taskListId: number;
  targetCollectionId: number;
  targetCollectionName: string;
  creatorPersonOid?: number;
  canAccess?: boolean;
}

export interface TaskAddAssigneesAction extends RobotAction {
  personIds: number[];
  allTaskListOwners: boolean;
}

export interface DueDateAction extends RobotAction {
  dueDate?: number;
  daysCount?: number;
}

export interface CreateTaskBasedOnFileAction extends RobotAction {
  taskListId: number;
}

export interface RobotAction {
  type: RobotActionType;
}

export interface TaskAssignmentOnMovementRule extends Rule {
  trigger: TaskTrigger;
}

export interface TaskTrigger extends RobotTrigger {
  taskListId: number;
}

export interface ContentHubAssetAddedTrigger extends RobotTrigger {
  collectionFolderPairs: CollectionFolderPair[];
}

export interface TaskCreationFileCreatedTrigger extends RobotTrigger {
  folderIds: string[];
}

export type Robots = Rule | Automator;
export type RobotTypes = RuleType | AutomatorType;

export function isAutomator(robot: Robots): robot is Automator {
  return 'action' in robot && 'trigger' in robot;
}

export enum ContentHubAutomatorTypes {
  CONTENT_HUB_ASSETS = 'CONTENT_HUB_ASSETS',
  WORKROOM_FILES = 'WORKROOM_FILES',
  STANDALONE_GENERAL_ROBOT = 'STANDALONE_GENERAL_ROBOT'
}

export interface CollectionFolderPair {
  collectionId: number;
  folderId: string;
}

export enum ExistingAssigneesStrategy {
  KEEP = 'KEEP',
  REMOVE = 'REMOVE'
}

export interface RobotWithSubsequentAction extends RobotAction {
  existingAssigneesStrategy?: ExistingAssigneesStrategy;
}
