import { ContentHubAutomatorTypes, RobotActionType } from './../../core/model/entities/workroom/robot.model';
import { ContentHub610XStrategy } from './content-hub-6-10-x-strategy';
import { ContentHubStrategy } from './content-hub-strategy';

export class ContentHub612XStrategy extends ContentHubStrategy {
  public defaultStrategy(): ContentHubStrategy {
    return new ContentHub610XStrategy();
  }

  public buildNumber(): string {
    return '6.12.0';
  }

  public getSupportedContentHubAction(): { [key: string]: RobotActionType[] } {
    const previousSupportedAction = this.defaultStrategy().getSupportedContentHubAction();

    previousSupportedAction[ContentHubAutomatorTypes.CONTENT_HUB_ASSETS].unshift(
      RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION,
      RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION
    );

    previousSupportedAction[ContentHubAutomatorTypes.STANDALONE_GENERAL_ROBOT] = [
      RobotActionType.CONTENT_HUB_IMPORT_ASSET
    ];

    return previousSupportedAction;
  }

  public supportsImportCollection(): boolean {
    return true;
  }
}
