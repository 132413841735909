import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { DataUtil, EntityType } from '@celum/core';
import { Roles } from '@celum/work/app/core/model';

import { Entity } from '../../entity';
import { MemberType } from '../member/member.model';
import { PersonType } from '../person/person.model';
import { WorkroomType } from '../workroom/workroom.model';

export interface Contributor extends Entity<string, ContributorType> {
  id: string;
  workroomId: number;
  personId: number;
  roles: Roles[];
  creationDate: number;
}

export class ContributorType implements EntityType {
  public static readonly TYPE_KEY: string = 'Contributor';
  public static readonly WORKROOM_REF: string = 'workroomId';
  public static readonly PERSON_REF: string = 'personId';

  private static _instance: ContributorType;

  public id = ContributorType.TYPE_KEY;
  public inheritsFrom: Set<EntityType>;

  public static instance(): ContributorType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(MemberType.TYPE_KEY)) {
        relationDefinitions.membership = MemberType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY] });
      }
      if (relations.relationsFor.includes(PersonType.TYPE_KEY)) {
        relationDefinitions.person = PersonType.instance().getSchema({});
      }
      if (relations.relationsFor.includes(WorkroomType.TYPE_KEY)) {
        relationDefinitions.workroom = WorkroomType.instance().getSchema({});
      }
    }

    return new schema.Entity(ContributorType.TYPE_KEY, relationDefinitions, {
      idAttribute: value => `${value.workroomId}_${value.personId}`
    });
  }
}

export interface ContributorState extends EntityState<Contributor> {}

export interface InviteContributor {
  invitationEmails: InvitationEmail[];
}

export interface InvitationEmail {
  email: string;
  message?: string;
}
