import { createSelector } from '@ngrx/store';

import { Permission } from '@celum/work/app/core/api/permission';
import { Roles } from '@celum/work/app/core/model';
import {
  selectLoggedInPersonIsTaskAssignee,
  selectLoggedInPersonIsTaskListOwner,
  selectTaskHasTaskEditingRestrictionRobot,
  selectTaskListHasTaskListOwners
} from '@celum/work/app/core/model/entities/task';
import {
  selectHasPermissionForCurrentWorkroom,
  selectLoggedInPersonHasRoleForCurrentWorkroom
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';

// used for similar task permission checks like Permission.TASK_UPDATE, Permission.TASK_ASSIGNEE_ADD etc.
export const selectLoggedInPersonHasTaskPermission = (permission: Permission) =>
  createSelector(
    selectHasPermissionForCurrentWorkroom(permission),
    selectLoggedInPersonIsTaskListOwner,
    selectLoggedInPersonHasRoleForCurrentWorkroom(Roles.VISITOR),
    selectTaskHasTaskEditingRestrictionRobot,
    selectTaskListHasTaskListOwners,
    (
      hasRequiredPermissionForWorkroom,
      isTaskListOwner,
      isVisitor,
      taskHasTaskEditingRestrictionRobot,
      taskListHasTaskListOwners
    ) => {
      if (!hasRequiredPermissionForWorkroom) {
        return false;
      }
      if (isVisitor) {
        return false;
      }
      if (taskHasTaskEditingRestrictionRobot) {
        return !!(!taskListHasTaskListOwners || isTaskListOwner);
      }

      return true;
    }
  );

export const selectCanMoveTask = createSelector(
  selectHasPermissionForCurrentWorkroom(Permission.TASK_MOVE),
  selectLoggedInPersonIsTaskListOwner,
  selectLoggedInPersonHasRoleForCurrentWorkroom(Roles.VISITOR),
  selectLoggedInPersonIsTaskAssignee,
  selectTaskHasTaskEditingRestrictionRobot,
  selectTaskListHasTaskListOwners,
  (
    hasMoveTaskPermissionForWorkroom,
    isTaskListOwner,
    isVisitor,
    isTaskAssignee,
    taskHasTaskEditingRestrictionRobot,
    taskListHasTaskListOwners
  ) => {
    if (!hasMoveTaskPermissionForWorkroom) {
      return false;
    }
    if (isTaskListOwner) {
      return true;
    } else {
      if (isTaskAssignee && !taskHasTaskEditingRestrictionRobot) {
        return true;
      }
      if (!isVisitor && !taskHasTaskEditingRestrictionRobot) {
        return true;
      }

      return taskHasTaskEditingRestrictionRobot && !taskListHasTaskListOwners;
    }
  }
);

export const canAddFileToTask = createSelector(
  selectHasPermissionForCurrentWorkroom(Permission.TASK_ATTACHMENT_ADD),
  selectHasPermissionForCurrentWorkroom(Permission.UPLOAD_AND_ATTACH),
  selectLoggedInPersonIsTaskListOwner,
  selectTaskHasTaskEditingRestrictionRobot,
  selectTaskListHasTaskListOwners,
  (
    hasAddAttachmentToTaskPermissionForWorkroom,
    hasUploadAndAttachPermissionForWorkroom,
    isTaskListOwner,
    taskHasTaskEditingRestrictionRobot,
    taskListHasTaskListOwners
  ) => {
    if (!hasAddAttachmentToTaskPermissionForWorkroom && !hasUploadAndAttachPermissionForWorkroom) {
      return false;
    }
    if (taskHasTaskEditingRestrictionRobot) {
      return !!(!taskListHasTaskListOwners || isTaskListOwner);
    }

    return true;
  }
);
