import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QuillEditorComponent } from 'ngx-quill';
import { ContentChange } from 'ngx-quill/lib/quill-editor.component';
import Quill from 'quill';
import MagicUrl from 'quill-magic-url';
import QuillMention from 'quill-mention';
import { filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { QuillImageBlot, QuillImageRenderer } from '@celum/quill-plugins';
import { CommentCreateComment } from '@celum/work/app/core/api/comment/comment.actions';
import { CommentService } from '@celum/work/app/core/api/comment/comment.service';
import {
  CommentAddMarker,
  CommentRemoveMarker,
  CommentStartMarker
} from '@celum/work/app/core/model/entities/comment/comment.actions';
import { ContentItemStatus } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { File, RenditionTypes } from '@celum/work/app/core/model/entities/file/file.model';
import {
  selectCurrentWorkroom,
  selectCurrentWorkroomId
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import {
  InsertQuillImagePlaceholderEvent,
  QuillEvents,
  QuillImagesLoadedEvent
} from '@celum/work/app/shared/components/celum-quill/model/quill.events';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';

import { CelumQuillService } from './celum-quill.service';
import { formatsAll } from './formats';
import { QuillImageDropAndPasteExtended } from './plugins/quill-image-drop-and-paste-extended';
import { Task } from '../../../core/model/entities/task';
import { AvatarDecoratorFn, AvatarUtil, DownloadUrlUtil } from '../../util';

QuillMention.prototype.containerRightIsNotVisible = function (leftPos, containerPos) {
  if (this.options.fixMentionsToQuill) {
    return false;
  }
  const rightPos = leftPos + this.mentionContainer.offsetWidth + containerPos.left;
  const browserWidth = window.pageXOffset + containerPos.right;
  return rightPos > browserWidth;
};

type FieldType = 'workroom-description' | 'task-description' | 'comment' | 'other';

@Component({
  selector: 'celum-quill-editor',
  templateUrl: './celum-quill.component.html',
  styleUrls: ['./celum-quill.component.less'],
  providers: [CelumQuillService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CelumQuillComponent extends ReactiveComponent implements OnChanges, OnInit {
  @Input() public readonly: boolean;
  @Input() public quillMaxLength: number;
  @Input() public placeholder: string;
  @Input() public hostClass: string;
  @Input() public isVXIconsVisible: boolean;
  @Input() public jsonString: string;
  @Input() public type: FieldType;
  @Input() public displayShowMore: boolean;
  @Input() public toolbarVisible: boolean;
  @Input() public getQuillImagesFromServer: boolean;
  @Input() public targetEntity?: File | Task;
  @Input() public withMarkers = false;
  @Input() public showAll = true;
  @Input() public isEditMode = false;
  @Input() public canUpload = true;
  @Input() public canMention = true;

  @Output() public readonly jsonStringChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public readonly onBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly onFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly onLengthChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() public readonly onVClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() public readonly onXClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChild(QuillEditorComponent) public quillEditorComponent: QuillEditorComponent;
  @ViewChild('personTemplate', { static: true }) public personTemplate: TemplateRef<any>;
  @ViewChild('markerMenuContent', { read: ElementRef }) public markerMenuContent: ElementRef;

  public isMarkerStarted = false;
  public uncommentedMarkersCount = 0;

  public readonly attachFileIcon = IconConfiguration.small('attach-file').withColor(ColorConstants.BLUE_GRAY_900);

  public editorValueObject: any = [];
  public editorReadonlyValueObject: any = [];

  public isFocused = false;
  public currentQuillLength: number;
  public timeout: number;

  public quillConfig: any;

  public readonly vIcon = IconConfiguration.medium('check-m').withColor(ColorConstants.BLUE_GRAY_600);
  public readonly xIcon = IconConfiguration.medium('cancel-m').withColor(ColorConstants.BLUE_GRAY_600);

  public formats: string[];

  public avatarDecorator$: AvatarDecoratorFn;

  constructor(
    private elementRef: ElementRef,
    private store: Store<any>,
    private actions$: Actions,
    private eventBus: ApplicationEventBus,
    private commentService: CommentService,
    private cdRef: ChangeDetectorRef,
    private avatarUtil: AvatarUtil,
    @Self() private celumQuillService: CelumQuillService
  ) {
    super();
  }

  public static checkOrCutLengthToMeetLimit(quill: any, limit: number): string {
    if (!(quill && limit && quill.getLength() > limit)) {
      return null;
    }
    quill.deleteText(limit, quill.getLength());
    return JSON.stringify(quill.getContents());
  }

  @HostBinding('class')
  public get hostCls(): string {
    return `celum-quill-editor${this.hostClass ? ' ' + this.hostClass : ''}`;
  }

  @Input()
  public set focusEditor(shouldFocus: boolean) {
    if (shouldFocus) {
      this.focusInput();
    }
  }

  @HostListener('click', ['$event'])
  public onClick(event: PointerEvent) {
    event.stopPropagation();

    const target = event.target as HTMLElement;

    if (this.elementRef?.nativeElement) {
      // on hyperlink click it should not enter edit mode (it should go to URL)
      if (
        !target.getAttribute('href') &&
        (this.elementRef.nativeElement.contains(target) ||
          this.markerMenuContent?.nativeElement?.contains(target) ||
          (target.tagName.toLowerCase() === 'canvas' && this.isMarkerStarted))
      ) {
        this.handleFocus();
      }
    }
  }

  // check if user clicks outside the quill editor, if so, emit blur event for these types of editors
  @HostListener('document:pointerdown', ['$event'])
  public click(event: PointerEvent): void {
    const eventPathTagNames = event.composedPath().map(element => (element as HTMLElement).tagName?.toLowerCase());
    if (this.type === 'task-description' || this.type === 'workroom-description') {
      // search-and-select-person needs a separate check because it will never be contained,
      // clicking a mention option will just blur without it
      if (
        !this.elementRef.nativeElement.contains(event.target) &&
        !eventPathTagNames.find(tagName => tagName === 'search-and-select-person')
      ) {
        this.handleBlur();
        this.handleVClicked(event);
      }
    }
  }

  public ngOnInit(): void {
    this.avatarDecorator$ = this.avatarUtil.getAvatarDecoratorForCurrentWorkroom();

    this.quillConfig = this.celumQuillService.getQuillConfig({
      personTemplate: this.personTemplate,
      canUpload: this.canUpload,
      canMention: this.canMention,
      targetEntityForCommentBasedFiles: this.targetEntity,
      unsubscribe$: this.unsubscribe$
    });

    Quill.register('modules/magicUrl', MagicUrl);
    Quill.register('modules/imageDropAndPaste', QuillImageDropAndPasteExtended);
    Quill.register('modules/quillImageRenderer', QuillImageRenderer);

    if (!this.canUpload) {
      this.formats = formatsAll.filter(item => !['image', 'video', 'quill-image'].includes(item));
    }

    if (!this.withMarkers) {
      return;
    }

    this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(CommentAddMarker)).subscribe(() => {
      this.uncommentedMarkersCount++;
      this.focusInput();
      this.isMarkerStarted = false;
      this.cdRef.detectChanges();
    });

    this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(CommentRemoveMarker)).subscribe(() => {
      this.uncommentedMarkersCount--;
      this.cdRef.detectChanges();
    });

    this.actions$
      .pipe(takeUntil(this.unsubscribe$), ofType(CommentStartMarker))
      .subscribe(() => (this.isMarkerStarted = true));

    this.actions$
      .pipe(takeUntil(this.unsubscribe$), ofType(CommentCreateComment))
      .subscribe(() => (this.uncommentedMarkersCount = 0));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.jsonString && changes.jsonString.currentValue !== JSON.stringify(this.editorValueObject)) {
      try {
        this.editorValueObject = JSON.parse(changes.jsonString.currentValue) || [];
      } catch (e) {
        this.editorValueObject = [];
      }
    }

    if (changes.readonly?.currentValue) {
      // saving readonly value
      this.editorReadonlyValueObject = this.editorValueObject;
    }
    if (changes.quillMaxLength) {
      // saving readonly value
      if (this.editorReadonlyValueObject && this.editorReadonlyValueObject.length > 0) {
        this.editorValueObject = this.editorReadonlyValueObject;
      }
      const limit: number = changes.quillMaxLength.currentValue;
      const quill: any = this.quillEditorComponent?.quillEditor;
      this.editorValueObject = CelumQuillComponent.checkOrCutLengthToMeetLimit(quill, limit) || this.editorValueObject;
    }
  }

  public onEditorCreated() {
    this.emitLengthChange();

    if (this.getQuillImagesFromServer) {
      const fileIds = this.getQuillImagePlugin().getImagesFileIds();

      if (fileIds.length !== 0) {
        this.store
          .select(selectCurrentWorkroomId)
          .pipe(
            take(1),
            switchMap(workroomId => this.commentService.getAttachments(workroomId, fileIds))
          )
          .subscribe(attachments => {
            this.getQuillImagePlugin().removeBrokenImages(
              attachments
                .filter(
                  attachment => attachment.status === ContentItemStatus.HARD_DELETED && !attachment.activeVersionId
                )
                .map(attachment => attachment.id)
            );
            this.getQuillImagePlugin().reloadImages();
          });
      }
    }

    this.eventBus
      .observeEvents<QuillImagesLoadedEvent>(this.unsubscribe$, QuillEvents.QUILL_IMAGE_LOADED)
      .pipe(
        map(event => event.data),
        filter(({ rendition }) => rendition.type === RenditionTypes.SMALL),
        withLatestFrom(this.store.select(selectCurrentWorkroom))
      )
      .subscribe(([{ fileId, rendition }, currentWorkroom]) => {
        const imgUrl = DownloadUrlUtil.resolveDownloadUrl(rendition.downloadUrl, currentWorkroom);
        this.getQuillImagePlugin().handleImageLoaded(fileId.toString(), imgUrl);
      });

    this.eventBus
      .observeEvents<InsertQuillImagePlaceholderEvent>(this.unsubscribe$, QuillEvents.INSERT_QUILL_IMAGE_PLACEHOLDER)
      .pipe(
        filter(event => event.data.quillImageHandlerUid === this.getQuillImagePlugin().options.quillImageHandlerUid)
      )
      .subscribe(event => this.getQuillImagePlugin().insertImagePlaceholder(event.data.fileId));
  }

  public onContentChanged(event: ContentChange): void {
    const quill = event.editor;

    if (event.content.ops.find(op => !!op.attributes?.color)) {
      quill.formatText(0, quill.getLength(), 'color', false);
    }

    if (!this.readonly || !this.currentQuillLength) {
      this.emitLengthChange();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.editorValueObject =
      CelumQuillComponent.checkOrCutLengthToMeetLimit(quill, this.quillMaxLength) || this.editorValueObject;

    if (!this.readonly) {
      if (this.currentQuillLength) {
        const jsonString = JSON.stringify(this.editorValueObject);
        this.jsonStringChange.emit(jsonString);
      } else {
        this.timeout = window.setTimeout(() => {
          this.jsonStringChange.emit(null);
        });
      }
    }
  }

  public uploadImages(): void {
    this.celumQuillService.uploadImages(this.getQuillImagePlugin().options.quillImageHandlerUid, this.targetEntity);
  }

  public handleFocus(): void {
    if (!this.isFocused) {
      this.isFocused = true;
      this.onFocus.emit();
    }
  }

  public handleBlur(): void {
    const quill = this.quillEditorComponent.quillEditor as any;
    quill.scrollingContainer.scroll(0, 0);

    if (this.isFocused) {
      this.quillEditorComponent.editorElem.style.minHeight = '';
      this.isFocused = false;
      this.onBlur.emit();
    }
  }

  public focusInput(): void {
    if (this.quillEditorComponent?.quillEditor) {
      const quill = this.quillEditorComponent.quillEditor;
      quill.focus();
      quill.setSelection(quill.getLength(), 0);
      this.isFocused = true;
      this.onFocus.emit();
    }
  }

  public getQuillLength(): number {
    const quill = this.quillEditorComponent?.quillEditor;
    if (!quill) {
      return 0;
    }

    const currentContents = quill.getContents();
    const hasMention = !!currentContents.ops.find(
      obj => typeof obj.insert === `object` && obj.insert.hasOwnProperty(`mention`)
    );
    const hasScreenshots = !!currentContents.ops.find(
      obj => typeof obj.insert === `object` && obj.insert.hasOwnProperty(QuillImageBlot.className)
    );
    const hasList = !!currentContents.ops.find(obj => obj.attributes?.list);
    const textLength: number = (this.quillEditorComponent?.quillEditor?.getText() || '').trim().length || 0;

    if (!hasMention && !hasList && !textLength && !hasScreenshots) {
      return 0;
    }

    return this.quillEditorComponent.quillEditor.getLength();
  }

  public handleVClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.onVClicked.emit(event);
  }

  public handleXClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.onXClicked.emit(event);
  }

  public shouldHideToolbar(): boolean {
    return !this.isFocused && !this.toolbarVisible;
  }

  public getHtmlContent(): string {
    return this.quillEditorComponent?.quillEditor?.root?.innerHTML;
  }

  private emitLengthChange() {
    this.currentQuillLength = this.getQuillLength();
    this.onLengthChanged.emit(this.currentQuillLength);
  }

  private getQuillImagePlugin(): QuillImageRenderer {
    return this.quillEditorComponent.quillEditor.getModule('quillImageRenderer') as QuillImageRenderer;
  }
}
