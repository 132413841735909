import { Template } from '../../core/model/entities/template/template.model';

export class TemplateUtil {
  public static updateTemplateTaskListsSort(template: Template): void {
    template.taskLists = template.taskLists.map((list, index) => ({
      ...list,
      sort: list.sort || index + 1
    }));
  }
}
