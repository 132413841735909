import { Pipe, PipeTransform } from '@angular/core';

import { getFilenameExtension } from '../filename-extension.util';

@Pipe({
  name: 'fileExtension',
  pure: true,
  standalone: true
})
export class FileExtensionPipe implements PipeTransform {
  public transform(fileName: string): string {
    return getFilenameExtension(fileName);
  }
}
