import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { Person } from '@celum/work/app/core/model/entities/person';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';
import { AccountAccess } from '@celum/work/app/core/ui-state/ui-state.model';
import { selectCurrentAccountAccess, selectLoggedInPerson } from '@celum/work/app/core/ui-state/ui-state.selectors';
import {
  ContactDialog,
  ContactDialogConfiguration,
  QuotaType
} from '@celum/work/app/pages/workroom-creator/components/contact-dialog/contact-dialog.component';

@Component({
  selector: 'license-limit-reached',
  templateUrl: './license-limit-reached.component.html',
  styleUrls: ['./license-limit-reached.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseLimitReachedComponent implements OnInit {
  @Input() public showContactButton = true;
  @Input() public teamspace: Teamspace;

  public iconLicenseWarningConfig = IconConfiguration.small('license-warning', '');
  public loggedInPerson$: Observable<Person> = this.store.select(selectLoggedInPerson);
  public accountAccess$: Observable<AccountAccess>;

  constructor(
    private dialogOpener: CelumDialogOpener,
    private translateService: TranslateService,
    private store: Store<any>
  ) {}

  public get tooltipInfo$(): Observable<string> {
    return combineLatest([this.loggedInPerson$, this.accountAccess$]).pipe(
      map(([person, accountAccess]) => {
        if (accountAccess && person.email === accountAccess.ownerEmail) {
          return this.translateService.instant('TEMPLATE_CHOOSER.LICENSE_LIMIT.CONTACT.BUTTON.OWNER');
        }
        return `${this.translateService.instant('TEMPLATE_CHOOSER.LICENSE_LIMIT.CONTACT.FORM.CONFIRM')} ${
          accountAccess.ownerName
        }`;
      })
    );
  }

  public get licenseLimitLabel(): string {
    if (this.teamspace) {
      if (
        (!this.teamspace.workroomsUsed && !this.teamspace.workroomsLimit) ||
        this.teamspace.workroomsUsed >= this.teamspace.workroomsLimit
      ) {
        return this.translateService.instant('TEMPLATE_CHOOSER.LICENSE_LIMIT.LABEL.REACHED');
      } else if (this.teamspace.workroomsUsed === this.teamspace.workroomsLimit - 1) {
        return this.translateService.instant('TEMPLATE_CHOOSER.LICENSE_LIMIT.LABEL.TO_BE_REACHED');
      }
    } else {
      // it means is CELUM or PERSONAL category
      return this.translateService.instant('TEMPLATE_CHOOSER.LICENSE_LIMIT.LABEL.REACHED');
    }
  }

  public get contactLabel$(): Observable<string> {
    return combineLatest([this.loggedInPerson$, this.accountAccess$]).pipe(
      map(([person, accountAccess]) => {
        if (accountAccess && person.email === accountAccess.ownerEmail) {
          return this.translateService.instant('TEMPLATE_CHOOSER.LICENSE_LIMIT.CONTACT.BUTTON.OWNER');
        }
        return this.translateService.instant('TEMPLATE_CHOOSER.LICENSE_LIMIT.CONTACT.BUTTON.MEMBER');
      })
    );
  }

  public ngOnInit(): void {
    this.accountAccess$ = this.store.select(selectCurrentAccountAccess);
  }

  public openContactDialog() {
    return combineLatest([this.loggedInPerson$, this.accountAccess$]).subscribe(([person, accountAccess]) => {
      if (accountAccess && person.email === accountAccess.ownerEmail) {
        window.open(
          `${(window as any).Celum.properties.saccHttpBaseAddress}/myaccount/${accountAccess.accountId}`,
          '_blank'
        );
      } else {
        const dialogConfig = new ContactDialogConfiguration(accountAccess, [QuotaType.WORKROOMS]);
        this.dialogOpener.showDialog(ContactDialog.name, ContactDialog, dialogConfig);
      }
    });
  }
}
