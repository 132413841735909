import { NgModule } from '@angular/core';
import { MatTreeModule } from '@angular/material/tree';

import { FilesTreeComponent } from './files-tree.component';
import { SharedModule } from '../../../shared';

@NgModule({
  declarations: [FilesTreeComponent],
  imports: [SharedModule, MatTreeModule],
  exports: [FilesTreeComponent]
})
export class FilesTreeModule {}
