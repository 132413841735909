import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
  standalone: true
})
export class MapPipe implements PipeTransform {
  public transform(value: any, mapFunction: (value: any, ...args: any[]) => any, ...args: any[]): any {
    return mapFunction(value, ...args);
  }
}
