import { EntityTranslator } from '@celum/work/app/core/model';
import { Rendition, RenditionType } from '@celum/work/app/core/model/entities/rendition/rendition.model';

export class RenditionTranslator implements EntityTranslator<Rendition> {
  public translateToEntity(json: any): Rendition {
    // File version's id is called fileVersion if json is returned from normalizer, otherwise its fileVersionId (e.g. if we take json directly from http response)
    const fileVersionId: string = json.fileVersion || json.fileVersionId;

    return {
      id: json.id,
      downloadUrl: json.downloadUrl,
      storageId: json.storageId,
      type: json.type,
      originalFormat: json.originalFormat,
      entityType: RenditionType.instance(),
      fileVersionId
    };
  }
}
