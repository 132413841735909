import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConfigurationKey } from '@celum/work/app/core/model/configuration.model';

import { WorkroomCreatorState } from './workroom-creator.reducer';

export const FEATURE_NAME = 'workroomCreator';

export const workroomCreatorFeature = createFeatureSelector<WorkroomCreatorState>(FEATURE_NAME);

export const selectWorkroomCreatorInitiating = createSelector(workroomCreatorFeature, state => state.initiating);

export const selectWorkroomCreatorHasTemplatesBottom = createSelector(
  workroomCreatorFeature,
  state => state.paginationResult?.hasBottom
);

export const selectIfWorkroomWasCreated = createSelector(workroomCreatorFeature, state => state.isWorkroomCreated);

export const selectTotalTemplateCount = createSelector(
  workroomCreatorFeature,
  state => state.paginationResult?.totalElementCount
);

export const selectImportIntentRepositoryId = createSelector(
  workroomCreatorFeature,
  state => state.importIntentRepositoryId
);

export const selectImportIntentCollectionName = createSelector(
  workroomCreatorFeature,
  state => state.importIntentCollectionName
);

export const selectDefaultConfig = (
  configKey: ConfigurationKey.DEFAULT_WORKROOM | ConfigurationKey.DEFAULT_WORKROOM_TEMPLATE
) => createSelector(workroomCreatorFeature, state => state.defaultConfigs[configKey]);
