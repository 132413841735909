import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { concat, EMPTY, from, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { ConvertToTaskSucceededEvent, SubtaskEvents } from '@celum/work/app/core/api/subtask/subtask.events';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { subtaskBasicProperties } from '@celum/work/app/core/model/entities/subtask/subtask.model';
import {
  selectCurrentTask,
  selectCurrentWorkroomTaskListsExcludedByRule
} from '@celum/work/app/core/model/entities/task';
import { RuleType } from '@celum/work/app/core/model/entities/workroom/robot.model';
import {
  ConvertSubtaskToTaskDialogComponent,
  ConvertSubtaskToTaskDialogConfiguration,
  ConvertSubtaskToTaskResult
} from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/subtasks/components/convert-subtask-to-task-dialog/convert-subtask-to-task-dialog.component';
import {
  selectLasOffset,
  selectSubtasksBatchSize
} from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/subtasks/store/subtask-state.selectors';
import { SubtaskUtilService } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/services/subtask-util.service';
import { selectCurrentWorkroomId } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { sortAscending } from '@celum/work/app/shared/util';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';

import {
  OpenSubtaskConvertToTaskDialog,
  SubtaskAssignPerson,
  SubtaskAssignPersonFailure,
  SubtaskAssignPersonSuccess,
  SubtaskConvertToTask,
  SubtaskConvertToTaskCanceled,
  SubtaskConvertToTaskFailed,
  SubtaskConvertToTaskSucceeded,
  SubtaskCreateSubtask,
  SubtaskCreateSubtaskFailed,
  SubtaskCreateSubtaskSucceeded,
  SubtaskDeleteSubtask,
  SubtaskDeleteSubtaskFailed,
  SubtaskFetchNextBatchSubtasks,
  SubtaskHandleAssignee,
  SubtaskLoadSubtasks,
  SubtaskLoadSubtasksFailed,
  SubtaskLoadSubtasksSucceeded,
  SubtaskUnassignPerson,
  SubtaskUnassignPersonFailure,
  SubtaskUnassignPersonSuccess,
  SubtaskUpdateSubtask,
  SubtaskUpdateSubtaskFailed,
  SubtaskUpdateSubtaskSucceeded
} from './subtask.actions';
import { SubtaskService } from './subtask.service';
import { SubtaskDeleteOne, SubtaskUpsertOne } from '../../model/entities/subtask/subtask.actions';
import { selectSubtaskById } from '../../model/entities/subtask/subtask.selectors';
import { TaskService } from '../task/task.service';

@Injectable()
export class SubtaskEffects {
  public loadSubtasks = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskLoadSubtasks),
      concatLatestFrom(_ => this.store.pipe(select(selectSubtasksBatchSize))),
      switchMap(([action, batchSize]) =>
        this.subtaskService
          .getSubtasks(action.taskId, Paging.of(action.offset, batchSize), Sorting.of('sort', SortDirection.DESC))
          .pipe(
            map(({ subtasks, paginationResult }) =>
              SubtaskLoadSubtasksSucceeded({
                taskId: action.taskId,
                subtasks,
                resetState: action.offset === 0,
                paginationResult
              })
            ),
            catchError(() => of(SubtaskLoadSubtasksFailed({ taskId: action.taskId })))
          )
      )
    )
  );

  public fetchNextBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskFetchNextBatchSubtasks),
      concatLatestFrom(_ => this.store.pipe(select(selectLasOffset))),
      map(([{ taskId }, lastOffset]) => SubtaskLoadSubtasks({ offset: lastOffset, taskId }))
    )
  );

  public createSubtask = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskCreateSubtask),
      concatMap(action =>
        concat(
          of(SubtaskUpsertOne({ task: action.tmpSubtask })),
          this.subtaskService.createSubtask(SubtaskUtilService.subtaskToCreateParams(action.tmpSubtask)).pipe(
            map(subtask => SubtaskCreateSubtaskSucceeded({ tmpSubtask: action.tmpSubtask, subtask })),
            catchError(() => of(SubtaskCreateSubtaskFailed({ tmpSubtask: action.tmpSubtask })))
          )
        )
      )
    )
  );

  public updateSubtask = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskUpdateSubtask),
      concatLatestFrom(action => this.store.select(selectSubtaskById(action.subtask.id))),
      mergeMap(([action, oldSubtask]) =>
        concat(
          of(SubtaskUpsertOne({ task: action.subtask, propertiesToUpdate: subtaskBasicProperties })),
          this.subtaskService.updateSubtask(SubtaskUtilService.subtaskToUpdateParams(oldSubtask, action.subtask)).pipe(
            map(subtask => SubtaskUpdateSubtaskSucceeded({ oldSubtask, subtask })),
            catchError(() => of(SubtaskUpdateSubtaskFailed({ oldSubtask })))
          )
        )
      )
    )
  );

  public deleteSubtask = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskDeleteSubtask),
      concatMap(action =>
        concat(
          of(SubtaskDeleteOne({ task: action.subtask })),
          this.subtaskService.deleteSubtasks(action.subtask.parentId, [action.subtask.id]).pipe(
            switchMap(() => EMPTY),
            catchError(() => of(SubtaskDeleteSubtaskFailed({ subtask: action.subtask })))
          )
        )
      )
    )
  );

  public openSubtaskConvertToTaskDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenSubtaskConvertToTaskDialog),
      concatLatestFrom(() =>
        this.store
          .select(selectCurrentWorkroomTaskListsExcludedByRule(RuleType.TASK_CREATION_RESTRICTION))
          .pipe(map(taskLists => taskLists.sort((a, b) => sortAscending(a.sort, b.sort))))
      ),
      concatMap(([action, taskLists]) =>
        from(
          this.dialogOpener
            .showDialog(
              'convert-subtask-to-task-dialog',
              ConvertSubtaskToTaskDialogComponent,
              new ConvertSubtaskToTaskDialogConfiguration({ taskLists }),
              { restoreFocus: false }
            )
            .then((result: ConvertSubtaskToTaskResult | null) => {
              if (!result) {
                return SubtaskConvertToTaskCanceled();
              }

              return SubtaskConvertToTask({ subtask: action.subtask, taskListId: result.taskListId });
            })
        )
      )
    )
  );

  public convertSubtaskToTask = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskConvertToTask),
      concatLatestFrom(() => this.store.select(selectCurrentTask)),
      concatMap(([action, currentTask]) =>
        this.taskService.convertToTask(currentTask.id, action.subtask.id, action.taskListId).pipe(
          map(task => SubtaskConvertToTaskSucceeded({ task, subtask: action.subtask })),
          catchError(() => of(SubtaskConvertToTaskFailed({ subtask: action.subtask })))
        )
      )
    )
  );

  public convertSubtaskToTaskSucceeded = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubtaskConvertToTaskSucceeded),
        concatLatestFrom(() => [this.store.select(selectCurrentWorkroomId)]),
        tap(([{ task }, workroomId]) => this.router.navigate(['workroom', workroomId, 'tasks', 'task', task.id])),
        tap(([{ task }]) =>
          this.eventBus.publishEvent({
            type: SubtaskEvents.CONVERT_TO_TASK_SUCCEEDED,
            data: { task: task }
          } as ConvertToTaskSucceededEvent)
        )
      ),
    { dispatch: false }
  );

  public rollbackDeleteSubtaskOnFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskDeleteSubtaskFailed, SubtaskConvertToTaskFailed),
      map(action => SubtaskUpsertOne({ task: action.subtask }))
    )
  );

  public rollbackCreateSubtaskOnFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskCreateSubtaskFailed),
      map(action => SubtaskDeleteOne({ task: action.tmpSubtask }))
    )
  );

  public rollbackUpdateSubtaskOnFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskUpdateSubtaskFailed),
      map(action => SubtaskUpsertOne({ task: action.oldSubtask }))
    )
  );

  public assignPerson = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskAssignPerson),
      concatMap(({ subtask, personId }) =>
        this.subtaskService.assignPersonToSubtask(subtask, personId).pipe(
          map(() =>
            SubtaskAssignPersonSuccess({
              subtask,
              personId
            })
          ),
          catchError(_ => of(SubtaskAssignPersonFailure({ subtask }))),
          startWith(SubtaskHandleAssignee({ entityId: subtask.id, assigneeId: personId, operation: 'add' }))
        )
      )
    )
  );

  public unassignPerson = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskUnassignPerson),
      concatMap(({ subtask, personId }) =>
        this.subtaskService.unassignPersonFromSubtask(subtask, personId).pipe(
          map(() =>
            SubtaskUnassignPersonSuccess({
              subtask,
              personId
            })
          ),
          catchError(_ => of(SubtaskUnassignPersonFailure({ subtask }))),
          startWith(SubtaskHandleAssignee({ entityId: subtask.id, assigneeId: personId, operation: 'remove' }))
        )
      )
    )
  );

  public rollbackSubtaskAssigneesOnFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskAssignPersonFailure, SubtaskUnassignPersonFailure),
      map(action => SubtaskUpsertOne({ task: action.subtask }))
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private subtaskService: SubtaskService,
    private taskService: TaskService,
    private dialogOpener: CelumDialogOpener,
    private router: Router,
    private eventBus: ApplicationEventBus
  ) {}
}
