<div class="custom-fields-virtual-scroll-table_row">
  <div class="custom-fields-virtual-scroll-table_row_name-column">
    <text-truncator
        class="custom-fields-virtual-scroll-table_row_name-column_text"
        [text]="customField.name">
    </text-truncator>
  </div>
  <div class="custom-fields-virtual-scroll-table_row_counter-column">
    <text-truncator
        class="custom-fields-virtual-scroll-table_row_counter-column_text"
        [text]="customField.formsUsageCounter.toString()">
    </text-truncator>
  </div>
  <div class="custom-fields-virtual-scroll-table_row_type-column">
    <text-truncator
        class="custom-fields-virtual-scroll-table_row_type-column_text"
        [text]="('TEAMSPACE_MANAGEMENT.FIELDS.FIELD_TYPES.' + customField.type) | translate">
    </text-truncator>
  </div>
</div>
