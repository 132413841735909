import { createAction, props } from '@ngrx/store';

import { PaginationResult } from '@celum/core';

import { TasksOverviewTaskList } from './tasks-overview.model';

export const TasksOverviewLoadTaskBoard = createAction(
  '[TasksOverview] Load task lists',
  props<{ workroomId: number }>()
);

export const TasksOverviewLoadingFailed = createAction('[TasksOverview] Loading failed');

export const TasksOverviewTaskBoardLoaded = createAction(
  '[TasksOverview] TaskLists loaded',
  props<{ taskLists: TasksOverviewTaskList[] }>()
);

export const TasksOverviewFetchNextBatch = createAction(
  '[TasksOverview] Fetch next batch',
  props<{ listId: number }>()
);

export const TasksOverviewChangeBatchSize = createAction(
  '[TasksOverview] Change batch size',
  props<{ batchSize: number }>()
);

export const TasksOverviewTaskListsWithTasksBatchLoaded = createAction(
  '[TasksOverview] Next batch loaded',
  props<{ listId: number; paginationResult: PaginationResult; taskIds: number[] }>()
);

export const TasksOverviewUpdateTaskCount = createAction(
  '[Tasks] Update task count',
  props<{ taskListId: number; relativeCountChange: number }>()
);

export const TasksOverviewTaskCardTogglePreview = createAction('[TasksOverview] Hide task preview');

export const TasksOverviewTaskCardInitPreviewState = createAction(
  '[TasksOverview] Init task preview state',
  props<{ shown: boolean }>()
);

export const TasksOverviewUpdateVisibleColumns = createAction(
  '[TasksOverview] Update visible columns',
  props<{ taskListId: number; visibleColumnsCount: number }>()
);

export const TasksOverviewResetState = createAction('[TasksOverview] Reset task board state');
