<mat-list class="category-list">
  <mat-list-item class="category-list_item"
                 data-operation-id="reset-template-category"
                 [ngClass]="{ 'category-list_item--active': activeCategory === 'all' }"
                 (click)="resetActiveCategory()">
    <div matLine>{{ 'TEMPLATE_CHOOSER.ALL_CATEGORY' | translate }}</div>
  </mat-list-item>
  <mat-divider class="category-list_divider"></mat-divider>

  <ng-container *ngFor="let category of categories; let i = index; trackBy: trackByFn">
    <mat-list-item class="category-list_item"
                   [attr.data-cy]="'category-item-' + i"
                   [ngClass]="{ 'category-list_item--active': activeCategory === category.id }"
                   (click)="setCategoryAsActive(category)">
      <div matLine class="category-list_template">
        <text-truncator [text]="category | templateCategoryName"></text-truncator>
        <div *ngIf="category.id > 1 && (category.id | hasTemplateCategoryPermission: createWorkroomPermission | async)
                        && (category.id | licenseLimitReached | async)"
             matTooltip="{{ 'TEMPLATE_CHOOSER.LICENSE_LIMIT.INFO' | translate }}"
             [matTooltipPosition]="'below'">
          <celum-icon [configuration]="iconLicenseWarningConfig"></celum-icon>
        </div>
      </div>
    </mat-list-item>
    <mat-divider class="category-list_divider"></mat-divider>
  </ng-container>

  <mat-list-item class="category-list_item"
                 data-operation-id="request-only-personal"
                 [ngClass]="{ 'category-list_item--active': activeCategory === personalCategoryKey }"
                 (click)="requestPersonal()">
    <div matLine>{{ 'TEMPLATE_CHOOSER.PERSONAL_CATEGORY' | translate }}</div>
  </mat-list-item>
  <mat-divider class="category-list_divider"></mat-divider>
</mat-list>
