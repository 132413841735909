import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { EntityTranslator } from '@celum/work/app/core/model';
import { ItemContext } from '@celum/work/app/core/model/entities/activity/activity.model';

import { Comment, CommentType } from './comment.model';
import { selectCommentById } from './comment.selectors';
import { nullableString } from '../../nullable-string';
import { selectAllFiles, selectFileEntities } from '../file/file.selectors';
import { selectPersonEntities } from '../person/person.selectors';
import { selectTaskEntities } from '../task/task.selectors';

export class CommentTranslator implements EntityTranslator<Comment> {
  public translateToEntity(json: any): Comment {
    const id = json.id;

    return {
      id,
      content: json.content,
      objectId: json.objectId,
      context: nullableString(json.context) as ItemContext,
      createdBy: json.createdById,
      changedBy: json.changedById,
      changedOn: json.changedOn,
      createdOn: json.createdOn,
      workroomId: json.workroomId,
      markers: json.marker || [],
      fileVersionId: json.fileVersionId,
      versionNumber: json.versionNumber,
      parentId: json.parentId,
      replyCount: json.replyCount ?? 0,
      entityType: CommentType.instance(),
      attachmentIds: json.attachmentIds,
      attachments: (store: Store<any>) =>
        store.select(selectAllFiles).pipe(map(files => files.filter(file => json.attachmentIds.includes(file.id)))),
      object: (store: Store<any>) =>
        combineLatest([
          store.select(selectTaskEntities),
          store.select(selectFileEntities),
          store.select(selectCommentById(id))
        ]).pipe(
          filter(([_, comment]) => !!comment),
          map(([tasks, files, comment]) => {
            const objectId = comment.objectId;
            if (!objectId) {
              return null;
            }
            if (tasks[objectId]) {
              return tasks[objectId];
            }
            if (files[objectId]) {
              return files[objectId];
            }
            return null;
          })
        ),
      creator: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectCommentById(id))]).pipe(
          filter(([_, comment]) => !!comment),
          map(([persons, comment]) => persons[comment.createdBy])
        ),
      modifier: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectCommentById(id))]).pipe(
          filter(([_, comment]) => !!comment),
          map(([persons, comment]) => persons[comment.changedBy])
        )
    };
  }
}
