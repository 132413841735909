<celum-dialog class="save-template" (onCloseAll)="cancel()">
  <celum-dialog-header>
    {{'WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.HEADLINE' | translate}}
  </celum-dialog-header>

  <celum-dialog-content>
    <p class="save-template_intro-text">{{'WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.INTRO_TEXT' | translate}}</p>
    <form [formGroup]="form">
      <div class="save-template_label">{{'WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.TEMPLATE_NAME' | translate}}</div>
      <mat-form-field celum-form-field [reserveHintSpace]="true">
        <input
            matInput
            [formControl]="nameControl"
            [maxLength]="validationValues.maxNameLength"
            [placeholder]="'TEMPLATE_CHOOSER.ACTION_DIALOG.COPY_DIALOG.NAME' | translate">
        <mat-hint *ngIf="showRemainingNameCharsHint">{{ 'INPUT_HINTS.REMAINING_CHARS' | translate: remainingNameChars }}</mat-hint>
        <mat-error *ngIf="nameControl.hasError('required')">{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
        <mat-error *ngIf="nameControl.hasError('maxlength')">
          {{ 'VALIDATION.MAX_LENGTH' | translate: { maxLength: validationValues.maxNameLength } }}
        </mat-error>
      </mat-form-field>


      <ng-container *ngIf="categories$ | async as categories">
        <ng-container *ngIf="categories.length > 0; else categoryName">
          <div class="save-template_category">
            <div class="save-template_label">{{'WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.PICKER_CATEGORY' | translate}}</div>
            <mat-form-field celum-form-field class="save-template_category-field" [reserveHintSpace]="true">
              <mat-select
                  panelClass="celum-dropdown-panel"
                  [formControl]="categoryIdControl"
                  placeholder="{{'WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.PICKER_CATEGORY' | translate}}">
                <mat-option [value]="personalCategoryKey">{{ 'TEMPLATE_CHOOSER.PERSONAL_CATEGORY' | translate }}</mat-option>
                <mat-option [value]="category.id" *ngFor="let category of categories, trackBy: trackByFn">
                  {{ category | templateCategoryName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="categoryIdControl.hasError('required')">{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-template #categoryName>
          <div class="save-template_category">
            <div class="save-template_label">{{'WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.PICKER_CATEGORY' | translate}}</div>
            <div>{{ 'TEMPLATE_CHOOSER.PERSONAL_CATEGORY' | translate }}</div>
          </div>
        </ng-template>
      </ng-container>
    </form>

    <div class="save-template_info-note" *ngIf="showInfoNote">
      <info-note text="WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.INFO_NOTE"></info-note>
    </div>
  </celum-dialog-content>

  <celum-dialog-footer
    [color]="color"
    [valid]="form.valid"
    [confirmBtnText]="'COMMON.SAVE'"
    [cancelBtnText]="'COMMON.CANCEL'"
    (onCancel)="cancel()"
    (onConfirm)="saveTemplate()">
  </celum-dialog-footer>
</celum-dialog>
