import { createAction, props } from '@ngrx/store';

import { Rendition } from '@celum/work/app/core/model/entities/rendition/rendition.model';

export const RenditionUpsertOne = createAction(
  '[Rendition] Upsert One',
  props<{ rendition: Rendition; propertiesToUpdate?: string[] }>()
);

export const RenditionUpsertMany = createAction(
  '[Rendition] Upsert Many',
  props<{ renditions: Rendition[]; propertiesToUpdate?: string[] }>()
);

export const RenditionDeleteOne = createAction('[Rendition] Delete One', props<{ rendition: Rendition }>());

export const RenditionDeleteMany = createAction('[Rendition] Delete Many', props<{ ids: number[] }>());
