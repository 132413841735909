<div data-cy="people-card" class="content-row people-row" [attr.data-person-email]="entity?.email" #peopleRow>
  <selection-trigger-wrapper [item]="entity" [size]="size"></selection-trigger-wrapper>

  <div class="people-row_wrapper">
    <workroom-avatar [configuration]="avatar$ | async"></workroom-avatar>
    <div class="people-row_wrapper_info">
      <div class="text-cell content-cell_name category">
        <span data-cy="people-card-name" [ngClass]="{'people-row_empty-name': isEmptyName}">{{ getUserName }}</span>
      </div>
      <person-label
          class='people-row-label'
          *ngIf="status | peopleCardShowLabel:invitationStatus"
          [roles]="roles"
          [status]="status"
          [invitationStatus]="invitationStatus">
      </person-label>
    </div>
  </div>
</div>
