import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AbstractWebsocketListener } from '@celum/work/app/core/websocket/abstract-websocket-listener';
import { ProgressTaskWebsocketListener } from '@celum/work/app/progress-task/services/progress-task-websocket-listener.service';
import { SharedModule } from '@celum/work/app/shared';
import { SnackbarGroupModule } from '@celum/work/app/shared/components/snackbar-group/snackbar-group.module';

import { ProgressTaskSnackbarGroup } from './components/progress-task-snackbar-group/progress-task-snackbar-group.component';
import { ProgressTaskSnackbarGroupWrapperComponent } from './components/progress-task-snackbar-group-wrapper/progress-task-snackbar-group-wrapper.component';
import { ProgressTaskErrorPipe } from './pipes/progress-task-error.pipe';
import { ProgressTaskHandlerService } from './services/progress-task-handler.service';
import { progressTaskStateReducer } from './store/progress-task.reducer';
import { PROGRESS_TASK_FEATURE_NAME } from './store/progress-task.selectors';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(PROGRESS_TASK_FEATURE_NAME, progressTaskStateReducer),
    SnackbarGroupModule
  ],
  declarations: [ProgressTaskSnackbarGroup, ProgressTaskSnackbarGroupWrapperComponent, ProgressTaskErrorPipe],
  exports: [ProgressTaskSnackbarGroup, ProgressTaskSnackbarGroupWrapperComponent],
  providers: [
    ProgressTaskHandlerService,
    {
      provide: AbstractWebsocketListener,
      useClass: ProgressTaskWebsocketListener,
      multi: true
    }
  ]
})
export class ProgressTaskModule {}
