import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { flatten, isEmpty, uniqBy } from 'lodash';
import { filter, mapTo, switchMap, take } from 'rxjs/operators';

import { LocalStorageService } from '@celum/work/app/core';
import { FetchPersonAvatars, Person, selectAllPersons } from '@celum/work/app/core/model/entities/person';
import { selectAllTeamspaces } from '@celum/work/app/core/model/entities/teamspace';
import { AccountAccess, AccountAccessToken } from '@celum/work/app/core/ui-state/ui-state.model';

@Injectable({ providedIn: 'root' })
export class AvatarService {
  public static AVATAR_ACCOUNT_ACCESS_TOKEN = 'avatarAccountAccessToken';

  constructor(
    private store: Store,
    private localStorageService: LocalStorageService
  ) {
    this.store
      .select(selectAllPersons)
      .pipe(
        switchMap(persons =>
          this.store.select(selectAllTeamspaces).pipe(
            filter(teamspaces => teamspaces.length > 0),
            take(1),
            mapTo(persons)
          )
        ),
        filter(persons => !isEmpty(persons))
      )
      .subscribe(persons => {
        this.store.dispatch(FetchPersonAvatars({ persons }));
      });
  }

  public static buildAvatarUrl(profilePictureDownloadLink: string, accountAccessToken: string): string {
    return profilePictureDownloadLink && accountAccessToken
      ? `${profilePictureDownloadLink}&token=${accountAccessToken}`
      : null;
  }

  public addTokensToStorage(accountAccesses: AccountAccess[]): void {
    this.storeAccountAccessTokens(accountAccesses, 'accountAccessToken');
  }

  public getAccountAccessTokenByAccountId(accountId: string): string {
    const accountAccessTokens: AccountAccessToken[] = JSON.parse(
      this.localStorageService.getItem(AvatarService.AVATAR_ACCOUNT_ACCESS_TOKEN)
    );
    return accountAccessTokens?.find(accountAccessToken => accountAccessToken.accountId === accountId)?.token;
  }

  public mapToUniqueAvatarsWithAccountId(avatarsAndAccountIds: { id: string; avatars: any }[]): any[] {
    const avatarsWithAccessToken = avatarsAndAccountIds
      .map(avatarsAndAccountId => ({
        accountAccessToken: this.getAccountAccessTokenByAccountId(avatarsAndAccountId.id),
        avatars: avatarsAndAccountId.avatars
      }))
      .map(value =>
        value.avatars.map(avatar => ({
          ...avatar,
          accountAccessToken: value.accountAccessToken
        }))
      );
    return uniqBy(flatten(avatarsWithAccessToken), 'email');
  }

  public mapAvatarToUser(
    persons: Person[],
    avatars: Array<{ profilePictureDownloadLink: any; email: string; accountAccessToken: string }>
  ): Person[] {
    return persons.map(person => {
      const { profilePictureDownloadLink = null, accountAccessToken = null } =
        avatars.find(avatar => avatar.email === person.email) || {};
      return {
        ...person,
        avatarUrl: AvatarService.buildAvatarUrl(profilePictureDownloadLink, accountAccessToken)
      };
    });
  }

  private storeAccountAccessTokens(
    accountAccessTokens: (AccountAccessToken | AccountAccess)[],
    tokenPropertyName: 'token' | 'accountAccessToken'
  ): void {
    const tokens: AccountAccessToken[] = accountAccessTokens.map(accountAccessToken => ({
      accountId: accountAccessToken.accountId,
      token: accountAccessToken[tokenPropertyName]
    }));
    this.localStorageService.setItem(AvatarService.AVATAR_ACCOUNT_ACCESS_TOKEN, JSON.stringify(tokens));
  }
}
