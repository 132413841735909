import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autoFocus]',
  standalone: true
})
export class AutoFocusDirective implements AfterContentInit {
  @Input() public autoFocus: boolean;

  public constructor(private el: ElementRef) {}

  public ngAfterContentInit() {
    if (this.autoFocus) {
      setTimeout(() => this.el.nativeElement.focus(), 100);
    }
  }
}
