<button
    [class.collapse-button--expanded]="expanded"
    mat-button
    celum-square-button
    [style.width.px]="buttonSize"
    [style.height.px]="buttonSize"
    [matTooltip]="expanded ? collapseTooltip: expandTooltip"
    (click)="$event.stopPropagation(); toggle.emit(!expanded)">
  <celum-icon [configuration]="toggleIcon">
  </celum-icon>
</button>