<mat-select class="sorter_dropdown"
            [panelClass]="'celum-select-panel'"
            [placeholder]="'SORTER.ORDER_BY' | translate"
            [value]="sortField?.value"
            (selectionChange)="handleSelection($event.source.value)"
            [matTooltip]="sortField?.displayName | translate"
            matSelectTooltipAutoDisabling>

  <mat-select-trigger>{{ sortField?.displayName | translate }}</mat-select-trigger>
  <mat-option *ngFor="let sortProperty of sortProperties; trackBy: trackByFn" [value]="sortProperty.value" [attr.data-sort-option]="sortProperty.value">
    <div class="sorter_dropdown-text-wrap">
      <div class="sorter_dropdown-additional-text">{{'SORTER.ORDER_BY' | translate}}</div>
      <div class="sorter_dropdown-text"> {{sortProperty.displayName | translate}}</div>
    </div>
  </mat-option>
</mat-select>

<button 
    mat-button celum-square-button
    [matTooltip]="'SORTER.SORT_ORDER' | translate"
    (click)="toggleSortDirection()">
  <celum-icon class="sorter_direction parent-hover"
              [attr.data-sort-direction]="isDescending() ? 'desc' : 'asc'"
              [class.sorter_direction--ascending]="!isDescending()"
              [configuration]="sortOrderIcon">
  </celum-icon>
</button>
