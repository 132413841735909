import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider, DataUtil, PaginationResult } from '@celum/core';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo, Paging, Sorting } from '@celum/work/app/core/model';
import {
  Subtask,
  subtaskBasicProperties,
  SubtaskCreateParams,
  SubtaskType,
  SubtaskUpdateParams
} from '@celum/work/app/core/model/entities/subtask/subtask.model';
import { MAX_SORT } from '@celum/work/app/shared/util';

@Injectable({
  providedIn: 'root'
})
export class SubtaskService {
  constructor(
    private resultConsumerService: ResultConsumerService,
    private http: HttpClient
  ) {}

  public getSubtasks(
    taskId: number,
    paging: Paging,
    sorting: Sorting
  ): Observable<{ subtasks: Subtask[]; paginationResult: PaginationResult }> {
    const resultsSchema = {
      results: [SubtaskType.instance().getSchema()]
    };

    const metaInfo = MetaInfo.of([SubtaskType.TYPE_KEY], resultsSchema, [SubtaskType.TYPE_KEY], 'results');

    const body: any = {
      paging,
      sorting
    };

    return this.http
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/subtasks/search`, body)
      .pipe(
        map(response => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(response, metaInfo);
          return {
            subtasks: entitiesResult.entities[SubtaskType.TYPE_KEY] as Subtask[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public createSubtask(createParams: SubtaskCreateParams): Observable<Subtask> {
    const { taskId, subtaskName } = createParams;
    const body = {
      name: subtaskName,
      sort: MAX_SORT,
      typeKey: SubtaskType.SUB_TYPE_KEY
    };

    return this.http
      .post<Subtask>(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/subtasks`, body)
      .pipe(
        map(res => {
          const metaInfo = MetaInfo.of([SubtaskType.TYPE_KEY], SubtaskType.instance().getSchema());

          return this.handleSingleTaskResult(res, metaInfo);
        })
      );
  }

  public updateSubtask(updateParams: SubtaskUpdateParams): Observable<Subtask> {
    const { taskId, subtaskId, sort } = updateParams;
    const payload = {
      name: updateParams.name,
      status: updateParams.status,
      sort
    };
    return this.http
      .patch<Subtask>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/subtasks/${subtaskId}`,
        payload
      )
      .pipe(
        map(res => {
          const metaInfo = MetaInfo.of([SubtaskType.TYPE_KEY], SubtaskType.instance().getSchema());
          metaInfo.partialUpdates = {
            [SubtaskType.TYPE_KEY]: subtaskBasicProperties
          };

          return this.handleSingleTaskResult(res, metaInfo);
        })
      );
  }

  public deleteSubtasks(taskId: number, subtaskIds: number[]): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders(),
      body: subtaskIds
    };

    return this.http
      .delete<Subtask>(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/subtasks`, httpOptions)
      .pipe(map(() => void 0));
  }

  public assignPersonToSubtask(subtask: Subtask, personId: number): Observable<void> {
    return this.http.post<void>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${subtask.parentId}/subtasks/${subtask.id}/assign/${personId}`,
      {}
    );
  }

  public unassignPersonFromSubtask(subtask: Subtask, personId: number): Observable<void> {
    return this.http.delete<void>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${subtask.parentId}/subtasks/${subtask.id}/assign/${personId}`
    );
  }

  public handleSingleTaskResult(res: any, metaInfo: MetaInfo): Subtask {
    const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
    const tasks = entitiesResult.entities[SubtaskType.TYPE_KEY];
    return DataUtil.isEmpty(tasks) ? null : (tasks[0] as Subtask);
  }
}
