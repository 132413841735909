import { Directive, Host, OnDestroy } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { takeUntil } from 'rxjs/operators';

import { ReactiveComponent } from '@celum/ng2base';

/*
 * The purpose of this directive is to add tooltip on mat-select
 * when selected option is too long (has 3 dots at end).
 * The directive works only on a mat-select tag with both
 * matTooltip and matSelectTooltipAutoDisabling attributes.
 */

@Directive({
  selector: '[matTooltip] [matSelectTooltipAutoDisabling]'
})
export class MatSelectTooltipAutoDisablingDirective extends ReactiveComponent implements OnDestroy {
  private initialized = false;
  private mutationObserver: MutationObserver;
  constructor(
    @Host() public tooltip: MatTooltip,
    @Host() public matSelect: MatSelect
  ) {
    super();
    this.tooltip.disabled = true;
    matSelect.stateChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (matSelect.trigger?.nativeElement && !matSelect.empty && !this.initialized) {
        const selectedValueElement: HTMLElement =
          matSelect.trigger.nativeElement.querySelector('.mat-mdc-select-value');
        this.mutationObserver = new MutationObserver(() => {
          this.tooltip.disabled = selectedValueElement.offsetWidth >= selectedValueElement.scrollWidth;
        });
        const config = { characterData: true, attributes: false, childList: false, subtree: true };
        this.mutationObserver.observe(matSelect.trigger.nativeElement, config);
      }
    });
  }

  public ngOnDestroy(): void {
    this.mutationObserver?.disconnect();
  }
}
