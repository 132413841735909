import { EntityTranslator } from '@celum/work/app/core/model';
import { Portal, PortalType } from '@celum/work/app/core/model/entities/portal/portal.model';

export class PortalTranslator implements EntityTranslator<Portal> {
  public translateToEntity(json: any): Portal {
    return {
      id: json.id,
      name: json.name,
      url: json.url,
      status: json.status,
      entityType: PortalType.instance()
    };
  }
}
