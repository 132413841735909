/* eslint-disable max-classes-per-file */
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { DataUtil } from '@celum/core';
import { sortAssetIds } from '@celum/work/app/shared/util';

import { Folder } from '../../../core/model/entities/folder/folder.model';
import { Workroom } from '../../../core/model/entities/workroom';
import { selectCurrentWorkroom } from '../../../pages/workroom/store/workroom-wrapper.selectors';
import { isNullOrUndefined, notNullOrUndefined } from '../../../shared/util/typescript-util';
import { TREE_BATCH_SIZE } from '../../store/files-tree/files-tree.effects';

export interface FolderTreePaginationInformation {
  [folderId: string]: { hasBottom: boolean; totalElementCount: number };
}

@Component({
  selector: 'files-tree',
  templateUrl: './files-tree.component.html',
  styleUrls: ['./files-tree.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesTreeComponent implements OnInit, OnChanges {
  @Input() public folders: Folder[];
  @Input() public activeFolder: Folder;
  @Input() public selectedFolderIds: string[] = [];
  @Input() public paginationInformation: FolderTreePaginationInformation;
  @Input() public disabledFolders: string[] = [];
  @Input() public sortFolders = true;
  @Input() public isStrict: (folder: Folder) => boolean;
  @Input() public foldersAffectedByRestore: string[] = [];
  @Input() public rightSideTemplate: TemplateRef<any>;

  @HostBinding('class.multiselection')
  @Input()
  public multiSelection = false;

  @Output() public readonly expand = new EventEmitter<{ parentId: string; limit: number }>();
  @Output() public readonly selectionChanged = new EventEmitter<Folder[]>();
  @Output() public readonly fetchMore = new EventEmitter<{ parentId: string; offset: number }>();
  @Output() public readonly openContextMenu = new EventEmitter<{ x: number; y: number; folder: Folder }>();

  @ViewChild('scrollableContainer', { static: false }) public scrollbar: ElementRef<HTMLDivElement>;
  @ViewChildren('treeNode') public treeNodes: QueryList<ElementRef>;

  public treeControl = new NestedTreeControl<TreeNode>(node => {
    if (node && node instanceof FolderNode) {
      const folderNode = node as FolderNode;
      return folderNode.folder.hasSubfolders ? folderNode.subFolders : undefined;
    } else {
      return undefined;
    }
  });
  public dataSource = new MatTreeNestedDataSource<TreeNode>();
  public folderIcon = IconConfiguration.medium('folder');
  public robotIcon = IconConfiguration.medium('robot').withColor(ColorConstants.BLUE_GRAY_800);
  public strictFolderIcon = IconConfiguration.medium('strict');
  public expandedIcon = IconConfiguration.xSmall('arrow-down-xs');
  public collapsedIcon = IconConfiguration.xSmall('arrow-right-xs');
  public activeFolderNode: FolderNode;
  public dict: { [id: string]: Folder[] };
  public currentWorkroom$: Observable<Workroom>;

  constructor(
    private store: Store<any>,
    public elementRef: ElementRef
  ) {}

  public hasChild = (_: number, node: TreeNode) =>
    node instanceof FolderNode && (node as FolderNode).folder.hasSubfolders && this.folders.length > 1;

  public isLoadMore = (_: number, node: TreeNode) => node instanceof LoadMoreNode;

  public isStrictFn(node: FolderNode): boolean {
    return this.isStrict ? this.isStrict(node.folder) : false;
  }

  public expandIconShouldBeDisabled(node: FolderNode): boolean {
    return this.disabledFolders.some(id => id === node.folder.id);
  }

  public resolveNodeIcon(node: FolderNode): IconConfiguration {
    return this.isStrictFn(node) ? this.strictFolderIcon : this.folderIcon;
  }

  public ngOnInit(): void {
    this.currentWorkroom$ = this.store.select(selectCurrentWorkroom);
  }

  public ngOnChanges({ folders, activeFolder, foldersAffectedByRestore, selectedFolderIds }: SimpleChanges): void {
    if (this.hasInputPropertyChanged(folders) && !isEmpty(folders.currentValue)) {
      this.rebuildFolderDictionary();
      this.initialize();
    }

    if (this.hasInputPropertyChanged(activeFolder)) {
      this.activeFolderNode = this.findNode(this.dataSource.data, this.activeFolder.id);
    }

    if (this.hasInputPropertyChanged(selectedFolderIds) && !isEmpty(this.folders) && this.multiSelection) {
      const selectedFoldersDictionary = this.selectedFolderIds.reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
      this.setNodesCheckedProperty(this.dataSource.data[0] as FolderNode, selectedFoldersDictionary);
    }

    if (!isEmpty(this.folders)) {
      if (!isEmpty(folders) && folders.previousValue !== folders.currentValue) {
        this.rebuildFolderDictionary();
      }
      this.initialize();
    }

    if (!activeFolder?.currentValue && isEmpty(this.folders)) {
      this.reset();
    }

    if (
      !DataUtil.isEmpty(foldersAffectedByRestore) &&
      foldersAffectedByRestore.previousValue !== foldersAffectedByRestore.currentValue
    ) {
      this.refreshExpandedNodes();
    }
  }

  public initialize(): void {
    if (this.shouldInitRoot()) {
      this.activeFolderNode = undefined;
      this.initRoot();
    }
    const rootNode = this.updateRootNodeFolder();
    this.createTreeFromNode(this.dict, rootNode.folder.id);
    this.expandParents();
    this.refreshTree();
  }

  public getRootFolder(): Folder {
    return this.folders?.find(folder => isNullOrUndefined(folder.parentId));
  }

  public loadFromRoot(): FolderNode {
    const rootFolder = this.getRootFolder();
    return new FolderNode(rootFolder, this.disabledFolders.includes(rootFolder.id));
  }

  public setNodeChecked(node: FolderNode) {
    node.checked = this.selectedFolderIds.includes(node.folder.id);
  }

  public setNodesCheckedProperty(node: FolderNode, selectedFoldersDictionary: { [id: number]: boolean }) {
    node.checked = selectedFoldersDictionary[node.folder.id] ?? false;
    this.removeLoadMoreNode(node.subFolders);
    for (const childNode of node.subFolders) {
      this.setNodesCheckedProperty(childNode as FolderNode, selectedFoldersDictionary);
    }
  }

  public findNode(treeNodes: TreeNode[], id: string): FolderNode {
    if (treeNodes) {
      for (const treeNode of treeNodes) {
        if (!(treeNode instanceof FolderNode)) {
          continue;
        }
        const folderNode = treeNode as FolderNode;
        if (folderNode.folder.id === id) {
          return folderNode;
        }
      }
      for (const treeNode of treeNodes) {
        if (!(treeNode instanceof FolderNode)) {
          continue;
        }
        const folderNode = treeNode as FolderNode;
        if (folderNode.folder.hasSubfolders) {
          const res = this.findNode(folderNode.subFolders, id);
          if (res !== undefined) {
            return res;
          }
        }
      }
    }
    return undefined;
  }

  public loadChildren(node: FolderNode): void {
    if (this.treeControl.isExpanded(node)) {
      this.expand.emit({
        parentId: node.folder.id,
        limit: Math.max(
          node.subFolders.filter(folder => folder instanceof FolderNode).length || TREE_BATCH_SIZE,
          TREE_BATCH_SIZE
        )
      });
    }
  }

  public selectFolder(node: FolderNode, isBeingSelected = false): void {
    if (this.multiSelection) {
      this.selectedFolderIds = isBeingSelected
        ? [...this.selectedFolderIds, node.folder.id]
        : this.selectedFolderIds.filter(id => id !== node.folder.id);
      const selectedFolders = this.folders.filter(({ id }) => this.selectedFolderIds.includes(id));
      this.selectionChanged.emit(selectedFolders);
      node.checked = isBeingSelected;
    } else {
      this.selectionChanged.emit([node.folder]);
    }
  }

  public fetchNextBatch(node: LoadMoreNode): void {
    const parentNode = this.findNode(this.dataSource.data, node.parentId);
    this.fetchMore.emit({
      parentId: parentNode.folder.id,
      offset: parentNode.subFolders.filter(folder => folder instanceof FolderNode).length
    });
  }

  public toggle(node: FolderNode): void {
    if (this.treeControl.isExpanded(node)) {
      this.treeControl.collapse(node);
    } else {
      this.treeControl.expand(node);
      this.expand.emit({
        parentId: node.folder.id,
        limit: node.subFolders.length || TREE_BATCH_SIZE
      });
    }
  }

  public async handleContextMenu(event: MouseEvent, folder: Folder): Promise<void> {
    event.preventDefault();
    this.openContextMenu.emit({ x: event.clientX, y: event.clientY, folder });
  }

  private hasInputPropertyChanged(prop: any) {
    return prop?.currentValue && prop?.previousValue !== prop?.currentValue;
  }

  private refreshTree(): void {
    const newData = this.dataSource.data;
    this.dataSource.data = null;
    this.dataSource.data = newData;
  }

  private recreateNodes(subfolders: TreeNode[], elements: FolderNode[]): TreeNode[] {
    // all references have to be retained!
    this.removeLoadMoreNode(subfolders);
    elements.forEach(e => {
      const node: FolderNode = subfolders.find(
        subfolder => (subfolder as FolderNode).folder.id === e.folder.id
      ) as FolderNode;
      if (node) {
        node.folder = e.folder;
        node.checked = e.checked;
      } else {
        subfolders.push(e);
      }
    });

    let index = subfolders.length - 1;
    while (index >= 0) {
      if (!elements.find(e => e.folder.id === (subfolders[index] as FolderNode).folder.id)) {
        subfolders.splice(index, 1);
      }
      index -= 1;
    }

    if (this.sortFolders) {
      return subfolders.sort(
        (first: FolderNode, second: FolderNode) =>
          first.folder.name.localeCompare(second.folder.name) || sortAssetIds(first.folder.id, second.folder.id)
      );
    }

    return subfolders;
  }

  private removeLoadMoreNode(subfolders: TreeNode[]) {
    const loadMoreIndex = subfolders.findIndex(subfolder => subfolder instanceof LoadMoreNode);
    if (loadMoreIndex !== -1) {
      subfolders.splice(loadMoreIndex, 1);
    }
  }

  private rebuildFolderDictionary(): void {
    const dict: { [id: string]: Folder[] } = {};
    this.folders.forEach(folder => {
      if (notNullOrUndefined(folder.parentId)) {
        dict[folder.parentId] = [...(dict[folder.parentId] || []), folder];
      }
    });
    this.dict = dict;
  }

  private initRoot(): void {
    this.dataSource.data = [this.loadFromRoot()];
    this.treeControl.expand(this.dataSource.data[0]);
  }

  private createTreeFromNode(dict: { [id: number]: Folder[] }, nodeId: string): void {
    const node = this.findNode(this.dataSource.data, nodeId);
    if (!dict[nodeId] || !node) {
      if (node && this.treeControl.isExpanded(node) && !isEmpty(node.subFolders)) {
        this.treeControl.collapse(node);
      }
      return;
    }
    const subfolders = dict[nodeId];
    this.translateSubfolder(node, subfolders);
    subfolders.filter(folder => folder.hasSubfolders).forEach(folder => this.createTreeFromNode(dict, folder.id));
  }

  private translateSubfolder(node: FolderNode, subfolders: Folder[]): void {
    const selectedFoldersDictionary = this.selectedFolderIds.reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
    const disabledFoldersDictionary = this.disabledFolders.reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
    const nodes = subfolders.map(
      folder =>
        new FolderNode(
          folder,
          disabledFoldersDictionary[folder.id] ?? false,
          [],
          selectedFoldersDictionary[folder.id] ?? false
        )
    );
    node.subFolders = this.recreateNodes(node.subFolders, nodes);
    this.addLoadMoreNodeConditionally(node);
  }

  private addLoadMoreNodeConditionally(node: FolderNode) {
    if (
      this.paginationInformation[node.folder.id]?.hasBottom &&
      this.paginationInformation[node.folder.id].totalElementCount > node.subFolders.length
    ) {
      node.subFolders.push(new LoadMoreNode(node.folder.id, node.folder.parentId));
    }
  }

  private expandParents(): void {
    // TODO @jja not get error snackbar
    if (!this.activeFolderNode || !this.activeFolderNode.folder) {
      return;
    }
    let parentId = this.activeFolderNode.folder.parentId;
    while (parentId !== null) {
      const parentNode = this.findNode(this.dataSource.data, parentId);
      if (parentNode) {
        this.treeControl.expand(parentNode);
        parentId = parentNode.folder.parentId;
      }
    }
  }

  private updateRootNodeFolder() {
    const rootNode = this.dataSource.data[0] as FolderNode;
    rootNode.folder = this.getRootFolder();
    this.setNodeChecked(rootNode);
    return rootNode;
  }

  private reset(): void {
    this.dataSource.data = null;
  }

  private shouldInitRoot(): boolean {
    return (
      !this.dataSource.data ||
      this.dataSource.data.length === 0 ||
      (this.dataSource.data[0] as FolderNode).folder.hasSubfolders !== this.getRootFolder().hasSubfolders ||
      (this.dataSource.data[0] as FolderNode).folder.id !== this.getRootFolder().id
    );
  }

  private refreshExpandedNodes() {
    if (!this.foldersAffectedByRestore) {
      return;
    }
    this.foldersAffectedByRestore.forEach(folderId => {
      const folderNode = this.findNode(this.dataSource.data, folderId);
      if (folderNode) {
        this.loadChildren(folderNode);
      }
    });
  }
}

export class TreeNode {
  constructor(public item: string) {}
}

export class FolderNode extends TreeNode {
  constructor(
    public folder: Folder,
    public disabled = false,
    public subFolders: TreeNode[] = [],
    public checked = false
  ) {
    super('folder');
  }
}

export class LoadMoreNode extends TreeNode {
  constructor(
    public parentId: string,
    public rootParent: string
  ) {
    super('load_more');
  }
}
