import { createAction, props } from '@ngrx/store';

import { WorkroomGroup } from './workroom-group.model';

export const WorkroomGroupUpsertMany = createAction(
  '[WorkroomGroup] Upsert Many',
  props<{ workroomGroups: WorkroomGroup[] }>()
);

export const WorkroomGroupEditName = createAction(
  '[WorkroomGroup] Edit Name',
  props<{ workroomGroupId: number; currentName: string; previousName: string }>()
);

export const WorkroomGroupEditNameFailed = createAction(
  '[WorkroomGroup] Edit Name Failed',
  props<{ workroomGroupId: number; name: string }>()
);

export const WorkroomGroupDelete = createAction('[WorkroomGroup] Delete', props<{ group: WorkroomGroup }>());

export const WorkroomGroupDeleteSucceded = createAction(
  '[WorkroomGroup] Delete Succeded',
  props<{ group: WorkroomGroup }>()
);

export const WorkroomGroupDeleteFailed = createAction(
  '[WorkroomGroup] Delete Failed',
  props<{ group: WorkroomGroup }>()
);

export const WorkroomGroupMove = createAction(
  '[WorkroomGroup] Move',
  props<{ workroomGroupId: number; currentSort: number; previousSort: number }>()
);

export const WorkroomGroupMoveFailed = createAction(
  '[WorkroomGroup] Move Failed',
  props<{ workroomGroupId: number; sort: number }>()
);
