import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CelumPropertiesProvider } from '@celum/core';

import { PusherAuth, PusherAuthCreate } from './pusher/pusher-auth.model';

@Injectable({ providedIn: 'root' })
export class PusherAuthService {
  private readonly SLIB_AUTHORIZED_CHANNEL_PATTERNS: string[] = ['-topic-library[.].+[.]rendition', '-topic-reply[.]'];

  constructor(private httpClient: HttpClient) {}

  public authorizeChannel(pusherAuth: PusherAuthCreate): Observable<PusherAuth> {
    if (this.SLIB_AUTHORIZED_CHANNEL_PATTERNS.some(pattern => pusherAuth.channelName.match(pattern))) {
      return this.httpClient.post<PusherAuth>(
        `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/pusher/auth`,
        pusherAuth
      );
    } else {
      return this.httpClient.post<PusherAuth>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/pusher/auth`,
        pusherAuth
      );
    }
  }
}
