<celum-dialog cdkFocusInitial class="template-chooser" (onCloseAll)="closeDialog()">
  <celum-dialog-header class="template-chooser_header">{{ 'TEMPLATE_CHOOSER.DIALOG.HEADER' | translate }}</celum-dialog-header>
  <celum-dialog-content>
    <mat-sidenav-container class="template-chooser_container">
      <mat-sidenav mode="side" [disableClose]="true" opened class="template-chooser_categories category-list">
        <template-chooser-categories
          [activeCategory]="selectedCategoryId$ | async"
          [categories]="templateCategories$ | async"
          (onSelected)="setCategoryAsActive($event)"
          (onReset)="resetCategoryFilter()"
          (onRequestPersonal)="fetchPersonalTemplates()"></template-chooser-categories>
      </mat-sidenav>
      <mat-sidenav-content class="template-chooser_content">
        <div *ngIf="(selectedCategoryId$ | async) !== 'all'" class="template-chooser_organization-information">
          <organization-information [category]="activeCategory$ | async"
                                    [teamspace]="activeTeamspace$ | async"
                                    [hasCreateWorkroomPermission]="(activeCategory$| async)?.id | hasTemplateCategoryPermission: permissions.CREATE_WORKROOM | async">
          </organization-information>

          <div class="template-chooser_permission-license-check">
            <div *ngIf="((selectedCategoryId$ | async) | hasTemplateCategoryPermission: permissions.CREATE_WORKROOM | async) === false"
                 class="template-chooser-template-category_missing-permission">
              <celum-icon class="template-chooser-template-category_missing-permission_icon" [configuration]="iconInfoConfig"></celum-icon>
              <span
                class="template-chooser-template-category_missing-permission_label"> {{'TEMPLATE_CHOOSER.CREATE_WORKROOM.NO_PERMISSION' | translate}} </span>
            </div>

            <license-limit-reached
              *ngIf="((selectedCategoryId$ | async) | hasTemplateCategoryPermission: permissions.CREATE_WORKROOM | async)
                        && (((selectedCategoryId$ | async)| licenseLimitReached | async) || ((activeTeamspace$ | async)?.workroomsUsed === (activeTeamspace$ | async)?.workroomsLimit -1))"
              [showContactButton]="(activeCategory$ | async)?.id > 1"
              [teamspace]="activeTeamspace$ | async">
            </license-limit-reached>
          </div>
        </div>

        <section *ngIf="showCreateWorkroomButton$ | async" class="template-chooser_creation-buttons"
                 [class.template-chooser_creation-buttons--no-templates]="(templates$ | async)?.length === 0"
                 [class.template-chooser_creation-buttons--category]="isCategorySelected$ | async"
                 [class.template-chooser_creation-buttons--disabled]="(selectedCategoryId$ | async) | licenseLimitReached | async">
          <create-board-button
            [icon]="createWorkroomIcon"
            data-default-board-type="taskboard"
            [topText]="'TEMPLATE_CHOOSER.BLANK.DEFAULT_WORKROOM' | translate"
            [bottomText]="'TEMPLATE_CHOOSER.BLANK.NEW_TASKBOARD' | translate"
            [disabled]="(selectedCategoryId$ | async) | licenseLimitReached | async"
            (onHeaderClicked)="createDefaultWorkroom()">
          </create-board-button>
        </section>

        <div *ngIf="(templates$ | async)?.length > 0" class="template-chooser_content-header"
             [class.template-chooser_content-header--label-present]="
                ((activeCategory$ | async)?.id | hasTemplateCategoryPermission: permissions.CREATE_WORKROOM | async) === false
                || (((selectedCategoryId$ | async)| licenseLimitReached | async)
                || ((activeTeamspace$ | async)?.workroomsUsed === (activeTeamspace$ | async)?.workroomsLimit - 1))"
             [class.template-chooser_content-header--creation-buttons]="(showCreateWorkroomButton$ | async)">
          <p class="template-chooser_content-header_count"
             [innerHTML]="'TEMPLATE_CHOOSER.TEMPLATE_COUNT' | translate: { templateCount: (totalTemplateCount$ | async) }"></p>
          <button *ngIf="showCreateTemplateButton$ | async"
                  class="create-new-template-button"
                  celum-button
                  mat-flat-button
                  size="small"
                  (click)="createBlankTemplate()">
            <celum-icon [configuration]="createNewTemplateIcon"></celum-icon>
            {{'TEMPLATE_CHOOSER.CREATE_NEW_TEMPLATE.BUTTON' | translate}}
          </button>
        </div>

        <ng-container *ngIf="(workroomCreatorInitiating$ | async) === false; else loadingCategoryTmpl"></ng-container>

        <ng-container *ngIf="(templates$ | async)?.length; else emptyCategoryTmpl">
          <celum-simple-list
            class="template-chooser_content-templates"
            [items]="categoryIds$ | async"
            [itemTemplate]="itemTemplate"
            [hasMoreBottom]="hasMoreBottom$ | async"
            [hasMoreTop]="false"
            (requestNextPage)="onRequestNextPage($event)">

            <ng-template let-item #itemTemplate>
              <template-chooser-template-category [categoryId]="item"
                                                  [allTemplatesCategory]="(selectedCategoryId$ | async) === 'all'">
              </template-chooser-template-category>
            </ng-template>
          </celum-simple-list>
        </ng-container>

      </mat-sidenav-content>
    </mat-sidenav-container>
  </celum-dialog-content>
</celum-dialog>

<ng-template #loadingCategoryTmpl>
  <mat-progress-bar class="template-chooser_loader" color="primary" mode="indeterminate"></mat-progress-bar>
</ng-template>

<ng-template #emptyCategoryTmpl>
  <section class="empty-section">
    <celum-icon class="empty-section_icon" [configuration]="emptySectionIcon"></celum-icon>
    <h5 class="empty-section_heading">{{ 'TEMPLATE_CHOOSER.EMPTY_PAGE.TITLE' | translate }}</h5>
    <p class="empty-section_message">{{ 'TEMPLATE_CHOOSER.EMPTY_PAGE.DESCRIPTION' | translate }}</p>
    <button *ngIf="showCreateTemplateButton$ | async"
            class="create-new-template-button"
            celum-button
            mat-flat-button
            size="small"
            (click)="createBlankTemplate()">
      <celum-icon [configuration]="createNewTemplateIcon"></celum-icon>
      {{'TEMPLATE_CHOOSER.CREATE_NEW_TEMPLATE.BUTTON' | translate}}
    </button>
  </section>
</ng-template>
