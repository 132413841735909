import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';

import { DataUtil, isSimpleArrayEqual } from '@celum/core';
import {
  CommentCreateCommentSucceeded,
  CommentDeleteCommentSucceeded
} from '@celum/work/app/core/api/comment/comment.actions';
import {
  SubtaskConvertToTask,
  SubtaskConvertToTaskFailed,
  SubtaskCreateSubtask,
  SubtaskCreateSubtaskFailed,
  SubtaskDeleteSubtask,
  SubtaskDeleteSubtaskFailed,
  SubtaskHandleAssignee,
  SubtaskUpdateSubtaskSucceeded
} from '@celum/work/app/core/api/subtask/subtask.actions';
import { TaskAttachmentsAdded, TaskDeleteTaskContentItemsSucceeded } from '@celum/work/app/core/api/task/task.actions';
import { EntityUtil } from '@celum/work/app/core/model';
import { ItemContext } from '@celum/work/app/core/model/entities/activity/activity.model';
import { mergeEntities, mergeEntity } from '@celum/work/app/core/model/entities/entities-state-util';
import {
  SubtaskDeleteOne,
  SubtaskUpsertMany,
  SubtaskUpsertOne
} from '@celum/work/app/core/model/entities/subtask/subtask.actions';
import {
  TaskHandleAssignee,
  TasksDeleteMany,
  TasksDeleteOne,
  TasksUpsertMany,
  TasksUpsertOne
} from '@celum/work/app/core/model/entities/task/task.actions';
import { ContentItemContentItemsDeleted } from '@celum/work/app/files/store/content-item-list/content-item-list.actions';
import {
  AddFormToTaskSucceeded,
  DeleteCustomFormSucceeded,
  RemoveFormFromTaskSucceeded
} from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/custom-form/store/custom-form-state.actions';

import { SubtaskStatus, Task, taskBasicProperties, TaskState } from './task.model';

export const taskAdapter: EntityAdapter<Task> = createEntityAdapter<Task>();

export const initialState: TaskState = taskAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(TasksUpsertOne, SubtaskUpsertOne, (state: TaskState, { task, propertiesToUpdate }) => {
    const tasks = EntityUtil.changedEntities(taskBasicProperties, [task], state.entities, assigneesOrAttachmentChanged);

    if (!DataUtil.isEmpty(tasks)) {
      return taskAdapter.upsertOne(mergeEntity(tasks[0], state, propertiesToUpdate), state);
    } else {
      return state;
    }
  }),

  on(TasksUpsertMany, SubtaskUpsertMany, (state: TaskState, { tasks, propertiesToUpdate }) => {
    const newTasks = EntityUtil.changedEntities(
      taskBasicProperties,
      tasks,
      state.entities,
      assigneesOrAttachmentChanged
    );
    return taskAdapter.upsertMany(mergeEntities(newTasks, state, propertiesToUpdate), state);
  }),

  on(TasksDeleteOne, SubtaskDeleteOne, (state: TaskState, { task }) => {
    return taskAdapter.removeOne(task.id, state);
  }),

  on(TasksDeleteMany, (state: TaskState, { ids }) => {
    return taskAdapter.removeMany(ids, state);
  }),

  on(CommentDeleteCommentSucceeded, (state: TaskState, { comment }) => {
    const tasks = [];
    if (comment.context === ItemContext.TASK) {
      const task = state.entities[comment.objectId];
      const numberOfComments = task.numberOfComments - 1;
      tasks.push({
        ...task,
        numberOfComments
      });
    } else {
      state.ids.forEach(id => {
        const commentsOnAttachments = state.entities[id].attachmentIds.filter(
          attachmentId => attachmentId === comment.objectId
        );
        if (state.entities[id] && commentsOnAttachments.length === 1) {
          const task = state.entities[id];
          const numberOfComments = task.numberOfComments - 1;
          tasks.push({
            ...task,
            numberOfComments
          });
        }
      });
    }

    return taskAdapter.upsertMany(tasks, state);
  }),

  on(CommentCreateCommentSucceeded, (state: TaskState, { comment }) => {
    const tasks = [];
    if (comment.context === ItemContext.TASK) {
      const task = state.entities[comment.objectId];
      const numberOfComments = task.numberOfComments + 1;
      tasks.push({
        ...task,
        numberOfComments
      });
    } else {
      state.ids.forEach(id => {
        const commentsOnAttachments = state.entities[id].attachmentIds.filter(
          attachmentId => attachmentId === comment.objectId
        );
        if (state.entities[id] && commentsOnAttachments.length === 1) {
          const task = state.entities[id];
          const numberOfComments = task.numberOfComments + 1;
          tasks.push({
            ...task,
            numberOfComments
          });
        }
      });
    }
    return taskAdapter.upsertMany(tasks, state);
  }),
  on(TaskDeleteTaskContentItemsSucceeded, (state: TaskState, { taskId, contentItems }) => {
    return produce(state, draft => {
      const storedTask = draft.entities[taskId];
      storedTask.attachmentIds = storedTask.attachmentIds.filter(
        attachmentId => !contentItems.some(({ id }) => attachmentId === id)
      );

      if (storedTask.attachmentIds.length > 0) {
        if (storedTask.attachmentIds.indexOf(storedTask.previewFileId) === -1) {
          storedTask.previewFileId = storedTask.attachmentIds[0];
        }
      } else {
        delete storedTask.previewFileId;
      }
    });
  }),
  on(TaskAttachmentsAdded, (state: TaskState, { taskId, fileIds }) =>
    produce(state, draft => {
      const storedTask = draft.entities[taskId];
      storedTask.attachmentIds = [...fileIds, ...storedTask.attachmentIds];
      storedTask.previewFileId = storedTask.attachmentIds[0];
    })
  ),
  on(ContentItemContentItemsDeleted, (state: TaskState, { contentItemIds }) =>
    produce(state, draft => {
      draft.ids.forEach(id => {
        const storedTask = draft.entities[id];
        storedTask.attachmentIds = storedTask.attachmentIds.filter(fileId => !contentItemIds.includes(fileId));
        storedTask.previewFileId = storedTask.attachmentIds[0];
      });
    })
  ),
  on(TaskHandleAssignee, SubtaskHandleAssignee, (state: TaskState, { assigneeId, entityId, operation }) => {
    return produce(state, draft => {
      const task = draft.entities[entityId];
      if (operation === 'add') {
        task.assigneeIds.push(assigneeId);
      } else {
        task.assigneeIds = task.assigneeIds.filter(id => id !== assigneeId);
      }
    });
  }),
  on(SubtaskCreateSubtask, (state: TaskState, { tmpSubtask }) => {
    return produce(state, draft => {
      const task = draft.entities[tmpSubtask.parentId];
      if (!task.subtaskInformation) {
        task.subtaskInformation = {
          numberOfTotalSubtasks: 0,
          numberOfCompletedSubtasks: 0
        };
      }
      task.subtaskInformation.numberOfTotalSubtasks++;
    });
  }),
  on(SubtaskCreateSubtaskFailed, (state: TaskState, { tmpSubtask }) => {
    return produce(state, draft => {
      const task = draft.entities[tmpSubtask.parentId];
      task.subtaskInformation.numberOfTotalSubtasks--;
      if (task.subtaskInformation.numberOfTotalSubtasks === 0) {
        delete task.subtaskInformation;
      }
    });
  }),
  on(SubtaskDeleteSubtask, SubtaskConvertToTask, (state: TaskState, { subtask }) => {
    return produce(state, draft => {
      const task = draft.entities[subtask.parentId];
      task.subtaskInformation.numberOfTotalSubtasks--;
      if (subtask.status === SubtaskStatus.DONE) {
        task.subtaskInformation.numberOfCompletedSubtasks--;
      }
      if (task.subtaskInformation.numberOfTotalSubtasks === 0) {
        delete task.subtaskInformation;
      }
    });
  }),
  on(SubtaskDeleteSubtaskFailed, SubtaskConvertToTaskFailed, (state: TaskState, { subtask }) => {
    return produce(state, draft => {
      const task = draft.entities[subtask.parentId];
      if (!task.subtaskInformation) {
        task.subtaskInformation = {
          numberOfTotalSubtasks: 0,
          numberOfCompletedSubtasks: 0
        };
      }
      task.subtaskInformation.numberOfTotalSubtasks++;
      if (subtask.status === SubtaskStatus.DONE) {
        task.subtaskInformation.numberOfCompletedSubtasks++;
      }
    });
  }),
  on(SubtaskUpdateSubtaskSucceeded, (state: TaskState, { oldSubtask, subtask }) => {
    return produce(state, draft => {
      const task = draft.entities[subtask.parentId];
      if (oldSubtask.status !== subtask.status) {
        if (subtask.status === SubtaskStatus.DONE) {
          task.subtaskInformation.numberOfCompletedSubtasks++;
        } else {
          task.subtaskInformation.numberOfCompletedSubtasks--;
        }
      }
    });
  }),
  on(AddFormToTaskSucceeded, (state: TaskState, { form }) => {
    return produce(state, draft => {
      const task = draft.entities[form.taskId];
      task.customFormId = form.customFormId;
    });
  }),
  on(RemoveFormFromTaskSucceeded, (state: TaskState, { taskId }) => {
    return produce(state, draft => {
      const task = draft.entities[taskId];
      task.customFormId = null;
    });
  }),
  on(DeleteCustomFormSucceeded, (state: TaskState, { formId }) => {
    return produce(state, draft => {
      draft.ids.forEach(id => {
        const task = draft.entities[id];
        if (task.customFormId === formId) {
          task.customFormId = null;
        }
      });
    });
  })
);

export function taskReducer(state: TaskState | undefined, action: Action) {
  return reducer(state, action);
}

function assigneesOrAttachmentChanged(oldTask: Task, newTask: Task): boolean {
  return (
    isSimpleArrayEqual(oldTask.assigneeIds, newTask.assigneeIds) &&
    elementsAreOrdered(oldTask.attachmentIds, newTask.attachmentIds)
  );
}

function elementsAreOrdered(oldIds: string[], newIds: string[]): boolean {
  if (isSimpleArrayEqual(oldIds, newIds)) {
    for (let i = 0; i < oldIds.length; i++) {
      if (oldIds[i] !== newIds[i]) {
        return false;
      }
    }
  } else {
    return false;
  }

  return true;
}
