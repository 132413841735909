import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, takeUntil } from 'rxjs';

import { EmptyPage, EmptyPageConfig, SortDirection } from '@celum/common-components';
import { ReactiveService } from '@celum/core';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { selectWorkroomEntities, Workroom } from '@celum/work/app/core/model/entities/workroom';
import { VirtualScrollTableService } from '@celum/work/app/shared/components/virtual-scroll-table/virtual-scroll-table.base';
import { WindowResizeService } from '@celum/work/app/shared/util';

import { WorkroomsManagementService } from '../workrooms-management.service';

@Injectable()
export class WorkroomsVirtualScrollTableService extends ReactiveService implements VirtualScrollTableService<Workroom> {
  public readonly items$: Observable<Workroom[]>;
  public readonly emptyPageConfig: EmptyPageConfig;
  public readonly isEmpty$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;
  public readonly stickyRowConfig: string[];

  public lastEmitCount: number;
  public itemSize: number;

  constructor(
    private store: Store<any>,
    public workroomManagementService: WorkroomsManagementService
  ) {
    super();

    this.items$ = combineLatest([
      this.workroomManagementService.workroomsIds$,
      this.store.select(selectWorkroomEntities)
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([workroomIds, workroomEntities]) => workroomIds.map(id => workroomEntities[id]).filter(wr => !!wr))
    );

    this.emptyPageConfig = EmptyPage.noActionConfig(
      'no-workrooms',
      'no-workrooms',
      'TEAMSPACE_MANAGEMENT.WORKROOMS.EMPTY',
      'normal',
      259
    );
    this.emptyPageConfig.iconConfiguration.iconHeight = 188;

    this.isEmpty$ = combineLatest([
      this.workroomManagementService.isLoading$,
      this.workroomManagementService.hasWorkrooms$
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([isLoading, hasWorkrooms]) => !isLoading && !hasWorkrooms)
    );

    this.loading$ = this.workroomManagementService.isLoading$;

    this.stickyRowConfig = this.generateStickyRowConfig();

    this.itemSize = 60;
  }

  public loadItems(): void {
    this.workroomManagementService.loadWorkrooms({
      paging: Paging.of(0, this.getBatchSize()),
      sorting: Sorting.of('name', SortDirection.ASC)
    });
  }

  public onRequestNextPage(viewport: CdkVirtualScrollViewport): void {
    if (
      viewport &&
      (!this.workroomManagementService.paginationResult || this.workroomManagementService.paginationResult.hasBottom)
    ) {
      const end = viewport.getRenderedRange().end;
      const total = viewport.getDataLength();

      if (end >= total && end !== this.lastEmitCount) {
        // if scrolled while loading, this would emit multiple times - so remember last end count of items and check if it changed
        this.lastEmitCount = end;
        this.loadNextBatch();
      }
    }
  }

  public loadNextBatch(): void {
    this.workroomManagementService.loadNextBatch();
  }

  public getBatchSize(): number {
    return WindowResizeService.calculateBatchSize({
      elementSize: this.itemSize * window.innerWidth,
      valueModifier: { height: -130 }
    });
  }

  private generateStickyRowConfig(): string[] {
    return [
      'TEAMSPACE_MANAGEMENT.WORKROOMS.TABLE.HEADERS.NAME',
      'TEAMSPACE_MANAGEMENT.WORKROOMS.TABLE.HEADERS.FILES',
      'TEAMSPACE_MANAGEMENT.WORKROOMS.TABLE.HEADERS.TASKS',
      'TEAMSPACE_MANAGEMENT.WORKROOMS.TABLE.HEADERS.PEOPLE',
      'TEAMSPACE_MANAGEMENT.WORKROOMS.TABLE.HEADERS.DUE_DATE',
      'TEAMSPACE_MANAGEMENT.WORKROOMS.TABLE.HEADERS.LAST_ACTIVITY'
    ];
  }
}
