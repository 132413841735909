import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CelumPropertiesProvider } from '@celum/core';
import { SubsequentActionType } from '@celum/work/app/core/model/subsequent-action.model';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

import { ContentHubAssetTicketDTO } from '../model/content-hub.model';
import { ImportIntent } from '../model/import-intent.model';

@Injectable({ providedIn: 'root' })
export class ImportIntentService {
  constructor(private httpClient: HttpClient) {}

  public getImportIntent(importIntentId: string): Observable<ImportIntent> {
    return this.httpClient.get<ImportIntent>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/content-hub/intent/import/${importIntentId}`
    );
  }

  public consume(
    workroomId: number,
    importIntentId: string,
    folderId: string,
    watch: boolean,
    action: SubsequentActionType
  ): Observable<ContentHubAssetTicketDTO[]> {
    return this.httpClient.post<ContentHubAssetTicketDTO[]>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/content-hub/intent/import/${importIntentId}/consume`,
      {
        workroomId,
        folderId,
        watch,
        action
      },
      STRONGLY_CONSISTENT_OPTION
    );
  }
}
