import { DOCUMENT } from '@angular/common';
import { Directive, HostBinding, Inject, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { DataContext } from '@celum/core';
import { CelumDialogOpener, CelumSimpleList } from '@celum/internal-components';
import { ContentHubBuildNumberStrategyResolverService } from '@celum/work/app/content-hub/services/content-hub-build-number-strategy-resolver.service';
import {
  ContentItemDeleteContentItems,
  ContentItemMoveContentItems,
  ContentItemRestoreContentItems,
  ContentItemSelected
} from '@celum/work/app/files/store/content-item-list/content-item-list.actions';
import { ContentItemPermanentlyDeleteDialogOpener } from '@celum/work/app/pages/workroom/pages/files/services/content-item-permanently-delete-dialog-opener';
import {
  SelectFolderDialogComponent,
  SelectFolderDialogConfiguration,
  SelectFolderDialogResult
} from '@celum/work/app/shared/components/select-folder-dialog/select-folder-dialog.component';

import { selectCanImport } from '../../../content-hub/store/content-hub.selectors';
import { ContentItem, ContentItemStatus } from '../../../core/model/entities/content-item/content-item.model';
import { File, FileType } from '../../../core/model/entities/file/file.model';
import { FolderType } from '../../../core/model/entities/folder/folder.model';
import { ShareSelectionType, ShareService } from '../../services/share.service';
import { ShareDialogActions } from '../../store/share-dialog/share-dialog.actions';

@Directive()
export abstract class ContentItemBase<T extends ContentItem> {
  @Input() public contentItem: T;
  @Input() public small = false;
  @Input() public active = true;
  @Input() public isInSelection: boolean;
  @Input() public isModerator = true;
  @Input() public selectionContainsFoldersWithRobots = false;

  public readonly deleteIcon = IconConfiguration.small('remove-m');
  public readonly restoreIcon = IconConfiguration.small('icon-16-time-set-up');
  public readonly moveIcon = IconConfiguration.small('move-x').withColor(ColorConstants.BLUE_GRAY_900);
  public readonly contentHubIcon = IconConfiguration.small('content-hub-icon')
    .withColor(ColorConstants.BLUE_GRAY_500)
    .withIconSize(14);
  public readonly chImportIcon = IconConfiguration.small('import').withColor(ColorConstants.BLUE_GRAY_900);
  public readonly chExportIcon = IconConfiguration.small('export').withColor(ColorConstants.BLUE_GRAY_900);

  public sharingEnabled: boolean;

  @ViewChild(MatMenuTrigger) public matMenuTrigger: MatMenuTrigger;

  constructor(
    protected celumDialogOpener: CelumDialogOpener,
    public celumSimpleList: CelumSimpleList<T>,
    public store: Store<any>,
    public translateService: TranslateService,
    public contentItemPermanentlyDeleteDialogOpener: ContentItemPermanentlyDeleteDialogOpener,
    public chStrategyResolver: ContentHubBuildNumberStrategyResolverService,
    public shareService: ShareService,
    @Inject(DOCUMENT) public document: Document
  ) {
    this.sharingEnabled = (this.document.defaultView.window as any).Celum.properties.features.sharing;
  }

  @HostBinding('attr.data-cy')
  public get attrDataCy(): string {
    return `content-item-card-${this.contentItem?.id}`;
  }

  public get canImportFromCH$(): Observable<boolean> {
    return this.store.select(selectCanImport).pipe(
      withLatestFrom(this.chStrategyResolver.resolve()),
      map(([canImport, strategy]) => canImport && this.getSelectionLength() <= 1 && strategy.supportsRelayCalls())
    );
  }

  public get isSoftDeleted(): boolean {
    return this.contentItem.status === ContentItemStatus.SOFT_DELETED;
  }

  public get isDeletionDisabled(): boolean {
    return !this.isModerator && this.selectionContainsFoldersWithRobots;
  }

  public checkCanRightClick(item: T): boolean {
    const selection = this.celumSimpleList.selectionHandler?.getCurrentSelection() ?? [];
    return selection && (!selection.length || this.celumSimpleList.selectionHandler?.isInSelection(item));
  }

  public moveItem(item: ContentItem): void {
    const contentItems = this.getSelection()?.length > 0 ? this.getSelection() : [item];
    this.celumDialogOpener
      .showDialog('move-items', SelectFolderDialogComponent, new SelectFolderDialogConfiguration(contentItems))
      .then((result: SelectFolderDialogResult) => {
        if (result) {
          this.store.dispatch(ContentItemSelected({ contentItems: [] }));
          this.store.dispatch(
            ContentItemMoveContentItems({
              targetFolder: result.folder,
              contentItemIds: contentItems.map(contentItem => contentItem.id)
            })
          );
        }
      });
  }

  public permanentlyDeleteItem(item: ContentItem): void {
    const contentItems = this.getSelection()?.length > 0 ? this.getSelection() : [item];
    const dataContext: DataContext = {
      getEntityTypes: () => contentItems.map(({ entityType }) => entityType),
      getSelection: () => contentItems
    };
    this.contentItemPermanentlyDeleteDialogOpener.showDialog(dataContext).then(res => {
      if (res) {
        this.store.dispatch(
          ContentItemDeleteContentItems({
            contentItemIds: contentItems.map(({ id }) => id),
            permanently: true
          })
        );
      }
    });
  }

  public restoreItem(item: ContentItem): void {
    const contentItems = this.getSelection()?.length > 0 ? this.getSelection() : [item];
    this.store.dispatch(ContentItemRestoreContentItems({ contentItemIds: contentItems.map(({ id }) => id) }));
  }

  public getSelectionLength(): number {
    return this.getSelection().length;
  }

  public getSelection(): T[] {
    if (!this.celumSimpleList || !this.celumSimpleList.selectionHandler) {
      return [];
    }
    return this.celumSimpleList.selectionHandler.getCurrentSelection();
  }

  public selectionHasPortalAssets(): boolean {
    return this.getSelection().some(item => (item as unknown as File).hasLinkedPortalAssets);
  }

  public getMoveLabel(typeKey: string): string {
    if (this.getSelectionLength() === 0 || this.getSelectionLength() === 1) {
      return this.translateService.instant(typeKey === FileType.TYPE_KEY ? 'FILES.DIALOG.MOVE' : 'FOLDER.DIALOG.MOVE');
    }

    if (this.allSelectionsOfType(typeKey)) {
      return this.translateService.instant(
        typeKey === FileType.TYPE_KEY ? 'FILES.DIALOG.MOVE_PLURAL' : 'FOLDER.DIALOG.MOVE_PLURAL'
      );
    } else {
      return this.translateService.instant('CONTENT_ITEMS.MOVE_ITEMS');
    }
  }

  public getDeleteTooltip(typeKey: string): string {
    if (typeKey === FolderType.TYPE_KEY && this.allSelectionsOfType(typeKey)) {
      return this.translateService.instant('FOLDER.DIALOG.DELETE.TOOLTIP');
    }

    return this.translateService.instant('CONTENT_ITEMS.DELETE_ITEMS_TOOLTIP');
  }

  public getDeleteLabel(typeKey: string): string {
    if (this.getSelectionLength() === 0 || this.getSelectionLength() === 1) {
      return this.translateService.instant(
        typeKey === FileType.TYPE_KEY ? 'FILES.DIALOG.DELETE.SINGLE' : 'FOLDER.DIALOG.DELETE.SINGLE'
      );
    }

    if (this.allSelectionsOfType(typeKey)) {
      return this.translateService.instant(
        typeKey === FileType.TYPE_KEY ? 'FILES.DIALOG.DELETE.MULTIPLE' : 'FOLDER.DIALOG.DELETE.MULTIPLE'
      );
    } else {
      return this.translateService.instant('CONTENT_ITEMS.DELETE_ITEMS');
    }
  }

  public getPermanentDeleteLabel(typeKey: string): string {
    if (this.getSelectionLength() === 0 || this.getSelectionLength() === 1) {
      return this.translateService.instant(
        typeKey === FileType.TYPE_KEY ? 'TRASH_BIN.OPERATIONS.DELETE_FILE' : 'TRASH_BIN.OPERATIONS.DELETE_FOLDER'
      );
    }

    if (this.allSelectionsOfType(typeKey)) {
      return this.translateService.instant(
        typeKey === FileType.TYPE_KEY ? 'TRASH_BIN.OPERATIONS.DELETE_FILES' : 'TRASH_BIN.OPERATIONS.DELETE_FOLDERS'
      );
    } else {
      return this.translateService.instant('TRASH_BIN.OPERATIONS.DELETE_ITEMS');
    }
  }

  public getRestorationLabel(typeKey: string): string {
    if (this.getSelectionLength() === 0 || this.getSelectionLength() === 1) {
      return this.translateService.instant(
        typeKey === FileType.TYPE_KEY ? 'TRASH_BIN.OPERATIONS.RESTORE_FILE' : 'TRASH_BIN.OPERATIONS.RESTORE_FOLDER'
      );
    }

    if (this.allSelectionsOfType(typeKey)) {
      return this.translateService.instant(
        typeKey === FileType.TYPE_KEY ? 'TRASH_BIN.OPERATIONS.RESTORE_FILES' : 'TRASH_BIN.OPERATIONS.RESTORE_FOLDERS'
      );
    } else {
      return this.translateService.instant('TRASH_BIN.OPERATIONS.RESTORE_ITEMS');
    }
  }

  public allSelectionsOfType(typeKey: string): boolean {
    return this.getSelection().every(selectedItem => selectedItem.entityType.id === typeKey);
  }

  public getShareTranslationKey(): string {
    const selectedItemsType: ShareSelectionType = this.shareService.getSelectionType(this.getSelection());
    return this.shareService.getTranslationKey(selectedItemsType, this.getSelection());
  }

  public createShare(file?: File, skipDialog?: boolean): void {
    const contentItems = this.getSelection();

    if (this.shareService.getSelectionType(this.getSelection()) === ShareSelectionType.FILES) {
      this.store.dispatch(
        ShareDialogActions.OpenShareDialogForFiles({
          filesToShare: this.getSelectionLength() > 0 ? (contentItems as any as File[]) : [file], // if length is 0 then it was selected in task-detail view, that's why we need [file]
          skipDialog
        })
      );
    } else {
      this.store.dispatch(
        ShareDialogActions.OpenShareDialogForFoldersAndFiles({ itemsToShare: this.getSelection(), skipDialog })
      );
    }
  }
}
