import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { RenditionTypes } from '@celum/work/app/core/model/entities/file/file.model';
import { Rendition } from '@celum/work/app/core/model/entities/rendition/rendition.model';
import { PreviewUtil, WorkroomConstants } from '@celum/work/app/shared/util';

import { FileFormat } from '../../core/model/entities/file-version/file-version.model';

export interface PreviewUrlPipeArgs {
  renditions: Rendition[];
  fileName: string;
  targetRenditionTypes?: RenditionTypes[];
  uploadedDate: number;
}

@Pipe({
  name: 'previewUrl'
})
export class PreviewUrlPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform({ renditions, fileName, targetRenditionTypes, uploadedDate }: PreviewUrlPipeArgs): string {
    let previewRendition: Rendition;

    if (targetRenditionTypes) {
      previewRendition = renditions?.filter(rendition => targetRenditionTypes.includes(rendition.type)).pop();
    } else {
      // CH renditions type is "waveform". so we have to add this originalFormat is AUDIO check.
      // We need a rendition entity to determine showing 3 dots.
      previewRendition = renditions
        ?.filter(value => PreviewUtil.isCardPreview(value) || value?.originalFormat === FileFormat.AUDIO)
        .pop();
    }

    // CH and sass rendition dtos are different a bit, so we have to do this cool check :)
    if (previewRendition?.type === RenditionTypes.AUDIO || previewRendition?.originalFormat === FileFormat.AUDIO) {
      const chFileDeleted = previewRendition.downloadUrl.includes(WorkroomConstants.DISCONNECTED_FROM_CH);
      return chFileDeleted
        ? WorkroomConstants.FILE_DELETE_ON_CH[this.translateService.currentLang]
        : PreviewUtil.getPreviewFile(fileName, WorkroomConstants.AUDIO_PREVIEW);
    }
    return PreviewUtil.getFileDownloadURL(previewRendition, fileName, this.translateService.currentLang, uploadedDate);
  }
}
