import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, shareReplay, switchMap } from 'rxjs/operators';

import { File, FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { selectFileById } from '@celum/work/app/core/model/entities/file/file.selectors';
import { FileDetailDialogData } from '@celum/work/app/pages/workroom/pages/files/pages/file-detail/components/file-detail-dialog/model/file-detail-dialog-data.model';
import { selectFileForDetail } from '@celum/work/app/pages/workroom/pages/files/pages/file-detail/store/file-detail.selectors';

@Component({
  selector: 'file-detail-dialog',
  templateUrl: './file-detail-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FileDetailDialogComponent implements OnInit {
  public file$: Observable<File>;

  constructor(
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public dialogData: FileDetailDialogData
  ) {}

  public ngOnInit() {
    this.file$ = this.dialogData.fileId$.pipe(
      switchMap(fileId =>
        fileId ? this.store.select(selectFileById(fileId)) : this.store.select(selectFileForDetail)
      ),
      filter(file => !!file),
      distinctUntilChanged(FileType.equalsByIdAndActiveVersionIdAndName),
      shareReplay(1)
    );
  }
}
