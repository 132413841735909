import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validator,
  Validators
} from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { CustomFieldDropdownOption } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { SharedModule } from '@celum/work/app/shared';
import { StringUtil } from '@celum/work/app/shared/util/string.util';

import { CustomFieldChipsInputValidationErrors } from './config/custom-field-chips-input-errors.enum';
import { customFieldChipsInputValidator } from './config/custom-field-chips-input.validator';
import { CustomFieldCardChipsComponent } from './custom-field-card-chips/custom-field-card-chips.component';
import { ChipNameCustomErrorStateMatcher } from '../config/chip-name-custom-error-matcher';

@Component({
  selector: 'custom-field-chips-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    SharedModule,
    FormsModule,
    CustomFieldCardChipsComponent,
    MatTooltipModule,
    TranslateModule,
    MatInputModule,
    CelumIconModule
  ],
  templateUrl: './custom-field-chips-input.component.html',
  styleUrls: ['./custom-field-chips-input.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldChipsInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldChipsInputComponent),
      multi: true
    }
  ]
})
export class CustomFieldChipsInputComponent
  extends ReactiveComponent
  implements OnInit, ControlValueAccessor, Validator
{
  public inputAndChipsForm = new FormGroup({
    chipName: new FormControl<string>(null, [Validators.required]),
    chips: new FormControl<CustomFieldDropdownOption[]>(null, [Validators.required])
  });
  public controls = {
    chipName: this.inputAndChipsForm.controls.chipName,
    chips: this.inputAndChipsForm.controls.chips
  };
  public chipNameCustomErrorStateMatcher = new ChipNameCustomErrorStateMatcher(this.controls.chips);
  public onChangeFn: (value: CustomFieldDropdownOption[]) => void;
  public onTouchFn: () => void;

  public readonly infoIcon = IconConfiguration.small('info').withIconSize(12);

  public readonly CHIPS_LIMIT = 50;
  public readonly TEXT_CONTROL_LIMIT = 30;

  constructor(
    private cdr: ChangeDetectorRef,
    private stringUtil: StringUtil
  ) {
    super();
  }

  public ngOnInit(): void {
    this.controls.chips.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(chips => {
      this.onChangeFn(chips);
      this.updateDisabledStateBasedOnLimit(chips, this.CHIPS_LIMIT);
    });
  }

  public writeValue(value: CustomFieldDropdownOption[] | null): void {
    if (value) {
      this.inputAndChipsForm.controls.chips.setValue(value, { emitEvent: false });
      this.cdr.detectChanges();
    }
  }

  public registerOnChange(fn: (value: CustomFieldDropdownOption[]) => void): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchFn = fn;
  }

  public validate(): CustomFieldChipsInputValidationErrors {
    return customFieldChipsInputValidator()(this.inputAndChipsForm);
  }

  public chipNameSubmitted(event: KeyboardEvent): void {
    event.preventDefault();

    const text = (event.target as any).value as string;
    const chips = this.controls.chips.value ?? [];
    const lowercasedChips = chips.map(({ id, name }) => ({ id, name: name.toLowerCase() }));
    const trimmedText = this.stringUtil.collapseWhitespacesBetweenWords(text.trim());

    this.controls.chipName.setValue('', { emitEvent: false });

    if (
      trimmedText !== '' &&
      !lowercasedChips.find(chip => chip.name === trimmedText.toLowerCase()) &&
      chips.length < this.CHIPS_LIMIT
    ) {
      this.controls.chips.setValue([...chips, { id: -1, name: trimmedText }]);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.inputAndChipsForm.disable({ emitEvent: false })
      : this.inputAndChipsForm.enable({ emitEvent: false });
    this.cdr.detectChanges();
  }

  private updateDisabledStateBasedOnLimit(chips: CustomFieldDropdownOption[], limit: number): void {
    if (chips.length === limit) {
      this.controls.chipName.disable({ emitEvent: false });
    }

    if (this.controls.chipName.disabled && chips.length < limit) {
      this.controls.chipName.enable({ emitEvent: false });
    }

    this.cdr.detectChanges();
  }
}
