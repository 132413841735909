import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CelumPropertiesProvider, DataUtil, PaginationResult } from '@celum/core';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo, Paging, Sorting } from '@celum/work/app/core/model';
import { CustomFieldType } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { CustomForm, CustomFormType } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { PersonType } from '@celum/work/app/core/model/entities/person/index';
import { TaskFormDTO } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/custom-form/model/task-form.model';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';
import { isNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

export interface CreateFormField {
  fieldId: number;
  sort: number;
}

@Injectable({ providedIn: 'root' })
export class CustomFormsService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public loadCustomForms(
    paging: Paging,
    sorting: Sorting,
    name?: string
  ): Observable<{ customForms: CustomForm[]; paginationResult: PaginationResult }> {
    const body = { paging, sorting, name };

    const resultsSchema = {
      results: [CustomFormType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, CustomFieldType.TYPE_KEY] })]
    };

    const metaInfo = MetaInfo.of(
      [CustomFormType.TYPE_KEY, CustomFieldType.TYPE_KEY, PersonType.TYPE_KEY],
      resultsSchema,
      [CustomFormType.TYPE_KEY],
      'results'
    );

    return this.httpClient.post(`${CelumPropertiesProvider.properties.httpBaseAddress}/forms/search`, body).pipe(
      map(res => {
        const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
        return {
          customForms: entitiesResult.entities[CustomFormType.TYPE_KEY] as CustomForm[],
          paginationResult: entitiesResult.paginationResult
        };
      })
    );
  }

  public getCustomFormById(formId: number): Observable<CustomForm> {
    const metaInfo = MetaInfo.of(
      [CustomFormType.TYPE_KEY, CustomFieldType.TYPE_KEY, PersonType.TYPE_KEY],
      CustomFormType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, CustomFieldType.TYPE_KEY] })
    );
    return this.httpClient.get(`${CelumPropertiesProvider.properties.httpBaseAddress}/forms/${formId}`).pipe(
      map(res => {
        const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
        const customForms = entitiesResult.entities[CustomFormType.TYPE_KEY];
        return DataUtil.isEmpty(customForms) ? null : (customForms[0] as CustomForm);
      })
    );
  }

  public getCustomFormCount(): Observable<number> {
    return this.httpClient
      .get(CelumPropertiesProvider.properties.httpBaseAddress + '/forms/counter')
      .pipe(map((res: any) => res.count));
  }

  public createCustomForm(name: string, fields: CreateFormField[]): Observable<CustomForm> {
    const metaInfo = MetaInfo.of(
      [CustomFormType.TYPE_KEY, CustomFieldType.TYPE_KEY, PersonType.TYPE_KEY],
      CustomFormType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, CustomFieldType.TYPE_KEY] })
    );

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/forms`, { name, fields }, STRONGLY_CONSISTENT_OPTION)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          const customForms = entitiesResult.entities[CustomFormType.TYPE_KEY];
          return DataUtil.isEmpty(customForms) ? null : (customForms[0] as CustomForm);
        })
      );
  }

  public deleteCustomForm(formId: number): Observable<void> {
    return this.httpClient.delete<void>(`${CelumPropertiesProvider.properties.httpBaseAddress}/forms/${formId}`);
  }

  public getTaskFormById(taskId: number, formId: number): Observable<TaskFormDTO> {
    return this.httpClient.get<TaskFormDTO>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/forms/${formId}`
    );
  }

  public attachFormToTask(taskId: number, formId: number): Observable<TaskFormDTO> {
    return this.httpClient.post<TaskFormDTO>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/forms/${formId}`,
      {}
    );
  }

  public removeFormFromTask(taskId: number, formId: number): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders()
    };

    return this.httpClient.delete<void>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/forms/${formId}`,
      httpOptions
    );
  }

  public updateCustomFormFieldValue(taskId: number, formId: number, fieldId: number, value: any): Observable<void> {
    const payload = isNullOrUndefined(value) || value === '' ? { value: null } : { value: { data: value } };

    return this.httpClient.put<void>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/forms/${formId}/fields/${fieldId}`,
      payload
    );
  }
}
