import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { MandatoryAssignmentRuleComponent } from './mandatory-assignment-rule.component';
import { Robots, RuleType } from '../../../../core/model/entities/workroom/robot.model';
import { RobotChipConfig } from '../../../services/robots-factory';
import { BaseRobotConfigService } from '../base-robot/base-robot-config.service';

@Injectable()
export class MandatoryAssignmentRuleConfigService extends BaseRobotConfigService {
  constructor(public translate: TranslateService) {
    super(translate);
  }

  public serviceMatchesRobot(robot: Robots): boolean {
    return robot.type === RuleType.TASK_MANDATORY_ASSIGNMENT;
  }

  public getRobotChipConfig(robot: Robots, soureEntity?: any): Observable<Partial<RobotChipConfig>> {
    return of({
      row1: this.translate.instant(`ROBOTS.CHIP.FIRST_ROW.${robot.type}`),
      row2: this.translate.instant('ROBOTS.CHIP.SECOND_ROW.TASK_MOVED'),
      editEvent: {
        robot,
        type: MandatoryAssignmentRuleComponent
      }
    });
  }
}
