import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, switchMap, tap, withLatestFrom } from 'rxjs';

import { PaginationResult } from '@celum/core';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { CustomField } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { DictionaryListState } from '@celum/work/app/core/model/list.model';
import { CustomFieldsService } from '@celum/work/app/teamspace-management/components/fields-overview-tab/services/custom-fields.service';

export interface CustomFieldsState extends DictionaryListState {
  hasMore: boolean;
  hasMoreAmount: number;
  selectedFieldIds: number[];
}

export const initialState: CustomFieldsState = {
  entityIds: [],
  loading: true,
  hasMore: false,
  hasMoreAmount: 0,
  selectedFieldIds: []
};

@Injectable()
export class SearchAndSelectCustomFieldsStore extends ComponentStore<CustomFieldsState> {
  public readonly customFieldIds$: Observable<number[]> = this.select(state => state.entityIds);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);
  public readonly hasMore$: Observable<boolean> = this.select(state => state.hasMore);
  public readonly hasMoreAmount$: Observable<number> = this.select(state => state.hasMoreAmount);
  public readonly selectedFieldIds$: Observable<number[]> = this.select(state => state.selectedFieldIds);

  public readonly setLoading = this.updater((state, loading: boolean) => ({ ...state, loading }));
  public readonly resetState = this.updater(state => ({
    ...state,
    entityIds: [],
    customFields: [],
    hasMore: false,
    hasMoreAmount: 0
  }));

  public readonly setSelectedFieldIds = this.updater((state, selectedFieldIds: number[]) => ({
    ...state,
    selectedFieldIds
  }));

  public readonly setLoadedCustomFieldsData = this.updater(
    (
      state,
      { customFields, paginationResult }: { customFields: CustomField[]; paginationResult: PaginationResult }
    ) => {
      const customFieldsIds = customFields.map(field => field.id);
      const entityIds = [...state.entityIds, ...customFieldsIds];

      return {
        ...state,
        entityIds,
        hasMore: paginationResult.hasBottom,
        hasMoreAmount: paginationResult.totalElementCount - customFields.length
      };
    }
  );

  public readonly loadCustomFields$ = this.effect(
    ($config: Observable<{ offset: number; paging: Paging; sorting: Sorting; name: string }>) =>
      $config.pipe(
        tap(({ offset }) => {
          this.setLoading(true);

          if (offset === 0) {
            this.resetState();
          }
        }),
        withLatestFrom(this.selectedFieldIds$),
        switchMap(([config, selectedFieldIds]) =>
          this.customFieldsService.loadCustomFields(config.paging, config.sorting, config.name, selectedFieldIds).pipe(
            tapResponse(
              response => {
                this.setLoading(false);
                this.setLoadedCustomFieldsData(response);
              },
              () => this.setLoading(false)
            )
          )
        )
      )
  );

  constructor(private customFieldsService: CustomFieldsService) {
    super(initialState);
  }
}
