import { createAction, props } from '@ngrx/store';

import { Template } from '@celum/work/app/core/model/entities/template/template.model';
import { Workroom, WorkroomTemplateInputParams } from '@celum/work/app/core/model/entities/workroom';

import { Folder } from '../../model/entities/folder/folder.model';

export const WorkroomCreateWorkroom = createAction(
  '[Workroom] Add New Workroom',
  props<{ data: WorkroomTemplateInputParams; subscriptionId?: string }>()
);

export const WorkroomCreateWorkroomSucceeded = createAction(
  '[Workroom] Add New Workroom Success',
  props<{ workroom: Workroom }>()
);

export const WorkroomCreateWorkroomFailed = createAction('[Workroom] Add New Workroom Failed');

export const WorkroomUpdateWorkroomName = createAction(
  '[Workroom] Update Workroom Name',
  props<{ newName: string; workroom: Workroom; folder?: Folder }>()
);

export const WorkroomFinishWorkroom = createAction(
  '[Workroom] Finish Workroom',
  props<{ workroom: Workroom; keepContent: boolean; optimistic?: boolean }>()
);

export const WorkroomFinishWorkroomSucceeded = createAction(
  '[Workroom] Finish Workroom Succeeded',
  props<{ workroom: Workroom }>()
);

export const WorkroomFinishWorkroomFailed = createAction(
  '[Workroom] Finish Workroom Failed',
  props<{ workroom: Workroom }>()
);

export const WorkroomDeleteWorkroom = createAction('[Workroom] Delete Workroom', props<{ workroom: Workroom }>());

export const WorkroomDeleteWorkroomSucceeded = createAction(
  '[Workroom] Delete Workroom Succeeded',
  props<{ workroom: Workroom }>()
);

export const WorkroomDeleteWorkroomFailed = createAction(
  '[Workroom] Delete Workroom Failed',
  props<{ workroom: Workroom }>()
);

export const WorkroomReopenWorkroom = createAction(
  '[Workroom] Reopen Workroom',
  props<{ workroom: Workroom; canRedirect: boolean }>()
);

export const WorkroomReopenWorkroomSucceeded = createAction(
  '[Workroom] Reopen Workroom Succeeded',
  props<{ workroom: Workroom }>()
);

export const WorkroomReopenWorkroomFailed = createAction(
  '[Workroom] Reopen Workroom Failed',
  props<{ workroom: Workroom }>()
);

export const WorkroomLoadWorkroomPermissions = createAction(
  '[Workroom] Load Permissions',
  props<{ workroomId: number }>()
);

export const WorkroomLoadWorkroomDriveSubscription = createAction(
  '[Workroom] Load Drive Subscription',
  props<{ workroom: Workroom }>()
);

export const WorkroomRefreshCurrentWorkroom = createAction('[Workroom] Refresh Current Workroom');

export const WorkroomTemplateUpdated = createAction(
  '[Workroom] Template Updated',
  props<{ workroomId: number; template: Template }>()
);

export const WorkroomsUpdateContentItemCount = createAction(
  '[Workrooms] Update Content Item Count',
  props<{ workroomId: number; count: number }>()
);
