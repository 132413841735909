import { Pipe, PipeTransform } from '@angular/core';

import { InvitationStatus, MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { isNewUser } from '@celum/work/app/core/model/entities/person/person-util';

import { WorkroomAvatarConfiguration } from '../workroom-avatar-configuration';

enum AdditionalAvatarClasses {
  PENDING_APPROVAL = 'pending',
  INVITED = 'invited',
  DEACTIVATED = 'deactivated'
}

@Pipe({
  name: 'additionalAvatarClass',
  standalone: true
})
export class WorkroomAvatarAdditionalAvatarClassPipe implements PipeTransform {
  public transform(configuration: WorkroomAvatarConfiguration): string {
    const classPrefix = 'celum-avatar--';

    if (configuration?.member?.status === MembershipStatus.INACTIVE) {
      return classPrefix + AdditionalAvatarClasses.DEACTIVATED;
    }
    if (isNewUser(configuration?.person)) {
      return classPrefix + AdditionalAvatarClasses.INVITED;
    }

    switch (configuration?.person?.invitationStatus) {
      case InvitationStatus.PENDING_APPROVAL:
        return classPrefix + AdditionalAvatarClasses.PENDING_APPROVAL;
      case InvitationStatus.INVITED:
        return classPrefix + AdditionalAvatarClasses.INVITED;
    }

    switch (configuration?.member?.invitationStatus) {
      case InvitationStatus.INVITED:
        return classPrefix + AdditionalAvatarClasses.INVITED;
      case InvitationStatus.PENDING_APPROVAL:
        return classPrefix + AdditionalAvatarClasses.PENDING_APPROVAL;
      default:
        return null;
    }
  }
}
