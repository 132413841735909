<div [ngClass]="cssClasses">
  <section class="robot-dialog_content_title">{{ title | translate }}</section>

  <section class="robot-dialog_content_when">
    <celum-icon class="robot-dialog_content_icon" [configuration]="whenIcon" [style.order]="-1"></celum-icon>
    <div class="robot-dialog_content_wrapper">
      <ng-content select="[when]"></ng-content>
    </div>
  </section>

  <mat-divider class="robot-dialog_content_divider"></mat-divider>

  <section class="robot-dialog_content_then">
    <celum-icon class="robot-dialog_content_icon" [configuration]="thenIcon" [style.order]="-1"></celum-icon>
    <div class="robot-dialog_content_wrapper">
      <ng-content select="[then]"></ng-content>
    </div>
  </section>

</div>
<section class="robot-dialog_content_msg" *ngIf="showMessageSection">
  <div class="robot-dialog_content_wrapper">
    <section class="robot-dialog_content_messages">
      <message-box
          *ngFor="let message of messageBoxes; trackBy: trackByIdxFn"
          [type]="message.type"
          [headline]="message.headline"
          [text]="message.text">
      </message-box>
    </section>
  </div>
</section>


