import { createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { PredefinedCategoryValues } from '@celum/work/app/core/model/entities/template-category/template-category.model';
import { workroomCreatorFeature } from '@celum/work/app/pages/workroom-creator/store/workroom-creator.selectors';

import { templateAdapter } from './template.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';
import { selectActiveCategoryId } from '../template-category/template-category.selectors';

const { selectAll, selectIds } = templateAdapter.getSelectors();

export const selectTemplate = createSelector(selectEntitiesState, (state: EntitiesState) => state.template);

export const selectAllTemplates = createSelector(selectTemplate, selectAll);
export const selectAllTemplateIds = createSelector(selectTemplate, selectIds);

export const selectTemplateListByCategoryId = createSelector(
  workroomCreatorFeature,
  selectAllTemplates,
  (wrCreatorState, allTemplates, { categoryId, translateService }) => {
    const templates = allTemplates.filter(
      template =>
        wrCreatorState.entityIds.includes(template.id) &&
        categoryId === (template.categoryId ?? PredefinedCategoryValues.PERSONAL)
    );
    const translatedTemplate = cloneDeep(templates);
    if (categoryId === PredefinedCategoryValues.CELUM_TEMPLATE_CATEGORY_ID || categoryId === null) {
      translatedTemplate.map(template =>
        template.taskLists.map(taskList => (taskList.name = translateService.instant(taskList.name)))
      );
    }
    return translatedTemplate;
  }
);

export const selectAllTemplatesForActiveCategory = createSelector(
  selectActiveCategoryId,
  selectAllTemplates,
  (activeCategory, templates, { translateService }) => {
    if (activeCategory === PredefinedCategoryValues.ALL) {
      return templates;
    }

    const translatedTemplate = cloneDeep(templates);
    if (activeCategory === PredefinedCategoryValues.CELUM_TEMPLATE_CATEGORY_ID) {
      translatedTemplate.map(template =>
        template.taskLists.map(taskList => (taskList.name = translateService.instant(taskList.name)))
      );
    }

    // null means that template is personal.
    const categoryId = activeCategory === PredefinedCategoryValues.PERSONAL ? null : Number(activeCategory);
    return translatedTemplate.filter(tpl => tpl.categoryId === categoryId);
  }
);
