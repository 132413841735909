import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CelumDialogModule } from '@celum/common-components';
import { CelumQuillModule } from '@celum/work/app/shared/components/celum-quill/celum-quill.module';
import { TaskListModule } from '@celum/work/app/task-list/task-list.module';

import { ContactDialogTranslationsPipe } from './components/contact-dialog/contact-dialog-translations.pipe';
import { ContactDialog } from './components/contact-dialog/contact-dialog.component';
import { CreateBoardButtonComponent } from './components/create-board-button/create-board-button.component';
import { OrganizationInformationComponent } from './components/organization-information/organization-information.component';
import { TemplateChooserCategoriesComponent } from './components/template-chooser-categories/template-chooser-categories.component';
import { TemplateChooserDialogComponent } from './components/template-chooser-dialog/template-chooser-dialog.component';
import { TemplateChooserTemplateComponent } from './components/template-chooser-template/template-chooser-template.component';
import { TemplateChooserTemplateCategoryComponent } from './components/template-chooser-template-category/template-chooser-template-category.component';
import { CopyTemplateDialogComponent } from './components/template-maintenance/copy-template-dialog/copy-template-dialog.component';
import { MoveTemplateDialogComponent } from './components/template-maintenance/move-template-dialog/move-template-dialog.component';
import { HasTemplateCategoryPermissionPipe } from './pipes/has-template-category-permission.pipe';
import { IsCategoryEditablePipe } from './pipes/is-category-editable.pipe';
import { LicenseLimitReachedPipe } from './pipes/license-limit-reached.pipe';
import { WorkroomCreatorEffects } from './store/workroom-creator.effects';
import { workroomCreatorReducer } from './store/workroom-creator.reducer';
import { FEATURE_NAME } from './store/workroom-creator.selectors';
import { SharedModule } from '../../shared';
import { WorkroomWizardModule } from '../../workroom-wizard/workroom-wizard.module';

@NgModule({
  declarations: [
    TemplateChooserDialogComponent,
    TemplateChooserCategoriesComponent,
    TemplateChooserTemplateComponent,
    CopyTemplateDialogComponent,
    CreateBoardButtonComponent,
    MoveTemplateDialogComponent,
    IsCategoryEditablePipe,
    HasTemplateCategoryPermissionPipe,
    LicenseLimitReachedPipe,
    TemplateChooserTemplateCategoryComponent,
    OrganizationInformationComponent,
    ContactDialog,
    ContactDialogTranslationsPipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatTabsModule,
    StoreModule.forFeature(FEATURE_NAME, workroomCreatorReducer),
    EffectsModule.forFeature([WorkroomCreatorEffects]),
    TaskListModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    CelumDialogModule,
    WorkroomWizardModule,
    CelumQuillModule
  ],
  providers: [HasTemplateCategoryPermissionPipe]
})
export class WorkroomCreatorModule {}
