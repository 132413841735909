<div class="license-limit-reached">
  <celum-icon class="license-limit-reached_icon" [configuration]="iconLicenseWarningConfig"></celum-icon>
  <span class="license-limit-reached_label" [innerHtml]="licenseLimitLabel"></span>
  <div *ngIf="showContactButton"
       (click)="openContactDialog()"
       data-action-id="contact-dialog"
       class="license-limit-reached_contact"
       [matTooltip]="tooltipInfo$ | async"
       [matTooltipPosition]="'below'" >{{ contactLabel$ | async | uppercase}}
  </div>
</div>
