import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FileVersion } from '@celum/work/app/core/model/entities/file-version/file-version.model';
import { selectFileVersionEntities } from '@celum/work/app/core/model/entities/file-version/file-version.selectors';

import { VersionSwitcherState } from './version-switcher.model';

export const FEATURE_NAME_VERSION_SWITCHER = 'versionSwitcher';

export const selectVersionSwitcherStateState =
  createFeatureSelector<VersionSwitcherState>(FEATURE_NAME_VERSION_SWITCHER);

export const selectVersionSwitcherState = createSelector(
  selectVersionSwitcherStateState,
  (state: VersionSwitcherState) => state
);

export const selectFileVersionsLoading = createSelector(selectVersionSwitcherState, state => state.loading);

export const selectSelectedFileVersionId = createSelector(
  selectVersionSwitcherState,
  state => state.selectedFileVersionId
);

export const selectCompareFileVersionId = createSelector(
  selectVersionSwitcherState,
  state => state.compareFileVersionId
);

export const selectSelectedFileVersion = createSelector(
  selectSelectedFileVersionId,
  selectFileVersionEntities,
  (fileVersionId: string, fileVersions: Dictionary<FileVersion>) => fileVersions[fileVersionId]
);

export const selectCompareFileVersion = createSelector(
  selectCompareFileVersionId,
  selectFileVersionEntities,
  (fileVersionId: string, fileVersions: Dictionary<FileVersion>) => fileVersions[fileVersionId]
);

export const selectFileVersionIds = createSelector(selectVersionSwitcherState, state => state?.entityIds);

export const selectFileVersionsByFileVersionIds = createSelector(
  selectFileVersionIds,
  selectFileVersionEntities,
  (ids: string[], fileVersions: Dictionary<FileVersion>) =>
    ids?.map(id => fileVersions[id]).filter(fileVersion => !!fileVersion)
);
