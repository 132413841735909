import { Color } from '../../core/model';

export class MailItem {
  private defaultColorForEmail: Color = new Color(0, 0, 0);

  constructor(public mail: string) {}

  public setColorForInvitedUserByEmail(defaultColor: Color) {
    this.defaultColorForEmail = defaultColor;
  }

  public getDefaultColorForEmail() {
    return this.defaultColorForEmail;
  }

  public getMail(): string {
    return this.mail;
  }
}
