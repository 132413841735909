import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { ApplicationQueryParamKey } from '@celum/work/app/core';
import { RouterNavigationHelperService } from '@celum/work/app/core/router-navigation-helper.service';

import { DeleteQueryParam, SetQueryParam } from './router.actions';

@Injectable()
export class RouterEffects {
  public setQueryParam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SetQueryParam),
        tap(action => this.setQueryParam(action.key, action.value))
      ),
    { dispatch: false }
  );

  public clearQueryParam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeleteQueryParam),
        tap(action => this.deleteQueryParam(action.key))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private routerNavigationStatusService: RouterNavigationHelperService
  ) {}

  private setQueryParam(key: ApplicationQueryParamKey, value: any): void {
    this.routerNavigationStatusService.safeNavigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { [key]: value },
      queryParamsHandling: 'merge'
    });
  }

  private deleteQueryParam(key: ApplicationQueryParamKey): void {
    this.setQueryParam(key, null);
  }
}
