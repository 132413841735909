export class Sorting {
  constructor(
    public field: string,
    public direction: string
  ) {}

  public static of(field: string, direction: string) {
    return new Sorting(field, direction);
  }
}
