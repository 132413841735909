import { createSelector } from '@ngrx/store';

import { customFormAdapter } from '@celum/work/app/core/model/entities/custom-form/custom-form.reducer';

import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities } = customFormAdapter.getSelectors();

export const selectCustomForms = createSelector(selectEntitiesState, (state: EntitiesState) => state.customForms);

export const selectCustomFormEntities = createSelector(selectCustomForms, selectEntities);
