import { ContentHub68XStrategy } from './content-hub-6-8-x-strategy';
import { ContentHubStrategy } from './content-hub-strategy';
import { ContentHubSortField } from './content-hub.model';

export class ContentHub69XStrategy extends ContentHubStrategy {
  public defaultStrategy(): ContentHubStrategy {
    return new ContentHub68XStrategy();
  }

  public buildNumber(): string {
    return '6.9.0';
  }

  public getSupportedContentHubSortFields(): ContentHubSortField[] {
    return [
      ContentHubSortField.NAME,
      ContentHubSortField.ID,
      ContentHubSortField.ORIGINAL_FILE_NAME,
      ContentHubSortField.MEDIA_TYPE,
      ContentHubSortField.CREATION_DATE,
      ContentHubSortField.LAST_MODIFIED_DATE,
      ContentHubSortField.FILE_EXTENSION,
      ContentHubSortField.ORIGINAL_FILE_SIZE,
      ContentHubSortField.CREATED_BY,
      ContentHubSortField.LAST_MODIFIED_BY
    ];
  }
}
