import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import {
  ContentItemUpsertMany,
  ContentItemUpsertOne
} from '@celum/work/app/core/model/entities/content-item/content-item.actions';
import { FileUpsertMany, FileUpsertOne } from '@celum/work/app/core/model/entities/file/file.actions';
import { File, FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { FolderUpsertMany, FolderUpsertOne } from '@celum/work/app/core/model/entities/folder/folder.actions';
import { Folder, FolderType } from '@celum/work/app/core/model/entities/folder/folder.model';

@Injectable()
export class ContentItemEffects {
  public upsertOneContentItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentItemUpsertOne),
      map(action => {
        if (action.contentItem.entityType instanceof FileType) {
          const file = action.contentItem as File;
          return FileUpsertOne({
            file,
            propertiesToUpdate: action.propertiesToUpdate
          });
        } else {
          const folder = action.contentItem as Folder;
          return FolderUpsertOne({
            folder,
            propertiesToUpdate: action.propertiesToUpdate
          });
        }
      })
    )
  );

  public upsertManyContentItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentItemUpsertMany),
      switchMap(action => {
        const files = action.contentItems.filter(item => item.entityType instanceof FileType) as File[];
        const folders = action.contentItems.filter(item => item.entityType instanceof FolderType) as Folder[];
        return [
          FileUpsertMany({
            files,
            propertiesToUpdate: action.propertiesToUpdate
          }),
          FolderUpsertMany({
            folders,
            propertiesToUpdate: action.propertiesToUpdate
          })
        ];
      })
    )
  );

  constructor(private actions$: Actions) {}
}
