import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TemplateCategory } from '@celum/work/app/core/model/entities/template-category/template-category.model';

@Pipe({
  name: 'templateCategoryName'
})
export class TemplateCategoryNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(category: TemplateCategory | null): string {
    if (!category) {
      return '';
    }
    return category.editable ? category.name : this.translateService.instant(category.name);
  }
}
