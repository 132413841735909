<task-lists-board-base
    [taskLists]="template.taskLists"
    [permissionCheckStrategy]="permissionCheckStrategy"
    [hasPermissionToMoveTaskList]="permissionCheckStrategy.canMove() | async"
    [taskListClasses]="['task-list--small']"
    [dragNDropListBag]="dragNDropListBag"
    [showWorkroomRobots]="showWorkroomRobots"
    (createNewTaskList)="onCreateNewTaskList($event)">
  <ng-container header>
    <mat-expansion-panel *ngIf="showWorkroomRobots" class="task-list-accordion_panel task-lists-configuration-board_general-robots" data-content="automator" [expanded]="true" [hideToggle]="true" [disabled]="true">

      <mat-expansion-panel-header class="task-list-accordion_header" collapsedHeight="2.4rem" expandedHeight="2.4rem">
        <mat-panel-title>{{ 'WORKROOM_WIZARD.DIALOG.STEP_SECTIONS.TASKBOARD.GENERAL_ROBOT_TITLE' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>

      <robot-chip
          *ngIf="generalCollectionRobot; else robotMenu"
          [robot]="generalCollectionRobot"
          [workroomConfig]="template.workroomConfiguration"
          [templatePeople]="templatePeople$ | async"
          [repositoryId]="repositoryId"
          [sourceEntity]="currentWorkroom"
          [editable]="true"
          (editRobot)="openEditRobotDialog($event)"
          (deleteRobot)="deleteRobotFromTemplate(generalCollectionRobot)">
      </robot-chip>

      <ng-template #robotMenu>
        <robot-menu
            [source]="null"
            [repositoryId]="repositoryId"
            [robotContext]="robotContextType.CONTENT_ITEM"
            (createRobot)="openCreateRobotDialog($event)">
        </robot-menu>
      </ng-template>

    </mat-expansion-panel>
    <mat-expansion-panel class="task-list-accordion_panel task-lists-configuration-board_taskboard-title" data-content="automator" [expanded]="true" [hideToggle]="true" [disabled]="true">
      <mat-expansion-panel-header class="task-list-accordion_header" collapsedHeight="2.4rem" expandedHeight="2.4rem">
        <mat-panel-title>{{ 'WORKROOM_WIZARD.DIALOG.STEP_SECTIONS.TASKBOARD.TASKBOARD_TITLE'  | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </ng-container>

  <ng-container>
    <task-list
        *ngFor="let taskList of template.taskLists; let i = index; trackBy: trackByFn"
        class="task-list--small"
        [dragNDropListBag]="dragNDropListBag"
        [taskList]="taskList"
        [deletable]="taskListDeletable(taskList) | async"
        [hasPermissionToMoveTaskList]="permissionCheckStrategy.canMove() | async"
        [taskListUpdatePermission]="permissionCheckStrategy.canUpdate(taskList) | async"
        [taskListDeletePermission]="permissionCheckStrategy.canDelete(taskList) | async"
        [collapsible]="false"
        (onDeleteTaskList)="deleteTaskList($event)"
        (droppedList)="droppedTaskList($event)"
        (nameChanged)="updateTaskListName($event, taskList)"
        (onColorChanged)="updateColor($event, taskList)">
      <ng-container [ngTemplateOutlet]="taskListBodyTemplate" [ngTemplateOutletContext]="{ taskList: taskList, index: i }"></ng-container>
    </task-list>
  </ng-container>
</task-lists-board-base>
