import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { AppState } from '@celum/work/app/core';

import { CustomFormStateState } from '../components/custom-form/store/custom-form-state.model';
import { customFormStateReducer } from '../components/custom-form/store/custom-form-state.reducer';
import { SubtaskStateState } from '../components/subtasks/store/subtask-state.model';
import { subtaskStateReducer } from '../components/subtasks/store/subtask-state.reducer';
import { ItemLinkState, itemLinkStateReducer } from '../components/task-link/store/item-link.reducer';

export const FEATURE_NAME = 'taskDetail';
export const selectTaskDetailsState = createFeatureSelector<TaskDetailsState>(FEATURE_NAME);

export const reducers: ActionReducerMap<TaskDetailsState> = {
  subtasks: subtaskStateReducer,
  itemLinks: itemLinkStateReducer,
  forms: customFormStateReducer
};

export interface TaskDetailsState {
  subtasks: SubtaskStateState;
  itemLinks: ItemLinkState;
  forms: CustomFormStateState;
}

export interface State extends AppState {
  taskDetail: TaskDetailsState;
}
