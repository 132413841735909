import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, merge, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, switchMapTo, take, tap } from 'rxjs/operators';

import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import { ContributorsDeleteMany, ContributorsUpsertMany } from '@celum/work/app/core/model/entities/contributor';
import { getAllRoles } from '@celum/work/app/shared/util/role-util';

import {
  WorkroomContributorExit,
  WorkroomContributorExitFailed,
  WorkroomContributorExitSucceeded,
  WorkroomContributorsInviteSuccessful,
  WorkroomContributorsRemove,
  WorkroomContributorsRemoveError,
  WorkroomContributorsRemoveSuccess,
  WorkroomContributorsUpdateRole
} from './workroom-contributor.actions';
import { WorkroomContributorService } from './workroom-contributor.service';

@Injectable()
export class WorkroomContributorEffects {
  public updateRole = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomContributorsUpdateRole),
      mergeMap(action =>
        this.contributorService
          .updatePersonsRole(
            action.workroomId,
            action.contributorRoles.map(({ contributor, role }) => ({
              personId: contributor.personId,
              role
            }))
          )
          .pipe(
            switchMap(() => EMPTY),
            catchError(err => {
              this.failureHandler.handleError(err);
              return of(
                ContributorsUpsertMany({ contributors: action.contributorRoles.map(({ contributor }) => contributor) })
              );
            }),
            startWith(
              ContributorsUpsertMany({
                contributors: action.contributorRoles.map(({ contributor, role }) => ({
                  ...contributor,
                  roles: getAllRoles(role)
                }))
              })
            )
          )
      )
    )
  );

  public removeContributor = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomContributorsRemove),
      mergeMap(action =>
        this.contributorService
          .removePersonsFromWorkroom(
            action.workroomId,
            action.contributors.map(contributor => contributor.personId),
            action.keepContent
          )
          .pipe(
            map(() => WorkroomContributorsRemoveSuccess({ contributors: action.contributors })),
            catchError(err => {
              this.failureHandler.handleError(err);
              return merge(
                of(ContributorsUpsertMany({ contributors: action.contributors })),
                of(WorkroomContributorsRemoveError({ contributors: action.contributors }))
              );
            }),
            startWith(ContributorsDeleteMany({ ids: action.contributors.map(contributor => contributor.id) }))
          )
      )
    )
  );

  public contributorExits = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomContributorExit),
      mergeMap(action =>
        this.contributorService.exitWorkroom(action.workroom.id, action.keepContent).pipe(
          switchMapTo(action.workroom.contributors(this.store)),
          take(1),
          switchMap(contributors =>
            merge(
              of(ContributorsDeleteMany({ ids: contributors.map(contributor => contributor.id) })),
              of(WorkroomContributorExitSucceeded())
            )
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(WorkroomContributorExitFailed());
          })
        )
      )
    )
  );

  public contributorExitedSuccessfully = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkroomContributorExitSucceeded),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  public upsertContributorsOnSuccessfulInvite = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomContributorsInviteSuccessful),
      map(action => ContributorsUpsertMany({ contributors: action.contributors }))
    )
  );

  constructor(
    private actions$: Actions,
    private contributorService: WorkroomContributorService,
    private failureHandler: FailureHandler,
    private store: Store<any>,
    private router: Router
  ) {}
}
