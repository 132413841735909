import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider, DataUtil } from '@celum/core';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo } from '@celum/work/app/core/model';
import { StorageDetails, Teamspace, TeamspaceType } from '@celum/work/app/core/model/entities/teamspace';
import { QuotaType } from '@celum/work/app/pages/workroom-creator/components/contact-dialog/contact-dialog.component';

@Injectable({ providedIn: 'root' })
export class TeamspaceService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public contactAccountOwner(accountId: string, accountOwnerEmail: string, quotaTypes: QuotaType[]): Observable<any> {
    return this.httpClient.post(CelumPropertiesProvider.properties.httpBaseAddress + '/teamspace/contact-owner', {
      accountId,
      accountOwnerEmail,
      quotaTypes
    });
  }

  public resolveByWorkroomId(workroomId: number): Observable<Teamspace> {
    const metaInfo = MetaInfo.of([TeamspaceType.TYPE_KEY], TeamspaceType.instance().getSchema());

    return this.httpClient
      .get(`${CelumPropertiesProvider.properties.httpBaseAddress}/teamspace/resolve-by-workroom/${workroomId}`)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          const teamspaces = entitiesResult.entities[TeamspaceType.TYPE_KEY];
          return DataUtil.isEmpty(teamspaces) ? null : (teamspaces[0] as Teamspace);
        })
      );
  }

  public getLibrariesStorageDetails(): Observable<StorageDetails> {
    return this.httpClient.get<StorageDetails>(
      CelumPropertiesProvider.properties.librariesHttpBaseAddress + '/content/storage-details'
    );
  }
}
