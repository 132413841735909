import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { UUIDGenerator } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

import { TaskList } from '../../../../core/model/entities/task';
import { WorkroomTransitionRule } from '../../../../core/model/entities/workroom';
import { ColorService } from '../../../../shared/util/color.service';

interface TransitionWithTaskList {
  transition: WorkroomTransitionRule;
  taskList: TaskList;
}

@Component({
  selector: 'task-list-transitions',
  templateUrl: './task-list-transitions.component.html',
  styleUrls: ['./task-list-transitions.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TaskListTransitionsComponent extends ReactiveComponent implements OnChanges {
  @HostBinding('class') public hostCls = 'task-list-transitions';

  @Input() public taskList: TaskList;
  @Input() public taskLists: TaskList[];
  @Input() public transitions: WorkroomTransitionRule[];

  @Output() public readonly transitionsChange: EventEmitter<WorkroomTransitionRule[]> = new EventEmitter<
    WorkroomTransitionRule[]
  >();

  public selectableTaskLists: TaskList[];
  public selectedTransitionsWithTaskList: TransitionWithTaskList[];
  public selectedTaskLists: TaskList[] = [];
  public isSearchAndSelectVisible = false;

  public readonly xIcon = IconConfiguration.small('cancel-m').withColor('#FFF');
  public readonly unidirectionalIcon = IconConfiguration.small('unidirectional')
    .withColor('#FFF')
    .withCssClass('unidirectional');
  public readonly addTransitionIcon: IconConfiguration = IconConfiguration.xLarge('add-transition', '', 30)
    .withColor(ColorConstants.BLUE_GRAY_500)
    .withHoverColor(ColorConstants.BLUE_GRAY_700)
    .withCssClass('bidirectional');

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  public ngOnChanges(): void {
    this.selectableTaskLists = this.taskLists.filter((taskList: TaskList) => taskList.id !== this.taskList.id);
    this.selectedTransitionsWithTaskList = this.transitions
      .filter((transition: WorkroomTransitionRule) => transition.from === this.taskList.id)
      .map(
        (transition: WorkroomTransitionRule) =>
          ({
            transition,
            taskList: this.taskLists.find((taskList: TaskList) => taskList.id === transition.to)
          }) as TransitionWithTaskList
      )
      .filter(transition => !!transition.taskList)
      .sort((t1, t2) => t1.taskList.sort - t2.taskList.sort);
    this.selectedTaskLists = this.selectedTransitionsWithTaskList.map(e => e.taskList);
  }

  public bidirectionalIcon({ color }: TaskList): IconConfiguration {
    return IconConfiguration.small('bidirectional')
      .withBackground('#FFF')
      .withColor(ColorService.getColorAsRgbString(color))
      .withCssClass('bidirectional');
  }

  public addTransition({ id: targetTaskListId }: TaskList): void {
    const predicate = ({ from, to }): boolean => from === targetTaskListId && to === this.taskList.id;
    if (this.transitions.filter(predicate).length > 0) {
      const newTransition: WorkroomTransitionRule = this.buildTransition(this.taskList.id, targetTaskListId, true);
      const existingReversedTransition: WorkroomTransitionRule = this.buildTransition(
        targetTaskListId,
        this.taskList.id,
        true
      );
      this.transitionsChange.emit([
        ...this.transitions.filter(e => !predicate(e)),
        newTransition,
        existingReversedTransition
      ]);
    } else {
      const newTransition: WorkroomTransitionRule = this.buildTransition(this.taskList.id, targetTaskListId, false);
      this.transitionsChange.emit([...this.transitions, newTransition]);
    }
  }

  public onUnidirectionalTransitionClick(transition: WorkroomTransitionRule): void {
    const newTransitionInAnotherTaskList: WorkroomTransitionRule = this.buildTransition(
      transition.to,
      this.taskList.id,
      true
    );
    this.transitionsChange.emit([
      ...this.transitions.filter(e => e.from !== transition.from || e.to !== transition.to),
      this.reverse(transition),
      newTransitionInAnotherTaskList
    ]);
  }

  public onBidirectionalTransitionClick(transition: WorkroomTransitionRule): void {
    const newTransitions = [
      ...this.transitions.filter(this.filterOutSelfAndOppositeTransition(transition)),
      this.reverse(transition)
    ];
    this.transitionsChange.emit(newTransitions);
  }

  public removeTransition({ id: targetTaskListId }: TaskList): void {
    const predicate = (e): boolean => e.from === targetTaskListId && e.to === this.taskList.id;
    if (this.transitions.filter(predicate).length > 0) {
      const existingReversedTransition: WorkroomTransitionRule = this.buildTransition(
        targetTaskListId,
        this.taskList.id,
        false
      );
      this.transitionsChange.emit([
        ...this.transitions.filter(e => !predicate(e) && (e.from !== this.taskList.id || e.to !== targetTaskListId)),
        existingReversedTransition
      ]);
    } else {
      this.transitionsChange.emit(
        this.transitions.filter(e => e.from !== this.taskList.id || e.to !== targetTaskListId)
      );
    }
  }

  public getTaskListColor(taskList: TaskList): string {
    return ColorService.getColorAsRgbString(taskList.color);
  }

  public openSearchAndSelectOverlay(): void {
    setTimeout(() => {
      this.isSearchAndSelectVisible = true;
      this.cdr.detectChanges();
    });
  }

  public closeSearchAndSelectOverlay(): void {
    setTimeout(() => {
      this.isSearchAndSelectVisible = false;
      this.cdr.detectChanges();
    });
  }

  public trackByFn(index, item: any): string {
    return item?.taskList?.id ?? item?.taskList?.name ?? UUIDGenerator.generateId();
  }

  private filterOutSelfAndOppositeTransition(transition: WorkroomTransitionRule) {
    return e =>
      (e.from !== transition.from || e.to !== transition.to) && (e.from !== transition.to || e.to !== transition.from);
  }

  private buildTransition(from: number, to: number, reverse: boolean) {
    return {
      from,
      to,
      reverse
    };
  }

  private reverse(transition: WorkroomTransitionRule) {
    return {
      ...transition,
      reverse: !transition.reverse
    };
  }
}
