import { createSelector } from '@ngrx/store';

import { ContentItemStatus } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { FolderSubType } from '@celum/work/app/core/model/entities/folder/folder.model';
import { Workroom } from '@celum/work/app/core/model/entities/workroom/workroom.model';
import {
  selectCurrentLibraryId,
  selectCurrentWorkroom
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { hasAutomatorTaskCreationFileCreatedTrigger } from '@celum/work/app/robots/services/robots-util';

import { folderAdapter } from './folder.reducer';
import { selectSelectedFolderId } from '../../../../files/store/files-tree/files-tree.selector';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = folderAdapter.getSelectors();

export const selectFolders = createSelector(selectEntitiesState, (state: EntitiesState) => state.folders);

export const selectAllFolders = createSelector(selectFolders, selectAll);
export const selectFolderEntities = createSelector(selectFolders, selectEntities);

export const selectCurrentFolder = createSelector(
  selectSelectedFolderId,
  selectFolderEntities,
  (folderId: string, folders) => folders[folderId]
);

export const selectCurrentWorkroomRootFolder = createSelector(
  selectAllFolders,
  selectCurrentLibraryId,
  (folders, libraryId) =>
    folders.find(
      folder =>
        folder.parentId === null &&
        folder.libraryId === libraryId &&
        folder.status === ContentItemStatus.NOT_DELETED &&
        folder.folderSubType === FolderSubType.ROOT
    )
);

export const selectCurrentWorkroomCommentAttachmentsFolder = createSelector(
  selectAllFolders,
  selectCurrentLibraryId,
  (folders, libraryId) =>
    folders.find(
      folder =>
        folder.parentId === null &&
        folder.libraryId === libraryId &&
        folder.status === ContentItemStatus.NOT_DELETED &&
        folder.folderSubType === FolderSubType.COMMENT_ATTACHMENTS
    )
);

export const selectFoldersForCurrentWorkroom = createSelector(
  selectCurrentLibraryId,
  selectAllFolders,
  (libraryId, folders) =>
    folders.filter(folder => folder.libraryId === libraryId && folder.status === ContentItemStatus.NOT_DELETED)
);

export const selectCurrentWorkroomTrashFolder = createSelector(
  selectAllFolders,
  selectCurrentLibraryId,
  (folders, libraryId) =>
    folders.find(
      folder =>
        folder.parentId === null && folder.libraryId === libraryId && folder.status === ContentItemStatus.SOFT_DELETED
    )
);

export const selectIsTrashBinSelected = createSelector(
  selectSelectedFolderId,
  selectCurrentWorkroomTrashFolder,
  (folderId, trashBinFolder) => folderId === trashBinFolder?.id
);

export const selectHasActiveFolderAutomatorTaskCreationFileCreatedTrigger = createSelector(
  selectCurrentWorkroom,
  selectSelectedFolderId,
  (workroom: Workroom, folderId: string) => hasAutomatorTaskCreationFileCreatedTrigger(workroom.configuration, folderId)
);
