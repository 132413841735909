import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { EntityUtil } from '@celum/work/app/core/model/entity-util';

import {
  WorkroomGroupItemMove,
  WorkroomGroupItemMoveFailed,
  WorkroomGroupItemUpdateOne,
  WorkroomGroupItemUpsertMany
} from './workroom-group-item.actions';
import { WorkroomGroupItem, WorkroomGroupItemState } from './workroom-group-item.model';

export const workroomGroupItemAdapter: EntityAdapter<WorkroomGroupItem> = createEntityAdapter<WorkroomGroupItem>({});

export const initialState: WorkroomGroupItemState = workroomGroupItemAdapter.getInitialState();
type workroomGroupItemProp = keyof WorkroomGroupItem;
const properties: workroomGroupItemProp[] = ['groupId', 'sort'];

const reducer = createReducer(
  initialState,
  on(WorkroomGroupItemUpsertMany, (state: WorkroomGroupItemState, { workroomGroupItems }) => {
    const changedWorkroomGroupItems = EntityUtil.changedEntities(properties, workroomGroupItems, state.entities);
    return workroomGroupItemAdapter.upsertMany(changedWorkroomGroupItems, state);
  }),
  on(WorkroomGroupItemUpdateOne, (state: WorkroomGroupItemState, { groupToUpdate }) => {
    return workroomGroupItemAdapter.updateOne(groupToUpdate, state);
  }),
  on(WorkroomGroupItemMove, (state: WorkroomGroupItemState, action) => {
    const update: Update<WorkroomGroupItem> = {
      id: action.workroomGroupItemId,
      changes: {
        groupId: action.targetGroupId,
        sort: action.currentSort
      }
    };
    return workroomGroupItemAdapter.updateOne(update, state);
  }),
  on(WorkroomGroupItemMoveFailed, (state: WorkroomGroupItemState, action) => {
    const update: Update<WorkroomGroupItem> = {
      id: action.workroomGroupItemId,
      changes: {
        groupId: action.sourceGroupId,
        sort: action.sort
      }
    };
    return workroomGroupItemAdapter.updateOne(update, state);
  })
);

export function workroomGroupItemReducer(
  state: WorkroomGroupItemState = initialState,
  action: Action
): WorkroomGroupItemState {
  return reducer(state, action);
}
