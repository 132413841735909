import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FilesTreeModule } from '@celum/work/app/files/components/files-tree/files-tree.module';
import { RobotsFactory } from '@celum/work/app/robots/services/robots-factory';
import { DueDateModule } from '@celum/work/app/shared/components/due-date/due-date.module';

import { CurrentWorkroomDefinedRobotsComponent } from './components/current-workroom-defined-robots/current-workroom-defined-robots.component';
import { DefinedRobotsComponent } from './components/defined-robots/defined-robots.component';
import { RobotChipConfigFactory } from './components/robot-chip/robot-chip-config.factory';
import { AssignmentAutomatorConfigService } from './components/robot-dialog-templates/assignment-automator/assignment-automator-config.service';
import { BaseRobotConfigService } from './components/robot-dialog-templates/base-robot/base-robot-config.service';
import { ContentHubAutomatorConfigService } from './components/robot-dialog-templates/content-hub-automator/content-hub-automator-config.service';
import { ContentHubAutomatorComponent } from './components/robot-dialog-templates/content-hub-automator/content-hub-automator.component';
import { CreateRestrictionRuleConfigService } from './components/robot-dialog-templates/create-restriction-rule/create-restriction-rule-config.service';
import { CreateRestrictionRuleComponent } from './components/robot-dialog-templates/create-restriction-rule/create-restriction-rule.component';
import { DueDateAutomatorConfigService } from './components/robot-dialog-templates/due-date-automator/due-date-automator-config.service';
import { DueDateAutomatorComponent } from './components/robot-dialog-templates/due-date-automator/due-date-automator.component';
import { MandatoryAssignmentRuleConfigService } from './components/robot-dialog-templates/mandatory-assignment-rule/mandatory-assignment-rule-config.service';
import { RobotComponent } from './components/robot-dialog-templates/robot/robot.component';
import { FolderTreeSelectionDialogComponent } from './components/robot-dialog-templates/task-creation-automator/folder-tree-selection-dialog/folder-tree-selection-dialog.component';
import { TaskCreationAutomatorConfigService } from './components/robot-dialog-templates/task-creation-automator/task-creation-automator-config.service';
import { TaskCreationAutomatorComponent } from './components/robot-dialog-templates/task-creation-automator/task-creation-automator.component';
import { TaskListOwnerConfigService } from './components/robot-dialog-templates/task-list-owner-rule/task-list-owner-config.service';
import { TaskListOwnerRuleComponent } from './components/robot-dialog-templates/task-list-owner-rule/task-list-owner-rule.component';
import { SelectFoldersDialogComponent } from './components/robot-dialog-templates/watch-collections-automator/select-folders-dialog/select-folders-dialog.component';
import { SelectedPipe } from './components/robot-dialog-templates/watch-collections-automator/select-folders-dialog/selected.pipe';
import { WatchCollectionsAutomatorConfigService } from './components/robot-dialog-templates/watch-collections-automator/watch-collections-automator-config.service';
import { WatchCollectionsAutomatorComponent } from './components/robot-dialog-templates/watch-collections-automator/watch-collections-automator.component';
import { RobotScopeResolverService } from './services/robot-scope-resolver.service';
import { RobotChipComponent } from '../robots/components/robot-chip/robot-chip.component';
import { RobotDialogComponent } from '../robots/components/robot-dialog/robot-dialog.component';
import { AssignmentAutomatorComponent } from '../robots/components/robot-dialog-templates/assignment-automator/assignment-automator.component';
import { MandatoryAssignmentRuleComponent } from '../robots/components/robot-dialog-templates/mandatory-assignment-rule/mandatory-assignment-rule.component';
import { SharedModule } from '../shared';

@NgModule({
  declarations: [
    WatchCollectionsAutomatorComponent,
    RobotChipComponent,
    RobotDialogComponent,
    CreateRestrictionRuleComponent,
    AssignmentAutomatorComponent,
    MandatoryAssignmentRuleComponent,
    ContentHubAutomatorComponent,
    TaskListOwnerRuleComponent,
    RobotComponent,
    DueDateAutomatorComponent,
    SelectedPipe,
    SelectFoldersDialogComponent,
    DefinedRobotsComponent,
    CurrentWorkroomDefinedRobotsComponent,
    TaskCreationAutomatorComponent,
    FolderTreeSelectionDialogComponent
  ],
  providers: [
    { provide: BaseRobotConfigService, useClass: AssignmentAutomatorConfigService, multi: true },
    { provide: BaseRobotConfigService, useClass: ContentHubAutomatorConfigService, multi: true },
    { provide: BaseRobotConfigService, useClass: CreateRestrictionRuleConfigService, multi: true },
    { provide: BaseRobotConfigService, useClass: DueDateAutomatorConfigService, multi: true },
    { provide: BaseRobotConfigService, useClass: MandatoryAssignmentRuleConfigService, multi: true },
    { provide: BaseRobotConfigService, useClass: TaskListOwnerConfigService, multi: true },
    { provide: BaseRobotConfigService, useClass: WatchCollectionsAutomatorConfigService, multi: true },
    { provide: BaseRobotConfigService, useClass: TaskCreationAutomatorConfigService, multi: true },
    RobotChipConfigFactory,
    RobotsFactory,
    RobotScopeResolverService
  ],
  imports: [CommonModule, SharedModule, DueDateModule, TranslateModule, FilesTreeModule],
  exports: [
    RobotChipComponent,
    RobotDialogComponent,
    CreateRestrictionRuleComponent,
    AssignmentAutomatorComponent,
    MandatoryAssignmentRuleComponent,
    RobotComponent,
    TaskListOwnerRuleComponent,
    WatchCollectionsAutomatorComponent,
    DefinedRobotsComponent,
    CurrentWorkroomDefinedRobotsComponent,
    TaskCreationAutomatorComponent
  ]
})
export class RobotsModule {}
