import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatButtonModule as MatMenuModule } from '@angular/material/button';
import { Store } from '@ngrx/store';

import { CelumButtonModule, CelumIconModule } from '@celum/common-components';
import { CustomForm } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { SharedModule } from '@celum/work/app/shared';
import { TextTruncatorComponent } from '@celum/work/app/shared/components/text-truncator/text-truncator.component';
import { DateFormats } from '@celum/work/app/shared/util';

import { CustomFormsContextMenuComponent } from '../custom-forms-context-menu/custom-forms-context-menu.component';

@Component({
  selector: 'custom-forms-table-row',
  templateUrl: './custom-forms-table-row.component.html',
  styleUrls: ['./custom-forms-table-row.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    TextTruncatorComponent,
    CelumIconModule,
    CelumButtonModule,
    SharedModule,
    CustomFormsContextMenuComponent
  ]
})
export class CustomFormsTableRowComponent {
  @HostBinding('class') public componentCls = 'custom-forms-row';

  @Input() public customForm: CustomForm;
  @Input() public hasWorkroomsFinishPermission: boolean;

  @Output() public readonly copyAsNewDialogOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly deleteDialogOpened: EventEmitter<void> = new EventEmitter<void>();

  public readonly dateFormat = DateFormats;

  constructor(public store: Store<any>) {}
}
