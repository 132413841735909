import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@celum/work/app/app.module';
import { environment } from '@celum/work/env/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { ngZoneEventCoalescing: (window as any).Celum.properties.ngZoneEventCoalescing })
  .catch(console.error);
