import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { ReactiveService } from '@celum/core';
import { TaskAttachmentsAdded } from '@celum/work/app/core/api/task/task.actions';
import { TaskAttachmentAddedNotification } from '@celum/work/app/core/client-notification/model/client-notification.model';
import { NotificationWebsocketListener } from '@celum/work/app/core/client-notification/notification-websocket-listener.service';

@Injectable({
  providedIn: 'root'
})
export class TaskAttachmentAddedNotificationListener extends ReactiveService {
  constructor(
    private store: Store<any>,
    private clientNotificationWebsocketListener: NotificationWebsocketListener<TaskAttachmentAddedNotification>
  ) {
    super();
    this.startListening();
  }

  public startListening(): void {
    this.clientNotificationWebsocketListener.clientNotificationsSubj
      .pipe(
        filter(notification => notification.typeKey === 'task_attachment_added'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(notification => {
        this.store.dispatch(
          TaskAttachmentsAdded({
            taskId: notification.taskId,
            fileIds: [notification.contentItemId]
          })
        );
      });
  }
}
