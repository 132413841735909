import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { DataUtil, EntityType } from '@celum/core';
import { Roles } from '@celum/work/app/core/model';
import { File, FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { ItemLinkRelationType } from '@celum/work/app/core/model/entities/item-link/item-link.model';
import { Rendition } from '@celum/work/app/core/model/entities/rendition/rendition.model';
import { RequireAtLeastOne } from '@celum/work/app/core/model/requires-at-least-one';

import { Entity } from '../../entity';
import { Person, PersonType } from '../person/person.model';

export const taskUpdateProperties = [
  'name',
  'sort',
  'description',
  'dueDate',
  'taskListId',
  'changedBy',
  'changedOn',
  'createdBy',
  'assigneeIds',
  'priority'
];
export const taskBasicProperties = [
  ...taskUpdateProperties,
  'previewFileId',
  'pendingTransaction',
  'workroomAdditionalInfo',
  'taskListAdditionalInfo',
  'numberOfComments',
  'status',
  'taskLinkInformation',
  'parentTaskInformation'
];
export const taskDetailProperties = [
  ...taskUpdateProperties,
  'previewFileId',
  'pendingTransaction',
  'numberOfComments',
  'attachmentIds',
  'subtaskInformation',
  'taskLinkInformation',
  'parentTaskInformation',
  'customFormId'
];
export const taskCockpitProperties = [
  ...taskUpdateProperties,
  'workroomAdditionalInfo',
  'taskListAdditionalInfo',
  'parentTaskInformation'
];

export enum TaskCreationStrategy {
  SINGLE_TASK = 'SingleTask',
  MULTIPLE_TASKS = 'MultipleTasks'
}

export enum TaskCreationContext {
  FILE_EXPLORER = 'FILE_EXPLORER',
  IMPORTED_ASSETS = 'IMPORTED_ASSETS',
  IMPORT_INTENT = 'IMPORT_INTENT',
  PORTAL_APPROVAL = 'PORTAL_APPROVAL'
}

export enum SubtaskStatus {
  OPEN = 'OPEN',
  DONE = 'DONE'
}

export interface Task extends Entity<number, TaskType> {
  name: string;
  sort: number;
  taskListId: number;
  dueDate: number;
  createdOn: number;
  changedOn: number;
  modifiedDate: number;
  description: string;
  htmlContent?: string;
  createdBy: number;
  changedBy: number;
  assigneeIds: number[];
  attachmentIds: string[];
  numberOfComments?: number;
  previewFileId: string;
  previewOrigin?: string;
  workroomId: number;
  workroomAdditionalInfo?: WorkroomAdditionalInfo;
  taskListAdditionalInfo?: TaskListAdditionalInfo;
  subtaskInformation: SubtaskInformation;
  taskLinkInformation: ItemLinkInformation;
  parentTaskInformation?: TaskInformation;
  priority?: TaskPriority | null;
  parentId?: number;
  status?: SubtaskStatus;
  customFormId?: number;

  /**
   * Returns the {@link Person} entity for the creator of this task.
   * @param store the store
   */
  creator: (store: Store<any>) => Observable<Person>;
  /**
   * Returns the {@link Person} entity for the last modifier of this task.
   * @param store the store
   */
  modifier: (store: Store<any>) => Observable<Person>;
  /**
   * Returns the {@link Person} entities for the assigned persons of this task.
   * @param store the store
   */
  assignees: (store: Store<any>) => Observable<Person[]>;
  /**
   * Returns the {@link File} entities for the attachments of this task.
   * @param store the store
   */
  attachments: (store: Store<any>) => Observable<File[]>;

  previewRenditions: (store: Store<any>) => Observable<Rendition[]>;
  previewFile: (store: Store<any>) => Observable<File>;
}

export interface WorkroomAdditionalInfo {
  id: number;
  workroomName: string;
  workroomType: string;
  slibResourceToken: string;
  peopleByRoles?: Partial<Record<Roles, number[]>>;
}

export interface TaskListAdditionalInfo {
  taskListName: string;
  color: {
    red: number;
    green: number;
    blue: number;
  };
}

export const NO_PRIORITY = 'NO_PRIORITY' as const;

export enum TaskPriority {
  Blocker = 'BLOCKER',
  High = 'HIGH',
  Standard = 'STANDARD',
  Low = 'LOW'
}

export class TaskType implements EntityType {
  public static readonly TYPE_KEY: string = 'Task';

  private static _instance: TaskType;

  public id = TaskType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): TaskType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(PersonType.TYPE_KEY)) {
        relationDefinitions.createdBy = PersonType.instance().getSchema({});
        relationDefinitions.changedBy = PersonType.instance().getSchema({});
        relationDefinitions.assignees = [PersonType.instance().getSchema({})];
      }
      relationDefinitions.previewFile = FileType.instance().getSchema();
      if (relations.relationsFor.includes(FileType.TYPE_KEY)) {
        relationDefinitions.attachments = [FileType.instance().getSchema()];
      }
    }
    return new schema.Entity(TaskType.TYPE_KEY, relationDefinitions);
  }
}

export interface TaskState extends EntityState<Task> {}

export type TaskPropertiesToUpdate = RequireAtLeastOne<
  Task,
  'name' | 'sort' | 'description' | 'dueDate' | 'taskListId' | 'priority'
>;

export interface TaskValidationValues {
  maxDescriptionLength: number;
  hintOffsetPercentage: number;
}

export const TASK_VALIDATION_VALUES: TaskValidationValues = {
  maxDescriptionLength: 12000,
  hintOffsetPercentage: 0.8
};

export interface SubtaskInformation {
  numberOfTotalSubtasks: number;
  numberOfCompletedSubtasks: number;
}

export interface ItemLinkInformation {
  numberOfLinksPerType: Record<ItemLinkRelationType, number>;
}

export interface TaskInformation {
  name: string;
}
