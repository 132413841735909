import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider, DataUtil, PaginationResult } from '@celum/core';
import { ContributorType } from '@celum/work/app/core/model/entities/contributor';
import { FolderType } from '@celum/work/app/core/model/entities/folder/folder.model';
import { folderProperties } from '@celum/work/app/core/model/entities/folder/folder.reducer';
import { MemberType } from '@celum/work/app/core/model/entities/member';
import { PersonType } from '@celum/work/app/core/model/entities/person';
import { WorkroomAdditionalInfo } from '@celum/work/app/core/model/entities/task';
import { InvitedPerson, Template } from '@celum/work/app/core/model/entities/template/template.model';
import {
  Workroom,
  WorkroomStatus,
  WorkroomTemplateInputParams,
  WorkroomType,
  workroomUpdatableProperties
} from '@celum/work/app/core/model/entities/workroom';
import { WorkroomBasicInformation } from '@celum/work/app/pages/advanced-search/models/advanced-search-filter.model';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

import { ResultConsumerService } from '../../communication/result-consumer.service';
import { MetaInfo, Paging, Roles, Sorting } from '../../model';

export interface LoadWorkroomsResult {
  workrooms: Workroom[];
  paginationResult: PaginationResult;
}

@Injectable({ providedIn: 'root' })
export class WorkroomService {
  public readonly METAINFO_WITH_CONTRIBUTORS_AND_SYSTEM_FOLDERS = this.getMetaInfoWithContributorsAndSystemFolders();

  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public getMetaInfoWithContributorsAndSystemFolders(): MetaInfo {
    return MetaInfo.of(
      [WorkroomType.TYPE_KEY, ContributorType.TYPE_KEY, MemberType.TYPE_KEY, PersonType.TYPE_KEY, FolderType.TYPE_KEY],
      WorkroomType.instance().getSchema({ relationsFor: [ContributorType.TYPE_KEY, FolderType.TYPE_KEY] })
    );
  }

  public loadWorkroomById(workroomId: number): Observable<Workroom> {
    return this.httpClient.get(`${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}`).pipe(
      map((res: any) => {
        const metaInfo = this.getMetaInfoWithContributorsAndSystemFolders();
        metaInfo.partialUpdates[WorkroomType.TYPE_KEY] = [
          ...workroomUpdatableProperties,
          'slibResourceToken',
          'driveSubscribed',
          'renditionLibraryId'
        ];
        metaInfo.partialUpdates[FolderType.TYPE_KEY] = folderProperties.filter(prop => prop !== 'hasSubfolders');

        return this.handleSingleWorkroomResult(res, metaInfo);
      })
    );
  }

  public updateWorkroom(name: string, workroom: Workroom): Observable<Workroom> {
    const metaInfo = this.getMetaInfoWithContributorsAndSystemFolders();
    metaInfo.partialUpdates[WorkroomType.TYPE_KEY] = workroomUpdatableProperties;

    return this.httpClient
      .patch(`${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroom.id}`, {
        name,
        id: workroom.id
      })
      .pipe(map(res => this.handleSingleWorkroomResult(res, metaInfo)));
  }

  public finishWorkroom(workroomId: number, keepContent: boolean): Observable<Workroom> {
    const metaInfo = this.getMetaInfoWithContributorsAndSystemFolders();
    metaInfo.partialUpdates[WorkroomType.TYPE_KEY] = [
      ...workroomUpdatableProperties,
      'lastActivity',
      'slibResourceToken'
    ];
    return this.httpClient
      .patch(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}`,
        {
          keepContent,
          status: WorkroomStatus.INACTIVE
        },
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(map(res => this.handleSingleWorkroomResult(res, metaInfo)));
  }

  public reopenWorkroom(workroomId: number): Observable<Workroom> {
    const metaInfo = this.getMetaInfoWithContributorsAndSystemFolders();
    metaInfo.partialUpdates[WorkroomType.TYPE_KEY] = [...workroomUpdatableProperties, 'lastActivity'];
    return this.httpClient
      .patch(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}`,
        {
          status: WorkroomStatus.ACTIVE
        },
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(map(res => this.handleSingleWorkroomResult(res, metaInfo)));
  }

  public deleteWorkroom(workroomId: number): Observable<void> {
    return this.httpClient
      .delete(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}`,
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(
        map(() => {
          return void 0;
        })
      );
  }

  public createWorkroomFromTemplate(templateParams: WorkroomTemplateInputParams): Observable<Workroom> {
    const metaInfo = this.getMetaInfoWithContributorsAndSystemFolders();
    return this.httpClient
      .post(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms`,
        templateParams,
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(map(res => this.handleSingleWorkroomResult(res, metaInfo)));
  }

  public applyTemplate(
    workroomId,
    args: { repositoryId: string; template: Partial<Template>; people: InvitedPerson[] }
  ): Observable<Workroom> {
    const metaInfo = { ...this.METAINFO_WITH_CONTRIBUTORS_AND_SYSTEM_FOLDERS };
    metaInfo.partialUpdates[WorkroomType.TYPE_KEY] = workroomUpdatableProperties;

    return this.httpClient
      .put<Template>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}`,
        args,
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(map(res => this.handleSingleWorkroomResult(res, metaInfo)));
  }

  public getWorkroomsForMyTasksFilter(): Observable<WorkroomAdditionalInfo[]> {
    return this.httpClient.get<WorkroomAdditionalInfo[]>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/task-inbox-filter`
    );
  }

  public loadWorkrooms(
    searchValue: string,
    paging: Paging,
    sorting: Sorting,
    workroomStatuses: WorkroomStatus[],
    withMyContributionsOnly: boolean,
    repositoryId: string,
    roles?: Roles[]
  ): Observable<LoadWorkroomsResult> {
    const metaInfoWithContributorsAndSystemFolders = {
      ...this.getMetaInfoWithContributorsAndSystemFolders()
    };
    const metaInfo = {
      ...metaInfoWithContributorsAndSystemFolders,
      schema: {
        results: [metaInfoWithContributorsAndSystemFolders.schema]
      },
      resultTypes: [WorkroomType.TYPE_KEY],
      resultKey: 'results'
    };

    const body = {
      searchTerm: searchValue,
      paging,
      sorting,
      workroomStatuses,
      withMyContributionsOnly,
      repositoryId,
      roles
    };

    return this.httpClient.post(CelumPropertiesProvider.properties.httpBaseAddress + '/workrooms/search', body).pipe(
      map(res => {
        const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);

        return {
          workrooms: entitiesResult.entities[WorkroomType.TYPE_KEY] as Workroom[],
          paginationResult: entitiesResult.paginationResult
        };
      })
    );
  }

  public searchWorkroomsForAdvancedSearchFilter(searchTerm: string): Observable<WorkroomBasicInformation[]> {
    const body = {
      searchTerm,
      paging: {
        offset: 0,
        limit: 200
      },
      sorting: {
        field: 'name',
        direction: 'asc'
      }
    };

    return this.httpClient
      .post<any>(CelumPropertiesProvider.properties.httpBaseAddress + '/workrooms/advanced-search', body)
      .pipe(map(res => res.results as WorkroomBasicInformation[]));
  }

  private handleSingleWorkroomResult(res, metaInfo) {
    const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
    const workrooms = entitiesResult.entities[WorkroomType.TYPE_KEY];
    return DataUtil.isEmpty(workrooms) ? null : (workrooms[0] as Workroom);
  }
}
