import { createAction, props } from '@ngrx/store';

import { RolesWithPermissions } from '@celum/work/app/core/api/permission';

import { Workroom, WorkroomConfiguration } from './workroom.model';

export const WorkroomsUpsertOne = createAction(
  '[Workrooms] Upsert One',
  props<{ workroom: Workroom; propertiesToUpdate?: string[] }>()
);

export const WorkroomsUpsertMany = createAction(
  '[Workrooms] Upsert Many',
  props<{ workrooms: Workroom[]; propertiesToUpdate?: string[] }>()
);

export const WorkroomsUpsertPermissions = createAction(
  '[Workrooms] Upsert Permissions',
  props<{ workroomId: number; permissions: RolesWithPermissions }>()
);

export const WorkroomsUpsertConfiguration = createAction(
  '[Workrooms] Upsert Configuration',
  props<{ workroomId: number; configuration: WorkroomConfiguration }>()
);

export const WorkroomsUpsertDriveSubscription = createAction(
  '[Workrooms] Upsert Drive Subscription',
  props<{ workroomId: number; driveSubscribed: boolean }>()
);
