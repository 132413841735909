import { InvitationEmail } from '@celum/work/app/core/model/entities/contributor';
import { Roles } from '@celum/work/app/core/model/roles.model';

import { MailItem } from './mail-item.model';
import { Person } from '../../core/model/entities/person';

export class InvitedPersonDialogModel {
  private person: Person;
  private message: string;
  private role: Roles = Roles.CONTRIBUTOR;

  constructor(public mailItem: MailItem) {}

  public setPerson(value: Person) {
    this.person = value;
  }

  public getPerson(): Person {
    return this.person;
  }

  public setMessage(message: string) {
    this.message = message;
  }

  public getMessage(): string {
    return this.message;
  }

  public getInvitationEmail(): InvitationEmail {
    return { email: this.mailItem.mail, message: this.message };
  }

  public setRole(role: Roles) {
    this.role = role;
  }

  public getRole(): Roles {
    return this.role;
  }
}
