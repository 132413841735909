<form class="people-lookup">
  <celum-autocomplete-area
      id="people-lookup-area"
      [formControl]="lookupItem"
      [placeholder]="placeholderText"
      [selected]="initialPeopleSearchString"
      data-cy="people-lookup-input">
    <div class="flex-column-scrollbar celum-scrollbar">
      <mat-autocomplete [autoActiveFirstOption]="true">
        <ng-container *ngIf="(lookupResults$ | async) as lookupResult">
          <mat-option *ngIf="lookupResult.type === 'hint'" class="people-lookup_option">
            <div>{{ lookupResult.hint | translate }}</div>
          </mat-option>

          <ng-container *ngIf="lookupResult.type === 'person-suggestion'">
            <mat-option
                class="people-lookup_option"
                *ngFor="let person of lookupResult.persons; trackBy: trackByFn"
                [value]="person"
                (onSelectionChange)="handleItemClicked($event.source.value)">
              <multiline-template-person
                  [withRoles]="false"
                  [person]="person"
                  [invitationStatus]="person.invitationStatus"
                  [additionalInfo]="additionalInfo">
              </multiline-template-person>
            </mat-option>
          </ng-container>
        </ng-container>
      </mat-autocomplete>
    </div>
  </celum-autocomplete-area>
</form>
