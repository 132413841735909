import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private readonly store: Store<any>) {}

  public success(message: string) {
    this.store.dispatch(new ShowSnackbar('success', SimpleSnackbar, SnackbarConfiguration.success(message)));
  }

  public error(message: string) {
    this.store.dispatch(new ShowSnackbar('error', SimpleSnackbar, SnackbarConfiguration.error(message)));
  }

  public info(message: string) {
    this.store.dispatch(new ShowSnackbar('info', SimpleSnackbar, SnackbarConfiguration.info(message)));
  }
}
