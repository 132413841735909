import { createSelector } from '@ngrx/store';

import {
  EntitiesState,
  selectEntities as selectEntitiesState
} from '@celum/work/app/core/model/entities/entities.state';

import { workroomGroupAdapter } from './workroom-group.reducer';

const { selectAll, selectEntities } = workroomGroupAdapter.getSelectors();
export const selectWorkroomGroups = createSelector(selectEntitiesState, (state: EntitiesState) => state.workroomGroups);

export const selectAllWorkroomGroups = createSelector(selectWorkroomGroups, selectAll);
export const selectAllWorkroomGroupEntities = createSelector(selectWorkroomGroups, selectEntities);

export const selectGroupByName = (name: string) =>
  createSelector(selectAllWorkroomGroups, allGroups => allGroups.find(group => group.name === name));
