import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { bufferCount, map, take, tap } from 'rxjs/operators';

import { isTruthy } from '@celum/core';
import { UploadActions } from '@celum/work/app/files/upload/store/upload.actions';
import { selectCurrentWorkroomContributingPersons } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { InviteDialogOkClicked } from '@celum/work/app/person/invite/components/invite-user-dialog/invite-dialog.actions';

import { UserPilot } from './user-pilot.service';
import { TaskCreateTaskFromFilesSucceeded, TaskCreateTaskSucceeded } from '../api/task/task.actions';
import { WorkroomCreateWorkroomSucceeded } from '../api/workroom';
import { selectLoggedInPerson } from '../ui-state/ui-state.selectors';

@Injectable()
export class UserPilotEffects {
  public updateNoWorkroom = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WorkroomCreateWorkroomSucceeded),
        take(1),
        concatLatestFrom(() => this.store.select(selectLoggedInPerson)),
        map(([_, { oid }]) => this.userPilot.setProperty(oid, 'workNoWorkroom', false))
      );
    },
    { dispatch: false }
  );

  public updateNoTask = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TaskCreateTaskSucceeded, TaskCreateTaskFromFilesSucceeded),
        take(2),
        concatLatestFrom(() => this.store.select(selectLoggedInPerson)),
        tap(([_, { oid }]) => {
          if (this.userPilot.taskCreatedCount === 0) {
            this.userPilot.setProperty(oid, 'workFirstTask', true);
          } else {
            this.userPilot.setProperty(oid, 'workFirstTask', false);
          }
          this.userPilot.setProperty(oid, 'workNoTask', false);
          this.userPilot.taskCreatedCount++;
        })
      );
    },
    {
      dispatch: false
    }
  );

  public updateNoFile = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UploadActions.UploadConfirmed),
        take(2),
        concatLatestFrom(() => this.store.select(selectLoggedInPerson)),
        tap(([_, { oid }]) => {
          if (this.userPilot.fileCreatedCount === 0) {
            this.userPilot.setProperty(oid, 'workFirstFile', true);
          } else {
            this.userPilot.setProperty(oid, 'workFirstFile', false);
          }
          this.userPilot.setProperty(oid, 'workNoFile', false);
          this.userPilot.fileCreatedCount++;
        })
      );
    },
    {
      dispatch: false
    }
  );

  public updateNoInvite = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InviteDialogOkClicked),
        take(1),
        concatLatestFrom(() => this.store.select(selectLoggedInPerson)),
        tap(([_, { oid }]) => {
          this.contributorsIncreased$.subscribe(() => this.userPilot.setProperty(oid, 'workNoInvite', false));
        })
      );
    },
    {
      dispatch: false
    }
  );

  private readonly contributorsIncreased$ = this.store.select(selectCurrentWorkroomContributingPersons).pipe(
    bufferCount(2, 1),
    map(contributors => contributors[1].length > contributors[0].length),
    isTruthy(),
    take(1)
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private userPilot: UserPilot
  ) {}
}
