import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNumber'
})
export class IsNumberPipe implements PipeTransform {
  public transform(value: any): boolean {
    return Number.isInteger(value);
  }
}
