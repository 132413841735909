import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import {
  SelectNodeDialog,
  SelectNodeDialogConfiguration
} from '@celum/work/app/content-hub/components/select-node-dialog/select-node-dialog.component';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import {
  Automator,
  AutomatorSubType,
  AutomatorType,
  ContentHubAction,
  ContentHubAutomatorTypes,
  isAutomator,
  RobotActionType,
  RobotContext,
  RobotTrigger,
  RobotTriggerType,
  RobotTypes,
  TaskTrigger
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import { RobotDialogConfiguration } from '@celum/work/app/robots/components/robot-dialog/robot-dialog-config';
import { BaseRobot } from '@celum/work/app/robots/components/robot-dialog-templates/base-robot/base-robot.component';
import { TokenStyle } from '@celum/work/app/robots/components/robot-dialog-templates/robot-components.model';
import { ContentHubAutomatorService } from '@celum/work/app/robots/services/content-hub-automator.service';
import { RobotsFactory } from '@celum/work/app/robots/services/robots-factory';
import { MessageBoxConfigType } from '@celum/work/app/shared/components/message/message-box';

@Component({
  selector: 'content-hub-automator',
  templateUrl: './content-hub-automator.component.html',
  styleUrls: ['./content-hub-automator.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ContentHubAutomatorComponent extends BaseRobot<TaskList> {
  public robotTriggerType: typeof RobotTriggerType = RobotTriggerType;
  public readonly TARGETING_ROBOT_TYPE: RobotTypes = AutomatorType.CONTENT_HUB;
  public readonly contentHubAutomatorTypes: typeof ContentHubAutomatorTypes = ContentHubAutomatorTypes;
  public readonly collectionIcon = IconConfiguration.small('ch-collection').withIconSize(30);
  public readonly cancelIcon: IconConfiguration = IconConfiguration.small('cancel-s');
  public chType: string = this.contentHubAutomatorTypes.WORKROOM_FILES;
  public selectedCollection: { id: number; name: string };
  public canAccessCollection = true;
  public subsequentAction = FileSubsequentAction.KEEP;
  public fileSubsequentAction = FileSubsequentAction;
  protected readonly automatorSubType = AutomatorSubType;

  constructor(
    protected robotsFactory: RobotsFactory,
    protected automatorService: ContentHubAutomatorService,
    translateService: TranslateService,
    private dialogOpener: CelumDialogOpener,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(robotsFactory, translateService, automatorService);
  }

  public get needSubsequentActionDropdown(): boolean {
    return (
      this.selectedTriggerType === RobotTriggerType.TASK_MOVED ||
      this.selectedTriggerType === RobotTriggerType.WORKROOM_FINISHED
    );
  }

  public get whenTranslationsCategory(): string {
    if (
      this.robotSubType === AutomatorSubType.CONTENT_HUB_ASSETS &&
      this.selectedTriggerType === RobotTriggerType.TASK_MOVED
    ) {
      return TranslationCategories.TASK_MOVED;
    }

    if (this.selectedTriggerType === RobotTriggerType.WORKROOM_FINISHED) {
      return TranslationCategories.WORKROOM_FINISHED;
    }

    return TranslationCategories.OTHER_TRIGGERS;
  }

  public get thenTranslationsCategory(): string {
    if (
      this.selectedActionType === RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION ||
      this.selectedActionType === RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION
    ) {
      return this.getTranslationCategoryFromActionAndTrigger(this.whenTranslationsCategory, this.selectedActionType);
    }

    return this.whenTranslationsCategory;
  }

  public get messageBoxes$(): Observable<MessageBoxConfigType[]> {
    return this.notApplied$.pipe(
      map(notApplied => {
        if (notApplied) {
          return [
            {
              type: 'warn',
              text: this.translateService.instant('ROBOTS.CONTENT_HUB.MISSING_FOLDER_ACCESS.WARN_MISSING_FOLDER_ACCESS')
            }
          ];
        }

        if (this.selectedActionType === RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION) {
          return [
            {
              type: 'info',
              text: this.translateService.instant(`ROBOTS.CONTENT_HUB.CONTENT_HUB_ASSETS.ADD_INFO_NOTE`)
            }
          ];
        }

        if (this.selectedActionType === RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION) {
          return [
            {
              type: 'info',
              text: this.translateService.instant(`ROBOTS.CONTENT_HUB.CONTENT_HUB_ASSETS.MOVE_INFO_NOTE`)
            }
          ];
        }

        return [];
      })
    );
  }

  public isValid(): boolean {
    const collectionNeeded = this.isCollectionNeeded();
    return !collectionNeeded || (collectionNeeded && !!this.selectedCollection && this.canAccessCollection === true);
  }

  public configure(configuration: RobotDialogConfiguration<TaskList>): void {
    super.configure(configuration);
    this.repositoryId = configuration.args.repositoryId;
    this.canAccessCollection = ((this.robot as Automator)?.action as ContentHubAction)?.canAccess !== false;
    this.setCHType();
    this.setSelectedCollection();
    this.setSubsequentAction();
    this.splitIntoTokens();
  }

  public getThenTokenStyle(token: string) {
    return super.getThenTokenStyle(this.thenTranslationsCategory, token);
  }

  public getWhenTokenStyle(token: string) {
    return super.getWhenTokenStyle(this.whenTranslationsCategory, token);
  }

  public getResult(): Automator {
    const action: ContentHubAction = {
      type: this.selectedActionType,
      taskListId: this.sourceEntity.id,
      targetCollectionName: this.selectedCollection?.name,
      targetCollectionId: this.selectedCollection?.id,
      canAccess: this.canAccessCollection
    };

    if (
      this.selectedTriggerType === RobotTriggerType.WORKROOM_FINISHED ||
      this.selectedTriggerType === RobotTriggerType.TASK_MOVED
    ) {
      (action as ContentHubSubsequentAction).fileSubsequentAction = this.subsequentAction;
    }

    return {
      type: AutomatorType.CONTENT_HUB,
      sourceContext: RobotContext.TASK_LIST,
      sourceId: this.sourceEntity.id,
      trigger: this.getTriggerDTO(),
      action,
      subType: this.robotSubType as AutomatorSubType
    };
  }

  public getWhenTokens(): TokenStyle[] {
    return this.whenTokens[this.whenTranslationsCategory].textTokens;
  }

  public getThenTokens(): TokenStyle[] {
    return this.thenTokens[this.thenTranslationsCategory].textTokens;
  }

  public getTriggerTranslationKey(selectedTrigger: RobotTriggerType): string {
    return `ROBOTS.CONTENT_HUB.TRIGGER.${this.chType}.${selectedTrigger}`;
  }

  public getActionTranslationKey(selectedAction: RobotActionType): string {
    return `ROBOTS.CONTENT_HUB.ACTION.${selectedAction}`;
  }

  public async selectCollection() {
    const config = new SelectNodeDialogConfiguration(
      'CONTENT_HUB.DIALOG.DROPDOWN_PLACEHOLDER',
      'WORK_SHARED.SELECT',
      IconConfiguration.medium('check-m'),
      this.repositoryId
    );

    const selectedNode = await this.dialogOpener.showDialog('select-collection', SelectNodeDialog, config);
    if (!selectedNode) {
      return;
    }

    this.selectedCollection = selectedNode;
    this.canAccessCollection = true;
    this.changeDetectorRef.markForCheck();
  }

  public resetCollection(event?: MouseEvent) {
    event.stopPropagation();
    this.selectedCollection = null;
    this.canAccessCollection = true;
  }

  private setSubsequentAction(): void {
    if (!!this.robot && isAutomator(this.robot)) {
      const action = this.robot.action as ContentHubAction;
      this.subsequentAction = (action as ContentHubSubsequentAction).fileSubsequentAction || FileSubsequentAction.KEEP;
    }
  }

  private setSelectedCollection() {
    if (!this.isCollectionNeeded()) {
      return;
    }
    if (this.robot && isAutomator(this.robot) && 'targetCollectionName' in this.robot.action) {
      const { targetCollectionId, targetCollectionName } = this.robot.action as ContentHubAction;
      this.selectedCollection = {
        name: targetCollectionName,
        id: targetCollectionId
      };
    }
  }

  private setCHType() {
    this.chType =
      this.robotSubType === AutomatorSubType.CONTENT_HUB_ASSETS
        ? this.contentHubAutomatorTypes.CONTENT_HUB_ASSETS
        : this.contentHubAutomatorTypes.WORKROOM_FILES;
  }

  private splitIntoTokens(): void {
    [
      TranslationCategories.WORKROOM_FINISHED,
      TranslationCategories.OTHER_TRIGGERS,
      this.robotSubType === AutomatorSubType.CONTENT_HUB_ASSETS && TranslationCategories.TASK_MOVED
    ]
      .filter(Boolean)
      .forEach(translationCategoryKey => {
        this.whenTokens[translationCategoryKey] = this.tokenizeTranslation(
          `ROBOTS.CONTENT_HUB.${this.chType}.${translationCategoryKey}.WHEN`
        );
        this.thenTokens[translationCategoryKey] = this.tokenizeTranslation(
          `ROBOTS.CONTENT_HUB.${this.chType}.${translationCategoryKey}.THEN`
        );

        if (this.chType === this.contentHubAutomatorTypes.CONTENT_HUB_ASSETS) {
          this.addCombinedTranslationCategoryToThenTokens(
            translationCategoryKey,
            RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION
          );
          this.addCombinedTranslationCategoryToThenTokens(
            translationCategoryKey,
            RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION
          );
        }
      });
  }

  private addCombinedTranslationCategoryToThenTokens(
    translationCategoryKey: TranslationCategories,
    robotActionType: RobotActionType
  ) {
    const combinedTranslationCategory = this.getTranslationCategoryFromActionAndTrigger(
      translationCategoryKey,
      robotActionType
    );
    this.thenTokens[combinedTranslationCategory] = this.tokenizeTranslation(
      `ROBOTS.CONTENT_HUB.${this.chType}.${translationCategoryKey}.THEN_${robotActionType}`
    );
  }

  private getTranslationCategoryFromActionAndTrigger(action: string, trigger: string): string {
    return `trigger_${trigger}_action_${action}`;
  }

  private getTriggerDTO(): RobotTrigger {
    const trigger: TaskTrigger = {
      type: this.selectedTriggerType,
      taskListId: this.sourceEntity.id
    };

    if (this.selectedTriggerType === RobotTriggerType.WORKROOM_FINISHED) {
      const taskListId = 'taskListId';
      delete trigger[taskListId];
    }

    return trigger;
  }

  private isCollectionNeeded(): boolean {
    const actionsWithCollectionSelectionRequired = [
      RobotActionType.CONTENT_HUB_EXPORT_AS_NEW_ASSET,
      RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION,
      RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION
    ];
    return actionsWithCollectionSelectionRequired.includes(this.selectedActionType);
  }
}

enum TranslationCategories {
  WORKROOM_FINISHED = 'WORKROOM_FINISHED',
  TASK_MOVED = 'TASK_MOVED',
  OTHER_TRIGGERS = 'OTHER_TRIGGERS'
}

enum FileSubsequentAction {
  KEEP = 'KEEP',
  DELETE = 'DELETE'
}

// TODO: check how to unify it with assignment automator
interface ContentHubSubsequentAction extends ContentHubAction {
  fileSubsequentAction: FileSubsequentAction;
}
