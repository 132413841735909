import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

/**
 * This directive let's you decide if the click event should or should not be emmited based on input param
 * It's useful when having matTooltip on buttons that need to be disabled
 * Normal click + disabled combination also stops hover => matToolTip does not work (w/o introducing a wrapper element)
 */
@Directive({ selector: '[configurableClick]' })
export class ConfigurabableClickDirective {
  @HostBinding('class.click-disabled') @Input() public clickDisabled = false;
  @Output('configurableClick') public readonly onClick = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  public handleClick($event: MouseEvent) {
    if (!this.clickDisabled) {
      this.onClick.emit($event);
    }
  }
}
