import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import { IconConfiguration } from '@celum/common-components';
import { CustomField, CustomFieldTypes } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';

import { CustomFieldErrorKeyEnum, CustomFieldHttpErrorResponse } from '../../model/custom-field-error-response';
import { CustomFieldCardValidationErrorsEnum } from '../custom-field-card/config/validation-errors.enum';
import { CustomFieldCardControlValue } from '../custom-field-card/custom-field-card.component';
@Component({
  selector: 'base-custom-field-dialog',
  standalone: true,
  imports: [CommonModule],
  template: '<ng-content></ng-content>',
  styleUrls: ['./base-custom-field-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseCustomFieldDialogComponent {
  @HostBinding('class') public componentCls = 'base-custom-field-dialog';

  public readonly cancelIcon = IconConfiguration.medium('cancel-s').withColor('white');
  public readonly confirmIcon = IconConfiguration.medium('check-m').withColor('white');

  public readonly customFieldCardValidationErrors = CustomFieldCardValidationErrorsEnum;

  public customFieldCard: FormControl<CustomFieldCardControlValue>;
  public loading = false;

  public customFieldTypeEnum: typeof CustomFieldTypes = CustomFieldTypes;
  public color: string;

  constructor(
    private dialogRef: MatDialogRef<BaseCustomFieldDialogComponent, BaseCustomFieldDialogResponse>,
    private cdr: ChangeDetectorRef
  ) {}

  public submitHandler(submitEndpointCall$: Observable<CustomField>): void {
    submitEndpointCall$
      .pipe(
        catchError((err: CustomFieldHttpErrorResponse) => {
          this.loading = false;
          this.cdr.detectChanges();

          if (err.error?.errorKey === CustomFieldErrorKeyEnum.CUSTOM_FIELD_CONFLICTING_STATE) {
            this.customFieldCard.setErrors({ [CustomFieldCardValidationErrorsEnum.NAME_NOT_UNIQUE]: true });

            return EMPTY;
          }

          if (err.error?.errorKey === CustomFieldErrorKeyEnum.CUSTOM_FIELD_LIMIT_EXCEEDED) {
            this.dialogRef.close({ errorKey: err.error.errorKey });

            return EMPTY;
          }

          return throwError(() => err);
        })
      )
      .subscribe(customField => this.dialogRef.close({ customField }));
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

export interface BaseCustomFieldDialogResponse {
  customField?: CustomField;
  errorKey?: CustomFieldErrorKeyEnum;
}
