import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CelumPropertiesProvider, DataUtil, PaginationResult } from '@celum/core';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo, Paging, Sorting } from '@celum/work/app/core/model';
import {
  CustomField,
  CustomFieldType,
  CustomFieldTypes
} from '@celum/work/app/core/model/entities/custom-field/custom-field.model';

@Injectable({ providedIn: 'root' })
export class CustomFieldsService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public loadCustomFields(
    paging: Paging,
    sorting: Sorting,
    name?: string,
    excludedIds: number[] = []
  ): Observable<{ customFields: CustomField[]; paginationResult: PaginationResult }> {
    const resultsSchema = {
      results: [CustomFieldType.instance().getSchema()]
    };

    const metaInfo = MetaInfo.of([CustomFieldType.TYPE_KEY], resultsSchema, [CustomFieldType.TYPE_KEY], 'results');

    const body = { paging, sorting, name, excludedIds };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/custom-fields/search`, body)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            customFields: entitiesResult.entities[CustomFieldType.TYPE_KEY] as CustomField[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public getCustomFieldCount(): Observable<number> {
    return this.httpClient
      .get(CelumPropertiesProvider.properties.httpBaseAddress + '/custom-fields/counter')
      .pipe(map((res: any) => res.count));
  }

  public createCustomField(name: string, type: CustomFieldTypes, dropdownValues?: string[]): Observable<CustomField> {
    const metaInfo = MetaInfo.of([CustomFieldType.TYPE_KEY], CustomFieldType.instance().getSchema());

    const body = {
      name,
      type,
      ...(dropdownValues && { dropdownOptions: dropdownValues.map(value => ({ name: value })) })
    };

    return this.httpClient.post(`${CelumPropertiesProvider.properties.httpBaseAddress}/custom-fields`, body).pipe(
      map(res => {
        const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
        const customFields = entitiesResult.entities[CustomFieldType.TYPE_KEY];
        return DataUtil.isEmpty(customFields) ? null : (customFields[0] as CustomField);
      })
    );
  }

  public editCustomField(
    id: number,
    name: string,
    type: CustomFieldTypes,
    removedIds?: number[],
    addedOptions?: { name: string }[]
  ): Observable<CustomField> {
    const metaInfo = MetaInfo.of([CustomFieldType.TYPE_KEY], CustomFieldType.instance().getSchema());
    metaInfo.partialUpdates = {
      [CustomFieldType.TYPE_KEY]: ['name', 'type', 'dropdownOptions']
    };

    const body: any = {
      name,
      type
    };

    if (type === CustomFieldTypes.DROPDOWN && (removedIds || addedOptions)) {
      body.updatedDropdownOptions = {
        removedIds,
        addedOptions
      };
    }

    return this.httpClient
      .patch<CustomField>(`${CelumPropertiesProvider.properties.httpBaseAddress}/custom-fields/${id}`, body)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          const customFields = entitiesResult.entities[CustomFieldType.TYPE_KEY];
          return DataUtil.isEmpty(customFields) ? null : (customFields[0] as CustomField);
        })
      );
  }
}
