<mat-menu #subMenu="matMenu" xPosition="after" [hasBackdrop]="false" class="celum-operation-context-menu">

  <!-- use template with matMenuContent to render the menu contents lazily -->
  <ng-template matMenuContent>
    <ng-container *ngFor="let operationOrGroup of items; let lastItem = last;">

      <ng-container *ngIf="$any(operationOrGroup).group as group">
        <!-- wrap the actual item into a span in order to be able to add "mat-menu-item" directive. this directive needs to be on a direct child of the menu in
        order to allow "on hover" to work correctly for opening and closing sub menus.
        it's not possible to put it on the icon-menu-item as it clears all contents of it 🤦‍ -->
        <span mat-menu-item class="operation-sub-menu_sub-menu-item" [matMenuTriggerFor]="childMenu.menu" [attr.data-test-operation]="$any(group).key">
          <icon-menu-item [text]="group.messageKey | language" [icon]="group | wrapFn : getIconConfiguration"></icon-menu-item>
        </span>
        <operation-sub-menu #childMenu
                            [context]="context"
                            [items]="$any(operationOrGroup).operationsAndSubGroups"
                            (itemClick)="itemClick.next($event)"></operation-sub-menu>
      </ng-container>

      <span *ngIf="$any(operationOrGroup).hasPriority" mat-menu-item>
        <operation-menu-item
            [attr.data-test-operation]="$any(operationOrGroup).getKey()"
            [context]="context"
            [operation]="$any(operationOrGroup)"
            (itemClick)="itemClick.next($any(operationOrGroup))"></operation-menu-item>
      </span>

      <div *ngIf="$any(operationOrGroup) === 'context-menu-sep' && !lastItem" class="operation-sub-menu_separator"></div>

    </ng-container>
  </ng-template>

</mat-menu>
