import { Rendition } from '@celum/work/app/core/model/entities/rendition/rendition.model';

export enum QuillEvents {
  INSERT_QUILL_IMAGE_PLACEHOLDER = 'INSERT_QUILL_IMAGE_PLACEHOLDER',
  QUILL_IMAGE_LOADED = 'QUILL_IMAGE_LOADED'
}

export interface InsertQuillImagePlaceholderEvent {
  type: QuillEvents.INSERT_QUILL_IMAGE_PLACEHOLDER;
  data: { quillImageHandlerUid: string; fileId: string };
}

export interface QuillImagesLoadedEvent {
  type: QuillEvents.QUILL_IMAGE_LOADED;
  data: { rendition: Rendition; fileId: string };
}
