<form [formGroup]="collectionForm">
  <mat-form-field celum-form-field class="collection-selection_form-field">
    <mat-select [formControlName]="collectionsFormControlName"
                panelClass="celum-dropdown-panel"
                [placeholder]="'CONTENT_HUB.DIALOG.DROPDOWN_PLACEHOLDER' | translate">
      <mat-option *ngFor="let collection of collections; trackBy: trackByFn"
                  [value]="collection.id"
                  (click)="changeCollection(collection)">
        {{ collection.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
