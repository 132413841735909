import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { EntityType } from '@celum/core';
import { ContentItem, ContentItemType } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { FileVersion, FileVersionType } from '@celum/work/app/core/model/entities/file-version/file-version.model';
import { RenditionType } from '@celum/work/app/core/model/entities/rendition/rendition.model';

export enum RenditionTypes {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  AUDIO = 'audio',
  WAVEFORM = 'waveform',
  VIDEO = 'video',
  MULTIPAGE = 'multipage',
  ALTERNATIVE = 'alternative',
  PDF = 'pdf',
  BACKGROUND = 'background',
  OVERLAY = 'overlay',
  PREVIEW = 'preview',
  TRIDIMENSIONAL = '3d'
}

export interface File extends ContentItem {
  entityType: FileType;
  activeVersionId: string;
  activeVersion: (store: Store<any>) => Observable<FileVersion>;
  numberOfTasks: number;
  format: string;
  hasLinkedPortalAssets?: boolean;
}

export class FileType implements EntityType {
  public static readonly TYPE_KEY = 'File';
  public static readonly TYPE_KEY_NESTED = [FileType.TYPE_KEY, FileVersionType.TYPE_KEY, RenditionType.TYPE_KEY];

  private static _instance: FileType;

  public id = FileType.TYPE_KEY;
  public inheritsFrom = new Set<ContentItemType>();

  public static instance(): FileType {
    return this._instance || (this._instance = new this());
  }

  public static equalsByIdAndActiveVersionIdAndName(x: File, y: File): boolean {
    return x?.id === y?.id && x?.activeVersionId === y?.activeVersionId && x?.name === y?.name;
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    relationDefinitions.activeVersion = FileVersionType.instance().getSchema();
    return new schema.Entity(FileType.TYPE_KEY, relationDefinitions);
  }
}

export interface FileState extends EntityState<File> {}

export type FilePropertiesToUpdate = Partial<File>;

export interface LinkedAsset {
  assetName: string;
  deepLink: string;
}

export interface FileTaskCount {
  fileId: string;
  numberOfTasks: number;
}

export interface ContentHubContentItemMapping {
  contentItemId: string;
}
