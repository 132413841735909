import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { CelumPropertiesProvider, isTruthy } from '@celum/core';
import { replaceProtocol } from '@celum/work/app/app-init';
import { AvatarService } from '@celum/work/app/core/avatar/avatar.service';
import { selectTenantTeamspace } from '@celum/work/app/core/ui-state/ui-state.selectors';
import { VersionService } from '@celum/work/app/core/version/version.service';

import { ResultConsumerService } from '../../communication/result-consumer.service';
import { Person, PersonType } from '../../model/entities/person/person.model';
import { MetaInfo } from '../../model/meta-info.model';
import { AbstractWebsocketConnectionService } from '../../websocket/abstract-websocket-connection.service';

@Injectable({ providedIn: 'root' })
export class PersonService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService,
    private versionService: VersionService,
    private websocketConnectionService: AbstractWebsocketConnectionService,
    private store: Store<any>,
    private avatarService: AvatarService
  ) {}

  public loadLoggedInPerson(): Observable<Person> {
    const metaInfo = MetaInfo.of([PersonType.TYPE_KEY], PersonType.instance().getSchema());

    return this.httpClient
      .get<Person>(CelumPropertiesProvider.properties.httpBaseAddress + '/person', {
        observe: 'response',
        responseType: 'json'
      })
      .pipe(
        map(res => this.extractAppVersion(res)),
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          const persons = entitiesResult.entities[PersonType.TYPE_KEY];
          return isEmpty(persons) ? null : (persons[0] as Person);
        })
      );
  }

  public fetchAvatars(accountId: string, emails: Array<{ email: string }>): Observable<any> {
    return this.httpClient.post(
      `${(window as any).Celum.properties.saccHttpBaseAddress}/api/accounts/${accountId}/users/profilePicture`,
      [...emails]
    );
  }

  public populatePersonAvatar(persons: Person[]): Observable<Person[]> {
    const emails = Array.from(new Set(persons.map(person => person.email))).map(email => ({ email }));

    return this.store.select(selectTenantTeamspace).pipe(
      isTruthy(),
      take(1),
      switchMap(teamspace =>
        this.fetchAvatars(teamspace.externalId, emails).pipe(map(avatars => [teamspace, avatars]))
      ),
      map(([teamspace, avatars]) => [{ id: teamspace.externalId, avatars }]),
      map(avatarsAndAccountId => this.avatarService.mapToUniqueAvatarsWithAccountId(avatarsAndAccountId)),
      map(avatarsWithAccessToken => this.avatarService.mapAvatarToUser(persons, avatarsWithAccessToken))
    );
  }

  private extractAppVersion(response: HttpResponse<Person>): Person {
    if (response.status === 200) {
      this.versionService.setAppVersion(response.headers.get('X-Version'));
      const backend = response.headers.get('X-API-Management-Response-Backend');
      if (backend) {
        (window as any).Celum.properties.webSocketHostAddress = replaceProtocol(`http://${backend}/api`);
      }

      if (!(window as any).COMP_TESTS) {
        this.websocketConnectionService.init();
      }
    }
    return response.body;
  }
}
