import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { isEmpty } from 'lodash';

import { EntityUtil } from '@celum/work/app/core/model';

import { FetchPersonAvatarsSuccess, PersonsDeleteOne, PersonsUpsertMany, PersonsUpsertOne } from './person.actions';
import { Person, personBasicProperties, PersonState } from './person.model';

export const personAdapter: EntityAdapter<Person> = createEntityAdapter<Person>();

export const initialState: PersonState = personAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(PersonsUpsertOne, (state: PersonState, { person }) => {
    const persons = EntityUtil.changedEntities(personBasicProperties, [person], state.entities);
    if (isEmpty(persons)) {
      return state;
    }
    return personAdapter.upsertOne(persons[0], state);
  }),

  on(PersonsUpsertMany, (state: PersonState, { persons }) => {
    const updatedPersons = EntityUtil.changedEntities(personBasicProperties, persons, state.entities);
    if (isEmpty(updatedPersons)) {
      return state;
    }
    return personAdapter.upsertMany(updatedPersons, state);
  }),

  on(PersonsDeleteOne, (state: PersonState, { id }) => {
    return personAdapter.removeOne(id, state);
  }),

  on(FetchPersonAvatarsSuccess, (state: PersonState, { persons }) => {
    const changedPersons = EntityUtil.changedEntities([...personBasicProperties, 'avatarUrl'], persons, state.entities);
    if (isEmpty(changedPersons)) {
      return state;
    }
    return personAdapter.upsertMany(changedPersons, state);
  })
);

export function personReducer(state: PersonState = initialState, action: Action): PersonState {
  return reducer(state, action);
}
