import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  getActiveTeamspacesWithActiveMemberships,
  selectTeamspacesByUserId,
  Teamspace
} from '@celum/work/app/core/model/entities/teamspace';

import { SACCResponse } from './ui-state.model';
import { selectLoggedInPerson } from './ui-state.selectors';
import { selectMembersByUserId } from '../model/entities/member/member.selectors';

@Injectable({ providedIn: 'root' })
export class UIStateService {
  constructor(
    private store: Store<any>,
    private http: HttpClient
  ) {}

  public getTeamspacesWithPermission(permission: string): Observable<Teamspace[]> {
    return this.store.select(selectLoggedInPerson).pipe(
      filter(person => !!person),
      switchMap(person =>
        this.store
          .select(selectTeamspacesByUserId(person.id))
          .pipe(withLatestFrom(this.store.select(selectMembersByUserId(person.id))))
      ),
      map(([teamspaces, members]) => getActiveTeamspacesWithActiveMemberships(teamspaces, members)),
      map(teamspaces => teamspaces.filter(ts => ts?.permissions)),
      first(({ length }) => length > 0),
      map(teamspaces => teamspaces.filter(ts => ts?.permissions?.permissions?.includes(permission)))
    );
  }

  public setLanguageInSACC(lang: string) {
    return this.http.put(`${(window as any).Celum.properties.saccHttpBaseAddress}/api/users/current`, { locale: lang });
  }

  public fetchCurrentUserDataFromSACC(): Observable<SACCResponse> {
    return this.http.get<SACCResponse>(`${(window as any).Celum.properties.saccHttpBaseAddress}/api/users/current`);
  }
}
