import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SaveStrategy } from './save-strategy';
import {
  WorkroomCreateWorkroom,
  WorkroomCreateWorkroomFailed,
  WorkroomCreateWorkroomSucceeded
} from '../../../core/api/workroom';
import { InvitedPerson, Template } from '../../../core/model/entities/template/template.model';
import { WorkroomTemplateInputParams } from '../../../core/model/entities/workroom';
import { ColorService } from '../../../shared/util/color.service';

@Injectable({
  providedIn: 'root'
})
export class CreateWorkroomFromTemplateStrategy implements SaveStrategy {
  constructor(
    private store: Store<any>,
    private colorService: ColorService,
    private actions$: Actions
  ) {}

  public save(template: Template, form: any, people: InvitedPerson[]): Observable<any> {
    this.store.dispatch(WorkroomCreateWorkroom({ data: this.buildData(template, form, people) }));
    return this.actions$.pipe(ofType(WorkroomCreateWorkroomSucceeded, WorkroomCreateWorkroomFailed));
  }

  private buildData(template: Template, form: any, people: InvitedPerson[]): WorkroomTemplateInputParams {
    const { name, dueDate, description, teamspaceId, repositoryId } = form;
    const { id, taskLists = [], workroomConfiguration } = template;

    return {
      teamspaceId,
      repositoryId,
      people,
      template: {
        id: Number(id),
        name,
        description,
        dueDate,
        taskLists,
        version: 1,
        color: this.colorService.getRandomColorForWorkroom(),
        colorVariant: 0,
        workroomConfiguration
      }
    };
  }
}
