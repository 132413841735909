import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PermissionCheckStrategy } from './permission-check-strategy';
import { Permission } from '../../../core/api/permission';
import { TaskList } from '../../../core/model/entities/task';

@Injectable({
  providedIn: 'root'
})
export class PermissionNoCheckStrategy implements PermissionCheckStrategy {
  public canCreate(): Observable<Permission.ALLOWED> {
    return of(Permission.ALLOWED);
  }
  public canUpdate(list: TaskList): Observable<Permission.ALLOWED | Permission.DENIED>;
  public canUpdate(list: TaskList, asBoolean: boolean): Observable<boolean>;
  public canUpdate(_: TaskList, asBoolean?: boolean) {
    return asBoolean ? of(true) : of(Permission.ALLOWED);
  }
  public canMove(): Observable<boolean> {
    return of(true);
  }
  public canDelete(_: TaskList): Observable<Permission.ALLOWED | Permission.DENIED> {
    return of(Permission.ALLOWED);
  }

  public canEditWorkroom(): Observable<boolean> {
    return of(true);
  }
}
