import { Task } from '@celum/work/app/core/model/entities/task';

export enum SubtaskEvents {
  CONVERT_TO_TASK_SUCCEEDED = 'CONVERT_TO_TASK_SUCCEEDED'
}

export interface ConvertToTaskSucceededEvent {
  type: SubtaskEvents.CONVERT_TO_TASK_SUCCEEDED;
  data: { task: Task };
}
