import { PaginationResult } from '@celum/core';

export interface SimpleListState<T extends string | number = number> {
  entityIds: T[];
  loading: boolean;
  batchSize: number;
}

export interface DictionaryListState<T extends string | number = number> {
  entityIds: T[];
  loading: boolean;
}

export interface PaginatedListState {
  paginationResult: PaginationResult;
  lastOffset: number;
}

export const initialSimpleListState = {
  entityIds: [],
  loading: true,
  batchSize: 0
};

export const initialPaginatedListState = {
  paginationResult: {
    hasBottom: true,
    hasTop: false,
    totalElementCount: 0
  },
  lastOffset: 0
};
