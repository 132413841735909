import {
  AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, Input, OnChanges, SimpleChanges, Type, ViewChild, ViewContainerRef, ViewEncapsulation
} from '@angular/core';

import { Entity, ExtensionPointUtil, Scope } from '@celum/core';

import { ListItem, ListItemConfiguration, ListItemResolver } from '../../list-basics';
import { OneCardItemCustomization } from '../extension-point/card-item-customization';
import { CardItemExtension } from '../extension-point/card-item-extension';

@Component({
             selector: 'celum-list-item',
             template: '<ng-container #placeHolder></ng-container>',
             styleUrls: ['./dynamic-list-item.less'],
             providers: [ListItemResolver],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class CelumDynamicListItem implements OnChanges, AfterViewInit {

  @Input() public item: Entity;
  @Input() public config: ListItemConfiguration;
  @Input() public viewType: 'card' | 'list';
  @Input() public viewScope: Scope;

  @ViewChild('placeHolder', {
    read: ViewContainerRef,
    static: false
  }) private placeHolder: ViewContainerRef;

  private initialized = false;

  constructor(private resolver: ListItemResolver<CardItemExtension<any>, ListItem>) {
  }

  public get listItem(): ComponentRef<ListItem> {
    return this.resolver?.listItem;
  }

  public ngOnChanges({ config, item, viewType }: SimpleChanges): void {
    if (config) {
      this.resolver.handleConfigChange(this.config);
    }

    if ((item || viewType) && this.initialized) {
      this.resolver.resolveListItem(this.item, this.config, this.getComponentClass.bind(this), this.placeHolder);
    }
  }

  public ngAfterViewInit(): void {
    this.initialized = true;
    this.resolver.resolveListItem(this.item, this.config, this.getComponentClass.bind(this), this.placeHolder);
  }

  public scrollIntoView(): void {
    this.resolver.listItem.location.nativeElement.scrollIntoView();
  }

  private getComponentClass(): Type<ListItem> {
    this.viewScope.setEntityType(this.item.entityType);
    const types: ListItem[] = ExtensionPointUtil.collectEntityComponents<ListItem>(CardItemExtension.type, this.viewScope, this.item);
    return ExtensionPointUtil.chooseOne<ListItem>(OneCardItemCustomization.type, this.viewScope, this.item, types, types?.[0]) as any;
  }
}
