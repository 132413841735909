import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { EntityType } from '@celum/core';

import { Entity } from '../../entity';

export type CategoryValues = 'all' | 'personal' | number;

export enum PredefinedCategoryValues {
  ALL = 'all',
  PERSONAL = 'personal',
  CELUM_TEMPLATE_CATEGORY_ID = 1
}

export interface TemplateCategory extends Entity<number, TemplateCategoryType> {
  editable: boolean;
  teamspaceId: number;
  name: string;
}

export class TemplateCategoryType implements EntityType {
  public static readonly TYPE_KEY: string = 'TemplateCategory';

  private static _instance: TemplateCategoryType;

  public id = TemplateCategoryType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): TemplateCategoryType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    return new schema.Entity(TemplateCategoryType.TYPE_KEY, relationDefinitions);
  }
}

export interface TemplateCategoryState extends EntityState<TemplateCategory> {}
