<celum-dialog (onCloseAll)="cancel()">

  <celum-dialog-header>
    <span [innerHTML]="headerText"></span>
  </celum-dialog-header>

  <celum-dialog-content>
    <div class="reopen-workroom-dialog_hint" [class.reopen-workroom-dialog_hint--moderator]="isModerator">
      <celum-icon [configuration]="contentHint"></celum-icon>
      <span [innerHTML]="recoveryHintText"></span>
    </div>
    <div class="reopen-workroom-dialog_hint" *ngIf="isModerator">
      <celum-icon [configuration]="teamHint"></celum-icon>
      <span [innerHTML]="teamAssignHintText"></span>
    </div>
  </celum-dialog-content>

  <celum-dialog-footer
      [confirmBtnText]="'REOPEN_WORKROOM.ACTION' | translate"
      [color]="color"
      [loading]="loading"
      (onConfirm)="reopenWorkroom()"
      (onCancel)="cancel()">
  </celum-dialog-footer>

</celum-dialog>
