import { createAction, props } from '@ngrx/store';

import { ItemLink } from './item-link.model';

export const ItemLinkUpsertOne = createAction(
  '[ItemLink] Upsert One',
  props<{ itemLink: ItemLink; propertiesToUpdate?: string[] }>()
);

export const ItemLinkUpsertMany = createAction(
  '[ItemLink] Upsert Many',
  props<{ itemLinks: ItemLink[]; propertiesToUpdate?: string[] }>()
);

export const ItemLinkDeleteOne = createAction('[ItemLink] Delete One', props<{ itemLink: ItemLink }>());
