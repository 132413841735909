<div [class.person-multiline-template--deactivated]="isDeactivated" class="person-multiline-template_entry">

  <workroom-avatar class="person-multiline-template_avatar" [configuration]="avatar$ | async"></workroom-avatar>

  <div class="person-multiline-template_info">
    <ng-container *ngIf="(person.id?.toString() !== person.email ) && person.firstName && person.lastName; else emailOnly">
      <div class="category" data-private>
        <text-truncator [updateOnAfterViewChecked]="true" [text]="displayName$ | async | translate"></text-truncator>
      </div>
      <div class="caption person-multiline-template_mail" data-private>
        <text-truncator [updateOnAfterViewChecked]="true" [text]="person.email"></text-truncator>
      </div>
    </ng-container>
    <ng-template #emailOnly>
      <div class="category" data-private>
        <text-truncator [updateOnAfterViewChecked]="true" [text]="person.email"></text-truncator>
      </div>
    </ng-template>
  </div>
  <div class="person-multiline-template_status" *ngIf="withStatus">{{ invitationStatus | multilinePersonTemplateUserStatus | translate }}</div>

  <mat-form-field
      *ngIf="withRoles"
      class="person-multiline-template_roles"
      data-cy="person-multiline-template_roles"
      celum-form-field>
    <mat-select
        panelClass="person-multiline-template_role-dropdown"
        (selectionChange)="onRoleChanged($event)"
        [value]="(initialRoleOption$ | async)"
        [compareWith]="compareRoleOptions"
        #roleSelect>
      <mat-option
          *ngFor="let option of roleOptions$ | async; trackBy: trackByFn"
          [attr.data-cy]="'person-multiline-template_role-' + option.role"
          [matTooltip]="option.disabledTooltipKey | translate"
          [matTooltipDisabled]="!option.disabled"
          [disabled]="option.disabled"
          [value]="option">
        <ng-container *ngTemplateOutlet="roleDropdownTemplate; context: {roleOption: option, initialRole: initialRoleOption$ | async}"></ng-container>
      </mat-option>
      <mat-select-trigger>
        <ng-container *ngTemplateOutlet="roleDropdownTemplate; context: {roleOption: roleSelect.value, initialRole: initialRoleOption$ | async}"></ng-container>
      </mat-select-trigger>
    </mat-select>
  </mat-form-field>

  <button
      *ngIf="deletable"
      mat-button celum-square-button
      [matTooltip]="'PEOPLE.INVITE.REMOVE' | translate"
      (click)="onItemDeleted(person)">
    <celum-icon
        class="person-multiline-template_delete"
        [configuration]="closeIcon">
    </celum-icon>
  </button>
  <div [hidden]="!isDeactivated" class="person-multiline-template_note">{{additionalInfo}}</div>
</div>

<ng-template #roleDropdownTemplate let-roleOption="roleOption" let-initialRole="initialRole">
  <div class="person-multiline-template_roles_option">
    <span>{{roleOption.text | translate}}</span>
    <celum-icon
        *ngIf="roleOption | multilinePersonTemplateDriveWarning:initialRole:showDriveWarnings:invitationStatus "
        [configuration]="driveWarningIcon"
        [matTooltip]="'PEOPLE.UPDATE_ROLE.VISITOR_WARNING' | translate">
    </celum-icon>
  </div>
</ng-template>
