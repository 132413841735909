import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

import { IconConfiguration, PageContext } from '@celum/common-components';
import { Operation, OperationGroup } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

import { OperationOrGroup } from '../../model/operation-menu.models';

@Component({
             selector: 'operation-sub-menu',
             templateUrl: './operation-sub-menu.html',
             styleUrls: ['./operation-sub-menu.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class OperationSubMenu extends ReactiveComponent {

  @Input() public items: OperationOrGroup[];
  @Input() public context: PageContext;

  @Output() public readonly itemClick = new EventEmitter<Operation>();

  @ViewChild('subMenu', { static: true }) public menu: MatMenu;

  public getIconConfiguration(group: OperationGroup): IconConfiguration {
    return IconConfiguration.medium(group.icon, '');
  }
}
