import { trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatButton } from '@angular/material/button';

import { EmptyPageConfig, IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'browsing-dialog',
  templateUrl: './browsing-dialog.component.html',
  styleUrls: ['./browsing-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [trigger('noop', [])]
})
export class BrowsingDialogComponent {
  @Input() public title: string;
  @Input() public color: string;
  @Input() public submitIcon: IconConfiguration;
  @Input() public submitText: string;
  @Input() public submitDisabled: boolean;
  @Input() public showSubmitSubMenu: boolean;
  @Input() public submitSubMenuConfig: BrowsingDialogSubmitSubMenuConfiguration[];
  @Input() public showContentHeader: boolean;
  @Input() public drawerOpened: boolean;
  @Input() public showEmptyPage: boolean;
  @Input() public emptyPageConfig: EmptyPageConfig;

  @Output() public readonly onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly onSubmit: EventEmitter<BrowsingDialogSubmitSubMenuType> =
    new EventEmitter<BrowsingDialogSubmitSubMenuType>();

  @ViewChild('submitButtonWithSubMenu') public submitButtonWithSubMenu: MatButton;

  public resizeSliderActive: boolean;

  public cancelIcon: IconConfiguration = IconConfiguration.medium('cancel-s').withColor('white');
  public showSidebarIcon: IconConfiguration = IconConfiguration.small('workroom-sidebar');

  public get submitButtonWidth(): string {
    return this.submitButtonWithSubMenu?._elementRef.nativeElement.clientWidth + 'px';
  }

  public submit(type?: BrowsingDialogSubmitSubMenuType): void {
    this.onSubmit.emit(type);
  }

  public trackByFn(_, item) {
    return item.name;
  }
}

export class BrowsingDialogSubmitSubMenuConfiguration {
  constructor(
    public name: string,
    public type?: BrowsingDialogSubmitSubMenuType,
    public tooltip?: string,
    public tooltipIcon?: IconConfiguration
  ) {}
}

export type BrowsingDialogSubmitSubMenuType = 'AddAndWatch' | 'Add' | 'AddAndCreateTasks';
