import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { PageContext } from '@celum/common-components';
import { GroupType, OperationDefinition, OperationGroupInfo, OperationsManager, OperationsUtil } from '@celum/core';

import { MagicButtonDataProvider } from '../../magic-button/button/services/magic-button-data-provider';
import { ContextMenuPosition, OperationGroupWrapper, OperationMenuInfo, OperationOrGroup, SEPARATOR } from '../model/operation-menu.models';

@Injectable({ providedIn: 'root' })
export class OperationMenuService {

  private openContextMenuCommands$ = new Subject<[ContextMenuPosition, PageContext]>();

  constructor(private magicButtonDataProvider: MagicButtonDataProvider) {
  }

  public openContextMenu(position: ContextMenuPosition, dataContext?: PageContext): void {
    this.openContextMenuCommands$.next([position, dataContext]);
  }

  public listenForContextMenuOpen$(): Observable<OperationMenuInfo> {
    return this.openContextMenuCommands$.pipe(
      // collection necessary information
      map(([position, context]) => OperationMenuService.initializeMenuInfo(position, context)),
      // grab the current context
      switchMap(info => this.evaluateContext(info)),
      // evaluate with operations to show
      switchMap(info => this.evaluateOperations(info))
    );
  }

  private evaluateContext(info: OperationMenuInfo): Observable<OperationMenuInfo> {
    const context$ = info.context ? of(info.context) : this.magicButtonDataProvider.getPageContext();
    return context$.pipe(
      map(context => ({
        ...info,
        context
      })),
      take(1)
    );
  }

  private evaluateOperations(info: OperationMenuInfo): Observable<OperationMenuInfo> {
    return this.currentOperationsGrouped(info.context).pipe(map(operations => ({
      ...info,
      operations
    })));
  }

  private currentOperationsGrouped(context: PageContext): Observable<OperationOrGroup[]> {
    const context$ = context ? of(context) : this.magicButtonDataProvider.getPageContext();

    return context$.pipe(
      switchMap(currentContext => OperationsManager.getAvailableOperationsForContexts(currentContext.viewContext, currentContext)),
      map(operationDefinitions => OperationsUtil.groupOperationDefinitions(operationDefinitions)),
      map(operationDefinitions => operationDefinitions.reduce(
        (acc, def, index) => [...acc, ...this.handleGroup(def, index === (operationDefinitions.length - 1))], [])),
      take(1)
    );
  }

  private handleEntry(opOrGroup: OperationDefinition | OperationGroupInfo, lastEntry: boolean): OperationOrGroup[] {
    if (isOperation(opOrGroup)) {
      return [opOrGroup.operation];
    } else {
      return this.handleGroup(opOrGroup, lastEntry);
    }
  }

  private handleGroup(groupInfo: OperationGroupInfo, lastEntry: boolean): OperationOrGroup[] {
    if (groupInfo.group.groupType === GroupType.SECTION) {
      return this.flattenSection(groupInfo, lastEntry);
    } else {
      return [this.handleSubMenu(groupInfo)];
    }
  }

  private handleSubMenu(groupInfo: OperationGroupInfo): OperationGroupWrapper {
    return {
      group: groupInfo.group,
      operationsAndSubGroups: groupInfo.operationsAndSubGroups.reduce((acc, def, index) =>
                                                                        [
                                                                          ...acc,
                                                                          ...this.handleEntry(def, index === (groupInfo.operationsAndSubGroups.length - 1))
                                                                        ], [])
    };
  }

  private flattenSection(groupInfo: OperationGroupInfo, lastEntry: boolean): OperationOrGroup[] {
    let sectionContent: OperationOrGroup[] = [];

    groupInfo.operationsAndSubGroups.forEach((opOrGroup, index) => {
      if (isOperation(opOrGroup)) {
        sectionContent.push(opOrGroup.operation);
      } else {
        sectionContent = [...sectionContent, ...this.handleGroup(opOrGroup, index === (groupInfo.operationsAndSubGroups.length - 1))];
      }
    });

    !lastEntry && sectionContent.push(SEPARATOR);

    return sectionContent;
  }

  private static initializeMenuInfo(position: ContextMenuPosition, context?: PageContext): OperationMenuInfo {
    return {
      position,
      context
    };
  }
}

function isOperation(entity: OperationDefinition | OperationGroupInfo): entity is OperationDefinition {
  return (entity as OperationDefinition).operation !== undefined;
}
