import { EntityTranslator } from '@celum/work/app/core/model';

import { TemplateCategory, TemplateCategoryType } from './template-category.model';

export class TemplateCategoryTranslator implements EntityTranslator<TemplateCategory> {
  public translateToEntity(json: any): TemplateCategory {
    const id = json.id;
    const { name, teamspaceId, editable } = json;
    return {
      id,
      name,
      teamspaceId,
      editable,
      entityType: TemplateCategoryType.instance()
    };
  }
}
