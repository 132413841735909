<celum-dialog cdkFocusInitial class="browsing-dialog" (onCloseAll)="onClose.emit()" [hideCloseAllBtn]="true">
  <celum-dialog-header class="browsing-dialog_header">
    <div>{{ title | translate }}</div>
    <ng-content select="[headerActions]"></ng-content>
  </celum-dialog-header>
  <celum-dialog-content>
    <mat-drawer-container class="browsing-dialog-container" autosize>
      <mat-drawer #drawer [opened]="drawerOpened" class="browsing-dialog_drawer" mode="side">
         <div
          resize
          class="browsing-dialog_drawer_resizable-container"
          [slider]="resizeSlider"
          [orientation]="'horizontal-left'"
          (sliderActive)="resizeSliderActive = $event">
        <ng-content select="[sideContent]"></ng-content>

        <resize-button #resizeSlider class="resizer" [active]="resizeSliderActive"></resize-button>
      </div>

      </mat-drawer>

      <section class="browsing-dialog-container_overview">
        <div *ngIf="showContentHeader" class="browsing-dialog_panel">
          <celum-icon class="browsing-dialog_icon browsing-dialog_icon--sidebar-toggle"
                      [configuration]="showSidebarIcon"
                      (click)="drawer.toggle()">
          </celum-icon>

          <ng-content select="[sectionHeader]"></ng-content>
        </div>

        <empty-page *ngIf="showEmptyPage; else sectionContent" [config]="emptyPageConfig"></empty-page>

        <ng-template #sectionContent>
          <ng-content select="[sectionContent]"></ng-content>
        </ng-template>

      </section>
    </mat-drawer-container>
  </celum-dialog-content>
  <celum-dialog-footer class="browsing-dialog_footer" [color]="color">
    <button class="browsing-dialog_footer_button browsing-dialog_footer_button--dark" mat-button [disableRipple]="true"
            (click)="onClose.emit()">
      <celum-icon [clickable]="true" [configuration]="cancelIcon"></celum-icon>
      {{ 'COMMON.CANCEL' | translate | uppercase}}
    </button>

    <ng-container *ngIf="showSubmitSubMenu; then submitButtonWithSubMenuTemplate; else simpleSubmitButtonTemplate"></ng-container>
    <ng-template #simpleSubmitButtonTemplate>
      <button class="browsing-dialog_footer_button"
              [disabled]="submitDisabled"
              [disableRipple]="true"
              mat-button
              (click)="$event.stopPropagation();onSubmit.emit()">
        <celum-icon [clickable]="true" [configuration]="submitIcon"></celum-icon>
        {{ submitText | translate | uppercase}}
      </button>
    </ng-template>
    <ng-template #submitButtonWithSubMenuTemplate>
      <button class="browsing-dialog_footer_button"
              [disabled]="submitDisabled"
              #submitButtonWithSubMenu
              [disableRipple]="true"
              mat-button
              [matMenuTriggerFor]="aboveMenu"
              (click)="$event.stopPropagation()">
        <celum-icon [clickable]="true" [configuration]="submitIcon"></celum-icon>
        {{ submitText | translate | uppercase}}
      </button>
    </ng-template>
  </celum-dialog-footer>
</celum-dialog>

<mat-menu #aboveMenu="matMenu" yPosition="above">
  <mat-list-item *ngFor="let subMenuConfig of submitSubMenuConfig; last as last; trackBy: trackByFn">
    <button mat-menu-item
        [ngStyle]="{'width': submitButtonWidth}"
        (click)="submit(subMenuConfig.type); $event.stopPropagation()">
      <span class="browsing-dialog_footer_sub-menu">
        {{subMenuConfig.name | translate}}
        <celum-icon class="browsing-dialog_footer_sub-menu_icon"
                    *ngIf="subMenuConfig.tooltip"
                    [clickable]="true"
                    [configuration]="subMenuConfig.tooltipIcon"
                    [matTooltip]="subMenuConfig.tooltip | translate"></celum-icon>
      </span>
    </button>
 </mat-list-item>
</mat-menu>
