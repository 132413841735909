import { createSelector } from '@ngrx/store';

import { ShapeTypes } from '@celum/annotations';

import { markerAdapter } from './marker.reducer';
import { Comment } from '../comment/comment.model';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = markerAdapter.getSelectors();

export const selectMarkers = createSelector(selectEntitiesState, (state: EntitiesState) => state.markers);

export const selectAllMarkers = createSelector(selectMarkers, selectAll);
export const selectMarkerEntities = createSelector(selectMarkers, selectEntities);

export const selectMarkerById = (id: string) => createSelector(selectMarkerEntities, markers => markers[id]);

export const selectMarkersOfComment = (comment: Comment) =>
  createSelector(selectAllMarkers, markers =>
    markers.filter(marker => comment.markers.find(commentMarker => commentMarker.id === marker.id))
  );

export const selectAllMarkersByFileVersionId = (fileVersionId: string) =>
  createSelector(selectAllMarkers, markers =>
    markers.filter(marker => marker.viewId.split('_')[0] === fileVersionId.toString())
  );

export const selectStampMarkersByFileVersionId = (fileVersionId: string) =>
  createSelector(selectAllMarkers, markers =>
    markers.filter(
      marker => marker.viewId.split('_')[0] === fileVersionId.toString() && marker.shape.objectType === ShapeTypes.Stamp
    )
  );
