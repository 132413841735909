import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { File } from '@celum/work/app/core/model/entities/file/file.model';
import { FileVersion } from '@celum/work/app/core/model/entities/file-version/file-version.model';

@Pipe({
  name: 'activeVersion'
})
export class ActiveVersionPipe implements PipeTransform {
  constructor(private store: Store<any>) {}

  public transform(file: File): Observable<FileVersion> {
    return file?.activeVersion?.(this.store);
  }
}
