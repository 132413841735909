import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { CelumValidators, ReactiveComponent } from '@celum/ng2base';
import { selectContentHubRepositoryId } from '@celum/work/app/content-hub/store/content-hub.selectors';
import { Permission } from '@celum/work/app/core/api/permission';
import { TenantService } from '@celum/work/app/core/auth/tenant.service';
import { selectAllTeamspaces, Teamspace } from '@celum/work/app/core/model/entities/teamspace';
import { Template } from '@celum/work/app/core/model/entities/template/template.model';
import {
  PredefinedCategoryValues,
  TemplateCategory
} from '@celum/work/app/core/model/entities/template-category/template-category.model';
import { selectCategoriesForPermission } from '@celum/work/app/core/model/entities/template-category/template-category.selectors';
import { WORKROOM_VALIDATION_VALUES } from '@celum/work/app/core/model/entities/workroom';
import { selectCurrentWorkroomRepository } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { PermissionCheckStrategy } from '@celum/work/app/pages/workroom-creator/services/permission-check-strategy';
import { selectImportIntentRepositoryId } from '@celum/work/app/pages/workroom-creator/store/workroom-creator.selectors';
import { CelumQuillComponent } from '@celum/work/app/shared/components/celum-quill/celum-quill.component';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';
import { TemplateUtil } from '@celum/work/app/shared/util/template-util';
import { notNullOrUndefined, notNullOrUndefinedOrEmptyString } from '@celum/work/app/shared/util/typescript-util';
import {
  WorkroomDetailsSaveTemplateDialogComponent,
  WorkroomDetailsSaveTemplateDialogConfiguration
} from '@celum/work/app/workroom-wizard/components/workroom-details-save-template-dialog/workroom-details-save-template-dialog.component';
import { WorkroomWizardEvent } from '@celum/work/app/workroom-wizard/model/workroom-wizard-event';
import { WorkroomWizardPeopleService } from '@celum/work/app/workroom-wizard/services/workroom-wizard-people.service';
import { WorkroomWizardTranslations } from '@celum/work/app/workroom-wizard/workroom-wizard-translations';

import { WorkroomWizardType } from '../workroom-wizard.component';

@Component({
  selector: 'workroom-details',
  templateUrl: './workroom-details.component.html',
  styleUrls: ['./workroom-details.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WorkroomDetailsComponent extends ReactiveComponent implements OnInit {
  @ViewChild(CelumQuillComponent) public celumQuillComponent: CelumQuillComponent;

  @Input() public template: Template;
  @Input() public permissionStrategy: PermissionCheckStrategy;
  @Input() public workroomWizardType: WorkroomWizardType;
  @Input() public workroomWizardForm: UntypedFormGroup;
  @Input() public workroomsLimitReached: boolean;
  @Input() public translations: WorkroomWizardTranslations;

  public dueDateControlName = 'dueDate';
  public nameControlName = 'name';
  public descriptionControlName = 'description';
  public repositoryIdControlName = 'repositoryId';
  public readonly validationValues = WORKROOM_VALIDATION_VALUES;
  public teamspace$: Observable<Teamspace>;
  public categories$: Observable<TemplateCategory[]>;
  public isCategoriesVisible$: Observable<boolean>;
  public repositoryIds$: Observable<string[]>;
  public disabledRepositorySelection$: Observable<boolean>;
  public showRepositorySelection$: Observable<boolean>;
  public dueDateBackgroundColor = ColorConstants.BLUE_GRAY_050;
  public saveTemplateIcon = IconConfiguration.large('icon-20-save');
  public selectedRepositoryId: string;

  constructor(
    public workroomWizardPeopleService: WorkroomWizardPeopleService,
    private store: Store<any>,
    private translateService: TranslateService,
    private eventBus: ApplicationEventBus,
    private tenantService: TenantService,
    private dialogOpener: CelumDialogOpener
  ) {
    super();
  }

  get teamspaceId$(): Observable<number> {
    return this.teamspace$.pipe(
      map(({ id }) => id),
      distinctUntilChanged()
    );
  }

  get nameLength(): number {
    return this.workroomWizardForm.get(this.nameControlName).value.length;
  }

  get remainingNameChars(): { remaining: number } {
    return {
      remaining: this.validationValues.maxNameLength - this.nameLength
    };
  }

  get showRemainingNameCharsHint(): boolean {
    const { maxNameLength, hintOffsetPercentage } = this.validationValues;
    return this.nameLength / maxNameLength >= hintOffsetPercentage;
  }

  get descriptionLength(): number {
    return this.celumQuillComponent?.getQuillLength() - 1;
  }

  get remainingDescriptionChars(): { remaining: number } {
    return {
      remaining: this.validationValues.maxDescriptionLength - this.descriptionLength
    };
  }

  get showRemainingDescriptionCharsHint(): boolean {
    const { maxDescriptionLength, hintOffsetPercentage } = this.validationValues;
    return this.descriptionLength / maxDescriptionLength >= hintOffsetPercentage;
  }

  get dueDate(): number {
    return this.workroomWizardForm.get(this.dueDateControlName)?.value;
  }

  get name(): UntypedFormControl {
    return this.workroomWizardForm.get(this.nameControlName) as UntypedFormControl;
  }

  get categoryId(): number {
    return this.workroomWizardForm.value.categoryId;
  }

  public ngOnInit() {
    TemplateUtil.updateTemplateTaskListsSort(this.template);

    this.initFormControls();
    const tenantId = this.tenantService.getStoredTenant();

    this.teamspace$ = this.store.select(selectAllTeamspaces).pipe(
      map(teamspaces => teamspaces.filter(teamspace => teamspace.externalId === tenantId)[0]),
      tap(teamspace => this.workroomWizardForm.get('teamspaceId')?.setValue(teamspace.id))
    );

    this.categories$ = this.store.select(selectCategoriesForPermission, { permission: Permission.TEMPLATE_CREATE });
    this.isCategoriesVisible$ = this.categories$.pipe(map(({ length }: TemplateCategory[]) => length > 0));
    this.disabledRepositorySelection$ = combineLatest([
      this.store.select(selectImportIntentRepositoryId),
      this.store.select(selectCurrentWorkroomRepository)
    ]).pipe(
      map(
        ([importIntentRepositoryId, workroomDefinedRepository]) =>
          notNullOrUndefined(importIntentRepositoryId) ||
          notNullOrUndefined(workroomDefinedRepository) ||
          notNullOrUndefined(this.template.contentHubRepositoryId)
      )
    );

    this.repositoryIds$ = this.teamspace$.pipe(map(teamspace => teamspace.repositories.map(({ id }) => id)));

    this.preselectRepository();
    this.selectedRepositoryId = this.workroomWizardForm.get(this.repositoryIdControlName)?.value;

    this.workroomWizardForm
      .get('categoryId')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.eventBus.publishEvent({ type: WorkroomWizardEvent.TEAMSPACE_CHANGED }));

    this.workroomWizardForm
      .get(this.repositoryIdControlName)
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(repository => {
        if (repository !== this.selectedRepositoryId) {
          this.selectedRepositoryId = repository;
          if (!repository) {
            this.eventBus.publishEvent({ type: WorkroomWizardEvent.REPOSITORY_REMOVED });
          } else {
            this.eventBus.publishEvent({ type: WorkroomWizardEvent.REPOSITORY_CHANGED });
          }
        }
      });

    this.showRepositorySelection$ = this.repositoryIds$.pipe(
      map(repositoryIds => repositoryIds.length > 0 || notNullOrUndefinedOrEmptyString(this.selectedRepositoryId))
    );

    this.disabledRepositorySelection$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(shouldDisable =>
        shouldDisable
          ? this.workroomWizardForm.get('repositoryId')?.disable()
          : this.workroomWizardForm.get('repositoryId')?.enable()
      );
  }

  public idTrackByFn(index: number, item: TemplateCategory) {
    return item.id ?? index;
  }

  public repositoryTrackByFn(index: number, item: string) {
    return item || index;
  }

  public onDueDateChanged(dueDate: number): void {
    this.workroomWizardForm.get(this.dueDateControlName).setValue(dueDate);
    this.workroomWizardForm.get(this.dueDateControlName).markAsDirty();
    this.template.dueDate = this.workroomWizardForm.get(this.dueDateControlName).value;
  }

  public openDialog(teamspace: Teamspace) {
    const name = `${this.translateService.instant('WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.NAME_PREFIX')}${
      this.name.value
    }`;
    const newTemplate = {
      ...this.template,
      description: this.workroomWizardForm.get(this.descriptionControlName)?.value,
      contentHubRepositoryId: this.workroomWizardForm.get(this.repositoryIdControlName)?.value,
      name: this.workroomWizardForm.get(this.nameControlName).value,
      dueDate: this.workroomWizardForm.get(this.dueDateControlName).value
    };
    const config = new WorkroomDetailsSaveTemplateDialogConfiguration(
      name,
      newTemplate,
      this.workroomWizardPeopleService,
      teamspace
    );

    this.dialogOpener.showDialog('saveTemplateDialog', WorkroomDetailsSaveTemplateDialogComponent, config, {
      restoreFocus: false
    });
  }

  public updateDescription(description: string): void {
    this.workroomWizardForm.get(this.descriptionControlName).setValue(description);
    this.workroomWizardForm.get(this.descriptionControlName).markAsDirty();
  }

  private initFormControls(): void {
    const { name, description, categoryId } = this.template;

    const formControls = {
      name: new UntypedFormControl(name ? this.translateService.instant(name) : '', [
        CelumValidators.required,
        CelumValidators.maxLength(this.validationValues.maxNameLength)
      ]),
      dueDate: new UntypedFormControl(this.template.dueDate),
      description: new UntypedFormControl(
        categoryId === PredefinedCategoryValues.CELUM_TEMPLATE_CATEGORY_ID && description
          ? this.prepareDescriptionForCelumTemplate(description)
          : description
      ),
      repositoryId: new UntypedFormControl(''),
      ...this.resolveAdditionalFormControls()
    };

    Object.keys(formControls).forEach(key => this.workroomWizardForm.addControl(key, formControls[key]));
  }

  private prepareDescriptionForCelumTemplate(description: string): string {
    const translation = this.translateService.instant(description);
    return `"{\\"ops\\":[{\\"insert\\":\\"${translation}\\\\n\\"}]}"`;
  }

  private resolveAdditionalFormControls(): { [key: string]: AbstractControl } {
    const WITH_CATEGORY = {
      categoryId: new UntypedFormControl({
        disabled: this.workroomWizardType.editMode,
        value: this.template.categoryId ?? PredefinedCategoryValues.PERSONAL
      })
    };
    const WITH_TEAMSPACE = {
      teamspaceId: new UntypedFormControl(null, [Validators.required])
    };

    return this.isTeamspaceRequired() ? WITH_TEAMSPACE : WITH_CATEGORY;
  }

  private isTeamspaceRequired(): boolean {
    return this.workroomWizardType.targetEntity === 'WORKROOM';
  }

  private preselectRepository(): void {
    if (notNullOrUndefined(this.template.contentHubRepositoryId)) {
      this.workroomWizardForm.get(this.repositoryIdControlName).setValue(this.template.contentHubRepositoryId);
    } else {
      this.store
        .select(selectContentHubRepositoryId)
        .pipe(
          take(1),
          filter(repositoryId => notNullOrUndefined(repositoryId))
        )
        .subscribe(repositoryId => {
          this.workroomWizardForm.get(this.repositoryIdControlName).setValue(repositoryId);
        });
    }
  }
}
