import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { SelectElement } from '../search-and-select.component';

export interface SearchAndSelectGenericItem {
  id: number;
}

export abstract class SearchAndSelectGenericService<T extends SearchAndSelectGenericItem> {
  public readonly displayedValue: string;
  public readonly noResultsKey: string;
  public readonly hasMoreKey?: string;
  public readonly hasMoreKeySingular?: string;
  public readonly hasMoreKeyPlural?: string;
  public readonly searchFieldLabelKey: string;
  public readonly placeholder: string;
  public readonly itemsToLoad: number;

  public readonly items$: Observable<T[]>;
  public readonly loading$: Observable<boolean>;
  public readonly hasMore$: Observable<boolean>;
  public readonly hasMoreAmount$: Observable<number>;

  public readonly elementSelected = new EventEmitter<SelectElement>();

  public abstract loadEntities(searchQuery?: string): void;
}
