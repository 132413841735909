import { Inject, Injectable, Injector, Type } from '@angular/core';
import { Observable, of } from 'rxjs';

import { IconConfiguration } from '@celum/common-components';
import { Person } from '@celum/work/app/core/model/entities/person';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import { BaseRobotConfigService } from '@celum/work/app/robots/components/robot-dialog-templates/base-robot/base-robot-config.service';
import { AssignmentAutomatorService } from '@celum/work/app/robots/services/assignment-automator.service';
import { ContentHubAutomatorService } from '@celum/work/app/robots/services/content-hub-automator.service';
import { DueDateAutomatorService } from '@celum/work/app/robots/services/due-date-automator.service';
import { RobotService } from '@celum/work/app/robots/services/robot.service';
import { findMatchingRobotConfigService } from '@celum/work/app/robots/services/robots-util';
import { RuleService } from '@celum/work/app/robots/services/rule.service';
import { TaskCreationAutomatorService } from '@celum/work/app/robots/services/task-creation-automator.service';

import { Workroom } from './../../core/model/entities/workroom/workroom.model';
import { ContentHubWatchCollectionsAutomatorService } from './content-hub-watch-collections-automator.service';
import {
  AutomatorSubType,
  AutomatorType,
  Robots,
  RobotTypes,
  RuleType
} from '../../core/model/entities/workroom/robot.model';
import { BaseRobot } from '../components/robot-dialog-templates/base-robot/base-robot.component';

@Injectable()
export class RobotsFactory {
  public static robotConfigMap: Record<RobotTypes, { titleKey?: string; iconName: string }> = {
    [AutomatorType.TASK_ASSIGNMENT]: {
      iconName: 'robot-assignee'
    },
    [AutomatorType.CONTENT_HUB]: {
      iconName: 'robot-content-hub'
    },
    [RuleType.TASK_CREATION_RESTRICTION]: {
      iconName: 'robot-task-create'
    },
    [RuleType.TASK_MANDATORY_ASSIGNMENT]: {
      iconName: 'robot-required-assignment'
    },
    [RuleType.TASK_EDITING_RESTRICTION]: {
      iconName: 'robot-task-list-owner'
    },
    [RuleType.TASK_MOVEMENT_RESTRICTION]: {
      iconName: 'robot-task-list-owner'
    },
    [AutomatorType.TASK_DUE_DATE]: {
      iconName: 'robot-due-date'
    },
    [AutomatorType.TASK_CREATION]: {
      iconName: 'robot-task-create'
    }
  };

  constructor(
    @Inject(BaseRobotConfigService) private baseRobotConfigServices: BaseRobotConfigService[],
    private injector: Injector
  ) {}

  public static getIcon(type: RobotTypes): IconConfiguration {
    return IconConfiguration.large(RobotsFactory.robotConfigMap[type].iconName);
  }

  public static getColorScheme(type: RobotTypes) {
    switch (type) {
      case RuleType.TASK_CREATION_RESTRICTION:
        return 'color-scheme-1';
      case RuleType.TASK_MANDATORY_ASSIGNMENT:
      case AutomatorType.TASK_ASSIGNMENT:
      case AutomatorType.TASK_CREATION:
        return 'color-scheme-3';
      case AutomatorType.CONTENT_HUB:
        return 'color-scheme-2';
      case AutomatorType.TASK_DUE_DATE:
        return 'color-scheme-4';
      case RuleType.TASK_EDITING_RESTRICTION:
      case RuleType.TASK_MOVEMENT_RESTRICTION:
        return 'color-scheme-2';
      default:
        throw new Error(`Tried to get color scheme for type ${type}`);
    }
  }

  public getService(automatorType: RobotTypes | AutomatorSubType, isGeneralRobot: boolean): RobotService {
    return isGeneralRobot ? this.getGeneralRobotService(automatorType) : this.getTaskListRobotService(automatorType);
  }

  public getTaskListRobotService(automatorType: RobotTypes | AutomatorSubType): RobotService {
    switch (automatorType) {
      case AutomatorType.TASK_DUE_DATE:
        return this.injector.get<DueDateAutomatorService>(DueDateAutomatorService);
      case AutomatorType.CONTENT_HUB:
      case AutomatorSubType.CONTENT_HUB_ASSETS:
      case AutomatorSubType.WORKROOMS_FILES:
        return this.injector.get<ContentHubAutomatorService>(ContentHubAutomatorService);
      case AutomatorType.TASK_ASSIGNMENT:
        return this.injector.get<AssignmentAutomatorService>(AssignmentAutomatorService);
      case AutomatorType.TASK_CREATION:
      case AutomatorSubType.AUTOMATIC_TASK_CREATION:
        return this.injector.get<TaskCreationAutomatorService>(TaskCreationAutomatorService);
      case RuleType.TASK_MOVEMENT_RESTRICTION:
      case RuleType.TASK_EDITING_RESTRICTION:
      case RuleType.TASK_MANDATORY_ASSIGNMENT:
      case RuleType.TASK_CREATION_RESTRICTION:
        return new RuleService(automatorType);
    }
  }

  public getGeneralRobotService(automatorType: RobotTypes | AutomatorSubType): RobotService {
    switch (automatorType) {
      case AutomatorType.CONTENT_HUB:
        return this.injector.get<ContentHubWatchCollectionsAutomatorService>(
          ContentHubWatchCollectionsAutomatorService
        );
    }
  }

  public getPossibleTaskListOwnerRuleTypes(): RuleType[] {
    return [RuleType.TASK_EDITING_RESTRICTION, RuleType.TASK_MOVEMENT_RESTRICTION];
  }

  public isRobotNotApplied(
    type: RobotTypes,
    sourceEntity: any,
    robot: Robots | null,
    isRobotBeingCreated: boolean,
    invitedPeople: Person[],
    teamspaceId?: number,
    workroomId?: number
  ): Observable<boolean> {
    if (!robot) {
      return of(false);
    }
    const robotConfigService = findMatchingRobotConfigService(this.baseRobotConfigServices, robot);
    return robotConfigService.isRobotNotApplied({
      robot,
      invitedPeople,
      sourceEntity,
      teamspaceId,
      type,
      workroomId,
      isRobotBeingCreated
    });
  }
}

export interface RobotChipConfig {
  icon: IconConfiguration;
  row1: string;
  row2: string;
  colorSchemeClass: string;
  editEvent?: RobotEditEvent;
}

export interface RobotEditEvent {
  robot: Robots;
  type: Type<BaseRobot<any>>;
}

export type RobotCreateEventSource = TaskList | Workroom;

export interface RobotCreateEvent {
  source: RobotCreateEventSource;
  type: Type<BaseRobot<any>>;
  robotSubType?: AutomatorSubType | RuleType;
}

export interface BaseRobotMenuItem {
  text: string;
}

export interface RobotMenuItem extends BaseRobotMenuItem {
  icon: IconConfiguration;
  robotType: RobotTypes;
  componentType: Type<BaseRobot<any>>;
  subMenuItems?: RobotSubMenuItem[];
}

export interface RobotSubMenuItem extends BaseRobotMenuItem {
  robotSubType: AutomatorSubType | RuleType;
}
