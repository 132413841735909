import { FormGroup, ValidatorFn } from '@angular/forms';
import { isEmpty } from 'lodash';

import { CustomFieldCardValidationErrors, CustomFieldCardValidationErrorsEnum } from './validation-errors.enum';
import { CustomFieldCardControlStructure } from '../custom-field-card.component';
import { CustomFieldChipsInputValidationErrorsEnum } from '../custom-field-chips-input/config/custom-field-chips-input-errors.enum';

export function customFieldCardValidator(): ValidatorFn {
  return (
    customFieldCardControl: FormGroup<CustomFieldCardControlStructure>
  ): CustomFieldCardValidationErrors | null => {
    const { name, chips } = customFieldCardControl.controls as CustomFieldCardControlStructure;
    const errors: CustomFieldCardValidationErrors = {};

    if (name.hasError('required')) {
      errors.NAME_REQUIRED = true;
    }

    if (name.hasError(CustomFieldCardValidationErrorsEnum.NAME_NOT_UNIQUE)) {
      errors.NAME_NOT_UNIQUE = true;
    }

    if (name.hasError(CustomFieldCardValidationErrorsEnum.NAME_IS_EMPTY)) {
      errors.NAME_IS_EMPTY = true;
    }

    if (chips && chips.hasError(CustomFieldChipsInputValidationErrorsEnum.CHIPS_NOT_DEFINED)) {
      errors.CHIPS_NOT_DEFINED = true;
    }

    if (chips && chips.hasError(CustomFieldChipsInputValidationErrorsEnum.CHIP_NAME_REQUIRED)) {
      errors.CHIP_NAME_REQUIRED = true;
    }

    return isEmpty(errors) ? null : errors;
  };
}
