import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { delay, distinctUntilChanged, filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { ProgressSnackbar, RemoveSnackbar, ShowSnackbar, SnackbarConfiguration } from '@celum/common-components';
import { UUIDGenerator } from '@celum/core';
import { CancellableService } from '@celum/work/app/core/cancellable.service';
import { TeamspacesLoaded } from '@celum/work/app/core/model/entities/teamspace';

import { BackendProviderService } from '../auth/backend-provider.service';

export const BYPASS_LONG_RUNNING_SNACKBAR = new HttpContextToken(() => true);

@Injectable({ providedIn: 'root' })
export class LongRequestInterceptor extends CancellableService implements HttpInterceptor {
  public static readonly LONG_REQUEST_SNACKBAR_ID = 'long-request';
  private static readonly LONG_REQUEST_THRESHOLD = 2500;
  private static readonly MESSAGE_SWITCH_TIME = 3000;
  private static readonly MINIMAL_SNACKBAR_DISPLAY_TIME = 1000;
  private static readonly DISMISS_FLAG = -1;

  private readonly longRequests = new BehaviorSubject<string[]>([]);

  constructor(
    private store: Store,
    private actions$: Actions
  ) {
    super();
    // ignore slow requests up to initial data load
    this.actions$.pipe(ofType(TeamspacesLoaded), take(1)).subscribe(() => this.startTrackingRequests());
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('http')) {
      return next.handle(req);
    }

    if (req.context?.get(BYPASS_LONG_RUNNING_SNACKBAR) === true) {
      return next.handle(req);
    }

    const service = BackendProviderService.services.find(serviceItem =>
      serviceItem.urls.map(url => url.host).includes(new URL(req.url).host)
    );
    if (!service) {
      return next.handle(req);
    }

    const requestId = UUIDGenerator.generateId();
    this.startRequest(requestId);
    return next.handle(req).pipe(finalize(() => this.finishRequest(requestId)));
  }

  private startTrackingRequests() {
    this.longRequests
      .pipe(
        map(requests => requests.length > 0),
        distinctUntilChanged(),
        switchMap(areThereLongRequests =>
          areThereLongRequests
            ? timer(0, LongRequestInterceptor.MESSAGE_SWITCH_TIME).pipe(
                map(i => i % 2),
                takeUntil(this.longRequests.pipe(filter(requests => requests.length === 0)))
              )
            : of(LongRequestInterceptor.DISMISS_FLAG).pipe(delay(LongRequestInterceptor.MINIMAL_SNACKBAR_DISPLAY_TIME))
        )
      )
      .subscribe(translationKeyPart => {
        if (translationKeyPart === LongRequestInterceptor.DISMISS_FLAG) {
          this.store.dispatch(new RemoveSnackbar(LongRequestInterceptor.LONG_REQUEST_SNACKBAR_ID));
        } else {
          this.store.dispatch(
            new ShowSnackbar(
              LongRequestInterceptor.LONG_REQUEST_SNACKBAR_ID,
              ProgressSnackbar,
              SnackbarConfiguration.progress(
                `LONG_OPERATION.SNACKBAR.MESSAGE_TYPE_${translationKeyPart}`
              ).withDismissible(true)
            )
          );
        }
      });
  }

  private startRequest(requestId: string) {
    this.withCancellation(
      requestId,
      of(requestId).pipe(delay(LongRequestInterceptor.LONG_REQUEST_THRESHOLD))
    ).subscribe(_ => this.longRequests.next([...this.longRequests.value, requestId]));
  }

  private finishRequest(requestId: string) {
    this.cancelPendingRequests(requestId);
    this.longRequests.next(this.longRequests.value.filter(id => id !== requestId));
  }
}
