<robot
  [title]="'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.TITLE'"
  [whenIcon]="contentHubFolderIcon"
  [thenIcon]="automatorIcon"
  [readonly]="isReadonly"
  [messageBoxes]="messageBoxes$ | async"
  [notApplied]="notApplied$ | async"
>
  <ng-container when>
    <span class="robot-dialog_content_text" [innerHTML]="'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.WHEN.PART_1' | translate"></span>

    <ng-container *ngIf="selectedCollections && !isReadonly">
      <mat-chip-option
          *ngFor="let collection of selectedCollections; trackBy:trackByFn"
          class="collection-chip cursor-default"
          disableRipple
          [selectable]='false'>
        <text-truncator
            class="robot-dialog_collection-chip_text"
            [text]="collection.folderName"
            [updateOnAfterViewChecked]="true"
            [attr.data-cy]="'files-tree-node-name'"></text-truncator>
        <celum-icon
          class="robot-dialog_collection-chip_remove-icon"
          [configuration]="cancelIcon"
          [clickable]="true"
          (click)="removeCollection(collection.folderId)">
        </celum-icon>
      </mat-chip-option>
    </ng-container>

    <ng-container *ngTemplateOutlet="collectionSelector"></ng-container>
    <span class="robot-dialog_content_text" [innerHTML]="'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.WHEN.PART_2' | translate"></span>
  </ng-container>
  <ng-container then>
    <span class="robot-dialog_content_text" [innerHTML]="'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.THEN' | translate"></span>
  </ng-container>
</robot>

<ng-template #collectionSelector>
  <ng-container *ngIf="selectedImportIntent?.collectionCounter > 0; then importIntentFolderTemplate; else folderSelectionTemplate"></ng-container>
  <ng-template #importIntentFolderTemplate>
    <mat-chip-option class="collection-chip cursor-default" disableRipple>
      <text-truncator class="robot-dialog_collection-chip_text" [text]="importIntentCollectionName$ | async"></text-truncator>
    </mat-chip-option>
  </ng-template>
  <ng-template #folderSelectionTemplate>
    <celum-icon
        *ngIf="!isReadonly"
        [matTooltip]="'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.SELECT_COLLECTION_TOOLTIP' | translate"
        class="folder-selector"
        data-cy="folder-selector"
        [configuration]="collectionIcon"
        (click)="openSelectCollectionsFolder()"
        [clickable]="true">
    </celum-icon>
  </ng-template>
</ng-template>
