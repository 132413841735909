import { Store } from '@ngrx/store';

import { EntityTranslator, EntityUtil } from '@celum/work/app/core/model';

import { Member, MemberType } from '../member/member.model';
import { selectAllMembers } from '../member/member.selectors';
import { Person } from '../person/person.model';
import { selectPersonEntities } from '../person/person.selectors';
import { Teamspace, TeamspaceType } from '../teamspace/teamspace.model';

export class TeamspaceTranslator implements EntityTranslator<Teamspace> {
  public translateToEntity(json: any): Teamspace {
    const id = json.id;
    return {
      id: id,
      name: json.name,
      createdById: json.createdById,
      entityType: TeamspaceType.instance(),
      externalId: json.externalId,
      active: json.active,
      workroomsUsed: json.workroomsUsed,
      workroomsLimit: json.workroomsLimit,
      workroomsFinished: json.workroomsFinished,
      monthly3DUsedAndReserved: json.monthly3DUsedAndReserved,
      monthly3DLimit: json.monthly3DLimit,
      active3DLicense: json.active3DLicense,
      storageLimitStatus: json.storageLimitStatus,
      usedStorageInGiB: json.usedStorageInGiB,
      availableStorageInGiB: json.availableStorageInGiB,

      repositories: json.repositories,
      persons: (store: Store<any>) =>
        EntityUtil.getRelatedEntities<Person>(
          store,
          selectAllMembers,
          selectPersonEntities,
          {
            id: id,
            key: MemberType.TEAMSPACE_REF
          },
          MemberType.PERSON_REF
        ),
      members: (store: Store<any>) =>
        EntityUtil.getRelationEntities<Member>(store, selectAllMembers, {
          id: id,
          key: MemberType.TEAMSPACE_REF
        })
    };
  }
}
