import { Directive, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { kebabCase } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { Entity } from '@celum/work/app/core/model';
import { WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import { TokenList, TokenStyle } from '@celum/work/app/robots/components/robot-dialog-templates/robot-components.model';
import { AutomatorService, AutomatorServiceArgs } from '@celum/work/app/robots/services/automator.service';
import { RobotsFactory } from '@celum/work/app/robots/services/robots-factory';
import { MessageBoxConfigType } from '@celum/work/app/shared/components/message/message-box';
import { PersonWithStatuses } from '@celum/work/app/workroom-wizard/services/workroom-wizard-people.service';

import {
  Automator,
  AutomatorSubType,
  AutomatorType,
  isAutomator,
  RobotActionType,
  Robots,
  RobotTriggerType,
  RobotTypes,
  RuleType
} from '../../../../core/model/entities/workroom/robot.model';
import { RobotDialogConfiguration } from '../../robot-dialog/robot-dialog-config';

@Directive()
export abstract class BaseRobot<T extends Entity<any, any>> {
  public templatePeople: PersonWithStatuses[];

  public teamspaceId: number;
  public workroomConfig: WorkroomConfiguration;
  public isReadonly: boolean;
  public robot: Robots | null;
  public sourceEntity: T;
  public robotSubType: AutomatorSubType | RuleType | null;
  public robotTaskListIcon: IconConfiguration;
  public colorSchemeClass: string;
  public selectedTriggerType: RobotTriggerType;
  public selectedActionType: RobotActionType;
  public selectedRuleType: RuleType;

  public automatorIcon: IconConfiguration;

  public repositoryId: string | null;

  public abstract readonly TARGETING_ROBOT_TYPE: RobotTypes;

  public whenTokens: { [key: string]: TokenList } = {};
  public thenTokens: { [key: string]: TokenList } = {};

  constructor(
    protected robotFactory: RobotsFactory,
    protected translateService: TranslateService,
    @Optional() protected automatorService: AutomatorService
  ) {}

  public get notApplied$(): Observable<boolean> {
    return this.robotFactory.isRobotNotApplied(
      this.TARGETING_ROBOT_TYPE,
      this.sourceEntity,
      this.getResult(),
      !this.robot,
      this.templatePeople,
      this.teamspaceId
    );
  }

  public get messageBoxes$(): Observable<MessageBoxConfigType[]> {
    return of([]);
  }

  protected get iconSize(): number {
    return !this.isReadonly ? 35 : 18;
  }

  public configure({ args }: RobotDialogConfiguration<T>): void {
    this.robot = args.robot;
    this.robotSubType = args.robotSubType;
    this.sourceEntity = args.sourceEntity;
    this.workroomConfig = args.workroomConfig;
    this.teamspaceId = args.teamspaceId;
    this.templatePeople = args.templatePeople;
    this.isReadonly = args.readonly;
    this.repositoryId = args.repositoryId;
    this.robotTaskListIcon = IconConfiguration.large('robot-list-icon').withIconSize(this.iconSize);
    this.colorSchemeClass = RobotsFactory.getColorScheme(this.TARGETING_ROBOT_TYPE);
    this.automatorIcon = RobotsFactory.getIcon(this.TARGETING_ROBOT_TYPE).withIconSize(this.iconSize);
    if (this.robot && isAutomator(this.robot)) {
      this.selectedTriggerType = this.robot.trigger.type;
      this.selectedActionType = this.robot.action.type;
    }

    if (this.robot && !isAutomator(this.robot)) {
      this.selectedRuleType = this.robot.type;
    }

    if (this.TARGETING_ROBOT_TYPE in AutomatorType) {
      this.preselectActionsAndTriggers();
    }
  }

  public abstract getResult(): Robots;

  public getAllActions(): Observable<RobotActionType[]> {
    const automatorServiceArgs = this.getAutomatorServiceArgs();
    return this.automatorService.getAllActions(automatorServiceArgs);
  }

  public getAllTriggers(): Observable<RobotTriggerType[]> {
    const automatorServiceArgs = this.getAutomatorServiceArgs();
    return this.automatorService.getAllTriggers(automatorServiceArgs);
  }

  public isValid(): boolean {
    return true;
  }

  public trackByIdxFn(idx: number): number {
    return idx;
  }

  public getWhenTokenStyle(translationsCategory: string, token: string) {
    return this.getStyleForToken(this.whenTokens[translationsCategory], token);
  }

  public getThenTokenStyle(translationsCategory: string, token: string) {
    return this.getStyleForToken(this.thenTokens[translationsCategory], token);
  }

  public getStyleForToken(tokenList: TokenList, token: string) {
    if (tokenList.componentTokens.has(token)) {
      return { order: tokenList.componentTokens.get(token).order };
    }
    return { display: 'none' };
  }

  public isTriggerDisabled(trigger: RobotTriggerType): Observable<boolean> {
    return this.automatorService
      .getPossibleTriggers(this.getAutomatorServiceArgs())
      .pipe(map(possibleTriggers => !possibleTriggers.includes(trigger)));
  }

  public isActionDisabled(action: RobotActionType): Observable<boolean> {
    return this.automatorService
      .getPossibleActions(this.getAutomatorServiceArgs())
      .pipe(map(possibleActions => !possibleActions.includes(action)));
  }

  protected tokenizeTranslation(translationKey: string): TokenList {
    const predefinedTextTokens = ['lineBreak'];
    let translatedText: string = this.translateService.instant(translationKey);
    translatedText = translatedText.replace(/ /g, '\u00a0'); // replace spaces with html spaces
    const removeCurlyBracesFn = (token: string) => token.substring(2, token.length - 2);
    const textTokens: TokenStyle[] = [];

    let componentTokens = translatedText.match(/{{.*?}}/g).map(removeCurlyBracesFn);
    const splitAllRegex = new RegExp(String.raw`{{(${componentTokens.join('|')})}}+`);
    componentTokens = componentTokens.filter(token => !predefinedTextTokens.includes(token));
    const componentTokensMap = new Map<string, TokenStyle>(componentTokens.map(i => [i, { order: -1 }]));

    const allTokens = translatedText.split(splitAllRegex);
    allTokens
      .filter(token => !!token)
      .forEach((token, index) => {
        if (predefinedTextTokens.includes(token)) {
          textTokens.push({
            order: index,
            class: kebabCase(token)
          });
          return;
        }

        if (componentTokensMap.has(token)) {
          componentTokensMap.get(token).order = index;
          componentTokensMap.get(token).class = kebabCase(token);
          return;
        }

        textTokens.push({
          order: index,
          text: token
        });
      });
    return { textTokens, componentTokens: componentTokensMap };
  }

  private preselectActionsAndTriggers(): void {
    const automatorServiceArgs = this.getAutomatorServiceArgs();
    this.automatorService.getAutomatorPreselection(automatorServiceArgs).subscribe(automatorPreselection => {
      this.selectedTriggerType = this.selectedTriggerType ?? automatorPreselection.triggerType;
      this.selectedActionType = this.selectedActionType ?? automatorPreselection.actionType;
    });
  }

  private getAutomatorServiceArgs(): AutomatorServiceArgs {
    const args: AutomatorServiceArgs = {
      workroomConfig: this.workroomConfig,
      sourceId: this.sourceEntity?.id,
      robotSubType: this.robotSubType,
      repositoryId: this.repositoryId,
      initialRobotState: this.robot as Automator
    };

    const result = this.getResult();

    if (result && isAutomator(result)) {
      args.currentRobotState = result;
    }

    return args;
  }
}
