import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, take } from 'rxjs/operators';

import {
  selectTeamspaceById,
  TeamspacesUpdateOne,
  TeamspaceUpdateUsedStorage
} from '@celum/work/app/core/model/entities/teamspace';
import { WorkroomConstants } from '@celum/work/app/shared/util';

@Injectable()
export class TeamspaceEffects {
  public updateUsedStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamspaceUpdateUsedStorage),
      mergeMap(({ teamspaceId, fileSize }) =>
        this.store.select(selectTeamspaceById(teamspaceId)).pipe(
          take(1),
          map(({ usedStorageInGiB }) =>
            TeamspacesUpdateOne({
              teamspaceUpdate: {
                id: teamspaceId,
                changes: {
                  usedStorageInGiB: usedStorageInGiB + fileSize / WorkroomConstants.GIB_IN_BYTES
                }
              }
            })
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>
  ) {}
}
