import { ContentItemTypes } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { FileFormat } from '@celum/work/app/core/model/entities/file-version/file-version.model';

export interface ContentFilterDTO {
  contentItemTypes?: ContentItemTypes[];
  searchTerm?: string;
  createdByIds?: string[];
  fileFormats?: FileFormat[];
}

export interface SectionItem<T = any> {
  id: any;
  text?: string;
  templateData?: any;
  templateType?: SectionItemTemplateType;
  value?: T;
  checked?: boolean;
  type?: FilterSectionItemType;
  placeholder?: string;
  dueDateBucket?: DueDateBucket;
}

export enum SectionItemTemplateType {
  ICON = 'ICON',
  AVATAR = 'AVATAR'
}

export enum DueDateBucket {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  THIS_WEEK = 'THIS_WEEK',
  NEXT_WEEK = 'NEXT_WEEK',
  LATER = 'LATER',
  OVERDUE = 'OVERDUE',
  NO_DUE_DATE = 'NO_DUE_DATE'
}

export interface Section<T> {
  title?: string;
  maxHeight?: string;
  height?: string;
  type: T | FilterSectionType;
  items: SectionItem[];
  searchValue?: string;
}

export interface FilterInfo<T> {
  sections: Section<T>[];
}

export enum FilterSectionType {
  SEARCH = 'SEARCH'
}

export enum FileFilterSectionType {
  CONTENT_TYPE = 'CONTENT_TYPE',
  MEDIA_TYPE = 'MEDIA_TYPE',
  UPLOADED_BY = 'UPLOADED_BY'
}

export enum TaskFilterSectionType {
  CREATED_BY = 'CREATED_BY',
  ASSIGNED_TO = 'ASSIGNED_TO',
  PRIORITY = 'PRIORITY'
}

export enum MyTaskFilterSectionType {
  DUE_DATE = 'DUE_DATE',
  WORKROOMS = 'WORKROOMS',
  PRIORITY = 'PRIORITY'
}

export enum FilterSectionItemType {
  CHECKBOX = 'CHECKBOX',
  SEARCH_FIELD = 'SEARCH_FIELD'
}

export enum SearchSectionType {
  ASSIGNED_TO = 'ASSIGNED_TO',
  CREATED_BY = 'CREATED_BY',
  UPLOADED_BY = 'UPLOADED_BY',
  WORKROOMS = 'WORKROOMS'
}
