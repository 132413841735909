import { ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, ViewEncapsulation } from '@angular/core';

import { ColorConstants } from '@celum/common-components';
import { Color } from '@celum/work/app/core/model';
import { ColorService } from '@celum/work/app/shared/util';

@Component({
  selector: 'clickable-popup-dialog',
  templateUrl: './clickable-popup-dialog.component.html',
  styleUrls: ['./clickable-popup-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClickablePopupDialogComponent implements OnChanges {
  public isHoverActive = false;
  public isMenuOpen = false;
  public triggerBackgroundColor: string;
  public triggerHoverBackgroundColor: string;
  public computedBackgroundColor: string;

  @Input() public triggerClass: string;
  @Input() public contentClass: string;
  @Input() public triggerTooltipTranslationKey: string;
  @Input() public showBackgroundColor = true;
  @Input() public backgroundColor: Color;

  public ngOnChanges(): void {
    this.triggerBackgroundColor = this.getColor(0.12, this.backgroundColor);
    this.triggerHoverBackgroundColor = this.getColor(0.08, this.backgroundColor);
    this.computedBackgroundColor = this.getBackgroundColor();
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.isHoverActive = true;
    this.computedBackgroundColor = this.getBackgroundColor();
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.isHoverActive = false;
    this.computedBackgroundColor = this.getBackgroundColor();
  }

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.stopPropagation();
  }

  public menuOpened(): void {
    this.isMenuOpen = true;
    this.computedBackgroundColor = this.getBackgroundColor();
  }

  public menuClosed(): void {
    this.isMenuOpen = false;
    this.computedBackgroundColor = this.getBackgroundColor();
  }

  private getBackgroundColor(): string {
    if (!this.backgroundColor && !this.isHoverActive && !this.isMenuOpen) {
      return 'transparent';
    }
    return this.isHoverActive || this.isMenuOpen ? this.triggerHoverBackgroundColor : this.triggerBackgroundColor;
  }

  private getColor(opacity: number, backgroundColor?: Color): string {
    if (backgroundColor) {
      return ColorService.getColorAsRgbString(backgroundColor, opacity);
    }
    return ColorService.rgbToRGBA(ColorService.hexToRGB(ColorConstants.BLUE_GRAY_800), opacity);
  }
}
