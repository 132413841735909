<celum-dialog (onCloseAll)="closeDialog()" class="select-folder-dialog">
  <celum-dialog-header cdkFocusInitial>
    {{ title | translate }}
  </celum-dialog-header>
  <celum-dialog-content>
    <button mat-stroked-button celum-button size="small" class="select-folder-dialog_button" (click)="createNewFolder()" tabindex="-1">
      <celum-icon [configuration]="plusIcon"></celum-icon>
      {{'FOLDER.DIALOG.CREATE.DEFAULT_NAME' | translate}}
    </button>
    <files-tree
      #filesTree
      class="select-folder-dialog_files-tree"
      [activeFolder]="target"
      [folders]="children$ | async"
      [paginationInformation]="paginationInformation$ | async"
      [disabledFolders]="disabledFolderIds"
      [rightSideTemplate]="robotIconTemplate"
      (expand)="loadChildren($event)"
      (fetchMore)="loadMoreForFolder($event)"
      (selectionChanged)="selectTargetFolder($event[0])"></files-tree>
</celum-dialog-content>
<celum-dialog-footer class="select-folder-dialog_footer" [color]="color">
<button class="select-folder-dialog_footer_button dialog-footer_btn darker" mat-button [disableRipple]="true"
  (click)="closeDialog()">
<celum-icon [clickable]="true" [configuration]="cancelIcon"></celum-icon>
</button>

<ng-container *ngIf="showSubmitSubMenu$ | async; then submitButtonWithSubMenuTemplate; else simpleSubmitButtonTemplate"></ng-container>
<ng-template #simpleSubmitButtonTemplate>
<button class="select-folder-dialog_footer_button"
    [disableRipple]="true"
    mat-button
    (click)="confirmDialog()">
  <celum-icon [clickable]="true" [configuration]="confirmIcon"></celum-icon>
</button>
</ng-template>
<ng-template #submitButtonWithSubMenuTemplate>
<button class="select-folder-dialog_footer_button"
    #submitButtonWithSubMenu
    [disableRipple]="true"
    mat-button
    [matMenuTriggerFor]="aboveMenu">
  <celum-icon [clickable]="true" [configuration]="confirmIcon"></celum-icon>
</button>
</ng-template>
</celum-dialog-footer>
</celum-dialog>

<mat-menu #aboveMenu="matMenu" yPosition="above">
<mat-list-item *ngFor="let subMenuConfig of submitSubMenuConfig; last as last; trackBy: trackByFn">
<button mat-menu-item
  [ngStyle]="{'min-width': submitButtonWidth}"
  (click)="confirmDialog(subMenuConfig.type)">
<span class="select-folder-dialog_footer_sub-menu">
  {{subMenuConfig.name | translate}}
  <celum-icon class="select-folder-dialog_footer_sub-menu_icon"
      *ngIf="subMenuConfig.tooltip"
      [clickable]="true"
      [configuration]="subMenuConfig.tooltipIcon"
      [matTooltip]="subMenuConfig.tooltip | translate"></celum-icon>
</span>
</button>
</mat-list-item>
</mat-menu>

<ng-template #robotIconTemplate let-node>
<current-workroom-defined-robots [folder]="node.folder">
</current-workroom-defined-robots>
</ng-template>
