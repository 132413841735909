import { createAction, props } from '@ngrx/store';

import { ItemLinkRelationType } from '@celum/work/app/core/model/entities/item-link/item-link.model';

export const ItemLinkSetItemLinksPanelExpandedState = createAction(
  '[ItemLink] Set Item Links Panel Expanded State',
  props<{ expanded: boolean }>()
);

export const ItemLinkChangeDisplayedItemLinksCount = createAction(
  '[ItemLink] Change Displayed Item Links Count For Relations Slice',
  props<{ count: number; itemLinkRelationType: ItemLinkRelationType }>()
);
