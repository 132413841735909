<robot
    class="due-date-automator"
    [title]="'ROBOTS.TASK_DUE_DATE.TITLE'"
    [whenIcon]="robotTaskListIcon"
    [thenIcon]="automatorIcon"
    [readonly]="isReadonly"
    [messageBoxes]="messageBoxes$ | async"
    [notApplied]="notApplied$ | async">
  <ng-container when>
    <ng-container *ngFor="let token of getWhenTokens(); trackBy: trackByIdxFn">
      <span
          class="robot-dialog_content_text"
          [style.order]="token.order">
        {{ token.text }}
      </span>
    </ng-container>

    <ng-container *ngIf="isReadonly; else triggerSelect">
      <strong [ngStyle]="getWhenTokenStyle('trigger')"> {{ ('ROBOTS.TASK_DUE_DATE.TRIGGER.' + selectedTriggerType) | translate }} </strong>
    </ng-container>

    <ng-template #triggerSelect>
      <mat-form-field celum-form-field size="small" class="robot-dialog_content_form-field robot-dialog_content_trigger" [ngStyle]="getWhenTokenStyle('trigger')">
        <mat-select data-cy="trigger-selection" [(value)]="selectedTriggerType">
          <mat-option
              *ngFor="let trigger of getAllTriggers() | async; trackBy: trackByIdxFn"
              [attr.data-cy]="'trigger-option-' + trigger"
              [value]="trigger"
              [disabled]="isTriggerDisabled(trigger) | async">
            {{ ('ROBOTS.TASK_DUE_DATE.TRIGGER.' + trigger) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <span
        class="robot-dialog_content_text"
        [ngStyle]="getWhenTokenStyle('taskList')">
      <strong>{{ sourceEntity.name | uppercase }}</strong>
    </span>
  </ng-container>

  <ng-container then>
    <span class="robot-dialog_content_text" *ngFor="let token of getThenTokens(); trackBy: trackByIdxFn" [style.order]="token.order">{{ token.text }}</span>

    <ng-container *ngIf="isReadonly; else actionSelect">
      <span class="robot-dialog_content_text" data-cy="action-selection" [ngStyle]="getThenTokenStyle('action')">
      <strong> {{ ('ROBOTS.TASK_DUE_DATE.ACTION.' + selectedActionType) | translate }} </strong>
    </span>
    </ng-container>

    <ng-template #actionSelect>
      <mat-form-field celum-form-field size="small" class="robot-dialog_content_form-field robot-dialog_content_action" [ngStyle]="getThenTokenStyle('action')">
        <mat-select data-cy="action-selection" [(value)]="selectedActionType">
          <mat-option
              *ngFor="let action of getAllActions() | async; trackBy: trackByIdxFn"
              [attr.data-cy]="'action-option-' + action"
              [value]="action"
              [disabled]="isActionDisabled(action) | async">
            {{ ('ROBOTS.TASK_DUE_DATE.ACTION.' + action) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <ng-container *ngIf="isReadonly; else daysCountInput">
      <strong [ngStyle]="getThenTokenStyle('daysCount')">{{ daysCount }}</strong>
    </ng-container>

    <ng-template #daysCountInput>
      <mat-form-field celum-form-field [ngStyle]="getThenTokenStyle('daysCount')" class="robot-dialog_content_form-field robot-dialog_content_form-field--small">
        <input data-cy="days-count" type="number" matInput [(ngModel)]="daysCount">
      </mat-form-field>
    </ng-template>

    <ng-container *ngIf="isReadonly; else dueDateSelect">
      <strong [ngStyle]="getThenTokenStyle('dueDate')">{{ dueDate | localizedDate: dateFormats.LONG }}</strong>
    </ng-container>

    <ng-template #dueDateSelect>
      <due-date [ngStyle]="getThenTokenStyle('dueDate')" [backgroundColor]="'rgba(207, 216, 220, 0.5)'" [(dueDate)]="dueDate"></due-date>
    </ng-template>

  </ng-container>
</robot>
