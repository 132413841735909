import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@celum/authentication';
import { PortalImportGuard } from '@celum/work/app/core/auth/portal-import.guard';
import { TenantGuard } from '@celum/work/app/core/auth/tenant.guard';
import { InitialDataLoadedGuard } from '@celum/work/app/initial-data-loaded.guard';

const routes: Routes = [
  {
    path: 'logout',
    loadComponent: () => import('@celum/authentication').then(mod => mod.LogoutComponent)
  },
  {
    path: '',
    canActivate: [AuthGuard, TenantGuard, InitialDataLoadedGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@celum/work/app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [PortalImportGuard]
      },
      {
        path: 'workroom',
        loadChildren: () => import('@celum/work/app/pages/workroom/workroom.module').then(m => m.WorkroomModule)
      },
      {
        path: 'advanced-search',
        loadComponent: () =>
          import('@celum/work/app/pages/advanced-search/components/advanced-search/advanced-search.component').then(
            m => m.AdvancedSearchComponent
          ),
        canMatch: [() => (window as any).Celum.properties.features.advancedSearch === true]
      },
      {
        path: '**',
        redirectTo: 'dashboard'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      enableTracing: false,
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: [AuthGuard, TenantGuard, InitialDataLoadedGuard]
})
export class AppRoutingModule {}
