import { Language } from '@celum/work/app/core/ui-state/ui-state.model';

export class WorkroomConstants {
  public static ROUTER_STATE_KEY = 'router.state';
  public static GIB_IN_BYTES = 1_073_741_824;
  public static VISIBLE_COLUMNS_ATTRIBUTE = 'visible-columns';
  public static COLLABORATION_SEARCH_TYPE = 'activity_stream_filter';
  public static LOCAL_STORAGE_SHOW_PREVIEW = 'showPreview';
  public static LOCAL_STORAGE_IS_CAROUSEL_OPENED = 'IS_CAROUSEL_OPENED';
  public static LOCAL_STORAGE_IS_FILE_NAVIGATION_MENU_OPENED = 'IS_FILE_NAVIGATION_MENU_OPENED';
  public static LOCAL_STORAGE_IS_SUBTASK_SECTION_OPENED = 'IS_SUBTASK_OPENED';
  public static LOCAL_STORAGE_IS_LINKED_TASKS_SECTION_OPENED = 'IS_LINKED_TASKS_OPENED';
  public static LOCAL_STORAGE_IS_FORM_SECTION_OPENED = 'IS_FORM_OPENED';
  public static CARD_VIEW_MODE = 'CARD_VIEW_MODE';
  public static FILTERS = 'FILTERS';

  public static QUERY_PARAMS = {
    SELECTED_FOLDER: 'selectedFolder',
    TEMPLATE_CREATOR: 'templateCreator',
    TEMPLATE_MANAGER: 'templateManager'
  };

  public static LOADING_PREVIEW_PATH = '/assets/images/loading-dots.svg';
  public static LOADING_PREVIEW_TIMEOUT = 900;

  public static NO_PREVIEW_ASSET_PATH = '/assets/images/no-preview.svg';

  public static FILE_DELETE_ON_CH = {
    [Language.ENGLISH]: '/assets/images/en-file-deleted-ch.svg',
    [Language.GERMAN]: '/assets/images/de-file-deleted-ch.svg',
    [Language.FRENCH]: '/assets/images/en-file-deleted-ch.svg' // TODO: replace with french file deleted on ch image
  };

  public static DISCONNECTED_FROM_CH = 'disconnected-from-ch';
  public static NO_PREVIEW = 'no-preview';
  public static FILE_DELETED = 'file-deleted';
  public static AUDIO_PREVIEW = 'assets/images/filetypes/filetype-audio-l.svg';
  public static MP3_PLAYED = 'assets/images/filetypes/filetype-mp3-l-played.svg';

  public static CH_BUILD_NUMBER_REGEX = /([.0-9])+/;
}

export type Size = 'small' | 'medium' | 'large' | 'extra-large';
