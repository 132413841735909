import { createSelector } from '@ngrx/store';

import { personAdapter } from './person.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll, selectIds } = personAdapter.getSelectors();

export const selectPersons = createSelector(selectEntitiesState, (state: EntitiesState) => state.persons);

export const selectAllPersons = createSelector(selectPersons, selectAll);
export const selectAllPersonsIds = createSelector(selectPersons, selectIds);
export const selectPersonEntities = createSelector(selectPersons, selectEntities);

export const selectPersonById = (id: number) =>
  createSelector(selectPersonEntities, personDictionary => personDictionary[id]);
export const selectPersonsByIds = (ids: number[]) =>
  createSelector(selectPersonEntities, persons => ids.map(id => persons[id]));
