import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IconConfiguration } from '@celum/common-components';

import { RobotMenuItem, RobotsFactory } from './robots-factory';
import { AutomatorSubType, AutomatorType, RuleType } from '../../core/model/entities/workroom/robot.model';
import { AssignmentAutomatorComponent } from '../components/robot-dialog-templates/assignment-automator/assignment-automator.component';
import { ContentHubAutomatorComponent } from '../components/robot-dialog-templates/content-hub-automator/content-hub-automator.component';
import { CreateRestrictionRuleComponent } from '../components/robot-dialog-templates/create-restriction-rule/create-restriction-rule.component';
import { DueDateAutomatorComponent } from '../components/robot-dialog-templates/due-date-automator/due-date-automator.component';
import { MandatoryAssignmentRuleComponent } from '../components/robot-dialog-templates/mandatory-assignment-rule/mandatory-assignment-rule.component';
import { TaskCreationAutomatorComponent } from '../components/robot-dialog-templates/task-creation-automator/task-creation-automator.component';
import { TaskListOwnerRuleComponent } from '../components/robot-dialog-templates/task-list-owner-rule/task-list-owner-rule.component';
import { WatchCollectionsAutomatorComponent } from '../components/robot-dialog-templates/watch-collections-automator/watch-collections-automator.component';

@Injectable({ providedIn: 'root' })
export class RobotsMenuItemFactory {
  constructor(private translate: TranslateService) {}

  public getRobotsMenuItems(isForGeneralRobots: boolean): RobotMenuItem[] {
    return isForGeneralRobots ? this.getGeneralRobotsMenuItems() : this.getTasklistRobotsMenuItems();
  }

  public getTasklistRobotsMenuItems(): RobotMenuItem[] {
    const menuItems = [
      {
        text: this.translate.instant('ROBOTS.MANDATORY_ASSIGNMENT.TITLE'),
        robotType: RuleType.TASK_MANDATORY_ASSIGNMENT,
        componentType: MandatoryAssignmentRuleComponent
      },
      {
        text: this.translate.instant('ROBOTS.TASK_MANAGEMENT.TITLE'),
        robotType: AutomatorType.TASK_CREATION,
        componentType: TaskCreationAutomatorComponent,
        subMenuItems: [
          {
            text: this.translate.instant('ROBOTS.TASK_MANAGEMENT.SUB_MENU_TITLE.AUTOMATIC_CREATION'),
            robotSubType: AutomatorSubType.AUTOMATIC_TASK_CREATION,
            componentType: TaskCreationAutomatorComponent
          },
          {
            text: this.translate.instant('ROBOTS.TASK_MANAGEMENT.SUB_MENU_TITLE.TASK_CREATION_RESTRICTION'),
            robotSubType: RuleType.TASK_CREATION_RESTRICTION,
            componentType: CreateRestrictionRuleComponent
          }
        ]
      },
      {
        text: this.translate.instant('ROBOTS.CONTENT_HUB.TITLE'),
        robotType: AutomatorType.CONTENT_HUB,
        componentType: ContentHubAutomatorComponent,
        subMenuItems: [
          {
            text: this.translate.instant('ROBOTS.CONTENT_HUB.SUB_MENU_TITLE.CONTENT_HUB_ASSETS'),
            robotSubType: AutomatorSubType.CONTENT_HUB_ASSETS
          },
          {
            text: this.translate.instant('ROBOTS.CONTENT_HUB.SUB_MENU_TITLE.WORKROOMS_FILES'),
            robotSubType: AutomatorSubType.WORKROOMS_FILES
          }
        ]
      },
      {
        text: this.translate.instant('ROBOTS.ASSIGNMENT.TITLE'),
        robotType: AutomatorType.TASK_ASSIGNMENT,
        componentType: AssignmentAutomatorComponent
      },
      {
        text: this.translate.instant('ROBOTS.TASK_LIST_OWNER.TITLE'),
        robotType: RuleType.TASK_EDITING_RESTRICTION,
        componentType: TaskListOwnerRuleComponent
      },
      {
        text: this.translate.instant('ROBOTS.TASK_DUE_DATE.TITLE'),
        robotType: AutomatorType.TASK_DUE_DATE,
        componentType: DueDateAutomatorComponent
      }
    ];
    return menuItems.map(item => {
      return {
        ...item,
        icon: RobotsFactory.getIcon(item.robotType)
      };
    });
  }

  public getGeneralRobotsMenuItems(): RobotMenuItem[] {
    const menuItems = [
      {
        text: this.translate.instant('ROBOTS.CONTENT_HUB.WATCH_COLLECTION.TITLE'),
        robotType: AutomatorType.CONTENT_HUB,
        componentType: WatchCollectionsAutomatorComponent
      }
    ];
    return menuItems.map(item => {
      return {
        ...item,
        icon: IconConfiguration.large('icon-content-hub-folder')
      };
    });
  }
}
