import { Injectable } from '@angular/core';
import Quill from 'quill';

@Injectable()
export class QuillInitializeService {
  constructor() {
    const link = Quill.import('formats/link');
    link.sanitize = (url: string) => {
      return (url || ``).startsWith(`http`) ? url : `https://${url}`;
    };
  }
}

const Link = Quill.import('formats/link');

class MyLink extends Link {
  public static create(value) {
    const node = super.create(value);
    let newValue = this.sanitize(value);
    if (!newValue.startsWith('http')) {
      newValue = 'http://' + value;
    }
    node.setAttribute('href', newValue);
    return node;
  }
}
Quill.register(MyLink);
