import { ContentHub612XStrategy } from '@celum/work/app/content-hub/model/content-hub-6-12-x-strategy';

import { ContentHubStrategy } from './content-hub-strategy';

export class ContentHub617XStrategy extends ContentHubStrategy {
  public defaultStrategy(): ContentHubStrategy {
    return new ContentHub612XStrategy();
  }

  public buildNumber(): string {
    return '6.17.0';
  }

  public supportsStringContentItemIds(): boolean {
    return true;
  }
}
