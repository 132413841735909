<div class="files-tree">
  <div #scrollableContainer class="flex-column-scrollbar celum-scrollbar">
    <mat-tree
        [dataSource]="dataSource"
        [treeControl]="treeControl"
        class="files-tree_tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node #treeNode *matTreeNodeDef="let node" matTreeNodeToggle>

        <div *ngIf="node?.folder.id === activeFolder?.id" class="mat-tree-node--selected"></div>

        <div class="files-tree_node-wrapper">
          <li class="mat-tree-node mat-tree-node_underline"
              [attr.data-cy]="'files-tree-node-' + node.folder.id"
              [attr.strict-node]="isStrictFn(node)"
              (click)="selectFolder(node, multiSelection ? !node.checked : undefined)"
              (contextmenu)="handleContextMenu($event, node?.folder)"
              [class.files-tree_node--disabled]="node?.disabled"
              [class.mat-tree-node--has-right-side]="leafNodeRightSide.childElementCount > 0">

            <celum-icon
                class="files-tree_folder-icon"
                [matTooltip]="'CONTENT_HUB.STRICT_NODE.TOOLTIP' | translate"
                [matTooltipDisabled]="!isStrictFn(node)"
                [configuration]="resolveNodeIcon(node)"
                (click)="multiSelection ? $event.stopPropagation() : false"></celum-icon>
            <text-truncator
                [class.files-tree_node--disabled]="isStrictFn(node)"
                class="files-tree_name"
                [text]="node.folder.name"
                [updateOnAfterViewChecked]="true"
                [attr.data-cy]="'files-tree-node-name'"></text-truncator>
          </li>

          <div class="files-tree_right-side" #leafNodeRightSide>
            <ng-container *ngTemplateOutlet="rightSideTemplate; context: {$implicit:node}"></ng-container>
          </div>
          <ng-container *ngIf="multiSelection">
            <mat-checkbox
                class="files-tree_checkbox"
                [checked]="node.checked"
                [attr.data-cy]="'files-tree-node-checkbox-' + node.folder.id"
                (change)="selectFolder(node, $event.checked)">
            </mat-checkbox>
          </ng-container>
        </div>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">

        <div *ngIf="node?.folder.id === activeFolder?.id" class="mat-tree-node--selected"></div>

        <li [attr.is-expanded]="treeControl.isExpanded(node)">
          <div class="files-tree_node-wrapper">
            <div
                class="mat-tree-node mat-tree-node_underline"
                [class.files-tree_node--disabled]="node?.disabled"
                [class.mat-tree-node--has-right-side]="expandableNodeRightSide.childElementCount > 0"
                [attr.data-cy]="'files-tree-node-' + node.folder.id"
                [attr.strict-node]="isStrictFn(node)"
                (click)="selectFolder(node, multiSelection ? !node.checked : undefined)"
                (contextmenu)="handleContextMenu($event, node?.folder)"
                (dblclick)="toggle(node)">

              <celum-icon
                  matTreeNodeToggle class="files-tree_expand-icon"
                  [class.files-tree_expand-icon--disabled]="expandIconShouldBeDisabled(node)"
                  [configuration]="treeControl.isExpanded(node) ? expandedIcon : collapsedIcon"
                  [matTooltip]="(treeControl.isExpanded(node) ? 'FILES_TREE.COLLAPSE_TREE_STRUCTURE' : 'FILES_TREE.EXPAND_TREE_STRUCTURE') | translate"
                  [attr.aria-label]="'toggle ' + node.name"
                  (click)="loadChildren(node)">
              </celum-icon>

              <celum-icon
                  class="files-tree_folder-icon"
                  [matTooltip]="'CONTENT_HUB.STRICT_NODE.TOOLTIP' | translate"
                  [matTooltipDisabled]="!isStrictFn(node)"
                  [configuration]="resolveNodeIcon(node)"
                  (click)="multiSelection ? $event.stopPropagation() : false"></celum-icon>
              <text-truncator
                  [class.files-tree_node--disabled]="isStrictFn(node)"
                  class="files-tree_name"
                  [text]="node.folder.name"
                  [updateOnAfterViewChecked]="true"
                  [attr.data-cy]="'files-tree-node-name'"></text-truncator>
            </div>

            <div class="files-tree_right-side" #expandableNodeRightSide>
              <ng-container *ngTemplateOutlet="rightSideTemplate; context: {$implicit:node}"></ng-container>
            </div>
            <ng-container *ngIf="multiSelection">
              <mat-checkbox class="files-tree_checkbox" [checked]="node.checked" (change)="selectFolder(node, $event.checked)"></mat-checkbox>
            </ng-container>
          </div>

          <ul class="files-tree_child" [class.files-tree--invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>

      <!-- This is the tree node template for load more nodes -->
      <mat-tree-node
          *matTreeNodeDef="let node; when: isLoadMore"
          class="mat-tree-node files-tree_load-more"
          data-cy="files-tree-load-more"
          [class.files-tree_load-more-root]="!node.rootParent"
          (click)="fetchNextBatch(node)"
          matTreeNodeToggle>
        {{ 'FILES_TREE.LOAD_MORE' | translate | uppercase }}
      </mat-tree-node>

    </mat-tree>
  </div>
  <ng-content></ng-content>
</div>


