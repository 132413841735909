import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, forkJoin, NEVER, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { DataUtil } from '@celum/core';
import { DomHelper } from '@celum/ng2base';
import { ActivityKey } from '@celum/work/app/collaboration-properties/components/activity/activity.constants';
import { selectWorkroomConfiguredForContentHub } from '@celum/work/app/content-hub/store/content-hub.selectors';
import { CollaborationPropertyService } from '@celum/work/app/core/api/collaboration-item/collaboration-property.service';
import { Permission, RoleName } from '@celum/work/app/core/api/permission';
import { FolderSubType } from '@celum/work/app/core/model/entities/folder/folder.model';
import { selectFoldersForCurrentWorkroom } from '@celum/work/app/core/model/entities/folder/folder.selector';
import { Person } from '@celum/work/app/core/model/entities/person';
import { WorkroomGroup } from '@celum/work/app/core/model/entities/workroom/group/workroom-group.model';
import { selectAllWorkroomGroups } from '@celum/work/app/core/model/entities/workroom/group/workroom-group.selectors';
import { selectAllWorkroomGroupItems } from '@celum/work/app/core/model/entities/workroom/group-item/workroom-group-item.selectors';
import { selectFileVersionsByFileVersionIds } from '@celum/work/app/files/detail/version-switcher/store/version-switcher.selectors';
import { selectSelectedFolderId } from '@celum/work/app/files/store/files-tree/files-tree.selector';
import {
  WorkroomGroupService,
  WORKROOOM_GROUP_EVENT
} from '@celum/work/app/pages/dashboard/components/workroom-group-list/components/workroom-group/workroom-group.service';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { PermissionUtil } from '@celum/work/app/shared/util';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';
import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';
import { WorkroomConstants } from '@celum/work/app/shared/util/workroom-constants';
import { TaskListService } from '@celum/work/app/task-list/services/task-list.service';

import { UserPilotInfo } from './user-pilot-info';
import { Roles } from '../model';
import { selectCurrentFileId } from '../model/entities/file/file.selectors';
import {
  selectCurrentTask,
  selectCurrentWorkroomHasTaskListsWithTaskCreationEnabled,
  selectCurrentWorkroomTaskLists,
  selectTasksOfCurrentWorkroom
} from '../model/entities/task';
import { selectHasRoleInAnyTeamspace } from '../model/entities/teamspace';
import {
  selectAllWorkrooms,
  selectCurrentWorkroomIdParam,
  selectPermissionsForWorkroom
} from '../model/entities/workroom';
import {
  selectLoggedInPerson,
  selectLoggedInPersonId,
  selectTenant,
  selectUiStateLanguage
} from '../ui-state/ui-state.selectors';
import { VersionService } from '../version/version.service';

@Injectable({ providedIn: 'root' })
export class UserPilot {
  public taskCreatedCount: number;
  public fileCreatedCount: number;
  private userpilotEnabled: boolean;
  private userPilotApiKey: string;
  private isInitialized: boolean;

  private readonly workIsTaskListOwner$: Observable<boolean> = combineLatest([
    this.store.select(selectCurrentWorkroomTaskLists),
    this.store.select(selectLoggedInPersonId)
  ]).pipe(
    switchMap(([taskLists, loggedInPersonId]) =>
      of(taskLists.some(taskList => taskList.ownerIds.includes(loggedInPersonId)))
    )
  );

  private readonly workIsTaskAssignee$: Observable<boolean> = combineLatest([
    this.store.select(selectTasksOfCurrentWorkroom),
    this.store.select(selectLoggedInPersonId)
  ]).pipe(switchMap(([tasks, personId]) => of(tasks.some(task => task.assigneeIds.includes(personId)))));

  private readonly workIsFileAttached$: Observable<boolean> = this.store
    .select(selectCurrentTask)
    .pipe(map(task => (task?.attachmentIds ? task.attachmentIds.length > 0 : null)));

  private readonly workHasMultipleVersions$: Observable<boolean> = this.store
    .select(selectFileVersionsByFileVersionIds)
    .pipe(map(fileVersions => fileVersions?.length > 1));

  private readonly workCanAccessAnyWorkroom$: Observable<boolean> = combineLatest([
    this.store.select(selectAllWorkrooms),
    this.store.select(selectLoggedInPerson)
  ]).pipe(
    switchMap(([workrooms, person]) => {
      return of(
        workrooms.findIndex(wr =>
          this.permissionUtil.hasAnyWorkroomRole([Roles.MODERATOR, Roles.CONTRIBUTOR], person, wr)
        ) >= 0
      );
    })
  );

  private readonly workCanCreateTasks$ = this.store.select(selectCurrentWorkroomHasTaskListsWithTaskCreationEnabled);

  private readonly workCanManageTasks$: Observable<boolean> = combineLatest([
    this.store.select(selectTasksOfCurrentWorkroom),
    this.store.select(selectCurrentWorkroomTaskLists),
    this.store.select(selectCurrentWorkroom)
  ]).pipe(
    map(([tasks]) => [...new Set(tasks.map(task => task.taskListId))]),
    map(nonEmptyTaskListIds =>
      nonEmptyTaskListIds.map(taskListId =>
        this.taskListService.hasPermissionForTaskListId(Permission.TASK_UPDATE, taskListId)
      )
    ),
    switchMap(hasPermissionObs => (hasPermissionObs.length > 0 ? forkJoin([...hasPermissionObs]) : of([false]))),
    map(canManageTaskPerTaskList => canManageTaskPerTaskList.some(b => b))
  );

  private readonly workIsGroupExpanded$ = combineLatest([
    this.eventBus.observeEvents(NEVER, WORKROOOM_GROUP_EVENT.EXPAND_STATE_CHANGED),
    this.store.select(selectAllWorkroomGroupItems)
  ]).pipe(
    map(([expandChangeEvent, groupItems]) =>
      groupItems.some(groupItem => (expandChangeEvent as any).data[groupItem.groupId] !== false)
    )
  );

  private readonly workIsGroupExpandedLocalstorageState$ = this.store.select(selectAllWorkroomGroupItems).pipe(
    take(1),
    map(groupItems => {
      const expandState = this.workroomGroupService.getWorkroomGroupExpandState();
      return groupItems.some(groupItem => expandState[groupItem.groupId] !== false);
    })
  );

  private readonly workHasOtherGroups$ = this.store
    .select(selectAllWorkroomGroups)
    .pipe(map(allWorkroomGroups => allWorkroomGroups.filter((group: WorkroomGroup) => group.editable).length > 0));

  private readonly workHasFolder$ = this.store
    .select(selectFoldersForCurrentWorkroom)
    .pipe(
      map(
        foldersForCurrentWorkroom =>
          foldersForCurrentWorkroom?.filter(folder => folder.folderSubType === FolderSubType.ORDINARY).length > 0
      )
    );

  private readonly workIsTransitionDefined$ = this.store
    .select(selectCurrentWorkroom)
    .pipe(map(currentWorkroom => currentWorkroom?.configuration?.transitions?.length > 0));
  private readonly workIsRobotDefined$ = this.store
    .select(selectCurrentWorkroom)
    .pipe(map(currentWorkroom => currentWorkroom?.configuration?.rules?.length > 0));
  private readonly workHasPeople$ = this.store
    .select(selectCurrentWorkroom)
    .pipe(map(currentWorkroom => currentWorkroom?.contributorIds.length > 1));
  private readonly workHasRepo$ = this.store
    .select(selectCurrentWorkroom)
    .pipe(map(currentWorkroom => !!currentWorkroom?.contentHubRepositoryId));
  private readonly workHasDrive$ = this.store
    .select(selectCurrentWorkroom)
    .pipe(map(currentWorkroom => currentWorkroom?.driveSubscribed));

  private readonly workCanManageTemplates$ = this.permissionUtil.hasTeamspaceRole(RoleName.TEMPLATE_MAINTAINER);
  private readonly workIsContributor$ = this.permissionUtil.hasRoleForCurrentWorkroom(Roles.CONTRIBUTOR);
  private readonly workIsModerator$ = this.permissionUtil.hasRoleForCurrentWorkroom(Roles.MODERATOR);
  private readonly workCanCreateWr$ = this.store.select(selectHasRoleInAnyTeamspace(RoleName.WORKROOM_CREATOR));
  private readonly workCanFinishWorkrooms$ = this.permissionUtil.hasTeamspacePermission(Permission.WORKROOM_FINISH);
  private readonly workHasCelumContent$ = this.store.select(selectWorkroomConfiguredForContentHub);
  private readonly workIsCelumUser$ = this.store
    .select(selectLoggedInPerson)
    .pipe(map(user => user.email.includes('celum')));

  constructor(
    private router: Router,
    private store: Store<any>,
    private versionService: VersionService,
    private permissionUtil: PermissionUtil,
    private taskListService: TaskListService,
    private eventBus: ApplicationEventBus,
    private workroomGroupService: WorkroomGroupService,
    private collaborationPropertyService: CollaborationPropertyService
  ) {
    this.userpilotEnabled = (window as any).Celum.properties.features.userPilot;
    this.userPilotApiKey = (window as any).Celum.properties.userPilotApiKey;
    this.initializeIfConfigured();
  }

  /**
   * This checks the relevant property and includes the user-pilot script if necessary.
   * It will reload on navigation end events as described in https://docs.userpilot.com/article/59-installation-guide-for-single-page-applications-spas
   * It will update the user info depending on which actions can be executed in current context.
   */
  public initializeIfConfigured(): void {
    const userPilotApiKey = (window as any).Celum.properties.userPilotApiKey;

    if (!this.isInitialized && !DataUtil.isEmpty(userPilotApiKey) && this.userpilotEnabled) {
      DomHelper.addScript(`https://js.userpilot.io/${this.userPilotApiKey}/latest.js`).onload = () => {
        this.store
          .select(selectLoggedInPerson)
          .pipe(
            filter(person => notNullOrUndefined(person)),
            take(1)
          )
          .subscribe(person => {
            this.loadInitialState(person.oid);
            this.listenForAppChangesToSetUserPilotProperties(person);
          });
        this.updateOnNavigation();
        this.isInitialized = true;
      };
    }
  }

  public triggerTour(): void {
    this.store.select(selectUiStateLanguage).subscribe(uiLanguage => {
      if (this.userpilotEnabled) {
        const userPilotApiKey = (window as any).Celum.properties.userPilotApiKey;
        if (userPilotApiKey) {
          window.userpilot.trigger(this.getTourKey(uiLanguage));
        }
      }
    });
  }

  public setProperty(personOid: string, userPilotInfoKey: keyof UserPilotInfo, value: any) {
    if (!this.isInitialized || !this.userpilotEnabled) {
      return;
    }

    window.userpilot.identify(personOid, { [userPilotInfoKey]: value } as UserPilotInfo);
  }

  private loadInitialState(personOid: string): void {
    this.collaborationPropertyService
      .countActivitiesByKey({
        onlyLoggedIn: true,
        activities: [
          ActivityKey.WORKROOM_CREATED,
          ActivityKey.WORKROOM_CONTRIBUTOR_ADDED,
          ActivityKey.TASK_CREATED,
          ActivityKey.FILE_CREATED,
          ActivityKey.TASK_CREATED,
          ActivityKey.FILE_CREATED
        ]
      })
      .pipe(
        tap(counts => {
          this.taskCreatedCount = counts[ActivityKey.TASK_CREATED];
          this.fileCreatedCount = counts[ActivityKey.FILE_CREATED];
        }),
        map(counts => {
          return {
            workNoWorkroom: counts[ActivityKey.WORKROOM_CREATED] === 0,
            workNoInvite: counts[ActivityKey.WORKROOM_CONTRIBUTOR_ADDED] === 0,
            workNoTask: counts[ActivityKey.TASK_CREATED] === 0,
            workNoFile: counts[ActivityKey.FILE_CREATED] === 0,
            workFirstTask: counts[ActivityKey.TASK_CREATED] === 1,
            workFirstFile: counts[ActivityKey.FILE_CREATED] === 1
          } as UserPilotInfo;
        })
      )
      .subscribe(initialState => window.userpilot.identify(personOid, initialState));
  }

  private getTourKey(uiLanguage: string): string {
    return (window as any).Celum.properties.userPilotTourKey[uiLanguage];
  }

  private listenForAppChangesToSetUserPilotProperties(person: Person): void {
    const applicationUrl = (window as any).Celum.properties.hostAddress;

    this.setPropertyFromObservable(person, 'workCanManageTemplates', this.workCanManageTemplates$);
    this.setPropertyFromObservable(person, 'workIsContributor', this.workIsContributor$);
    this.setPropertyFromObservable(person, 'workIsModerator', this.workIsModerator$);
    this.setPropertyFromObservable(person, 'workCanCreateWr', this.workCanCreateWr$);
    this.setPropertyFromObservable(person, 'workCanManageTasks', this.workCanManageTasks$);
    this.setPropertyFromObservable(person, 'workCanCreateTasks', this.workCanCreateTasks$);
    this.setPropertyFromObservable(person, 'workCanAccessAnyWorkroom', this.workCanAccessAnyWorkroom$);
    this.setPropertyFromObservable(person, 'workCanFinishWorkrooms', this.workCanFinishWorkrooms$);
    this.setPropertyFromObservable(person, 'workIsTaskListOwner', this.workIsTaskListOwner$);
    this.setPropertyFromObservable(person, 'workIsTaskAssignee', this.workIsTaskAssignee$);
    this.setPropertyFromObservable(person, 'workIsFileAttached', this.workIsFileAttached$);
    this.setPropertyFromObservable(person, 'workHasMultipleVersions', this.workHasMultipleVersions$);
    this.setPropertyFromObservable(person, 'workIsGroupExpanded', this.workIsGroupExpandedLocalstorageState$);
    this.setPropertyFromObservable(person, 'workIsGroupExpanded', this.workIsGroupExpanded$);
    this.setPropertyFromObservable(person, 'workSignUpDate', of(person.createdOn));
    this.setPropertyFromObservable(person, 'workIsTransitionDefined', this.workIsTransitionDefined$);
    this.setPropertyFromObservable(person, 'workIsRobotDefined', this.workIsRobotDefined$);
    this.setPropertyFromObservable(person, 'workHasCelumContent', this.workHasCelumContent$);
    this.setPropertyFromObservable(person, 'workHasPeople', this.workHasPeople$);
    this.setPropertyFromObservable(person, 'workHasFolder', this.workHasFolder$);
    this.setPropertyFromObservable(person, 'workHasOtherGroups', this.workHasOtherGroups$);
    this.setPropertyFromObservable(person, 'workHasRepo', this.workHasRepo$);
    this.setPropertyFromObservable(person, 'workHasDrive', this.workHasDrive$);
    this.setPropertyFromObservable(person, 'workIsCelumUser', this.workIsCelumUser$);

    combineLatest([
      this.store.select(selectTenant),
      this.store.select(selectUiStateLanguage),
      this.store.select(selectCurrentWorkroomIdParam),
      this.store.select(selectHasRoleInAnyTeamspace(RoleName.WORKROOM_CREATOR)),
      this.store.select(selectCurrentTask),
      this.store.select(selectCurrentFileId),
      this.store.select(selectSelectedFolderId)
    ])
      .pipe(
        switchMap(
          ([tenant, uiLanguage, workroomId, hasPermissionToCreateWorkRooms, currentTask, fileId, selectedFolderId]) =>
            (workroomId ? this.store.select(selectPermissionsForWorkroom(workroomId)) : of(null)).pipe(
              switchMap(permissionsWithRoles =>
                this.versionService.getAppVersion().pipe(
                  map(
                    appVersion =>
                      ({
                        tenant,
                        uiLocale: uiLanguage,
                        workroomId: workroomId ?? null,
                        taskId: currentTask ? currentTask.id : null,
                        version: `${this.versionService.getWebVersion()}/${appVersion}`,
                        applicationUrl,
                        isModerator: permissionsWithRoles
                          ? permissionsWithRoles.roles.some(role => role.name === Roles.MODERATOR)
                          : false,
                        canCreateWr: hasPermissionToCreateWorkRooms,
                        fileId: fileId ?? null,
                        fileParams: this.buildQueryParams(
                          WorkroomConstants.QUERY_PARAMS.SELECTED_FOLDER,
                          selectedFolderId
                        )
                      }) as UserPilotInfo
                  ),
                  distinctUntilChanged(),
                  tap(info => {
                    window.userpilot.identify(person.oid, info);
                  })
                )
              )
            )
        )
      )
      .subscribe();
  }

  private setPropertyFromObservable(person: Person, userPilotInfoKey: keyof UserPilotInfo, value$: Observable<any>) {
    value$.subscribe(value => this.setProperty(person.oid, userPilotInfoKey, value));
  }

  private buildQueryParams(key: string, value: any): string {
    return value ? `?${key}=${encodeURIComponent(value)}` : '';
  }

  private updateOnNavigation(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => window.userpilot.reload());
  }
}
