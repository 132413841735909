import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'task-list-columns-menu-item',
  templateUrl: './task-list-columns-menu-item.component.html',
  styleUrls: ['./task-list-columns-menu-item.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TaskListColumnsMenuItem {
  @HostBinding('class') public componentCls = 'task-list-columns-menu-item';

  @Input() public visibleColumns = 1;

  @Output() public readonly columnsChanged = new EventEmitter<number>();

  public collapseIconActive = IconConfiguration.medium('line-0').withColor(ColorConstants.BLUE_GRAY_600);
  public collapseIconInactive = IconConfiguration.medium('line-0')
    .withColor(ColorConstants.BLUE_GRAY_200)
    .withHoverColor(ColorConstants.BLUE_GRAY_600);
  public possibleVisibleColumns: number[] = [1, 2, 3];

  public changeVisibleColumns(columns: number) {
    this.visibleColumns = columns;
    this.columnsChanged.emit(columns);
  }

  public trackByFn(idx: number): number {
    return idx;
  }
}
