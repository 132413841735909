import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'role-information-dialog',
  templateUrl: './role-information-dialog.component.html',
  styleUrls: ['./role-information-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleInformationDialogComponent {
  public roleDescriptionTranslationKeys = ['MODERATOR', 'CONTRIBUTOR', 'VISITOR'];
  public iconStrokeColor: string = ColorConstants.BLUE_GRAY_800;
  public infoIcon: IconConfiguration = IconConfiguration.small('question-mark')
    .withColor(ColorConstants.PRIMARY)
    .withIconSize(12);
}
