/* eslint-disable max-classes-per-file */
import { trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, pairwise, take, takeUntil } from 'rxjs/operators';

import {
  CelumDialog,
  CelumDialogConfiguration,
  ColorConstants,
  ICON_COLOR_VARIANT,
  IconConfiguration
} from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';
import { ContentItem } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { Folder } from '@celum/work/app/core/model/entities/folder/folder.model';
import {
  selectCurrentWorkroomRootFolder,
  selectFoldersForCurrentWorkroom
} from '@celum/work/app/core/model/entities/folder/folder.selector';
import { WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import { UiViewContext } from '@celum/work/app/core/ui-state/ui-state.model';
import {
  FilesTreeComponent,
  FolderTreePaginationInformation
} from '@celum/work/app/files/components/files-tree/files-tree.component';
import * as FilesTreeActions from '@celum/work/app/files/store/files-tree/files-tree.actions';
import { selectFolderTreePaginationInformation } from '@celum/work/app/files/store/files-tree/files-tree.selector';
import {
  CreateFolderDialog,
  CreateFolderDialogConfiguration
} from '@celum/work/app/pages/workroom/pages/files/components/create-folder-dialog/create-folder-dialog.component';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';

@Component({
  selector: 'select-folder-dialog',
  templateUrl: './select-folder-dialog.component.html',
  styleUrls: ['./select-folder-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [trigger('noop', [])]
})
export class SelectFolderDialogComponent
  extends ReactiveComponent
  implements OnInit, OnDestroy, CelumDialog<SelectFolderDialogConfiguration>
{
  public color: string;
  public target: Folder;
  public title: string;

  public confirmText: string;
  public cancelText: string;
  public disabledFolderIds: string[] = [];
  public showSubmitSubMenu$: Observable<boolean> = of(false);
  public submitSubMenuConfig: SelectFolderDialogSubmitSubMenuConfiguration[];
  public children$: Observable<Folder[]>;
  public paginationInformation$: Observable<FolderTreePaginationInformation>;

  public readonly plusIcon = IconConfiguration.small('add-m').withColor(ColorConstants.BLUE_GRAY_900);
  public cancelIcon: IconConfiguration = IconConfiguration.medium('cancel-m')
    .withColor('white')
    .withColorVariant(ICON_COLOR_VARIANT.light);
  public confirmIcon: IconConfiguration = IconConfiguration.medium('check-m')
    .withColor('white')
    .withColorVariant(ICON_COLOR_VARIANT.light);

  @ViewChild('submitButtonWithSubMenu') public submitButtonWithSubMenu: MatButton;
  @ViewChild('filesTree') public filesTree: FilesTreeComponent;

  private configuration: SelectFolderDialogConfiguration;

  constructor(
    private dialogRef: MatDialogRef<SelectFolderDialogComponent>,
    private store: Store<any>,
    private celumDialogOpener: CelumDialogOpener,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public get submitButtonWidth(): string {
    return this.submitButtonWithSubMenu?._elementRef.nativeElement.clientWidth + 'px';
  }

  public ngOnInit(): void {
    this.children$ = this.store.select(selectFoldersForCurrentWorkroom);
    this.store
      .select(selectCurrentWorkroomRootFolder)
      .pipe(take(1))
      .subscribe(folder => this.selectTargetFolder(folder));
    this.paginationInformation$ = this.store.select(
      selectFolderTreePaginationInformation(UiViewContext.SELECT_FOLDER_DIALOG)
    );

    this.selectAndFocusOnNewlyCreatedFolder();
  }

  public confirmDialog(type?: SubmitSubMenuType): void {
    this.dialogRef.close({ type, folder: this.target });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public configure(configuration: SelectFolderDialogConfiguration): void {
    this.configuration = configuration;
    this.color = configuration.color;
    this.disabledFolderIds = configuration.items.map(item => item.id);
    this.title = configuration.title ?? 'CONTENT_ITEMS.DIALOG.MOVE.HEADER';
    this.submitSubMenuConfig = configuration.subMenuItems;
    this.showSubmitSubMenu$ = of(this.submitSubMenuConfig?.length > 0);
    this.confirmText = configuration.confirmText ?? 'CONTENT_ITEMS.DIALOG.MOVE.CONFIRM';
    this.cancelText = configuration.cancelText ?? 'CONTENT_ITEMS.DIALOG.CANCEL';
    this.confirmIcon = (configuration.confirmIcon || this.confirmIcon).withText(this.confirmText);
    this.cancelIcon = (configuration.cancelIcon || this.cancelIcon).withText(this.cancelText);
  }

  public loadChildren(params: any): void {
    this.store.dispatch(
      FilesTreeActions.FilesTreeLoadSubfolders({
        offset: 0,
        parentId: params.parentId,
        limit: params.limit,
        treeId: UiViewContext.SELECT_FOLDER_DIALOG
      })
    );
  }

  public loadMoreForFolder(params: any): void {
    this.store.dispatch(
      FilesTreeActions.FilesTreeFetchNextBatch({
        parentId: params.parentId,
        offset: params.offset,
        treeId: UiViewContext.SELECT_FOLDER_DIALOG
      })
    );
  }

  public selectTargetFolder(target: Folder): void {
    this.target = target;
    if (this.configuration.isSubMenuForFolderEnabled) {
      this.showSubmitSubMenu$ = this.store.select(selectCurrentWorkroom).pipe(
        take(1),
        map(
          workroom =>
            this.configuration.isSubMenuForFolderEnabled(workroom.configuration, target.id) &&
            this.submitSubMenuConfig?.length > 0
        )
      );
    }
  }

  public createNewFolder(): void {
    this.celumDialogOpener.showDialog(
      'create-folder',
      CreateFolderDialog,
      new CreateFolderDialogConfiguration(this.target),
      {
        restoreFocus: false
      }
    );
  }

  public trackByFn(_: number, item: SelectFolderDialogSubmitSubMenuConfiguration) {
    return item.name;
  }

  private selectAndFocusOnNewlyCreatedFolder() {
    this.children$
      .pipe(
        pairwise(),
        filter(([previous, current]) => current.length > previous.length),
        map(([_, current]: [Folder[], Folder[]]) => current[current.length - 1]),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((createdFolder: Folder) => {
        // select the new created folder
        this.selectTargetFolder(createdFolder);

        const treeNodes = this.filesTree.treeNodes.toArray();
        // find the selected folder
        const selectedTreeNode = treeNodes.find(treeNode =>
          Array.from(new Set([...(treeNode.nativeElement?.firstElementChild.classList ?? [])])).includes(
            'mat-tree-node--selected'
          )
        );
        // if the newly created folder is not visible scroll to it
        if (selectedTreeNode?.nativeElement?.offsetTop > this.filesTree?.elementRef?.nativeElement?.clientHeight) {
          requestAnimationFrame(() =>
            this.filesTree.scrollbar.nativeElement.scrollTo({ top: selectedTreeNode?.nativeElement?.offsetTop })
          );
          this.cdr.detectChanges();
        }
      });
  }
}

export class SelectFolderDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(
    public items: ContentItem[],
    public title?: string,
    public confirmText?: string,
    public confirmIcon?: IconConfiguration,
    public cancelText?: string,
    public cancelIcon?: IconConfiguration,
    public subMenuItems?: SelectFolderDialogSubmitSubMenuConfiguration[],
    public isSubMenuForFolderEnabled?: (config: WorkroomConfiguration, folderId: string) => boolean
  ) {
    super('main');
  }
}

export class SelectFolderDialogSubmitSubMenuConfiguration {
  constructor(
    public name: string,
    public type?: SubmitSubMenuType,
    public tooltip?: string,
    public tooltipIcon?: IconConfiguration
  ) {}
}

export class SelectFolderDialogResult {
  public type?: SubmitSubMenuType;
  public folder: Folder;
}

export type SubmitSubMenuType = 'AddAndWatch' | 'Add' | 'AddAndCreateTasks';
