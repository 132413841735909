<clickable-popup-dialog
    [triggerClass]="'role-information-dialog_trigger'"
    [contentClass]="'role-information-dialog_menu'"
    [triggerTooltipTranslationKey]="'PEOPLE.INVITE.ROLE_INFORMATION_DIALOG.TOOLTIP'">

  <div popupTrigger class="role-information-dialog_icon-wrapper">
    <celum-icon
      class="role-information-dialog_icon"
      [style.stroke]="iconStrokeColor"
      [configuration]="infoIcon"
      data-cy="role-information-popup-trigger">
    </celum-icon>
  </div>


  <div popupContent stopClickPropagation>
    <span class="role-information-dialog_menu_headline caption">{{ 'PEOPLE.INVITE.ROLE_INFORMATION_DIALOG.HEADER' | translate }}</span>
    <ul>
      <li *ngFor="let roleKey of roleDescriptionTranslationKeys">
        <span [innerHTML]="'• ' + ( 'PEOPLE.INVITE.ROLE_INFORMATION_DIALOG.ROLES.' + roleKey | translate )"></span>
      </li>
    </ul>

    <a class="role-information-dialog_menu_link"
        href="https://help.celum.com/en/topic.htm#t=mergedProjects%2Fwork%2FtocTopics%2Fstart%2FUser_roles_and_permissions.htm"
        target="_blank">{{ 'PEOPLE.INVITE.ROLE_INFORMATION_DIALOG.LEARN_MORE_LINK' | translate }}
    </a>
  </div>
</clickable-popup-dialog>
