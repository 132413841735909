import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { findMatchingRobotConfigService } from '@celum/work/app/robots/services/robots-util';

import { Robots } from '../../../core/model/entities/workroom/robot.model';
import { RobotChipConfig, RobotsFactory } from '../../services/robots-factory';
import { BaseRobotConfigService } from '../robot-dialog-templates/base-robot/base-robot-config.service';

@Injectable()
export class RobotChipConfigFactory {
  constructor(@Inject(BaseRobotConfigService) private baseRobotConfigServices: BaseRobotConfigService[]) {}

  public getRobotChipConfig(robot: Robots, sourceEntity?: any): Observable<RobotChipConfig> {
    const baseConfig = {
      icon: RobotsFactory.getIcon(robot.type).withIconSize(20),
      colorSchemeClass: RobotsFactory.getColorScheme(robot.type)
    };

    const robotConfigService = findMatchingRobotConfigService(this.baseRobotConfigServices, robot);
    return robotConfigService.getRobotChipConfig(robot, sourceEntity).pipe(
      map(robotConfig => {
        return {
          ...baseConfig,
          ...robotConfig
        } as RobotChipConfig;
      })
    );
  }
}
