import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { DataContext } from '@celum/core';
import { CelumDialogOpener } from '@celum/internal-components';
import { FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { FolderType } from '@celum/work/app/core/model/entities/folder/folder.model';
import {
  ConfirmationDialog,
  ConfirmationDialogConfiguration
} from '@celum/work/app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ContentItemPermanentlyDeleteDialogOpener {
  constructor(
    private celumDialogOpener: CelumDialogOpener,
    public translateService: TranslateService
  ) {}
  public showDialog(data: DataContext): Promise<boolean> {
    return this.celumDialogOpener.showDialog(
      'delete-content-item-permanently',
      ConfirmationDialog,
      this.getDeleteDialogConfig(data),
      {
        restoreFocus: false
      }
    );
  }

  private getDeleteDialogConfig(data: DataContext): ConfirmationDialogConfiguration {
    return new ConfirmationDialogConfiguration(
      this.translateService.instant('TRASH_BIN.PERMANENT_DELETE_DIALOG.HEADLINE'),
      this.translateService.instant(this.getDialogBodyKey(data)),
      ColorConstants.WARNING,
      'COMMON.DELETE',
      IconConfiguration.xLarge('information', '', 64),
      'permanently-delete-item'
    );
  }

  private getDialogBodyKey(data: DataContext): string {
    const folders = data.getEntityTypes().filter(type => type === FolderType.instance()).length > 0;
    const files = data.getEntityTypes().filter(type => type === FileType.instance()).length > 0;

    if (folders && files) {
      return 'TRASH_BIN.PERMANENT_DELETE_DIALOG.MIXED';
    }
    if (folders) {
      if (data.getSelection().length > 1) {
        return 'TRASH_BIN.PERMANENT_DELETE_DIALOG.FOLDER.MULTIPLE';
      }
      return 'TRASH_BIN.PERMANENT_DELETE_DIALOG.FOLDER.SINGLE';
    }
    if (files) {
      if (data.getSelection().length > 1) {
        return 'TRASH_BIN.PERMANENT_DELETE_DIALOG.FILE.MULTIPLE';
      }
      return 'TRASH_BIN.PERMANENT_DELETE_DIALOG.FILE.SINGLE';
    }
  }
}
