/**
 * Contains information about the structure and content of a result from the server.
 * Necessary if the result should be normalized automatically.
 */
export class MetaInfo {
  public entities: Set<string> = new Set<string>();
  public resultTypes: string[];
  public resultKey: string;
  public schema?: any;
  /**
   * Define which type should only partially updated and which properties are to update.
   * E.g.
   * <pre><code>
   *   {
   *     [TaskType.TYPE_KEY]: ['name', 'taskListId'],
   *     [PersonType.TYPE_KEY]: ['username']
   *   }
   * </code></pre>
   */
  public partialUpdates?: { [key: string]: string[] } = {};

  /**
   * Create MetaInfo with expected entity types and result schema.
   *
   * @param entities  the expected entity types (which should be automatically translated into entities)
   * @param schema    the schema of the result from the server
   */
  public static of(entities: string[], schema: any): MetaInfo;
  /**
   * Create MetaInfo with given entity type definitions and define for which entityType
   * you want to keep the order in which they are defined (``resultTypes``).
   * In addition you need to provide the "property key" how to access this entities (`resultKey`).
   * For example, if you have a schema like follows:
   * <code>
   *   const schema = {
   *      results: [
   *        new schema.Entity('workroom', {
   *          contributors: [
   *            new schema.Entity('contributor', { person: new schema.Entity('person') }, {
   *              idAttribute: value => `contr_${value.workroomId}_${value.person.id}`
   *            })
   *          ]
   *        })
   *      ]
   *    };
   * </code>
   *
   * <p>
   * You can only define to keep the order of <code>'workroom'</code> entities. Therefore you would define
   * <code>resultTypes</code> as <code>'Workroom'</code> and <code>resultKey</code> as <code>'results'</code>.
   * Make sure that what you define as <code>resultTypes</code> actually matches how you named the schema.
   * Entity entry for this specific type!.
   * </p>
   *
   * <b>Note</b>: this is only supported for entities which are returned on root level!
   *
   * @param entities    the expected entity types
   * @param schema      the schema of the result from the server
   * @param resultTypes  the entity type of the result
   * @param resultKey   the property name of the object that contains the entities for which to retain the order
   */
  public static of(entities: string[], schema: any, resultTypes: string[], resultKey: string): MetaInfo;

  public static of(entities: string[], schema: any, resultTypes?: string[], resultKey?: string): MetaInfo {
    const metaInfo = new MetaInfo();
    entities.forEach(entity => metaInfo.entities.add(entity));
    metaInfo.resultTypes = resultTypes || [];
    metaInfo.resultKey = resultKey;
    metaInfo.schema = schema;
    return metaInfo;
  }
}
