import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { EntityType } from '@celum/core';
import { Roles } from '@celum/work/app/core/model';
import { Member } from '@celum/work/app/core/model/entities/member';

import { Color } from '../../color.model';
import { Entity } from '../../entity';
import { InvitationEmail } from '../contributor';
import { Person } from '../person';
import { TaskList } from '../task';
import { WorkroomConfiguration } from '../workroom';

export interface TemplateResponse {
  createdBy: Person;
  createdById: number;
  createdOn: number;
  template: Template;
  members: Member[];
  typeKey: 'TemplateResponse';
}

export interface Template extends Entity<number, TemplateType> {
  version?: number;
  name: string;
  description?: string;
  dueDate?: string;
  color: Color;
  colorVariant: number;
  contentHubRepositoryId?: string;
  categoryId?: number;
  taskLists?: TaskList[];
  people: TemplatePerson[];
  workroomConfiguration?: WorkroomConfiguration;
  createdOn: number;
}

export interface TemplatePerson {
  personId: number;
  roles: Roles[];
}

export interface InvitedPerson {
  personId: number;
  invitationEmail: InvitationEmail;
  roles: Roles[];
}

export class TemplateType implements EntityType {
  public static readonly TYPE_KEY: string = 'Template';

  private static _instance: TemplateType;

  public id = TemplateType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): TemplateType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    return new schema.Entity(TemplateType.TYPE_KEY, relationDefinitions);
  }
}

export interface TemplateState extends EntityState<Template> {}
