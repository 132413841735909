import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fullscreen-dialog',
  templateUrl: './fullscreen-dialog.component.html',
  styleUrls: ['./fullscreen-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FullscreenDialogComponent {
  @HostBinding('class.fullscreen-dialog') public hostCls = true;
  @Input() public title: string;
  @Input() public light: boolean;
  @Input() public actions: TemplateRef<any>;
  @Input() public loading: boolean;

  constructor(private dialogRef: MatDialogRef<FullscreenDialogComponent>) {}

  public static dialogConfig(data?: object): MatDialogConfig {
    return {
      panelClass: 'fullscreen-dialog-overlay-pane',
      data,
      autoFocus: false
    };
  }

  public close(): void {
    this.dialogRef.close();
  }
}
