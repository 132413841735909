import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { DataUtil } from '@celum/core';
import { EntityUtil } from '@celum/work/app/core/model';

import { TemplateCategoryUpsertMany, TemplateCategoryUpsertOne } from './template-category.actions';
import { TemplateCategory, TemplateCategoryState } from './template-category.model';

export const templateCategoryAdapter: EntityAdapter<TemplateCategory> = createEntityAdapter<TemplateCategory>();

export const initialState: TemplateCategoryState = templateCategoryAdapter.getInitialState();

const properties = ['name'];

const reducer = createReducer(
  initialState,
  on(TemplateCategoryUpsertOne, (state: TemplateCategoryState, { templateCategory }) => {
    const templateCategories = EntityUtil.changedEntities(properties, [templateCategory], state.entities);
    if (!DataUtil.isEmpty(templateCategories)) {
      return templateCategoryAdapter.upsertOne(templateCategories[0], state);
    } else {
      return state;
    }
  }),
  on(TemplateCategoryUpsertMany, (state: TemplateCategoryState, { templateCategories }) => {
    const changedTemplateCategories = EntityUtil.changedEntities(properties, templateCategories, state.entities);
    return templateCategoryAdapter.upsertMany(changedTemplateCategories, state);
  })
);

export function templateCategoryReducer(
  state: TemplateCategoryState = initialState,
  action: Action
): TemplateCategoryState {
  return reducer(state, action);
}
