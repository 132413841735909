import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { EMPTY, forkJoin, merge, Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { AuthService } from '@celum/authentication';
import { LoggedInPersonLoadMembership } from '@celum/work/app/core/api/teamspace-member';
import { AuthLogin } from '@celum/work/app/core/auth/auth.actions';
import { AvatarService } from '@celum/work/app/core/avatar/avatar.service';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import { selectAllTeamspaces } from '@celum/work/app/core/model/entities/teamspace';
import { UiStateSetLoggedInPerson } from '@celum/work/app/core/ui-state/ui-state.actions';

import { PersonService } from './person.service';
import { FetchPersonAvatars, FetchPersonAvatarsSuccess, Person } from '../../model/entities/person';

@Injectable()
export class PersonEffects {
  public loadLoggedInPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLogin),
      exhaustMap(() =>
        this.personService.loadLoggedInPerson().pipe(
          switchMap(person => {
            if (person) {
              return merge(of(UiStateSetLoggedInPerson({ personId: person.id })), of(LoggedInPersonLoadMembership()));
            } else {
              this.authService.signOut();
              this.failureHandler.handleError(new Error('Could not load logged in person - result was empty!'));
              return EMPTY;
            }
          }),
          catchError(err => {
            this.failureHandler.handleError(err);
            return EMPTY;
          })
        )
      )
    )
  );

  public avatars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FetchPersonAvatars),
      filter(({ persons }) => !isEmpty(persons)),
      mergeMap(({ persons }) => {
        return this.store.select(selectAllTeamspaces).pipe(
          take(1),
          map(teamspaces => teamspaces.map(teamspace => teamspace.externalId)),
          switchMap(externalTeamspaceIds =>
            forkJoin(this.requestAvatarsFromAllTeamspaces(externalTeamspaceIds, persons))
          ),
          map(avatarsAndAccountId => this.avatarService.mapToUniqueAvatarsWithAccountId(avatarsAndAccountId)),
          map(avatarsWithAccessToken => this.avatarService.mapAvatarToUser(persons, avatarsWithAccessToken)),
          map(personsWithAvatars => FetchPersonAvatarsSuccess({ persons: personsWithAvatars })),
          catchError(err => {
            console.error('Could not load avatars', err);
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private personService: PersonService,
    private failureHandler: FailureHandler,
    private store: Store<any>,
    private avatarService: AvatarService,
    private authService: AuthService
  ) {}

  private requestAvatarsFromAllTeamspaces(
    teamspaceIds: string[],
    persons: any[]
  ): Observable<{ id: string; avatars: any }>[] {
    return teamspaceIds.map(id =>
      this.personService.fetchAvatars(id, this.extractEmails(persons)).pipe(
        map(avatars => ({
          id,
          avatars
        }))
      )
    );
  }

  private extractEmails(persons: Person[]): Array<{ email: string }> {
    return persons.map(person => ({ email: person.email }));
  }
}
