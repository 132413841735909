import { EntityState } from '@ngrx/entity';

import { SubtaskStatus, Task, TaskType } from '@celum/work/app/core/model/entities/task/task.model';

export const subtaskBasicProperties = ['name', 'sort', 'status'];

export interface Subtask extends Task {}

type SubtaskProp = keyof Subtask;
export const subtaskUpdateProperties: SubtaskProp[] = ['id', 'name', 'sort', 'parentId', 'status', 'workroomId'];

export interface SubtaskCreateParams {
  taskId: number;
  subtaskName: string;
}

export interface SubtaskUpdateParams {
  taskId: number;
  subtaskId: number;
  name?: string;
  status?: SubtaskStatus;
  sort?: number;
}

export class SubtaskType extends TaskType {
  public static readonly SUB_TYPE_KEY: string = 'Subtask';
}

export interface SubtaskState extends EntityState<Subtask> {}

export const subtaskProperties = ['name', 'sort', 'parentId', 'status', 'workroomId'];
