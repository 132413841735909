import { Pipe, PipeTransform } from '@angular/core';

import { InvitationStatus, MembershipStatus } from '../../../../core/model/entities/member';

@Pipe({
  name: 'peopleCardShowLabel',
  standalone: true
})
export class PeopleCardShowLabelPipe implements PipeTransform {
  public transform(membershipStatus: MembershipStatus, invitationStatus: InvitationStatus): boolean {
    return (
      membershipStatus === MembershipStatus.INACTIVE ||
      invitationStatus === InvitationStatus.PENDING_APPROVAL ||
      invitationStatus === InvitationStatus.INVITED
    );
  }
}
