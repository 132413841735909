import { Dictionary } from '@ngrx/entity';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { EntityType } from '@celum/core';
import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { State } from './entities/entities.state';
import { Entity } from './entity';

export class EntityUtil {
  /**
   * Get the entity that represent a relation between two entities. If there are multiple
   * ones that match, the first one is returned. Either define both {@code from} and {@code to},
   * or at least one of the them. This method will return the entity where the from or to definitions match.
   *
   * <h2>Example</h2>
   * If you have a Workroom and a Person entity which are related via Contributor entity.
   *
   * - Workrooms
   * -- <code>{ id: '123', name: 'A Workroom' }</code>
   * -- <code>{ id: '124', name: 'Another Workroom' }</code>
   * - Persons
   * -- <code>{ id: '456', name: 'Fred Stone' }</code>
   * -- <code>{ id: '457', name: 'Frieda Stone' }</code>
   * - Contributors
   * -- <code>{ personId: '465', workroomId: '123' }</code>
   * -- <code>{ personId: '465', workroomId: '124' }</code>
   * -- <code>{ personId: '457', workroomId: '124' }</code>
   *
   * <p>
   * If you want to have the contributor entity that relate to 'Another Workroom', you should pass
   * as from <code>{ id: '124', key: 'workroomId' }</code>. This will return you the Contributor
   * entity where 'workroomId' is '124' (in this example 2, so you will just get the first match). </p>
   * <p>
   * If you want to have the Contributor entity where Frieda Stone is involved, you should pass as from
   * <code>{ id: '457', key: 'personId' }</code>. This will return you the Contributor entity where
   * 'personId' is '457' (which is 1 in this example).</p>
   * <p>
   * If you want to have exactly the Contributor entity that relates Frieda Stone with 'Another Workroom',
   * you should pass as from <code>{ id: '457', key: 'personId' }</code> and as to <code>{ id: '124',
   * key: 'workroomId' }</code>. This will you return the Contributor entity that have 'personId' of '457'
   * and 'workroomId' of '124'.</p>
   * <p>(Note that it does not matter what of the two is from and what is to, you can switch them as you like).</p>
   *
   * @param store     the store
   * @param selector  pass the selector to get _all_ entities of the desired "relation" type
   * @param from      define the id of the source entity and the name of the attribute
   *                  that contains the id of the source entity on the "relation entity"
   * @param to        define the id of the source entity and the name of the attribute
   *                  that contains the id of the source entity on the "relation entity"
   */
  public static getRelationEntity<E extends Entity<any, EntityType>>(
    store: Store<any>,
    selector: MemoizedSelector<State, E[]>,
    from?: { id: number; key: string },
    to?: { id: number; key: string }
  ): Observable<E> {
    return this.getRelationEntities(store, selector, from, to).pipe(
      map(entities => (entities?.length > 0 ? entities[0] : null))
    );
  }

  /**
   * Get the entities that represent a relation between two entities.
   * Either define both {@code from} and {@code to}, or at least one of the them.
   * This method will return all entities where the from or to definitions match.
   *
   * <h2>Example</h2>
   * If you have a Workroom and a Person entity which are related via Contributor entity.
   *
   * - Workrooms
   * -- <code>{ id: '123', name: 'A Workroom' }</code>
   * -- <code>{ id: '124', name: 'Another Workroom' }</code>
   * - Persons
   * -- <code>{ id: '456', name: 'Fred Stone' }</code>
   * -- <code>{ id: '457', name: 'Frieda Stone' }</code>
   * - Contributors
   * -- <code>{ personId: '465', workroomId: '123' }</code>
   * -- <code>{ personId: '465', workroomId: '124' }</code>
   * -- <code>{ personId: '457', workroomId: '124' }</code>
   *
   * <p>
   * If you want to have all contributor entities that relate to 'Another Workroom', you should pass as
   * from <code>{ id: '124', key: 'workroomId' }</code>.
   * This will return you all Contributor entities where 'workroomId' is '124' (in this example 2). </p>
   * <p>
   * If you want to have all Contributor entities where Frieda Stone is involved, you should pass as from
   * <code>{ id: '457', key: 'personId' }</code>.
   * This will return you all Contributor entities where 'personId' is '457' (which is 1 in this example).</p>
   * <p>
   * If you want to have exactly the Contributor entity that relates Frieda Stone with 'Another Workroom',
   * you should pass as from <code>{ id: '457', key: 'personId' }</code> and as to <code>{ id: '124', key:
   * 'workroomId' }</code>. This will you return all Contributor entities that have 'personId' of '457'
   * and 'workroomId' of '124'.</p>
   * <p>(Note that it does not matter what of the two is from and what is to, you can switch them as you like).</p>
   *
   * @param store      the store
   * @param selector   pass the selector to get _all_ entities of the desired "relation" type
   * @param from       define the id of the source entity and the name of the attribute
   *                   that contains the id of the source entity on the "relation entity"
   * @param to         define the id of the source entity and the name of the attribute
   *                   that contains the id of the source entity on the "relation entity"
   */
  public static getRelationEntities<E extends Entity<any, EntityType>>(
    store: Store<any>,
    selector: MemoizedSelector<State, E[]>,
    from?: { id: number; key: string },
    to?: { id: number; key: string }
  ): Observable<E[]> {
    if (!from && !to) {
      return throwError('Invalid usage! Either "from" or "to" needs to be defined!');
    }

    return store.pipe(
      select(selector),
      map(
        (entities: Entity<any, EntityType>[]) =>
          entities.filter(entity => {
            let matches = false;

            if (notNullOrUndefined(from)) {
              matches = entity[from.key] === from.id;
            }

            if (notNullOrUndefined(to)) {
              matches = matches && entity[to.key] === to.id;
            }

            return matches;
          }) as E[]
      )
    );
  }

  /**
   * Get the entities that are related (via entities that you define by the <code>relationSelector</code>)
   * with entities that the match given <code>from</code>
   * definition. The "target" entities are returned based on the id of the <code>toKey</code>
   * value on the "relation" entity.
   *
   * <h2>Example</h2>
   * If you have a Workroom and a Person entity which are related via Contributor entity.
   *
   * - Workrooms
   * -- <code>{ id: '123', name: 'A Workroom' }</code>
   * -- <code>{ id: '124', name: 'Another Workroom' }</code>
   * - Persons
   * -- <code>{ id: '456', name: 'Fred Stone' }</code>
   * -- <code>{ id: '457', name: 'Frieda Stone' }</code>
   * - Contributors
   * -- <code>{ personId: '465', workroomId: '123' }</code>
   * -- <code>{ personId: '465', workroomId: '124' }</code>
   * -- <code>{ personId: '457', workroomId: '124' }</code>
   *
   * <p>
   * If you want to have all Person entities that relate to 'Another Workroom', you should pass
   * as from <code>{ id: '124', key: 'workroomId' }</code> and toKey as 'personId'. This will return you
   * all Person entities that match via the Contributor entities with 'workroomId' is '124'
   * (in this example you will get both Person entities).</p>
   * <p>
   * If you want to have all Workroom entities where Frieda Stone is involved, you should pass as from
   * <code>{ id: '457', key: 'personId' }</code>. This will return you all Workroom entities where
   * a Contributor entity has 'personId' of '457' (which is 1 in this example).</p>
   *
   * @param store             the store
   * @param relationSelector  define the selector to get _all_ entities of the "relation" type
   * @param entitySelector    define the selector to get entities of the expected return type
   * @param from              define the id of the source entity and the name of the attribute
   *                          that contains the id of the source entity on the "relation entity"
   * @param toKey             define the name of the attribute that contains the id of the desired "target" entities
   */
  public static getRelatedEntities<E extends Entity<any, EntityType>>(
    store: Store<any>,
    relationSelector: MemoizedSelector<State, Entity<any, EntityType>[]>,
    entitySelector: MemoizedSelector<State, Dictionary<E>>,
    from: { id: number; key: string },
    toKey: string
  ): Observable<E[]> {
    if (!from || !toKey) {
      return throwError('Invalid usage! Either "from" or "to" needs to be defined!');
    }

    return EntityUtil.getRelationEntities(store, relationSelector, from).pipe(
      switchMap(entities => {
        const ids = entities.map(entity => entity[toKey]).filter(entityKey => notNullOrUndefined(entityKey));
        return store.pipe(
          select(entitySelector),
          map(dictionary => ids.map(id => dictionary[id]).filter(entity => !!entity))
        );
      })
    );
  }

  /**
   * Get the entity that is related (via entities that you define by the <code>relationSelector</code>)
   * with the entity that the match given <code>from</code>
   * definition. The "target" entity is returned based on the id of the <code>toKey</code> value on the
   * "relation" entity. Only the first match is returned (if there are multiple matches).
   *
   * <h2>Example</h2>
   * If you have a Workroom and a Person entity which are related via Contributor entity.
   *
   * - Workrooms
   * -- <code>{ id: '123', name: 'A Workroom' }</code>
   * -- <code>{ id: '124', name: 'Another Workroom' }</code>
   * - Persons
   * -- <code>{ id: '456', name: 'Fred Stone' }</code>
   * -- <code>{ id: '457', name: 'Frieda Stone' }</code>
   * - Contributors
   * -- <code>{ personId: '465', workroomId: '123' }</code>
   * -- <code>{ personId: '465', workroomId: '124' }</code>
   * -- <code>{ personId: '457', workroomId: '124' }</code>
   *
   * <p>
   * If you want to have the Person entity that relates to 'Another Workroom', you should pass
   * as from <code>{ id: '124', key: 'workroomId' }</code> and toKey as 'personId'. This will return
   * you the first Person entity that match via the Contributor entities with 'workroomId' is '124'.</p>
   * <p>
   * If you want to have a Workroom entity where Frieda Stone is involved, you should pass as
   * from <code>{ id: '457', key: 'personId' }</code>. This will return you a Workroom entities
   * where a Contributor entity has 'personId' of '457' (which is 1 in this example).</p>
   *
   * @param store             the store
   * @param relationSelector  define the selector to get _all_ entities of the "relation" type
   * @param entitySelector    define the selector to get entities of the expected return type
   * @param from              define the id of the source entity and the name of the attribute
   *                          that contains the id of the source entity on the "relation entity"
   * @param toKey             define the name of the attribute that contains the id of the desired "target" entities
   */
  public static getRelatedEntity<E extends Entity<any, EntityType>>(
    store: Store<any>,
    relationSelector: MemoizedSelector<State, Entity<any, EntityType>[]>,
    entitySelector: MemoizedSelector<State, Dictionary<E>>,
    from: { id: number; key: string },
    toKey: string
  ): Observable<E> {
    return this.getRelatedEntities(store, relationSelector, entitySelector, from, toKey).pipe(
      map(entities => (entities?.length > 0 ? entities[0] : null))
    );
  }

  public static changedEntities<E extends Entity<any, EntityType>>(
    properties: string[],
    entities: E[],
    existingEntities: Dictionary<E>,
    additionalIsEqualCheck?: (oldEntity: E, newEntity: E) => boolean
  ): E[] {
    const changedEntities: E[] = [];

    entities.forEach(entity => {
      const existingEntity = existingEntities[entity.id];

      if (!existingEntity) {
        changedEntities.push(entity);
      } else {
        // if at least one property has changed...
        const changedValue = properties.find(property => {
          if (Array.isArray(existingEntity[property])) {
            return !isEqual(existingEntity[property], entity[property]);
          }

          return existingEntity[property] !== entity[property];
        });

        // ... add entity to list of entities to update (also if there is an additional check defined and this one returns false)
        if (changedValue || (additionalIsEqualCheck && !additionalIsEqualCheck(existingEntity, entity))) {
          changedEntities.push(entity);
        }
      }
    });

    return changedEntities;
  }
}
