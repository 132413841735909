import { createSelector } from '@ngrx/store';

import { selectTeamspaceOfCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';

export const selectStorageLimitReachedByCurrentWorkroom = createSelector(
  selectTeamspaceOfCurrentWorkroom,
  teamspace => teamspace?.availableStorageInGiB <= teamspace?.usedStorageInGiB
);

export const selectUsedStoragePercentage = createSelector(selectTeamspaceOfCurrentWorkroom, teamspace => {
  const usedStoragePercentage = (teamspace?.usedStorageInGiB / teamspace?.availableStorageInGiB) * 100;

  if (isNaN(usedStoragePercentage)) {
    return 0;
  }

  return isFinite(usedStoragePercentage) ? Number(usedStoragePercentage.toFixed(2)) : 100;
});

export const select3DUsedPercentage = createSelector(selectTeamspaceOfCurrentWorkroom, teamspace => {
  const x3DUsedPercentage = (teamspace?.monthly3DUsedAndReserved / teamspace?.monthly3DLimit) * 100;
  const active3DLicence = teamspace?.active3DLicense;

  if (!active3DLicence || isNaN(x3DUsedPercentage)) {
    return 0;
  }

  return isFinite(x3DUsedPercentage) ? Number(x3DUsedPercentage.toFixed(2)) : 100;
});
