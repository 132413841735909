import { createAction, props } from '@ngrx/store';

import { Marker, MarkingModes } from '@celum/annotations';

import { Comment } from './comment.model';

/**
 * By default, all properties are updated!
 */
export const CommentUpsertOne = createAction(
  '[Comment] Upsert One',
  props<{ comment: Comment; propertiesToUpdate?: string[] }>()
);

/**
 * By default, all properties are updated!
 */
export const CommentUpsertMany = createAction(
  '[Comment] Upsert Many',
  props<{ comments: Comment[]; propertiesToUpdate?: string[] }>()
);

export const CommentDeleteOne = createAction('[Comment] Delete One', props<{ comment: Comment }>());

export const CommentSelectOne = createAction('[Comment] Select One', props<{ commentId: number; fileId?: string }>());

export const ResetSelection = createAction('[Comment] Reset Selection');

export const CommentRemoveSelection = createAction('[Comment] Remove selection');

export const CommentStartMarker = createAction(
  '[Comment] Start Marker',
  props<{ label: string; markingType: MarkingModes }>()
);

export const CommentAddMarker = createAction('[Comment] Add Marker', props<{ marker: Marker }>());

export const CommentEditMarker = createAction('[Comment] Edit Marker', props<{ marker: Marker }>());

export const CommentRemoveMarker = createAction('[Comment] Remove Marker', props<{ marker: Marker }>());

export const CommentClearMarkers = createAction('[Comment] Clear Markers');

export const CommentChangeReplyCount = createAction(
  '[Comment] Change ReplyCount',
  props<{ id: number; delta: number }>()
);
