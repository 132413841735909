import { createAction, props } from '@ngrx/store';

import { Contributor } from './contributor.model';

export const ContributorsUpsertOne = createAction('[Contributors] Upsert One', props<{ contributor: Contributor }>());

export const ContributorsUpsertMany = createAction(
  '[Contributors] Upsert Many',
  props<{ contributors: Contributor[] }>()
);

export const ContributorsDeleteOne = createAction('[Contributors] Delete One', props<{ contributor: Contributor }>());

export const ContributorsDeleteMany = createAction('[Contributors] Delete Many', props<{ ids: string[] }>());
