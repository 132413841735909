/* eslint-disable @angular-eslint/no-input-rename */
import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, HostBinding, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core';

import { formatCount } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

import { AVATAR_SIZE, AvatarConfigBuilder, AvatarConfiguration } from '../../avatar/avatar-configuration';
import { ColorConstants } from '../../base/color-constants';

@Component({
             selector: 'celum-avatar-list',
             templateUrl: './avatar-list.html',
             styleUrls: ['./avatar-list.less'],
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class CelumAvatarList extends ReactiveComponent {
  /** Define if a menu should be opened when the "show-more" avatar is clicked. */
  @Input() public showMoreAvatarsMenu = false;
  /** Define how many avatars should be directly visible, this might be ignored if there is not enough space. */
  @Input() public maxAvatars = 5;
  /**
   * Pagination information of the avatar list. If both {@see showMoreAvatarsMenu} and {@see elementsFollow} are true,
   * a spinner will be shown when the menu is scrolled to the end and the {@see loadMore} output emits.
   */
  @Input() public paginationInfo: { elementsFollow: boolean; loading: boolean };

  /** Define whether the click on the menu avatar should not be propagated outside of this control **/
  @Input() public preventEventPropagationForMoreAvatarsMenu = false;

  /** Emits if {@see paginationInfo.elementsFollow} is true and the user scrolled to the end of the avatar menu */
  @Output() public readonly loadMore = new EventEmitter<void>();

  @HostBinding('class.celum-avatar-list') public hostCls = true;

  @ContentChild(TemplateRef, { static: false }) public avatarTemplate: TemplateRef<any>;

  protected avatarsShortList: AvatarConfiguration[];
  protected showMoreAvatarsIcon: AvatarConfiguration;
  protected avatarConfigsForMenu: AvatarConfiguration[];
  protected isShowMoreAvatarsMenuOpen = false;
  private maxShowableAvatars = 5;

  private avatarConfigs: AvatarConfiguration[] = [];
  private totalCount: number;

  /** A list of the configuration of all avatars that should be displayed.  */
  @Input('avatarConfigs')
  public set configs(avatarConfigs: AvatarConfiguration[]) {
    this.avatarConfigs = avatarConfigs || [];
    this.init();
  }

  /** The total count of avatars. If no totalCount is given, the length of the {@see avatarConfigs} is used instead.  */
  @Input('totalCount')
  public set totalAvatarCount(totalCount: number) {
    this.totalCount = totalCount;
    this.init();
  }

  private get maxAvatarsToShow(): number {
    return Math.max(1, Math.min(this.maxAvatars, this.maxShowableAvatars));
  }

  public init(): void {
    const totalAvatarCount = this.totalCount || this.avatarConfigs.length;
    const lastIndex = totalAvatarCount > this.maxAvatarsToShow ? this.maxAvatarsToShow - 1 : this.maxAvatarsToShow;
    this.avatarsShortList = this.maxAvatarsToShow !== -1 ? this.avatarConfigs.slice(0, lastIndex) : this.avatarConfigs;
    this.avatarConfigsForMenu = this.activateDisplayNameForMenu(this.avatarConfigs);

    const size = this.avatarConfigs[0]?.size || AVATAR_SIZE.s;
    const croppedAvatarCount = totalAvatarCount - this.avatarsShortList.length;
    const formattedCount = formatCount(croppedAvatarCount);
    this.showMoreAvatarsIcon = croppedAvatarCount === 0 ? null : CelumAvatarList.buildShowMoreButtonAvatar(size, `+${formattedCount}`);
  }

  public evaluateMaxAvatarsToShow(resizeObserver: ResizeObserverEntry): void {
    const target = resizeObserver.target;
    const avatars: HTMLElement[] = Array.from(target.querySelectorAll('celum-avatar'));

    if (!avatars.length) {
      return;
    }

    const avatarWidth = avatars[0].clientWidth;
    const newMaxAvatarsToShow = Math.floor(target.clientWidth / avatarWidth);

    if (newMaxAvatarsToShow === this.maxAvatarsToShow) {
      return;
    }
    this.maxShowableAvatars = newMaxAvatarsToShow;

    this.init();
  }

  protected onClick(event: MouseEvent): void {
    this.isShowMoreAvatarsMenuOpen = true;

    if(this.preventEventPropagationForMoreAvatarsMenu) {
      event.stopPropagation();
    }
  }

  private activateDisplayNameForMenu(avatarConfigurations: AvatarConfiguration[]): AvatarConfiguration[] {
    return avatarConfigurations.map(avatar => {
      const avatarClone = Object.assign({}, avatar);
      avatarClone.displayName = true;
      return avatarClone;
    });
  }

  private static buildShowMoreButtonAvatar(size: number, title: string): AvatarConfiguration {
    return new AvatarConfigBuilder().withSize(size).withTitle(title).withTitleLength(10).withHtmlTitleAttribute(false).withBackgroundColor(ColorConstants.BLUE_GRAY_400).build();
  }
}
