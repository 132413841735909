import { ValidationErrors } from '@angular/forms';

export enum CustomFieldChipsInputValidationErrorsEnum {
  CHIP_NAME_REQUIRED = 'CHIP_NAME_REQUIRED',
  CHIPS_NOT_DEFINED = 'CHIPS_NOT_DEFINED'
}

export type CustomFieldChipsInputValidationErrors = ValidationErrors & {
  [key in CustomFieldChipsInputValidationErrorsEnum]?: boolean;
};
