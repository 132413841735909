<ng-container *ngIf="!isReadOnly; else readOnlyView">
  <div class="custom-field-card">
    <div class="custom-field-card_header">
      <div>
          {{ controls.name.value || 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.NAME_INPUT_LABEL' | translate }}
      </div>
      <button
          *ngIf="isRemovable"
          mat-button
          celum-square-button
          [disableRipple]="true"
          [matTooltip]="'WORK_SHARED.REMOVE' | translate"
          class="custom-field-card_remove-button"
          (click)="removeClicked.emit()">
        <celum-icon class="custom-field-card_remove-button_icon" [clickable]="true" [configuration]="cancelIcon"></celum-icon>
      </button>
    </div>

    <div class="custom-field-card_content" [formGroup]="customFieldGroup">
      <div class="custom-field-card_content_name-container field_container">
        <span class="custom-field-label custom-field-name_label input-label">
            {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.NAME_INPUT_LABEL' | translate }}
        </span>
        <mat-form-field celum-form-field class="custom-field-name_control custom-error-field" [reserveHintSpace]="true">
          <input
              [formControl]="controls.name"
              placeholder="{{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.NAME_INPUT_PLACEHOLDER' | translate }}"
              [autoFocus]="true"
              maxlength="{{ TEXT_CONTROL_LIMIT }}"
              matInput
              data-cy="custom-field-name-input"
            />

          <mat-hint
              *ngIf="!controls.name.disabled && controls.name.value?.length >= TEXT_CONTROL_LIMIT - 10"
              data-cy="custom-field-dialog-name-input-remaining-hint">
              {{ TEXT_CONTROL_LIMIT - controls.name.value?.length ?? TEXT_CONTROL_LIMIT }}
              {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.NAME_INPUT_HINT' | translate }}
          </mat-hint>

          <mat-error *ngIf="controls.name.hasError('required')" data-cy="custom-field-dialog-name-input-required-error">
              {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.ERRORS.REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="controls.name.hasError(validationEnum.NAME_NOT_UNIQUE)">
              {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.ERRORS.NOT_UNIQUE' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="custom-field-card_content_type-container field_container">
        <span class="custom-field-label custom-field-type_label input-label">
            {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.TYPE_INPUT_LABEL' | translate }}
        </span>
        <mat-form-field 
            celum-form-field
            size="small"
            [matTooltip]="'TEAMSPACE_MANAGEMENT.FIELDS.CREATE_FIELD_DIALOG.FIELD_TYPE_DISABLED_TOOLTIP' | translate"
            [matTooltipDisabled]="isTypeChangingAllowed">
          <mat-select 
              [formControl]="controls.type"
              [compareWith]="customFieldTypeCompare"
              matSelectTooltipAutoDisabling
              [attr.data-cy]="'custom-field-type-select'">
            <mat-option *ngFor="let action of customFieldType | keyvalue" [value]="action.key" [attr.data-cy]="'custom-field-' + action.value + '-select-option'">
                {{ 'TEAMSPACE_MANAGEMENT.FIELDS.FIELD_TYPES.' + action.value | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <custom-field-chips-input *ngIf="controls.type.value === customFieldType.DROPDOWN" [formControl]="controls.chips"></custom-field-chips-input>
    </div>
  </div>
</ng-container>

<ng-template #readOnlyView>
  <custom-field-card-read-only-view
      [customFieldValue]="customFieldGroup.getRawValue()"
      [isRemovable]='isRemovable'
      (removeClicked)='removeClicked.emit()'
      [isExpanded]="isExpanded"
      (toggleExpandClicked)="toggleExpansion()">
  </custom-field-card-read-only-view>
</ng-template>
