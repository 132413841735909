import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';
import { Permission } from '@celum/work/app/core/api/permission';
import {
  CategoryValues,
  PredefinedCategoryValues,
  TemplateCategory
} from '@celum/work/app/core/model/entities/template-category/template-category.model';

@Component({
  selector: 'template-chooser-categories',
  templateUrl: './template-chooser-categories.component.html',
  styleUrls: ['./template-chooser-categories.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateChooserCategoriesComponent {
  @Input() public categories: TemplateCategory[];
  @Input() public activeCategory: CategoryValues;

  @Output() public readonly onSelected = new EventEmitter<TemplateCategory>();
  @Output() public readonly onReset = new EventEmitter<void>();
  @Output() public readonly onRequestPersonal = new EventEmitter<void>();

  public readonly personalCategoryKey = PredefinedCategoryValues.PERSONAL.toString();
  public readonly createWorkroomPermission = Permission.WORKROOM_CREATE;

  public iconLicenseWarningConfig = IconConfiguration.small('license-warning-gray', '');

  public setCategoryAsActive(category: TemplateCategory) {
    this.onSelected.emit(category);
  }

  public resetActiveCategory() {
    this.onReset.emit();
  }

  public requestPersonal() {
    this.onRequestPersonal.emit();
  }

  public trackByFn(index: number, category: TemplateCategory) {
    return category ? category.id : index;
  }
}
