import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { ContentItem, ContentItemType } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { TaskList, TaskListType } from '@celum/work/app/core/model/entities/task';

import { TaskListOwnerRuleComponent } from './task-list-owner-rule.component';
import { Robots, RuleType } from '../../../../core/model/entities/workroom/robot.model';
import { RobotChipConfig } from '../../../services/robots-factory';
import { BaseRobotConfigService, RobotNotAppliedConfig } from '../base-robot/base-robot-config.service';

@Injectable()
export class TaskListOwnerConfigService extends BaseRobotConfigService {
  constructor(public translate: TranslateService) {
    super(translate);
  }

  public robotBelongsTo(robot: Robots, entity: TaskList | ContentItem): boolean {
    switch (entity.entityType) {
      case TaskListType.instance():
        return true;
      case ContentItemType.instance():
        return false;
    }
    return false;
  }

  public serviceMatchesRobot(robot: Robots): boolean {
    return robot.type === RuleType.TASK_EDITING_RESTRICTION || robot.type === RuleType.TASK_MOVEMENT_RESTRICTION;
  }

  public getRobotChipConfig(robot: Robots): Observable<Partial<RobotChipConfig>> {
    return of({
      row1: this.translate.instant('ROBOTS.CHIP.FIRST_ROW.' + robot.type),
      row2: this.translate.instant('ROBOTS.CHIP.SECOND_ROW.TASK_LISTED'),
      editEvent: {
        robot,
        type: TaskListOwnerRuleComponent
      }
    });
  }

  public isRobotNotApplied(params: RobotNotAppliedConfig): Observable<boolean> {
    const { sourceEntity } = params;
    return of((sourceEntity as any)?.ownerIds?.length === 0);
  }
}
