import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { DataUtil, EntityType } from '@celum/core';
import { Color } from '@celum/work/app/core/model';

import { Entity } from '../../entity';
import { ContributorType } from '../contributor/contributor.model';
import { InvitationStatus, MemberType } from '../member/member.model';

export interface Person extends Entity<number, PersonType> {
  username: string;
  oid: string;
  firstName: string;
  lastName: string;
  color: Color;
  position: string;
  email: string;
  emailInvitedFrom: string;
  trialPlanActiveSince: number;
  avatarUrl: string;
  uiLanguage: string;
  invitationStatus?: InvitationStatus;
  createdOn: number;
}

export class PersonType implements EntityType {
  public static readonly TYPE_KEY = 'Person';

  private static _instance: PersonType;

  public id = PersonType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): PersonType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(ContributorType.TYPE_KEY)) {
        relationDefinitions.contributes = [ContributorType.instance().getSchema({})];
      }
      if (relations.relationsFor.includes(MemberType.TYPE_KEY)) {
        relationDefinitions.teamspaces = [MemberType.instance().getSchema({})];
      }
    }

    return new schema.Entity(PersonType.TYPE_KEY, relationDefinitions);
  }
}

export const personBasicProperties = [
  'name',
  'lastName',
  'firstName',
  'color',
  'position',
  'status',
  'email',
  'emailInvitedFrom',
  'trialPlanActiveSince',
  'uiLanguage',
  'invitationStatus'
];

export interface PersonState extends EntityState<Person> {}
