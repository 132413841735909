import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider, DataUtil } from '@celum/core';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo } from '@celum/work/app/core/model';
import { ContentItemTypeKeys } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import {
  ContentHubContentItemMapping,
  File,
  FilePropertiesToUpdate,
  FileType
} from '@celum/work/app/core/model/entities/file/file.model';
import { fileUpdatableProperties } from '@celum/work/app/core/model/entities/file/file.reducer';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public getFile(fileId: string): Observable<File> {
    const schema = FileType.instance().getSchema();
    const metaInfo = MetaInfo.of(FileType.TYPE_KEY_NESTED, schema);
    metaInfo.partialUpdates = {
      [FileType.TYPE_KEY]: fileUpdatableProperties
    };
    return this.httpClient
      .get<File>(`${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/${fileId}`)
      .pipe(map(res => this.handleSingleContentItemResult(res, metaInfo)));
  }

  public updateFile(file: File, propertiesToUpdate: FilePropertiesToUpdate): Observable<File> {
    const metaInfo = MetaInfo.of(FileType.TYPE_KEY_NESTED, FileType.instance().getSchema());
    metaInfo.partialUpdates = {
      [FileType.TYPE_KEY]: fileUpdatableProperties
    };

    return this.httpClient
      .patch(
        `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/${file.id}`,
        {
          typeKey: ContentItemTypeKeys.FILE_UPDATE,
          ...propertiesToUpdate
        },
        { ...STRONGLY_CONSISTENT_OPTION, params: { libraryId: file.libraryId } }
      )
      .pipe(map(res => this.handleSingleContentItemResult(res, metaInfo)));
  }

  public resolveContentItemId(
    contentHubMappingId: number,
    workroomId: number
  ): Observable<ContentHubContentItemMapping> {
    return this.httpClient.get<ContentHubContentItemMapping>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/content/${workroomId}/resolve-by-id/${contentHubMappingId}`
    );
  }

  public handleSingleContentItemResult(res, metaInfo): File {
    const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
    const files = entitiesResult.entities[FileType.TYPE_KEY];
    return DataUtil.isEmpty(files) ? null : (files[0] as File);
  }
}
