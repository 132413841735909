import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { DataUtil, EntityType } from '@celum/core';

import { Entity } from '../../entity';
import { PersonType } from '../person/person.model';
import { TeamspaceType } from '../teamspace/teamspace.model';

export enum MembershipStatus {
  INIT = 'INIT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum InvitationStatus {
  APPROVED = 'APPROVED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ACCEPTED = 'ACCEPTED',
  INVITED = 'INVITED'
}

export interface Member extends Entity<string, MemberType> {
  teamspaceId: number;
  personId: number | string;
  status: MembershipStatus;
  invitationStatus: InvitationStatus | null;
  externalId: string;
}

export class MemberType implements EntityType {
  public static readonly TYPE_KEY: string = 'Member';
  public static readonly TEAMSPACE_REF: string = 'teamspaceId';
  public static readonly PERSON_REF: string = 'personId';

  private static _instance: MemberType;

  public id = MemberType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): MemberType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(PersonType.TYPE_KEY)) {
        relationDefinitions.person = PersonType.instance().getSchema({});
      }
      if (relations.relationsFor.includes(TeamspaceType.TYPE_KEY)) {
        relationDefinitions.teamspace = TeamspaceType.instance().getSchema({});
      }
    }

    return new schema.Entity(MemberType.TYPE_KEY, relationDefinitions, {
      idAttribute: value => `member_${value.teamspaceId}_${value.personId}`
    });
  }
}

export interface MemberState extends EntityState<Member> {}
