import { createSelector } from '@ngrx/store';

import {
  EntitiesState,
  selectEntities as selectEntitiesState
} from '@celum/work/app/core/model/entities/entities.state';

import { workroomGroupItemAdapter } from './workroom-group-item.reducer';

const { selectAll, selectEntities } = workroomGroupItemAdapter.getSelectors();
export const selectWorkroomGroupItems = createSelector(
  selectEntitiesState,
  (state: EntitiesState) => state.workroomGroupItems
);

export const selectAllWorkroomGroupItems = createSelector(selectWorkroomGroupItems, selectAll);
export const selectWorkroomGroupItemEntities = createSelector(selectWorkroomGroupItems, selectEntities);

export const selectWorkroomGroupItemsByIds = (ids: number[]) =>
  createSelector(selectWorkroomGroupItemEntities, workroomGroupItems => ids.map(id => workroomGroupItems[id]));

export const selectGroupItemByWorkroomId = (workroomId: number) =>
  createSelector(selectAllWorkroomGroupItems, workroomGroupItems =>
    workroomGroupItems.find(groupItem => groupItem.workroomId === workroomId)
  );

export const selectGroupItemsByGroupId = (targetGroupId: number) =>
  createSelector(selectAllWorkroomGroupItems, workroomGroupItems =>
    workroomGroupItems.filter(({ groupId }) => groupId === targetGroupId)
  );
