import { createAction, props } from '@ngrx/store';

import { AccountAccess, Language, UiViewContext } from './ui-state.model';

export const UiStateTenantSelected = createAction('[UiState] Tenant selected', props<{ accountId: string }>());

export const UiStateChangeLanguage = createAction('[UiState] Change Language', props<{ language: Language }>());

export const UiStateSetLanguage = createAction('[UiState] Set Language', props<{ language: Language }>());

export const UiStateSetLoggedInPerson = createAction('[UiState] Set Logged In Person', props<{ personId: number }>());

export const UiStateAccountAccessesLoad = createAction('[UiState] Fetch account accesses');

export const UiStateAccountAccessesLoaded = createAction(
  '[UiState] Fetched account accesses',
  props<{ accountAccesses: AccountAccess[] }>()
);

export const UiStatePushViewContext = createAction(
  '[UiState] Push view context',
  props<{ context: UiViewContext; clearExisting?: boolean }>()
);

export const UiStateRemoveCurrentContext = createAction('[UiState] Remove current context');

export const UiStateRemoveSpecificContext = createAction(
  '[UiState] Remove specific context',
  props<{ specificContext: UiViewContext }>()
);

export const UiStateSetCurrentContextUpdateTimestamp = createAction(
  '[UiState] Set current context update timestamp',
  props<{ timestamp: number }>()
);
