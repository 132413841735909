import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { EMPTY, merge, Observable, of } from 'rxjs';
import { catchError, mergeMap, startWith, switchMap, withLatestFrom } from 'rxjs/operators';

import { CommentService } from '@celum/work/app/core/api/comment/comment.service';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import {
  CommentChangeReplyCount,
  CommentDeleteOne,
  CommentUpsertOne
} from '@celum/work/app/core/model/entities/comment/comment.actions';
import { selectCommentEntities } from '@celum/work/app/core/model/entities/comment/comment.selectors';
import { selectCurrentFileId } from '@celum/work/app/core/model/entities/file/file.selectors';

import {
  CommentCreateComment,
  CommentCreateCommentFailed,
  CommentCreateCommentSucceeded,
  CommentDeleteComment,
  CommentDeleteCommentFailed,
  CommentDeleteCommentSucceeded,
  CommentUpdateComment
} from './comment.actions';

@Injectable()
export class CommentEffects {
  public createComment = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentCreateComment),
      concatLatestFrom(() => this.store.select(selectCurrentFileId)),
      mergeMap(([action, fileId]) => {
        let stream$: Observable<TypedAction<any>> = this.commentService.createComment(action.comment, fileId).pipe(
          switchMap(comment => {
            // 1. remove the temporary comment
            // 2. issue success action
            return merge(
              of(CommentDeleteOne({ comment: action.comment })),
              of(
                CommentCreateCommentSucceeded({
                  comment: comment,
                  tempId: action.comment.id
                })
              )
            );
          }),
          catchError(err => {
            this.failureHandler.handleError(err);
            // reset creation...
            const dispatchedActions: Observable<Action>[] = [
              of(CommentDeleteOne({ comment: action.comment })),
              of(CommentCreateCommentFailed({ comment: action.comment }))
            ];
            if (action.comment.parentId) {
              dispatchedActions.push(
                of(
                  CommentChangeReplyCount({
                    id: action.comment.parentId,
                    delta: -1
                  })
                )
              );
            }
            return merge(...dispatchedActions);
          })
        );
        stream$ = stream$.pipe(
          startWith(
            CommentUpsertOne({
              comment: action.comment
            })
          )
        );

        if (action.comment.parentId) {
          stream$ = stream$.pipe(
            startWith(
              CommentChangeReplyCount({
                id: action.comment.parentId,
                delta: 1
              })
            )
          );
        }
        return stream$;
      })
    )
  );

  public updateComment = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentUpdateComment),
      withLatestFrom(this.store.select(selectCommentEntities), this.store.select(selectCurrentFileId)),
      mergeMap(([action, commentEntities, fileId]) =>
        this.commentService
          .updateComment(
            action.comment,
            action.propertiesToUpdate,
            fileId || (action.comment.fileVersionId ? action.comment.objectId : null)
          )
          .pipe(
            switchMap(() => EMPTY),
            catchError(err => {
              this.failureHandler.handleError(err);
              // reset update...
              return of(CommentUpsertOne({ comment: commentEntities[action.comment.id] }));
            }),
            startWith(CommentUpsertOne({ comment: { ...action.comment, ...action.propertiesToUpdate } }))
          )
      )
    )
  );

  public deleteComment = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentDeleteComment),
      mergeMap(action => {
        let stream$: Observable<TypedAction<any>> = this.commentService.deleteComment(action.comment.id).pipe(
          switchMap(() => of(CommentDeleteCommentSucceeded({ comment: action.comment }))),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(
              CommentDeleteCommentFailed({ comment: action.comment }),
              CommentUpsertOne({ comment: action.comment }),
              CommentChangeReplyCount({
                id: action.comment.parentId,
                delta: 1
              })
            );
          })
        );

        stream$ = stream$.pipe(startWith(CommentDeleteOne({ comment: action.comment })));

        if (action.comment.parentId) {
          stream$ = stream$.pipe(
            startWith(
              CommentChangeReplyCount({
                id: action.comment.parentId,
                delta: -1
              })
            )
          );
        }

        return stream$;
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private commentService: CommentService,
    private failureHandler: FailureHandler
  ) {}
}
