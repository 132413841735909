import { isEqual } from 'lodash';

import { FolderData } from './task-creation-automator.component';

export function selectedFoldersChanged(initiallySelectedFolders: FolderData[], selectedFolders: FolderData[]): boolean {
  return !isEqual(selectedFolders.sort(sortCompareFn), initiallySelectedFolders.sort(sortCompareFn));
}

export const sortCompareFn = (a: FolderData, b: FolderData) => a.folderName.localeCompare(b.folderName);

export function trackFolderFn(_: number, item: FolderData): string {
  return item.folderId;
}
