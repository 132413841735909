import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  RenditionDeleteMany,
  RenditionDeleteOne,
  RenditionUpsertMany,
  RenditionUpsertOne
} from '@celum/work/app/core/model/entities/rendition/rendition.actions';

import { Rendition, RenditionsState } from './rendition.model';

export const renditionAdapter: EntityAdapter<Rendition> = createEntityAdapter<Rendition>();
export const initialState: RenditionsState = renditionAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(RenditionUpsertOne, (state: RenditionsState, { rendition }) => renditionAdapter.upsertOne(rendition, state)),
  on(RenditionUpsertMany, (state: RenditionsState, { renditions }) => renditionAdapter.upsertMany(renditions, state)),
  on(RenditionDeleteOne, (state: RenditionsState, { rendition }) => renditionAdapter.removeOne(rendition.id, state)),
  on(RenditionDeleteMany, (state: RenditionsState, { ids }) => renditionAdapter.removeMany(ids, state))
);

export function renditionReducer(state: RenditionsState = initialState, action: Action): RenditionsState {
  return reducer(state, action);
}
