import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, startWith } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { ItemLinkDeleteOne, ItemLinkUpsertOne } from '@celum/work/app/core/model/entities/item-link/item-link.actions';
import { TargetContext } from '@celum/work/app/core/model/entities/item-link/item-link.model';
import {
  selectItemLinkRelationSliceBatchSize,
  selectItemLinkRelationSliceLastOffset
} from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/task-link/store/item-link.selectors';
import { selectCurrentWorkroomId } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';

import {
  ItemLinkCreateItemLink,
  ItemLinkCreateItemLinkFailed,
  ItemLinkCreateItemLinkSucceeded,
  ItemLinkDeleteItemLink,
  ItemLinkDeleteItemLinkFailed,
  ItemLinkDeleteItemLinkSucceeded,
  ItemLinkFetchNextBatchItemLinks,
  ItemLinkLoadItemLinksByType,
  ItemLinkLoadItemLinksByTypeFailed,
  ItemLinkLoadItemLinksByTypeSucceeded
} from './item-link.actions';
import { ItemLinkService } from './item-link.service';

@Injectable()
export class ItemLinkEffects {
  public loadItemLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemLinkLoadItemLinksByType),
      concatLatestFrom(({ itemLinkRelationType }) => [
        this.store.select(selectItemLinkRelationSliceBatchSize(itemLinkRelationType)),
        this.store.select(selectCurrentWorkroomId)
      ]),
      mergeMap(([{ sourceId, offset, itemLinkRelationType }, batchSize, workroomId]) =>
        this.itemLinkService
          .loadItemLinksByType({
            sourceId,
            itemLinkRelationType,
            paging: Paging.of(offset, batchSize),
            sorting: Sorting.of('createdOn', SortDirection.DESC),
            sourceContext: TargetContext.TASK,
            workroomId
          })
          .pipe(
            map(({ itemLinks, paginationResult }) =>
              ItemLinkLoadItemLinksByTypeSucceeded({
                itemLinks: itemLinks,
                paginationResult,
                resetState: offset === 0,
                itemLinkRelationType
              })
            ),
            catchError((error: HttpErrorResponse) => {
              this.failureHandler.handleError(error);
              return of(ItemLinkLoadItemLinksByTypeFailed({ error, itemLinkRelationType }));
            })
          )
      )
    )
  );

  public fetchNextBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemLinkFetchNextBatchItemLinks),
      concatLatestFrom(({ itemLinkRelationType }) =>
        this.store.select(selectItemLinkRelationSliceLastOffset(itemLinkRelationType))
      ),
      map(([{ sourceTaskId, itemLinkRelationType }, lastOffset]) =>
        ItemLinkLoadItemLinksByType({ offset: lastOffset, sourceId: sourceTaskId, itemLinkRelationType })
      )
    )
  );

  public createItemLink = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemLinkCreateItemLink),
      concatMap(({ itemLinkRelationType, targetTask, sourceTaskId, tmpItemLink }) =>
        this.itemLinkService.createItemLink(sourceTaskId, itemLinkRelationType, targetTask.id).pipe(
          map(itemLink => ItemLinkCreateItemLinkSucceeded({ itemLink, itemLinkRelationType })),
          catchError(() =>
            of(
              ItemLinkCreateItemLinkFailed({
                createdItemLink: tmpItemLink,
                itemLinkRelationType
              })
            )
          )
        )
      )
    )
  );

  public removeItemLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemLinkDeleteItemLink),
      mergeMap(({ itemLink, itemLinkRelationType }) =>
        this.itemLinkService.deleteItemLink(itemLink).pipe(
          map(() => ItemLinkDeleteItemLinkSucceeded({ itemLink, itemLinkRelationType })),
          catchError(_ => {
            return of(
              ItemLinkDeleteItemLinkFailed({ itemLink, itemLinkRelationType }),
              ItemLinkUpsertOne({ itemLink })
            );
          }),
          startWith(ItemLinkDeleteOne({ itemLink }))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private itemLinkService: ItemLinkService,
    private failureHandler: FailureHandler,
    private store: Store<any>
  ) {}
}
