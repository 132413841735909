import { Person } from '@celum/work/app/core/model/entities/person/person.model';
import { WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import {
  Automator,
  AutomatorType,
  isAutomator,
  RobotActionType,
  RobotContext,
  Robots,
  RobotTriggerType,
  RuleType,
  TaskAddAssigneesAction,
  TaskCreationFileCreatedTrigger
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import { BaseRobotConfigService } from '@celum/work/app/robots/components/robot-dialog-templates/base-robot/base-robot-config.service';

export function hasContentHubAutomator(config: WorkroomConfiguration, taskListId: number): boolean {
  return !!config?.automators?.find(
    automator => automator.type === AutomatorType.CONTENT_HUB && isTaskListRobot(taskListId, automator)
  );
}

export function hasTaskListRule(config: WorkroomConfiguration, taskListId: number, ruleType: RuleType): boolean {
  return config?.rules?.some(rule => isTaskListRobot(taskListId, rule) && rule.type === ruleType);
}

export function isTaskListRobot(taskListId: number, robot: Robots): boolean {
  return robot.sourceContext === RobotContext.TASK_LIST && taskListId === robot.sourceId;
}

export function getTaskListAutomatorsOfType(
  config: WorkroomConfiguration,
  taskListId: number,
  type: AutomatorType
): Automator[] {
  return config.automators.filter(automator => isTaskListRobot(taskListId, automator) && automator.type === type);
}

export function hasAutomatorAddAssigneesActionActiveContributors(
  config: WorkroomConfiguration,
  taskListId: number,
  activeContributors: Person[]
): boolean {
  const automator = getTaskListAutomatorsOfType(config, taskListId, AutomatorType.TASK_ASSIGNMENT).find(
    automatorItem =>
      automatorItem.action.type === RobotActionType.TASK_ADD_ASSIGNEES &&
      automatorItem.trigger.type === RobotTriggerType.TASK_MOVED
  );
  if (automator) {
    const peopleToAssignIds = (automator.action as TaskAddAssigneesAction).personIds;
    return activeContributors.some(person => peopleToAssignIds.includes(person.id));
  }
  return false;
}

export function isTaskListOwnerRestrictionRule(robot: Robots): boolean {
  return (
    !isAutomator(robot) && [RuleType.TASK_EDITING_RESTRICTION, RuleType.TASK_MOVEMENT_RESTRICTION].includes(robot.type)
  );
}

export function isTaskListOwnerRelatedAutomator(robot: Robots): boolean {
  return [
    RuleType.TASK_EDITING_RESTRICTION,
    RuleType.TASK_MOVEMENT_RESTRICTION,
    AutomatorType.TASK_ASSIGNMENT
  ].includes(robot.type);
}

export function findMatchingRobotConfigService(
  baseRobotConfigServices: BaseRobotConfigService[],
  robot: Robots
): BaseRobotConfigService {
  const robotConfigService = baseRobotConfigServices.find(configService => configService.serviceMatchesRobot(robot));

  if (!robotConfigService) {
    throw new Error(`Tried to get robot chip config for unsupported type: ${robot.type}`);
  }

  return robotConfigService;
}

export function hasAutomatorTaskCreationFileCreatedTrigger(config: WorkroomConfiguration, folderId: string): boolean {
  return !!config?.automators?.find(
    automator =>
      automator.trigger.type === RobotTriggerType.FILE_CREATED &&
      (automator.trigger as TaskCreationFileCreatedTrigger).folderIds?.some(id => id === folderId)
  );
}
