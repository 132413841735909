export enum ErrorKey {
  WORKROOM_INACTIVE = 'WORKROOM_INACTIVE',
  LICENSE_LIMIT_REACHED = 'LICENSE_LIMIT_REACHED',
  CONTENT_HUB_REPOSITORY_MISSING = 'CONTENT_HUB_REPOSITORY_MISSING',
  SACC_REPOSITORY_DISABLED = 'SACC_REPOSITORY_DISABLED',
  FILE_LIMIT_EXCEEDED = 'FILE_LIMIT_EXCEEDED',
  USER_INVITATION_USER_LIMIT_REACHED = 'USER_INVITATION_USER_LIMIT_REACHED',
  USER_INVITATION_PERIOD_LIMIT_REACHED = 'USER_INVITATION_PERIOD_LIMIT_REACHED',
  TASK_PERMISSION_DENIED = 'TASK_PERMISSION_DENIED',
  CONTENT_HUB_UNAUTHORIZED = 'CONTENT_HUB_UNAUTHORIZED'
}
