import { createSelector } from '@ngrx/store';

import { selectRouterParam } from '@celum/work/app/core/router/router.selectors';

import { workroomAdapter } from './workroom.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = workroomAdapter.getSelectors();

export const selectCurrentWorkroomIdParam = createSelector(selectRouterParam('workroomId'), workroomId => workroomId);

export const selectWorkrooms = createSelector(selectEntitiesState, (state: EntitiesState) => state.workrooms);

export const selectAllWorkrooms = createSelector(selectWorkrooms, selectAll);

export const selectWorkroomEntities = createSelector(selectWorkrooms, selectEntities);

export const selectPermissionsForWorkroom = (id: number) =>
  createSelector(selectWorkroomEntities, dict => dict[id]?.permissions);

export const selectWorkroomById = (id: number) => createSelector(selectWorkroomEntities, entities => entities[id]);

export const selectWorkroomByIds = (ids: number[]) =>
  createSelector(selectWorkroomEntities, workrooms => ids.map(id => workrooms[id]));

export const selectLibraryId = (workroomId: number) =>
  createSelector(selectWorkroomEntities, workrooms => workrooms[workroomId].libraryId);

export const selectWorkroomByLibraryId = (libraryId: string) =>
  createSelector(selectAllWorkrooms, workrooms => workrooms.find(workroom => workroom.libraryId === libraryId));

export const selectWorkroomIdByLibraryId = (libraryId: string) =>
  createSelector(selectWorkroomByLibraryId(libraryId), workroom => workroom.id);
