import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep, isEqual } from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { Roles } from '@celum/work/app/core/model';
import { InvitationStatus, Member, MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { selectMembersByUserIdsAndTeamspaceId } from '@celum/work/app/core/model/entities/member/member.selectors';
import { Person, selectPersonsByIds } from '@celum/work/app/core/model/entities/person';
import { InvitedPerson } from '@celum/work/app/core/model/entities/template/template.model';
import { UserRoleTuple } from '@celum/work/app/core/model/user-role-tuple.model';
import { selectLoggedInPerson, selectTenantTeamspace } from '@celum/work/app/core/ui-state/ui-state.selectors';
import { InvitedPersonDialogModel } from '@celum/work/app/person/invite/invited-person.model';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';
import { AvatarDecoratorFn, AvatarUtil } from '@celum/work/app/shared/util/avatar-util';
import { getAllRoles } from '@celum/work/app/shared/util/role-util';
import { WorkroomWizardEvent } from '@celum/work/app/workroom-wizard/model/workroom-wizard-event';

import { WorkroomWizardData } from '../components/workroom-wizard.component';

export interface PersonWithStatuses extends Person {
  status?: MembershipStatus;
  invitationStatus?: InvitationStatus;
}

@Injectable()
export class WorkroomWizardPeopleService {
  public people$: BehaviorSubject<Person[]> = new BehaviorSubject([]);
  public peopleWithStatuses: PersonWithStatuses[] = [];
  public invitedPeople: InvitedPerson[] = [];

  private initialInvitedPeople: InvitedPerson[];
  private fakeIdCounter = -1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WorkroomWizardData,
    private store: Store<any>,
    private eventBus: ApplicationEventBus,
    private avatarUtil: AvatarUtil
  ) {
    this.initPeople();
  }

  private static createInvitedPerson(person: Person, roles: Roles[], invitationMessage?: string): InvitedPerson {
    return {
      personId: person.id,
      roles,
      invitationEmail: { email: person.email, message: invitationMessage }
    };
  }

  public peopleNotTouched(): boolean {
    return isEqual(this.initialInvitedPeople, this.invitedPeople);
  }

  public setRole(updateRoleTuples: UserRoleTuple[]) {
    const invitedPeopleDict = this.invitedPeople.reduce((acc, curr) => {
      acc.set(curr.invitationEmail.email, curr);
      return acc;
    }, new Map<string, InvitedPerson>());
    updateRoleTuples.forEach(item => (invitedPeopleDict.get(item.person.email).roles = getAllRoles(item.role)));

    // TODO: this service should be refactored! added this to trigger UI changes in people-list.component
    this.people$.next([...this.people$.getValue()]);
  }

  public hasRole(person: Person, role: Roles): boolean {
    return this.invitedPeople.some(item => item.invitationEmail.email === person.email && item.roles.includes(role));
  }

  public getRoles(person: Person) {
    return this.invitedPeople.find(item => item.invitationEmail.email === person.email)?.roles || [];
  }

  public removePerson(people: Person[]) {
    const peopleCopy = this.people$.getValue().filter(item => !people.map(({ email }) => email).includes(item.email));
    this.invitedPeople = this.invitedPeople.filter(
      item => !people.map(({ email }) => email).includes(item.invitationEmail.email)
    );
    this.people$.next(peopleCopy);
    this.storePeopleWithStatuses(peopleCopy);
    this.eventBus.publishEvent({ type: WorkroomWizardEvent.PEOPLE_REMOVED });
  }

  public addNewPeople(newPeople: InvitedPersonDialogModel[]): Person[] {
    const dummyPeople: Person[] = newPeople?.map(item => this.createDummyNewPerson(item));
    const newInvitedPeople = dummyPeople.map((item, index) => {
      const personDialogModel = newPeople[index];
      return WorkroomWizardPeopleService.createInvitedPerson(
        item,
        [personDialogModel.getRole()],
        personDialogModel.getInvitationEmail().message
      );
    });

    this.invitedPeople = [...this.invitedPeople, ...newInvitedPeople];
    const newPeopleComputed = [...this.people$.getValue(), ...dummyPeople];
    this.people$.next(newPeopleComputed);
    this.storePeopleWithStatuses(newPeopleComputed);

    return newPeopleComputed;
  }

  public getAvatarDecoratorFn(): AvatarDecoratorFn {
    return avatar =>
      of(this.getRoles(avatar.person)).pipe(map(roles => this.avatarUtil.handleRoleBadge(avatar, roles)));
  }

  private generateInvitedPersonId(): number {
    return this.fakeIdCounter--;
  }

  private initPeople() {
    if (this.data.type.targetEntity === 'WORKROOM') {
      this.addMyself();
    }

    const personIds = this.data.template.people.map(({ personId }) => personId);

    const members$ = this.store
      .select(selectTenantTeamspace)
      .pipe(switchMap(teamspace => this.store.select(selectMembersByUserIdsAndTeamspaceId(personIds, teamspace.id))));

    this.store
      .select(selectPersonsByIds(personIds))
      .pipe(take(1), withLatestFrom(members$))
      .subscribe(([people, members]) => {
        const mappedPeople = people.map(person => ({
          ...person,
          invitationStatus: members.find(member => member.personId === person.id)?.invitationStatus
        }));

        this.invitedPeople = this.data.template.people.map(item => {
          const person = people.find(personItem => personItem.id === item.personId);
          return WorkroomWizardPeopleService.createInvitedPerson(person, item.roles);
        });
        this.initialInvitedPeople = cloneDeep(this.invitedPeople);
        this.people$.next(cloneDeep(people));
        this.storePeopleWithStatuses(mappedPeople, members);
      });
  }

  private addMyself() {
    this.store
      .select(selectLoggedInPerson)
      .pipe(take(1))
      .subscribe(person => {
        const existsInTemplatePeople = this.data.template.people.map(({ personId }) => personId).includes(person.id);

        if (!existsInTemplatePeople) {
          this.data.template.people.push({ personId: person.id, roles: [Roles.CONTRIBUTOR, Roles.MODERATOR] });
        } else {
          this.data.template.people.find(({ personId }) => personId === person.id).roles = [
            Roles.CONTRIBUTOR,
            Roles.MODERATOR
          ];
        }
      });
  }

  private createDummyNewPerson(item: InvitedPersonDialogModel): Person {
    const personId = this.generateInvitedPersonId();

    return {
      ...item.getPerson(),
      id: personId
    };
  }

  private storePeopleWithStatuses(people: Person[], members?: Member[]): void {
    this.peopleWithStatuses = people.map(person => {
      const member = members?.find(memberItem => memberItem.personId === person.id);
      const status = this.getStatusFromMemberOrPerson(person, member);

      return { ...person, status };
    });
  }

  private getStatusFromMemberOrPerson(person: Person, member?: Member): MembershipStatus {
    if (member) {
      return member.status;
    }

    return [InvitationStatus.INVITED, InvitationStatus.PENDING_APPROVAL].includes(person.invitationStatus)
      ? MembershipStatus.INIT
      : MembershipStatus.ACTIVE;
  }
}
