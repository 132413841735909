<robot [title]="'ROBOTS.ASSIGNMENT.TITLE'"
       [whenIcon]="robotTaskListIcon"
       [thenIcon]="automatorIcon"
       [notApplied]="notApplied$ | async"
       [readonly]="isReadonly"
       [messageBoxes]="messageBoxes$ | async">

  <ng-container when>
    <span class="robot-dialog_content_text" *ngFor="let token of getWhenTokens(); trackBy: trackByIdxFn"
          [style.order]="token.order">
      {{ token.text }}
    </span>

    <ng-container *ngIf="isReadonly; else triggerSelect">
      <span class="robot-dialog_content_text" data-cy="trigger-selection" [ngStyle]="getWhenTokenStyle('trigger')">
      <strong> {{ getTriggerTranslation(selectedTriggerType) | translate }} </strong>
    </span>
    </ng-container>

    <ng-template #triggerSelect>
      <mat-form-field celum-form-field size="small" class="robot-dialog_content_form-field robot-dialog_content_form-field--action"
                      [ngStyle]="getWhenTokenStyle('trigger')">
        <mat-select data-cy="trigger-selection" [(value)]="selectedTriggerType">
          <mat-option
              *ngFor="let trigger of getAllTriggers() | async; trackBy: trackByIdxFn"
              [attr.data-cy]="'trigger-option-' + trigger"
              [value]="trigger"
              [disabled]="isTriggerDisabled(trigger) | async">
            {{ getTriggerTranslation(trigger) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>


    <span class="robot-dialog_content_text" [ngStyle]="getWhenTokenStyle('taskList')">
      <strong>{{ sourceEntity.name | uppercase }}</strong>
    </span>
  </ng-container>
  <ng-container then>
    <span class="robot-dialog_content_text" *ngFor="let token of getThenTokens(); trackBy: trackByIdxFn" [style.order]="token.order" [ngClass]="token.class">
          {{ token.text }}
    </span>

    <ng-container *ngIf="isReadonly || !needsActionDropdown; else actionSelect">
      <span class="robot-dialog_content_text" data-cy="action-selection" [ngStyle]="getThenTokenStyle('action')">
      <strong *ngIf="isReadonly"> {{ getActionTranslation(selectedActionType) | translate }} </strong>
      <span *ngIf="!isReadonly"> {{ getActionTranslation(selectedActionType) | translate }} </span>
    </span>
    </ng-container>

    <ng-container *ngIf="isReadonly || !needsActionDropdown; else selectExistingAssigneesDropdown">
      <strong class="robot-dialog_content_text robot-dialog_content_assignees_text-lowercase" data-cy="keep-assignees-selection" [ngStyle]="getThenTokenStyle('existingAssigneesStrategy')">
          {{ 'ROBOTS.ASSIGNMENT.SUBSEQUENT_ACTION.' + selectedExistingAssigneesStrategy | translate }}
      </strong>
    </ng-container>

    <ng-template #actionSelect>
      <mat-form-field celum-form-field size="small" class="robot-dialog_content_form-field  robot-dialog_content_form-field--trigger"
                      [ngStyle]="getThenTokenStyle('action')">
        <mat-select data-cy="action-selection"
                    [(value)]="selectedActionType"
                    [matTooltip]="getActionTranslation(selectedActionType) | translate"
                    matSelectTooltipAutoDisabling>
          <mat-option
              *ngFor="let action of getAllActions() | async; trackBy: trackByIdxFn"
              [attr.data-cy]="'action-option-' + action"
              [value]="action"
              [disabled]="isActionDisabled(action) | async">
            {{ getActionTranslation(action) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <div class="robot-dialog_content_assignees" [ngStyle]="getThenTokenStyle('assignmentPicker')">
      <celum-icon
          *ngIf="selectAllTLO"
          class="select-all-tlo-icon"
          data-cy="select-all-tlo-icon"
          [matTooltip]="'ROBOTS.ASSIGNMENT.ALL_TLO.TOOLTIP' | translate"
          [configuration]="tloIcon">
      </celum-icon>
      <interactive-avatars
          [allPeople]="allPeople$ | async"
          [selectedPeople]="assignees"
          [peopleToExcludeInAvatarList]="getPeopleToExcludeInAvatarList()"
          [maxNumberOfAvatars]="4"
          [startingIcon]="assignIconConfig"
          [areTaskListOwners]="true"
          [hasAddPermissions]="!isReadonly"
          [hasRemovePermissions]="!isReadonly"
          [avatarSize]="avatarSize"
          [darkOverflowBackground]="true"
          [avatarDecorator]="avatarDecorator$"
          (searchValueChanged)="searchQuery$.next($event)"
          (personSelected)="addAssignee($event)"
          (personDeselected)="removeAssignee($event)"
          (avatarRemoved)="removeAssignee($event)">
        <ng-container extra-option>
          <search-and-select-entry
              class="robot-dialog_content_assignees_all-tlo"
              elementId="all-task-list-owners"
              [selected]="selectAllTLO"
              [showCheckbox]="true"
              (selectionChange)="allTloSelectionChanged($event)">
            <ng-container>
              <celum-icon class="search-and-select-task-list-owner-icon" [configuration]="tloIcon"></celum-icon>
              <span>{{ 'ROBOTS.ASSIGNMENT.ALL_TLO.HEADLINE' | translate }}</span>
            </ng-container>
          </search-and-select-entry>
        </ng-container>
        <button fallback-action mat-button celum-button size="small" (click)="inviteAndAssign()">
          {{ 'SEARCH_AND_SELECT.NO_RESULTS_BTN' | translate}}
        </button>
      </interactive-avatars>
    </div>

    <ng-template #selectExistingAssigneesDropdown>
      <mat-form-field
        celum-form-field size="small"
        class="robot-dialog_content_form-field robot-dialog_content_keep-assignees"
        [ngStyle]="getThenTokenStyle('existingAssigneesStrategy')">
        <mat-select data-cy="keep-assignees-selection" [(value)]="selectedExistingAssigneesStrategy">
          <mat-option value="{{ robotSubsequentActionType.KEEP }}" data-cy="keep-assignees-selection-keep">{{ 'ROBOTS.ASSIGNMENT.SUBSEQUENT_ACTION.KEEP' | translate }}</mat-option>
          <mat-option value="{{ robotSubsequentActionType.REMOVE }}" data-cy="keep-assignees-selection-remove">{{ 'ROBOTS.ASSIGNMENT.SUBSEQUENT_ACTION.REMOVE' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </ng-container>
</robot>

