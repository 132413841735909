import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { CelumPropertiesProvider } from '@celum/core';
import { ContentItemTypes } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import {
  RepositorySubscription,
  Subscription
} from '@celum/work/app/core/model/entities/subscription/subscription.model';
import { Workroom, WorkroomsUpsertDriveSubscription } from '@celum/work/app/core/model/entities/workroom';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<any>
  ) {}

  public getDriveSubscriptionOfPersons(workroomId: number, oids: string[]): Observable<RepositorySubscription[]> {
    return this.httpClient.post<RepositorySubscription[]>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/subscription/of-persons/for-workroom/${workroomId}`,
      oids
    );
  }

  public getLoggedInPersonDriveSubscription(
    workroom: Workroom,
    usernames: string[]
  ): Observable<RepositorySubscription[]> {
    return this.httpClient
      .post<
        RepositorySubscription[]
      >(`${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/subscription/of-persons/for-workroom/${workroom.libraryId}`, usernames)
      .pipe(
        map(res => {
          const subscriptions = res as RepositorySubscription[];
          const hasDriveSubscription = subscriptions.some(subscription => subscription?.active);
          this.store.dispatch(
            WorkroomsUpsertDriveSubscription({
              workroomId: workroom.id,
              driveSubscribed: hasDriveSubscription
            })
          );
          return subscriptions;
        })
      );
  }

  public createDriveSubscription(workroomId: number, libraryId: string): Observable<Subscription> {
    const body = {
      libraryId,
      workroomId: workroomId.toString()
    };
    return this.httpClient.post<Subscription>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/subscription/create-drive-subscription`,
      body
    );
  }

  public removeDriveSubscription(libraryId: string, allowedToKeepData: boolean): Observable<Subscription> {
    const body = {
      libraryId,
      allowedToKeepData
    };

    return this.httpClient.post<Subscription>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/subscription/remove-drive-subscription`,
      body
    );
  }

  public countContentItemsByLibrary(libraryId: string, filterByType: ContentItemTypes): Observable<number> {
    const body = {
      libraryId,
      filterByType
    };
    return this.httpClient.post<number>(
      `${CelumPropertiesProvider.properties.librariesHttpBaseAddress}/content/count-content-items-by-library`,
      body
    );
  }
}
