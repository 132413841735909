export class CommunicationException extends Error {
  constructor(
    public messageKey: string,
    public code: number,
    public stack?: any
  ) {
    super(messageKey);
    Object.setPrototypeOf(this, CommunicationException.prototype);
  }
}
