<task-lists-configuration-board
    [template]="template"
    [permissionCheckStrategy]="permissionCheckStrategy"
    [taskListBodyTemplate]="taskListBodyTemplate"
    [showWorkroomRobots]="false">
</task-lists-configuration-board>

<ng-template #taskListBodyTemplate let-index="index" let-taskList="taskList">
  <mat-accordion *ngIf="template.taskLists?.length > 1" [multi]="true" class="task-list-accordion">
    <mat-expansion-panel class="task-list-accordion_panel" data-content="general" [expanded]="true" [hideToggle]="true" [disabled]="true">
      <task-list-transitions
          [(transitions)]="template.workroomConfiguration.transitions"
          (transitionsChange)="onTransitionsChange()"
          [taskList]="getTaskList(taskList)"
          [taskLists]="getTaskLists(template.taskLists)">
      </task-list-transitions>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<div
    #processViewResizer
    resize
    orientation="vertical-down"
    class="workflow_diagram-resizer"
    [class.workflow_diagram-resizer--resizing]="resizing"
    [class.workflow_diagram-resizer--closed]="processViewClosed"
    [slider]="resizeButton"
    (sliderActive)="onSliderActive($event)">
  <toggle-drawer-button
      class="workflow_diagram-resizer_toggle-drawer-button"
      [vertical]="true"
      [tooltipConfig]="{ close: 'WORKROOM_WIZARD.DIALOG.COLLAPSE_BUTTON.PROCESS_VIEW.CLOSE', open: 'WORKROOM_WIZARD.DIALOG.COLLAPSE_BUTTON.PROCESS_VIEW.OPEN' }"
      [opened]="!processViewClosed"
      (openedChange)="processViewClosed = !processViewClosed">
  </toggle-drawer-button>
  <resize-button #resizeButton class="workflow_diagram-resizer_resize-button" orientation="vertical"></resize-button>
  <div class="jointjs-outlet" #jointjsOutlet></div>
</div>
