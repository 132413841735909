import { ElementRef, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter, map, startWith, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WindowResizeService {
  private onResize$: Observable<any>;

  constructor() {
    if (window) {
      this.onResize$ = fromEvent(window, 'resize').pipe(debounceTime(300));
    }
  }

  public static calculateBatchSize(config: BatchSizeConfiguration, innerWidth?: number, innerHeight?: number): number {
    let width = config.fixedValue?.width || config.el?.nativeElement?.clientWidth || innerWidth || window.innerWidth;

    let height =
      config.fixedValue?.height || config.el?.nativeElement?.clientHeight || innerHeight || window.innerHeight;

    width += config.valueModifier?.width || 0;
    height += config.valueModifier?.height || 0;

    return this.limitBatchSize(Math.floor((width * height) / config.elementSize));
  }

  public static limitBatchSize(batchSize: number): number {
    return Math.min(batchSize, 100);
  }

  private static calculateBatchSizeForEvent(event: any, config: Configuration): number {
    return WindowResizeService.calculateBatchSize(config, event.target.innerWidth, event.target.innerHeight);
  }

  /**
   * Calculate a batchSize according to the given configuration each time the window is resized.
   *
   * @param config  configuration to base the batchSize calculation on
   */
  public batchSizeChanges(config: Configuration): Observable<number> {
    let stream$ = this.onResize$.pipe(
      map(event => WindowResizeService.calculateBatchSizeForEvent(event, config)),
      filter(newBatchSize => newBatchSize > 0),
      takeUntil(config.unsubscribe$)
    );

    if (config.initialValue === undefined || config.initialValue) {
      const currentSize = WindowResizeService.calculateBatchSize(config);

      stream$ = stream$.pipe(startWith(currentSize));
    }

    return stream$;
  }
}

export interface BatchSizeConfiguration {
  elementSize: number;
  /**
   * The element from which the sizes are used. If not set, 'window' is used.
   */
  el?: ElementRef;
  /**
   * Define if one of the values should be fixed. No defaults.
   */
  fixedValue?: { width?: number; height?: number };
  /**
   * Define whether it should start with initial value.
   * (which means that it emits immediately and not only after the first resize event). Default is true.
   */
  initialValue?: boolean;
  /**
   * Define a modifier for width and/or height values (will be added/removed) before calculation of the available area.
   */
  valueModifier?: { width?: number; height?: number };
}

export interface Configuration extends BatchSizeConfiguration {
  unsubscribe$: Observable<any>;
}
