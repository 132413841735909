import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DueDateModule } from '@celum/work/app/shared/components/due-date/due-date.module';
import { WorkroomContextMenuModule } from '@celum/work/app/shared/components/workroom-context-menu/workroom-context-menu.module';
import { WorkroomIconModule } from '@celum/work/app/shared/components/workroom-icon/workroom-icon.module';
import { FormsManagementComponent } from '@celum/work/app/teamspace-management/components/forms-management/forms-management.component';

import { FormsOverviewTabComponent } from './components/forms-overview-tab/forms-overview-tab.component';
import { TeamspaceManagementComponent } from './components/teamspace-management-dialog/teamspace-management.component';
import { WorkroomsTabComponent } from './components/workrooms-tab/workrooms-tab.component';
import { WorkroomTableRowComponent } from './components/workrooms-tab/workrooms-virtual-scroll-table/workroom-table-row/workroom-table-row.component';
import { WorkroomsVirtualScrollTableComponent } from './components/workrooms-tab/workrooms-virtual-scroll-table/workrooms-virtual-scroll-table.component';
import { SharedModule } from '../shared';
import { StepperModule } from '../shared/components/stepper/stepper.module';

@NgModule({
  declarations: [TeamspaceManagementComponent, WorkroomsTabComponent],
  imports: [
    CommonModule,
    SharedModule,
    StepperModule,
    WorkroomContextMenuModule,
    WorkroomIconModule,
    DueDateModule,
    FormsManagementComponent,
    WorkroomTableRowComponent,
    WorkroomsVirtualScrollTableComponent,
    FormsOverviewTabComponent
  ],
  exports: [TeamspaceManagementComponent, WorkroomsTabComponent, FormsOverviewTabComponent]
})
export class TeamspaceManagementModule {}
