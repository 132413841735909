import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FilesTreeRootState } from './files-tree.model';
import { selectRouterQueryParam } from '../../../core/router/router.selectors';
import { WorkroomConstants } from '../../../shared/util/workroom-constants';

export const FEATURE_NAME_FILES_TREE = 'filesTree';

export const selectFilesTreeStateState = createFeatureSelector<FilesTreeRootState>(FEATURE_NAME_FILES_TREE);

export const selectFilesTreeState = (treeId: string) =>
  createSelector(selectFilesTreeStateState, (state: FilesTreeRootState) => state[treeId]);

export const selectFolderTreePaginationInformation = (treeId: string) =>
  createSelector(selectFilesTreeState(treeId), ({ lists }) =>
    Object.keys(lists).reduce((acc, listId) => {
      acc[listId] = {
        hasBottom: lists[listId].paginationResult.hasBottom,
        totalElementCount: lists[listId].paginationResult.totalElementCount
      };
      return acc;
    }, {})
  );

export const selectSelectedFolderId = createSelector(
  selectRouterQueryParam(WorkroomConstants.QUERY_PARAMS.SELECTED_FOLDER),
  folderId => folderId?.toString()
);
