import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, firstValueFrom, from, Observable, of } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { MessageDialog, MessageDialogConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { Permission } from '@celum/work/app/core/api/permission';
import {
  TaskMoveTasks,
  TaskMoveWithBulkAssignment,
  TasksMovedToOtherList
} from '@celum/work/app/core/api/task/task.actions';
import { TaskListDeleteTaskList } from '@celum/work/app/core/api/task-list/task-list.actions';
import { Roles } from '@celum/work/app/core/model';
import {
  Automator,
  AutomatorType,
  ExistingAssigneesStrategy,
  RobotAction,
  RobotActionType,
  RobotWithSubsequentAction,
  RuleType,
  TaskAddAssigneesAction
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import { selectLoggedInPerson, selectLoggedInPersonId } from '@celum/work/app/core/ui-state/ui-state.selectors';
import {
  MoveTasksDialogComponent,
  MoveTasksDialogConfiguration
} from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/move-task-dialog/move-tasks-dialog.component';
import { selectTasksForList } from '@celum/work/app/pages/workroom/pages/tasks/store/tasks-overview.selectors';
import {
  selectCurrentWorkroom,
  selectLoggedInPersonHasRoleForCurrentWorkroom
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { isTaskListOwnerRestrictionRule, isTaskListRobot } from '@celum/work/app/robots/services/robots-util';
import {
  ConfirmationDialog,
  ConfirmationDialogConfiguration
} from '@celum/work/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { calculateNewSortValue, ColorService, PermissionUtil, sortDescending } from '@celum/work/app/shared/util';

import { selectCurrentWorkroomTaskLists, selectTaskListById, Task, TaskList } from '../../core/model/entities/task';
import { Workroom, WorkroomConfiguration, WorkroomTransitionRule } from '../../core/model/entities/workroom';
import {
  hasAssignmentAutomatorWithActiveAssignees as hasTaskAssignmentAutomatorWithActiveAsigneesDefined,
  selectHasMandatoryAssignment
} from '../../robots/store/robot.selectors';

@Injectable({ providedIn: 'root' })
export class TaskListService {
  private readonly moveTaskWarnConfig = MessageDialogConfiguration.createWarnConfig(
    'MOVE_TASK_WARNING_DIALOG.TEXT',
    'MOVE_TASK_WARNING_DIALOG.HEADLINE'
  )
    .withButtons(['cancel', 'ok'])
    .withOkButtonText('MOVE_TASK_WARNING_DIALOG.CONFIRM');

  constructor(
    private dialogOpener: CelumDialogOpener,
    private store: Store<any>,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private permissionUtil: PermissionUtil
  ) {}

  public getNeighborToOpen(taskListId: number, task: Task): Observable<Task> {
    return this.store.select(selectTasksForList(taskListId)).pipe(
      take(1),
      map(tasks => tasks.sort((a, b) => sortDescending(a.sort, b.sort))),
      map((tasks: Task[]) => {
        const index = tasks.findIndex(otherTask => otherTask.id === task.id);
        return [tasks[index + 1], tasks[index - 1]].filter((taskItem: Task) => !!taskItem)[0] || null;
      })
    );
  }

  public moveTasksFromOneListToAnother(
    movedTasks: Task[],
    targetTaskListId: number,
    assignmentChanged: boolean,
    movedByDrag: boolean,
    idx?: number
  ): Observable<boolean> {
    const sourceTaskListId: number = movedTasks[0].taskListId;
    return combineLatest([
      this.store.select(selectHasMandatoryAssignment(targetTaskListId)),
      this.getNewSortValue(targetTaskListId, movedTasks, idx),
      this.store.select(hasTaskAssignmentAutomatorWithActiveAsigneesDefined(targetTaskListId)),
      this.wouldLoseAccessToTaskAfterMove(targetTaskListId, movedTasks)
    ]).pipe(
      take(1),
      switchMap(([isAssignmentMandatory, newSort, validAssignmentRobotExists, wouldLoseAccessToTaskAfterMove]) => {
        const haveToAssign =
          sourceTaskListId !== targetTaskListId &&
          isAssignmentMandatory &&
          !assignmentChanged &&
          !validAssignmentRobotExists;

        if (haveToAssign) {
          return this.showMoveTasksWithAssignmentDialogAndHandleResult(
            movedTasks,
            sourceTaskListId,
            targetTaskListId,
            movedByDrag,
            newSort
          );
        } else {
          if (wouldLoseAccessToTaskAfterMove) {
            const moveConfirmedPromise = this.moveTasksWithConfirmation(
              sourceTaskListId,
              targetTaskListId,
              movedTasks,
              movedByDrag,
              () => this.moveTasks(sourceTaskListId, targetTaskListId, movedTasks, newSort, movedByDrag)
            );
            return from(moveConfirmedPromise);
          } else {
            this.moveTasks(sourceTaskListId, targetTaskListId, movedTasks, newSort, movedByDrag);
            return of(true);
          }
        }
      })
    );
  }

  public deleteTask(taskCount$: Observable<number>, taskList: TaskList) {
    taskCount$
      .pipe(
        withLatestFrom(this.store.select(selectTasksForList(taskList.id)), this.store.select(selectCurrentWorkroom)),
        switchMap(([count, taskListTasks, workroom]) => {
          if (count > 0) {
            return of({ workroom, taskListTasks });
          } else {
            this.store.next(TaskListDeleteTaskList({ taskList, tasks: taskListTasks }));
            return EMPTY;
          }
        }),
        take(1)
      )
      .subscribe(({ workroom, taskListTasks }) => this.showDeleteTaskDialog(workroom, taskList, taskListTasks));
  }

  public isTaskListOwner(taskListId: number): Observable<boolean> {
    return combineLatest([
      this.store.select(selectTaskListById(taskListId)),
      this.store.select(selectLoggedInPerson)
    ]).pipe(map(([taskList, person]) => taskList?.ownerIds.includes(person?.id)));
  }

  public hasPermissionForAction(action: string, taskListId: number): Observable<boolean> {
    return combineLatest([this.store.select(selectTaskListById(taskListId)), this.isTaskListOwner(taskListId)]).pipe(
      switchMap(([taskList, isTLO]) =>
        this.permissionUtil
          .checkDynamicPermission(isTLO, action)
          .pipe(map(hasPermission => taskList?.ownerIds.length < 1 || hasPermission))
      )
    );
  }

  public hasPermissionForTaskListId(action: string, taskListId: number): Observable<boolean> {
    return this.hasPermissionForAction(action, taskListId).pipe(
      withLatestFrom(this.store.select(selectCurrentWorkroom)),
      take(1),
      map(
        ([hasPermission, workroom]) =>
          !!(
            hasPermission ||
            !workroom?.configuration?.rules.some(
              rule => isTaskListRobot(taskListId, rule) && rule.type === RuleType.TASK_EDITING_RESTRICTION
            )
          )
      )
    );
  }

  public getTaskListsForDropping(
    taskListId: number,
    workroomConfiguration: WorkroomConfiguration,
    taskLists: TaskList[]
  ): Observable<{
    taskLists: TaskList[];
    taskListsWithMandatoryAssignment: TaskList[];
    source: number;
    isVisitor: boolean;
  }> {
    return this.hasPermissionForAction(Permission.TASK_MOVE, taskListId).pipe(
      concatLatestFrom(() => this.permissionUtil.hasRoleForCurrentWorkroom(Roles.VISITOR)),
      take(1),
      map(([hasPermission, isVisitor]) => {
        const taskListsForDropping = [];
        const taskListsWithMandatoryAssignment = [];

        if (hasPermission || !this.rulesDefined(workroomConfiguration, taskListId)) {
          taskLists.forEach(taskList => {
            const hasMandatoryAssignmentRobot = workroomConfiguration?.rules.some(
              rule => isTaskListRobot(taskList.id, rule) && rule.type === RuleType.TASK_MANDATORY_ASSIGNMENT
            );
            if (hasMandatoryAssignmentRobot) {
              taskListsWithMandatoryAssignment.push(taskList);
            }
            if (
              this.transitionExists(taskListId, taskList.id, workroomConfiguration.transitions) ||
              // eslint-disable-next-line eqeqeq
              workroomConfiguration.transitions.findIndex(transition => transition.to == taskList.id) == -1 ||
              // eslint-disable-next-line eqeqeq
              taskList.id == taskListId
            ) {
              taskListsForDropping.push(taskList);
            }
          });
        }
        return {
          taskLists: taskListsForDropping,
          taskListsWithMandatoryAssignment,
          source: taskListId,
          isVisitor
        };
      })
    );
  }

  public getDynamicPermission(
    permission: Permission,
    taskList: TaskList
  ): Observable<Permission.ALLOWED | Permission.DENIED> {
    return this.permissionUtil.hasRoleForCurrentWorkroom(Roles.MODERATOR).pipe(
      withLatestFrom(this.store.select(selectCurrentWorkroom), this.store.select(selectLoggedInPersonId)),
      take(1),
      map(([isModerator, workroom, loggedInPersonId]) => {
        let permissions = [];
        const isTLO = taskList.ownerIds.includes(loggedInPersonId);

        if (isTLO || isModerator) {
          permissions = permissions.concat(this.permissionUtil.rolesToPermissions([Roles.TASK_LIST_OWNER]));
        }

        if (isModerator) {
          permissions = permissions.concat(this.permissionUtil.rolesToPermissions([Roles.MODERATOR]));
        }

        const hasPermission = workroom?.permissions?.permissions.concat(permissions).includes(permission);
        if (hasPermission) {
          return Permission.ALLOWED;
        }

        return Permission.DENIED;
      })
    );
  }

  public wouldLoseAccessToTaskAfterMove(targetTaskListId: number, movedTasks: Task[]): Observable<boolean> {
    return this.getAccessibleTasksAfterMove(targetTaskListId, movedTasks).pipe(
      map(accessibleTasks => accessibleTasks.length !== movedTasks.length)
    );
  }

  public getAccessibleTasksAfterMove(targetTaskListId: number, selectedTasks: Task[]) {
    return combineLatest([
      this.store.select(selectCurrentWorkroomTaskLists),
      this.store.select(selectCurrentWorkroom),
      this.store.select(selectLoggedInPersonHasRoleForCurrentWorkroom(Roles.VISITOR)),
      this.store.select(selectLoggedInPersonId)
    ]).pipe(
      take(1),
      map(([taskLists, currentWorkroom, isVisitor, loggedInPersonId]) => {
        const targetTaskList = taskLists.find(tasklist => tasklist.id === targetTaskListId);

        if (!isVisitor || targetTaskList.ownerIds.includes(loggedInPersonId)) {
          return selectedTasks;
        }

        if (
          selectedTasks.some(task => !task.assigneeIds.includes(loggedInPersonId)) &&
          !currentWorkroom.configuration.automators.some(robot =>
            this.isRobotAddingTheAssigneeFn(robot, targetTaskListId, loggedInPersonId)
          )
        ) {
          return selectedTasks.filter(task => task.assigneeIds.includes(loggedInPersonId));
        }

        if (
          currentWorkroom.configuration.automators.some(robot =>
            this.isRobotRemovingTheAssigneeFn(robot, targetTaskListId, loggedInPersonId)
          )
        ) {
          return [];
        }

        return selectedTasks;
      })
    );
  }

  private moveTasksWithConfirmation(
    sourceTaskListId: number,
    targetTaskListId: number,
    movedTasks: Task[],
    movedByDrag: boolean,
    onConfirmed: () => void
  ) {
    return firstValueFrom(this.wouldLoseAccessToTaskAfterMove(targetTaskListId, movedTasks)).then(showWarning => {
      if (showWarning) {
        return this.dialogOpener
          .showDialog('move-task-warning', MessageDialog, this.moveTaskWarnConfig)
          .then(isMoveConfirmed => {
            if (isMoveConfirmed) {
              onConfirmed();
            } else if (movedByDrag) {
              this.refreshTaskLists(movedTasks, sourceTaskListId, targetTaskListId);
            }
            return isMoveConfirmed;
          });
      } else {
        onConfirmed();
        return of(true);
      }
    });
  }

  private isRobotActionRemovingTheAssigneeFn(action: RobotAction, assigneeId: number): boolean {
    if (action.type === RobotActionType.TASK_REMOVE_ASSIGNEES) {
      return true;
    }

    return (
      action.type === RobotActionType.TASK_ADD_ASSIGNEES &&
      (action as RobotWithSubsequentAction).existingAssigneesStrategy === ExistingAssigneesStrategy.REMOVE &&
      !(action as TaskAddAssigneesAction).personIds.includes(assigneeId)
    );
  }

  private isRobotRemovingTheAssigneeFn(robot: Automator, targetTaskListId: number, assigneeId: number) {
    return (
      robot.type === AutomatorType.TASK_ASSIGNMENT &&
      robot.sourceId === targetTaskListId &&
      this.isRobotActionRemovingTheAssigneeFn(robot.action, assigneeId)
    );
  }

  private isRobotAddingTheAssigneeFn(robot: Automator, targetTaskListId: number, assigneeId: number) {
    return (
      robot.type === AutomatorType.TASK_ASSIGNMENT &&
      robot.sourceId === targetTaskListId &&
      robot.action.type === RobotActionType.TASK_ADD_ASSIGNEES &&
      (robot.action as TaskAddAssigneesAction).personIds.includes(assigneeId)
    );
  }

  private showDeleteTaskDialog(workroom: Workroom, taskList: TaskList, taskListTasks: Task[]) {
    const header = this.sanitizer.bypassSecurityTrustHtml(
      this.translateService.instant('TASK_BOARD.DELETE_LIST.HEADER')
    );
    const question = this.sanitizer.bypassSecurityTrustHtml(
      this.translateService.instant('TASK_BOARD.DELETE_LIST.QUESTION')
    );

    this.dialogOpener
      .showDialog(
        'deleteTaskList',
        ConfirmationDialog,
        new ConfirmationDialogConfiguration(
          header,
          question,
          ColorService.getColorAsRgbString(workroom.color),
          'COMMON.DELETE'
        )
      )
      .then(confirmed => {
        if (confirmed) {
          this.store.next(TaskListDeleteTaskList({ taskList, tasks: taskListTasks }));
        }
      });
  }

  private transitionExists(fromNum: number, to: number, transitions: WorkroomTransitionRule[]): boolean {
    return (
      // eslint-disable-next-line eqeqeq
      transitions.findIndex(transition => transition.from == fromNum && transition.to == to) !== -1 ||
      // eslint-disable-next-line eqeqeq
      transitions.findIndex(transition => transition.from == to && transition.to == fromNum && transition.reverse) !==
        -1
    );
  }

  private moveTasks(
    sourceTaskListId: number,
    targetTaskListId: number,
    tasks: Task[],
    newSortValue: number,
    movedByDrag?: boolean
  ): void {
    this.store.next(
      TaskMoveTasks({
        tasks,
        sourceTaskListId,
        targetTaskListId,
        sort: newSortValue,
        showSnackbar: !movedByDrag
      })
    );
  }

  private getNewSortValue(targetTaskListId: number, tasks: Task[], idx?: number): Observable<number> {
    return this.store.select(selectTasksForList(targetTaskListId)).pipe(
      map(tasksItem => tasksItem.sort((a, b) => sortDescending(a.sort, b.sort))),
      map(sortedTasks => {
        const task = tasks[0];
        return calculateNewSortValue(idx ?? 0, task.id, sortedTasks, idx === undefined);
      }),
      take(1)
    );
  }

  private refreshTaskLists(movedTasks: Task[], sourceTaskListId: number, targetTaskListId: number): void {
    this.store.dispatch(
      TasksMovedToOtherList({
        loggedInPersonId: null,
        tasks: movedTasks,
        sourceTaskListId: sourceTaskListId,
        targetTaskListId: targetTaskListId
      })
    );
    setTimeout(() => {
      this.store.dispatch(
        TasksMovedToOtherList({
          loggedInPersonId: null,
          tasks: movedTasks,
          sourceTaskListId: targetTaskListId,
          targetTaskListId: sourceTaskListId
        })
      );
    }, 0);
  }

  private rulesDefined(workroomConfiguration: WorkroomConfiguration, taskListId: number): boolean {
    return workroomConfiguration.rules.some(
      rule => isTaskListRobot(taskListId, rule) && isTaskListOwnerRestrictionRule(rule)
    );
  }

  private showMoveTasksWithAssignmentDialogAndHandleResult(
    movedTasks: Task[],
    sourceTaskListId: number,
    targetTaskListId: number,
    movedByDrag: boolean,
    newSort
  ) {
    return from(
      this.dialogOpener
        .showDialog('move-task', MoveTasksDialogComponent, new MoveTasksDialogConfiguration(movedTasks))
        .then(assignees => {
          if (assignees) {
            return from(
              this.moveTasksWithConfirmation(sourceTaskListId, targetTaskListId, movedTasks, movedByDrag, () => {
                const personIds = assignees.map(({ id }) => id);
                this.store.dispatch(
                  TaskMoveWithBulkAssignment({
                    tasks: movedTasks,
                    personIds,
                    targetTaskListId,
                    sourceTaskListId,
                    sort: newSort,
                    showSnackbar: !movedByDrag
                  })
                );
              })
            );
          } else if (movedByDrag) {
            this.refreshTaskLists(movedTasks, sourceTaskListId, targetTaskListId);
          }

          return !!assignees;
        })
    );
  }
}
