import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ImageData as QImageData } from 'quill-image-drop-and-paste';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';
import { isTruthy, UUIDGenerator } from '@celum/core';
import { QuillImageRendererOptions } from '@celum/quill-plugins';
import { CommentService } from '@celum/work/app/core/api/comment/comment.service';
import { Roles } from '@celum/work/app/core/model';
import { File as FileEntity, RenditionTypes } from '@celum/work/app/core/model/entities/file/file.model';
import { selectFileById } from '@celum/work/app/core/model/entities/file/file.selectors';
import { selectCurrentWorkroomCommentAttachmentsFolder } from '@celum/work/app/core/model/entities/folder/folder.selector';
import { Person } from '@celum/work/app/core/model/entities/person/person.model';
import {
  selectCurrentWorkroomContributorsForCurrentTaskWithSearchForVisitor,
  Task
} from '@celum/work/app/core/model/entities/task';
import { UiViewContext } from '@celum/work/app/core/ui-state/ui-state.model';
import { VersionSwitcherSelectCompareVersion } from '@celum/work/app/files/detail/version-switcher/store/version-switcher.actions';
import { UploadProgressTaskService } from '@celum/work/app/files/upload/components/upload-file/upload-progress-task.service';
import { FileDetailDialogComponent } from '@celum/work/app/pages/workroom/pages/files/pages/file-detail/components/file-detail-dialog/file-detail-dialog.component';
import { FileDetailDialogData } from '@celum/work/app/pages/workroom/pages/files/pages/file-detail/components/file-detail-dialog/model/file-detail-dialog-data.model';
import { FileDetailDialogResponse } from '@celum/work/app/pages/workroom/pages/files/pages/file-detail/components/file-detail-dialog/model/file-detail-dialog-response.model';
import {
  selectCurrentWorkroom,
  selectCurrentWorkroomContributorsWithSearch,
  selectCurrentWorkroomId,
  selectLoggedInPersonHasRoleForCurrentWorkroom
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { ProgressTaskActions } from '@celum/work/app/progress-task/store/progress-task.actions';
import { DateUtil, DownloadUrlUtil } from '@celum/work/app/shared/util';
import { AvatarUtil } from '@celum/work/app/shared/util/avatar-util';
import { WorkroomConstants } from '@celum/work/app/shared/util/workroom-constants';

import { FullscreenDialogComponent } from './../fullscreen-dialog/fullscreen-dialog.component';
import { supportedFileTypes } from './supported-files';
import { PreviewUtil } from '../../util';

interface CelumQuillConfigParams {
  personTemplate: TemplateRef<any>;
  canUpload: boolean;
  canMention: boolean;
  targetEntityForCommentBasedFiles: FileEntity | Task;
  unsubscribe$: Subject<void>;
}

interface ValueForMention {
  id: string;
  value: string;
  person: Person;
}

type RenderListFn = (values: ValueForMention[], searchTerm: string) => void;

@Injectable()
export class CelumQuillService {
  constructor(
    private commentService: CommentService,
    private store: Store<any>,
    private matDialog: MatDialog,
    private avatarUtil: AvatarUtil,
    private uploadProgressTaskService: UploadProgressTaskService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    // required because it appears images don't get rendered in embedded views when they are loaded after the view was created
    iconRegistry.getNamedSvgIcon('visitor-badge').subscribe({
      error: () => {
        return this.iconRegistry.addSvgIcon(
          'visitor-badge',
          this.sanitizer.bypassSecurityTrustResourceUrl('icons/visitor-badge.svg')
        );
      }
    });
  }

  public getCelumRendererConfig(): QuillImageRendererOptions {
    return {
      placeholderImageUrl: WorkroomConstants.LOADING_PREVIEW_PATH,
      quillImageHandlerUid: UUIDGenerator.generateId(),
      handleImageClicked: (fileId: string) => {
        this.resolveCommentImageFile(fileId).subscribe(() => this.openFullScreenDialogForQuillImages(fileId));
      },
      getImageUrl: (fileId: string) =>
        firstValueFrom(
          this.resolveCommentImageFile(fileId).pipe(
            isTruthy(),
            switchMap(file => file.activeVersion(this.store).pipe(take(1), isTruthy())),
            concatLatestFrom(activeVersion => [
              this.store.select(selectCurrentWorkroom),
              activeVersion.renditions(this.store)
            ]),
            map(([{ name, uploadedDate }, currentWorkroom, renditions]) => {
              const smallRendition = renditions.find(rendition => rendition.type === RenditionTypes.SMALL);
              const imgUrl = PreviewUtil.getFileDownloadURL(smallRendition, name, 'en', uploadedDate);

              return DownloadUrlUtil.resolveDownloadUrl(imgUrl, currentWorkroom);
            })
          ),
          { defaultValue: undefined }
        )
    };
  }

  public getQuillConfig({
    unsubscribe$,
    canMention,
    canUpload,
    personTemplate,
    targetEntityForCommentBasedFiles
  }: CelumQuillConfigParams) {
    const quillImageRendererConfig = this.getCelumRendererConfig();

    return {
      magicUrl: {
        urlRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
        globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g
      },
      imageDropAndPaste: {
        handler: this.imageHandler.bind(
          this,
          quillImageRendererConfig.quillImageHandlerUid,
          targetEntityForCommentBasedFiles
        ),
        actionOnUnsupportedFilePaste: this.showUnsupportedFileSnackbar.bind(this),
        actionOnUploadDisabled: this.showUploadNotAllowedSnackbar.bind(this),
        canUpload
      },
      quillImageRenderer: quillImageRendererConfig,
      mention: canMention ? this.generateMentionConfig({ personTemplate, unsubscribe$ }) : null
    };
  }

  public uploadImages(quillImageHandlerUid: string, targetEntityForQuillImages: FileEntity | Task): void {
    this.uploadProgressTaskService
      .getFiles(false, supportedFileTypes)
      .pipe(take(1))
      .subscribe(files => {
        // post validation if user selected 'All files' in native file selection dialog
        const supportedFiles = files.filter(file => supportedFileTypes.includes(file.type));

        if (supportedFiles.length < files.length) {
          this.showUnsupportedFileSnackbar();
        }

        if (supportedFiles.length > 0) {
          supportedFiles.forEach(file => this.imageFileHandler(file, quillImageHandlerUid, targetEntityForQuillImages));
        }
      });
  }

  public imageFileHandler(file: File, quillImageHandlerUid: string, targetEntityForQuillImages: FileEntity | Task) {
    const formattedDate = DateUtil.formatForFileName(new Date(file.lastModified), 'ddMMyy_HHmmss');
    const newFile = new File([file], formattedDate);

    this.store
      .select(selectCurrentWorkroomCommentAttachmentsFolder)
      .pipe(
        take(1),
        switchMap(rootFolder =>
          this.uploadProgressTaskService
            .getUploadTasksForFilesFromComputer({
              files: [newFile],
              folderId: rootFolder.id,
              targetEntityForQuillImages
            })
            .pipe(take(1))
        )
      )
      .subscribe(progressTasks => {
        progressTasks.forEach(progressTask => {
          progressTask.hiddenFromUi = true;
          progressTask.params.quillImageUid = quillImageHandlerUid;
        });
        this.store.dispatch(ProgressTaskActions.UpsertMany({ progressTasks }));
      });
  }

  private resolveCommentImageFile(fileId: string): Observable<FileEntity> {
    const fileFromServer$ = this.store.select(selectCurrentWorkroomId).pipe(
      take(1),
      switchMap(workroomId => this.commentService.getAttachments(workroomId, [fileId])),
      map(files => (files.length > 0 ? files[0] : null))
    );

    return this.store.select(selectFileById(fileId)).pipe(
      take(1),
      switchMap(file => (file?.activeVersionId ? of(file) : fileFromServer$))
    );
  }

  private openFullScreenDialogForQuillImages(fileId: string) {
    this.store.dispatch(VersionSwitcherSelectCompareVersion({ versionId: null }));

    const data: FileDetailDialogData = {
      fileId$: of(fileId),
      viewContext: UiViewContext.COMMENT_ATTACHMENT_DIALOG,
      showElements: {
        sidebar: false,
        annotationHeader: false,
        navigationButtons: false,
        informationThumbs: false,
        tasksTab: false
      },
      hasNext: () => of(false),
      hasPrevious: () => of(false)
    };

    this.matDialog.open<FileDetailDialogComponent, any, FileDetailDialogResponse>(
      FileDetailDialogComponent,
      FullscreenDialogComponent.dialogConfig(data)
    );
  }

  private generateMentionConfig({
    unsubscribe$,
    personTemplate
  }: Pick<CelumQuillConfigParams, 'unsubscribe$' | 'personTemplate'>) {
    return {
      allowedChars: /^.*$/,
      mentionDenotationChars: [`@`],
      defaultMenuOrientation: 'bottom',
      source: async (searchQuery: string, renderList: RenderListFn) => {
        renderList(await this.getValuesForMentioning(searchQuery), searchQuery);

        // close mention menu if quill is destroyed
        unsubscribe$.subscribe(() => renderList([], ''));
      },
      renderItem: (item: ValueForMention) => {
        const view = personTemplate.createEmbeddedView({ element: item.person });
        unsubscribe$.subscribe({
          complete: () => view.destroy()
        });
        view.detectChanges();
        return view.rootNodes[0];
      },
      positioningStrategy: 'fixed'
    };
  }

  private getValuesForMentioning(searchQuery: string): Promise<ValueForMention[]> {
    return firstValueFrom(
      this.store.select(selectLoggedInPersonHasRoleForCurrentWorkroom(Roles.VISITOR)).pipe(
        take(1),
        mergeMap(isVisitor =>
          (isVisitor
            ? this.store.select(selectCurrentWorkroomContributorsForCurrentTaskWithSearchForVisitor(searchQuery))
            : this.store.select(selectCurrentWorkroomContributorsWithSearch(searchQuery))
          ).pipe(take(1))
        ),
        map(persons =>
          persons.map(person => ({
            person,
            id: person.id.toString(),
            value: this.avatarUtil.getUserDisplayName(person)
          }))
        )
      )
    );
  }

  private showUnsupportedFileSnackbar(): void {
    const snackbarConfig = SnackbarConfiguration.error('QUILL.FILE_TYPE_NOT_SUPPORTED.TITLE').withDescription(
      'QUILL.FILE_TYPE_NOT_SUPPORTED.DESCRIPTION'
    );

    this.store.dispatch(new ShowSnackbar('unsuported-file-type', SimpleSnackbar, snackbarConfig));
  }

  private imageHandler(
    quillImageHandlerUid: string,
    targetEntityForCommentBasedFiles: FileEntity | Task,
    _dataUrl: string,
    _type: string,
    imageData: QImageData
  ) {
    this.imageFileHandler(imageData.toFile(), quillImageHandlerUid, targetEntityForCommentBasedFiles);
  }

  private showUploadNotAllowedSnackbar(): void {
    const snackbarConfig = SnackbarConfiguration.error('QUILL.UPLOAD_NOT_ALLOWED');

    this.store.dispatch(new ShowSnackbar('upload-not-allowed', SimpleSnackbar, snackbarConfig));
  }
}
