import { createAction, props } from '@ngrx/store';

import { Folder } from '@celum/work/app/core/model/entities/folder/folder.model';
import { Workroom } from '@celum/work/app/core/model/entities/workroom';
import { SubsequentActionType } from '@celum/work/app/core/model/subsequent-action.model';

import { Entity } from '../../core/model';
import { ContentItemType } from '../../core/model/entities/content-item/content-item.model';
import { AssetDTO } from '../model/content-hub.model';
import { ImportParams } from '../model/import.model';

export class ContentHubImportActions {
  public static RequestImportAsNewFile = createAction(
    '[Content Hub] Request Import as new file',
    props<ImportParams>()
  );

  public static RequestImportAsNewVersion = createAction(
    '[Content Hub] Request Import as a version',
    props<ImportParams>()
  );

  public static RequestImportDifferentAssetAsANewVersion = createAction(
    '[Content Hub] Request Import different asset as a new version',
    props<ImportParams>()
  );

  public static ImportVersion = createAction(
    '[Content Hub] Import version',
    props<{ fileId: string; asset: AssetDTO }>()
  );

  public static ImportAssets = createAction(
    '[Content Hub] Import Assets',
    props<{
      parentId: string;
      assetIds: string[];
      parentCollectionId: number;
      assetIdToProgressTaskIdMap: { [id: string]: string };
      workroomId: number;
      action?: SubsequentActionType;
    }>()
  );

  public static ImportCollection = createAction(
    '[Content Hub] Import Collection',
    props<{
      parentId: string;
      collectionId: string;
      workroom: Workroom;
      watch: boolean;
    }>()
  );

  public static ImportIntentConsume = createAction(
    '[Content Hub] Import Intent',
    props<{ importIntentId: string; folder: Folder; watch: boolean; action?: SubsequentActionType }>()
  );

  public static CheckForImportAssetSuccess = createAction(
    '[Content Hub] Check For Import Asset success',
    props<{ progressTaskId: string }>()
  );

  public static ImportAssetSuccess = createAction(
    '[Content Hub] Import Asset success',
    props<{ progressTaskId: string; untranslatedFile: Entity<string, ContentItemType> }>()
  );

  public static ImportVersionSuccess = createAction(
    '[Content Hub] Import Version success',
    props<{ progressTaskId: string }>()
  );

  public static HandleMissingProgressTaskMessages = createAction('[Content Hub] Handle Missing Progress Task Messages');
}
