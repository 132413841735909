import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export interface ApplicationEvent {
  type: string;
  data?: { [key: string]: any };
}

@Injectable({ providedIn: 'root' })
export class ApplicationEventBus {
  private events: Subject<ApplicationEvent> = new Subject<ApplicationEvent>();

  public publishEvent<T>(event: T);
  public publishEvent(event: ApplicationEvent) {
    this.events.next(event);
  }

  public observeEvents<T>(unsubscribeSignal$: Observable<void>, ...types: string[]): Observable<T>;
  public observeEvents(unsubscribeSignal$: Observable<void>, ...types: string[]): Observable<ApplicationEvent> {
    return this.events.pipe(
      filter(event => types.includes(event.type)),
      takeUntil(unsubscribeSignal$)
    );
  }
}
