export enum SubsequentActionTypeKey {
  CREATE_TASK_PER_IMPORT = 'create_task_per_import',
  CREATE_ONE_TASK_FOR_ALL_IMPORTS = 'create_one_task_for_all_imports',
  ATTACH_FILES_TO_TASK_ON_IMPORT = 'attach_files_to_task_on_import',
  ATTACH_FILES_TO_TASK_ON_UPLOAD = 'attach_files_to_task_on_upload'
}

export interface SubsequentActionType {
  _type: SubsequentActionTypeKey;
}

export interface CreateTaskPerImportAction extends SubsequentActionType {
  taskListId: number;
  taskForm?: TaskFormAutofillData;
}

export interface CreateOneTaskForAllImportsAction extends SubsequentActionType {
  taskListId: number;
  taskName: string;
  taskForm?: TaskFormAutofillData;
}

export interface AttachFilesToTaskOnImport extends SubsequentActionType {
  taskId: number;
}

export interface AttachFilesToTaskOnUpload extends SubsequentActionType {
  taskId: number;
}

export interface TaskFormAutofillData {
  formId: number;
  fields: {
    customFieldId: number;
    value: { data: any };
  }[];
}
