import { RenditionTypes } from '@celum/work/app/core/model/entities/file/file.model';
import { Rendition } from '@celum/work/app/core/model/entities/rendition/rendition.model';
import { DateUtil } from '@celum/work/app/shared/util/date-util';
import { WorkroomConstants } from '@celum/work/app/shared/util/workroom-constants';

// prettier-ignore
const availableFileExtensions: string[] = [
  '3fr', '3gp', 'aac', 'ai', 'aiff', 'arw', 'asf', 'avi', 'bmp', 'cr2', 'crw',
  'dcr', 'dng', 'doc', 'docx', 'dv', 'erf', 'f4v', 'flac', 'flv', 'gif', 'iiq',
  'jp2', 'jpg', 'm2t', 'm2v', 'm4a', 'm4v', 'mkv', 'mov', 'mp3', 'mp4', 'mpg',
  'mrw', 'mxf', 'nef', 'odp', 'ods', 'odt', 'ogg', 'opt', 'opus', 'orf',
  'pcd', 'pcd', 'pcx', 'pdf', 'pef', 'png', 'ppt', 'pptx', 'psb', 'psd',
  'raf', 'rar', 'raw', 'rtf', 'srw', 'sti', 'tif', 'ts', 'wav', 'webm',
  'wma', 'wmv', 'x3f', 'xls', 'slt', 'zip'
];

export class PreviewUtil {
  public static isCardPreview({ type }: Rendition): boolean {
    return [RenditionTypes.PREVIEW, RenditionTypes.AUDIO, RenditionTypes.SMALL].includes(type);
  }

  public static getExtension(fileName: string): string {
    if (!fileName) {
      return fileName;
    }
    const fileNameSplit = fileName.split('.');
    return fileNameSplit.length > 1 ? fileNameSplit.reverse()[0] : undefined;
  }

  public static getFileDownloadURL(rendition: Rendition, fileName: string, lang: string, uploadedDate: number): string {
    const now = new Date().getTime();
    const differenceInSeconds = DateUtil.getDifferenceInSeconds(now, uploadedDate);

    if (!fileName) {
      return this.getNoPreviewAssetPath();
    }

    if (!rendition && (!differenceInSeconds || differenceInSeconds < WorkroomConstants.LOADING_PREVIEW_TIMEOUT)) {
      return this.getLoadingPreviewAssetPath();
    }

    if (rendition?.downloadUrl) {
      return this.getDownloadUrlOrByLanguage(rendition, lang);
    }

    return this.getPreviewFile(fileName);
  }

  public static getNoPreviewAssetPath(): string {
    return WorkroomConstants.NO_PREVIEW_ASSET_PATH;
  }

  public static getLoadingPreviewAssetPath(): string {
    return WorkroomConstants.LOADING_PREVIEW_PATH;
  }

  public static getPreviewFile(fileName: string, fallbackPreview: string = this.getNoPreviewAssetPath()): string {
    const extension = PreviewUtil.getExtension(fileName);
    return PreviewUtil.getFileTypeIconOf(extension, fallbackPreview);
  }

  private static getFileTypeIconOf(extension: string, fallbackPreview: string): string {
    if (extension) {
      const extensionLowerCase = extension.toLowerCase();
      if (availableFileExtensions.includes(extensionLowerCase)) {
        return `assets/images/filetypes/filetype-${extensionLowerCase}-l.svg`;
      }
    }

    return fallbackPreview;
  }

  private static getDownloadUrlOrByLanguage(fileRendition: Rendition, lang: string) {
    const chFileDeleted = fileRendition.downloadUrl.includes(WorkroomConstants.DISCONNECTED_FROM_CH);
    if (chFileDeleted) {
      return WorkroomConstants.FILE_DELETE_ON_CH[lang];
    }

    return fileRendition.downloadUrl;
  }
}
