import { WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import {
  AutomatorType,
  ContentHubAutomatorTypes,
  RobotActionType
} from '@celum/work/app/core/model/entities/workroom/robot.model';

import { ContentHubSortField } from './content-hub.model';

export abstract class ContentHubStrategy {
  public abstract buildNumber(): string;

  public abstract defaultStrategy(): ContentHubStrategy;

  public filterInvalidCHAutomators(workroomConfig: WorkroomConfiguration): WorkroomConfiguration {
    const nonChAutomators = workroomConfig.automators.filter(({ type }) => type !== AutomatorType.CONTENT_HUB);
    const supportedChAutomators = workroomConfig.automators
      .filter(({ type }) => type === AutomatorType.CONTENT_HUB)
      .filter(automator => {
        const supportedActionsMap = this.getSupportedContentHubAction();
        const standaloneSupportedActions = supportedActionsMap[ContentHubAutomatorTypes.CONTENT_HUB_ASSETS].concat(
          supportedActionsMap[ContentHubAutomatorTypes.WORKROOM_FILES],
          supportedActionsMap[ContentHubAutomatorTypes.STANDALONE_GENERAL_ROBOT]
        );
        return standaloneSupportedActions.includes(automator.action.type);
      });
    return {
      ...workroomConfig,
      automators: [...nonChAutomators, ...supportedChAutomators]
    };
  }

  public supportsRelayCalls(): boolean {
    return this.getDefaultStrategy().supportsRelayCalls();
  }

  public getSupportedContentHubAction(): { [key: string]: RobotActionType[] } {
    return this.getDefaultStrategy().getSupportedContentHubAction();
  }

  public getSupportedContentHubSortFields(): ContentHubSortField[] {
    return this.getDefaultStrategy().getSupportedContentHubSortFields();
  }

  public supportsImportCollection(): boolean {
    return this.getDefaultStrategy().supportsImportCollection();
  }

  public supportsStringContentItemIds(): boolean {
    return this.getDefaultStrategy().supportsStringContentItemIds();
  }

  private getDefaultStrategy(): ContentHubStrategy {
    const strategy = this.defaultStrategy();
    if (!strategy) {
      throw new Error('Default strategy not defined');
    }
    return strategy;
  }
}
