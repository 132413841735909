import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { Folder } from '@celum/work/app/core/model/entities/folder/folder.model';
import { selectTaskListById, TaskList } from '@celum/work/app/core/model/entities/task';
import { ColorService } from '@celum/work/app/shared/util';

import { TaskCreationAutomatorComponent } from './task-creation-automator.component';
import {
  Automator,
  AutomatorType,
  CreateTaskBasedOnFileAction,
  isAutomator,
  RobotActionType,
  RobotContext,
  Robots,
  RobotTriggerType,
  TaskCreationFileCreatedTrigger
} from '../../../../core/model/entities/workroom/robot.model';
import { RobotChipConfig } from '../../../services/robots-factory';
import { BaseRobotConfigService, RobotNotAppliedConfig } from '../base-robot/base-robot-config.service';

@Injectable()
export class TaskCreationAutomatorConfigService extends BaseRobotConfigService {
  constructor(
    public translate: TranslateService,
    private store: Store<any>
  ) {
    super(translate);
  }

  public robotBelongsToTaskList(robot: Robots, taskList: TaskList): boolean {
    return ((robot as Automator).action as CreateTaskBasedOnFileAction).taskListId === taskList.id;
  }

  public robotBelongsToFolder(robot: Robots, folder: Folder) {
    return ((robot as Automator).trigger as TaskCreationFileCreatedTrigger).folderIds.some(
      folderId => folderId === folder.id
    );
  }

  public getTaskListById(taskListId: number): Observable<TaskList> {
    return this.store.select(selectTaskListById(taskListId));
  }

  public getTaskListName(taskListId: number, sourceEntity: TaskList): Observable<string> {
    return sourceEntity
      ? of(sourceEntity.name)
      : this.getTaskListById(taskListId).pipe(map(taskList => (taskList ? taskList.name : '')));
  }

  public getTaskListColor(taskListId: number, sourceEntity: TaskList): Observable<string> {
    return sourceEntity
      ? of(ColorService.getColorAsRgbString(sourceEntity.color))
      : this.getTaskListById(taskListId).pipe(
          map(taskList => {
            return taskList ? ColorService.getColorAsRgbString(taskList.color) : '';
          })
        );
  }

  public serviceMatchesRobot(robot: Robots): boolean {
    return robot.type === AutomatorType.TASK_CREATION && robot.sourceContext === RobotContext.TASK_LIST;
  }

  public getRobotChipConfig(robot: Automator, sourceEntity?: any): Observable<Partial<RobotChipConfig>> {
    const taskIcon = IconConfiguration.large('robot-task-create').withIconSize(16);
    const taskListId: number = robot.sourceId;
    return combineLatest([
      this.getTaskListName(taskListId, sourceEntity as TaskList),
      this.getTaskListColor(taskListId, sourceEntity as TaskList)
    ]).pipe(
      map(([name, color]) => {
        const nameUpper = name ? name.toUpperCase() : '';
        return {
          icon: taskIcon,
          row1: this.translate.instant(`ROBOTS.CHIP.FIRST_ROW.${robot.action.type}`),
          row2: this.translate.instant(`ROBOTS.CHIP.SECOND_ROW.${robot.trigger.type}`, {
            taskListName: nameUpper,
            taskListColor: color
          }),
          editEvent: {
            robot,
            type: TaskCreationAutomatorComponent
          }
        };
      })
    );
  }

  public isRobotNotApplied(params: RobotNotAppliedConfig): Observable<boolean> {
    const { robot, isRobotBeingCreated } = params;

    if (
      isAutomator(robot) &&
      robot.action.type === RobotActionType.CREATE_TASK_BASED_ON_FILE &&
      robot.trigger.type === RobotTriggerType.FILE_CREATED &&
      !isRobotBeingCreated
    ) {
      const folderIds = (robot.trigger as TaskCreationFileCreatedTrigger).folderIds;
      return of(folderIds.length === 0);
    }

    return of(false);
  }
}
