<robot
    [title]="'ROBOTS.CONTENT_HUB.TITLE'"
    [whenIcon]="robotTaskListIcon"
    [thenIcon]="automatorIcon"
    [readonly]="isReadonly"
    [messageBoxes]="messageBoxes$ | async"
    [notApplied]="notApplied$ | async">
  <ng-container when>
    <ng-container *ngFor="let token of getWhenTokens(); trackBy: trackByIdxFn">
      <span
          class="robot-dialog_content_text"
          [style.order]="token.order">
        {{ token.text }}
      </span>
    </ng-container>

    <ng-container *ngIf="isReadonly; else triggerSelect">
      <strong [ngStyle]="getWhenTokenStyle('trigger')"> {{ getTriggerTranslationKey(selectedTriggerType) | translate }} </strong>
    </ng-container>

    <ng-template #triggerSelect>
      <mat-form-field
          celum-form-field
          size="small"
          class="robot-dialog_content_form-field"
          [ngClass]='robotSubType === automatorSubType.WORKROOMS_FILES ? "robot-dialog_content_trigger_extra-long" : "robot-dialog_content_trigger_long"'
          [ngStyle]="getWhenTokenStyle('trigger')">
        <mat-select
            data-cy="trigger-selection"
            [(value)]="selectedTriggerType"
            panelClass="auto-fit-options-panel"
            matTooltip="{{getTriggerTranslationKey(selectedTriggerType) | translate}}"
            matSelectTooltipAutoDisabling>
          <mat-option
              *ngFor="let trigger of getAllTriggers() | async"
              [attr.data-cy]="'trigger-option-' + trigger"
              [value]="trigger"
              [disabled]="isTriggerDisabled(trigger) | async">
            {{ getTriggerTranslationKey(trigger) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <span
        *ngIf="selectedTriggerType !== robotTriggerType.WORKROOM_FINISHED"
        class="robot-dialog_content_text"
        [ngStyle]="getWhenTokenStyle('taskList')">
      <strong>{{ sourceEntity.name | uppercase }}</strong>
    </span>
  </ng-container>

  <ng-container then>
    <span
        *ngFor="let token of getThenTokens(); trackBy: trackByIdxFn"
        class="robot-dialog_content_text"
        [style.order]="token.order"
        [ngClass]="token.class">
      {{ token.text }}
    </span>

    <ng-container *ngIf="isReadonly || chType !== contentHubAutomatorTypes.CONTENT_HUB_ASSETS; else actionSelect">
      <span class="robot-dialog_content_text" data-cy="action-selection" [ngStyle]="getThenTokenStyle('action')">
        <strong> {{ (getActionTranslationKey(selectedActionType)) | translate }} </strong>
      </span>
    </ng-container>

    <ng-template #actionSelect>
      <mat-form-field celum-form-field size="small" class="robot-dialog_content_form-field robot-dialog_content_action" [ngStyle]="getThenTokenStyle('action')">
        <mat-select data-cy="action-selection"
                    [(value)]="selectedActionType"
                    panelClass="auto-fit-options-panel"
                    [matTooltip]="getActionTranslationKey(selectedActionType) | translate"
                    matSelectTooltipAutoDisabling>
          <mat-option
              *ngFor="let action of getAllActions() | async; trackBy: trackByIdxFn"
              [attr.data-cy]="'action-option-' + action"
              [value]="action"
              [disabled]="isActionDisabled(action) | async">
            {{ (getActionTranslationKey(action)) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <ng-container *ngIf="isReadonly || !needSubsequentActionDropdown; else fileSubsequentActionDropdown">
      <strong
          class="robot-dialog_content_text robot-dialog_content_subsequent_action"
          data-cy="subsequent-action-selection"
          [ngStyle]="getThenTokenStyle('fileSubsequentAction')">
        {{ ('ROBOTS.CONTENT_HUB.CONTENT_HUB_ASSETS.SUBSEQUENT_ACTION.' + subsequentAction) | translate }}
      </strong>
    </ng-container>

    <span
        *ngIf="selectedTriggerType === robotTriggerType.WORKROOM_FINISHED"
        class="robot-dialog_content_text"
        [ngStyle]="getThenTokenStyle('taskList')">
      <strong>{{ sourceEntity.name | uppercase }}</strong>
    </span>

    <mat-chip-option
        *ngIf="selectedCollection; else collectionSelector"
        class="robot-dialog_collection-chip"
        [selectable]="false"
        [ngStyle]="getThenTokenStyle('collectionSelector')"
        (click)="selectCollection()">
      <text-truncator class="robot-dialog_collection-chip_text" [text]="selectedCollection.name"></text-truncator>
      <celum-icon class="robot-dialog_collection-chip_remove-icon" [configuration]="cancelIcon" [clickable]="true" (click)="resetCollection($event)"></celum-icon>
    </mat-chip-option>

    <ng-template #collectionSelector>
      <celum-icon
          class="robot-dialog_collection-selector"
          [configuration]="collectionIcon"
          [clickable]="true"
          [ngStyle]="getThenTokenStyle('collectionSelector')"
          (click)="selectCollection()"
          [attr.data-cy]="'action-collection-selection'">
      </celum-icon>
    </ng-template>

    <ng-template #fileSubsequentActionDropdown>
      <mat-form-field
        celum-form-field size="small"
        class="robot-dialog_content_form-field robot-dialog_content_subsequent-dropdown"
        [ngStyle]="getThenTokenStyle('fileSubsequentAction')">
        <mat-select data-cy="subsequent-action-selection" [(value)]="subsequentAction">
          <mat-option
              value="{{ fileSubsequentAction.KEEP }}"
              data-cy="subsequent-keep">
            {{ 'ROBOTS.CONTENT_HUB.CONTENT_HUB_ASSETS.SUBSEQUENT_ACTION.KEEP' | translate }}
          </mat-option>
          <mat-option
              value="{{ fileSubsequentAction.DELETE }}"
              data-cy="subsequent-delete">
            {{ 'ROBOTS.CONTENT_HUB.CONTENT_HUB_ASSETS.SUBSEQUENT_ACTION.DELETE' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </ng-container>
</robot>
