import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';

import { initialSimpleListState } from '@celum/work/app/core/model/list.model';

import {
  AddFormToTask,
  AddFormToTaskFailed,
  AddFormToTaskSucceeded,
  CustomFormStateSetFormPanelExpandedState,
  HandleUpdateCustomFormFieldValue,
  LoadCustomFormFailed,
  LoadCustomFormSucceeded,
  LoadCustomFormToTask,
  RemoveFormFromTask,
  RemoveFormFromTaskFailed,
  RemoveFormFromTaskSucceeded,
  ResetCustomFormState
} from './custom-form-state.actions';
import { CustomFormStateState } from './custom-form-state.model';

export const initialState: CustomFormStateState = {
  ...initialSimpleListState,
  loading: false,
  expanded: true
};

const reducer = createReducer(
  initialState,
  on(CustomFormStateSetFormPanelExpandedState, (state, { expanded }) =>
    produce(state, draft => {
      draft.expanded = expanded;
    })
  ),
  on(AddFormToTask, RemoveFormFromTask, LoadCustomFormToTask, state =>
    produce(state, draft => {
      draft.loading = true;
    })
  ),
  on(
    AddFormToTaskSucceeded,
    AddFormToTaskFailed,
    RemoveFormFromTaskSucceeded,
    RemoveFormFromTaskFailed,
    LoadCustomFormSucceeded,
    LoadCustomFormFailed,
    state =>
      produce(state, draft => {
        draft.loading = false;
      })
  ),
  on(AddFormToTaskSucceeded, LoadCustomFormSucceeded, (state, { form }) =>
    produce(state, draft => {
      draft.formName = form.formName;
      draft.formFields = form.formFields;
      draft.formId = form.customFormId;
      draft.taskId = form.taskId;
    })
  ),
  on(HandleUpdateCustomFormFieldValue, (state, { fieldId, value }) =>
    produce(state, draft => {
      const field = draft.formFields.find(f => f.customFieldId === fieldId);
      if (field) {
        field.value = { data: value };
      }
    })
  ),
  on(ResetCustomFormState, RemoveFormFromTaskSucceeded, () => initialState)
);

export function customFormStateReducer(
  state: CustomFormStateState = initialState,
  action: Action
): CustomFormStateState {
  return reducer(state, action);
}
