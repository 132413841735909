import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import semver from 'semver/preload';

import { ContentHub610XStrategy } from '@celum/work/app/content-hub/model/content-hub-6-10-x-strategy';
import { ContentHub617XStrategy } from '@celum/work/app/content-hub/model/content-hub-6-17-x-strategy';
import { selectRepositoryById } from '@celum/work/app/core/model/entities/teamspace/index';
import { isNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { WorkroomConstants } from '../../shared/util';
import { ContentHub612XStrategy } from '../model/content-hub-6-12-x-strategy';
import { ContentHub63XStrategy } from '../model/content-hub-6-3-x-strategy';
import { ContentHub68XStrategy } from '../model/content-hub-6-8-x-strategy';
import { ContentHub69XStrategy } from '../model/content-hub-6-9-x-strategy';
import { ContentHubStrategy } from '../model/content-hub-strategy';
import { selectContentHubBuildNumber } from '../store/content-hub.selectors';

@Injectable({ providedIn: 'root' })
export class ContentHubBuildNumberStrategyResolverService {
  private strategies: ContentHubStrategy[] = [
    new ContentHub63XStrategy(),
    new ContentHub68XStrategy(),
    new ContentHub69XStrategy(),
    new ContentHub610XStrategy(),
    new ContentHub612XStrategy(),
    new ContentHub617XStrategy()
  ];

  constructor(private store: Store<any>) {}

  public resolve(repositoryId?: string): Observable<ContentHubStrategy> {
    if (isNullOrUndefined(repositoryId)) {
      return this.store.select(selectContentHubBuildNumber).pipe(
        map(currentBuildNumber => {
          return this.getStrategyFromBuildNumber(currentBuildNumber);
        })
      );
    }

    return this.store.select(selectRepositoryById(repositoryId)).pipe(
      map(repository => {
        return this.getStrategyFromBuildNumber(repository?.buildNumber);
      })
    );
  }

  private getStrategyFromBuildNumber(currentBuildNumber: string) {
    if (!currentBuildNumber) {
      return null;
    }
    const buildNumber = currentBuildNumber.match(WorkroomConstants.CH_BUILD_NUMBER_REGEX)[0];
    return this.strategies
      .sort((s1, s2) => semver.compare(s2.buildNumber(), s1.buildNumber()))
      .find(strategy => semver.gte(buildNumber, strategy.buildNumber()));
  }
}
