import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isTaskListRobot } from '@celum/work/app/robots/services/robots-util';

import { AutomatorService, AutomatorServiceArgs } from './automator.service';
import { RobotServiceArgs } from './robot.service';
import {
  AutomatorType,
  CreateTaskBasedOnFileAction,
  RobotActionType,
  RobotTriggerType,
  RuleType
} from '../../core/model/entities/workroom/robot.model';

@Injectable({ providedIn: 'root' })
export class TaskCreationAutomatorService extends AutomatorService {
  public getAllTriggers(args?: AutomatorServiceArgs): Observable<RobotTriggerType[]> {
    return of([RobotTriggerType.FILE_CREATED]);
  }

  public getAllActions(args?: AutomatorServiceArgs): Observable<RobotActionType[]> {
    return of([RobotActionType.CREATE_TASK_BASED_ON_FILE]);
  }

  public getAutomatorPreselection(
    args: AutomatorServiceArgs
  ): Observable<{ triggerType: RobotTriggerType; actionType: RobotActionType }> {
    return combineLatest([this.getAllTriggers(args), this.getAllActions(args)]).pipe(
      map(([allTriggers, allActions]) => {
        return {
          triggerType: allTriggers[0],
          actionType: allActions[0]
        };
      })
    );
  }

  public definedRobotsLimitReached(args: RobotServiceArgs): Observable<boolean> {
    const { sourceId, workroomConfig } = args;
    const automators = workroomConfig.automators.filter(automator => isTaskListRobot(sourceId, automator));

    const limitReached =
      automators.some(
        automator =>
          automator.action?.type === RobotActionType.CREATE_TASK_BASED_ON_FILE &&
          automator.trigger?.type === RobotTriggerType.FILE_CREATED &&
          (automator.action as CreateTaskBasedOnFileAction).taskListId === sourceId
      ) ||
      automators.some(
        automator =>
          automator.trigger?.type === RobotTriggerType.TASK_CREATED && automator.type === AutomatorType.CONTENT_HUB
      ) ||
      workroomConfig.rules.some(rule => rule.type === RuleType.TASK_CREATION_RESTRICTION && rule.sourceId === sourceId);

    return of(limitReached);
  }
}
