import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { isEmpty } from 'lodash';

import { DataUtil } from '@celum/core';
import { EntityUtil } from '@celum/work/app/core/model';
import { mergeEntities, mergeEntity } from '@celum/work/app/core/model/entities/entities-state-util';
import {
  FolderCreateSucceeded,
  FolderDeleteMany,
  FolderDeleteOne,
  FolderUpsertMany,
  FolderUpsertOne,
  ImportCollectionFolderCreated
} from '@celum/work/app/core/model/entities/folder/folder.actions';
import { Folder, FolderState, FolderType } from '@celum/work/app/core/model/entities/folder/folder.model';
import {
  ContentItemContentItemsDeleted,
  ContentItemContentItemsMoved,
  ContentItemContentItemsRestored
} from '@celum/work/app/files/store/content-item-list/content-item-list.actions';
import { FilesTreeSubfoldersLoaded } from '@celum/work/app/files/store/files-tree/files-tree.actions';
import { sortAssetIds } from '@celum/work/app/shared/util/sort.service';

export const folderAdapter: EntityAdapter<Folder> = createEntityAdapter<Folder>();
export const initialState: FolderState = folderAdapter.getInitialState();

type folderProp = keyof Folder;
export const folderProperties: folderProp[] = ['name', 'libraryId', 'parentId', 'hasSubfolders', 'hasChildren'];
export const folderUpdatableProperties: folderProp[] = ['name', 'libraryId', 'parentId'];

const reducer = createReducer(
  initialState,
  on(FolderUpsertOne, (state: FolderState, { folder, propertiesToUpdate }) => {
    const folders = EntityUtil.changedEntities(folderProperties, [folder], state.entities);

    if (!DataUtil.isEmpty(folders)) {
      return folderAdapter.upsertOne(mergeEntity(folders[0], state, propertiesToUpdate), state);
    }
    return state;
  }),

  on(FolderUpsertMany, (state: FolderState, { folders, propertiesToUpdate }) => {
    const updatedFolders = EntityUtil.changedEntities(folderProperties, folders, state.entities);
    return folderAdapter.upsertMany(mergeEntities(updatedFolders, state, propertiesToUpdate), state);
  }),

  on(FolderDeleteOne, (state: FolderState, { id }) => {
    return folderAdapter.removeOne(id, state);
  }),

  on(FolderDeleteMany, (state: FolderState, { ids }) => {
    return folderAdapter.removeMany(ids, state);
  }),

  on(FolderCreateSucceeded, (state: FolderState, { folder }) => {
    const entity = state.entities[folder.parentId];
    if (entity) {
      return folderAdapter.upsertOne(
        {
          ...entity,
          hasSubfolders: true
        },
        state
      );
    }
    return state;
  }),

  on(
    FilesTreeSubfoldersLoaded,
    (state: FolderState, { parentId, offset, limit, folders, paginationResult, treeId }) => {
      const expectedFolders = Object.values(state.entities)
        .filter(folder => folder.parentId === parentId)
        .sort((first, second) => first.name.localeCompare(second.name) || sortAssetIds(first.id, second.id))
        .slice(offset, offset + limit);
      const missingFolders = expectedFolders.filter(
        expected => folders.findIndex(folder => expected.id === folder.id) === -1
      );

      let folderState = state;
      if (!isEmpty(missingFolders)) {
        folderState = folderAdapter.removeMany(
          missingFolders.map(folder => folder.id),
          folderState
        );
      }
      if (isEmpty(folders) && state.entities[parentId]) {
        folderState = folderAdapter.upsertOne(
          {
            ...state.entities[parentId],
            hasSubfolders: false
          },
          folderState
        );
      }
      folders
        .filter(folder => !folder.hasSubfolders)
        .forEach(parent => {
          folderState = folderAdapter.removeMany(folder => folder.parentId === parent.id, folderState);
        });
      return folderState;
    }
  ),

  on(ContentItemContentItemsMoved, (state: FolderState, { contentItems, sourceParentIds, targetParentId }) => {
    const folders = [];
    sourceParentIds.forEach(id => {
      const entity = state.entities[id];
      if (entity) {
        const hasNoMoreSubfolders = !Object.values(state.entities).find(e => e.parentId === id);
        if (hasNoMoreSubfolders) {
          folders.push({
            ...entity,
            hasSubfolders: false
          });
        }
      }
    });

    const isFolder = contentItems.find(ci => ci.entityType instanceof FolderType);
    if (isFolder) {
      const entity = state.entities[targetParentId];
      if (entity) {
        folders.push({
          ...entity,
          hasSubfolders: true
        });
      }
    }
    return folderAdapter.upsertMany(folders, state);
  }),

  on(ContentItemContentItemsDeleted, (state: FolderState, { contentItemIds }) => {
    const folders = [];
    contentItemIds.forEach(id => {
      const entity = state.entities[id];
      if (entity) {
        const parent = state.entities[entity.parentId];
        if (parent) {
          const hasNoMoreSubfolders = !Object.values(state.entities).find(e => e.parentId === parent.id && e.id !== id);
          if (hasNoMoreSubfolders) {
            folders.push({
              ...parent,
              hasSubfolders: false
            });
          }
        }
      }
    });
    let folderState = folderAdapter.removeMany(contentItemIds, state);
    if (folders) {
      folderState = folderAdapter.upsertMany(folders, folderState);
    }
    return folderState;
  }),

  on(ContentItemContentItemsRestored, (state: FolderState, { contentItemIds, parentIds }) => {
    const folders = parentIds
      .map(parentId => state.entities[parentId])
      .map(parent => ({ ...parent, hasSubfolders: true }));

    const folderState = folderAdapter.removeMany(contentItemIds, state);
    return folderAdapter.upsertMany(folders, folderState);
  }),

  on(ImportCollectionFolderCreated, (state: FolderState, { parentId }) => {
    const entity = state.entities[parentId];
    if (entity) {
      return folderAdapter.upsertOne(
        {
          ...entity,
          hasSubfolders: true
        },
        state
      );
    }
    return state;
  })
);

export function folderReducer(state: FolderState = initialState, action: Action): FolderState {
  return reducer(state, action);
}
