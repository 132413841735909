import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CustomFieldUpsertMany } from '@celum/work/app/core/model/entities/custom-field/custom-field.actions';
import { mergeEntities } from '@celum/work/app/core/model/entities/entities-state-util';

import { CustomField, CustomFieldsState } from './custom-field.model';

export const customFieldAdapter: EntityAdapter<CustomField> = createEntityAdapter<CustomField>();
export const initialState: CustomFieldsState = customFieldAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(CustomFieldUpsertMany, (state: CustomFieldsState, { customFields, propertiesToUpdate }) =>
    customFieldAdapter.upsertMany(mergeEntities(customFields, state, propertiesToUpdate), state)
  )
);

export function customFieldReducer(state: CustomFieldsState = initialState, action: Action): CustomFieldsState {
  return reducer(state, action);
}
