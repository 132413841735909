import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { PageContext } from '@celum/common-components';
import { DataUtil, GroupType, Operation } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

import { ContextMenuPosition, OperationOrGroup } from '../../model/operation-menu.models';
import { OperationMenuService } from '../../services/operation-menu.service';

@Component({
             selector: 'operation-menu',
             templateUrl: './operation-menu.html',
             styleUrls: ['./operation-menu.less', '../icon-menu-item/icon-menu-item.html'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class OperationMenu extends ReactiveComponent implements OnInit {

  @ViewChild('contextMenuTrigger', { static: true, read: ElementRef }) public contextMenuTrigger: ElementRef;
  @ViewChild(MatMenuTrigger) public matMenuTrigger: MatMenuTrigger;

  public currentContext: PageContext;
  public groupsOfOperations: OperationOrGroup[];
  public sectionType = GroupType.SECTION;

  private backdropClickSubscription: Subscription;

  constructor(private menuService: OperationMenuService, private renderer: Renderer2) {
    super();
  }

  public ngOnInit(): void {
    this.menuService.listenForContextMenuOpen$().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(({ position, context, operations }) => {
      this.triggerContextMenuOpen(operations, context, position);
    });
  }

  public executeOperation(operation: Operation): void {
    operation.execute(this.currentContext);
    this.closeMenu();
  }

  private handleContextmenu(position: ContextMenuPosition): void {
    // move the trigger to the click position -> menu should open at correct position
    this.renderer.setStyle(this.contextMenuTrigger.nativeElement, 'left', `${position.x}px`);
    this.renderer.setStyle(this.contextMenuTrigger.nativeElement, 'top', `${position.y}px`);

    // need to set openedBy explicitly, otherwise it will focus first menu item
    this.matMenuTrigger._openedBy = 'mouse';
    this.matMenuTrigger.openMenu();
  }

  private triggerContextMenuOpen(operations: OperationOrGroup[], context: PageContext, position: ContextMenuPosition): void {
    this.groupsOfOperations = operations;
    this.currentContext = context;

    if (DataUtil.isEmpty(this.groupsOfOperations)) {
      this.closeMenu(); // there might be another menu open which still needs to be closed
      return;
    }

    if (!this.matMenuTrigger.menuOpen) {
      this.openMenu(position);
      return;
    }

    // if the menu is still open, close it and wait for the "menuClosed" event before opening it again
    // if this is not done, the menu just stays at the old position :/
    this.matMenuTrigger.menuClosed.pipe(take(1)).subscribe(() => {
      this.openMenu(position);
    });

    this.closeMenu();
  }

  private openMenu(position: ContextMenuPosition): void {
    this.handleContextmenu(position);
    this.closeMenuOnBackdropClick();
  }

  private closeMenu(): void {
    this.backdropClickSubscription && this.backdropClickSubscription.unsubscribe();
    this.matMenuTrigger?.closeMenu();
  }

  private closeMenuOnBackdropClick(): void {
    this.backdropClickSubscription = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(() => this.matMenuTrigger.menuOpen),
        take(1),
        takeUntil(this.unsubscribe$)
      ).subscribe(() => this.closeMenu());
  }

}
