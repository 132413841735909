import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
  ColorConstants,
  ICON_COLOR_VARIANT,
  ICON_TEXT_ALIGN,
  ICON_TEXT_TRANSFORM_NONE,
  IconConfiguration
} from '@celum/common-components';

@Injectable({ providedIn: 'root' })
export class DraggingService {
  public draggingOverSource = new BehaviorSubject(false);
  public readonly draggingOver$ = this.draggingOverSource.asObservable();

  public changeDroppable(draggingOver: boolean): void {
    this.draggingOverSource.next(draggingOver);
  }

  public getUploadDropIcon(): IconConfiguration {
    return IconConfiguration.large('upload-to-folder')
      .withColor(ColorConstants.SYSTEM_BLACK_75)
      .withIconDimensions(120, 82)
      .withTextAlign(ICON_TEXT_ALIGN.bottom)
      .withColorVariant(ICON_COLOR_VARIANT.dark)
      .withTextTransformation(ICON_TEXT_TRANSFORM_NONE)
      .withTextColor(ColorConstants.SYSTEM_BLACK_75)
      .withTextSize(16);
  }
}
