<robot [title]="'ROBOTS.TASK_LIST_OWNER.TITLE'"
       [whenIcon]="robotTaskListIcon"
       [thenIcon]="automatorIcon"
       [notApplied]="notApplied$ | async"
       [readonly]="isReadonly"
       [messageBoxes]="messageBoxes$ | async">
  <ng-container when>
    <span class="robot-dialog_content_text"
          [innerHTML]="'ROBOTS.TASK_LIST_OWNER.ROW1' | translate: { taskListName: sourceEntity.name.toUpperCase() }">
    </span>
  </ng-container>
  <ng-container then>
    <span class="robot-dialog_content_text" *ngFor="let token of getThenTokens(); trackBy: trackByIdxFn" [style.order]="token.order">{{ token.text }}</span>

    <ng-container *ngIf="isReadonly; else typeSelect">
      <strong> {{ getTypeTranslation(selectedRuleType) | translate }} </strong>
    </ng-container>

    <ng-template #typeSelect>
      <span class="robot-dialog_content_text" data-cy="action-selection" [ngStyle]="getThenTokenStyle('rule')">
        <mat-form-field celum-form-field size="small" class="robot-dialog_content_form-field">
          <mat-select data-cy="type-selection" [(value)]="selectedRuleType">
            <mat-option
                *ngFor="let type of possibleTypes; trackBy: trackByIdxFn"
                [attr.data-cy]="'type-option-' + type"
                [value]="type">
              {{ getTypeTranslation(type) | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    </ng-template>

  </ng-container>
</robot>
