import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

import { DataUtil } from '@celum/core';

export interface SelectElement {
  id: any;
}

@Component({
  selector: 'search-and-select',
  templateUrl: './search-and-select.component.html',
  styleUrls: ['./search-and-select.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SearchAndSelect implements OnChanges {
  @HostBinding('class') public hostCls = 'search-and-select';

  @Input() public placeholder = 'COMMON.SEARCH';
  @Input() public hasMoreKey = 'SEARCH_AND_SELECT.HAS_MORE.SIMPLE';
  @Input() public hasMoreKeySingular: string;
  @Input() public hasMoreKeyPlural: string;
  @Input() public noResultsKey = 'SEARCH_AND_SELECT.NO_RESULTS';

  @Input() public elements: SelectElement[] = [];
  @Input() public minChars = 1;
  @Input() public selectedElements: SelectElement[] = [];
  @Input() public hasMore = false;
  @Input() public hasMoreAmount: number;
  @Input() public searchFieldLabelKey: string;
  @Input() public isSearchEnabled = true;
  @Input() public showCheckbox = true;
  @Input() public isLoading = false;

  @Output() public readonly elementSelected = new EventEmitter<SelectElement>();
  @Output() public readonly elementDeselected = new EventEmitter<SelectElement>();
  @Output() public readonly searchValueChanged = new EventEmitter<string>();

  @ContentChild(TemplateRef, { static: true }) public elementTemplate: TemplateRef<any>;

  public currentSearchValue = '';

  @Input() public elementIdentifier: (element: SelectElement) => string = e => e.id;

  public ngOnChanges({ selectedElements }: SimpleChanges) {
    if (selectedElements) {
      // make sure not use the actually passed array -> there will modifications happen!
      this.selectedElements = [...selectedElements.currentValue];
    }
  }

  public onSearchValueChanged(searchValue: string) {
    this.currentSearchValue = searchValue;
    if (searchValue.length < this.minChars && searchValue.length > 0) {
      return;
    }

    this.searchValueChanged.emit(searchValue);
  }

  public isChecked(element: SelectElement): boolean {
    return !!this.selectedElements.find(selectedElement => selectedElement.id === element.id);
  }

  public trackByFn(index: number, entry: SelectElement): string {
    return entry.id;
  }

  public hasNoResults(): boolean {
    // there is a searchValue present and no results...
    return this.currentSearchValue?.length > 0 && DataUtil.isEmpty(this.elements);
  }

  public toggleValue(checked: boolean, element: SelectElement) {
    const index = this.selectedElements.findIndex(selectedElement => selectedElement.id === element.id);

    if (checked) {
      if (index < 0) {
        this.selectedElements.push(element);
      }

      this.elementSelected.emit(element);
    } else {
      if (index >= 0) {
        this.selectedElements.splice(index, 1);
      }

      this.elementDeselected.emit(element);
    }
  }
}
