import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumButtonModule, CelumIconModule, IconConfiguration } from '@celum/common-components';
import { CollapseButtonComponent } from '@celum/work/app/shared/components/collapse-button/collapse-button.component';
import { CustomFieldCardControlValue } from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/custom-field-card/custom-field-card.component';
import { CustomFieldCardChipsComponent } from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/custom-field-card/custom-field-chips-input/custom-field-card-chips/custom-field-card-chips.component';
import { CustomFieldChipsInputComponent } from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/custom-field-card/custom-field-chips-input/custom-field-chips-input.component';

@Component({
  selector: 'custom-field-card-read-only-view',
  standalone: true,
  imports: [
    CommonModule,
    CustomFieldChipsInputComponent,
    TranslateModule,
    CustomFieldCardChipsComponent,
    ReactiveFormsModule,
    CelumIconModule,
    MatChipsModule,
    CelumButtonModule,
    MatButtonModule,
    MatTooltipModule,
    CollapseButtonComponent
  ],
  templateUrl: './custom-field-card-read-only-view.component.html',
  styleUrls: ['./custom-field-card-read-only-view.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldCardReadOnlyViewComponent {
  @Input() public customFieldValue: CustomFieldCardControlValue;
  @Input() public isRemovable = false;
  @Input() public isExpanded = true;

  @Output() public readonly removeClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly toggleExpandClicked: EventEmitter<void> = new EventEmitter<void>();

  public readonly cancelIcon: IconConfiguration = IconConfiguration.medium('cancel-m');
}
