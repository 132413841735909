<mat-sidenav-container>
  <mat-sidenav #drawer mode="side" [disableClose]="true" opened>
    <div (click)="selectStepByIndex(i)"
         *ngFor="let stepConfiguration of stepsConfiguration; let i = index; trackBy: trackByFn"
         [attr.data-cy]="'step-' + i"
         [class.stepper_step--disabled]="isDisabled(i)"
         [class.stepper_step--selected]="selectedIndex === i"
         class="stepper_step">
      <span [class.stepper_step_icon--selected]="selectedIndex === i" class="stepper_step_icon">
        <celum-icon [configuration]="stepConfiguration.icon"></celum-icon>
      </span>
      <span [innerHTML]="sanitizeHtml(stepConfiguration.label | translate)"></span>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <toggle-drawer-button
        (openedChange)="drawer.toggle()"
        [opened]="drawer.opened"
        [tooltipConfig]="collapseButtonTooltipConfig"
        class="stepper_toggle-button">
    </toggle-drawer-button>

    <div [ngTemplateOutlet]="selected?.content"></div>
  </mat-sidenav-content>

</mat-sidenav-container>
