import { Injectable } from '@angular/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { addDays, format, startOfWeek } from 'date-fns';

@Injectable()
export class CustomDateFnsAdapter extends DateFnsAdapter {
  // for locales to match how it was before with moment.js and UI/UX requirements in DatePicker
  private dayOfWeekMap = {
    'en-US': ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    fr: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
  };

  public getFirstDayOfWeek(): number {
    return this.locale.code === 'de' || this.locale.code === 'fr' ? 1 : 0;
  }

  public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const localeCode = this.locale.code;

    if (style !== 'narrow') {
      return super.getDayOfWeekNames(style);
    }

    if (this.dayOfWeekMap[localeCode]) {
      return this.dayOfWeekMap[localeCode];
    } else {
      // Default fallback if locale not in map
      const weekStart = startOfWeek(new Date(), { locale: this.locale as Locale });
      return Array.from({ length: 7 }).map((_, i) =>
        format(addDays(weekStart, i), 'EE', { locale: this.locale as Locale }).slice(0, 2)
      );
    }
  }
}
