import { createSelector } from '@ngrx/store';
import { flatten, uniqBy } from 'lodash';

import { Permission, RoleName } from '@celum/work/app/core/api/permission/permission.service';
import { Member, MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace/teamspace.model';

import { teamspaceAdapter } from './teamspace.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';
import { selectAllMembers } from '../member/member.selectors';

const { selectEntities, selectAll, selectIds } = teamspaceAdapter.getSelectors();

export const selectTeamspacesState = createSelector(selectEntitiesState, (state: EntitiesState) => state.teamspaces);

export const selectAllTeamspaces = createSelector(selectTeamspacesState, selectAll);
export const selectAllTeamspaceIds = createSelector(selectTeamspacesState, selectIds);
export const selectTeamspaceEntities = createSelector(selectTeamspacesState, selectEntities);
export const selectTeamspaceById = (id: number) => createSelector(selectTeamspaceEntities, dict => dict[id]);

export const selectTeamspacesByUserId = (userId: number) =>
  createSelector(selectAllMembers, selectTeamspaceEntities, (members, teamspaces) =>
    members.filter(member => member.personId === userId).map(member => teamspaces[member.teamspaceId])
  );

export const selectPermissionsForTeamspace = (id: number) =>
  createSelector(selectTeamspaceEntities, dict => dict[id]?.permissions);

export const selectHasRoleInAnyTeamspace = (role: RoleName) =>
  createSelector(selectAllTeamspaces, teamspaces =>
    teamspaces?.some(
      teamspace => teamspace.permissions && teamspace.permissions.roles.some(({ name }) => role === name)
    )
  );

export const selectLicensesLeftInAnyTeamspaceWithPermission = createSelector(selectAllTeamspaces, teamspaces =>
  teamspaces?.some(
    teamspace =>
      teamspace.workroomsUsed < teamspace.workroomsLimit &&
      teamspace?.permissions?.permissions?.includes(Permission.WORKROOM_CREATE)
  )
);

export const selectAllRepositories = createSelector(selectAllTeamspaces, teamspaces =>
  uniqBy(flatten(teamspaces.map(({ repositories }) => repositories)), 'id')
);

export const selectRepositoryById = (repositoryId: string) =>
  createSelector(selectAllRepositories, repositories =>
    repositories.find(repository => repository.id === repositoryId)
  );

export function getActiveTeamspacesWithActiveMemberships(teamspaces: Teamspace[], members: Member[]): Teamspace[] {
  if (!teamspaces || teamspaces.length < 1 || !members || members.length < 1) {
    return [];
  }

  const activeTeamspaces = teamspaces.filter(teamspace => teamspace.active);
  return activeTeamspaces.filter(
    teamspace => MembershipStatus.ACTIVE === members.find(member => member.teamspaceId === teamspace.id).status
  );
}
