<workroom-avatar
    *ngFor="let avatar of peopleAvatars$ | async; trackBy: trackByFn"
    class="interactive-avatars_person"
    [configuration]="avatar"
    (avatarClicked)="onAvatarClicked(avatar)">
</workroom-avatar>

<ng-container *ngIf="overflow > 0">
  <div class="interactive-avatars_person" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">
    <!--Counter of overflow avatars icon-->
    <celum-avatar
      #overlayTrigger
        [class.celum-avatar--interactive]="overflowAvatar?.interactive"
        [configuration]="overflowAvatar"
        (click)="openPeopleListOverlay(overlayTrigger, overlayTemplate, $event)">
    </celum-avatar>

    <ng-template #overlayTemplate >
      <workroom-avatar-list [people]="getSelectedPeople()"
                            [avatarDecorator]="avatarDecorator"
                            [deletable]="hasAddPermissions"
                            (personDeleted)="avatarRemoved.emit($event)">
      </workroom-avatar-list>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="overflow === 0 || selectedPeople?.length !== overflow">
  <div class="interactive-avatars_add-person"
       matRipple
       [matRippleDisabled]="addButtonRippleDisabled"
       cdkOverlayOrigin
       #originOverlay="cdkOverlayOrigin"
       (click)="openSearchAndSelectOverlay()"
       [attr.data-has-add-permission]="hasAddPermissions">

    <!--Add person icon-->
    <celum-icon
        *ngIf="hasAddPermissions"
        class="interactive-avatars_icon"
        [configuration]="startingIcon"
        [matTooltip]="addContributorTooltip | translate"
        [attr.data-cy]="'interactive-avatars-selection-open'"
        data-operation-id="select-person">
    </celum-icon>

    <celum-icon
        *ngIf="!hasAddPermissions && selectedPeople?.length === 0 && showUnableToAddListOwner"
        class="interactive-avatars_icon"
        [clickable]="false"
        [configuration]="noAddPersonPermission"
        [matTooltip]="noPermissionTooltip | translate">
    </celum-icon>

    <ng-template cdkConnectedOverlay
                 [cdkConnectedOverlayOffsetY]="1"
                 cdkConnectedOverlayPanelClass="mat-elevation-z3"
                 [cdkConnectedOverlayBackdropClass]="'interactive-avatars_backdrop'"
                 [cdkConnectedOverlayHasBackdrop]="true"
                 [cdkConnectedOverlayOrigin]="originOverlay"
                 [cdkConnectedOverlayOpen]="isSearchAndSelectVisible"
                 [cdkConnectedOverlayPositions]="[{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }]"
                 (backdropClick)="closeSearchAndSelectOverlay()">
      <search-and-select-contributors
          [ngClass]="searchAndSelectClass"
          [selectedContributors]="selectedPeople"
          [contributors]="allPeople"
          [isSearchEnabled]="true"
          [avatarDecorator]="avatarDecorator"
          (elementDeselected)="personDeselected.emit($event)"
          (elementSelected)="personSelected.emit($event)"
          (searchValueChanged)="onSearchValueChanged($event)">
        <ng-content select="[top-info-note]" ngProjectAs="[top-info-note]"></ng-content>
        <ng-content select="[extra-option]" ngProjectAs="[extra-option]"></ng-content>
        <ng-content select="[fallback-action]" ngProjectAs="[fallback-action]"></ng-content>
      </search-and-select-contributors>
    </ng-template>
  </div>
</ng-container>



