import { createSelector } from '@ngrx/store';

import { renditionAdapter } from '@celum/work/app/core/model/entities/rendition/rendition.reducer';

import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = renditionAdapter.getSelectors();

export const selectRenditions = createSelector(selectEntitiesState, (state: EntitiesState) => state.renditions);

export const selectAllRenditions = createSelector(selectRenditions, selectAll);
export const selectRenditionEntities = createSelector(selectRenditions, selectEntities);

export const selectRenditionById = (id: number) =>
  createSelector(selectRenditionEntities, fileVersionDictionary => fileVersionDictionary[id]);

export const selectRenditionsByIds = (ids: number[]) =>
  createSelector(selectRenditionEntities, fileVersionDictionary => ids.map(id => fileVersionDictionary[id]));
