import { createSelector } from '@ngrx/store';

import { commentAdapter } from './comment.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = commentAdapter.getSelectors();

export const selectComments = createSelector(selectEntitiesState, (state: EntitiesState) => state.comments);

export const selectAllComments = createSelector(selectComments, selectAll);
export const selectCommentEntities = createSelector(selectComments, selectEntities);

export const selectCommentById = (id: number) =>
  createSelector(selectCommentEntities, commentDictionary => commentDictionary[id]);

export const selectSelectedCommentId = createSelector(selectComments, state => state.selectedId);

export const selectSelectedComment = createSelector(
  selectCommentEntities,
  selectSelectedCommentId,
  (commentDictionary, selectedId) => commentDictionary[selectedId]
);

export const selectMarkers = createSelector(selectComments, state => state.markers);
