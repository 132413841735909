<div class='forms-overview'>

  <div class='forms-overview_container'>
    <div class='forms-overview_button-wrapper'>
      <ng-container *ngIf="hasWorkroomsFinishPermission">
        <span class="forms-label">{{ 'TEAMSPACE_MANAGEMENT.FORMS.NAV_BUTTON.LABEL' | translate }}</span>

        <ng-container *ngIf="(hasCustomFields$ | async) === false">
          <celum-icon
              class="forms-overview_disabled-icon"
              [matTooltip]="'TEAMSPACE_MANAGEMENT.FORMS.NAV_BUTTON.DISABLED_TOOLTIP' | translate"
              [configuration]="formCreateDisabledIcon"
              [clickable]="false"
              data-component-id="form-create-button-disabled"
              data-cy="form-create-button-disabled">
          </celum-icon>
        </ng-container>
        
        <ng-container *ngIf="hasCustomFields$ | async">
          <celum-icon
              class="forms-overview_icon"
              [matTooltip]="'TEAMSPACE_MANAGEMENT.FORMS.NAV_BUTTON.TOOLTIP' | translate"
              [configuration]="formCreateIcon"
              [clickable]="true"
              (click)="openDialogToAddForm()"
              data-component-id="form-create-button"
              data-cy="form-create-button">
          </celum-icon>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<custom-forms-virtual-scroll-table
    [hasWorkroomsFinishPermission]="hasWorkroomsFinishPermission">
</custom-forms-virtual-scroll-table>