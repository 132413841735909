import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { DataUtil, EntityType } from '@celum/core';
import { RolesWithPermissions } from '@celum/work/app/core/api/permission';

import { Entity } from '../../entity';
import { Member, MemberType } from '../member/member.model';
import { Person } from '../person/person.model';

export interface Teamspace extends Entity<number, TeamspaceType>, StorageDetails {
  name: string;
  createdById: number;
  externalId: string;
  active: boolean;
  workroomsUsed: number;
  workroomsLimit: number;
  workroomsFinished: number;
  repositories: SaccRepository[];
  active3DLicense: boolean;
  monthly3DLimit?: number;
  monthly3DUsedAndReserved?: number;
  /**
   * Returns all {@link Person Persons} which are members of this teamspace.
   * @param store the store
   */
  persons: (store: Store<any>) => Observable<Person[]>;
  /**
   * Returns all {@link Member} entities this teamspace is reference by.
   * @param store the store
   */
  members: (store: Store<any>) => Observable<Member[]>;
  permissions?: RolesWithPermissions;
}

export interface SaccRepository {
  id: string;
  url: string;
  buildNumber: string;
}

export class TeamspaceType implements EntityType {
  public static readonly TYPE_KEY: string = 'Teamspace';

  private static _instance: TeamspaceType;

  public id = TeamspaceType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): TeamspaceType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(MemberType.TYPE_KEY)) {
        relationDefinitions.members = [MemberType.instance().getSchema({})];
      }
    }

    return new schema.Entity(TeamspaceType.TYPE_KEY, relationDefinitions);
  }
}

export interface TeamspaceState extends EntityState<Teamspace> {
  loaded: boolean;
}

export interface StorageDetails {
  storageLimitStatus: string;
  usedStorageInGiB: number;
  availableStorageInGiB: number;
}

export function getRepository(teamspace: Teamspace, repositoryId: string): SaccRepository {
  return teamspace.repositories.find(({ id }) => id === repositoryId);
}
