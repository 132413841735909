import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { selectLicensesLeftInAnyTeamspaceWithPermission } from '../../../core/model/entities/teamspace';
import { PredefinedCategoryValues } from '../../../core/model/entities/template-category/template-category.model';
import {
  selectCelumTemplateCategory,
  selectTeamspaceByCategoryId
} from '../../../core/model/entities/template-category/template-category.selectors';

@Pipe({
  name: 'licenseLimitReached'
})
export class LicenseLimitReachedPipe implements PipeTransform {
  constructor(private store: Store<any>) {}

  public transform(templateCategoryId: number | string): Observable<boolean> {
    // In person tab, enable creating WR from template only if license limit was not reached at least in one teamspace
    if (
      PredefinedCategoryValues.PERSONAL === templateCategoryId ||
      PredefinedCategoryValues.ALL === templateCategoryId
    ) {
      return this.store.select(selectLicensesLeftInAnyTeamspaceWithPermission).pipe(map(val => !val));
    }

    return this.store.select(selectCelumTemplateCategory).pipe(
      switchMap(celumTemplateCategory => {
        // In celum tab, enable creating WR from template only if license limit was not reached at least in one teamspace
        if (celumTemplateCategory && celumTemplateCategory.id === templateCategoryId) {
          return this.store.select(selectLicensesLeftInAnyTeamspaceWithPermission).pipe(map(val => !val));
        } else {
          // For others tab, check permission for specific teamspace
          return this.store.select(selectTeamspaceByCategoryId(templateCategoryId as any)).pipe(
            filter(teamspace => !!teamspace),
            map(({ workroomsUsed, workroomsLimit }) => workroomsUsed >= workroomsLimit),
            catchError(() => of(false))
          );
        }
      })
    );
  }
}
