import { FileFormat } from '@celum/work/app/core/model/entities/file-version/file-version.model';
import { FileFilter } from '@celum/work/app/files/store/content-item-list/content-item-list.model';

import {
  ContentFilterDTO,
  FileFilterSectionType,
  FilterSectionItemType,
  FilterSectionType,
  MyTaskFilterSectionType,
  Section,
  SectionItem,
  TaskFilterSectionType
} from './filter.model';
import { TaskFilterDTO, TaskInboxFilterDTO } from '../../../../core/api/task/task.service';
import { ContentItemTypes } from '../../../../core/model/entities/content-item/content-item.model';
import { TaskInboxFilter } from '../../../../pages/dashboard/components/my-tasks/store/my-tasks.model';
import { MediaType } from '../../../../pages/workroom/pages/files/components/file-filter/file-filter.component';
import { TaskFilter } from '../../../../pages/workroom/pages/tasks/store/tasks-overview.model';

export class FilterUtils {
  public static isSectionItemActive(item: SectionItem): boolean {
    if (item.type === FilterSectionItemType.CHECKBOX) {
      return item.checked;
    } else if (item.type === FilterSectionItemType.SEARCH_FIELD) {
      return item.value;
    } else {
      return false;
    }
  }

  public static getFileFormat(mediaType: MediaType): FileFormat {
    switch (mediaType) {
      case 'Audio':
        return FileFormat.AUDIO;
      case 'Document':
        return FileFormat.DOCUMENT;
      case 'Image':
        return FileFormat.IMAGE;
      case 'Video':
        return FileFormat.VIDEO;
      default:
        return FileFormat.UNKNOWN;
    }
  }

  public static toFilter<T>(sections: Section<any>[]): T {
    const fileFilter: any = {
      sections: []
    };

    fileFilter.sections = sections
      .filter(section => section.items.some(FilterUtils.isSectionItemActive))
      .map(({ type, title, items, searchValue }) => ({
        type,
        title,
        searchValue,
        items: items.filter(FilterUtils.isSectionItemActive).map(item => ({
          ...item,
          templateData: null
        }))
      }));

    return fileFilter;
  }

  public static toFileFilterDTO(fileFilter: FileFilter, contentItemTypes: ContentItemTypes[]): ContentFilterDTO {
    const fileFilterDTO: ContentFilterDTO = {
      contentItemTypes: contentItemTypes
    };

    fileFilter?.sections.forEach(section => {
      switch (section.type) {
        case FileFilterSectionType.UPLOADED_BY:
          fileFilterDTO.createdByIds = section.items.map(({ value: { oid } }) => oid);
          break;
        case FilterSectionType.SEARCH:
          fileFilterDTO.searchTerm = section.items[0].value;
          break;
        case FileFilterSectionType.MEDIA_TYPE:
          fileFilterDTO.fileFormats = section.items.map(({ value }) => value);
          break;
        case FileFilterSectionType.CONTENT_TYPE:
          fileFilterDTO.contentItemTypes = section.items.map(({ value }) => value);
          break;
      }
    });

    return fileFilterDTO;
  }

  public static toTaskFilterDTO(taskFilter: TaskFilter): TaskFilterDTO {
    const taskFilterDTO: TaskFilterDTO = {};

    taskFilter?.sections.forEach(section => {
      switch (section.type) {
        case FilterSectionType.SEARCH:
          taskFilterDTO.name = section.items[0].value;
          break;
        case TaskFilterSectionType.CREATED_BY:
          taskFilterDTO.createdByIds = section.items.map(({ value }) => value.id);
          break;
        case TaskFilterSectionType.ASSIGNED_TO:
          taskFilterDTO.assignedToIds = section.items.map(({ value }) => value.id);
          break;
        case TaskFilterSectionType.PRIORITY:
          taskFilterDTO.priorities = section.items.map(({ value }) => value);
          break;
      }
    });

    return taskFilterDTO;
  }

  public static toTaskInboxFilterDto(taskInboxFilter: TaskInboxFilter): TaskInboxFilterDTO {
    const taskInboxFilterDTO: TaskInboxFilterDTO = {};

    taskInboxFilter?.sections.forEach(section => {
      switch (section.type) {
        case FilterSectionType.SEARCH:
          taskInboxFilterDTO.name = section.items[0].value;
          break;
        case MyTaskFilterSectionType.DUE_DATE:
          taskInboxFilterDTO.dueDateBuckets = section.items.map(({ dueDateBucket }) => dueDateBucket);
          break;
        case MyTaskFilterSectionType.WORKROOMS:
          taskInboxFilterDTO.workroomIds = section.items.map(({ value }) => value.id);
          break;
        case MyTaskFilterSectionType.PRIORITY:
          taskInboxFilterDTO.priorities = section.items.map(({ value }) => value);
          break;
      }
    });

    return taskInboxFilterDTO;
  }

  public static getSectionItems<T>(sections: Section<T>[], type: T | FilterSectionType): SectionItem[] {
    const section = sections.find(s => s.type === type);
    return section?.items || [];
  }
}
