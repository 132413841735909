import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, switchMap, tap } from 'rxjs';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration, SortDirection } from '@celum/common-components';
import { PaginationResult } from '@celum/core';
import { CelumDialogOpener } from '@celum/internal-components';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { CustomField, CustomFieldTypes } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import {
  initialPaginatedListState,
  initialSimpleListState,
  PaginatedListState,
  SimpleListState
} from '@celum/work/app/core/model/list.model';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';
import { BaseCustomFieldDialogResponse } from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/base-custom-field-dialog/base-custom-field-dialog.component';
import {
  EditCustomFieldDialogComponent,
  EditCustomFieldDialogConfiguration
} from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/edit-custom-field-dialog/edit-custom-field-dialog.component';

import {
  CreateCustomFieldDialogComponent,
  CreateCustomFieldDialogConfiguration
} from './components/create-custom-field-dialog/create-custom-field-dialog.component';
import { CustomFieldsTabEvents } from './events/fields-overview-events';
import { CustomFieldErrorKeyEnum } from './model/custom-field-error-response';
import { CustomFieldsService } from './services/custom-fields.service';
import {
  CreateCustomFormDialogComponent,
  CreateCustomFormDialogConfiguration,
  CreateCustomFormDialogResponse
} from '../forms-overview-tab/components/create-custom-form-dialog/create-custom-form-dialog.component';
import { CustomFormErrorKeyEnum } from '../forms-overview-tab/model/custom-form-error-response';

export interface CustomFieldsState extends SimpleListState<number>, PaginatedListState {
  displayedCustomFieldsCount: number;
  selectedCustomFields: Record<number, CustomField>;
  selectionLimitExceeded: boolean;
  selectionCleared: boolean;
}
export const initialState: CustomFieldsState = {
  ...initialSimpleListState,
  ...initialPaginatedListState,
  batchSize: 15,
  displayedCustomFieldsCount: 0,
  selectedCustomFields: [],
  selectionLimitExceeded: false,
  selectionCleared: true
};
export const CUSTOM_FIELDS_SELECTION_LIMIT = 101;

@Injectable()
export class CustomFieldsStore extends ComponentStore<CustomFieldsState> {
  public readonly customFieldIds$: Observable<number[]> = this.select(state => state.entityIds);
  public readonly selectedCustomFields$: Observable<Record<number, CustomField>> = this.select(
    state => state.selectedCustomFields
  );
  public readonly selectionLimitExceeded$: Observable<boolean> = this.select(state => state.selectionLimitExceeded);
  public readonly selectionCleared$: Observable<boolean> = this.select(state => state.selectionCleared);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);
  public readonly hasCustomFields$: Observable<boolean> = this.select(state => state.entityIds.length > 0);
  public readonly paginationResult$: Observable<PaginationResult> = this.select(state => state.paginationResult);
  public readonly lastOffset$: Observable<number> = this.select(state => state.lastOffset);
  public readonly batchSize$: Observable<number> = this.select(state => state.batchSize);

  public readonly setLoading = this.updater((state, loading: boolean) => ({ ...state, loading }));

  public readonly resetState = this.updater(state => ({ ...state, entityIds: [], customFields: [], lastOffset: 0 }));

  public readonly setLoadedCustomFieldsData = this.updater(
    (
      state,
      { customFields, paginationResult }: { customFields: CustomField[]; paginationResult: PaginationResult }
    ) => {
      const customFieldsIds = customFields.map(field => field.id);
      const entityIds = [...state.entityIds, ...customFieldsIds];

      return {
        ...state,
        entityIds,
        lastOffset: entityIds.length,
        paginationResult: {
          totalElementCount: paginationResult.totalElementCount,
          hasTop: false,
          hasBottom: paginationResult.hasBottom
        },
        displayedCustomFieldsCount: entityIds.length
      };
    }
  );

  public readonly setSelectedCustomFields = this.updater(
    (state, selectedCustomFields: Record<number, CustomField>) => ({
      ...state,
      selectedCustomFields,
      selectionLimitExceeded: Object.keys(selectedCustomFields).length >= CUSTOM_FIELDS_SELECTION_LIMIT,
      selectionCleared: Object.keys(selectedCustomFields).length === 0
    })
  );

  public readonly clearSelection = this.updater(state => ({
    ...state,
    selectedCustomFields: {},
    selectionCleared: true,
    selectionLimitExceeded: false
  }));

  public readonly changeBatchSize = this.updater((state, batchSize: number) => {
    return {
      ...state,
      batchSize
    };
  });

  public readonly addCustomField = this.updater((state, newCustomField: CustomField) => {
    const entityIds = [newCustomField.id, ...state.entityIds];
    const totalElementCount = state.paginationResult.totalElementCount + 1;

    return {
      ...state,
      entityIds,
      paginationResult: {
        ...state.paginationResult,
        totalElementCount
      },
      lastOffset: state.lastOffset + 1,
      displayedCustomFieldsCount: state.displayedCustomFieldsCount + 1
    };
  });

  public readonly loadCustomFields$ = this.effect(
    ($config: Observable<{ offset: number; paging: Paging; sorting: Sorting }>) =>
      $config.pipe(
        tap(({ offset }) => {
          this.setLoading(true);

          if (offset === 0) {
            this.resetState();
          }
        }),
        switchMap(config =>
          this.customFieldsService.loadCustomFields(config.paging, config.sorting).pipe(
            tapResponse(
              response => {
                this.setLoading(false);
                this.setLoadedCustomFieldsData(response);
              },
              () => this.setLoading(false)
            )
          )
        )
      )
  );

  public readonly loadNextBatch$ = this.effect($empty =>
    $empty.pipe(
      concatLatestFrom(() => [this.lastOffset$, this.loading$, this.paginationResult$, this.batchSize$]),
      tap(([_, lastOffset, loading, paginationResult, batchSize]) => {
        if (!loading && paginationResult?.hasBottom) {
          this.loadCustomFields$({
            offset: lastOffset,
            paging: Paging.of(lastOffset, batchSize),
            sorting: Sorting.of('createdOn', SortDirection.DESC)
          });
        }
      })
    )
  );

  public readonly displayFieldLimitationSnackbar$ = this.effect($empty =>
    $empty.pipe(
      tap(() => {
        const config = SnackbarConfiguration.info('TEAMSPACE_MANAGEMENT.FIELDS.FIELD_CREATION_LIMIT_SNACKBAR')
          .withAutoVanish(true)
          .withAutoVanishTime(5000);

        this.store.dispatch(new ShowSnackbar('info', SimpleSnackbar, config));
      })
    )
  );

  public readonly displayFormLimitationSnackbar$ = this.effect($empty =>
    $empty.pipe(
      tap(() => {
        const config = SnackbarConfiguration.info('TEAMSPACE_MANAGEMENT.FORMS.FORM_CREATION_LIMIT_SNACKBAR')
          .withAutoVanish(true)
          .withAutoVanishTime(5000);

        this.store.dispatch(new ShowSnackbar('info', SimpleSnackbar, config));
      })
    )
  );

  public readonly openCreateCustomFormDialog$ = this.effect(
    (customFieldsRecord$: Observable<Record<number, CustomField>>) =>
      customFieldsRecord$.pipe(
        tap(async (customFieldsRecord: Record<number, CustomField>) => {
          const response: CreateCustomFormDialogResponse = await this.dialogOpener.showDialog(
            CreateCustomFormDialogComponent.DIALOG_ID,
            CreateCustomFormDialogComponent,
            new CreateCustomFormDialogConfiguration({ customFields: Object.values(customFieldsRecord) })
          );

          if (response?.customForm) {
            this.eventBus.publishEvent({ type: CustomFieldsTabEvents.FORM_CREATED });
          } else {
            this.clearSelection();
          }

          if (response?.errorKey === CustomFormErrorKeyEnum.FORM_LIMIT_EXCEEDED) {
            this.displayFormLimitationSnackbar$();
          }
        })
      )
  );

  public readonly openCreateCustomFieldDialog$ = this.effect((type$: Observable<CustomFieldTypes>) =>
    type$.pipe(
      tap(async (type: CustomFieldTypes) => {
        const response: BaseCustomFieldDialogResponse = await this.dialogOpener.showDialog(
          CreateCustomFieldDialogComponent.DIALOG_ID,
          CreateCustomFieldDialogComponent,
          new CreateCustomFieldDialogConfiguration(type)
        );

        if (response?.customField) {
          this.addCustomField(response.customField);
        }

        if (response?.errorKey === CustomFieldErrorKeyEnum.CUSTOM_FIELD_LIMIT_EXCEEDED) {
          this.displayFieldLimitationSnackbar$();
        }
      })
    )
  );

  public readonly openEditCustomFieldDialog$ = this.effect((field$: Observable<CustomField>) =>
    field$.pipe(
      tap(async (field: CustomField) => {
        await this.dialogOpener.showDialog(
          EditCustomFieldDialogComponent.DIALOG_ID,
          EditCustomFieldDialogComponent,
          new EditCustomFieldDialogConfiguration(field)
        );
      })
    )
  );

  constructor(
    private customFieldsService: CustomFieldsService,
    private dialogOpener: CelumDialogOpener,
    private store: Store<any>,
    private eventBus: ApplicationEventBus
  ) {
    super(initialState);
  }
}
