import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { DataContext, Operation } from '@celum/core';
import { ContentHubBuildNumberStrategyResolverService } from '@celum/work/app/content-hub/services/content-hub-build-number-strategy-resolver.service';
import { ContentHubImportActions } from '@celum/work/app/content-hub/store/import.actions';

import { selectCanImport } from '../../../../../../../content-hub/store/content-hub.selectors';
import {
  selectCurrentFolder,
  selectCurrentWorkroomRootFolder,
  selectIsTrashBinSelected
} from '../../../../../../../core/model/entities/folder/folder.selector';

@Injectable()
export class ImportFileFromContentHubOperation implements Operation {
  public static readonly DIALOG_KEY: string = 'import-files';

  constructor(
    private store: Store<any>,
    private chStrategyResolver: ContentHubBuildNumberStrategyResolverService
  ) {}

  public isVisible(data: DataContext): Observable<boolean> {
    return combineLatest([
      this.store.select(selectCanImport),
      this.store.select(selectIsTrashBinSelected),
      this.chStrategyResolver.resolve()
    ]).pipe(
      map(
        ([canImport, isTrashBinSelected, strategy]) =>
          !isTrashBinSelected && canImport && data.getSelection().length === 0 && strategy?.supportsRelayCalls()
      )
    );
  }

  public hasPriority(): boolean {
    return true;
  }

  public execute(): void {
    combineLatest([this.store.select(selectCurrentWorkroomRootFolder), this.store.select(selectCurrentFolder)])
      .pipe(take(1))
      .subscribe(([rootFolder, currentFolder]) => {
        this.store.dispatch(
          ContentHubImportActions.RequestImportAsNewFile({ parentId: currentFolder?.id ?? rootFolder.id })
        );
      });
  }

  public getIcon(): string {
    return 'import';
  }

  public getKey(): string {
    return ImportFileFromContentHubOperation.DIALOG_KEY;
  }

  public getMessageKey(): string {
    return 'CONTENT_HUB.OPERATIONS.IMPORT.FILE';
  }
}
