import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Permission } from '@celum/work/app/core/api/permission';
import { Roles } from '@celum/work/app/core/model';
import { selectContributorEntities } from '@celum/work/app/core/model/entities/contributor';
import { MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { selectAllMembers } from '@celum/work/app/core/model/entities/member/member.selectors';
import { filterBySearchString, Person, selectPersonEntities } from '@celum/work/app/core/model/entities/person';
import { getRepository, selectTeamspaceEntities } from '@celum/work/app/core/model/entities/teamspace';
import { FeatureType, selectWorkroomById, selectWorkroomEntities } from '@celum/work/app/core/model/entities/workroom';
import { selectLoggedInPerson } from '@celum/work/app/core/ui-state/ui-state.selectors';

import { WorkroomWrapperState } from './workroom-wrapper.model';

export const FEATURE_NAME = 'workroomWrapper';

export const selectWorkroomWrapperStateState = createFeatureSelector<WorkroomWrapperState>(FEATURE_NAME);

export const selectWorkroomWrapperState = createSelector(
  selectWorkroomWrapperStateState,
  (state: WorkroomWrapperState) => state
);

export const selectCurrentWorkroomId = createSelector(
  selectWorkroomWrapperStateState,
  state => state?.currentWorkroomId
);

export const selectCurrentWorkroom = createSelector(
  selectCurrentWorkroomId,
  selectWorkroomEntities,
  (workroomId, workroomEntities) => workroomEntities[workroomId]
);

export const selectCurrentWorkroomContributingPersons = createSelector(
  selectCurrentWorkroom,
  selectContributorEntities,
  selectPersonEntities,
  (workroom, contributors, persons) => {
    if (!workroom?.contributorIds) {
      return [];
    }
    return workroom.contributorIds
      .map(id => contributors[id])
      .filter(contributor => !!contributor)
      .map(contributor => persons[contributor.personId])
      .filter(person => !!person);
  }
);

export const selectTeamspaceOfCurrentWorkroom = createSelector(
  selectCurrentWorkroom,
  selectTeamspaceEntities,
  (workroom, teamspaces) => teamspaces[workroom?.teamspaceId]
);

export const selectCurrentWorkroomTeamspaceId = createSelector(
  selectCurrentWorkroom,
  workroom => workroom?.teamspaceId
);

export const selectCurrentWorkroomRepository = createSelector(
  selectTeamspaceOfCurrentWorkroom,
  selectCurrentWorkroom,
  (teamspace, workroom) => (teamspace ? getRepository(teamspace, workroom.contentHubRepositoryId) : null)
);

export const selectCurrentWorkroomContributingPersonsWithMembershipStatus = (statuses: MembershipStatus[]) =>
  createSelector(
    selectCurrentWorkroom,
    selectCurrentWorkroomContributingPersons,
    selectPersonEntities,
    selectAllMembers,
    (workroom, contributingPersons, persons, members) => {
      if (!workroom?.contributorIds) {
        return [];
      }
      return contributingPersons
        .map(person =>
          members.find(
            member =>
              member.teamspaceId === workroom.teamspaceId &&
              member.personId === person.id &&
              statuses.includes(member.status)
          )
        )
        .map(member => persons[member?.personId])
        .filter(person => !!person);
    }
  );

export const selectCurrentWorkroomContributorsWithSearch = (searchQuery: string) =>
  createSelector(
    selectCurrentWorkroomContributingPersonsWithMembershipStatus([MembershipStatus.ACTIVE, MembershipStatus.INIT]),
    persons => filterBySearchString(searchQuery, persons)
  );

export const selectHasCurrentWorkroomFeature = (featureType: FeatureType) =>
  createSelector(
    selectCurrentWorkroom,
    workroom => !!workroom?.configuration?.features.find(feature => feature.name === featureType)
  );

export const selectHasPermissionForCurrentWorkroom = (requiredPermission: Permission) =>
  createSelector(selectCurrentWorkroom, workroom => {
    return workroom?.permissions?.permissions.includes(requiredPermission);
  });

export const selectLoggedInPersonContributorForWorkroom = (workroomId: number) =>
  createSelector(
    selectLoggedInPerson,
    selectWorkroomById(workroomId),
    selectContributorEntities,
    (person, workroom, contributors) => {
      if (!person || !workroom) {
        return null;
      }
      return contributors[`${workroom.id}_${person.id}`];
    }
  );

export const selectLoggedInPersonCurrentWorkroomContributor = createSelector(
  selectLoggedInPerson,
  selectCurrentWorkroom,
  selectContributorEntities,
  (person, workroom, contributors) => {
    if (!person || !workroom) {
      return null;
    }
    return contributors[`${workroom.id}_${person.id}`];
  }
);

export const selectLoggedInPersonHasRoleForCurrentWorkroom = (role: Roles) =>
  createSelector(selectLoggedInPersonCurrentWorkroomContributor, contributor => contributor?.roles.includes(role));

export const selectHasRoleForCurrentWorkroom = (person: Person, role: Roles) =>
  createSelector(selectCurrentWorkroom, selectContributorEntities, (workroom, contributors) => {
    if (!workroom) {
      return false;
    }

    const contributor = contributors[`${workroom.id}_${person.id}`];

    return contributor?.roles.includes(role);
  });

export const selectAllPersonsHaveSpecificRole = (personIds: number[], role: Roles) =>
  createSelector(selectCurrentWorkroom, selectContributorEntities, (workroom, contributors) => {
    if (!contributors || !workroom) {
      return false;
    }
    return personIds.every(personId => {
      const contributor = contributors[`${workroom.id}_${personId}`];
      return contributor?.roles.includes(role);
    });
  });

export const selectCurrentLibraryId = createSelector(selectCurrentWorkroom, workroom => workroom?.libraryId);
