import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';

import { StepperComponent } from './stepper.component';
import { SharedModule } from '../../index';

@NgModule({
  declarations: [StepperComponent],
  imports: [CommonModule, CdkStepperModule, MatSidenavModule, SharedModule],
  exports: [StepperComponent, CdkStepperModule]
})
export class StepperModule {}
