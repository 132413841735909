import { Inject, Injectable } from '@angular/core';

import { Folder } from '@celum/work/app/core/model/entities/folder/folder.model';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import { findMatchingRobotConfigService } from '@celum/work/app/robots/services/robots-util';

import { Automator } from '../../core/model/entities/workroom/robot.model';
import { BaseRobotConfigService } from '../components/robot-dialog-templates/base-robot/base-robot-config.service';

@Injectable()
export class RobotScopeResolverService {
  constructor(@Inject(BaseRobotConfigService) private baseRobotConfigServices: BaseRobotConfigService[]) {}

  public filterRobotsBelongingToTaskList(automators: Automator[], taskList: TaskList): Automator[] {
    return automators.filter(automator => {
      const service = findMatchingRobotConfigService(this.baseRobotConfigServices, automator);
      return service.robotBelongsToTaskList(automator, taskList);
    });
  }

  public filterRobotsBelongingToFolder(automators: Automator[], folder: Folder): Automator[] {
    return automators.filter(automator => {
      const service = findMatchingRobotConfigService(this.baseRobotConfigServices, automator);
      return service.robotBelongsToFolder(automator, folder);
    });
  }
}
