<ng-container *ngIf="(usedStoragePercentage$ | async) | number: '1.2-2'; let usedStoragePercentageRounded">
  <browsing-dialog class="select-assets-dialog"
                   [title]="title"
                   [color]="color"
                   [drawerOpened]="(collections$ | async).length > 0"
                   [showContentHeader]="(collections$ | async).length > 0"
                   [submitDisabled]="(missingContentHubUser$ | async) || (importCollectionDisabled() | async) || +usedStoragePercentageRounded >= 100 || (contentItemsLoading$ | async)"
                   [submitIcon]="importIcon"
                   [submitText]="submitButtonText"
                   [showEmptyPage]="missingContentHubUser$ | async"
                   [emptyPageConfig]="missingContentHubUserEmptyPageConfig"
                   [showSubmitSubMenu]="submitOptions.length > 0"
                   [submitSubMenuConfig]="submitOptions"
                   (onClose)="closeDialog()"
                   (onSubmit)="onSubmit($event)">
    <ng-container sideContent>
      <collection-selection [collections]="collections$ | async"
                            (collectionSelected)="changeCollection($event)">
      </collection-selection>
      <files-tree [activeFolder]="activeFolder$ | async"
                  [folders]="children$ | async"
                  [paginationInformation]="paginationInformation$ | async"
                  [sortFolders]="false"
                  (expand)="loadChildren($event)"
                  (selectionChanged)="selectedFolderChanged($event[0])"
                  (fetchMore)="loadMoreForFolder($event)">
      </files-tree>
    </ng-container>

    <div sectionHeader class="select-assets-dialog_header">
      <button  *ngIf="!rootCollectionSelected"
              mat-button celum-square-button
              [matTooltip]="(showChildren ? 'CONTENT_HUB.COMMON.RECURSIVE_PLACEHOLDER_HIDE' : 'CONTENT_HUB.COMMON.RECURSIVE_PLACEHOLDER') | translate"
              (click)="onSearchRecursiveClick()">
        <celum-icon *ngIf="!showChildren" [configuration]="hideChildrenIcon"></celum-icon>
        <celum-icon *ngIf="showChildren" [configuration]="showChildrenIcon"></celum-icon>
      </button>
      <div *ngIf="(sortFields$ | async) as sortFields">
        <sorter *ngIf="sortFields.length"
                [sortProperties]="sortFields"
                [sortField]="selectedSortField$ | async"
                [sortDirection]="(selectedSortField$ | async)?.direction"
                (sortChanged)="onSortChanged($event)">
        </sorter>
      </div>
      <search-field [placeholder]="'CONTENT_HUB.COMMON.SEARCH_PLACEHOLDER'"
                    (valueChange)="onSearchChanged($event)">
      </search-field>
    </div>

    <ng-container sectionContent>
      <content-item-list class="select-assets-dialog_content-item-list"
                         [disableContextMenu]="true"
                         [contentItems]="contentItems$ | async"
                         [usedStoragePercentage]="usedStoragePercentage$ | async"
                         [selectedContentItems]="selectedContentItems"
                         [isMultiSelection]="isMultiSelection"
                         [hasMoreBottom]="hasMoreContentItems$ | async"
                         [loading]="contentItemsLoading$ | async"
                         [emptyPageConfig]="emptyPageConfig$ | async"
                         (fetchNextBatch)="onFetchNextAssetsBatch()"
                         (onContentItemSelected)="onContentItemSelected($event)"
                         (onBatchSizeChanged)="onContentItemListBatchSizeChanged($event)">
      </content-item-list>
    </ng-container>
  </browsing-dialog>
</ng-container>
