import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
  CelumDialog,
  CelumDialogConfiguration,
  CelumDialogModule,
  CelumIconModule,
  ColorConstants
} from '@celum/common-components';
import {
  CustomField,
  CustomFieldDropdownOption,
  CustomFieldTypes
} from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { SharedModule } from '@celum/work/app/shared';
import { StringUtil } from '@celum/work/app/shared/util/string.util';
import {
  BaseCustomFieldDialogComponent,
  BaseCustomFieldDialogResponse
} from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/base-custom-field-dialog/base-custom-field-dialog.component';

import { CustomFieldsService } from '../../services/custom-fields.service';
import {
  CustomFieldCardComponent,
  CustomFieldCardControlValue
} from '../custom-field-card/custom-field-card.component';

@Component({
  selector: 'create-custom-field-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CelumDialogModule,
    CelumIconModule,
    MatButtonModule,
    CustomFieldCardComponent,
    ReactiveFormsModule,
    SharedModule
  ],
  templateUrl: './create-custom-field-dialog.component.html',
  styleUrls: ['../base-custom-field-dialog/base-custom-field-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateCustomFieldDialogComponent
  extends BaseCustomFieldDialogComponent
  implements CelumDialog<CreateCustomFieldDialogConfiguration>
{
  public static readonly DIALOG_ID = 'create-custom-field-dialog';

  public customFieldType: CustomFieldTypes;

  constructor(
    dialogRef: MatDialogRef<BaseCustomFieldDialogComponent, BaseCustomFieldDialogResponse>,
    private customFieldService: CustomFieldsService,
    cdr: ChangeDetectorRef,
    private stringUtil: StringUtil
  ) {
    super(dialogRef, cdr);
  }

  public configure({ customFieldType, color }: CreateCustomFieldDialogConfiguration): void {
    this.customFieldType = customFieldType;
    this.color = color;

    this.customFieldCard = new FormControl<CustomFieldCardControlValue>({
      name: null,
      type: this.customFieldType,
      chips: []
    });
  }

  public onSubmit(): void {
    const { name, type, chips } = this.customFieldCard.value;
    const trimmedName = this.stringUtil.collapseWhitespacesBetweenWords(name.trim());

    this.loading = true;

    const submitEndpointCall = this.createNewCustomField(trimmedName, type, chips);

    this.submitHandler(submitEndpointCall);
  }

  private createNewCustomField(
    name: string,
    type: CustomFieldTypes,
    newChips: CustomFieldDropdownOption[]
  ): Observable<CustomField> {
    return this.customFieldService.createCustomField(
      name,
      type,
      newChips?.map(chip => chip.name)
    );
  }
}

export class CreateCustomFieldDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(public customFieldType: CustomFieldTypes) {
    super('main');
  }
}
