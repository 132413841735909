import { AutomatorType, RobotActionType } from '@celum/work/app/core/model/entities/workroom/robot.model';

import { RobotsFactory } from '../services/robots-factory';

export const robotNotificationIconMap: { [key in RobotActionType]?: string } = {
  [RobotActionType.CONTENT_HUB_EXPORT_AS_NEW_ASSET]: RobotsFactory.robotConfigMap[AutomatorType.CONTENT_HUB].iconName,
  [RobotActionType.TASK_SET_DUE_DATE]: RobotsFactory.robotConfigMap[AutomatorType.TASK_DUE_DATE].iconName,
  [RobotActionType.TASK_REMOVE_DUE_DATE]: RobotsFactory.robotConfigMap[AutomatorType.TASK_DUE_DATE].iconName,
  [RobotActionType.TASK_ADD_ASSIGNEES]: RobotsFactory.robotConfigMap[AutomatorType.TASK_ASSIGNMENT].iconName,
  [RobotActionType.TASK_REMOVE_ASSIGNEES]: RobotsFactory.robotConfigMap[AutomatorType.TASK_ASSIGNMENT].iconName,
  [RobotActionType.CONTENT_HUB_EXPORT_TO_IMPORTED_ASSET]:
    RobotsFactory.robotConfigMap[AutomatorType.CONTENT_HUB].iconName,
  [RobotActionType.CONTENT_HUB_ADD_IMPORTED_ASSET_TO_COLLECTION]:
    RobotsFactory.robotConfigMap[AutomatorType.CONTENT_HUB].iconName,
  [RobotActionType.CONTENT_HUB_MOVE_IMPORTED_ASSET_TO_COLLECTION]:
    RobotsFactory.robotConfigMap[AutomatorType.CONTENT_HUB].iconName,
  [RobotActionType.CREATE_TASK_BASED_ON_FILE]: RobotsFactory.robotConfigMap[AutomatorType.TASK_CREATION].iconName
};
