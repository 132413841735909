import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, sortBy } from 'lodash';
import { of } from 'rxjs';

import { AvatarDecoratorFn } from '@celum/work/app/shared/util/avatar-util';

import { Person } from '../../../../core/model/entities/person';
import { SelectElement } from '../search-and-select.component';

@Component({
  selector: 'search-and-select-contributors',
  templateUrl: './search-and-select-contributors.component.html',
  styleUrls: ['./search-and-select-contributors.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SearchAndSelectContributors implements OnInit {
  @HostBinding('class') public hostCls = 'search-and-select-contributors';

  @Input() public contributors: Person[] = [];
  @Input() public selectedContributors: Person[] = [];
  @Input() public isSearchEnabled = false;
  @Input() public teamspaceId: number;

  @Output() public readonly elementSelected = new EventEmitter<Person>();
  @Output() public readonly elementDeselected = new EventEmitter<Person>();
  @Output() public readonly searchValueChanged = new EventEmitter<string>();

  private initiallySelectedContributors: Person[] = [];

  public get sortedContributors(): Person[] {
    const selectedIds = this.initiallySelectedContributors.map(({ id }) => id);
    return sortBy(this.contributors, contributor => (selectedIds.includes(contributor.id) ? -1 : 1));
  }

  @Input() public avatarDecorator: AvatarDecoratorFn = avatar => of(avatar);

  public ngOnInit(): void {
    this.initiallySelectedContributors = cloneDeep(this.selectedContributors);
  }

  public userIdentifier = (element: SelectElement) => {
    const selectedContributor = this.contributors.find(p => p.id === element.id);
    return selectedContributor ? selectedContributor.email : '';
  };
}
