<search-and-select
    [elements]="taskLists"
    [selectedElements]="selectedTaskLists"
    [isSearchEnabled]="false"
    (elementSelected)="elementSelected.emit($any($event))"
    (elementDeselected)="elementDeselected.emit($any($event))">
  <ng-template let-taskList="element">
    <div
        [style.color]="getTaskListColor(taskList)"
        class="search-and-select_task-list">
            <span
                class="search-and-select_task-list_color"
                [style.background-color]="getTaskListColor(taskList)">
            </span>
      {{taskList.name}}
    </div>
  </ng-template>
</search-and-select>
