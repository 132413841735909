import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { EntityTranslator } from '@celum/work/app/core/model';
import { selectFileVersionEntities } from '@celum/work/app/core/model/entities/file-version/file-version.selectors';
import { selectPersonEntities } from '@celum/work/app/core/model/entities/person';

import { File, FileType } from './file.model';
import { selectFileById } from './file.selectors';

export class FileTranslator implements EntityTranslator<File> {
  public translateToEntity(json: any): File {
    const id = json.id;
    const activeVersionId = json.activeVersionId;
    return {
      id,
      name: json.name,
      parentId: json.parentId,
      libraryId: json.libraryId,
      assetType: json.assetType,
      createdById: json.createdBy,
      changedById: json.changedBy,
      createdOn: json.createdOn,
      changedOn: json.changedOn,
      relationId: json.relationId,
      status: json.status,
      activeVersionId,
      numberOfTasks: json.numberOfTasks,
      importId: json.importId,
      exportId: json.exportId,
      format: json.format,
      entityType: FileType.instance(),
      activeVersion: (store: Store<any>) =>
        combineLatest([store.select(selectFileVersionEntities), store.select(selectFileById(id))]).pipe(
          filter(([_, file]) => !!file),
          map(([versions, file]) => versions[file.activeVersionId])
        ),
      creator: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectFileById(id))]).pipe(
          filter(([_, file]) => !!file),
          map(([persons, file]) => Object.values(persons).find(person => person.oid === file.createdById))
        ),
      modifier: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectFileById(id))]).pipe(
          filter(([_, file]) => !!file),
          map(([persons, file]) => Object.values(persons).find(person => person.oid === file.changedById))
        ),
      contentHubConnections: json.contentHubConnections
    };
  }
}
