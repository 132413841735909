<div>
  <shared-systembar
      *ngIf="systembarConfiguration$ | async as systemBarConfig"
      class="workroom-app_systembar"
      [configuration]="systemBarConfig">
  </shared-systembar>

  <div class="workroom-app_content" [class.workroom-app_content--system-bar-expanded]="!systemBarCollapsed">
    <router-outlet></router-outlet>

    <loading-mask class="workroom-app_loading-mask" [hidden]="(pending$ | async) === false" background="black" [opacity]="0.3"></loading-mask>
  </div>
</div>


<operation-menu></operation-menu>
