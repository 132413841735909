import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, switchMap, switchMapTo, withLatestFrom } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { isTruthy, PaginationResult } from '@celum/core';

import { WorkroomService } from '../../../../../core/api/workroom';
import { Paging, Roles, Sorting } from '../../../../../core/model';
import {
  selectAllWorkrooms,
  selectWorkroomByIds,
  Workroom,
  WorkroomStatus
} from '../../../../../core/model/entities/workroom';
import { PaginatedListState, SimpleListState } from '../../../../../core/model/list.model';

export interface WorkroomState extends SimpleListState, PaginatedListState {}

@Injectable()
export class WorkroomStore extends ComponentStore<WorkroomState> {
  public readonly paginationResult$: Observable<PaginationResult> = this.select(state => state.paginationResult);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);

  public readonly loadWorkrooms = this.effect(
    (config$: Observable<{ queryString: string; repositoryId: string; offset: number; batchSize?: number }>) => {
      return config$.pipe(
        mergeMap(config =>
          this.workroomService
            .loadWorkrooms(
              config.queryString,
              new Paging(config.offset, config.batchSize || this.WORKROOMS_BATCH_SIZE),
              new Sorting('name', SortDirection.ASC),
              [WorkroomStatus.ACTIVE],
              true,
              config.repositoryId,
              [Roles.CONTRIBUTOR]
            )
            .pipe(
              withLatestFrom(this.state$),
              tapResponse(
                ([{ workrooms, paginationResult }, { entityIds: currEntityIds }]) => {
                  const workroomIds = workrooms.map(({ id }) => id);
                  const entityIds = config.offset === 0 ? workroomIds : currEntityIds.concat(workroomIds);

                  this.patchState({
                    loading: false,
                    paginationResult,
                    entityIds,
                    lastOffset: entityIds.length
                  });
                },
                _error => this.setLoading(false)
              )
            )
        )
      );
    }
  );

  private readonly setLoading = this.updater((state, loading: boolean) => ({
    ...state,
    loading
  }));

  private readonly WORKROOMS_BATCH_SIZE: number = 10;

  constructor(
    private workroomService: WorkroomService,
    private store: Store<any>
  ) {
    super({
      entityIds: [],
      loading: true,
      lastOffset: 0,
      batchSize: 0,
      paginationResult: {
        totalElementCount: 0,
        hasTop: false,
        hasBottom: false
      }
    });
  }

  public selectWorkrooms(): Observable<Workroom[]> {
    return this.store.select(selectAllWorkrooms).pipe(
      switchMapTo(
        this.select(state => state).pipe(
          map(state => state.entityIds),
          isTruthy(),
          switchMap(ids => this.store.select(selectWorkroomByIds(ids)))
        )
      )
    );
  }

  public fetchNextWorkroomsBatch(queryString: string, repositoryId: string): void {
    const { paginationResult, lastOffset: offset } = this.get();

    if (paginationResult.hasBottom) {
      this.loadWorkrooms({
        queryString,
        repositoryId,
        offset
      });
    }
  }
}
