<celum-dialog (onCloseAll)="cancel()">
  <celum-dialog-header class="update-users-role-dialog update-users-role-dialog_header">
    <span>{{ headerText | translate }}</span>
    <role-information-dialog></role-information-dialog>
  </celum-dialog-header>

  <celum-dialog-content>
    <div
      [class.update-users-role-list--restrict]="(personDataArray$ | async).length > 3"
      class="update-users-role-list flex-column-scrollbar celum-scrollbar">
      <multiline-template-person
        *ngFor="let personData of personDataArray$ | async; let personDataIndex = index; trackBy: trackByFn"
        [withStatus]="false"
        [person]="personData.person"
        [invitationStatus]="personData.invitationStatus"
        [initialRole]="personData.role"
        [showDriveWarnings]="showDriveWarnings$ | async"
        (roleChanged)="onRoleChanged(personDataIndex, $event)"
        class="update-users-role">
      </multiline-template-person>
    </div>
  </celum-dialog-content>

  <celum-dialog-footer
    [confirmBtnText]="confirmBtnText | translate"
    [color]="color"
    [loading]="loading"
    [disableOnEnterConfirmation]="true"
    [valid]="isDirty"
    (onConfirm)="onOkClicked()"
    (onCancel)="cancel()">
  </celum-dialog-footer>
</celum-dialog>
