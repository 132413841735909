<celum-dialog (onCloseAll)="closeDialog()" class="folder-tree-selection-dialog">
  <celum-dialog-header>
    {{ 'ROBOTS.TASK_MANAGEMENT.TASK_CREATION.SELECT_FOLDERS_DIALOG.TITLE' | translate }}
  </celum-dialog-header>
  <celum-dialog-content>
    <div *ngIf="(folders$ | async).length > 0; else emptyPage">
      <files-tree
          [folders]="folders$ | async"
          [selectedFolderIds]="selectedFolderIds"
          [paginationInformation]="paginationInformation$ | async"
          [multiSelection]="true"
          (expand)="loadChildren($event)"
          (fetchMore)="loadMoreForFolder($event)"
          (selectionChanged)="onFolderSelectedChanged($event)">
      </files-tree>
    </div>
    <ng-template #emptyPage>
      <empty-page [config]="emptyPageConfig" class="folder-tree-selection-dialog--empty"></empty-page>
    </ng-template>
  </celum-dialog-content>
  <celum-dialog-footer
      confirmBtnText="WORK_SHARED.SELECT"
      cancelBtnText="CONTENT_ITEMS.DIALOG.CANCEL"
      [valid]="isValid"
      [color]="color"
      (onConfirm)="selectFolders()"
      (onCancel)="closeDialog()">
  </celum-dialog-footer>
</celum-dialog>
