<div class="search-and-select-person_entry">
  <workroom-avatar [configuration]="getAvatar(person) | async"></workroom-avatar>
  <div class="search-and-select-person_name-wrapper">
    <span class="search-and-select-person_name text-truncate" [title]="getUserName(person)">
      {{ getUserName(person) }}
    </span>
    <span *ngIf="shouldDisplayEmail(person)" class="search-and-select-person_email text-truncate">
          {{ person.email }}
    </span>
  </div>
</div>
