import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { DataUtil } from '@celum/core';
import { EntityUtil } from '@celum/work/app/core/model';

import {
  ContributorsDeleteMany,
  ContributorsDeleteOne,
  ContributorsUpsertMany,
  ContributorsUpsertOne
} from './contributor.actions';
import { Contributor, ContributorState } from './contributor.model';

export const contributorAdapter: EntityAdapter<Contributor> = createEntityAdapter<Contributor>();

export const initialState: ContributorState = contributorAdapter.getInitialState();

const properties = ['roles'];

const reducer = createReducer(
  initialState,
  on(ContributorsUpsertOne, (state: ContributorState, { contributor }) => {
    const contributors = EntityUtil.changedEntities(properties, [contributor], state.entities);
    if (!DataUtil.isEmpty(contributors)) {
      return contributorAdapter.upsertOne(contributors[0], state);
    } else {
      return state;
    }
  }),

  on(ContributorsUpsertMany, (state: ContributorState, { contributors }) => {
    const changedContributors = EntityUtil.changedEntities(properties, contributors, state.entities);
    return contributorAdapter.upsertMany(changedContributors, state);
  }),

  on(ContributorsDeleteOne, (state: ContributorState, { contributor }) => {
    return contributorAdapter.removeOne(contributor.id, state);
  }),

  on(ContributorsDeleteMany, (state: ContributorState, { ids }) => {
    return contributorAdapter.removeMany(ids, state);
  })
);

export function contributorReducer(state: ContributorState = initialState, action: Action): ContributorState {
  return reducer(state, action);
}
