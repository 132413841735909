import { createAction, props } from '@ngrx/store';

import { TemplateCategory } from './template-category.model';

export const TemplateCategoryUpsertOne = createAction(
  '[TemplateCategory] Upsert One',
  props<{ templateCategory: TemplateCategory }>()
);

export const TemplateCategoryUpsertMany = createAction(
  '[TemplateCategory] Upsert Many',
  props<{ templateCategories: TemplateCategory[] }>()
);
