import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, scan, startWith } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { RobotNotification } from '@celum/work/app/core/client-notification/model/robot-notification.model';
import { RobotActionType } from '@celum/work/app/core/model/entities/workroom/robot.model';
import { DateUtil } from '@celum/work/app/shared/util';

import { robotNotificationIconMap } from './robot-notification-icon-map';
import { NotificationWebsocketListener } from '../../core/client-notification/notification-websocket-listener.service';
import { SnackbarGroupConfig } from '../../shared/components/snackbar-group/snackbar-group.component';

@Component({
  selector: 'robot-notification-snackbar-group',
  templateUrl: './robot-notification-snackbar-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RobotNotificationSnackbarGroupComponent {
  public clientNotifications$: Observable<RobotNotification[]>;
  public config: SnackbarGroupConfig = {
    title: 'ROBOTS.NOTIFICATIONS.TITLE',
    titleIcon: IconConfiguration.medium('robot').withCustomStyles({ stroke: 'white' })
  };

  constructor(
    private clientNotificationWebsocketListener: NotificationWebsocketListener<RobotNotification>,
    private dateUtil: DateUtil
  ) {
    this.start();
  }

  public onDismiss(): void {
    this.start();
  }

  public getSnackbarGroupItemIcon(clientNotification: RobotNotification): IconConfiguration {
    return IconConfiguration.small(robotNotificationIconMap[clientNotification.key]);
  }

  public getSnackbarGroupItemTitle(item: RobotNotification): string {
    return `ROBOTS.NOTIFICATIONS.${item.key}`;
  }

  private start(): void {
    this.clientNotifications$ = this.clientNotificationWebsocketListener.clientNotificationsSubj.pipe(
      filter(notification => notification.typeKey === 'robot'),
      scan((acc, curr) => [...acc, curr], []),
      map(robotNotifications => robotNotifications.map(robotNotification => this.transformIfNeeded(robotNotification))),
      startWith([])
    );
  }

  private transformIfNeeded(robotNotification: RobotNotification): RobotNotification {
    switch (robotNotification.key) {
      case RobotActionType.TASK_REMOVE_DUE_DATE:
      case RobotActionType.TASK_SET_DUE_DATE:
        return {
          ...robotNotification,
          data: {
            ...robotNotification.data,
            dueDate: this.dateUtil.getFormattedFullDate(robotNotification?.data?.dueDate),
            taskListName: robotNotification.data.taskListName?.toUpperCase()
          }
        };
      case RobotActionType.CREATE_TASK_BASED_ON_FILE:
        return {
          ...robotNotification,
          data: {
            ...robotNotification.data,
            taskListName: robotNotification.data.taskListName?.toUpperCase()
          }
        };
      case RobotActionType.TASK_ADD_ASSIGNEES:
      case RobotActionType.TASK_REMOVE_ASSIGNEES:
        return {
          ...robotNotification,
          data: {
            taskListName: robotNotification.data.toUpperCase()
          }
        };
      default:
        break;
    }

    return robotNotification;
  }
}
