import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AVATAR_SIZE, CelumDialog, CelumDialogConfiguration, ColorConstants } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';

import { NotificationService } from '../../../../core';
import { TeamspaceService } from '../../../../core/api/teamspace/teamspace.service';
import { AccountAccess } from '../../../../core/ui-state/ui-state.model';
import {
  WorkroomAvatarConfigBuilder,
  WorkroomAvatarConfiguration
} from '../../../../shared/components/workroom-avatar/workroom-avatar-configuration';

export enum QuotaType {
  STORAGE = 'STORAGE',
  X3D_CONVERSION = 'X3D_CONVERSION',
  WORKROOMS = 'WORKROOMS'
}

@Component({
  selector: 'contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.less']
})
export class ContactDialog extends ReactiveComponent implements CelumDialog<ContactDialogConfiguration> {
  public color: string = ColorConstants.PRIMARY;
  public accountAccess: AccountAccess;
  public quotaTypes: QuotaType[];

  constructor(
    private dialogRef: MatDialogRef<ContactDialog>,
    private teamspaceService: TeamspaceService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public get accountOwnerAvatarConfig(): WorkroomAvatarConfiguration {
    return new WorkroomAvatarConfigBuilder()
      .withDisplayName(this.accountAccess.ownerName, false)
      .withTitle(this.accountAccess.ownerName)
      .withSize(AVATAR_SIZE.xxxl)
      .withBackgroundColor('#ec407a')
      .build();
  }

  public configure(cfg: ContactDialogConfiguration): void {
    this.accountAccess = cfg.accountAccess;
    this.quotaTypes = cfg.quotaTypes;
  }

  public confirm() {
    this.teamspaceService
      .contactAccountOwner(this.accountAccess.accountId, this.accountAccess.ownerEmail, this.quotaTypes)
      .subscribe(
        () => {
          this.notificationService.success('TEMPLATE_CHOOSER.LICENSE_LIMIT.CONTACT.FORM.SNACK_BAR.SUCCESS');
          this.dialogRef.close();
        },
        err => {
          this.notificationService.error('TEMPLATE_CHOOSER.LICENSE_LIMIT.CONTACT.FORM.SNACK_BAR.ERROR');
          this.dialogRef.close();
        }
      );
  }

  public cancel() {
    this.dialogRef.close();
  }
}

export class ContactDialogConfiguration extends CelumDialogConfiguration {
  constructor(
    public accountAccess: AccountAccess,
    public quotaTypes: QuotaType[]
  ) {
    super('main');
    this.color = ColorConstants.PRIMARY;
  }
}
