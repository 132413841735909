import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';

import { CelumAvatarListModule } from '@celum/common-components';

import { ColorMenuItem } from './components/color-menu-item/color-menu-item.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { TaskListColumnSetting } from './components/task-list-column-setting/task-list-column-setting.component';
import { TaskListColumnsMenuItem } from './components/task-list-columns-menu-item/task-list-columns-menu-item.component';
import { TaskListHeaderComponent } from './components/task-list-header/task-list-header.component';
import { TaskListOwners } from './components/task-list-owners/task-list-owners.component';
import { TaskListsBoardBaseComponent } from './components/task-lists-board-base/task-lists-board-base.component';
import { TaskListSubHeaderComponent } from '../pages/workroom/pages/tasks/components/task-list-sub-header/task-list-sub-header.component';
import { SharedModule } from '../shared';

@NgModule({
  declarations: [
    TaskListComponent,
    ColorMenuItem,
    TaskListColumnsMenuItem,
    TaskListColumnSetting,
    TaskListOwners,
    TaskListHeaderComponent,
    TaskListSubHeaderComponent,
    TaskListsBoardBaseComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DragulaModule.forRoot(),
    TranslateModule,
    CelumAvatarListModule,
    SharedModule
  ],
  exports: [
    TaskListComponent,
    ColorMenuItem,
    TaskListColumnsMenuItem,
    TaskListColumnSetting,
    TaskListOwners,
    TaskListHeaderComponent,
    TaskListSubHeaderComponent,
    TaskListsBoardBaseComponent
  ]
})
export class TaskListModule {}
