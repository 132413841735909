import { HttpErrorResponse } from '@angular/common/http';

export enum CustomFieldErrorKeyEnum {
  CUSTOM_FIELD_CONFLICTING_STATE = 'CUSTOM_FIELD_CONFLICTING_STATE',
  CUSTOM_FIELD_LIMIT_EXCEEDED = 'CUSTOM_FIELD_LIMIT_EXCEEDED'
}

export interface CustomFieldHttpErrorResponse extends HttpErrorResponse {
  error: {
    data: any;
    errorKey?: CustomFieldErrorKeyEnum;
  };
}
