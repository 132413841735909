import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TaskList } from '@celum/work/app/core/model/entities/task';
import {
  AutomatorType,
  DueDateAction,
  isAutomator,
  RobotActionType,
  RobotContext,
  Robots,
  RobotTypes,
  TaskTrigger
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import { RobotDialogConfiguration } from '@celum/work/app/robots/components/robot-dialog/robot-dialog-config';
import { BaseRobot } from '@celum/work/app/robots/components/robot-dialog-templates/base-robot/base-robot.component';
import { TokenStyle } from '@celum/work/app/robots/components/robot-dialog-templates/robot-components.model';
import { DueDateAutomatorService } from '@celum/work/app/robots/services/due-date-automator.service';
import { RobotsFactory } from '@celum/work/app/robots/services/robots-factory';
import { MessageBoxConfigType } from '@celum/work/app/shared/components/message/message-box';
import { DateFormats } from '@celum/work/app/shared/util';

@Component({
  selector: 'due-date-automator',
  templateUrl: './due-date-automator.component.html',
  styleUrls: ['./due-date-automator.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DueDateAutomatorComponent extends BaseRobot<TaskList> {
  public readonly TARGETING_ROBOT_TYPE: RobotTypes = AutomatorType.TASK_DUE_DATE;
  public dateFormats = DateFormats;

  public dueDate: number;
  public daysCount = 7;

  constructor(
    robotsFactory: RobotsFactory,
    translateService: TranslateService,
    protected automatorService: DueDateAutomatorService
  ) {
    super(robotsFactory, translateService, automatorService);
  }

  public get messageBoxes$(): Observable<MessageBoxConfigType[]> {
    return this.notApplied$.pipe(
      map(
        notApplied =>
          notApplied && [
            {
              type: 'warn',
              text: this.translateService.instant(`ROBOTS.TASK_DUE_DATE.WARNING`)
            }
          ]
      )
    );
  }

  public get translationsCategory(): string {
    return this.selectedActionType;
  }

  public configure(configuration: RobotDialogConfiguration<TaskList>): void {
    super.configure(configuration);

    if (this.robot && isAutomator(this.robot)) {
      this.dueDate = (this.robot as any).action.dueDate;
      this.daysCount = (this.robot as any).action.daysCount ?? this.daysCount;
    }

    this.splitIntoTokens();
  }

  public getThenTokenStyle(token: string) {
    return super.getThenTokenStyle(this.translationsCategory, token);
  }

  public getWhenTokenStyle(token: string) {
    return super.getWhenTokenStyle(this.translationsCategory, token);
  }

  public isValid(): boolean {
    switch (this.selectedActionType) {
      case RobotActionType.TASK_SET_DUE_DATE:
        return this.dueDate > 0;
      case RobotActionType.TASK_SET_TIME_PERIOD_DUE_DATE:
        return this.daysCount > 0;
      default:
        return true;
    }
  }

  public getResult(): Robots {
    const trigger: TaskTrigger = {
      type: this.selectedTriggerType,
      taskListId: this.sourceEntity.id
    };

    const action: DueDateAction = {
      type: this.selectedActionType
    };

    if (this.selectedActionType === RobotActionType.TASK_SET_DUE_DATE) {
      action.dueDate = this.dueDate;
    } else if (this.selectedActionType === RobotActionType.TASK_SET_TIME_PERIOD_DUE_DATE) {
      action.daysCount = this.daysCount;
    }

    return {
      type: AutomatorType.TASK_DUE_DATE,
      sourceContext: RobotContext.TASK_LIST,
      sourceId: this.sourceEntity.id,
      trigger,
      action
    };
  }

  public getWhenTokens(): TokenStyle[] {
    return this.whenTokens[this.translationsCategory].textTokens;
  }

  public getThenTokens(): TokenStyle[] {
    return this.thenTokens[this.translationsCategory].textTokens;
  }

  private splitIntoTokens(): void {
    this.getAllActions().subscribe(actions => {
      actions.forEach(action => {
        this.whenTokens[action] = this.tokenizeTranslation(`ROBOTS.TASK_DUE_DATE.WHEN`);
        this.thenTokens[action] = this.tokenizeTranslation(`ROBOTS.TASK_DUE_DATE.THEN_${action}`);
      });
    });
  }
}
