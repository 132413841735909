<div class="fields-overview">
  <div class="fields-overview_container">
    <div class="fields-overview_buttons-wrapper">
      <field-create-button (fieldTypeSelected)="openCreateCustomFieldDialog($event)"> </field-create-button>

      <div 
        [matTooltip]="'TEAMSPACE_MANAGEMENT.FIELDS.NAV_BUTTON.CREATE_FORM_BUTTON_DISABLED_TOOLTIP' | translate"
        [matTooltipDisabled]="(selectionLimitExceeded$ | async) === false">
        <div
          [matTooltip]="'TEAMSPACE_MANAGEMENT.FIELDS.NAV_BUTTON.CREATE_FORM_BUTTON_DEFAULT_TOOLTIP' | translate"
          [matTooltipDisabled]="(selectedCustomFields$ | async | keyvalue).length > 0">
          <button 
            *ngIf="{ customFields: selectedCustomFields$ | async } as customFieldsData" 
            class="form-create-button"
            [class.form-create-button-disabled]="(customFieldsData.customFields | keyvalue).length === 0 || (selectionLimitExceeded$ | async)"
            mat-button
            celum-button
            [disabled]="(customFieldsData.customFields | keyvalue).length === 0 || (selectionLimitExceeded$ | async)" 
            (click)="openCreateCustomFormDialog(customFieldsData.customFields)">
              {{'TEAMSPACE_MANAGEMENT.FIELDS.NAV_BUTTON.CREATE_FORM_BUTTON' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<custom-fields-virtual-scroll-table></custom-fields-virtual-scroll-table>
