import { createSelector } from '@ngrx/store';

import { selectCurrentTask } from '@celum/work/app/core/model/entities/task/task.selectors';
import { selectCurrentWorkroomIdParam } from '@celum/work/app/core/model/entities/workroom';
import { selectLoggedInPerson } from '@celum/work/app/core/ui-state/ui-state.selectors';
import {
  selectCurrentWorkroom,
  selectCurrentWorkroomContributingPersonsWithMembershipStatus
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { hasTaskListRule } from '@celum/work/app/robots/services/robots-util';

import { taskListAdapter } from './task-list.reducer';
import { selectContributorEntities } from '../contributor';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';
import { MembershipStatus } from '../member';
import { filterBySearchString, filterByVisitorMentionRestriction, Person } from '../person';
import { RuleType } from '../workroom/robot.model';

const { selectEntities, selectAll } = taskListAdapter.getSelectors();

export const selectTaskLists = createSelector(selectEntitiesState, (state: EntitiesState) => state.taskLists);

export const selectAllTaskLists = createSelector(selectTaskLists, selectAll);
export const selectTaskListEntities = createSelector(selectTaskLists, selectEntities);

export const selectTaskListById = (id: number) =>
  createSelector(selectTaskListEntities, taskListDictionary => taskListDictionary[id]);

export const selectCurrentWorkroomTaskLists = createSelector(
  selectAllTaskLists,
  selectCurrentWorkroomIdParam,
  (taskLists, workroomId) => taskLists.filter(taskList => taskList.workroomId === workroomId)
);

export const selectCurrentWorkroomTaskListsExcludedByRule = (rule: RuleType) =>
  createSelector(selectCurrentWorkroomTaskLists, selectCurrentWorkroom, (taskLists, currentWorkroom) =>
    taskLists.filter(taskList => currentWorkroom && !hasTaskListRule(currentWorkroom.configuration, taskList.id, rule))
  );

export const selectCurrentWorkroomHasTaskListsWithTaskCreationEnabled = createSelector(
  selectCurrentWorkroomTaskListsExcludedByRule(RuleType.TASK_CREATION_RESTRICTION),
  taskLists => !!taskLists.length
);

export const selectLoggedInPersonIsTaskListOwner = createSelector(
  selectCurrentTask,
  selectTaskListEntities,
  selectLoggedInPerson,
  (task, taskListEntities, loggedInPerson) => {
    const taskList = task && taskListEntities[task.taskListId];
    return taskList?.ownerIds.includes(loggedInPerson.id);
  }
);

export const selectIsTaskListOwner = (person: Person) =>
  createSelector(selectCurrentTask, selectTaskListEntities, (task, taskListEntities) => {
    const taskList = task && taskListEntities[task.taskListId];
    return taskList?.ownerIds.includes(person.id);
  });

export const selectTaskListHasTaskListOwners = createSelector(
  selectCurrentTask,
  selectTaskListEntities,
  (task, taskListEntities) => {
    const taskList = task && taskListEntities[task.taskListId];
    return taskList?.ownerIds.length > 0;
  }
);
export const selectCurrentWorkroomContributorsForCurrentTaskWithSearchForVisitor = (searchQuery: string) =>
  createSelector(
    selectCurrentWorkroomContributingPersonsWithMembershipStatus([MembershipStatus.ACTIVE, MembershipStatus.INIT]),
    selectCurrentTask,
    selectCurrentWorkroom,
    selectContributorEntities,
    selectTaskListEntities,
    (persons, currentTask, workroom, contributors, taskListEntities) =>
      filterByVisitorMentionRestriction(
        workroom,
        contributors,
        filterBySearchString(searchQuery, persons),
        currentTask,
        taskListEntities[currentTask.taskListId]
      )
  );
