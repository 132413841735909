import { Pipe, PipeTransform } from '@angular/core';

import { Color } from '@celum/work/app/core/model';
import { ColorService } from '@celum/work/app/shared/util/color.service';

@Pipe({ name: 'color' })
export class ColorPipe implements PipeTransform {
  public transform(value: Color, opacity?: number): string {
    if (value) {
      return ColorService.getColorAsRgbString(value, opacity);
    }
  }
}
