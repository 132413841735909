import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { EntityTranslator } from '@celum/work/app/core/model';
import { selectFileById } from '@celum/work/app/core/model/entities/file/file.selectors';
import { selectPersonEntities } from '@celum/work/app/core/model/entities/person';

import { Folder, FolderType } from './folder.model';

export class FolderTranslator implements EntityTranslator<Folder> {
  public translateToEntity(json: any): Folder {
    const id = json.id;

    return {
      id,
      name: json.name,
      parentId: json.parentId,
      libraryId: json.libraryId,
      assetType: json.assetType,
      createdById: json.createdBy,
      changedById: json.changedBy,
      createdOn: json.createdOn,
      changedOn: json.changedOn,
      relationId: json.relationId,
      status: json.status,
      creator: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectFileById(id))]).pipe(
          filter(([_, file]) => !!file),
          map(([persons, file]) => Object.values(persons).find(person => person.oid === file.createdById))
        ),
      modifier: (store: Store<any>) =>
        combineLatest([store.select(selectPersonEntities), store.select(selectFileById(id))]).pipe(
          filter(([_, file]) => !!file),
          map(([persons, file]) => Object.values(persons).find(person => person.oid === file.changedById))
        ),
      hasSubfolders: json.hasSubfolders,
      hasChildren: json.hasChildren,
      importId: json.importId,
      exportId: json.exportId,
      validationLevel: json.validationLevel,
      entityType: FolderType.instance(),
      contentHubConnections: json.contentHubConnections,
      folderSubType: json.folderType
    };
  }
}
