import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { map as mapTo } from 'lodash';
import { EMPTY, forkJoin, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';
import {
  TaskAssignPerson,
  TaskAssignPersonSuccess,
  TaskCreateTask,
  TaskCreateTaskFailed,
  TaskCreateTaskFromFilesSucceeded,
  TaskCreateTaskFromPortal,
  TaskCreateTaskFromPortalFailed,
  TaskCreateTaskFromPortalSucceeded,
  TaskCreateTaskSucceeded,
  TaskDeleteTask,
  TaskDeleteTaskContentItems,
  TaskDeleteTaskContentItemsFailed,
  TaskDeleteTaskContentItemsSucceeded,
  TaskDeleteTaskFailed,
  TaskDeleteTasks,
  TaskDeleteTasksFailed,
  TaskDeleteTasksSucceeded,
  TaskDeleteTaskSucceeded,
  TaskGetTask,
  TaskGetTaskError,
  TaskMoveTasks,
  TaskMoveWithBulkAssignment,
  TasksMovedToOtherList,
  TaskUnassignPerson,
  TaskUnassignPersonSuccess,
  TaskUpdateTask
} from '@celum/work/app/core/api/task/task.actions';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import {
  selectTaskEntities,
  selectTaskListById,
  taskDetailProperties,
  TaskHandleAssignee,
  TasksDeleteMany,
  TasksDeleteOne,
  TasksUpsertMany,
  TasksUpsertOne
} from '@celum/work/app/core/model/entities/task';
import { Task } from '@celum/work/app/core/model/entities/task/task.model';
import { selectLoggedInPersonId } from '@celum/work/app/core/ui-state/ui-state.selectors';
import { TasksOverviewFetchNextBatch } from '@celum/work/app/pages/workroom/pages/tasks/store/tasks-overview.actions';
import {
  selectHasBottomMoreForList,
  selectTasksForList
} from '@celum/work/app/pages/workroom/pages/tasks/store/tasks-overview.selectors';
import { selectCurrentWorkroomId } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { TaskListService } from '@celum/work/app/task-list/services/task-list.service';

import { TaskService } from './task.service';
import { FileFetchLinkedPortalAssetIds } from '../../model/entities/file/file.actions';
import { FileService } from '../file/file.service';

@Injectable()
export class TaskEffects {
  public getTaskById = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskGetTask),
      switchMap(({ taskId }) =>
        this.taskService.getTaskById(taskId).pipe(
          mergeMap(task => this.loadTaskAttachments(task)),
          switchMap(task =>
            merge(
              of(
                TasksUpsertOne({
                  task,
                  propertiesToUpdate: taskDetailProperties
                })
              ),
              of(FileFetchLinkedPortalAssetIds({ fileIds: task.attachmentIds }))
            )
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(TaskGetTaskError());
          })
        )
      )
    )
  );

  public getTaskError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskGetTaskError),
        switchMap(() => this.store.select(selectCurrentWorkroomId)),
        switchMap(workroomId => this.router.navigate(['workroom', workroomId, 'tasks']))
      ),
    { dispatch: false }
  );

  public createTask = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskCreateTask),
      mergeMap(action =>
        this.taskService.createTask(action.task).pipe(
          switchMap(task => {
            // 1. remove the temporary task
            // 2. issue success action
            return merge(
              of(TasksDeleteOne({ task: action.task })),
              of(
                TaskCreateTaskSucceeded({
                  task: task,
                  tempId: action.task.id
                })
              )
            );
          }),
          catchError(err => {
            this.failureHandler.handleError(err);
            // reset creation...
            return merge(of(TasksDeleteOne({ task: action.task })), of(TaskCreateTaskFailed({ task: action.task })));
          }),
          startWith(
            TasksUpsertOne({
              task: action.task
            })
          )
        )
      )
    )
  );

  public createTaskFromPortal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskCreateTaskFromPortal),
      mergeMap(({ taskListId, portalId, taskForm }) =>
        this.taskService.createTaskFromPortal(taskListId, portalId, taskForm).pipe(
          map(task => TaskCreateTaskFromPortalSucceeded({ tasks: [task], taskListId })),
          catchError(() => of(TaskCreateTaskFromPortalFailed({ portalId })))
        )
      )
    )
  );

  public taskSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskCreateTaskFromFilesSucceeded, TaskCreateTaskFromPortalSucceeded),
        withLatestFrom(this.store.select(selectCurrentWorkroomId)),
        tap(([action, workroomId]) =>
          this.router.navigate(['workroom', workroomId, 'tasks', 'task', action.tasks[action.tasks.length - 1].id])
        )
      ),
    { dispatch: false }
  );

  public updateTask = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskUpdateTask),
      withLatestFrom(this.store.select(selectTaskEntities)),
      mergeMap(([action, taskEntities]) =>
        this.taskService.updateTask(action.task, action.propertiesToUpdate).pipe(
          switchMap(() => EMPTY),
          catchError(err => {
            this.failureHandler.handleError(err);
            // reset update...
            return of(TasksUpsertOne({ task: taskEntities[action.task.id] }));
          }),
          startWith(TasksUpsertOne({ task: { ...action.task, ...action.propertiesToUpdate } }))
        )
      )
    )
  );

  public moveTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskMoveTasks),
      concatLatestFrom(action => [
        this.taskListService.getAccessibleTasksAfterMove(action.targetTaskListId, action.tasks),
        // this.store.select(selectAccessibleTasksAfterMove(action.targetTaskListId, action.tasks)),
        this.store.select(selectLoggedInPersonId)
      ]),
      mergeMap(([action, accessibleTasksAfterMove, loggedInPersonId]) => {
        const accessibleTaskIdsAfterMove = mapTo(accessibleTasksAfterMove, 'id');
        let stream$ = this.taskService
          .moveTasks(action.tasks, action.targetTaskListId, action.sort, accessibleTaskIdsAfterMove)
          .pipe(
            switchMap(() => this.handleTaskMoved(action.targetTaskListId, action.showSnackbar)),
            catchError(err => {
              this.failureHandler.handleError(err);
              // undo change
              return of(
                TasksUpsertMany({
                  tasks: action.tasks,
                  propertiesToUpdate: ['taskListId', 'sort']
                }),
                TasksMovedToOtherList({
                  loggedInPersonId: loggedInPersonId,
                  tasks: action.tasks,
                  sourceTaskListId: action.targetTaskListId,
                  targetTaskListId: action.sourceTaskListId
                })
              );
            })
          );

        const lostTasksAfterMove = action.tasks.filter(task => !accessibleTaskIdsAfterMove.includes(task.id));
        stream$ = stream$.pipe(
          startWith(
            TasksUpsertMany({
              tasks: action.tasks
                .map(task => ({
                  ...task,
                  taskListId: accessibleTaskIdsAfterMove.includes(task.id) ? action.targetTaskListId : -1,
                  sort: action.sort
                }))
                .reverse(),
              propertiesToUpdate: ['taskListId', 'sort']
            }),
            TasksMovedToOtherList({
              loggedInPersonId: loggedInPersonId,
              tasks: lostTasksAfterMove.reverse(),
              sourceTaskListId: action.sourceTaskListId,
              targetTaskListId: -1
            }),
            TasksMovedToOtherList({
              loggedInPersonId: loggedInPersonId,
              tasks: accessibleTasksAfterMove.reverse(),
              sourceTaskListId: action.sourceTaskListId,
              targetTaskListId: action.targetTaskListId
            })
          )
        );

        return stream$;
      })
    )
  );

  public fetchTasksOnMove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskMoveTasks),
      mergeMap(action =>
        this.store.select(selectHasBottomMoreForList(action.sourceTaskListId)).pipe(
          take(1),
          filter(Boolean),
          mergeMap(() =>
            this.store.select(selectTasksForList(action.sourceTaskListId)).pipe(
              take(1),
              filter(tasks => tasks.length <= 5),
              map(() => TasksOverviewFetchNextBatch({ listId: action.sourceTaskListId }))
            )
          )
        )
      )
    )
  );

  public deleteTask = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskDeleteTask),
      mergeMap(action =>
        this.taskService.deleteTasks([action.task.id]).pipe(
          map(() => TaskDeleteTaskSucceeded({ task: action.task })),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(TaskDeleteTaskFailed({ task: action.task }), TasksUpsertOne({ task: action.task }));
          }),
          startWith(TasksDeleteOne({ task: action.task }))
        )
      )
    )
  );

  public deleteTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskDeleteTasks),
      mergeMap(action =>
        this.taskService.deleteTasks(action.tasks.map(task => task.id)).pipe(
          switchMap(() => of(TaskDeleteTasksSucceeded({ tasks: action.tasks }))),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(TaskDeleteTasksFailed({ tasks: action.tasks }), TasksUpsertMany({ tasks: action.tasks }));
          }),
          startWith(TasksDeleteMany({ ids: action.tasks.map(task => task.id) }))
        )
      )
    )
  );

  public assignPerson = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskAssignPerson),
      mergeMap(action => {
        let stream$ = this.taskService.assignPersonToTask(action.task.id, action.personId).pipe(
          switchMap(() =>
            of(
              TaskAssignPersonSuccess({
                task: action.task,
                personId: action.personId
              })
            )
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(
              TaskHandleAssignee({
                entityId: action.task.id,
                assigneeId: action.personId,
                operation: 'remove'
              })
            );
          })
        );

        // only issue update if person is not yet known as assignee
        if (action.task.assigneeIds.indexOf(action.personId) < 0) {
          stream$ = stream$.pipe(
            startWith(
              TaskHandleAssignee({
                entityId: action.task.id,
                assigneeId: action.personId,
                operation: 'add'
              })
            )
          );
        }

        return stream$;
      })
    )
  );

  public unassignPerson = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskUnassignPerson),
      mergeMap(action => {
        const updatedTask = {
          ...action.task,
          assigneeIds: [...action.task.assigneeIds]
        };
        const index = updatedTask.assigneeIds.findIndex(id => action.personId === id);

        let stream$ = this.taskService.unassignPersonFromTask(action.task.id, action.personId).pipe(
          switchMap(() =>
            of(
              TaskUnassignPersonSuccess({
                task: updatedTask,
                personId: action.personId
              })
            )
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(TasksUpsertOne({ task: action.task }));
          })
        );

        if (index >= 0) {
          updatedTask.assigneeIds.splice(index, 1);

          stream$ = stream$.pipe(startWith(TasksUpsertOne({ task: updatedTask })));
        }

        return stream$;
      })
    )
  );

  public deleteTaskContentItems = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskDeleteTaskContentItems),
      mergeMap(action =>
        this.taskService
          .deleteTaskContentItems(
            action.task.id,
            action.contentItems.map(_ => _.id)
          )
          .pipe(
            switchMap(() =>
              of(
                TaskDeleteTaskContentItemsSucceeded({
                  taskId: action.task.id,
                  contentItems: action.contentItems
                })
              )
            ),
            catchError(err => {
              this.failureHandler.handleError(err);
              return of(
                TaskDeleteTaskContentItemsFailed({
                  task: action.task,
                  contentItems: action.contentItems
                })
              );
            })
          )
      )
    )
  );

  public taskMoveWithBulkAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskMoveWithBulkAssignment),
      withLatestFrom(this.store.select(selectLoggedInPersonId)),
      mergeMap(([action, loggedInPersonId]) =>
        this.taskService
          .moveWithBulkAssignment(action.tasks, action.targetTaskListId, action.sort, action.personIds)
          .pipe(
            switchMap(() => this.handleTaskMoved(action.targetTaskListId, action.showSnackbar)),
            catchError(err => {
              this.failureHandler.handleError(err);
              return of(
                TasksUpsertMany({
                  tasks: action.tasks,
                  propertiesToUpdate: ['taskListId', 'sort', 'assigneeIds']
                }),
                TasksMovedToOtherList({
                  loggedInPersonId: loggedInPersonId,
                  tasks: action.tasks,
                  sourceTaskListId: action.targetTaskListId,
                  targetTaskListId: action.sourceTaskListId
                })
              );
            }),
            startWith(
              TasksUpsertMany({
                tasks: action.tasks
                  .map(task => ({
                    ...task,
                    taskListId: action.targetTaskListId,
                    sort: action.sort,
                    assigneeIds: action.personIds
                  }))
                  .reverse(),
                propertiesToUpdate: ['taskListId', 'sort', 'assigneeIds']
              }),
              TasksMovedToOtherList({
                loggedInPersonId,
                tasks: action.tasks.reverse(),
                sourceTaskListId: action.sourceTaskListId,
                targetTaskListId: action.targetTaskListId
              })
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private taskService: TaskService,
    private failureHandler: FailureHandler,
    private router: Router,
    private fileService: FileService,
    private taskListService: TaskListService
  ) {}

  private handleTaskMoved(targetTaskListId: number, showSnackbar: boolean): Observable<Action> {
    if (!showSnackbar) {
      return EMPTY;
    }

    return this.store.select(selectTaskListById(targetTaskListId)).pipe(
      take(1),
      map(
        taskList =>
          new ShowSnackbar(
            'taskMoved',
            SimpleSnackbar,
            SnackbarConfiguration.success('TASK_DETAIL.TASK_MOVED_SNACKBAR', { taskList: taskList.name.toUpperCase() })
          )
      )
    );
  }

  private loadTaskAttachments(task: Task): Observable<Task> {
    const attachmentIds = task?.attachmentIds.filter(id => !!id);
    if (attachmentIds?.length === 0) {
      return of(task);
    }

    return forkJoin(
      attachmentIds.map(attachmentId => this.fileService.getFile(attachmentId).pipe(catchError(_ => of(null))))
    ).pipe(map(_ => task));
  }
}
