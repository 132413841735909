import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { WorkroomCreatorSetImportIntentCollectionName } from '@celum/work/app/pages/workroom-creator/store/workroom-creator.actions';

import { ImportIntentFetchCollectionName } from './import-intent.actions';
import { NodeService } from '../services/node.service';

@Injectable()
export class ImportIntentEffects {
  public onImportIntentFetchCollectionName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportIntentFetchCollectionName),
      switchMap(({ repositoryId, collectionId }) => {
        return this.nodeService
          .getNode(repositoryId, collectionId)
          .pipe(map(node => WorkroomCreatorSetImportIntentCollectionName({ importIntentCollectionName: node.name })));
      })
    )
  );

  constructor(
    private actions$: Actions,
    private nodeService: NodeService
  ) {}
}
