import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ErrorKey } from '@celum/work/app/core/error/error-key';

import { AggregationService } from './aggregation.service';
import { AssetService, GetAssetsRequestParams } from './asset.service';
import { NodeService } from './node.service';
import { FailureHandler } from '../../core/error/failure-handler.service';
import { File } from '../../core/model/entities/file/file.model';
import { Folder, FolderType } from '../../core/model/entities/folder/folder.model';
import { defaultListResult, ListResult } from '../../core/model/list-result';
import { selectCurrentWorkroom } from '../../pages/workroom/store/workroom-wrapper.selectors';
import { Collection, ContentHubSort, ContentHubSortField } from '../model/content-hub.model';

interface ListState<T> extends ListResult<T> {
  loading: boolean;
}

const defaultListState: ListState<any> = {
  ...defaultListResult,
  loading: false
};

@Injectable()
export class ContentHubBrowserService {
  public collections$: BehaviorSubject<Collection[]> = new BehaviorSubject<Collection[]>([]);
  // Its a map of parentId to list of its folders
  public folders$: BehaviorSubject<{ [folderId: string]: ListState<Folder> }> = new BehaviorSubject<{
    [folderId: string]: ListState<Folder>;
  }>({});
  public files$: BehaviorSubject<ListState<File>> = new BehaviorSubject<ListState<File>>(null);
  public missingContentHubUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public assetPageSize = 0;
  private readonly treeBatchSize: number = 30;

  private cancelAssetsFetch$: Subject<void> = new Subject<void>();
  private cancelCollectionFetch$: Subject<void> = new Subject<void>();

  private assetFilter: { searchTerm: string; recursive: boolean } = {
    searchTerm: undefined,
    recursive: false
  };

  private assetSort: ContentHubSort = {
    field: ContentHubSortField.NAME,
    direction: 'ASC'
  };

  constructor(
    private aggregationService: AggregationService,
    private assetService: AssetService,
    private nodeService: NodeService,
    private failureHandler: FailureHandler,
    private store: Store<any>
  ) {}

  public get allFolders$(): Observable<Folder[]> {
    return this.folders$.pipe(
      map(folderMap => Object.values(folderMap).reduce((acc, curr) => [...acc, ...curr.results], []))
    );
  }

  public changeCollection(collectionId: string, repositoryId?: string): Observable<any> {
    [this.cancelAssetsFetch$, this.cancelCollectionFetch$].forEach(subj => subj.next());
    const collection = this.getCollectionById(collectionId);

    return this.store.select(selectCurrentWorkroom).pipe(
      take(1),
      switchMap(workroom => {
        this.emitStateWithLoading(this.files$.getValue(), true);
        return this.aggregationService.getRootFoldersAndAssets(
          repositoryId ?? workroom.contentHubRepositoryId,
          collection.nodeType.id,
          0,
          this.assetPageSize,
          0,
          this.treeBatchSize,
          collectionId
        );
      }),
      tap(res => {
        const obj = {};
        const collectionFolder = this.getCollectionFolder(
          collection,
          res.folders.paginationInformation.totalElementCount
        );
        obj[collection.id] = {
          ...res.folders,
          results: [collectionFolder, ...res.folders.results]
        };
        this.folders$.next(obj);

        this.emitStateWithLoading(res.files, false);
      }),
      takeUntil(this.cancelCollectionFetch$),
      catchError(error => {
        this.files$.next({ ...defaultListState });
        this.folders$.next({});

        this.failureHandler.handleError(error);
        return EMPTY;
      })
    );
  }

  public isFilterActive(): boolean {
    return !!this.assetFilter.searchTerm;
  }

  public loadMoreAssets(parentId: string, resetAssets: boolean): Observable<any> {
    this.cancelAssetsFetch$.next();

    if (resetAssets) {
      this.files$.next({ ...defaultListState });
    }

    if (!this.files$.getValue().paginationInformation.elementsFollow) {
      return EMPTY;
    }

    return this.store.select(selectCurrentWorkroom).pipe(
      take(1),
      switchMap(workroom => {
        this.emitStateWithLoading(this.files$.getValue(), true);
        const requestDTO = this.getAssetsRequestParams(parentId);
        return this.assetService.getAssets(workroom.contentHubRepositoryId, requestDTO);
      }),
      tap(res => {
        let state = this.files$.getValue();
        state = {
          results: [...state.results, ...res.results],
          paginationInformation: res.paginationInformation,
          loading: false
        };
        this.files$.next(state);
      }),
      takeUntil(this.cancelAssetsFetch$),
      catchError(error => {
        this.emitStateWithLoading(this.files$.getValue(), false);
        this.failureHandler.handleError(error);
        return EMPTY;
      })
    );
  }

  public loadMoreNodes(params: any, repositoryId?: string): Observable<any> {
    const collection = this.collections$.getValue().filter(({ id }) => id === params.parentId)[0];
    const loadRootNodes = !!collection;
    let parentId = params.parentId;

    if (loadRootNodes) {
      parentId = collection.nodeType?.id;
    }

    return this.store.select(selectCurrentWorkroom).pipe(
      take(1),
      switchMap(workroom => {
        const limit = params.limit || this.treeBatchSize;
        const nextPage = this.getNextPage(this.folders$.getValue()[params.parentId]?.results.length, limit);
        return loadRootNodes
          ? this.nodeService.getRootNodes(repositoryId ?? workroom.contentHubRepositoryId, parentId, nextPage, limit)
          : this.nodeService.getNodes(repositoryId ?? workroom.contentHubRepositoryId, parentId, nextPage, limit);
      }),
      tap(res => {
        const state = this.folders$.getValue();

        state[params.parentId] = {
          results: [...(state[params.parentId]?.results || []), ...this.fixParentId(res.results, params.parentId)],
          paginationInformation: res.paginationInformation,
          loading: false
        };

        this.folders$.next(state);
      }),
      catchError(error => {
        this.failureHandler.handleError(error);
        return EMPTY;
      })
    );
  }

  public init(repositoryId?: string): Observable<any> {
    return this.store.select(selectCurrentWorkroom).pipe(
      take(1),
      switchMap(workroom => {
        this.emitStateWithLoading(defaultListResult, true);
        return this.aggregationService.getCollectionsNodesAndAssets(
          repositoryId ?? workroom.contentHubRepositoryId,
          0,
          this.assetPageSize,
          0,
          this.treeBatchSize
        );
      }),
      tap(res => {
        this.collections$.next(res.collections);

        if (res.collections?.length > 0) {
          const collection = res.collections[0];
          const collectionFolder = this.getCollectionFolder(
            collection,
            res.folders.paginationInformation.totalElementCount
          );
          const obj = {};
          obj[collection.id] = {
            ...res.folders,
            results: [collectionFolder, ...res.folders.results],
            loading: false
          };
          this.folders$.next(obj);
        }

        this.emitStateWithLoading({ ...res.files }, false);
      }),
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          (error.status === 403 ||
            (error.status === 409 && error.error?.errorKey === ErrorKey.CONTENT_HUB_UNAUTHORIZED))
        ) {
          this.missingContentHubUser$.next(true);
        } else {
          this.failureHandler.handleError(error);
        }

        return EMPTY;
      })
    );
  }

  public assetsSearchTermChanged(parentId: string, value: string): void {
    this.assetFilter.searchTerm = value;
    this.loadMoreAssets(parentId, true).subscribe();
  }

  public assetsSearchRecursiveChanged(parentId: string, recursive: boolean): void {
    this.assetFilter.recursive = recursive;
    this.loadMoreAssets(parentId, true).subscribe();
  }

  public assetsSortChanged(parentId: string, sort: ContentHubSort): void {
    this.assetSort = sort;
    this.loadMoreAssets(parentId, true).subscribe();
  }

  private fixParentId(folders: Folder[], parentId: string): Folder[] {
    folders.forEach(folder => {
      if (!folder.parentId) {
        folder.parentId = parentId;
      }
    });
    return folders;
  }

  private getCollectionFolder(collection: Collection, subfoldersCount: number | undefined) {
    return {
      id: collection.id,
      name: collection.name,
      parentId: null,
      workroomId: null,
      assetType: null,
      createdById: null,
      changedById: null,
      createdOn: null,
      changedOn: null,
      relationId: null,
      deleted: false,
      hasSubfolders: subfoldersCount && subfoldersCount > 0,
      entityType: FolderType.instance()
    };
  }

  private getNextPage(length: number, limit: number): number {
    const nextPage = Math.floor(length / limit);
    const firstPage = 0;
    return nextPage || firstPage;
  }

  private emitStateWithLoading(state: ListResult<File>, loading: boolean): void {
    this.files$.next({
      ...state,
      loading
    });
  }

  private getAssetsRequestParams(parentId: string): GetAssetsRequestParams {
    const pageSize = this.assetPageSize;
    const page = this.getNextPage(this.files$.getValue()?.results.length, pageSize);
    const collection = this.getCollectionById(parentId);

    const body = collection ? { nodeTypeId: collection.nodeType?.id } : { parentId };

    return {
      ...body,
      ...this.assetFilter,
      pageParams: {
        page,
        pageSize
      },
      sort: this.assetSort
    };
  }

  private getCollectionById(id: string): Collection {
    const collections = this.collections$.getValue();
    return collections.filter(coll => coll.id === id)[0];
  }
}
