import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { CelumPropertiesProvider, PaginationResult } from '@celum/core';
import { EntitiesResult, ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { Paging, Sorting } from '@celum/work/app/core/model';
import { Contributor, ContributorType } from '@celum/work/app/core/model/entities/contributor/contributor.model';
import { MemberType } from '@celum/work/app/core/model/entities/member';
import { Person, PersonType } from '@celum/work/app/core/model/entities/person';
import { InvitedPerson } from '@celum/work/app/core/model/entities/template/template.model';
import { MetaInfo } from '@celum/work/app/core/model/meta-info.model';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

@Injectable({ providedIn: 'root' })
export class WorkroomContributorService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public loadContributors(
    workroomId: number,
    paging: Paging = null,
    sorting: Sorting = Sorting.of('contributors.membership.person.lastName', SortDirection.ASC),
    searchTerm = '',
    filterRole?: string,
    excludeLoggedIn = false,
    searchTypeStartsWith = false
  ): Observable<{ people: Person[]; contributors: Contributor[]; paginationResult: PaginationResult }> {
    const schema = {
      results: [ContributorType.instance().getSchema({ relationsFor: [MemberType.TYPE_KEY] })]
    };

    const metaInfo = MetaInfo.of(
      [PersonType.TYPE_KEY, MemberType.TYPE_KEY, ContributorType.TYPE_KEY],
      schema,
      [ContributorType.TYPE_KEY],
      'results'
    );

    const body: any = {
      // excludeLoggedIn: excludeLoggedIn,
      paging: paging,
      sorting: sorting,
      filterRole: filterRole,
      excludeLoggedIn: excludeLoggedIn,
      contains: searchTypeStartsWith,
      searchTerm
    };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}/contributor/search`, body)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);

          const persons = (entitiesResult.entities[PersonType.TYPE_KEY] as Person[]) || [];

          // contributor entity is the root entity which is returned sorted, however, we want to return the actual persons!
          const people = (entitiesResult.entities[ContributorType.TYPE_KEY] || []).map((contributor: Contributor) =>
            persons.find(person => person.id === contributor.personId)
          );

          const contributors = (entitiesResult.entities[ContributorType.TYPE_KEY] as Contributor[]) || [];

          return {
            people: people,
            contributors: contributors,
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public invitePerson(workroomId: number, invitedPeople: InvitedPerson[]): Observable<Contributor[]> {
    const body = {
      invitedPeople
    };

    const schema = [
      {
        contributor: ContributorType.instance().getSchema({ relationsFor: [MemberType.TYPE_KEY] })
      }
    ];
    const metaInfo = MetaInfo.of([PersonType.TYPE_KEY, MemberType.TYPE_KEY, ContributorType.TYPE_KEY], schema);

    return this.httpClient
      .post(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}/contributor/invite`,
        body,
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(
        map((res: Array<any>) => res.filter(Boolean).filter(({ invited }) => invited)),
        map((res: Array<any>) => this.resultConsumerService.translateAndAddToStore(res, metaInfo)),
        map((res: EntitiesResult) => res.entities[ContributorType.TYPE_KEY] as Contributor[])
      );
  }

  public updatePersonsRole(
    workroomId: number,
    personRoles: { personId: number; role: string }[]
  ): Observable<Contributor[]> {
    const metaInfo = MetaInfo.of([ContributorType.TYPE_KEY], ContributorType.instance().getSchema());
    const body = {
      personRoles
    };

    return this.httpClient
      .put(`${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}/contributors/role`, body)
      .pipe(map(res => this.handleContributorsResult(res, metaInfo)));
  }

  public removePersonsFromWorkroom(workroomId: number, personIds: number[], keepContent: boolean): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders(),
      body: {
        personIds,
        keepContent
      }
    };

    return this.httpClient
      .delete(`${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}/contributor`, httpOptions)
      .pipe(map(() => void 0));
  }

  public exitWorkroom(workroomId: number, keepContent: boolean): Observable<void> {
    return this.httpClient.get<void>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/workrooms/${workroomId}/contributor/exit?keepContent=${keepContent}`,
      STRONGLY_CONSISTENT_OPTION
    );
  }

  private handleContributorsResult(res, metaInfo): Contributor[] {
    const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);

    const contributors = entitiesResult.entities[ContributorType.TYPE_KEY];

    return contributors as Contributor[];
  }
}
